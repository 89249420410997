import axios from "axios";
import createHistory from "history/createHashHistory";
import { notify } from "react-notify-toast";
import { func } from "prop-types";
import { Unauthorized } from "./errors";
import { BASEURL } from "../constant";
import moment from "moment";
import { sevenDaysLoading } from "../Reducers/message";

export default createHistory();
const globalHistory = new createHistory();

export function profileLoading(bool) {
  return {
    type: "PROFILE_LOADING",
    status: bool,
  };
}
// export function sevenDaysLoading(bool) {
//   return {
//     type: "PROFILE_LOADING",
//     status: bool,
//   };
// }



export function conversationList(data) {
  return {
    type: "CONVERSATION_LIST",
    data,
  };
}

export function getMessageList(data) {
  return {
    type: "MESSAGE_LIST",
    data,
  };
}

export function getNotification(data) {
  return {
    type: "NOTIFICATION_LIST",
    data,
  };
}

export function countUnreadedMessages(data) {
  return {
    type: "COUNT_UNREAD",
    data,
  };
}

export function addMessage(url, header, data, msgId) {
  return (dispatch) => {
    dispatch(profileLoading(true));
    axios
      .post(BASEURL + url, data, header)
      .then((response) => {
        dispatch(getConversationList("/api/v1/message_list", header));
        dispatch(profileLoading(false));
        dispatch(getUserMessageList(`/api/v1/message_list/${msgId}`, header));
        return response;
      })
      .catch((error) => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        return false;
      });
  };
}

export function getConversationList(url, header) {
  return (dispatch) => {
    axios
      .get(BASEURL + url, header)
      .then((response) => {
        dispatch(conversationList(response.data.result.messageList));
        dispatch(profileLoading(false));
      })
      .catch((error) => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getUserMessageList(url, header) {
  return (dispatch) => {
    dispatch(profileLoading(true));
    dispatch(getMessageList([]));
    axios
      .get(BASEURL + url, header)
      .then((response) => {
        dispatch(getMessageList(response.data.result.messages));
        dispatch(profileLoading(false));
      })
      .catch((error) => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function markAsRead(url, header) {
  return (dispatch) => {
    axios
      .get(BASEURL + url, header)
      .then((response) => {
        dispatch(countUnreadMessages("/api/v1/countUnreadedMessages", header));
        // dispatch(getConversationList(`/api/v1/message_list`,header));
        // dispatch(getMessageList(response.data.result.messages))
      })
      .catch((error) => {
        // dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function addNewMessage(url, data, header, msgId) {
  return (dispatch) => {
    dispatch(profileLoading(true));
    // dispatch(getMessageList([]));
    axios
      .post(BASEURL + url, data, header)
      .then((response) => {
        //  dispatch(getMessageList(response.data.result.messages))
        dispatch(getUserMessageList(`/api/v1/message_list/${msgId}`, header));
        dispatch(profileLoading(false));
        dispatch(getConversationList(`/api/v1/message_list`, header));
      })

      .catch((error) => {
        dispatch(profileLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getNotificationlist(url, header) {
  let dte = moment(new Date()).format("YYYY-MM-DD");
  return (dispatch) => {
    dispatch(sevenDaysLoading(true));
    // dispatch(profileLoading(true))
    dispatch(getNotification({}));
    axios
      .get(BASEURL + `${url}?dte=${dte}`, header)
      .then((response) => {
        dispatch(getNotification(response.data.result.notificationList));
        dispatch(sevenDaysLoading(false));
        // dispatch(profileLoading(false))

      })
      .catch((error) => {
        dispatch(sevenDaysLoading(false));
        // dispatch(profileLoading(false))
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function countUnreadMessages(url, header) {
  return (dispatch) => {
    // dispatch(profileLoading(true));
    dispatch(countUnreadedMessages(0));
    axios
      .get(BASEURL + url, header)
      .then((response) => {
        dispatch(countUnreadedMessages(response.data.result.count));
        //    dispatch(profileLoading(false));
      })
      .catch((error) => {
        // dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}
