import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASEURL } from '../../../constant';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consent_body: ''
    };

    this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
  }

  async getPrivacyPolicy() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getPrivacyPolicy'
      );
      console.log(response);

      console.log({ response });
      if (response.data.length)
        this.setState({
          consent_body: response.data[0].consent_body
        });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className='privacy-page'>
        <div className="container ">
          <div className="mb-4">
            <h2 className="text-center heading">Privacy Policy</h2>
          </div>

          <div
            contentEditable="false"
            className="msg-text"
            dangerouslySetInnerHTML={{ __html: this.state.consent_body }}
          >
          </div>
          {localStorage.getItem('token') ? (
            <>
              <div class="dashboard-btn">
                <a href="/" class="float-md-right">
                  <button
                    type="submit"
                    className="btn float-md-right new_btn_save_bt m-0"
                    style={{
                      marginRight: '0% !important'
                    }}
                  >
                    Back to Dashboard
                  </button>
                </a>

              </div>
            </>
          ) : (
            <></>
          )}
        </div>

      </div>
    );
  }

  componentDidMount() {
    this.getPrivacyPolicy();
    console.log('hi');
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => { };

const mapDispatchToProps = dispatch => { };

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
