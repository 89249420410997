import React, { Component } from 'react';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { searchEvent } from '../Actions/event-setup';
import axios from 'axios';
import { BASEURL } from '../constant';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import moment from 'moment';
import { Link, Navigate } from 'react-router-dom';
import createHistory from 'history/createHashHistory';
import authorImg from '../Utils/Assets/OTR_Default-avatar_v3.png';

const globalHistory = new createHistory();
class Searchbox extends PureComponent {
  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      data: [],
      value: null,
      openSuggestion: this.props.isSuggestionOpen,
      giveaway: '',
      release: '',
      storeNameofGiveawayvalue: '',
      isOpenPrivacy: false,
      duplicateGiveaway: '',
      dupli: '',
      giveawaytitle: '',
      storeGiveawayId: '',
      releasParty: false,
      duplicateReleaseParty: '',
      storeReleasePartyId: '',
      releasePartytitle: '',
      checkforGivewaylength: false,
      checkforReleaselength: false
    };
  }

  onChange = e => {
    if (e.target.value.trim().length > 1) {
      this.loadOptions(e.target.value);
    } else {
      this.setState({ data: [] });
    }

    // if(this.props.attributes.name === 'Name of Giveaway')
    // {
    //   this.setState({storeNameofGiveawayvalue})
    // }

    this.props.attributes.value = e.target.value;
    this.props.attributes.id = null;
    this.setState({ value: e.target.value, openSuggestion: true });
    this.props.onChangeTextHandler(
      { label: e.target.value, value: null },
      this.props.attributes.name
    );
  };

  isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  togglePrivacyModal = history => {
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };

  toggleReleasePartyModal = () => {
    this.setState({ releasParty: !this.state.releasParty });
  };
  setValue = selectedOption => {
    if (selectedOption) {
      this.props.attributes.value = selectedOption.label;
      this.props.attributes.id = selectedOption.value;

      this.setState({ value: selectedOption.label, openSuggestion: false });
      this.props.onChangeTextHandler(
        selectedOption,
        this.props.attributes.name
      );
    }
    this.setState({ isOpenPrivacy: false });
  };

  setValueGiveaway = (selectedOption, eventId) => {
    if (selectedOption) {
      this.props.attributes.value = selectedOption;
      this.props.attributes.id = eventId;

      this.setState({
        value: selectedOption,
        openSuggestion: false,
        giveaway: []
      });
      this.props.onChangeTextHandler(
        selectedOption,
        this.props.attributes.name
      );
    }
    this.setState({ isOpenPrivacy: false, duplicateGiveaway: [] });
  };

  setValueReleaseParty = (selectedOption, eventId) => {
    if (selectedOption) {
      this.props.attributes.value = selectedOption;
      this.props.attributes.id = eventId;

      this.setState({
        value: selectedOption,
        openSuggestion: false,
        release: []
      });
      this.props.onChangeTextHandler(
        selectedOption,
        this.props.attributes.name
      );
    }
    this.setState({ releasParty: false });
  };

  getEventDetails = async (eventId, name) => {
    let response = await axios.get(
      BASEURL +
        `/api/v1/events/giveaway_duplicate/${localStorage.getItem(
          'userId'
        )}/${name}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );

    if (response.data.result.length === 1) {
      this.setState({ checkforGivewaylength: true });
    } else {
      this.setState({ checkforGivewaylength: true });
    }
    if (response.data.result.length === 1) {
      this.setState({
        checkforGivewaylength: true,
        duplicateGiveaway: response.data.result[0],
        isOpenPrivacy: true,
        giveawaytitle: name,
        storeGiveawayId: eventId
      });
    } else {
      this.setState({
        checkforGivewaylength: false,
        duplicateGiveaway: response.data.result,
        isOpenPrivacy: true,
        giveawaytitle: name,
        storeGiveawayId: eventId
      });
    }
  };

  getReleaseParty = async (eventId, name) => {
    let response = await axios.get(
      BASEURL +
        `/api/v1/events/release_duplicate/${localStorage.getItem(
          'userId'
        )}/${name}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    if (response.data.result.length === 1) {
      this.setState({ checkforReleaselength: true });
    } else {
      this.setState({ checkforReleaselength: true });
    }

    if (response.data.result.length === 1) {
      this.setState({
        checkforReleaselength: true,
        duplicateReleaseParty: response.data.result[0],
        releasParty: true,
        storeReleasePartyId: eventId,
        releasePartytitle: name
      });
    } else {
      this.setState({
        checkforReleaselength: false,
        duplicateReleaseParty: response.data.result,
        releasParty: true,
        storeReleasePartyId: eventId,
        releasePartytitle: name
      });
    }

    // this.setState({
    //   duplicateReleaseParty: response.data.result[0],
    //   releasParty: true,
    //   storeReleasePartyId: eventId,
    //   releasePartytitle: name
    // });
  };

  loadOptions = async inputValue => {
    if (this.props.attributes.name == 'coauthor') {
      this.props.searchTitle(`/api/v1/user_search?searchString=${inputValue}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
    } else if (this.props.attributes.name == 'Name of Giveaway') {
      let data = await axios.get(
        BASEURL +
          `/api/v1/events/giveaway_search?searchString=${inputValue}&userId=${localStorage.getItem(
            'userId'
          )}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );
      let myData = data.data.result
        .map(ele => ele)
        .filter(el =>
          el.event_name
            ? el.eventId &&
              el.event_name['Name of Giveaway']
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) > -1
            : ''
        );

      //  console.log("console the data------------------>>> ",  data.data.result)
      this.setState({ giveaway: myData });
    } else if (this.props.attributes.name == 'release-party-event-name') {
      let data = await axios.get(
        BASEURL +
          `/api/v1/events/releaseparty_search?searchString=${inputValue}&userId=${localStorage.getItem(
            'userId'
          )}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );
      let myData = data.data.result
        .map(ele => ele)
        .filter(el =>
          el.event_name
            ? el.eventId &&
              el.event_name['release-party-event-name']
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) > -1
            : ''
        );

      this.setState({ release: myData });
    } else {
      this.props.searchTitle(
        `/api/v1/event_search?searchString=${inputValue}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } }
      );
    }
  };
  redirection = () => {
    this.toggleReleasePartyModal();
    this.setState({ releasParty: false });
  };

  render() {
    // console.log("this is giveaway search result---->>> ", this.state.giveaway)
    const { selectedOption } = this.state;

    const event_name = {
      2: 'Giveaway',
      4: 'Release Party'
    };

    return (
      <>
        {/* <AsyncSelect
        isClearable
        cacheOptions
        className={this.props.attributes.className} 
        isSearchable={true}
        isLoading={true}
        value={selectedOption}
        placeholder={this.props.attributes.placeholder} 
        loadOptions={this.loadOptions}
        onChange={(e) => {
            this.props.onChangeTextHandler(e,this.props.attributes.name); this.onChange(e);} }
          /> */}
        <Modal
          isOpen={this.state.isOpenPrivacy}
          toggle={this.togglePrivacyModal.bind()}
          id="myModal"
          className="event-cover-popup modal-md event-setup-popup giveaway-popup"
        >
          <div class="model_cover">
            {/* <ModalHeader
              toggle={this.togglePrivacyModal.bind()}
              className=""
            ></ModalHeader> */}
            <ModalBody className="event_body">
              <div className="logo-blast">
                {/* <img src={logo} alt="logo" /> */}
              </div>
              <div className="setup-popup-title">
                <h3>POSSIBLE DUPLICATE ALERT!</h3>
              </div>
              <p class="mb-2">
                You have already added (an) event(s) with same name, here is the
                info:
              </p>
              {this.state.checkforGivewaylength === true ? (
                <>
                  <div class="event-popup-body">
                    <ul class="">
                      <li>
                        Event Name: {'  '}
                        <b>
                          {this.state.giveawaytitle}{' '}
                          {event_name[localStorage.getItem('event_type')]}
                        </b>
                      </li>
                      <li>
                        Book Release Title:{'  '}
                        <b>{this.state.duplicateGiveaway.title}</b>
                      </li>
                      <li>
                        Author: {'   '}
                        <b>{this.state.duplicateGiveaway.name}</b>
                      </li>
                      <li>
                        Hosted by:{'  '}
                        <b>{this.state.duplicateGiveaway.hosted}</b>
                      </li>
                      <li>
                        Start date:{'  '}
                        <b>
                          {moment(
                            this.state.duplicateGiveaway.start_date
                          ).format('MMMM DD, YYYY')}
                        </b>
                      </li>
                      <li>
                        <span>Event link:</span>
                        <span>
                          {' '}
                          <Link
                            to={{
                              pathname: `/dashboard/linked?duplicate=${this.state.storeGiveawayId}`
                            }}
                            target="_blank"
                            onClick={() =>
                              this.setState({ isOpenPrivacy: false })
                            }
                          >
                            {/* {window.location.href} */}
                            {/* {window.location.host} */}
                            {/* /#/dashboard/my-events */}
                            www.coredev.ontargetromance.com/{'...'}
                          </Link>
                        </span>
                      </li>
                    </ul>
                    <div class="mb-3">
                      <b>
                        To update, access the event via My Event tab, open, then
                        click edit.
                      </b>
                    </div>
                    <div>
                      <em>
                        *There is no limit to the number of giveaways you can
                        add tied to a book release. When adding multiple we
                        encourage you to customize names to differentiate them.
                      </em>
                    </div>
                    <div class="mt-2">
                      <button
                        className="btn btn-filled "
                        id="Next"
                        type="button"
                        //disabled={this.state.disabled}
                        //onClick={() => this.togglePrivacyModal()}
                        onClick={() =>
                          this.setValueGiveaway(
                            this.state.giveawaytitle,
                            this.state.storeGiveawayId
                          )
                        }
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {this.state.duplicateGiveaway &&
                    this.state.duplicateGiveaway.map((el, i) => (
                      <>
                        <div class="event-popup-body">
                          <ul class="">
                            <li>
                              Event Name: {'  '}
                              <b>
                                {this.state.giveawaytitle}{' '}
                                {event_name[localStorage.getItem('event_type')]}
                              </b>
                            </li>

                            <li>
                              Book Release Title:{'  '}
                              <b>{el.title}</b>
                            </li>
                            <li>
                              Author: {'   '}
                              <b>{el.name}</b>
                            </li>
                            <li>
                              Hosted by:{'  '}
                              <b>{el.hosted}</b>
                            </li>
                            <li>
                              Start date:{'  '}
                              <b>
                                {moment(el.start_date).format('MMMM DD, YYYY')}
                              </b>
                            </li>
                            <li>
                              <span>Event link:</span>
                              <span>
                                {' '}
                                <Link
                                  to={{
                                    pathname: `/dashboard/linked?duplicate=${el.eventId}`
                                  }}
                                  target="_blank"
                                  onClick={() =>
                                    this.setState({ isOpenPrivacy: false })
                                  }
                                >
                                  {/* {window.location.href} */}
                                  {/* {window.location.host} */}
                                  {/* /#/dashboard/my-events */}
                                  www.coredev.ontargetromance.com/{'...'}
                                </Link>
                              </span>
                            </li>
                          </ul>

                          <div className="borderline"></div>
                          {i === this.state.duplicateGiveaway.length - 1 && (
                            <>
                              <div class="mb-3">
                                <b>
                                  To update, access the event via My Event tab,
                                  open, then click edit.
                                </b>
                              </div>
                              <div>
                                <em>
                                  *There is no limit to the number of giveaways
                                  you can add tied to a book release. When
                                  adding multiple we encourage you to customize
                                  names to differentiate them.
                                </em>
                              </div>
                              <div class="mt-2">
                                <button
                                  className="btn btn-filled "
                                  id="Next"
                                  type="button"
                                  //disabled={this.state.disabled}
                                  //onClick={() => this.togglePrivacyModal()}
                                  onClick={() =>
                                    this.setValueGiveaway(
                                      this.state.giveawaytitle,
                                      this.state.storeGiveawayId
                                    )
                                  }
                                >
                                  Ok
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ))}
                </>
              )}
            </ModalBody>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.releasParty}
          toggle={this.toggleReleasePartyModal.bind()}
          id="myModal"
          className="event-cover-popup modal-md event-setup-popup release-popup"
        >
          <div class="model_cover">
            <ModalHeader
              toggle={this.toggleReleasePartyModal.bind()}
              className=""
            ></ModalHeader>
            <ModalBody className="event_body">
              <div className="logo-blast">
                {/* <img src={logo} alt="logo" /> */}
              </div>
              <div className="setup-popup-title">
                <h3>POSSIBLE DUPLICATE ALERT!</h3>
              </div>
              <p class="mb-2">
                You have already added (an) event(s) with same name, here is the
                info:
              </p>
              {this.state.checkforReleaselength === true ? (
                <>
                  <div class="event-popup-body">
                    <ul class="">
                      <li>
                        Event Name: {'  '}
                        <b>
                          {this.state.releasePartytitle}{' '}
                          {event_name[localStorage.getItem('event_type')]}
                        </b>
                      </li>
                      <li>
                        Book Release Title:{'  '}
                        <b>{this.state.duplicateReleaseParty.title}</b>
                      </li>
                      <li>
                        Author: {'   '}
                        <b>{this.state.duplicateReleaseParty.name}</b>
                      </li>
                      <li>
                        Hosted by:{'  '}
                        <b>{this.state.duplicateReleaseParty.hosted}</b>
                      </li>
                      <li>
                        Start date:{'  '}
                        <b>
                          {moment(
                            this.state.duplicateReleaseParty.start_date
                          ).format('MMMM DD, YYYY')}
                        </b>
                      </li>
                      <li>
                        <span>Event link:</span>
                        <span>
                          {' '}
                          <Link
                            to={{
                              pathname: `/dashboard/linked?duplicate=${this.state.storeReleasePartyId}`
                            }}
                            target="_blank"
                            onClick={() =>
                              this.setState({ releasParty: false })
                            }
                          >
                            {/* {window.location.href} */}
                            {/* {window.location.host} */}
                            {/* /#/dashboard/my-events */}
                            www.coredev.ontargetromance.com{'...'}
                          </Link>
                        </span>
                      </li>
                    </ul>
                    <div class="mb-3">
                      <b>
                        To update, access the event via My Event tab, open, then
                        click edit.
                      </b>
                    </div>

                    <div>
                      <em>
                        *There is no limit to the number of release parties you
                        can add tied to a book release. When adding multiple we
                        encourage you to customize names to differentiate them.
                      </em>
                    </div>
                    <div class="mt-2">
                      <button
                        className="btn btn-filled "
                        id="Next"
                        type="button"
                        //disabled={this.state.disabled}
                        //onClick={() => this.togglePrivacyModal()}
                        onClick={() =>
                          this.setValueReleaseParty(
                            this.state.releasePartytitle,
                            this.state.storeReleasePartyId
                          )
                        }
                      >
                        Ok
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {this.state.duplicateReleaseParty &&
                    this.state.duplicateReleaseParty.map((el, i) => (
                      <>
                        <div class="event-popup-body">
                          <ul class="">
                            <li>
                              Event Name: {'  '}
                              <b>
                                {this.state.releasePartytitle}{' '}
                                {event_name[localStorage.getItem('event_type')]}
                              </b>
                            </li>

                            <li>
                              Book Release Title:{'  '}
                              <b>{el.title}</b>
                            </li>
                            <li>
                              Author: {'   '}
                              <b>{el.name}</b>
                            </li>
                            <li>
                              Hosted by:{'  '}
                              <b>{el.hosted}</b>
                            </li>
                            <li>
                              Start date:{'  '}
                              <b>
                                {moment(el.start_date).format('MMMM DD, YYYY')}
                              </b>
                            </li>
                            <li>
                              <span>Event link:</span>
                              <span>
                                {' '}
                                <Link
                                  to={{
                                    pathname: `/dashboard/linked?duplicate=${this.state.storeReleasePartyId}`
                                  }}
                                  target="_blank"
                                  onClick={() =>
                                    this.setState({ releasParty: false })
                                  }
                                >
                                  {/* {window.location.href} */}
                                  {/* {window.location.host} */}
                                  {/* /#/dashboard/my-events */}
                                  www.coredev.ontargetromance.com{'...'}
                                </Link>
                              </span>
                            </li>
                          </ul>

                          <div className="borderline"></div>
                          {i ===
                            this.state.duplicateReleaseParty.length - 1 && (
                            <>
                              <div class="mb-3">
                                <b>
                                  To update, access the event via My Event tab,
                                  open, then click edit.
                                </b>
                              </div>

                              <div>
                                <em>
                                  *There is no limit to the number of release
                                  parties you can add tied to a book release.
                                  When adding multiple we encourage you to
                                  customize names to differentiate them.
                                </em>
                              </div>
                              <div class="mt-2">
                                <button
                                  className="btn btn-filled "
                                  id="Next"
                                  type="button"
                                  //disabled={this.state.disabled}
                                  //onClick={() => this.togglePrivacyModal()}
                                  onClick={() =>
                                    this.setValueReleaseParty(
                                      this.state.releasePartytitle,
                                      this.state.storeReleasePartyId
                                    )
                                  }
                                >
                                  Ok
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ))}
                </>
              )}
            </ModalBody>
          </div>
        </Modal>

        <div class="picomplete" style={{ width: '100%' }} ref={this.wrapperRef}>
          <label>
            {this.props.attributes.placeholder === 'Book Title'
              ? 'Book Release Title*'
              : this.props.attributes.placeholder === 'Co-author(s)'
              ? 'Co-author(s) (if applicable)'
              : this.props.attributes.placeholder === 'Release Party event name'
              ? 'Release Party event name (should include book title)*'
              : this.props.attributes.placeholder === 'Name of Giveaway'
              ? 'Name of Giveaway (should include book title)*'
              : this.props.attributes.placeholder}
            :
          </label>
          <input
            type="text"
            className={this.props.attributes.className}
            placeholder={this.props.attributes.placeholder}
            value={this.state.value}
            onChange={e => {
              this.onChange(e);
            }}
            required={this.props.attributes.validation}
            readOnly={this.props.readOnly}
          />
          <div
            className={`picomplete-items ${
              this.props.attributes.name === 'coauthor' ? 'author-names' : ''
            }`}
          >
            {this.state.data.length && this.state.openSuggestion
              ? this.state.data.length > 0 &&
                this.state.data.map(suggestion => (
                  <>
                    {console.log(
                      'gdg-------------------------------------------------->>> ',
                      this.isJsonString(suggestion.user_details)
                        ? JSON.parse(suggestion.user_details)[3].value
                        : null
                    )}
                    {/* {console.log("hiiiiiiiiiiiiiiiiiii------------>> ", suggestion.user_details ? JSON.parse(suggestion.user_details)[2].value : null)} */}
                    {suggestion.name === 'Other' ? (
                      ''
                    ) : (
                      <div
                        key={JSON.stringify(suggestion)}
                        class="picomplete-item"
                        onClick={() => this.setValue(suggestion)}
                      >
                        {/* {console.log("typeof suggestion.user_details---> *******", JSON.parse(suggestion.user_details)[2].value )} */}
                        {this.props.attributes.name === 'coauthor' ? (
                          <div className="authorAvatar">
                            <img
                              src={
                                this.isJsonString(suggestion.user_details)
                                  ? localStorage.getItem('baseUrl') +
                                    JSON.parse(suggestion.user_details)[3].value
                                  : authorImg
                              }
                              alt="avatar"
                            />
                          </div>
                        ) : (
                          ''
                        )}
                        {suggestion.name
                          ? `${suggestion.label}  (${
                              suggestion.fname
                                ? suggestion.fname
                                : suggestion.name
                                ? suggestion.name
                                : ''
                            }
                          ${
                            suggestion.user_details.length > 0
                              ? this.isJsonString(suggestion.user_details)
                                ? JSON.parse(suggestion.user_details)[2].value
                                : null
                              : ''
                          } 
                          ${suggestion.lname ? suggestion.lname : ''})`
                          : // : suggestion.label
                            `${suggestion.fname} ${
                              suggestion.user_details.length > 0
                                ? this.isJsonString(suggestion.user_details)
                                  ? JSON.parse(suggestion.user_details)[2].value
                                  : null
                                : ''
                            } ${suggestion.lname}`}
                      </div>
                    )}
                  </>
                ))
              : null}
          </div>
          {/* {console.log("this.state.value---->> ", {value: this.state.value, giveway: this.state.giveaway})} */}
          <div class="picomplete-items" style={{ width: '100%' }}>
            {this.state.giveaway &&
            this.state.giveaway.length &&
            this.state.value
              ? this.state.giveaway.map(suggestion => (
                  <div
                    class="picomplete-item"
                    onClick={() => {
                      this.getEventDetails(
                        suggestion.eventId,
                        suggestion.event_name['Name of Giveaway'],
                        this.props.newTab
                      );
                    }}
                  >
                    {suggestion.event_name['Name of Giveaway']} {'  '}(host:{' '}
                    {suggestion.hosted.trim()}) [
                    {moment(suggestion.start_date).format('MMMM-DD-YYYY')}]
                  </div>
                ))
              : null}
            {this.state.release.length && this.state.value
              ? this.state.release.map(suggestion => (
                  <div
                    class="picomplete-item"
                    onClick={() => {
                      this.getReleaseParty(
                        suggestion.eventId,
                        suggestion.event_name['release-party-event-name']
                      );
                    }}
                  >
                    {suggestion.event_name['release-party-event-name']}
                    {'  '} (host:{'  '} {suggestion.hosted.trim()}) [
                    {moment(suggestion.start_date).format('MMMM-DD-YYYY')}]
                  </div>
                ))
              : null}
          </div>
        </div>
      </>
    );
  }

  componentDidUpdate() {
    if (!this.props.attributes.value) {
      this.setState({
        value: ''
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.searchData && this.state.openSuggestion) {
      let changedData = props.searchData;
      if (changedData[0] === 0) {
        changedData = [];
      }
      // console.log("this is the changed data------->>>>>>> ", changedData)
      this.setState({ data: changedData, openSuggestion: true });
    }
  }

  componentDidMount() {
    if (localStorage.getItem('mode') == 'edit') {
      this.setState({ value: this.props.attributes.value });
      this.props.onChangeTextHandler(
        { value: this.props.attributes.id, label: this.props.attributes.value },
        this.props.index
      );
    }

    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  // on click outside the div
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.openSuggestion) {
        this.setState({ openSuggestion: false });
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.profileLoading,
    searchData: state.searchTitle,
    newTab: state.addTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchTitle: (url, header) => dispatch(searchEvent(url, header))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Searchbox);
