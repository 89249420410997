import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { BASEURL } from '../../constant';
import { isAndroid, isIOS } from 'react-device-detect';

class CookieModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      consent_type: 'Cookie Policy',
      content: '',
      version: '',
      id: '',
      source: 'Website',
      ipAddress: '',
      reason: 'Cookie-pop-up 1/year',
      userId: localStorage.id ? localStorage.id : ''
    };
    this.getCookiePolicy = this.getCookiePolicy.bind(this);
    this.getIpAddress = this.getIpAddress.bind(this);
  }

  handleSubmit = async e => {
    e.preventDefault();

    const { title, content } = this.state;
    console.log({ token: localStorage.token });
    try {
      await axios.post(BASEURL + '/api/v1/users/postUserConsent', this.state, {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      });
    } catch (e) {
      console.log(e.response);
    }
  };

  async getCookiePolicy() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getCookiePolicy'
      );

      if (response.data.length)
        this.setState({
          content: response.data[0].consent_body,
          version: response.data[0].version,
          id: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getIpAddress() {
    try {
      console.log('ip');
      //const response = await axios.get("https://geolocation-db.com/json/");
      const response = await axios.get('https://api.ipify.org/?format=json');
      this.setState({ ipAddress: response.data.ip });
    } catch (e) {
      console.log(e);
    }
  }

  //===================== RENDER ==========================

  render() {
    console.log(this.state);
    return (
      <>
        <Modal
          isOpen={this.state.disabled ? false : this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h3 className="text-center p-0"> Cookie Policy </h3>
          </ModalHeader>
          <ModalBody>
            <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span>
            <button
              className="btn btn-filled align-self-end ml-auto"
              id="Next"
              type="button"
              onClick={e => {
                this.props.toggle();
                this.handleSubmit(e);
              }}
              disabled={this.state.disabled}
            >
              I agree
            </button>
          </ModalBody>
        </Modal>
      </>
    );
  }
  componentDidMount() {
    this.getCookiePolicy();
    this.getIpAddress();

    if (isIOS) return this.setState({ source: 'IOS' });
    if (isAndroid) return this.setState({ source: 'Android' });
  }
}

export default CookieModal;
