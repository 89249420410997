import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmail } from '../../Actions/index';
import Layout from '../layout/layout';
import '../Landing/landing.css';
import { Helmet } from 'react-helmet';
import { BASEURL } from '../../constant';
import Axios from 'axios';
import { notify } from 'react-notify-toast';

class ConverSocialMediaAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      username: '',
      isSubmitted: false,
      isSocailSubmitte: false
    };
    // this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    this.setState({
      [name]: target.value
    });
  }

  onSubmitFormHandler = async e => {
    try {
      e.preventDefault();

      let data = {
        email: this.state.email,
        display_name: this.state.username
      };

      const response = await Axios.post(`${BASEURL}/api/v1/updategoogle`, data);

      console.log('response=======', response.data.message);

      if (response.status === 200) {
        this.setState({ email: '' });
        notify.show(
          'Please check your email to set your password and complete OTR login setup.',
          'custom',
          2000,
          {
            background: '#d4edda',
            text: '#155724',
            borderColor: '#c3e6cb'
          }
        );
      } else {
        notify.show('something went wrong', 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        notify.show(`${err.response.data.message}`, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      } else {
        notify.show(
          'Something went wrong Please try again after some time.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
      }
    }
  };
  onSubmitChange = async e => {
    e.preventDefault();
    this.setState({ isSocailSubmitte: true });
  };

  render() {
    return (
      <>
        <Helmet>
          <html className="remove-header" lang="en" />
        </Helmet>
        <Helmet>
          <html className="remove-footer" lang="en" />
        </Helmet>
        <Layout>
          <div className="forgot-password">
            <div style={{ backgroundColor: '#1f1f1f' }}>
              <div className="container-fluid">
                <div className="row mainleft">
                  <div className="main_right author_profile convert-social-page">
                    <div className="setup_p_hed">
                      {!this.state.isSocailSubmitte ? (
                        <>
                          {' '}
                          <h4>
                            Google or Facebook username used when joining OTR:
                          </h4>
                        </>
                      ) : (
                        <>
                          <h4>Email address (to be used for OTR login):</h4>
                        </>
                      )}
                    </div>

                    <span>
                      {!this.state.isSocailSubmitte ? (
                        <>
                          <form
                            className="form_custom"
                            onSubmit={e => this.onSubmitChange(e)}
                          >
                            <div className="code_main">
                              <div className="signup_text"></div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="username"
                                    className="form-control email_s"
                                    placeholder="Please Enter Username"
                                    onChange={this.handleChange.bind(this)}
                                    required
                                    autoFocus
                                  />
                                </div>

                                <div className="form-group btnSection">
                                  <button
                                    className="btn btn-filled"
                                    type="submit"
                                  >
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        ''
                      )}
                      {this.state.isSocailSubmitte ? (
                        <>
                          {' '}
                          <form
                            className="form_custom"
                            onSubmit={e => this.onSubmitFormHandler(e)}
                          >
                            <div className="code_main">
                              <div class="signup_text"></div>
                              <div className="col-md-5">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="email"
                                    className="form-control email_s"
                                    placeholder="Please Enter Email"
                                    onChange={this.handleChange.bind(this)}
                                    value={this.state.email}
                                    required
                                    autoFocus
                                  />
                                </div>
                                <p className="socMsg">
                                  Please check your email to set your password.
                                </p>
                                <div className="form-group btnSection">
                                  <button
                                    className="btn btn-filled"
                                    type="submit"
                                  >
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }

  componentDidMount() {}
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  sendEmail: (url, data) => dispatch(getEmail(url, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConverSocialMediaAccount);
