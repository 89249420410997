import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Layout from '../layout/layout';
import defaulUserImg from '../../Utils/Assets/OTR_Default-avatar_v3.png';
import moment from 'moment';
import {
  getProfileDetails,
  getUserDetailsByPR,
  previewProfile,
  getFollowingStatus,
  followUser,
  unFollowUser
} from '../../Actions/profile-setup';
import { getAllTasks } from '../../Actions/dailytask';

import '../../App.css';
import BlankPage from '../../Components/blankPage';
import Loader from '../../Components/Loader/loader';
import createHistory from 'history/createHashHistory';

const globalHistory = new createHistory();

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: [],
      followingCalled: false
    };
  }

  getFollowingStatus(id) {
    if (!this.state.followingCalled) {
      this.setState(
        { followingCalled: true },
        this.props.getUserFollowingStatus(id)
      );
    }
  }

  //= ==================== RENDER ==========================
  render() {
    let fullName = null;

    if (this.props.userProfile.length) {
      this.getFollowingStatus(this.props.userProfile[0].id);

      const profileData = JSON.parse(
        this.props.userProfile[0].profile_details_with_values
      );

      let pathImg = '';
      if (profileData) {
        const objImg = profileData.find(_d => _d.profile);

        if (objImg) {
          pathImg = `${localStorage.getItem('baseUrl')}${
            objImg ? objImg.profile.split('\\').join('/') : ''
          }`;
        } else {
          pathImg = '';
        }
      } else {
        pathImg = '';
      }

      // let obj = {};

      // profileData.forEach(el => {
      //   const key = Object.keys(el)[0];

      //   obj[key] = el[key];
      // });
      const userdetails =
        this.props.userProfile &&
        JSON.parse(this.props.userProfile[0].user_details);
      const FName = userdetails[0].value;
      const MName = userdetails[2].value;
      const LName = userdetails[1].value;

      if (this.props.userProfile[0].type_id !== 5) {
        fullName = `${FName} ${MName} ${LName}`;
      } else {
        fullName = `${FName}`;
      }
      let obj = {};
      profileData.forEach(el => {
        const key = Object.keys(el)[0];
        console.log({ obj });
        if (obj[key]) {
          if (Array.isArray(obj[key])) {
            const prevValue = [...obj[key]];
            prevValue.push(el[key]);
            obj[key] = [...prevValue];
          } else {
            obj[key] = [obj[key], el[key]];
          }
        } else {
          obj[key] = el[key];
        }
      });
      if (obj.Author) {
        obj.Author = `${FName} ${MName} ${LName}`;
      }
      // console.log('profile data=-----', this.props.userProfile[0].id);

      // console.log("thiis is Author:: ", obj)

      const VCOLength = obj[
        'Currently featured on Vendor Cancellation Openings (VCO) list'
      ]
        ? obj[
            'Currently featured on Vendor Cancellation Openings (VCO) list'
          ][0].filter(
            el =>
              new Date().getTime() >= new Date(Object.values(el)[0]).getTime()
          )
        : '';

      console.log('profileDataprofileData', userdetails);

      return (
        <>
          {/* <Helmet>
          <html className='remove-header' lang="en" />
        </Helmet> */}
          <Layout>
            <div className="profile-detail-page hhhhhhhhhhhhhhhhhh">
              <div className="container">
                <h2
                  // className="profile_title heading"
                  // style={{
                  //   paddingTop: pathImg !== '' ? '250px' : '0px',
                  //   textAlign: 'center'
                  // }}
                  className="text-center"
                >
                  Profile
                </h2>

                <div className="model_cover profile_detail all-user-profile">
                  <div className="eventModal">
                    {console.log('subh', localStorage.getItem('membershipId'))}
                    {pathImg !== '' ? (
                      <div
                        className={`profile_image gg ${
                          pathImg
                            ? localStorage.getItem('membershipId') === '1' ||
                              localStorage.getItem('membershipId') === '8'
                              ? 'authorImg'
                              : localStorage.getItem('membershipId') === '4'
                              ? 'vendorImg'
                              : localStorage.getItem('membershipId') === '5'
                              ? 'prFirmImg'
                              : localStorage.getItem('membershipId') === '6'
                              ? 'bloggerImg'
                              : ''
                            : ''
                        }`}
                        style={{
                          // backgroundImage: pathImg !== '' ? `url(${pathImg})` : '',
                          backgroundSize: pathImg !== '' ? 'contain' : '',
                          backgroundRepeat: 'no-repeat',
                          height: pathImg !== '' ? '250px' : '100px',
                          width: '100%',
                          backgroundPositionX: 'center'
                        }}
                      >
                        <img src={pathImg !== '' ? pathImg : ''} alt="img" />
                      </div>
                    ) : (
                      ''
                    )}
                    {/* <div
                      className="profile_image ddd"
                      style={{
                        backgroundImage:
                          pathImg !== '' ? `url(${pathImg})` : '',
                        backgroundSize: pathImg !== '' ? 'contain' : '',
                        backgroundRepeat: 'no-repeat',
                        height: pathImg !== '' ? '250px' : '100px',
                        width: '100%',
                        backgroundPositionX: 'center'
                      }}
                    /> */}
                  </div>
                  <div className="event_body">
                    <ul>
                      {this.props.userProfile[0].membership_id == 8 ||
                      this.props.userProfile[0].membership_id == 1 ? (
                        <>
                          <li class="bullet-remove">
                            Author: <b>{obj.Author}</b>
                          </li>
                          <li class="bullet-remove">
                            Contact:{''} <b>{obj.Contact}</b>
                          </li>
                          {obj.Goodreads && (
                            <li class="bullet-remove">
                              Goodreads:{' '}
                              <a
                                href={obj.Goodreads}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj.Goodreads}
                              </a>
                            </li>
                          )}
                          {obj.Website && (
                            <li class="bullet-remove">
                              Website:{' '}
                              <a
                                href={obj.Website}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj.Website}
                              </a>
                            </li>
                          )}
                          {obj['Social Media'] &&
                            !!obj['Social Media'][0].length && (
                              <li class="bullet-remove">
                                {' '}
                                <li>
                                  <b>Social media:</b>
                                </li>
                                {obj['Social Media'] &&
                                  obj['Social Media'][0].map(subObj => {
                                    return (
                                      <li className="more-profile">
                                        <a
                                          href={Object.values(
                                            subObj
                                          )[0].toString()}
                                          target="_blank"
                                          //className="ellipsis"
                                          rel="noreferrer"
                                        >
                                          {Object.keys(subObj)[0]}
                                        </a>
                                        &nbsp;|&nbsp;
                                      </li>
                                    );
                                  })}
                              </li>
                            )}
                          {obj['Reader Groups'] &&
                            !!obj['Reader Groups'][0].length && (
                              <li class="bullet-remove">
                                {' '}
                                <li>
                                  <b>Reader groups:</b>
                                </li>
                                {obj['Reader Groups'] &&
                                  obj['Reader Groups'][0].map(subObj => {
                                    return (
                                      <ul>
                                        <li>
                                          {Object.keys(subObj)[0]}:{' '}
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.values(subObj)[0].slice(
                                              0,
                                              25
                                            )}
                                            {'...'}
                                          </a>{' '}
                                        </li>
                                      </ul>
                                    );
                                  })}
                              </li>
                            )}
                          {obj['Purchase sites'] &&
                            !!obj['Purchase sites'][0].length && (
                              <li class="bullet-remove">
                                {' '}
                                <li>
                                  <b>Purchase sites:</b>
                                </li>
                                {obj['Purchase sites'] &&
                                  obj['Purchase sites'][0].map(subObj => {
                                    return (
                                      <ul>
                                        <li>
                                          {Object.keys(subObj)[0]}:{' '}
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.values(subObj)[0].slice(
                                              0,
                                              25
                                            )}
                                            {'...'}
                                          </a>{' '}
                                        </li>
                                      </ul>
                                    );
                                  })}
                              </li>
                            )}{' '}
                          <li class="bullet-remove">
                            <b>Other:</b>
                          </li>
                          {obj.Alias && (
                            <li class="bullet-remove">
                              Alias: <b>{obj.Alias}</b>
                            </li>
                          )}
                          {obj['Newsletter sign up'] && (
                            <li class="bullet-remove">
                              Newsletter sign up:{' '}
                              <a
                                href={obj['Newsletter sign up']}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj['Newsletter sign up'].slice(0, 25)}
                                {'...'}
                              </a>
                            </li>
                          )}
                          {obj['Master ARC list sign up'] && (
                            <li class="bullet-remove">
                              Master ARC list sign up:{' '}
                              <a
                                href={obj['Master ARC list sign up']}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj['Master ARC list sign up'].slice(0, 25)}
                                {'...'}
                              </a>
                            </li>
                          )}
                          {obj['Best site to reach me'] ? (
                            <li class="bullet-remove">
                              {/* Best site to reach me: {obj['Best site to reach me']}
                          {console.log(
                            'Best site to reach me',
                            obj['Best site to reach me']
                          )} */}
                              {obj['Best site to reach me'].split(':')[0] ===
                                'http' ||
                              obj['Best site to reach me'].split(':')[0] ===
                                'https' ? (
                                <>
                                  {' '}
                                  Best site to reach me:{' '}
                                  <a
                                    href={obj['Best site to reach me']}
                                    //className="ellipsis"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {obj['Best site to reach me'].slice(0, 25)}
                                    {'...'}
                                  </a>
                                </>
                              ) : (
                                <>
                                  Best site to reach me:{' '}
                                  {obj['Best site to reach me']}
                                </>
                              )}
                            </li>
                          ) : (
                            ''
                          )}{' '}
                          {/* {+localStorage.getItem('userId') !==
                            this.props.userProfile[0].id && (
                            <div className="col-md-12 text-center">
                              {this.props.userProfile[0].type_id == 1 ||
                              this.props.userProfile[0].type_id == 5 ||
                              this.props.userProfile[0].membership_id == 6 ? (
                                <>
                                  {!this.props.status && (
                                    <button
                                      className="btn new_btn_save_bt float-md-right"
                                      onClick={() => {
                                        // if (
                                        //   window.confirm(
                                        //     "You will be notified about this member's updates and their events."
                                        //   )
                                        // )
                                        this.onFollow(this);
                                      }}
                                    >
                                      FOLLOW
                                    </button>
                                  )}

                                  {this.props.status && (
                                    <button
                                      className="btn new_btn_save_bt float-md-right btn-hover btn-danger"
                                      onClick={() => {
                                        this.onUnFollow(this);
                                      }}
                                    >
                                      UNFOLLOW
                                    </button>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          )} */}
                        </>
                      ) : this.props.userProfile[0].membership_id == 6 ? (
                        <>
                          {obj.Blog && (
                            <li class="bullet-remove">
                              Blog: <b>{obj.Blog}</b>
                            </li>
                          )}

                          {/* {obj['Publicly display email on profile page?'] &&
                              obj['Publicly display email on profile page?'] ===
                                'Yes' ? (
                                <li class="bullet-remove">
                                  Contact:
                                  <b>{obj.Contact}</b>
                                </li>
                              ) : (
                                ''
                              )} */}
                          {obj.Contact && (
                            <li class="bullet-remove">
                              Contact:{''} <b>{obj.Contact}</b>
                            </li>
                          )}

                          {obj['Blog email'] && (
                            <li class="bullet-remove">
                              Blog email: <b>{obj['Blog email']}</b>
                            </li>
                          )}
                          {obj['Blog link'] && (
                            <li class="bullet-remove">
                              Blog link:{' '}
                              <a
                                href={obj['Blog link']}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj['Blog link']}
                              </a>
                            </li>
                          )}
                          {obj['Goodreads link'] && (
                            <li class="bullet-remove">
                              Goodreads link:{' '}
                              <a
                                href={obj['Goodreads link']}
                                target="_blank"
                                //className="ellipsis"
                                rel="noreferrer"
                              >
                                {obj['Goodreads link']}
                              </a>
                            </li>
                          )}

                          {obj['Social media'] &&
                            !!obj['Social media'][0].length && (
                              <li class="bullet-remove">
                                {' '}
                                <li>
                                  <b>Social Media:</b>
                                </li>
                                {obj['Social media'] &&
                                  obj['Social media'][0].map(subObj => {
                                    return (
                                      <li className="more-profile">
                                        <a
                                          href={Object.values(
                                            subObj
                                          )[0].toString()}
                                          target="_blank"
                                          //className="ellipsis"
                                          rel="noreferrer"
                                        >
                                          {Object.keys(subObj)[0]}
                                        </a>
                                        &nbsp;|&nbsp;
                                      </li>
                                    );
                                  })}
                              </li>
                            )}

                          {obj['Reader Groups'] &&
                            !!obj['Reader Groups'][0].length && (
                              <li class="bullet-remove">
                                {' '}
                                <li>
                                  <b>Fan groups:</b>
                                </li>
                                {obj['Reader Groups'] &&
                                  obj['Reader Groups'][0].map(subObj => {
                                    return (
                                      <ul>
                                        <li>
                                          {Object.keys(subObj)[0]}:{' '}
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.values(subObj)[0]}
                                          </a>{' '}
                                        </li>
                                      </ul>
                                    );
                                  })}
                              </li>
                            )}
                          {obj['Best site to interact with us'] && (
                            <li class="bullet-remove">
                              {obj['Best site to interact with us'].split(
                                ':'
                              )[0] === 'http' ||
                              obj['Best site to interact with us'].split(
                                ':'
                              )[0] === 'https' ? (
                                <>
                                  {' '}
                                  Best site to interact:{' '}
                                  <a
                                    href={obj['Best site to interact with us']}
                                    //className="ellipsis"
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {obj['Best site to interact with us']}
                                  </a>
                                </>
                              ) : (
                                <>
                                  Best site to interact:{' '}
                                  {obj['Best site to interact with us']}
                                </>
                              )}
                            </li>
                          )}
                        </>
                      ) : this.props.userProfile[0].membership_id == 5 ? (
                        <>
                          {' '}
                          <>
                            <li class="bullet-remove">
                              {/* PR Firm:{''} */}
                              Company: <b>{obj.Vendor}</b>
                            </li>
                            {obj['Services offered'] &&
                              !!obj['Services offered'].length && (
                                <li class="bullet-remove">
                                  Services offered:{' '}
                                  <b>{obj['Services offered']}</b>
                                </li>
                              )}
                            {obj.Contact && (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            )}
                            {obj.Website && (
                              <li class="bullet-remove">
                                Website:{' '}
                                <a
                                  href={obj['Website']}
                                  target="_blank"
                                  //className="ellipsis"
                                  rel="noreferrer"
                                >
                                  {obj.Website}
                                </a>
                              </li>
                            )}
                            {obj['Social media'] &&
                              !!obj['Social media'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    <b>Social media:</b>
                                  </li>
                                  {obj['Social media'] &&
                                    obj['Social media'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}
                            {obj['PR Fan groups'] &&
                              !!obj['PR Fan groups'][0].length && (
                                <li class="bullet-remove">
                                  {' '}
                                  <li>
                                    {/* <b>PR Fan groups: </b> */}
                                    <b>Fan groups: </b>
                                  </li>
                                  {obj['PR Fan groups'] &&
                                    obj['PR Fan groups'][0].map(subObj => {
                                      return (
                                        <li className="more-profile">
                                          <a
                                            href={Object.values(
                                              subObj
                                            )[0].toString()}
                                            target="_blank"
                                            //className="ellipsis"
                                            rel="noreferrer"
                                          >
                                            {Object.keys(subObj)[0]}
                                          </a>
                                          &nbsp;|&nbsp;
                                        </li>
                                      );
                                    })}
                                </li>
                              )}{' '}
                            {obj[
                              'Best site for AUTHORS to interact with us'
                            ] && (
                              <li class="bullet-remove">
                                {obj[
                                  'Best site for AUTHORS to interact with us'
                                ].split(':')[0] === 'http' ||
                                obj[
                                  'Best site for AUTHORS to interact with us'
                                ].split(':')[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site for AUTHORS to interact with us:{' '}
                                    <a
                                      href={
                                        obj[
                                          'Best site for AUTHORS to interact with us'
                                        ]
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site for AUTHORS to interact with us'
                                      ].slice(0, 15)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    {' '}
                                    Best site for AUTHORS to interact with us:{' '}
                                    <b>
                                      {' '}
                                      {
                                        obj[
                                          'Best site for AUTHORS to interact with us'
                                        ]
                                      }
                                    </b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj[
                              'Best site for bloggers/readers to interact with us'
                            ] && (
                              <li class="bullet-remove">
                                {obj[
                                  'Best site for bloggers/readers to interact with us'
                                ].split(':')[0] === 'http' ||
                                obj[
                                  'Best site for bloggers/readers to interact with us'
                                ].split(':')[0] === 'https' ? (
                                  <>
                                    {' '}
                                    Best site for bloggers/readers to interact:{' '}
                                    <a
                                      href={
                                        obj[
                                          'Best site for bloggers/readers to interact with us'
                                        ]
                                      }
                                      //className="ellipsis"
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {obj[
                                        'Best site for bloggers/readers to interact with us'
                                      ].slice(0, 15)}
                                      {'...'}
                                    </a>
                                  </>
                                ) : (
                                  <>
                                    Best site for bloggers/readers to interact:{' '}
                                    <b>
                                      {
                                        obj[
                                          'Best site for bloggers/readers to interact with us'
                                        ]
                                      }
                                    </b>
                                  </>
                                )}
                              </li>
                            )}
                            {obj['Are you accepting new clients'] && (
                              <li class="bullet-remove">
                                Are you accepting new clients:{' '}
                                <b>{obj['Are you accepting new clients']}</b>
                              </li>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] &&
                            obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ].length == '1' ? (
                              <>
                                <li class="bullet-remove">
                                  {' '}
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                              </>
                            ) : (
                              <>
                                <li class="bullet-remove">
                                  <b>Vendor Cancellation Openings (VCO):</b>
                                </li>
                                <ul>
                                  <li>No</li>
                                </ul>
                              </>
                            )}
                            {obj[
                              'Currently featured on Vendor Cancellation Openings (VCO) list'
                            ] ? (
                              <div className="mb-1">
                                {obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ][0].map(subObj => {
                                  return (
                                    <>
                                      {/*                             
                              <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                      {new Date().getTime() >=
                                      new Date(
                                        Object.values(subObj)[0]
                                      ).getTime() ? (
                                        <>
                                          {/* <ul>
                                        <li>No</li>
                                      </ul> */}
                                        </>
                                      ) : (
                                        <>
                                          <ul>
                                            {' '}
                                            <li>
                                              <b>{Object.keys(subObj)[0]}</b>:{' '}
                                              {moment(
                                                Object.values(subObj)[0]
                                              ).format('MMMM DD, YYYY')}
                                            </li>
                                          </ul>
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                                {VCOLength.length ==
                                  obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].length && (
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {obj['Recent clients/works'] && (
                              <li class="bullet-remove">
                                Recent clients/works:{' '}
                                {obj['Recent clients/works'].map(el => (
                                  <>
                                    {console.log('ell', el)}
                                    {el.split(':')[0] === 'http' ||
                                    el.split(':')[0] === 'https' ? (
                                      <ul>
                                        <li>
                                          <a
                                            href={el}
                                            //className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {el.length > 50
                                              ? el.slice(0, 50) + '...'
                                              : el}
                                          </a>
                                        </li>
                                      </ul>
                                    ) : (
                                      <ul>
                                        <li>{el}</li>
                                      </ul>
                                    )}
                                  </>
                                ))}
                              </li>
                            )}
                            <li class="bullet-remove">
                              Brief introduction:{' '}
                              <b>{obj['Brief introduction']}</b>
                            </li>
                            {obj.Bio && (
                              <>
                                <li class="bullet-remove">Bio:</li>{' '}
                                <li class="bullet-remove">{obj.Bio}</li>
                              </>
                            )}
                          </>
                        </>
                      ) : this.props.userProfile[0].membership_id == 4 ? (
                        <>
                          <div className="event-popup-body">
                            <ul className="remove-icon vendorModal-list 1">
                              {console.log('objobjobjobj', obj)}
                              <li class="bullet-remove">
                                Vendor: <b>{obj.Vendor}</b>
                              </li>
                              {obj['Services offered'] &&
                                !!obj['Services offered'].length && (
                                  <li class="bullet-remove">
                                    Services offered:{' '}
                                    <b>{obj['Services offered']}</b>
                                  </li>
                                )}
                              {obj.emailDisplay &&
                              obj.emailDisplay === 'Yes' ? (
                                <li class="bullet-remove">
                                  Contact:{''} <b>{obj.Contact}</b>
                                </li>
                              ) : (
                                ''
                              )}
                              {obj['Website'] && (
                                <li class="bullet-remove">
                                  Website:{' '}
                                  <a
                                    href={obj['Website']}
                                    target="_blank"
                                    //className="ellipsis"
                                    rel="noreferrer"
                                  >
                                    {obj.Website.slice(0, 25)}
                                    {'...'}
                                  </a>
                                </li>
                              )}

                              {obj['Social media'] &&
                                !!obj['Social media'][0].length && (
                                  <li class="bullet-remove">
                                    {' '}
                                    <li>
                                      <b>Social media:</b>
                                    </li>
                                    {obj['Social media'] &&
                                      obj['Social media'][0].map(subObj => {
                                        return (
                                          <li className="more-profile">
                                            <a
                                              href={Object.values(
                                                subObj
                                              )[0].toString()}
                                              target="_blank"
                                              //className="ellipsis"
                                              rel="noreferrer"
                                            >
                                              {Object.keys(subObj)[0]}
                                            </a>
                                            &nbsp;|&nbsp;
                                          </li>
                                        );
                                      })}
                                  </li>
                                )}
                              {console.log(
                                'working',
                                obj[
                                  'Currently featured on Vendor Cancellation Openings (VCO) list'
                                ] &&
                                  obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ].length
                              )}

                              {obj['Accepting new clients?'] && (
                                <li class="bullet-remove">
                                  Are you accepting new clients:{' '}
                                  <b>{obj['Accepting new clients?']}</b>
                                </li>
                              )}
                              {obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ] &&
                              obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ].length == '1' ? (
                                <>
                                  <li class="bullet-remove">
                                    {' '}
                                    <b>Vendor Cancellation Openings (VCO):</b>
                                  </li>
                                </>
                              ) : (
                                <>
                                  <li class="bullet-remove">
                                    <b>Vendor Cancellation Openings (VCO):</b>
                                  </li>
                                  <ul>
                                    <li>No</li>
                                  </ul>
                                </>
                              )}

                              {obj[
                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                              ] ? (
                                <div className="mb-1">
                                  {obj[
                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                  ][0].map(subObj => {
                                    return (
                                      <>
                                        {/* <li class="bullet-remove"> <b>Vendor Cancellation Openings (VCO):</b></li> */}
                                        {new Date().getTime() >=
                                        new Date(
                                          Object.values(subObj)[0]
                                        ).getTime() ? (
                                          <>
                                            {/* <ul>
                                            <li>No</li>
                                          </ul> */}
                                          </>
                                        ) : (
                                          <>
                                            <ul>
                                              {' '}
                                              <li>
                                                <b>{Object.keys(subObj)[0]}</b>:{' '}
                                                {moment(
                                                  Object.values(subObj)[0]
                                                ).format('MMMM DD, YYYY')}
                                              </li>
                                            </ul>
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                                  {VCOLength.length ==
                                    obj[
                                      'Currently featured on Vendor Cancellation Openings (VCO) list'
                                    ][0].length && (
                                    <ul>
                                      <li>No</li>
                                    </ul>
                                  )}
                                </div>
                              ) : (
                                ''
                              )}
                              {/* {obj['Profile summary'] && (
                           <li class="bullet-remove">
                           Profile summary:{' '}
                         <b>{obj['Profile summary']}</b> 
                         </li>
                        )} */}
                              {obj['Best site to interact with us'] && (
                                <li class="bullet-remove">
                                  {obj['Best site to interact with us'].split(
                                    ':'
                                  )[0] === 'http' ||
                                  obj['Best site to interact with us'].split(
                                    ':'
                                  )[0] === 'https' ? (
                                    <>
                                      {' '}
                                      Best site to interact with us:{' '}
                                      <a
                                        href={
                                          obj['Best site to interact with us']
                                        }
                                        //className="ellipsis"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {obj[
                                          'Best site to interact with us'
                                        ].slice(0, 25)}
                                        {'...'}
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      Best site to interact with us:{' '}
                                      <b>
                                        {obj['Best site to interact with us']}
                                      </b>
                                    </>
                                  )}
                                </li>
                              )}
                              {obj['Recent clients/works'] && (
                                <li class="bullet-remove">
                                  Recent clients/works:{' '}
                                  {obj['Recent clients/works'].map(el => (
                                    <>
                                      {el.split(':')[0] === 'http' ||
                                      el.split(':')[0] === 'https' ? (
                                        <ul>
                                          <li>
                                            <a
                                              href={el}
                                              //className="ellipsis"
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {el.length > 50
                                                ? el.slice(0, 50) + '...'
                                                : el}
                                            </a>
                                          </li>
                                        </ul>
                                      ) : (
                                        <ul>
                                          <li>{el}</li>
                                        </ul>
                                      )}
                                    </>
                                  ))}
                                </li>
                              )}
                              {obj['Brief introduction'] && (
                                <li class="bullet-remove">
                                  Brief introduction:{' '}
                                  <b>{obj['Brief introduction']}</b>
                                </li>
                              )}
                              {console.log('obj', obj)}

                              {obj.Bio && (
                                <>
                                  <li class="bullet-remove">Bio:</li>{' '}
                                  <li class="bullet-remove">
                                    <i>{obj.Bio}</i>
                                  </li>
                                </>
                              )}
                              {/* <li>
                                Link to profile:{' '}
                                {console.log(
                                  'this.props.profileData',
                                  this.props.profileData
                                )}
                                <Link
                                  to={`/users/$null?userId=${this.props.userProfile[0].id}`}
                                  target="_blank"
                                >
                                  {' '}
                                  {window.location.host}
                                  /#/users/
                                  {this.props.userProfile[0].id}
                                  {'...'}
                                </Link>
                              </li> */}
                            </ul>
                          </div>
                        </>
                      ) : (
                        profileData &&
                        profileData.map(data => {
                          let key = Object.keys(data)[0];
                          let value = Object.values(data);

                          if (
                            key &&
                            (key.toLowerCase() === 'undefined' ||
                              key.toLowerCase() === 'end' ||
                              key.toLowerCase() === 'event_title' ||
                              key.toLowerCase() === 'image' ||
                              key.toLowerCase() === 'book-cover' ||
                              key.toLocaleLowerCase() == 'profile' ||
                              key ==
                                'Publicly display email on profile page?' ||
                              key == 'emailDisplay')
                          ) {
                            return;
                          }
                          if (key && value == 'undefined') {
                            return;
                          }
                          if (Object.keys(data).length !== 0) {
                            if (
                              value[0] &&
                              Array.isArray(value[0]) &&
                              value[0].length
                            ) {
                              return (
                                <span>
                                  <li className="remove-bullet">
                                    <span>{/* <b>{key}</b> */}</span>
                                  </li>
                                  <ul>
                                    {value[0].length &&
                                      value[0].map(sub => {
                                        if (sub) {
                                          return (
                                            sub.length > 0 &&
                                            sub.map(subObj => {
                                              if (
                                                key == 'More profiles' ||
                                                key == 'Social media' ||
                                                key == 'Social Media' ||
                                                key == 'Other social media'
                                              ) {
                                                return (
                                                  <li className="more-profile remove-bullet">
                                                    <a
                                                      href={Object.values(
                                                        subObj
                                                      )[0].toString()}
                                                      //className="ellipsis"
                                                      target="_blank"
                                                      rel="noreferrer"
                                                    >
                                                      {Object.keys(subObj)[0]}
                                                    </a>
                                                    &nbsp;|&nbsp;
                                                  </li>
                                                );
                                              }
                                              if (
                                                key ==
                                                  'Currently featured on "Last minute vendor cancellation list"' ||
                                                key ==
                                                  'Currently featured on Last minute vendor cancellation list' ||
                                                key ==
                                                  'Currently featured on last minite Vendor cancellations list' ||
                                                key ==
                                                  'Currently featured on Last minute vendor cancellation list' ||
                                                key ==
                                                  'Currently featured on last minute vendor cancellations list' ||
                                                key ==
                                                  'Currently featured on Last minute vendor cancellations list' ||
                                                key ==
                                                  'Currently featured on Vendor Cancellation Openings (VCO) list' ||
                                                key ==
                                                  'Currently featured on "Vendor Cancellation Openings (VCO) list"'
                                              ) {
                                                return (
                                                  <li className="remove-bullet">
                                                    {/* <span>{moment(Object.keys(subObj)[0]).format("MM/DD/YYYY")}</span>: */}
                                                    <span>
                                                      {' '}
                                                      <b>
                                                        {Object.keys(subObj)[0]}
                                                      </b>
                                                    </span>
                                                  </li>
                                                );
                                              }
                                              if (
                                                key == 'Reader groups' ||
                                                key == 'Fan groups' ||
                                                key == 'Purchase sites' ||
                                                key == 'Reader Groups' ||
                                                key == 'PR fan groups: ' ||
                                                key == 'Where to purchase'
                                              ) {
                                                return (
                                                  <li>
                                                    <span>
                                                      {Object.keys(subObj)[0]}
                                                    </span>
                                                    :
                                                    <span>
                                                      {' '}
                                                      <a
                                                        href={Object.values(
                                                          subObj
                                                        )[0].toString()}
                                                        //className="ellipsis"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {
                                                          Object.values(
                                                            subObj
                                                          )[0]
                                                        }
                                                      </a>
                                                    </span>
                                                  </li>
                                                );
                                              }
                                              return (
                                                <li className="remove-bullet">
                                                  <span>
                                                    {Object.keys(subObj)[0]}
                                                  </span>
                                                  :
                                                  <span>
                                                    {' '}
                                                    <b>
                                                      {Object.values(subObj)[0]}
                                                    </b>
                                                  </span>
                                                </li>
                                              );
                                            })
                                          );
                                        }
                                      })}
                                  </ul>
                                </span>
                              );
                            }
                            if (!Array.isArray(value[0]) && value[0] !== '') {
                              if (
                                key == 'Website' ||
                                key == 'Goodreads' ||
                                key == 'Goodreads link' ||
                                key == 'PR Fan groups' ||
                                key == 'Best site to rech me' ||
                                key == 'Blog link' ||
                                key == 'Best site to interact with us' ||
                                key == 'Website link' ||
                                key ==
                                  'Best site for AUTHORS to interact with us' ||
                                key == 'Master ARC list sign up' ||
                                // key == "Best site to reach me" ||
                                key == 'Newsletter sign up' ||
                                key ==
                                  'Best site for bloggers/readers to interact with us'
                              ) {
                                return (
                                  // <li className="remove-bullet">
                                  //   <span>{key}</span>:
                                  //   <span>
                                  //     {' '}
                                  //     <a
                                  //       href={value[0].toString()}
                                  //       className="ellipsis"
                                  //       target="_blank"
                                  //       rel="noreferrer"
                                  //     >
                                  //       {value[0].toString()}
                                  //     </a>
                                  //   </span>
                                  // </li>
                                  <li className="bullet-remove">
                                    {' '}
                                    {key == 'Website' || key == 'Goodreads' ? (
                                      <li className="bullet-remove">
                                        <span>{key}</span>:
                                        <span>
                                          {' '}
                                          <a
                                            href={value[0].toString()}
                                            className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {value[0].toString()}
                                          </a>
                                        </span>
                                      </li>
                                    ) : (
                                      <li className="bullet-remove">
                                        <b>
                                          {key == 'Newsletter sign up' && (
                                            <>
                                              Other:
                                              <br />
                                            </>
                                          )}
                                        </b>
                                        <span>{key}</span>:
                                        <span>
                                          {' '}
                                          <a
                                            href={value[0].toString()}
                                            className="ellipsis"
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {value[0].toString()}
                                          </a>
                                        </span>
                                      </li>
                                    )}
                                  </li>
                                );
                              }
                              if (key == 'Bio') {
                                return (
                                  <li className="remove-bullet">
                                    <span>{key}</span>:
                                    <p
                                      className="line-space"
                                      style={{
                                        whiteSpace: 'pre-line',
                                        'word-break': 'break-all',
                                        marginBottom: '0'
                                      }}
                                    >
                                      {' '}
                                      <i>{value[0]}</i>
                                    </p>
                                  </li>
                                );
                              }
                              if (key == 'Author') {
                                return (
                                  <li className="remove-bullet">
                                    <span>{key}</span>:
                                    <span
                                      style={{
                                        textTransform: 'capitalize'
                                      }}
                                    >
                                      {' '}
                                      <b>{value[0]}</b>
                                    </span>
                                  </li>
                                );
                              }

                              return (
                                <li className="remove-bullet">
                                  <span>{key}</span>:
                                  <span>
                                    {' '}
                                    <b>{value[0]}</b>
                                  </span>
                                </li>
                              );
                            }
                          }
                        })
                      )}
                      {console.log(
                        'userprofile of the vendor-- > ',
                        this.props.userProfile[0]
                      )}
                      {(this.props.userProfile[0].follow_code ||
                        this.props.userProfile[0].membership_id) && (
                        <>
                          {this.props.userProfile[0].membership_id !== '4' && (
                            <li className="bullet-remove">
                              INVITE code:{' '}
                              <b>{this.props.userProfile[0].follow_code}</b>
                            </li>
                          )}

                          <li className="bullet-remove">
                            <b> All OTR events added by this profile: </b>
                            {/* {console.log(
                              ' this.props.userProfile[0].fname',
                              this.props.userProfile[0].fname,
                              fullName
                            )} */}
                            <ul>
                              <li>
                                Linked Events page:{' '}
                                <Link
                                  to={{
                                    pathname: `/dashboard/linked?userId=${
                                      this.props.userProfile[0].id
                                    }&name=${
                                      fullName
                                        ? fullName
                                        : this.props.userProfile[0].fname
                                    }`
                                  }}
                                  target="_blank"
                                >
                                  {/* {window.location.href} */}
                                  {window.location.host}
                                  /#/dashboard/{this.props.userProfile[0].fname}
                                  {'...'}
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </>
                      )}
                    </ul>

                    {+localStorage.getItem('userId') !==
                      this.props.userProfile[0].id && (
                      <div className="col-md-12 text-center">
                        {this.props.userProfile[0].type_id == 1 ||
                        this.props.userProfile[0].type_id == 5 ||
                        this.props.userProfile[0].membership_id == 6 ? (
                          <>
                            {!this.props.status && (
                              <button
                                className="btn new_btn_save_bt float-md-right"
                                onClick={() => {
                                  // if (
                                  //   window.confirm(
                                  //     "You will be notified about this member's updates and their events."
                                  //   )
                                  // )
                                  this.onFollow(this);
                                }}
                              >
                                FOLLOW
                              </button>
                            )}

                            {this.props.status && (
                              <button
                                className="btn new_btn_save_bt float-md-right btn-hover btn-danger"
                                onClick={() => {
                                  this.onUnFollow(this);
                                }}
                              >
                                UNFOLLOW
                              </button>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {/* <Link
                              to="/dashboard"
                              className="btn new_btn_save_bt float-md-left "
                            >
                              BACK TO DASHBOARD
                            </Link> */}
                      </div>
                    )}
                  </div>
                </div>
                {this.props.userProfile[0].id ==
                localStorage.getItem('userId') ? (
                  <div className="edit-profile-btn mt-2">
                    <button
                      className={
                        this.props.displayMode ? 'btn bg-btn' : 'btn bg-btn'
                      }
                      onClick={() => {
                        localStorage.setItem(
                          'userId',
                          this.props.userProfile[0].id
                        );
                        localStorage.setItem('mode', 'edit');
                        this.props.getUserProfileEdit(
                          '/api/v1/user/profile',
                          {
                            headers: {
                              'x-authorization': localStorage.getItem('token')
                            }
                          },
                          true
                        );
                      }}
                    >
                      Edit Profile
                    </button>
                  </div>
                ) : (
                  <></>
                )}
                {/* <button
                          onClick={() => localStorage.setItem("userId",this.props.profileData.profile.id);this.props.editEvent("/api/v1/event/" + this.props.eventData.id, { headers: { 'x-authorization': localStorage.getItem("token") } }) }}>
                         className="btn btn_save_bt"
                       }>Edit Profile</button> */}
              </div>
            </div>
          </Layout>
        </>
      );
    } else {
      return (
        <>
          {/* {console.log("this.props.history.location.pathname.split('/').pop() ", this.props.history.location.pathname.split('/').pop() === 'fcfc')} */}
          {(!this.props.loading ||
            this.props.history.location.pathname.split('/').pop() !== 'fcfc') &&
          this.props.history.location.pathname.split('/').pop() === '9v1GiX' ? (
            <BlankPage />
          ) : (
            <Loader />
          )}
          {/* <div style={{ backgroundColor: '#1f1f1f' }}>
        <div className="container-fluid">
          <div className="row signupmain haya">
            <Logo />
            <div className="main_left">
              {/* <h2>NOT A VALID PR CODE</h2> *
            </div>
          </div>
        </div>
      </div> */}
        </>
      );
    }
  }

  onFollow() {
    let userData = {
      following_user_id: +this.props.userProfile[0].id
    };
    this.props.follow(userData);
  }

  onUnFollow() {
    this.props.unFollow(this.props.userProfile[0].id);
  }

  componentDidMount() {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    // console.log("route1183",route); this.props.history.location.pathname.split('/').pop()
    const query = window.location.hash;
    const params = new URLSearchParams(query.split('?')[1]);
    const userId = params.get('userId');
    console.log('userid in profile page---> ', userId);
    if (route) {
      this.props.getUserProfile(
        `/api/v1/getUsersDetail/${route}?userId=${userId}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );
    }
  }
}
const mapStateToProps = state => ({
  loading: state.profileLoading,
  userProfile: state.userProfile,
  status: state.checkIsFollowed,
  taskData: state.filterTask
});

const mapDispatchToProps = dispatch => ({
  getUserProfile: (url, header) => dispatch(getUserDetailsByPR(url, header)),
  follow: data =>
    dispatch(
      followUser(
        '/api/v1/follow',
        data,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        false,
        null
      )
    ),
  unFollow: id =>
    dispatch(
      unFollowUser(
        `/api/v1/follow/${id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        false,
        null
      )
    ),
  getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
  getUserFollowingStatus: id =>
    dispatch(
      getFollowingStatus(`/api/v1/follow_userlist/${id}`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  getUserProfileEdit: (url, header, edit) =>
    dispatch(getProfileDetails(url, header, edit))
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
