import React from 'react'
import './loader.css'

const Loader = () => {
  return (
    <div className="loader-section">
        <div class = "heart"></div>
    </div>
  )
}

export default Loader
