import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import createHistory from 'history/createHashHistory';
import { connect } from 'react-redux';
// import { authenticate, signuppage } from '../../Actions/index';
import '../Landing/landing.css';
// import { Redirect } from 'react-router-dom';
// import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { getProfileDetails } from '../../Actions/profile-setup';
import { getstatic } from '../../Actions/index';
import { notify } from 'react-notify-toast';
import axios from 'axios';
// import Footer from './footer';

// import Logo from '../../Components/left-logo';
import { BASEURL } from '../../constant';
import Layout from '../layout/layout';

// const override = css`
//   position: relative;
// `;

const globalHistory = new createHistory();
class Advertise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      time: '',
      name: '',
      title: '',
      link: '',
      date: '',
      email: '',
      display_name: ''
    };

    this.notifyHandle = this.notifyHandle.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    const profileData = this.props.profileData.profile;
    if (profileData) {
      this.setState({
        email: profileData.email,
        display_name: profileData.display_name
      });
    }
  };

  notifyHandle = () => {
    notify.show(
      'Your request is submitted. We will get back to you soon.',
      'custom',
      3000,
      {
        background: '#d4edda',
        text: '#155724',
        borderColor: '#c3e6cb'
      }
    );
  };

  handleSubmit = async e => {
    e.preventDefault();

    const { time, name, title, link, date, email, display_name } = this.state;
    const form = await axios.post(BASEURL + 'api/v1/advertise', {
      time,
      name,
      title,
      link,
      date,
      email,
      display_name
    });
    console.log('form', form);

    await this.setState({ title: '', time: '', name: '', date: '', link: '' });

    await this.notifyHandle();
  };

  render() {
    window.scrollTo(0, 0);
    // console.log("hello", this.state);
    return (
      <Layout>
        <div className="container">
          <div className="mb-4">
            <h2 className="text-center">Advertise on OTR</h2>
          </div>
          <div className="row">
            <div className="col-md-12 mt-3 mb-4">
              <ul className="">
                <li>
                  Ads, in the form of a banner to be part of weekly slideshow,
                  can be purchased to promote OTR romance book releases on the
                  OTR app and website.
                </li>
                <li>
                  Ad duration is one week, starting on Monday and ending on
                  Sunday.
                </li>
                <li>
                  For maximum exposure, the Banner slideshow plays on the OTR
                  Calendar page, OTR Event filter page, and all List pages under
                  the List tab.
                </li>
                <li>
                  Each ad banner links to the OTR Book Release Event page
                  visible to readers on the app and website, enabling members to
                  add your book to their calendar.
                </li>
                <li>
                  Ad slide shows starts on random ad banner and loops. In auto
                  play each ad displays for approx. 7 seconds. Web ad banner
                  slideshow allows for scrolling forward and backward.
                </li>
                <li>
                  Capped at 3 MB, banner can be a PNG or JPEG image or a GIF
                  file. Ad dimensions:
                  <br />
                  <span>App: Leaderboard (728 x 90 pixels)</span> <br />
                  <span>
                    {' '}
                    {/* Web: Vertical Rectangle Banner (200 x 400 pixels) */}
                    Web: Portrait Banner 1:2 ratio (minimum 500 x 1000 pixels)
                  </span>
                </li>
              </ul>
            </div>
            <div className=" col-12 ">
              <fieldset class="scheduler-border border-light">
                <legend class="scheduler-border">
                  <b>SCHEDULE</b>
                </legend>
                <div className="row">
                  <div class="control-group col-12 pb-3">
                    <div className="span_control pr-lg-3 pb-3 pb-lg-0">
                      Log into your account. Add the book release to the OTR
                      calendar. Purchased ad banners will link to the OTR Book
                      Release page.
                      <br />
                      <i>
                        *Book Release Events can only be added by authors. But
                        ads can be purchased by authors and PR firms.
                      </i>
                    </div>
                    <div class="advertise-logged-outbtn">
                      <Link
                        to={'/log-in?advertise'}
                        className="btn btn_save_bt "
                      >
                        Log in
                      </Link>

                      <Link to={'/join'} className="btn btn_save_bt">
                        JOIN
                      </Link>
                    </div>
                  </div>

                  {/* <div className="   col-3">
                  <Link to={"/dashboard"}>
                    <button
                      className={
                        this.props.displayMode
                          ? "btn btn_save_bt"
                          : "btn btn_save_bt dark-button"
                      }
                    >
                      Add Event
                    </button>
                  </Link> */}
                  {/* </div> */}
                </div>
              </fieldset>
            </div>
            {/* 
            {localStorage.getItem('token') ? (
              <>
                <div classname="col-md-12 mb-4 text-center">
                  <a href="/" class="btn new_btn_save_bt float-md-right">
                    Back to Dashboard
                  </a>
                </div>
              </>
            ) : (
              <></>
            )} */}
          </div>
          {/* {!localStorage.getItem('token') ? (
            <>
              <div className="edit-profile-btn mt-2">
                <a href="/" class="float-md-right">
                  <button
                    type="submit"
                    className="btn float-md-right new_btn_save_bt m-0"
                    style={{
                      marginRight: '0% !important'
                    }}
                  >
                    Back to Dashboard
                  </button>
                </a>
              </div>
            </>
          ) : (
            <></>
          )} */}
        </div>
      </Layout>
    );
  }
  componentDidMount() {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    if (localStorage.getItem('token')) {
      globalHistory.push('/dashboard/advertise');
    }

    if (route) {
      // console.log("Inside the if route condition");

      this.props.getstatic(`/api/v1/static?page=${route}`);
    }
  }
}

//{======= VideoFrames Component ========}

const mapStateToProps = state => {
  return {
    pages: state.userListData,
    profileData: state.myProfileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getstatic: url => dispatch(getstatic(url)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Advertise);
