import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { notify } from 'react-notify-toast';
import { getVerifyLink, updatePassword } from '../../Actions/index';
import Layout from '../layout/layout';
import '../Landing/landing.css';

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      password: '',
      conformPassword: ''
    };

    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleConfirmPassword = this.handleConfirmPassword.bind(this);
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleConfirmPassword(e) {
    this.setState({ conformPassword: e.target.value });
  }

  onSubmitFormHandler(e, history) {
    this.setState({ isSubmitted: true });
    e.preventDefault();
    if (this.state.password !== this.state.conformPassword) {
      notify.show('New password must match confirm password.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else {
      let route = this.props.history.location.pathname;
      route = route.split('/').pop();
      let data = {
        token: route,
        password: this.state.password
      };
      this.props.updatePassword('/api/v1/updatePassword', data);
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <html className="reset-page" lang="en" />
        </Helmet>
        <Layout>
          <div className="forgot-password forget-page">
            <div className="container-fluid">
              <div className="row ">
                <div className="main_right author_profile">
                  <div className="setup_p_hed">
                    <h4>RESET PASSWORD</h4>
                  </div>

                  <>
                    <form
                      className="form_custom"
                      onSubmit={e => this.onSubmitFormHandler(e)}
                    >
                      <div className="code_main">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label>
                              New password.<sup>*</sup>
                            </label>
                            {/* <div className="reset-input-password"> */}
                            <input
                              type={this.state.showpwd ? 'text' : 'password'}
                              style={{ marginBottom: '0%' }}
                              className="form-control password_s"
                              placeholder="Enter new password"
                              onChange={this.handleChangePassword}
                              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$"
                              required
                            />
                            <i
                              className={`fa fa-eye${
                                this.state.showpwd ? '-slash' : ''
                              } pull-right show-pwd-dark`}
                              onClick={() => {
                                this.setState({
                                  showpwd: !this.state.showpwd
                                });
                              }}
                            />
                            {/* </div> */}
                            <label
                              style={{ fontSize: '80%', display: 'table' }}
                            >
                              Minimum 8-20 characters, 1 number, 1 upper case*
                            </label>
                          </div>
                          <div className="form-group">
                            <label>
                              Confirm New password.<sup>*</sup>
                            </label>
                            <input
                              type={this.state.reshowpwd ? 'text' : 'password'}
                              // style={{ maxWidth: 250 }}
                              className="form-control password_s"
                              placeholder="Re-enter new password"
                              onChange={this.handleConfirmPassword}
                              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$"
                              required
                            />
                          </div>
                          <div className="forget-btn">
                            <button className="btn btn-filled" type="submit">
                              {localStorage.mID ? 'Set Password' : 'Change'}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div className="note_s" />
                  </>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }

  componentDidMount() {
    // localStorage.clear();

    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    console.log('route', route);

    if (route) {
      this.props.getVerifyLink(`/api/v1/verifyLink?token=${route}`);
    }
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  getVerifyLink: url => dispatch(getVerifyLink(url)),
  updatePassword: (url, data) => dispatch(updatePassword(url, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
