import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addEventToMyCalendar } from '../../../Actions/event-setup';
import { getNotificationlist } from '../../../Actions/messages';

// import { setupEvent, getFilterEventDataList ,addEventToFavourite} from '../../../Actions/event-setup';
import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';
import Loader from '../../../Components/Loader/loader';
import { sevenDaysLoading } from '../../../Reducers/message';

const overlayNoRowsTemplate = '<span>No record found</span>';

class NotificaitonListFollowing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      data: [],
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 180,

          cellRenderer: function(event_type) {
            var backgroundColor;
            var value;
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
              value = 'Book Release';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
              value = 'Giveaway';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
              value = 'Book Promo';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF79BC, #FF65FF';
              value = 'Release Party';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#F3FfB5, #fFDB69';
              value = 'Cover Reveal';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#B469FF, #8E5BfF';
              value = 'Book Signing';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Sign-up'
            ) {
              backgroundColor = '#FF5B5B, #FF5B9D';
              value = 'ARC Sign-up';
            }
            if (event_type.value === 'ticket sales') {
              backgroundColor = '#DCB9FF, #B9B9FF';
              value = 'Ticket Sales';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              value
            );
          }
        },
        {
          headerName: 'TITLE/NAME',
          field: 'commanTitle',
          sortable: true,
          filter: true,
          width: 130,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,
          width: 160,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 150,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 140,
          cellRenderer: function(endDate) {
            return moment(endDate.data.end_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'TIME',
          field: 'time',
          sortable: true,
          filter: true,
          width: 140,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          width: 140,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: function(params) {
            return params.data.hosted_by
              ? params.data.hosted_by.toLowerCase()
              : '';
          }
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          width: 150,
          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'ACTION',
          field: 'addcalender',
          cellRenderer: this.showAddCalender.bind(this)
        }

        // {
        //     headerName: "FAVOURITE", field: "favourite", sortable: true, filter: true, resizable: true,
        //     cellRenderer: function (favourite) {
        //         if (favourite.data.favourite)
        //         {
        //         return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
        //         }
        //         else{
        //             return '<i class="fa fa-star-o" style="font-size:20px;"></i>'
        //         }
        //     }
        // },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    // return `${
    //   params.data.author_first_name
    //     ? params.data.author_first_name[0].toUpperCase() +
    //       params.data.author_first_name.slice(1).toLowerCase()
    //     : ''
    // } ${
    //   params.data.author_last_name
    //     ? params.data.author_last_name[0].toUpperCase() +
    //       params.data.author_last_name.slice(1).toLowerCase()
    //     : ''
    // }`;
    let midName = params.data.author_mid_name;

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }
  showTime(params) {
    return params.data.time ? this.convertTo12Hour(params.data.time) : '-';
  }

  showAddCalender(params) {
    let is = params.data.isIncluded;
    if (this.props.eventMode === true && is === false) {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    } else {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    }
  }

  onActionAddCalenderClick(data) {
    data.event_details_with_values =
      typeof data.event_details_with_values == 'string'
        ? JSON.parse(data.event_details_with_values)
        : data.event_details_with_values;
    this.setState({
      eventData: data,
      modal: true
    });
  }

  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    // var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  getFormattedValue(params) {}

  titleCellRenderer(params) {
    if (params.data) {
      const eventDetails = JSON.parse(params.data.event_details_with_values);
      if (
        params.data.event_type === 'Release Party' ||
        params.data.event_type === 'event_type_4' ||
        params.data.event_type === 'Giveaway' ||
        params.data.event_type === 'event_type_2'
      ) {
        let newTitle = '';
        if (
          params.data.event_type === 'Release Party' ||
          params.data.event_type === 'event_type_4'
        ) {
          newTitle = eventDetails[6]['release-party-event-name']
            ? eventDetails[6]['release-party-event-name']
            : '';
        } else if (
          params.data.event_type === 'Giveaway' ||
          params.data.event_type === 'event_type_2'
        ) {
          newTitle = eventDetails[6]['Name of Giveaway']
            ? eventDetails[6]['Name of Giveaway']
            : '';
        }
        return newTitle;
      }
      return `${params.data.title ? params.data.title : ''} `;
    }
  }

  showEvent(e) {
    if (e.column.colId === 'addcalender') {
      this.props.addToMyCalendar(
        `/api/v1/add_mycalendar/${e.data.id}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        this.state.data,
        'followingevent',
        this.state.data
      );

      //return this.onActionViewClick(data);
    } else {
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  //===================== RENDER ==========================
  render() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // console.log("this is the data in members i follow---> ", this.state.data);
    let newData = [];
    this.state.data.forEach(el => {
      let obj = { ...el };
      // console.log("el.event_type--> ", el.event_type);
      if (el && el.event_type === 'Giveaway') {
        obj.commanTitle = el.giveaway
          ? el.giveaway.length > 20
            ? el.giveaway.substr(0, 20) + '...'
            : el.giveaway
          : '';
      } else if (el && el.event_type === 'Release Party') {
        obj.commanTitle = el.releaseparty
          ? el.releaseparty.length > 20
            ? el.releaseparty.substr(0, 20) + '...'
            : el.releaseparty
          : '';
      } else {
        obj.commanTitle =
          el && el.title
            ? el.title.length > 20
              ? el.title.substr(0, 20) + '...'
              : el.title
            : '';
      }
      obj.commanTitle = obj.commanTitle.toLowerCase();
      newData.push(obj);

      // console.log("new data-----------------> in event i follow:: ", newData);
      if (!!this.props.eventArray && this.props.eventArray.length > 0) {
        let MostSavedEvent = [];
        newData.forEach(el => {
          // console.log("elllllllllllllllllll ", el)
          let userId = localStorage.getItem('userId');
          let element = { ...el };
          let obj = this.props.eventArray.find(ne => el.id == ne.event_id);
          if (obj) {
            element.eventCount = obj.eventcount;
          } else if (userId == el.user_id) {
            element.eventCount = 0;
          } else {
            element.eventCount = -1;
          }

          MostSavedEvent.push(element);
        });

        newData = [...MostSavedEvent];
        newData = newData.sort((a, b) => {
          if (a.eventCount > b.eventCount) return -1;
          if (a.eventCount === b.eventCount && a.created_date > b.eventCount)
            return -1;
        });
      }

      if (!this.props.eventArray) {
        newData = newData.sort((a, b) => {
          // console.log({a: a.start_date , b: b.start_date})
          if (new Date(a.start_date) > new Date(b.start_date)) {
            return -1;
          }
        });
      }

      // console.log({newData, eventdata: this.props.eventArray });
    });
    console.log(
      'thiis is the nnew data in event i eventLoading::',
      this.props.sevenDaysLoading
    );
    return (
      <>
        <div className="container event-filter-page">
          <div className="mb-3">
            <div className="color_guide mb-3">
              <div className="row">
                <div className="legend">
                  <span className="color_box">RELEASE PARTY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">ARC SIGNUP</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK PROMO</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">COVER REVEAL</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">GIVEAWAY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK RELEASE</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>

                <div className="legend">
                  <span className="color_box text-uppercase">
                    Boxes & Signings
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box text-uppercase">
                    Ticket/box sales
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
              </div>
            </div>

            <h2 className="text-center heading">
              NEW EVENTS BY THE MEMBERS I FOLLOW
            </h2>
            <h4 className="text-center heading">
              Events added to OTR in the last 7 days
            </h4>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            {this.props.sevenDaysLoading ? (
              <>
                <Loader />

                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={newData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  // onRowClicked={this.addToFavourite.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                />
              </>
            ) : (
              <>
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={newData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  // onRowClicked={this.addToFavourite.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                />
              </>
            )}

            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          {/* {this.state.modal ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
            />
          ) : null} */}
          {this.state.modal ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
              isInclude={this.state.eventData.isIncluded}
            />
          ) : null}
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    let eventData = [];
    if (props.data && props.data.length) {
      props.data.map(data => {
        return eventData.push(data.event);
      });
    }

    this.setState({ data: eventData });
  }

  componentDidMount() {
    let eventData = [];
    if (this.props.data)
      if (this.props.data.length) {
        this.props.data.map(data => {
          return eventData.push(data.event);
        });
      }
    this.setState({ data: eventData });
    this.props.getNotificationList(`/api/v1/notification`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }
}

const mapStateToProps = state => {
  console.log('statestatestate', state);
  // console.log({ });
  return {
    // data: state.getNotification.currentUserData
    eventMode: state.getEventMode,
    profileLoading: state.profileLoading,
    eventLoading: state.eventLoading,
    sevenDaysLoading: state.sevenDaysLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToMyCalendar: (url, header, data, pageName, eventsData) =>
      dispatch(addEventToMyCalendar(url, header, data, pageName, eventsData)),
    getNotificationList: (url, header) =>
      dispatch(getNotificationlist(url, header))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificaitonListFollowing);
