import axios from 'axios';
import { notify } from 'react-notify-toast';
import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';

import { BASEURL } from '../constant';
import { getNotificationlist } from './messages';

export default createHistory();

export function setupEvent() {
  return dispatch => {
    dispatch(eventLoading(false));
  };
}
export function eventLoading(bool) {
  return {
    type: 'EVENT_LOADING',
    status: bool
  };
}

export function sendMailModel(bool) {
  return {
    type: 'SEND_EMAIL',
    status: bool
  };
}
export function EventError(bool) {
  return {
    type: 'EVENT_ERROR',
    error: bool
  };
}

export function Tab(activeTab) {
  return {
    type: 'ACTIVE_TAB',
    activeTab
  };
}
export function eventData(data) {
  return {
    type: 'EVENT_DATA',
    data
  };
}

export function editEventType(data) {
  return {
    type: 'EDIT_EVENT_TYPE',
    data
  };
}

export function events(data) {
  return {
    type: 'EVENTS',
    data
  };
}

export function eventsummary(data) {
  return {
    type: 'EVENTS_SUMMARY',
    data
  };
}

export function filterevent(data) {
  return {
    type: 'EVENTS_FILTER',
    data
  };
}

export function previewData(data) {
  return {
    type: 'PREVIEW_DATA',
    data
  };
}

export function dashboardTabs(data) {
  return {
    type: 'DASHBOARD_TABS',
    data
  };
}

export function getEventButtons(data) {
  return {
    type: 'EVENT_BUTTONS',
    data
  };
}

export function getEventsGroup(data) {
  return {
    type: 'EVENT_GROUP',
    data
  };
}

export function getEventMode(mode) {
  return {
    type: 'EVENT_MODE',
    mode
  };
}

export function getFilterEventData(data) {
  return {
    type: 'GET_FILTER_DATA',
    data
  };
}

export function addToFavourite(data) {
  return {
    type: 'ADD_FAVOURITE',
    data
  };
}

export function searchTitle(data) {
  return {
    type: 'EVENT_TITLE',
    data: [
      ...data,
      data.length && {
        label: data.length ? data[0].label : 'Other',
        name: 'Other',
        value: false
      }
    ]
  };
}

export function addToMyCalendar(data) {
  return {
    type: 'ADD_TO_CALENDAR',
    data
  };
}

export function getSingleEventDetails(data) {
  return {
    type: 'SINGLE_EVENT',
    data
  };
}

export function getAssociatedEvents(data) {
  return {
    type: 'GET_ASSOCIATED_EVENTS',
    data
  };
}

export function disableButtonRelease(boolean) {
  return {
    type: 'DISABLE_BOOK_RELEASE',
    boolean
  };
}

export function getBookRelaseTitle(data) {
  return {
    type: 'GET_BOOK_RELEASE_TITLE',
    data
  };
}

export function setLinkedEvents(data) {
  return {
    type: 'GET_LINKED_EVENTS_LIST',
    data
  };
}

export function bookReleaseBlast(data) {
  return {
    type: 'BOOK_RELEASE_BLAST',
    data
  };
}

export function bookReleaseEventBlast(data) {
  return {
    type: 'BOOK_RELEASE_EVENT_BLAST',
    data
  };
}

export function duplicateEvent(data) {
  return {
    type: 'DUPLICATE_EVENT_EXIST',
    data
  };
}

export function isSyncChecked(data) {
  return {
    type: 'CHECK_SYNC',
    data
  };
}

export function searchUserdata(data) {
  return {
    type: 'user_search',
    data
  };
}

export function initialAuthor(data) {
  return {
    type: 'initial_author',
    data
  };
}

export function saveEvent(url, headers, data, history, edit) {
  return dispatch => {
    // console.log("this the data and its type-- > ", data);
    // console.log("this the data and its type-- > ", data[0]);
    // data.created_date = moment().format('MM/DD/YYYY hh:mm');

    // console.log("vvvvvvvvvvvvvvvv ", data.created_date);
    if (!edit) {
      axios
        .post(BASEURL + url, data, { headers })
        .then(response => {
          if (localStorage.event_type === '1') {
            notify.show(
              'Book Release event successfully added. One free Release Day Blast credited.',
              'custom',
              5000,
              {
                background: '#d4edda',
                text: '#155724'
              }
            );
          } else {
            notify.show('Event successfully added.', 'custom', 5000, {
              background: '#d4edda',
              text: '#155724'
            });
          }
          if (response.data.data.follow) {
            // if (
            //   window.confirm('Do you want to send blast to your followers ?')
            // ) {
            //   dispatch(sendMailModel(true));
            //   localStorage.removeItem('mode');
            //   dispatch(previewData([]));
            //   dispatch(editEventType({}));
            //   dispatch(events([]));
            //   dispatch(eventLoading(false));
            //   globalHistory.push('/sendEmail');
            // } else {
            //   history.push('/dashboard');
            // }

            history.push('/dashboard');
          } else {
            localStorage.removeItem('mode');
            dispatch(previewData([]));
            dispatch(editEventType({}));
            dispatch(events([]));
            dispatch(eventLoading(false));
            if (history !== null) {
              history.push('/dashboard');
            }
          }
        })
        .catch(error => {
          dispatch(editEventType({}));
          dispatch(eventLoading(false));
          if (error.response && error.response.status === 403) {
            dispatch(Unauthorized);
          } else if (error.response && error.response.status === 400) {
            notify.show(error.response.data.message, 'custom', 5000, {
              background: '#f8d7da',
              text: '#721c24'
            });
          } else {
            notify.show('Failed to create Event.', 'custom', 5000, {
              background: '#f8d7da',
              text: '#721c24'
            });
          }
          // history.push("/setup-event");
        });
    } else {
      axios
        .put(BASEURL + url, data, { headers })
        .then(response => {
          // notify.show("Event setup successfully", "custom", 2000, { background: '#d4edda', text: "#155724", borderColor: "#c3e6cb" });

          localStorage.removeItem('mode');
          localStorage.removeItem('eventId');
          dispatch(previewData([]));
          dispatch(editEventType({}));
          dispatch(events([]));
          dispatch(eventLoading(false));
          dispatch(eventData([]));
          if (history !== null) {
            history.push('/dashboard');
          }
        })
        .catch(error => {
          dispatch(editEventType({}));
          dispatch(eventLoading(false));
          if (error.response && error.response.status === 403) {
            dispatch(Unauthorized);
          } else if (error.response && error.response.status === 400) {
            notify.show(error.response.data.message, 'custom', 5000, {
              background: '#f8d7da',
              text: '#721c24'
            });
          } else {
            notify.show('Failed to create Event.', 'custom', 5000, {
              background: '#f8d7da',
              text: '#721c24'
            });
          }
          // history.push("/setup-event");
        });
    }
  };
}

export function getEventPreview(url, headers, data, history) {
  return dispatch => {
    // headers.name= 'helloooooo'
    // console.log("this is data of event--> ", data)
    dispatch(eventLoading(true));
    axios
      .post(BASEURL + url, data, { headers })
      .then(response => {
        // notify.show("Event setup successfully", "custom", 2000, { background: '#d4edda', text: "#155724", borderColor: "#c3e6cb" });

        dispatch(previewData(response.data.result.preview_data));
        dispatch(eventLoading(false));
        // if(history !== null){
        //     history.push("/dashboard");
        // }
      })
      .catch(error => {
        dispatch(eventLoading(false));

        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show('failed to get Event preview data.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }

        // history.push("/setup-event");
      });
  };
}

export function getEvent(url, headers, schema_name) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getEventSchema(schema_name));
  };
}

export function getEventSchema(schema_name) {
  return dispatch => {
    axios
      .get(BASEURL + `/api/v1/form-schema/${schema_name}`)
      .then(response => {
        if (response.data.result.schema.length > 0) {
          dispatch(eventData(response.data.result.schema));
          dispatch(eventLoading(false));
        }
        if (response.data.result.schema.length === undefined) {
          dispatch(eventData([]));
          dispatch(eventLoading(false));
        }
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(
            'something went wrong please reload page or login again.',
            'custom',
            5000,
            { background: '#f8d7da', text: '#721c24' }
          );
        }
      });
  };
}

export function getAllEvents(url, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        dispatch(EventError(false));
        dispatch(events(res.data.result.events));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(EventError(true));
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(
            'Failed to fetch events. Please hit refresh to try again.',
            'custom',
            5000,
            {
              background: '#f8d7da',
              text: '#721c24'
            }
          );
        }
        // localStorage.removeItem("token");
      });
  };
}

export function getFilterEvents(url, headers, history) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        dispatch(filterevent(res.data.result.events));

        // dispatch(Tab("Event filter"))
        dispatch(eventLoading(false));
        if (history) history.push('/dashboard/my-events');
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }

        // notify.show("failed to fetch Events", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
      });
  };
}

export function getAllEventsSummary(url, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        dispatch(eventsummary(res.data.result));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));

        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show(
            'Failed to fetch events. Please hit refresh to try again.',
            'custom',
            5000,
            {
              background: '#f8d7da',
              text: '#721c24'
            }
          );
        }
        // localStorage.removeItem("token");
      });
  };
}

export function deleteEvent(url, data, pageName, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .delete(BASEURL + url, headers)
      .then(res => {
        dispatch(events([]));

        // dispatch(getEventmy(`/api/v1/events/getevent/${mydata}`, headers));
        if (pageName === 'my-events') {
          dispatch(getFilterEvents(`/api/v1/events?event_type=my`, headers));
        } else {
          dispatch(
            getFilterEventDataList(
              `/api/v1/event_filter?isGeneral=${true}`,
              data,
              headers
            )
          );
        }

        dispatch(previewData([]));

        // dispatch(getEventmys([]));
        return notify.show(res.data.message, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      })
      .catch(error => {
        dispatch(eventLoading(false));
        // if (error.response && error.response.status === 403) {
        //   dispatch(Unauthorized);
        // }
      });
  };
}

export function getDashboardTabs(url, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(dashboardTabs(response.data.result));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getEventDetails(url, header, newTab, isEventPage) {
  // console.log("getEventDetails--------->")
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        const openNewTab = () => {
          const url = `${window.location.origin}/#/setup-event`;
          const newWindow = window.open('', '_blank');
          newWindow.location.href = url;
        };
        // console.log("response.data.result.event.event_details88888---> ",response.data.result.event.event_details)
        dispatch(eventData(response.data.result.event.event_details));
        dispatch(editEventType(response.data.result.event.event_type));
        localStorage.setItem('mode', 'edit');
        if (!isEventPage) {
          openNewTab();
        }
        // globalHistory.push('/setup-event');
        dispatch(eventLoading(false));
      })

      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getEventButton(url, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(getEventButtons(response.data.result));
        if (+response.data.eventCount >= +response.data.eventLimitCount) {
          dispatch(disableButtonRelease(true));
        }
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getEventGroups(url, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(getEventsGroup(response.data.result));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function changeEventMode(mode) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(events(''));
    dispatch(eventsummary(''));
    dispatch(filterevent(''));
    dispatch(getFilterEventData([]));
    dispatch(getEventMode(mode));
    dispatch(eventLoading(false));
  };
}

export function getFilterEventDataList(url, data, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getFilterEventData([]));

    axios
      .post(BASEURL + url, data, header)
      .then(response => {
        dispatch(getFilterEventData(response.data.result));
        dispatch({ type: 'Event_Most', data: [...response.data.eventarray] });
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function getPopularEvents(url, header) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getFilterEventData([]));
    axios
      .get(BASEURL + '/' + url, header)
      .then(response => {
        dispatch(getFilterEventData(response.data.events));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function addEventToFavourite(url, header, data, pageName) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(addToFavourite(response.data.message));
        if (pageName == 'eventFilter') {
          dispatch(
            getFilterEventDataList(
              `/api/v1/event_filter?isGeneral=${data.isGeneral}`,
              data,
              header
            )
          );
        }
        if (pageName == 'listView') {
          dispatch(events([]));
          dispatch(getAllEvents(`/api/v1/events?isGeneral=${data}`, header));
        }

        if (pageName == 'trendingList') {
          // dispatch(events([]));
          dispatch(getPopularEvents(`api/v1/events/getPopularEvents`, header));
        }
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function searchEvent(url, header) {
  return dispatch => {
    dispatch(searchTitle([]));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(searchTitle(response.data.result));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function addEventToMyCalendar(
  url,
  header,
  data,
  pageName,
  filterData,
  eventType
) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(addToMyCalendar({}));
    // dispatch(events(''));
    // dispatch(eventsummary([]));
    // dispatch(filterevent(''));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(addToMyCalendar(response.data.message));
        if (eventType) {
          dispatch(
            associatedEvents(`/api/v1/events/associatedEvents/${eventType}`, {
              headers: { 'x-authorization': localStorage.getItem('token') }
            })
          );
        }
        // console.log("pageName",pageName)
        dispatch(getNotificationlist(`/api/v1/notification`, header));
        if (pageName == 'trendingList') {
          dispatch(getPopularEvents(`api/v1/events/getPopularEvents`, header));
        } else if (pageName !== 'followingevent') {
          dispatch(
            getFilterEventDataList(
              `/api/v1/event_filter?isGeneral=${data.isGeneral}`,
              filterData,
              header
            )
          );
        }

        dispatch(eventLoading(false));
        // return response.data.message;
        return notify.show(response.data.message, 'custom', 5000, {
          background: '#d4edda',
          text: '#155724'
        });
      })
      .catch(error => {
        dispatch(eventLoading(false));
        return notify.show(
          error.response && error.response.data.message,
          'custom',
          5000,
          {
            background: '#f8d7da',
            text: '#721c24'
          }
        );
      });
  };
}

// export function addEventToMyCalendarByModal(
//   url,
//   header,
//   data,
//   pageName,
//   filterData
// ) {
//   console.log('Dddddd=>', filterData);
//   return dispatch => {
//     dispatch(eventLoading(true));
//     dispatch(addToMyCalendar({}));
//     dispatch(events(''));
//     dispatch(eventsummary([]));
//     dispatch(filterevent(''));
//     axios
//       .get(BASEURL + url, header)
//       .then(response => {
//         dispatch(addToMyCalendar(response.data.message));
//         if (pageName == 'trendingList') {
//           dispatch(getPopularEvents(`api/v1/events/getPopularEvents`, header));
//         } else {
//           dispatch(
//             getFilterEventDataList(
//               `/api/v1/event_filter?isGeneral=${data.isGeneral}`,
//               filterData,
//               header
//             )
//           );
//         }

//         dispatch(eventLoading(false));
//         // return response.data.message;
//         return notify.show(response.data.message, 'custom', 5000, {
//           background: '#d4edda',
//           text: '#155724'
//         });
//       })
//       .catch(error => {
//         dispatch(eventLoading(false));
//         return notify.show(error.response.data.message, 'custom', 5000, {
//           background: '#f8d7da',
//           text: '#721c24'
//         });
//       });
//   };
// }

export function removeCalendar(url, header, data, pageName, filterData) {
  return dispatch => {
    dispatch(eventLoading(true));

    axios
      .delete(BASEURL + url, header)
      .then(response => {
        // dispatch(events([]));
        // dispatch(filterevent(''));
        // dispatch(previewData([]));
        // dispatch(eventsummary());
        dispatch(getNotificationlist(`/api/v1/notification`, header));
        if (pageName == 'trendingList') {
          dispatch(
            getPopularEvents(
              `api/v1/events/getPopularEvents`,
              header,
              filterData
            )
          );
        } else {
          dispatch(
            getFilterEventDataList(
              `/api/v1/event_filter?isGeneral=${data.isGeneral}`,
              filterData,
              header
            )
          );
        }
        dispatch(eventLoading(false));
        return notify.show(response.data.message, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      })
      .catch(error => {
        dispatch(eventLoading(false));
        return notify.show(error.response.data.message, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      });
  };
}
export function getSingelEventDetail(url, header, params, check) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getSingleEventDetails({}));

    axios
      .get(
        BASEURL + url,

        {
          params: { event_type: params },
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      )
      .then(response => {
        let chnangedFormJson = response.data.result.event;
        if (check === 'auto') {
          let newupdatedEventDetails = response.data.result.event.event_details.map(
            el => {
              if (
                el.tag === 'file' &&
                (el.name === 'temporary-book-cover' ||
                  el.name === 'book-cover' ||
                  el.name === 'alternative-book-cover')
              ) {
                const upObj = { ...el };
                upObj.value = '';
                return upObj;
              }

              return el;
            }
          );
          chnangedFormJson.event_details = newupdatedEventDetails;
        }

        // console.log("this is changedFormJson--------->> ", chnangedFormJson)
        dispatch(getSingleEventDetails(chnangedFormJson));

        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else if (error.response && error.response.status === 400) {
          dispatch(duplicateEvent(true));
          return notify.show('Event Already Exists.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        } else {
          return notify.show('Error in getting results.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
      });
  };
}

export function setToDefault() {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getSingleEventDetails({}));
    dispatch(eventLoading(false));
  };
}

export function associatedEvents(url, header) {
  return dispatch => {
    // dispatch(getAssociatedEvents({}));
    dispatch(eventLoading(true));

    axios
      .get(BASEURL + url, header)
      .then(response => {
        // dispatch(getAssociatedEvents(response.data.result));
        // dispatch(eventLoading(false));

        if (response.status === 200) {
          dispatch(getAssociatedEvents(response.data.result));
        } else {
          dispatch(getAssociatedEvents({}));
        }
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));

        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          // return notify.show("error in getting results", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        }
      });
  };
}

export function getTitle(url, header) {
  return dispatch => {
    dispatch(getBookRelaseTitle([]));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        dispatch(getBookRelaseTitle(response.data.events));
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          // return notify.show("error in getting results", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        }
      });
  };
}

export function getLinkedEvents(url, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(response => {
        dispatch(eventLoading(false));
        if (response.status === 200) {
          dispatch(setLinkedEvents(response.data.result));
        } else {
          dispatch(setLinkedEvents([]));
        }
      })
      .catch(error => {
        dispatch(eventLoading(true));
      });
  };
}
export function getParentid(url, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .get(BASEURL + url, headers)
      .then(response => {
        dispatch(eventLoading(false));
        if (response.status === 200) {
          dispatch(setLinkedEvents(response.data.result));
        } else {
          dispatch(setLinkedEvents([]));
        }
      })
      .catch(error => {
        dispatch(eventLoading(true));
      });
  };
}

export function getBookReleaseForBlast(url, headers) {
  return dispatch => {
    axios
      .get(BASEURL + url, headers)
      .then(response => {
        if (response.status === 200) {
          dispatch(bookReleaseBlast(response.data.data));
        } else {
          dispatch(bookReleaseBlast([]));
        }
      })
      .catch(error => {
        // dispatch(eventLoading(true));
      });
  };
}

export function getBookReleaseOfUser(url, headers) {
  return dispatch => {
    axios
      .get(BASEURL + url, headers)
      .then(response => {
        if (response.status === 200) {
          dispatch(bookReleaseEventBlast(response.data.data));
        } else {
          dispatch(bookReleaseEventBlast([]));
        }
      })
      .catch(error => {
        // dispatch(eventLoading(true));
      });
  };
}
// export function  getAllDailyTasks(url, headers) {
//   return (dispatch) => {
//     dispatch(eventLoading(true));
//     axios
//       .get(BASEURL + url, headers)
//       .then((res) => {
//         dispatch(EventError(false));
//         dispatch(events(res.data.result.events));
//         dispatch(eventLoading(false));
//       })
//       .catch((error) => {
//         dispatch(EventError(true));
//         dispatch(eventLoading(false));
//         if (error.response && error.response.status === 403) {
//           dispatch(Unauthorized);
//         } else {
//           notify.show("failed to fetch Events", "custom", 5000, {
//             background: "#f8d7da",
//             text: "#721c24",
//           });
//         }
//         // localStorage.removeItem("token");
//       });
//   };
// }

export function searchUser(url, header, cdm) {
  return dispatch => {
    // dispatch(searchTitle([]));
    dispatch(searchUserdata([]));
    axios
      .get(BASEURL + url, header)
      .then(response => {
        if (cdm) {
          dispatch(initialAuthor(response.data.result));
        } else {
          dispatch(searchUserdata(response.data.result));
        }
      })
      .catch(error => {
        // dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}
