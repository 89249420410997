import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';

import moment from 'moment';
import {
  getProfileDetails,
  isUpgradable,
  cancelMembership
} from '../../Actions/profile-setup';
import { BASEURL } from '../../constant';
import axios from 'axios';
import MANAGEACCOUNT from './manageAccount';
import RenewMembershipModel from '../Static/renewMembershipModel';
import Layout from '../layout/layout';
class MembershipInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: [],
      checkExpireUser: '',
      isRenewalMembership: true
    };
    this.cancelMembership = this.cancelMembership.bind(this);
  }

  cancelMembership() {
    this.props.cancelMembership();
  }
  toggleExpireModal = () => {
    this.setState({ isRenewalMembership: true });
  };
  toggleCloseModalPopup = () => {
    this.setState({ isRenewalMembership: false });
  };
  //= ==================== RENDER ==========================
  render() {
    window.scrollTo(0, 0);
    //  console.log("this is the props-- -- -- -- -- -->event loading ", this.props.eventLoading);
    //  console.log("this is the props-- -- -- -- -- -->profile loading ", this.props.loading);
    //  console.log("this is the props-- -- -- -- -- -->state.userloading ", this.props.userloading);
    //  console.log("this is the props-- -- -- -- -- -->signupLoading ", this.props.signupLoading);
    //  console.log("this is the props-- -- -- -- -- -->this.props.profileData ", this.props.profileData.profile);

    if (this.props.profileData) {
      const profileData = this.props.profileData.profile;
      const member_id = profileData.membership_id;

      let cost;
      let type;

      if (member_id === '1') {
        cost = 540;
        type = 'Author Premium';
      }
      if (member_id === '2') {
        cost = 29;
        type = 'Reader';
      }
      if (member_id === '3') {
        cost = 39;
        type = 'Blogger';
      }
      if (member_id === '4') {
        cost = 120;
        type = 'Vendor';
      }
      if (member_id === '5') {
        cost = 720;
        type = 'PR Firm';
      }
      if (member_id === '6') {
        cost = 59;
        type = 'Blogger Premium';
      }
      if (member_id === '8') {
        cost = 360;
        type = 'Author';
      }

      return (
        <>
          {/* {this.props.loading ? <Loader/> : null} */}
          {localStorage.getItem('expirepop') == '1' ? (
            <Layout history={this.props.history}>
              {this.state.checkExpireUser && (
                <>
                  <RenewMembershipModel
                    // isOpen={this.state.showRenewMembershipModal}
                    isOpen={this.state.isRenewalMembership}
                    toggle={this.toggleExpireModal.bind()}
                    addClientToken={this.addClientToken}
                    planCost={cost}
                    membershipType={type}
                    membershipId={member_id}
                    value="membershiprenew"
                    closePopup={this.toggleCloseModalPopup.bind()}
                    // toggle={this.toggleRenewMembershipModal.bind(this)}
                    blast="blast"
                  />
                </>
              )}
              <div className="my-membership-page">
                <div className="container">
                  <div className="mb-4">
                    <h2 className="text-center heading"> My Membership </h2>
                  </div>
                  {/* {this.props.loading ? <Loader/> : null} */}
                  <fieldset
                    className={
                      this.props.displayMode
                        ? 'scheduler-border border-light'
                        : 'scheduler-border border-dark'
                    }
                  >
                    <legend className="scheduler-border">
                      <b>MEMBERSHIP INFO</b>
                    </legend>
                    <ul className="list-unstyled mb-0">
                      <li>
                        Email: <b>{profileData.email}</b>
                      </li>
                      <li>
                        {/* {profileData.login_type === 'social' ? (<>Username: <b>{socialProfile}</b></>) :(<>   Username: <b>{profileData.display_name}</b></>)} */}
                        Username: <b>{profileData.display_name}</b>
                      </li>
                      <li>
                        Member ID:{' '}
                        {/* <b>
                    OTR
                    {profileData.id}
                  </b> */}
                        <b>{profileData.member_id}</b>
                      </li>
                      <li>
                        Membership plan: <b>{type}</b>
                      </li>
                      <li>
                        Membership start date:{' '}
                        <b>
                          {moment(profileData.created_date).format(
                            'MMM-DD-YYYY'
                          )}
                        </b>
                      </li>
                      <li>
                        Membership renewal date:{' '}
                        <b>
                          {moment(profileData.member_expiry_date).format(
                            'MMM-DD-YYYY'
                          )}
                        </b>
                      </li>
                      <li>
                        Annual fee: <b>${cost}</b>
                      </li>

                      <li>
                        {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/dashboard/plans"
                >
                  {' '}
                  <u> VIEW ALL PLANS & PRICING</u>
                </a> */}
                        <Link to={`/dashboard/plans`} target="_blank">
                          {' '}
                          <u> VIEW ALL PLANS & PRICING</u>
                        </Link>
                      </li>
                      {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/dashboard/membership-types"
                >
                  {' '}
                  <u> VIEW ALL PLANS & PRICING</u>
                </a>
                <Link to={`/dashboard/showadvertise`}>
                  {' '}
                  <u>Show Your Past Advertise</u>
                </Link>
              </li> */}
                    </ul>

                    {/* {profileData.membership_id === '4' ? (
            ''
          ) : (
            <div className="list-box">
              <ul>
                <li>
                  Invite code:<b>jfbvkjfvbjk</b>
                </li>
                <li>
                  Invite link:<a href="">fnvjfavnmkn</a>
                </li>
              </ul>
            </div>
          )} */}
                    {this.props.profileData.profile.follow_code && (
                      <>
                        <div className="list-box">
                          <ul>
                            <li>
                              Invite code:{' '}
                              <b>
                                {this.props.profileData.profile.follow_code}
                              </b>
                            </li>
                            <li>
                              Invite link:{' '}
                              <Link
                                to={`/users/${this.props.profileData.profile.follow_code}`}
                                target="_blank"
                              >
                                {window.location.host}
                                /#/users/
                                {this.props.profileData.profile.follow_code}
                              </Link>
                            </li>
                          </ul>
                        </div>

                        {localStorage.getItem('membershipId') === '6' ? (
                          <>
                            {' '}
                            <div className="list-box mt-4 mb-1">
                              <ul>
                                <li>
                                  View Blast page:{' '}
                                  <Link to="/dashboard/blasts" target="_blank">
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div className="list-box mt-4 mb-1">
                              <ul>
                                <li>
                                  View Blast page:{' '}
                                  <Link to="/dashboard/blasts" target="_blank">
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                                <li>
                                  View My Advertisements{'  '}:{' '}
                                  <Link
                                    to="/dashboard/showadvertise"
                                    target="_blank"
                                  >
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* <div className="list-box">
            <ul>
              <li>
                Invite code:<b>jfbvkjfvbjk</b>
              </li>
              <li>
                Invite link:<a href="">fnvjfavnmkn</a>
              </li>
            </ul>
          </div> */}
                  </fieldset>
                  <MANAGEACCOUNT />
                </div>
              </div>
            </Layout>
          ) : (
            <>
              {this.state.checkExpireUser && (
                <>
                  <RenewMembershipModel
                    // isOpen={this.state.showRenewMembershipModal}
                    isOpen={this.state.isRenewalMembership}
                    toggle={this.toggleExpireModal.bind()}
                    addClientToken={this.addClientToken}
                    planCost={cost}
                    membershipType={type}
                    membershipId={member_id}
                    closePopup={this.toggleCloseModalPopup.bind()}
                    // toggle={this.toggleRenewMembershipModal.bind(this)}
                    blast="blast"
                  />
                </>
              )}
              <div className="my-membership-page">
                <div className="container">
                  <div className="mb-4">
                    <h2 className="text-center heading"> My Membership </h2>
                  </div>
                  <fieldset
                    className={
                      this.props.displayMode
                        ? 'scheduler-border border-light'
                        : 'scheduler-border border-dark'
                    }
                  >
                    <legend className="scheduler-border">
                      <b>MEMBERSHIP INFO</b>
                    </legend>
                    <ul className="list-unstyled mb-0">
                      <li>
                        Email: <b>{profileData.email}</b>
                      </li>
                      <li>
                        Username: <b>{profileData.display_name}</b>
                        {/* {profileData.login_type === 'social' ? (<>Username: <b>{socialProfile}</b></>) :(<>   Username: <b>{profileData.display_name}</b></>)} */}
                      </li>
                      <li>
                        Member ID:{' '}
                        {/* <b>
                    OTR
                    {profileData.id}
                  </b> */}
                        <b>{profileData.member_id}</b>
                      </li>
                      <li>
                        Membership plan: <b>{type}</b>
                      </li>
                      <li>
                        Membership start date:{' '}
                        <b>
                          {moment(profileData.created_date).format(
                            'MMM-DD-YYYY'
                          )}
                        </b>
                      </li>
                      <li>
                        Membership renewal date:{' '}
                        <b>
                          {moment(profileData.member_expiry_date).format(
                            'MMM-DD-YYYY'
                          )}
                        </b>
                      </li>
                      <li>
                        Annual fee: <b>${cost}</b>
                      </li>

                      <li>
                        {/* <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/dashboard/plans"
                >
                  {' '}
                  <u> VIEW ALL PLANS & PRICING</u>
                </a> */}
                        <Link to={`/dashboard/plans`} target="_blank">
                          {' '}
                          <u> VIEW ALL PLANS & PRICING</u>
                        </Link>
                      </li>
                      {/* <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/dashboard/membership-types"
                >
                  {' '}
                  <u> VIEW ALL PLANS & PRICING</u>
                </a>
                <Link to={`/dashboard/showadvertise`}>
                  {' '}
                  <u>Show Your Past Advertise</u>
                </Link>
              </li> */}
                    </ul>

                    {/* {profileData.membership_id === '4' ? (
            ''
          ) : (
            <div className="list-box">
              <ul>
                <li>
                  Invite code:<b>jfbvkjfvbjk</b>
                </li>
                <li>
                  Invite link:<a href="">fnvjfavnmkn</a>
                </li>
              </ul>
            </div>
          )} */}
                    {this.props.profileData.profile.follow_code && (
                      <>
                        <div className="list-box">
                          <ul>
                            <li>
                              Invite code:{' '}
                              <b>
                                {this.props.profileData.profile.follow_code}
                              </b>
                            </li>
                            <li>
                              Invite link:{' '}
                              <Link
                                to={`/users/${this.props.profileData.profile.follow_code}`}
                                target="_blank"
                              >
                                {window.location.host}
                                /#/users/
                                {this.props.profileData.profile.follow_code}
                              </Link>
                            </li>
                          </ul>
                        </div>

                        {localStorage.getItem('membershipId') === '6' ? (
                          <>
                            {' '}
                            <div className="list-box mt-4 mb-1">
                              <ul>
                                <li>
                                  View Blast page:{' '}
                                  <Link to="/dashboard/blasts" target="_blank">
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <>
                            {' '}
                            <div className="list-box mt-4 mb-1">
                              <ul>
                                <li>
                                  View Blast page:{' '}
                                  <Link to="/dashboard/blasts" target="_blank">
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                                <li>
                                  View My Advertisements:{' '}
                                  <Link
                                    to="/dashboard/showadvertise"
                                    target="_blank"
                                  >
                                    {window.location.host}
                                    /#/users/
                                    {this.props.profileData.profile.follow_code}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    {/* <div className="list-box">
            <ul>
              <li>
                Invite code:<b>jfbvkjfvbjk</b>
              </li>
              <li>
                Invite link:<a href="">fnvjfavnmkn</a>
              </li>
            </ul>
          </div> */}
                  </fieldset>
                  <MANAGEACCOUNT />
                </div>
              </div>
            </>
          )}
        </>
      );
    }
    return !localStorage.getItem('token') ? <h1>No Membership found</h1> : '';
  }

  async componentDidMount() {
    this.props.checkIsUpgrable();
    this.props.getUserProfile();
    const checkExpireUser = await axios.get(
      BASEURL + `/api/v1/users/getexpiremember`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    if (checkExpireUser && checkExpireUser.length > 0) {
      this.setState({ checkExpireUser: checkExpireUser.data.result[0] });
    }
  }
}
const mapStateToProps = state => ({
  isUpgradable: state.isMemberhsipUpgradable,
  loading: state.profileLoading,
  profileData: state.myProfileData,
  displayMode: state.changeMode,
  eventLoading: state.eventLoading,
  userLoading: state.userloading,
  signupLoading: state.signuploading
});

const mapDispatchToProps = dispatch => ({
  getUserProfile: () =>
    dispatch(
      getProfileDetails('/api/v1/user/profile', {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  checkIsUpgrable: () =>
    dispatch(
      isUpgradable('/api/v1/users/checkUpgradable', {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    ),
  cancelMembership: () =>
    dispatch(
      cancelMembership('/api/v1/users/cancelSubscription', {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    )
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipInfo);
