import React, { Component } from 'react';
import { Link, Route, Navigate } from 'react-router-dom';
import { Tab } from '../../Actions/event-setup';
import { connect } from 'react-redux';
import CONSTANT from '../../constant';
import '../Landing/landing.css';
import './footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.activeTab
    };
  }

  onChangeTab(tab) {
    this.props.changeTab(tab);
  }

  render() {
    let route = localStorage.getItem('membershipId');

    return (
      <>
        {localStorage.getItem('token') && route !== 'null' ? (
          <footer id="footer">
            <div className="container-fluid">
              <div className="row ">
                <div className="col-md-12">
                  <div className="footer-bottom">
                    <div className="col-md-2 col-12">
                      <p className="copyRight text-white">
                        {/* &copy; Copyright {new Date().getFullYear() + 1} OTR LLC */}
                        &copy; Copyright 2023 OTR LLC
                      </p>
                    </div>
                    <div className="col-md-8 col-12">
                      <ul className="p-0 footer-links loggedIn">
                        {this.props.activeTab === 'offers' ? (
                          <>
                            <li
                              onClick={() => {
                                this.onChangeTab('home');
                              }}
                              className={
                                this.props.activeTab === '/home'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <Link to="/dashboard/home" className="links">
                                HOME
                              </Link>
                            </li>
                            <li>|</li>
                          </>
                        ) : (
                          ''
                        )}
                        <li
                          onClick={() => {
                            this.onChangeTab('privacy');
                          }}
                          className={
                            this.props.activeTab === 'privacy'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/dashboard/privacy" className="links">
                            PRIVACY
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('condition');
                          }}
                          className={
                            this.props.activeTab === 'condition'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/dashboard/condition" className="links">
                            TERMS &amp; CONDITIONS
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('plans');
                          }}
                          className={
                            this.props.activeTab === 'plans'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/dashboard/plans" className="links">
                            MEMBERSHIP PLANS
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('about');
                          }}
                          className={
                            this.props.activeTab === 'about'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/dashboard/about" className="links">
                            ABOUT US
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('help');
                          }}
                          className={
                            this.props.activeTab === 'help'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/dashboard/help" className="links">
                            HELP
                          </Link>
                        </li>
                        <li>|</li>
                        {localStorage.getItem('membershipId') == 1 ||
                        localStorage.getItem('membershipId') == 8 ||
                        localStorage.getItem('membershipId') == 5 ||
                        localStorage.getItem('membershipId') == 9 ? (
                          <>
                            <li
                              onClick={() => {
                                this.onChangeTab('advertise');
                              }}
                              className={
                                this.props.activeTab === 'advertise'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <Link to="/dashboard/advertise" className="links">
                                ADVERTISE
                              </Link>
                            </li>
                            <li>|</li>
                          </>
                        ) : (
                          ''
                        )}
                        <li
                          onClick={() => {
                            this.onChangeTab('offers');
                          }}
                          className={
                            this.props.activeTab === 'offers'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/offers" className="links">
                            OFFERS
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-2 col-12">
                      <ul className="list-unstyled footer-links text-lg-right text-center">
                        <li>
                          <a
                            href={CONSTANT.FACEBOOK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/facebook.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.INSTAGRAM}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/instagram.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.TWITTER_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/twitter.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.YOUTUBE_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/youtube.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        ) : (
          <footer id="footer">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12">
                  <div className="footer-bottom">
                    <div className="col-md-2 col-12">
                      <p className="copyRight text-white">
                        &copy; Copyright 2023 OTR LLC
                      </p>
                    </div>
                    <div className="col-md-8 col-12">
                      <ul className="p-0 footer-links loggedOut">
                        {this.props.activeTab === 'offers' ||
                        this.props.page ? (
                          <>
                            <li
                              onClick={() => {
                                this.onChangeTab('dashboard/home');
                              }}
                              className={
                                this.props.activeTab === '/home'
                                  ? 'nav-item active'
                                  : 'nav-item'
                              }
                            >
                              <Link to="/dashboard/home" className="links">
                                HOME
                              </Link>
                            </li>
                            <li>|</li>
                          </>
                        ) : (
                          ''
                        )}
                        <li
                          onClick={() => {
                            this.onChangeTab('privacy');
                          }}
                          className={
                            this.props.activeTab === 'privacy'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/privacy" className="links">
                            PRIVACY
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('condition');
                          }}
                          className={
                            this.props.activeTab === 'condition'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/condition" className="links">
                            TERMS &amp; CONDITIONS
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('plans');
                          }}
                          className={
                            this.props.activeTab === 'plans'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/plans" className="links">
                            MEMBERSHIP PLANS
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('about');
                          }}
                          className={
                            this.props.activeTab === 'about'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/about" className="links">
                            ABOUT US
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('help');
                          }}
                          className={
                            this.props.activeTab === 'help'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/help" className="links">
                            HELP
                          </Link>
                        </li>
                        <li>|</li>

                        <li
                          onClick={() => {
                            this.onChangeTab('advertise');
                          }}
                          className={
                            this.props.activeTab === 'advertise'
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          {/* {localStorage.getItem("membershipId") == 1 ||
                localStorage.getItem("membershipId") == 8 ||
                localStorage.getItem("membershipId") == 5 ? ( */}

                          <Link to="/advertise" className="links">
                            ADVERTISE
                          </Link>
                        </li>
                        <li>|</li>
                        <li
                          onClick={() => {
                            this.onChangeTab('offers');
                          }}
                          className={
                            this.props.activeTab === 'offers' || this.props.page
                              ? 'nav-item active'
                              : 'nav-item'
                          }
                        >
                          <Link to="/offers" className="links">
                            OFFERS
                          </Link>
                        </li>
                        {/* ) : (
                  ""
                )} */}
                      </ul>
                    </div>
                    <div className="col-md-2 col-12">
                      <ul className="list-unstyled footer-links text-lg-right text-center">
                        <li>
                          <a
                            href={CONSTANT.FACEBOOK}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/facebook.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.INSTAGRAM}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/instagram.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.TWITTER_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/twitter.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href={CONSTANT.YOUTUBE_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              src={require('../../Utils/Assets/social_media/White/youtube.png')}
                              alt="logo"
                              width="40px"
                              height="40px"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        )}
      </>
    );
  }

  componentDidMount() {}
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {
  return {
    activeTab: state.Tab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeTab: tab => dispatch(Tab(tab))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
