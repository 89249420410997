import React, { useState } from 'react';
import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import './Messenger.css';
import { Button } from 'reactstrap';

export default function Messenger(props) {
  const [search, setSearch] = useState('');
  const [listMessages, setListMessages] = useState(false);
  console.log('this is the value of search--> ', search);
  return (
    <div className="container">
      <div className="mb-4">
        <h2 className="text-center heading">Messages</h2>
      </div>
      <div className="message-search-section mb-2">
        <div className="message-search-box">
          <Button
            onClick={() => setListMessages(!listMessages)}
            style={{ display: 'none' }}
          >
            <i className={`fa fa-${listMessages ? 'minus' : 'plus'}-square `} />
          </Button>
          <input
            type="text"
            placeholder="Search for conversations"
            onChange={e => setSearch(e.target.value)}
          />
          <i className="las la-search"></i>
        </div>
      </div>
      <div
        className={`messenge-page ${listMessages ? 'list-show' : 'list-hide'}`}
      >
        <div className={`conversation-list ${listMessages ? 'show' : 'hide'}`}>
          <ConversationList search={search} />
        </div>
        <div className="scrollable content">
          <MessageList myProfile={props.myProfile} />
        </div>
      </div>
    </div>
  );
}
