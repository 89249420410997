import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import axios from 'axios';
import { BASEURL } from '../constant';
import './left-logo.css';

class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.total = React.createRef();
    this.count = React.createRef(0);
    this.state = {
      advertise: []
    };
  }

  componentDidMount = async () => {
    try {
      const headers = {
        'x-authorization': localStorage.getItem('token')
      };
      const getAdvertise = await axios.get(
        BASEURL + '/api/v1/events/getAdvertiseData',
        {
          headers: headers
        }
      );
      this.total = getAdvertise.data.data.length;
      this.count.current = 0;

      if (getAdvertise.status === 200) {
        this.setState({ advertise: getAdvertise.data.data });

        // setInterval(() => {
        //   if(this.count.current>=this.total)
        //   {
        //     this.count.current=0
        //   }
        //   this.setState({ advertise: getAdvertise.data.data[this.count.current] });

        //   this.count.current+=1
        // }, 7000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const props = this.props;

    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };

    let showAd =
       props.history &&
       (props.history.location.pathname === '/dashboard/event-filter' ||
        props.history.location.pathname === '/dashboard' ||
        props.history.location.pathname === '/dashboard/event-list' ||
        props.history.location.pathname === '/dashboard/users/beta_readers' ||
        props.history.location.pathname === '/dashboard/home' ||
        props.history.location.pathname === '/dashboard/users/following' ||
        // props.history.location.pathname === '/dashboard/users/followers' ||
        props.history.location.pathname === '/dashboard/users/all' ||
        props.history.location.pathname === '/dashboard/users/most_followed' ||
        props.history.location.pathname === '/dashboard/today-event-list' ||
        props.history.location.pathname === '/dashboard/tranding-events'||
        props.history.location.pathname === '/dashboard/notification' ||
        props.history.location.pathname === '/dashboard/followingevent'
        );

    // console.log(
    //   'this is the state of this.state.advertise ',
    //   this.state.advertise
    // );

    return (
      <div className="main_left">
        <div className="logo_3 pt-2 text-center">
          <Link
            to={
              props.profileData && props.profileData !== ''
                ? '/dashboard'
                : '/user'
            }
          >
            <img src={require('../Utils/Assets/logo_3.png')} alt="logo" />
          </Link>
          {/* showAd && props.profileData && props.profileData !== "" && */}
        </div>
        {showAd &&
        <div className="advertise-section">
            <div className=" advertise container  advertise-img">
              {/* Ad Banner */}
              {this.state.advertise ? (
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={4000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={['tablet', 'mobile']}
                  deviceType={this.props.deviceType}
                  // dotListClass="custom-dot-list-style"
                  // itemClass="carousel-item-padding-40-px"
                  arrows={true}
                >
                  {this.state.advertise.map(advertise => (
                    <>
                      <Link to="/dashboard/event-filter">
                        <div>
                          <img
                            src={`${localStorage.getItem('baseUrl')}/${
                              advertise.web_image
                            }`}
                            height="200px"
                            width="400px"
                            alt="img"
                            className="ad-img"
                          ></img>
                        </div>
                      </Link>
                    </>
                  ))}
                </Carousel>
              ) : // <>
              //  <Link to="/dashboard/event-filter">
              //   <div onclick={()=> this.props.history.push('/')}>
              //     <img
              //       src={`${localStorage.getItem("baseUrl")}/${
              //         this.state.advertise.web_image
              //       }`}
              //       height="300px"
              //       width="400px"
              //       alt="img"
              //       className="ad-img"
              //     ></img>
              //   </div>
              //   </Link>
              // </>
              null}
            </div>
        </div>
        }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {};

const mapStateToProps = state => {
  return {
    preview: state.previewProfile,
    profileData: state.myProfileData
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
