export function conversationList(state = [], action) {
  switch (action.type) {
    case 'CONVERSATION_LIST':
      return action.data;
    default:
      return state;
  }
}

export function getMessageList(state = [], action) {
  switch (action.type) {
    case 'MESSAGE_LIST':
      return action.data;
    default:
      return state;
  }
}

export function getNotification(state = [], action) {
  switch (action.type) {
    case 'NOTIFICATION_LIST':
      return action.data;
    default:
      return state;
  }
}

export function countUnreadedMessages(state = 0, action) {
  switch (action.type) {
    case 'COUNT_UNREAD':
      return action.data;
    default:
      return state;
  }
}

export function sevenDaysLoading(bool) {
  return {
    type: "SEVEN_LOADING",
    status: bool,
  };
}

export function newLoader(bool) {
  return {
    type: "NEW_LOADING",
    status: bool,
  };
}
