import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import { changeDisplayMode } from '../../Actions/profile-setup';
import { addEventFilter } from '../../Actions/filters';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Dashboard from '../Profile/dashboard';
import {
  setupEvent,
  getFilterEvents,
  Tab,
  getAllEventsSummary
} from '../../Actions/event-setup';
import { BASEURL } from '../../constant';
import axios from 'axios';
import createHistory from 'history/createHashHistory';

const globalHistory = new createHistory();
const event_name = {
  event_type_1: 'Book Release',
  event_type_2: 'Giveaway',
  event_type_3: 'Book Promo',
  event_type_4: 'Release Party',
  event_type_5: 'Cover Reveal',
  event_type_6: 'Book Signing',
  event_type_7: 'ARC Sign-up',
  'ticket sales': 'Ticket Sales'
};

// Calendar week starts with monday

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1
  }
});
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

moment.tz.setDefault(timezone);

const localizer = momentLocalizer(moment);

// let allViews = Object.keys(Views).map(k => Views[k])

class EventsCalendarView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      eventData: {},
      events: [],
      startDate: null,
      endDate: null,
      eventType: '',
      days: '',
      series: null,
      category: null,
      genre: null,
      popularEvents: false,
      populerauthor: false,
      links: false,
      isOpenPrivacy: true,
      updateCancelUser: '',
      favourite: false,
      options: [
        {
          name: 'Dark',
          key: 'Dark',
          label: 'Dark',
          isChecked: false
        },
        {
          name: 'Erotica',
          key: 'Erotica',
          label: 'Erotica',
          isChecked: false
        },
        {
          name: 'LGBT',
          key: 'LGBT',
          label: 'LGBT',
          isChecked: false
        },
        {
          name: 'Paranormal/SciFi/Fantasy',
          key: 'Paranormal/SciFi/Fantasy',
          label: 'Paranormal/SciFi/Fantasy',
          isChecked: false
        },
        {
          name: 'RomCom',
          key: 'RomCom',
          label: 'RomCom',
          isChecked: false
        },
        {
          name: 'Sport',
          key: 'Sport',
          label: 'Sport',
          isChecked: false
        },
        {
          name: 'Thriller/Mystery/Suspense',
          key: 'Thriller/Mystery/SuspenseRomCom',
          label: 'Thriller/Mystery/Suspense',
          isChecked: false
        }
      ],
      keyword: [
        {
          name: 'Angsty',
          key: 'Angsty',
          label: 'Angsty',
          isChecked: false
        },
        {
          name: 'BDSM',
          key: 'BDSM',
          label: 'BDSM',
          isChecked: false
        },
        {
          name: 'Forbidden',
          key: 'Forbidden',
          label: 'Forbidden',
          isChecked: false
        },
        {
          name: 'Gritty/Edgy',
          key: 'Gritty/Edgy',
          label: 'Gritty/Edgy',
          isChecked: false
        },
        {
          name: 'Inspirational',
          key: 'Inspirational',
          label: 'Inspirational',
          isChecked: false
        },
        {
          name: ' Kinky',
          key: ' Kinky',
          label: ' Kinky',
          isChecked: false
        },
        {
          name: 'Light/Sweet',
          key: 'Light/Sweet',
          label: 'Light/Sweet',
          isChecked: false
        },
        {
          name: 'Real life issues',
          key: 'Real life issues',
          label: 'Real life issues',
          isChecked: false
        },
        {
          name: 'Taboo',
          key: 'Taboo',
          label: 'Taboo',
          isChecked: false
        },
        {
          name: 'Tear-jerker',
          key: 'Tear-jerker',
          label: 'Tear-jerker',
          isChecked: false
        }
      ]
    };
    this.showEvent = this.showEvent.bind(this);
    this.hideEvent = this.hideEvent.bind(this);
  }

  showEvent(data) {
    let params = {
      startDate: data.start_date,
      start_date: data.start_date,
      endDate: null,
      eventType: data.event_type
        ? data.event_type === 'ticket sales'
          ? data.event_type
          : data.event_type
        : null,
      days: '',
      series: null,
      category: null,
      genre: null,
      popularEvents: false,
      populerauthor: false,
      links: false,
      count: 0,
      favourite: false,
      options: [
        {
          name: 'Dark',
          key: 'Dark',
          label: 'Dark',
          isChecked: false
        },
        {
          name: 'Erotica',
          key: 'Erotica',
          label: 'Erotica',
          isChecked: false
        },
        {
          name: 'LGBT',
          key: 'LGBT',
          label: 'LGBT',
          isChecked: false
        },
        {
          name: 'Paranormal/SciFi/Fantasy',
          key: 'Paranormal/SciFi/Fantasy',
          label: 'Paranormal/SciFi/Fantasy',
          isChecked: false
        },
        {
          name: 'RomCom',
          key: 'RomCom',
          label: 'RomCom',
          isChecked: false
        },
        {
          name: 'Sport',
          key: 'Sport',
          label: 'Sport',
          isChecked: false
        },
        {
          name: 'Thriller/Mystery/Suspense',
          key: 'Thriller/Mystery/SuspenseRomCom',
          label: 'Thriller/Mystery/Suspense',
          isChecked: false
        }
      ],
      keyword: [
        {
          name: 'Angsty',
          key: 'Angsty',
          label: 'Angsty',
          isChecked: false
        },
        {
          name: 'BDSM',
          key: 'BDSM',
          label: 'BDSM',
          isChecked: false
        },
        {
          name: 'Forbidden',
          key: 'Forbidden',
          label: 'Forbidden',
          isChecked: false
        },
        {
          name: 'Gritty/Edgy',
          key: 'Gritty/Edgy',
          label: 'Gritty/Edgy',
          isChecked: false
        },
        {
          name: 'Inspirational',
          key: 'Inspirational',
          label: 'Inspirational',
          isChecked: false
        },
        {
          name: ' Kinky',
          key: ' Kinky',
          label: ' Kinky',
          isChecked: false
        },
        {
          name: 'Light/Sweet',
          key: 'Light/Sweet',
          label: 'Light/Sweet',
          isChecked: false
        },
        {
          name: 'Real life issues',
          key: 'Real life issues',
          label: 'Real life issues',
          isChecked: false
        },
        {
          name: 'Taboo',
          key: 'Taboo',
          label: 'Taboo',
          isChecked: false
        },
        {
          name: 'Tear-jerker',
          key: 'Tear-jerker',
          label: 'Tear-jerker',
          isChecked: false
        }
      ],
      isCalendar: true,
      calendarView: true
    };

    this.props.addFilterData(params);

    this.props.changeTab('EVENT FILTER');
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  eventStyleGetter(event) {
    var style = {
      background: '',
      borderRadius: '12px',
      //opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
      fontWeight: '600',
      paddingLeft: '20px'
    };
    if (
      event.event_type === 'event_type_4' ||
      event.event_type === 'Release Party'
    ) {
      style.background = 'linear-gradient(to right, #FF65FF,#FF79BC)';
    }
    if (
      event.event_type === 'event_type_1' ||
      event.event_type === 'Book Release'
    ) {
      style.background = 'linear-gradient(to right,#65C1FF, #7193FF)';
    }
    if (
      event.event_type === 'event_type_2' ||
      event.event_type === 'Giveaway'
    ) {
      style.background = 'linear-gradient(to right,#9FFF81, #75ffc9)';
    }
    if (
      event.event_type === 'event_type_3' ||
      event.event_type === 'Cover Reveal Promo'
    ) {
      style.background = 'linear-gradient(to right, #ff8181,#FF964F)';
    }

    if (
      event.event_type === 'event_type_5' ||
      event.event_type === 'Cover Reveal'
    ) {
      style.background = 'linear-gradient(to right, #fFDB69, #F3FF85)';
    }
    if (
      event.event_type === 'event_type_6' ||
      event.event_type === 'Book Signing'
    ) {
      style.background = 'linear-gradient(to right, #8E5BFF,#B469FF)';
    }
    if (
      event.event_type === 'event_type_7' ||
      event.event_type === 'ARC Sign-up'
    ) {
      style.background = 'linear-gradient(to right,#FF5E8E, #FF5858)';
    }
    if (event.event_type === 'ticket sales') {
      style.background = 'linear-gradient(to right, #B9B9FF,#DCB9FF)';
    }

    // console.log({style,event})

    return {
      style
    };
  }
  togglePrivacyModal = history => {
    this.setState({ isOpenPrivacy: true });
  };
  // cancelUser = () => {
  //   alert('jjjjj');
  // };

  cancelUser = async () => {
    await axios.post(
      BASEURL + `/api/v1/users/canceluser`,
      {},
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );
    this.setState({ isOpenPrivacy: false });
    globalHistory.push('/dashboard/my-events');
  };

  getLastMonday = date => {
    // Create a Moment object with the given date
    const inputDate = moment(date);

    // Subtract the current day of the week (0 = Sunday, 6 = Saturday)
    const daysToSubtract = (inputDate.day() + 6) % 7;

    // Subtract the days to get the last Monday
    const lastMonday = inputDate.subtract(daysToSubtract, 'days');

    return lastMonday.format('YYYY-MM-DD'); // Format the date as desired
  };

  getNextSunday = date => {
    // Create a Moment object with the given date
    const inputDate = moment(date);

    // Calculate the number of days to add to reach the next Sunday
    const daysToAdd = (7 - inputDate.day()) % 7;

    // Add the days to get the next Sunday
    const nextSunday = inputDate.add(daysToAdd, 'days');

    return nextSunday.format('YYYY-MM-DD'); // Format the date as desired
  };

  //= ==================== RENDER ==========================
  render() {
    // console.log("here is the eventmode -----> ", this.props.eventmode);
    let data = this.props.eventsData;
    let membershipId = localStorage.getItem('membershipId');

    let parsedData = JSON.parse(JSON.stringify(data));
    if (data.length > 0) {
      data.forEach(element => {
        element.start = moment(element.start_date).format(
          'YYYY-MM-DD 12:00:00'
        );
        element.end = moment(element.start_date).format('YYYY-MM-DD 12:00:00');
      });
    }

    let data1;
    if (membershipId === '2' || membershipId === '4') {
      data1 = data.filter(el => {
        if (el.arc_sign_up !== 'Open to Bloggers/PR Firms') {
          return el;
        }
      });
    } else {
      data1 = data;
    }

    data1 = data1.map(event => {
      let changeObj = { ...event };
      if (
        event.event_type === 'event_type_4' ||
        event.event_type === 'Release Party'
      ) {
        changeObj.prefrenceOrder = 1;
      }
      if (
        event.event_type === 'event_type_1' ||
        event.event_type === 'Book Release'
      ) {
        changeObj.prefrenceOrder = 6;
      }
      if (
        event.event_type === 'event_type_2' ||
        event.event_type === 'Giveaway'
      ) {
        changeObj.prefrenceOrder = 5;
      }
      if (
        event.event_type === 'event_type_3' ||
        event.event_type === 'Cover Reveal Promo'
      ) {
        changeObj.prefrenceOrder = 3;
      }

      if (
        event.event_type === 'event_type_5' ||
        event.event_type === 'Cover Reveal'
      ) {
        changeObj.prefrenceOrder = 4;
      }
      if (
        event.event_type === 'event_type_6' ||
        event.event_type === 'Book Signing'
      ) {
        changeObj.prefrenceOrder = 7;
      }
      if (
        event.event_type === 'event_type_7' ||
        event.event_type === 'ARC Sign-up'
      ) {
        changeObj.prefrenceOrder = 2;
      }
      if (event.event_type === 'ticket sales') {
        changeObj.prefrenceOrder = 8;
      }

      return changeObj;
    });

    // console.log("this is the data1-------->>>>>> ", data1)

    return (
      <>
        {' '}
        <div className="container left calendar-main-page">
          <div className="color_guide mb-3">
            <div className="row">
              <div className="legend">
                <span className="color_box">RELEASE PARTY</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">ARC SIGNUP</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">BOOK PROMO</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">COVER REVEAL</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">GIVEAWAY</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">BOOK RELEASE</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>

              <div className="legend">
                <span className="color_box text-uppercase">
                  Boxes & Signings
                </span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box text-uppercase">
                  Ticket/box sales
                </span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
            </div>
          </div>

          {!this.props.isReader &&
          !this.props.isBlogger &&
          this.props.pathIsDashboard ? (
            <div className="col-md-12 mb-2">
              <div className="setup-btn">
                <button
                  onClick={e => this.props.openEventModal()}
                  className={
                    this.props.isTheameLight
                      ? 'btn bg-btn daily'
                      : 'btn bg-btn white-button  daily'
                  }
                >
                  <span>Set up Event</span>
                  <img
                    src={require('../../Utils/Assets/plus.png')}
                    alt="cup-icon"
                  />
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div
            className={
              this.props.isTheameLight
                ? 'calendar light-calendar light hghgf'
                : 'calendar calendar-dark dark-calendar'
            }
          >
            <Calendar
              className="calender-view-otr"
              // style={{ height: 1100 }}
              // views={allViews}
              // events={parsedData}
              events={data1.sort((a, b) =>
                a.prefrenceOrder < b.prefrenceOrder ? -1 : 1
              )}
              selectable={true}
              step={720}
              localizer={localizer}
              // popup
              // popupClassName="my-popup-class"
              // components={{
              //   eventWrapper: ({ children }) => (
              //   <div className="my-custom-popup-class">{children}</div>
              //   ),
              //   }}
              border="red"
              defaultView="month"
              views={['month']}
              defaultDate={new Date()}
              onSelectEvent={event => this.showEvent(event)}
              eventPropGetter={this.eventStyleGetter}
              popupOffset={{ x: 30, y: 20 }}
              onSelectSlot={e => {
                let data = {
                  startDate: moment(e.start).format('YYYY-MM-DD'),
                  start_date: moment(e.start).format('YYYY-MM-DD')
                };
                this.showEvent(data);
                globalHistory.push(
                  `/dashboard/event-filter?startDate=${moment(e.start).format(
                    'YYYY-MM-DD'
                  )}`
                );
              }}
              onNavigate={(date, flipUnit, prevOrNext) => {
                console.log(' i am callled onNavigate0-0----');
                let start = '';
                let end = '';
                if (flipUnit === 'month') {
                  start = moment(date)
                    .startOf('month')
                    .toDate();
                  end = moment(date)
                    .endOf('month')
                    .toDate();
                } else if (flipUnit === 'week') {
                  start = moment(date)
                    .startOf('week')
                    .toDate();
                  end = moment(date)
                    .endOf('week')
                    .toDate();
                } else if (flipUnit === 'day') {
                  start = moment(date)
                    .startOf('day')
                    .toDate();
                  end = moment(date)
                    .endOf('day')
                    .toDate();
                }
                let nStart = moment(start).format('YYYY-MM-DD');
                let nEnd = moment(end).format('YYYY-MM-DD');
                if (!(prevOrNext === 'TODAY')) {
                  nStart = this.getLastMonday(start);
                  nEnd = this.getNextSunday(end);
                }
                // console.log('here is the  monday and sunday date---> ', {
                //   nStart,
                //   nEnd
                // });

                this.props.getAllEvents(this.props.eventmode, nStart, nEnd);
                if (prevOrNext === 'DATE') {
                  let data = {
                    startDate: date,
                    start_date: date
                  };
                  this.showEvent(data);
                }

                this.props.childToProps(prevOrNext);
              }}
            />
          </div>
        </div>
      </>
    );
  }
  async componentDidMount() {
    const exist = await axios.get(BASEURL + `/api/v1/users/getcanceluser`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    // if (localStorage.getItem('expirepop') == 1) {
    //   window.location.href = 'http://localhost:3000/#/dashboard/membership';
    // }

    // this.setState({
    //   updateCancelUser: exist.data.result[0].cancel_member_update
    // });
  }
}
const mapStateToProps = state => ({
  loading: state.eventLoading,
  summary: state.eventsummary
});

const mapDispatchToProps = dispatch => ({
  setupEvent: () => dispatch(setupEvent()),
  getEvents: (params, history) =>
    dispatch(
      getFilterEvents(
        `/api/v1/events?eventType=${params.type}&startDate=${params.date}&isGeneral=${params.mode}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        history
      )
    ),
  getAllEvents: (eventMode, startDate, endDate) =>
    dispatch(
      getAllEventsSummary(
        `/api/v1/events/day/count?isGeneral=${eventMode}&startDate=${startDate}&endDate=${endDate}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      )
    ),
  addFilterData: data => dispatch(addEventFilter(data, true)),
  changeTab: tab => dispatch(Tab(tab)),
  getDisplayMode: () => dispatch(changeDisplayMode())
});

export default connect(mapStateToProps, mapDispatchToProps)(EventsCalendarView);
