import React from 'react';
// import { APIURL } from '../constant';
import { BASEURL } from '../constant';
const apiUrl = 'https://api.ontargetromance.com';

// const apiUrl = 'http://localhost:3001';
const FileBox = props => (
  // if(props.attributes.value!==""){
  //     let path = props.attributes.value;
  //     return(
  //       <img src= {path} height="60"  alt="profile"/>
  //     );
  //   }

  <>
    {props.attributes.value !== '' &&
    (props.type === 'book-cover' || props.type === 'alternative-book-cover') ? (
      <>
        {/* <img
          src={`${apiUrl}${props.attributes.value}`}
          height="60"
          alt="profile"
        /> */}
      </>
    ) : (
      ''
    )}
    <label htmlFor='vandorProfile' style={{ border: '1px solid rgb(172, 172, 172)', font: '14px Raleway', padding: '10.5px 10px', borderRadius: '5px', marginBottom: '0px !important', marginBottom: '0px' }}>
      <span style={{ border: '1px solid #acacac', padding: '4px', marginRight: '10px', borderRadius: '2px', backgroundColor: '#efefef', fontSize: '14px' }}>Choose File</span>
      <span id="file-chosen">{props.fileName}</span>
    </label>
    <input
      className="fileInput1212"
      id="vandorProfile"
      type={props.attributes.type}
      style={{ overflow: 'hidden' }}
      onChange={e => {
        return props.onFileHandler(
          e.target.files[0],
          props.attributes.size,
          props.type,
          props.index
        );
      }}
      data-max-size={props.attributes.size}
      required={
        localStorage.getItem('mode') && localStorage.getItem('mode') == 'edit'
          ? false
          : props.attributes.validation
      }
      accept={props.attributes.accept}
      hidden
    />
    <div className="upload_text" style={{ margin: 10 }}>
      {/* <span style={{ color: "#1f1f1f", fontWeight: "600" }}>Upload Image </span> */}
      <p style={{ color: '#8a8a8a', fontWeight: '600' }}>(max file size 3MB)</p>
    </div>
  </>
);
export default FileBox;
