import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { addSortFilter } from '../../Actions/filters';
import './dashboard.css';
import { getFilterEventDataList } from '../../Actions/event-setup';
import { changeProperty, multiSort } from '../../Utils/useFunction';

class SortPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: ['']
    };
    // this.add = this.add.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    // this.htmlParser = new HTMLParser();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setSelectedOption = i => e => {
    let questions = [...this.state.questions];
    let value = e.target.value;

    if (e.target.value == 'genre') {
      questions[i] = { [value]: 'Contemporary' };
      this.setState({
        questions
      });
    } else if (e.target.value == 'category') {
      questions[i] = { [value]: 'Adult' };
      this.setState({
        questions
      });
    } else if (e.target.value == 'stand-alone/series') {
      questions[i] = { [value]: 'Stand-alone' };
      this.setState({
        questions
      });
    } else if (e.target.value == 'host') {
      questions[i] = { [value]: 'Author' };
      this.setState({
        questions
      });
    } else {
      questions[i] = { [value]: 'TRUE' };
      this.setState({
        questions
      });
    }
  };

  handleChange = (i, key) => e => {
    let questions = [...this.state.questions];
    let value = e.target.value;

    questions[i][key] = value;
    this.setState({
      questions
    });
  };

  handleDelete = i => e => {
    e.preventDefault();
    let questions = [
      ...this.state.questions.slice(0, i),
      ...this.state.questions.slice(i + 1)
    ];
    this.setState({
      questions
    });
  };

  addQuestion = e => {
    e.preventDefault();
    let questions = this.state.questions.concat(['']);
    this.setState({
      questions
    });
  };

  handleSubmit(event) {
   
    const newData = changeProperty(this.props.eventsData)
    // data = [...newData];


    const sortFilter = this.state.questions;
    let data = {};
    this.props.addSortFilter(sortFilter);
    if (this.props.filterData) {
      data = this.props.filterData;
      data.sort = sortFilter;
    } else {
      data = sortFilter;
    }
  
    let newArr = [];
    let newArr1 = [];
    data.sort.forEach(element => {
      let obj = {
        key: Object.keys(element)[0],
        inverse: element[Object.keys(element)[0]] === 'TRUE' ? false : true
      };
      // console.log("============ >> >object.keys", Object.keys(element)[0])
      if (
        Object.keys(element)[0] === 'genre' ||
        Object.keys(element)[0] === 'host' ||
        Object.keys(element)[0] === 'category' ||
        Object.keys(element)[0] === 'stand-alone/series'
      ) {
        obj.inverse = element[Object.keys(element)[0]];
        newArr1.push(obj);
        return;
      }
      if (
        Object.keys(element)[0] === 'title' ||
        Object.keys(element)[0] === 'name_of_event'
      ) {
        obj.key = 'commanTitle';
      }
      if (Object.keys(element)[0] === 'preorder') {
        obj.key = 'pre';
      }
      if (Object.keys(element)[0] === 'date_added') {
        obj.key = 'created_date';
      }
      newArr.push(obj);
    });

    let sortedArr = multiSort.call(newData, newArr);

    if (newArr1.length > 0) {
      newArr1.forEach(el => {
        if (el.key === 'stand-alone/series') {
          sortedArr.sort((a, b) => {
            const cleanedString1 =a.type ? a.type.replace(/[\[\]\\]/g, '') : null;
              const jsonArray1 = cleanedString1 ? JSON.parse(cleanedString1) : '';

              const cleanedString2 =b.type ? b.type.replace(/[\[\]\\]/g, '') : null;
              const jsonArray2 = cleanedString2 ? JSON.parse(cleanedString2) : '';
              if (el.inverse.toLowerCase() === jsonArray1.toLowerCase() && el.inverse.toLowerCase() !== jsonArray2.toLowerCase())
              {
                return -1;
              }else
              if(el.inverse.toLowerCase() !== jsonArray1.toLowerCase() && el.inverse.toLowerCase() === jsonArray2.toLowerCase())
              {
                return 1;
              }else{
                return 0;
              }
          });
        } else if (el.key === 'category') {
          sortedArr.sort((a, b) => {
              const cleanedString1 =a.category ? a.category.replace(/[\[\]\\]/g, '') : null;
              const jsonArray1 = cleanedString1 ? JSON.parse(cleanedString1) : '';

              const cleanedString2 =b.category ? b.category.replace(/[\[\]\\]/g, '') : null;
              const jsonArray2 = cleanedString2 ? JSON.parse(cleanedString2) : '';
              if (el.inverse.toLowerCase() === jsonArray1.toLowerCase() && el.inverse.toLowerCase() !== jsonArray2.toLowerCase())
              {
                return -1;
              }else
              if(el.inverse.toLowerCase() !== jsonArray1.toLowerCase() && el.inverse.toLowerCase() === jsonArray2.toLowerCase())
              {
                return 1;
              }else{
                return 0;
              }
          });
        } else if (el.key === 'genre') {
          sortedArr.sort((a, b) => {
              const cleanedString1 = a.genre ? a.genre.replace(/[\[\]\\]/g, '') : null;
              const jsonArray1 = cleanedString1 ? JSON.parse(cleanedString1): '';
              const cleanedString2 = b.genre ? b.genre.replace(/[\[\]\\]/g, ''): null;
              const jsonArray2 = cleanedString2 ? JSON.parse(cleanedString2) : '';
              if (el.inverse.toLowerCase() === jsonArray1.toLowerCase() && el.inverse.toLowerCase() !== jsonArray2.toLowerCase())
              {
                return -1;
              }else
              if(el.inverse.toLowerCase() !== jsonArray1.toLowerCase() && el.inverse.toLowerCase() === jsonArray2.toLowerCase())
              {
                return 1;
              }else{
                return 0;
              }
          });
        } else if (el.key === 'host') {
          sortedArr.sort((a, b) => {
        
            if (el.inverse.toLowerCase() === a['authorType'].toLowerCase() && el.inverse.toLowerCase() !== b['authorType'].toLowerCase())
            {
              return -1;
            }else
            if(el.inverse.toLowerCase() !== a['authorType'].toLowerCase() && el.inverse.toLowerCase() === b['authorType'].toLowerCase())
            {
              return 1;
            }else{
              return 0;
            }

          });
        }
      });
    }
    this.props.setEventData(sortedArr);
    // this.props.getFilterEvent(
    //   `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
    //   data,
    //   { headers: { "x-authorization": localStorage.getItem("token") } }
    // );
  }
  //===================== RENDER ==========================
  render() {
    const options = [
      {
        name: 'Select',
        key: 'Select',
        label: 'Select',
        value: 'Select',
        selected: true,
        isUsed: false
      },
      {
        name: 'Title',
        key: 'Title',
        label: 'Title',
        value: 'title',
        selected: false,
        isUsed: false
      },
      {
        name: 'Name of event',
        key: 'Name of event',
        label: 'Name of event',
        value: 'name_of_event',
        selected: false,
        isUsed: false
      },
      {
        name: 'Event type',
        key: 'Event type',
        label: 'Event type',
        selected: false,
        value: 'event_type',
        isUsed: false
      },
      {
        name: 'Author first name',
        key: 'Author first name',
        label: 'Author first name',
        selected: false,
        value: 'author_first_name',
        isUsed: false
      },
      {
        name: 'Author last name',
        key: 'Author last name',
        label: 'Author last name',
        selected: false,
        value: 'author_last_name',
        isUsed: false
      },
      {
        name: 'Co-Author',
        key: 'Co-Author',
        label: 'Co-Author',
        selected: false,
        value: 'coauthor',
        isUsed: false
      },
      {
        name: 'Host',
        key: 'Host',
        label: 'Host',
        selected: false,
        value: 'host',
        isUsed: false
      },
      {
        name: 'Genre',
        key: 'Genre',
        label: 'Genre',
        selected: '',
        value: 'genre',
        isUsed: false
      },
      // {
      //   name: "Category",
      //   key: "Category",
      //   label: "Category",
      //   selected: false,
      //   value: "category",
      //   isUsed: false,
      // },
      {
        name: 'Type',
        key: 'Stand-alone/series',
        label: 'Type',
        selected: false,
        value: 'stand-alone/series',
        isUsed: false
      },
      {
        name: 'Pre-order: Yes/No',
        key: 'Preorder:Yes/No',
        label: 'Preorder:Yes/No',
        selected: false,
        value: 'preorder',
        isUsed: false
      },
      {
        name: 'Category',
        key: 'Category',
        label: 'Category',
        selected: false,
        value: 'category',
        isUsed: false
      },
      {
        name: 'Date added',
        key: 'Date Added',
        label: 'Date added',
        selected: false,
        value: 'date_added',
        isUsed: false
      },
      {
        name: 'Start date',
        key: 'Start Date',
        label: 'Start Date',
        selected: false,
        value: 'start_date',
        isUsed: false
      },
      {
        name: 'End date',
        key: 'End Date',
        label: 'End Date',
        selected: false,
        value: 'end_date',
        isUsed: false
      },
      {
        name: 'Duration',
        key: 'Duration',
        label: 'Duration',
        selected: false,
        value: 'duration',
        isUsed: false
      }
    ];

    if (this.state.questions.length > 0) {
      this.state.questions.map(result => {
        const key = Object.keys(result);

        if (key.length) {
          let obj = options.find(x => x.value.toLowerCase() == key);
          if (obj) {
            let index = options.indexOf(obj);

            return (options[index].isUsed = true);
          }
        }
      });
    }

    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          size="lg"
          className="sort-event-popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h5 className="text-center p-0">Sort Events</h5>
          </ModalHeader>
          <ModalBody>
            {/* <form > */}
            {this.state.questions.map((sortValue, index) => (
              <span key={index}>
                <div class="row">
                  <div class="form-group col-md-5">
                    <label for={+index}>Choose Field</label>
                    <select
                      class="custom-select"
                      id={+index}
                      name={index}
                      onChange={this.setSelectedOption(index)}
                      value={Object.keys(this.state.questions[index])}
                    >
                      {options.map((select, index) => {
                        return (
                          <option
                            value={select.value}
                            selected={select.selected}
                            disabled={select.isUsed}
                            key={index}
                          >
                            {select.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {(Object.keys(this.state.questions[index]) == 'title' ||
                    Object.keys(this.state.questions[index]) ==
                      'name_of_event' ||
                    Object.keys(this.state.questions[index]) == 'event_type' ||
                    Object.keys(this.state.questions[index]) ==
                      'author_first_name' ||
                    Object.keys(this.state.questions[index]) ==
                      'author_last_name') && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}
                        <option value="TRUE">A to Z </option>
                        <option value="FALSE">Z to A</option>
                      </select>
                    </div>
                  )}
                  {Object.keys(this.state.questions[index]) ==
                    'stand-alone/series' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}

                        <option value="Stand-alone">Stand-alones</option>
                        <option value="Stand-alone in a series">
                          Stand-alone in a series
                        </option>
                        <option value="Book in a series">
                          Book in a series
                        </option>
                      </select>
                    </div>
                  )}
                  {Object.keys(this.state.questions[index]) == 'host' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}

                        {/* <option value='Means member who added to calendar'>Means member who added to calendar</option> */}
                        <option value="Author"> Author</option>
                        <option value="Blogger">Blogger Premium</option>
                        <option value="PrFirm">PRFirm</option>
                      </select>
                    </div>
                  )}

                  {Object.keys(this.state.questions[index]) == 'genre' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}

                        <option value="Contemporary">Contemporary</option>
                        <option value="Historical">Historical</option>
                      </select>
                    </div>
                  )}
                  {Object.keys(this.state.questions[index]) == 'category' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}

                        <option value="Adult">Adult</option>
                        <option value="New adult">New adult</option>
                        <option value="Young adult">Young adult</option>
                      </select>
                    </div>
                  )}

                  {Object.keys(this.state.questions[index]) == 'preorder' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}
                        <option value="TRUE">YES</option>
                        <option value="FALSE">NO</option>
                      </select>
                    </div>
                  )}
                  {Object.keys(this.state.questions[index]) == 'coauthor' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option  value="">Select</option> */}
                        <option value="TRUE">A-Z</option>
                        <option value="FALSE">Z-A</option>
                      </select>
                    </div>
                  )}

                  {(Object.keys(this.state.questions[index]) == 'date_added' ||
                    Object.keys(this.state.questions[index]) == 'start_date' ||
                    Object.keys(this.state.questions[index]) == 'end_date') && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option value="">Select</option> */}
                        <option value="TRUE">Oldest to newest</option>
                        <option value="FALSE">Newest to oldest</option>
                      </select>
                    </div>
                  )}

                  {Object.keys(this.state.questions[index]) == 'duration' && (
                    <div class="form-group col-md-5">
                      <label for="dateofevent">Sort by</label>
                      <select
                        class="custom-select"
                        id="dateofevent"
                        value={Object.values(this.state.questions[index])}
                        onChange={this.handleChange(
                          index,
                          Object.keys(this.state.questions[index])
                        )}
                      >
                        {/* <option value="">Select</option> */}
                        <option value="TRUE">Shortest to longest</option>
                        <option value="FALSE">Longest to sortest</option>
                      </select>
                    </div>
                  )}

                  <div class="form-group col-md-2 ">
                    <button
                      class="delete_btn"
                      onClick={this.handleDelete(index)}
                    >
                      <i class="fa fa-times-circle"></i>
                    </button>
                  </div>
                </div>
              </span>
            ))}

            <button className="plus_btn" onClick={this.addQuestion}>
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              class="btn btn-dark pull-right"
              onClick={() => {
                this.handleSubmit(this);
                this.props.toggle(this);
              }}
              value="Submit"
            >
              Submit
            </button>
            {/* </form> */}
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    if (this.props.sortFilter) {
      let questions = this.props.sortFilter;
      this.setState({
        questions
      });
    }
  }
}

const mapStateToProps = state => {
  return {
    sortFilter: state.sort,
    filterData: state.eventFilter,
    eventMode: state.getEventMode,
    eventsData: state.getFilterEventData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addSortFilter: data => dispatch(addSortFilter(data)),
    getFilterEvent: (url, data, header) =>
      dispatch(getFilterEventDataList(url, data, header)),
    setEventData: data =>
      dispatch({
        type: 'GET_FILTER_DATA',
        data
      })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SortPopupModal);
