export const defaultSort = (data, propetry) => 
{
  // console.log("data in default sort--> ", data);
    return data.sort((a, b) => {
      if(!a[propetry] || !b[propetry]) return;
        let first_val = a[propetry].split(' ');
        let secon_val = b[propetry].split(' ');
        if (
          (new Date(first_val[0]) >= new Date(secon_val[0]) && first_val[1] > secon_val[1]) ||
          (new Date(first_val[0]) >= new Date(secon_val[0]) && first_val[1] < secon_val[1])
        ) {
          return -1;
        } else if (
          (new Date(first_val[0]) <= new Date(secon_val[0]) && first_val[1] > secon_val[1]) ||
          (new Date(first_val[0]) <= new Date(secon_val[0]) && first_val[1] < secon_val[1])
        ) {
          return 1;
        }else 
        {
            return 0;
        }
     } )
}

export const defaultSortByDate = (data, property) => 
{
    return data.sort((a, b) => {
      if(!a[property] || !b[property]) return;
        return new Date(b[property]) - new Date(a[property])
     } )
}


export function changeProperty(data)
{
  let newData = [];
  data.map(el => {
    let obj = { ...el };
    // console.log("el.event_type--> ", el.event_type);
    if (el.event_type === 'Giveaway') {
      obj.commanTitle = el.giveaway
        ? el.giveaway.length > 20
          ? el.giveaway.substr(0, 20) + '...'
          : el.giveaway
        : '';
    } else if (el.event_type === 'Release Party') {
      obj.commanTitle = el.releaseparty
        ? el.releaseparty.length > 20
          ? el.releaseparty.substr(0, 20) + '...'
          : el.releaseparty
        : '';
    } else {
      obj.commanTitle = el.title
        ? el.title.length > 20
          ? el.title.substr(0, 20) + '...'
          : el.title
        : '';
    }
    obj.commanTitle = obj.commanTitle.toLowerCase();

    if (el.membership_id == 1) {
      obj.authorType = 'Author Premium';
    } else if (el.membership_id == 2) {
      obj.authorType = 'Reader';
    } else if (el.membership_id == 3) {
      obj.authorType = 'Blogger Standard';
    } else if (el.membership_id == 4) {
      obj.authorType = 'Vendor';
    } else if (el.membership_id == 5) {
      obj.authorType = 'PR Firm';
    } else if (el.membership_id == 6) {
      obj.authorType = 'Blogger Premium';
    } else if (el.membership_id == 8) {
      obj.authorType = 'Author Standard';
    }

    newData.push(obj);
  }, []);

  return newData;
}

export function multiSort(sorts)
{
  console.log("this is the this value----> ", this)
    sorts.map(sort => {
      sort.uniques = Array.from(new Set(this.map(obj => obj[sort.key])));

      sort.uniques = sort.uniques.sort((a, b) => {
    
        if (typeof a == 'string') {
          return sort.inverse ? b.localeCompare(a) : a.localeCompare(b);
        } else if (typeof a == 'number') {
          return sort.inverse ? b - a : a - b;
        } else if (typeof a == 'boolean') {
          let x = sort.inverse
            ? a === b
              ? 0
              : a
              ? -1
              : 1
            : a === b
            ? 0
            : a
            ? 1
            : -1;
          return x;
        }
        return 0;
      });
    });

    const weightOfObject = obj => {
      let weight = '';
      sorts.map(sort => {
        let zeropad = `${sort.uniques.length}`.length;
        weight += sort.uniques
          .indexOf(obj[sort.key])
          .toString()
          .padStart(zeropad, '0');
      });
      //obj.weight = weight; // if you need to see weights
      return weight;
    };

    this.sort((a, b) => {
      // console.log("------------------> >>> ", )
      return weightOfObject(a).localeCompare(weightOfObject(b));
    });

    return this;
}