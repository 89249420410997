import React from 'react';

const SelectBox = props => (
  <>
    <label>
      {props.attributes.placeholder === 'Total number of books in the series'
        ? 'Total number of books in the series*:'
        : props.attributes.placeholder === 'Country'
        ? 'Country (or Online)*:'
        : props.attributes.placeholder === 'Time Zone*'
        ? 'Time Zone*:'
        : ''}
    </label>
    {(props.attributes.display_name == 'website' || props.attributes.display_name == 'Service' || props.attributes.display_name == 'Country'  || props.attributes.display_name == 'othersites') ? (
      <select
        className={props.attributes.className}
        placeholder={props.attributes.placeholder}
        value={props.attributes.value ? props.attributes.value : ''}
        onChange={e =>
          props.IsUnderCustom
            ? props.onCustomHandler(
                e.target.value,
                props.index,
                props.parentIndex
              )
            : props.onChangeTextHandler(e.target.value, props.index)
        }
        required={props.attributes.validation}
      >
        <option value=""> {props.attributes.placeholder} </option>
        <Options options={props.attributes.options} />
      </select>
    ) : (
      <select
        className={props.attributes.className}
        placeholder={props.attributes.placeholder}
        value={props.attributes.value ? props.attributes.value : ''}
        onChange={e =>
          props.IsUnderCustom
            ? props.onCustomHandler(
                e.target.value,
                props.index,
                props.parentIndex
              )
            : props.onChangeTextHandler(e.target.value, props.index)
        }
        required={props.attributes.validation}
      >
        <optgroup style={{ maxHeight: '50px' }}>
          <option value="" key="">
            {props.attributes.placeholder}
          </option>
          <Options options={props.attributes.options} />
        </optgroup>
      </select>
    )}
  </>
);

const Options = props =>
  props.options.map((el, index) => (
    <option value={el.value} key={`${el.value}_${index}`}>
      {el.text}
    </option>
  ));
export default SelectBox;
