import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { setupEvent, getFilterEventDataList ,addEventToFavourite} from '../../../Actions/event-setup';
import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';
import Loader from '../../../Components/Loader/loader';

const overlayNoRowsTemplate = '<span>No record found</span>';

class NotificaitonList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      data: [],
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 180,

          cellRenderer: function(event_type) {
            var backgroundColor;
            var value;
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
              value = 'Book Release';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
              value = 'Giveaway';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
              value = 'Book Promo';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF65FF,#FF79BC';
              value = 'Release Party';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#fFDB69, #F3FF85';
              value = 'Cover Reveal';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#8E5BFF,#B469FF';
              value = 'Book Signing';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Sign-up'
            ) {
              backgroundColor = '#FF5E8E, #FF5858';
              value = 'ARC Sign-up';
            }
            if (event_type.value === 'ticket sales') {
              console.log('hii');
              backgroundColor = '#B9B9FF,#DCB9FF';
              value = 'Ticket Sales';
            }
            console.log('event_type- = - = - = - = - = - = - => ', {
              event_type,
              value
            });
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              value
            );
          }
        },
        {
          headerName: 'TITLE/NAME',
          field: 'commanTitle',
          sortable: true,
          filter: true,
          width: 130,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,
          width: 160,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 150,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 140,
          cellRenderer: function(endDate) {
            return moment(endDate.data.end_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'TIME',
          field: 'time',
          sortable: true,
          filter: true,
          width: 140,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          width: 140,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          width: 150,
          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        }
        // {
        //     headerName: "FAVOURITE", field: "favourite", sortable: true, filter: true, resizable: true,
        //     cellRenderer: function (favourite) {
        //         if (favourite.data.favourite)
        //         {
        //         return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
        //         }
        //         else{
        //             return '<i class="fa fa-star-o" style="font-size:20px;"></i>'
        //         }
        //     }
        // },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    // return `${
    //   params.data.author_first_name ? params.data.author_first_name : ''
    // } ${params.data.author_last_name ? params.data.author_last_name : ''}`;
    let midName = params.data.author_mid_name;

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }
  showTime(params) {
    return params.data.time ? this.convertTo12Hour(params.data.time) : '-';
  }
  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    // var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  getFormattedValue(params) {}

  titleCellRenderer(params) {
    if (params.data) {
      const eventDetails = JSON.parse(params.data.event_details_with_values);
      if (
        params.data.event_type === 'Release Party' ||
        params.data.event_type === 'event_type_4' ||
        params.data.event_type === 'Giveaway' ||
        params.data.event_type === 'event_type_2'
      ) {
        let newTitle = '';
        if (
          params.data.event_type === 'Release Party' ||
          params.data.event_type === 'event_type_4'
        ) {
          newTitle = eventDetails[6]['release-party-event-name']
            ? eventDetails[6]['release-party-event-name']
            : '';
        } else if (
          params.data.event_type === 'Giveaway' ||
          params.data.event_type === 'event_type_2'
        ) {
          newTitle = eventDetails[6]['Name of Giveaway']
            ? eventDetails[6]['Name of Giveaway']
            : '';
        }
        return newTitle;
      }
      return `${params.data.title ? params.data.title : ''} `;
    }
  }

  showEvent(e) {
    if (e.column.colId == 'favourite') {
      const data = this.props.filterData;
      data.isGeneral = this.props.eventMode;
      if (this.props.sort) {
        data.sort = this.props.sort;
      }
      this.props.addToFavourite(
        `/api/v1/favourite/${e.data.id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        data,
        'eventFilter'
      );
    } else {
      const event_name = {
        event_type_1: 'Book Release',
        event_type_2: 'Giveaway',
        event_type_3: 'Book Promo',
        event_type_4: 'Release Party',
        event_type_5: 'Cover Reveal',
        event_type_6: 'Book Signing',
        event_type_7: 'ARC Sign-up',
        'ticket sales': 'Ticket Sales'
      };
      e.data.event_type = event_name[e.data.event_type];
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  //===================== RENDER ==========================
  render() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    let followingData = this.state.data.filter(
      (thing, index, self) => index === self.findIndex(t => t.id === thing.id)
    );

    let newData = [];
    followingData.forEach(el => {
      let obj = { ...el };
      // console.log("el.event_type--> ", el.event_type);
      if (el.event_type === 'Giveaway') {
        obj.commanTitle = el.giveaway
          ? el.giveaway.length > 20
            ? el.giveaway.substr(0, 20) + '...'
            : el.giveaway
          : '';
      } else if (el.event_type === 'Release Party') {
        obj.commanTitle = el.releaseparty
          ? el.releaseparty.length > 20
            ? el.releaseparty.substr(0, 20) + '...'
            : el.releaseparty
          : '';
      } else {
        obj.commanTitle = el.title
          ? el.title.length > 20
            ? el.title.substr(0, 20) + '...'
            : el.title
          : '';
      }
      obj.commanTitle = obj.commanTitle.toLowerCase();
      newData.push(obj);
    });

    let MostSavedEvent = [];
    if (this.props.eventArray && this.props.eventArray.length > 0) {
      newData.forEach(el => {
        // console.log("elllllllllllllllllll ", el)
        let userId = localStorage.getItem('userId');
        let element = { ...el };
        let obj = this.props.eventArray.find(ne => el.id == ne.event_id);
        if (obj) {
          element.eventCount = obj.eventcount;
        } else if (userId == el.user_id) {
          element.eventCount = 0;
        } else {
          element.eventCount = -1;
        }

        MostSavedEvent.push(element);
      });
    }

    MostSavedEvent = MostSavedEvent.sort((a, b) => {
      if (a.eventCount > b.eventCount) return -1;
      if (a.eventCount === b.eventCount && a.created_date > b.eventCount)
        return -1;
    });
    followingData = [...MostSavedEvent];

    return (
      <>
        <div className="container event-filter-page">
          <div className="mb-4">
            <div className="color_guide mb-3">
              <div className="row">
                <div className="legend">
                  <span className="color_box">RELEASE PARTY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">ARC SIGNUP</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK PROMO</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">COVER REVEAL</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">GIVEAWAY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK RELEASE</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>

                <div className="legend">
                  <span className="color_box text-uppercase">
                    Boxes & Signings
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box text-uppercase">
                    Ticket/box sales
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
              </div>
            </div>

            <h2 className="text-center heading">Events Today</h2>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            {this.props.profileLoading ? (
              <>
                <Loader />
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={followingData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  // onRowClicked={this.addToFavourite.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                />
              </>
            ) : (
              <>
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={followingData}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  // onRowClicked={this.addToFavourite.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                  overlayNoRowsTemplate={overlayNoRowsTemplate}
                />
              </>
            )}

            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          {this.state.modal ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
            />
          ) : null}
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    let eventData = [];
    if (props.data && props.data.length) {
      props.data.map(data => {
        return eventData.push(data.event);
      });
    }

    this.setState({ data: eventData });
  }

  componentDidMount() {
    let eventData = [];
    if (this.props.data)
      if (this.props.data.length) {
        this.props.data.map(data => {
          return eventData.push(data.event);
        });
      }
    this.setState({ data: eventData });
  }
}

const mapStateToProps = state => {
  // console.log({ });
  return {
    // data: state.getNotification.currentUserData
    notificationList: state.getNotification,
    profileLoading: state.profileLoading
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificaitonList);
