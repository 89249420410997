import React, { Component } from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import SocialButton from './SocialButton';
import { BASEURL } from '../../constant';

export default class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      display_name: '',
      email: '',
      password: '',
      country_id: 0,
      showpwd: false,
      social: null
    };

    this.stepOneSubmit = this.stepOneSubmit.bind(this);
    this.handleCountries = this.handleCountries.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
  }

  handleSocialLogin = user => {
    console.log('userrsrsrrsrrsrsr====>>>', user);
    this.setState({ social: user }, () => {
      this.props.step_one_details(this.state);
    });
    console.log('jkjkjjkjkjkjkjkj', this.state);
    this.props.step_one_details(user);
  };

  handleSocialLoginFailure = err => {
    console.error('error in login', err);
  };

  stepOneSubmit(e) {
    e.preventDefault();

    const containsSpaces = this.state.display_name.includes(' ');
    const lowercaseDisplayName = this.state.display_name.toLowerCase();
    const isLowercase = this.state.display_name === lowercaseDisplayName;

    if (containsSpaces) {
      notify.show('Username dosen\'t contain spaces.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else if(!isLowercase) {
      notify.show('Username contain only lower case.', 'custom', 2000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    } else {
      this.props.step_one_details(this.state);
    }
  }

  handleCountries(e) {
    this.setState({ country_id: e.target.value });
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  handleChangeUsername(e) {
    this.setState({ display_name: e.target.value });
  }

  render() {
    return (
      <div>
        <div className="social-buttons-in-signup mt-0">
          <div class="col-md-6 pr-md-1 pr-0 pl-0 pr-sm-0 mt-2">
            <SocialButton
              provider="facebook"
              appId="3522912994486567"
              onLoginSuccess={this.handleSocialLogin}
              onLoginFailure={this.handleSocialLoginFailure}
              scope="user_birthday,user_hometown"
            >
              <a class="fb btn text-white" href="/#">
                <i class="fa fa-facebook fa-fw"></i> Sign up with Facebook
              </a>
            </SocialButton>
          </div>
          {/* appId :272295103198-tgmuidjg59979o9pn3pi1stcmuhkcnal.apps.googleusercontent.com */}
          {/* 272295103198-bjnk6jdilb37dfi4q49134vbfobqe573.apps.googleusercontent.com */}
          <div class="col-md-6 pl-md-1 pr-0 pl-0 pl-sm-0 mt-2">
            <SocialButton
              provider="google"
              appId="448689382304-i4f7nvtt2hrnbbbhv0oopj32vqjdvefo.apps.googleusercontent.com"
              //appId="582639887423-vdcefhukh72po5rk55ervm7oeglv6a99.apps.googleusercontent.com"
              onLoginSuccess={this.handleSocialLogin}
              onLoginFailure={this.handleSocialLoginFailure}
            >
              <a class="google btn text-white" href="/#">
                <i class="fa fa-google fa-fw"></i> Sign up with Google
              </a>
            </SocialButton>
          </div>
        </div>
        {/* <p class="seprator">
          <span> Or </span>
        </p> */}

        <div class="text-center mb-2">
          <div className="container">
            <span
              style={{
                padding: '8px',
                border: '1px solid #0f0f0f',
                borderRadius: '30px'
              }}
            >
              OR
            </span>
          </div>
        </div>

        <form className="" onSubmit={this.stepOneSubmit} autoComplete="off">
          <div class="row">
            <div className="form-group col-md-6">
              <div className="input-section">
                <input
                  type="text"
                  // className="form-control email_s"
                  className="form-control"
                  placeholder="Email address*"
                  onChange={this.handleChangeEmail}
                  autoFocus
                />
                <i className="fa fa-envelope pull-right show-pwd-dark" />
              </div>
            </div>

            <div className="form-group col-md-6">
              <div className="input-section">
                <input
                  type="text"
                  autoComplete="new-username"
                  // className="form-control user_s"
                  className="form-control"
                  placeholder="Select username*"
                  // autoFocus
                  onChange={this.handleChangeUsername}
                />
                <i className="fa fa-user pull-right show-pwd-dark" />
              </div>
              <p style={{ fontSize: '80%' }}>
                ( Letters in lower case only, no spaces. )*
              </p>
            </div>

            <div className="form-group col-md-6">
              <div className="input-section">
                <input
                  autocomplete="new-password"
                  type={this.state.showpwd ? 'text' : 'password'}
                  className="form-control password_s"
                  placeholder="Password*"
                  onChange={this.handleChangePassword}
                  pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$"
                />
                <i
                  className={
                    'fa fa-eye' +
                    (this.state.showpwd ? '-slash' : '') +
                    ' pull-right show-pwd-dark'
                  }
                  onClick={() => {
                    this.setState({ showpwd: !this.state.showpwd });
                  }}
                ></i>
              </div>
              <p style={{ fontSize: '80%' }}>
                ( Minimum 8-20 characters, 1 number, 1 upper case ) *
              </p>
            </div>

            <div className="form-group col-md-6">
              <select
                value={this.state.country_id}
                onChange={this.handleCountries}
                className="form-control"
              >
                <option value="0">Select country</option>
                {this.state.countries.length ? this.getCountries() : ''}
              </select>
            </div>
            <p className="text-bottom">
              You must be at least 18 years old to join this platform. OTR uses
              third-party sites for payment. OTR does not collect nor store
              member payment information.
              <br />A member’s username is permanent, but email and password can
              be changed at any time. Country is asked to help us offer better
              services based on various regions.
            </p>

            <div className="col-md-6 d-flex flex-end">
              <a
                target="_blank"
                href="https://www.braintreepayments.com/"
                className="payment-option"
              >
                <img
                  src={require('../../Utils/Assets/braintree-badge-light.png')}
                  alt="braintree"
                  width="164px"
                  height="44px"
                />
              </a>
            </div>
            <div className="col-md-6 d-flex flex-start">
              <a
                target="_blank"
                href="https://www.paypal.com/"
                className="payment-option-other"
              >
                <img
                  src={require('../../Utils/Assets/PaymentOptions.png')}
                  alt="Payment"
                  width="164px"
                  height="44px"
                />
              </a>
            </div>
          </div>

          <div className="button_btft" id="signup">
            <button
              className="btn button_jon_sign"
              style={{ float: 'left' }}
              onClick={() => {
                this.props.goBack(0);
              }}
              type="button"
            >
              <img
                src={require('../../Utils/Assets/aerrow_left.png')}
                alt="join"
              />{' '}
              Go Back
            </button>
            <button className="btn btn-filled" type="submit">
              SAVE
            </button>
          </div>
        </form>
      </div>
    );
  }

  getCountries() {
    return this.state.countries.map(country => {
      if (country.name !== 'For testing purpose')
        return (
          <option key={country.id} value={country.id}>
            {country.name}
          </option>
        );
    });
  }
  componentDidMount() {
    axios
      .get(BASEURL + '/api/v1/country')
      .then(response => {
        this.setState({ countries: response.data.result.countries });
      })
      .catch(error => {
        // console.log(error);
      });
  }
}
