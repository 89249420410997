import React from 'react';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import Cookies from 'universal-cookie';
import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';
import { BASEURL } from '../constant';

export default createHistory();
const globalHistory = new createHistory();
const cookies = new Cookies();

export function userlists(token) {
    return {
        type: 'USERS_LOADED',
        token
    };
}
export function userloading(bool) {
    return {
        type: 'USERS_LOADING',
        status: bool
    };
}

export function signuploading(bool) {
    return {
        type: 'SIGNUP_LOADING',
        signup_status: bool
    };
}

export function signuppage(number) {
    return {
        type: 'SIGNUP_PAGE',
        page: number
    };
}

export function usererror(bool) {
    return {
        type: 'USERS_ERROR',
        error: bool
    };
}

export function userListData(data) {
    return {
        type: 'USERLIST_DATA',
        data
    };
}

export function getMembershipDetails(data) {
    return {
        type: 'MEMBERSHIP_DETAILS',
        data
    };
}

export function success(data) {
    return {
        type: 'SHOW_THANKYOU',
        data
    };
}

export function getFollowPrCode(data) {
    return {
        type: 'GET_FOLLOW_CODE',
        data
    };
}

export function authenticate(url, login, password, social, rememberMe, router) {
    return dispatch => {
        dispatch(userloading(true));
        let data = JSON.stringify({
            email: login,
            password,
            isSocial: social
        });
        axios
            .post(BASEURL + url, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {

                if (response.data.expirepop == 1) {

                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('userType', response.data.usertype);
                    localStorage.setItem('membershipId', response.data.membership_id);
                    localStorage.setItem('userId', response.data.id);
                    localStorage.setItem('menuList', JSON.stringify(response.data.menuList));
                    localStorage.setItem('baseUrl', BASEURL);

                    localStorage.setItem('isFoundingMember', response.data.isFoundingMember);
                    // localStorage.setItem('canceldate', response.data.canceldate);

                    // localStorage.setItem(
                    //   'users_member_expire',
                    //   response.data.users_member_expire_date
                    // );
                    localStorage.setItem('expirepop', response.data.expirepop);

                    localStorage.setItem('memberId', response.data.memberId);
                    // globalHistory.push('/dashboard/my-events');
                    globalHistory.push('/dashboard/membership');
                }

                //
                else {
                    if (response.data.token) {
                        dispatch(usererror(false));

                        if (rememberMe && !social) {
                            cookies.set('email', login, { path: '/' });
                            cookies.set('password', password, { path: '/' });
                        }


                        dispatch(userlists(response.data.token));
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('userType', response.data.usertype);
                        localStorage.setItem('membershipId', response.data.membership_id);
                        localStorage.setItem('userId', response.data.id);
                        localStorage.setItem(
                            'menuList',
                            JSON.stringify(response.data.menuList)
                        );
                        localStorage.setItem('baseUrl', BASEURL);
                        localStorage.setItem(
                            'isFoundingMember',
                            response.data.isFoundingMember
                        );

                        localStorage.setItem('canceldate', response.data.canceldate);
                        localStorage.setItem('update_popup', response.data.update_popup);

                        // localStorage.setItem(
                        //   'users_member_expire',
                        //   response.data.users_member_expire_date
                        // );
                        // localStorage.setItem('expirepop', response.data.expirepop);

                        let location = window.location.href;
                        let redirectAdvertise = location.split('?');
                        let redirecttoAdvertise = redirectAdvertise[1];
                        if (!response.data.membership_id) {
                            globalHistory.push('/payment');
                        } else if (redirecttoAdvertise === 'advertise') {
                            localStorage.setItem('advertise', redirecttoAdvertise)
                            globalHistory.push('/advertise');
                        } else {
                            globalHistory.push('/user');
                        }

                        notify.show('Logged in successfully.', 'custom', 2000, {
                            background: '#d4edda',
                            text: '#155724',
                            borderColor: '#c3e6cb'
                        });
                    }
                }
                dispatch(userloading(false));
            })
            .catch(error => {
                dispatch(userloading(false));
                if (error.response && error.response.status === 403) {
                    notify.show(
                        error.response && error.response.data.message,
                        'custom',
                        5000,
                        {
                            background: '#f8d7da',
                            text: '#721c24'
                        }
                    );
                    globalHistory.push('/signup');
                    //dispatch(Unauthorized);
                } else if (error.response && error.response.status === 412) {
                    notify.show(
                        error.response && error.response.data.message,
                        'custom',
                        5000,
                        {
                            background: '#f8d7da',
                            text: '#721c24'
                        }
                    );
                    //globalHistory.push('/signup');
                    //dispatch(Unauthorized);
                } else {
                    notify.show(
                        error.response && error.response.data.message,
                        'custom',
                        5000,
                        {
                            background: '#f8d7da',
                            text: '#721c24'
                        }
                    );
                }
            });
    };
}

export function register(url, name, email, password, country_id, type_id) {
    return dispatch => {
        dispatch(userloading(true));
        let data = JSON.stringify({
            type_id,
            display_name: name,
            email,
            password,
            country_id
        });
        axios
            .post(BASEURL + url, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Origin': 'https://coredev.ontargetromance.com'
                }
            })
            .then(response => {
                localStorage.setItem('username', email);
                localStorage.setItem('id', response.data.id);
                dispatch(signuppage(2));
                dispatch(userloading(false));
            })
            .catch(error => {
                dispatch(userloading(false));
                if (error.response && error.response.status === 422) {
                    notify.show(error.response.data.message, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                }

                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                }
            });
    };
}

export function doSocialSignUp(url, data) {
    return dispatch => {
        dispatch(userloading(true));
        axios
            .post(BASEURL + url, data, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(async response => {
                switch (response.data.status) {
                    case 'SOCIAL_SIGNUP_SUCCESSFULL':

                        localStorage.setItem('i_user', response.data.data.id);
                        // dispatch(signuppage(3));

                        //globalHistory.push("/user");

                        dispatch(userloading(false));

                        break;

                    case 'ALREADY_SOCIAL_USER':
                        dispatch(userloading(false));
                        localStorage.setItem('token', response.data.data.token);
                        localStorage.setItem('userType', response.data.data.usertype);
                        localStorage.setItem(
                            'membershipId',
                            response.data.data.membership_id
                        );
                        localStorage.setItem('userId', response.data.data.id);
                        localStorage.setItem(
                            'menuList',
                            JSON.stringify(response.data.data.menuList)
                        );
                        localStorage.setItem('baseUrl', BASEURL);
                        globalHistory.push('/user');

                        break;
                    default:
                }
            })
            .catch(error => {
                dispatch(userloading(false));

                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                }

                switch (error.response.data.status) {
                    case 'SOCIAL_SIGNUP_SUCCESSFULL':

                        // console.log("user ALready have OTR Account");
                        break;
                    default:
                }
            });
    };
}

export function verifyOTP(url, email, otp) {
    return dispatch => {
        dispatch(userloading(true));
        let key = JSON.stringify({ email, otp });

        axios
            .put(BASEURL + url, key, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    localStorage.setItem('i_user', response.data.result.data.userId);
                    dispatch(signuppage(3));
                } else {
                    notify.show(
                        'Please enter a valid Verification code.',
                        'custom',
                        5000,
                        { background: '#f8d7d9', text: '#721c24' }
                    )

                }
                dispatch(userloading(false));
            })
            .catch(error => {
                dispatch(userloading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    if (error.response.data.message == 'Verification code has expired') {
                        notify.show(
                            error.response.data.message + '.',
                            'custom',
                            5000,
                            { background: '#f8d7da', text: '#721c24' }
                        );
                    } else {
                        notify.show(
                            'Please enter a valid Verification code.',
                            'custom',
                            5000,
                            { background: '#f8d7d9', text: '#721c24' }
                        );
                    }

                }
            });
    };
}

export function paymentConfirmation(url, body, status) {
    return dispatch => {
        dispatch(userloading(true));
        const userId = localStorage.getItem('i_user')
            ? localStorage.getItem('i_user')
            : localStorage.getItem('userId');

        axios
            .put(BASEURL + url + userId, body, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if (response) {
                    localStorage.removeItem('i_user');
                    if (status) {
                        dispatch(signuppage(5));
                    } else {
                        dispatch(success(true));
                    }
                    notify.show('Payment completed successfully.', 'custom', 2000, {
                        background: '#d4edda',
                        text: '#155724',
                        borderColor: '#c3e6cb'
                    });
                    dispatch(userloading(false));
                }
            })
            .catch(error => {
                dispatch(userloading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    if (error.response.data.message === 'Plan ID is invalid.') {
                        notify.show('Unable to process. Please verify payment data.', 'custom', 5000, {
                            background: '#f8d7da',
                            text: '#721c24'
                        });
                    } else {
                        notify.show(error.response.data.message, 'custom', 5000, {
                            background: '#f8d7da',
                            text: '#721c24'
                        });
                    }
                }
            });
    };
}

export function verifyPRCode(url, value, status) {
    return dispatch => {
        dispatch(userloading(true));
        axios
            .get(BASEURL + url, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    if (response.data.canFollow) {
                        localStorage.setItem('prCode', value);
                        localStorage.setItem(
                            'followingUser',
                            response.data.following_user_id
                        );
                        notify.show('Valid PR Code.', 'custom', 5000, {
                            background: '#d4edda',
                            text: '#155724'
                        });

                        if (status) {
                            dispatch(signuppage(4));
                        }
                        // let params = {
                        //     "following_user_id": response.data.following_user_id,
                        //     "user_id": localStorage.getItem("i_user")?localStorage.getItem("i_user"):localStorage.getItem("userId")
                        // }
                        // dispatch(addPRCode(params))
                    } else {
                        notify.show(response.data.message, 'custom', 5000, {
                            background: '#f8d7da',
                            text: '#721c24'
                        });
                    }
                    // dispatch(signuppage(5));
                } else {
                    notify.show(
                        'Failed to validate and attach PR Code. Please try again.',
                        'custom',
                        5000,
                        { background: '#f8d7da', text: '#721c24' }
                    );
                }
                dispatch(userloading(false));
            })
            .catch(error => {
                dispatch(userloading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show(error.response.data.message, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                }
            });
    };
}

export function addPRCode(params) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .post(BASEURL + '/api/v1/signup/follow', params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch(signuppage(4));
                    notify.show('PR Code added successfully.', 'custom', 2000, {
                        background: '#d4edda',
                        text: '#155724',
                        borderColor: '#c3e6cb'
                    });
                } else {
                    notify.show(
                        'Failed to attach PR Code. Please try again.',
                        'custom',
                        5000,
                        { background: '#f8d7da', text: '#721c24' }
                    );
                }
                dispatch(signuploading(false));
            })
            .catch(error => {
                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show(error.response.data.message, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                }
            });
    };
}

export function resendOTP(url, email) {
    return dispatch => {
        dispatch(signuploading(true));
        fetch(BASEURL + url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({ email })
        })
            .then(response => {
                dispatch(signuploading(false));
                notify.show('OTP resent successfully.', 'custom', 2000, {
                    background: '#d4edda',
                    text: '#155724',
                    borderColor: '#c3e6cb'
                });
            })
            .catch(error => {
                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show(
                        'something went wrong. please try again.',
                        'custom',
                        5000,
                        { background: '#f8d7da', text: '#721c24' }
                    );
                }
            });
    };
}

export function getstatic(url, headers) {
    return dispatch => {
        //   dispatch(profileLoading(true));
        dispatch(userListData([]));
        axios
            .get(BASEURL + url, headers)
            .then(res => {
                // console.log(res.data.result);
                if (res.data.result) {
                    dispatch(userListData(res.data.result));
                    //    dispatch(profileLoading(false));
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show('Failed to store user profile.', 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                    localStorage.removeItem('token');
                }
                //   dispatch(profileLoading(false));
            });
    };
}

export function getVerifyLink(url) {
    return dispatch => {
        axios
            .get(BASEURL + url)
            .then(res => {
                if (res.data.membership_id) localStorage.setItem('mID', true);
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show(`${error.response.data.message}`, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                    globalHistory.push('/log-in');
                }
                //   dispatch(profileLoading(false));
            });
    };
}

export function updatePassword(url, data) {
    return dispatch => {
        axios
            .post(BASEURL + url, data)
            .then(res => {
                if (res && res.data.message === 'Password Changed') {
                    notify.show('Password successfully re-set.', 'custom', 5000, {
                        background: '#d4edda',
                        text: '#721c24'
                    });
                    globalHistory.push('/log-in');
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show('Failed to store user profile.', 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                    localStorage.removeItem('token');
                }
            });
    };
}

export function getEmail(url, data) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .post(BASEURL + url, data)
            .then(res => {
                notify.show(
                    'A reset link has been sent to your register Email Id.',
                    'custom',
                    5000,
                    { background: '#f8d7da', text: '#721c24' }
                );
                globalHistory.push('/log-in');
                dispatch(signuploading(false));
            })
            .catch(error => {
                dispatch(signuploading(false));

                if (error.response && error.response.status === 412) {
                    notify.show(`${error.response.data.message}`, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                    //localStorage.removeItem('token');
                } else {
                    dispatch(Unauthorized);
                }
            });
    };
}

export function getUserMembershipDetails(url) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .get(BASEURL + url)
            .then(res => {
                dispatch(getMembershipDetails(res.data.result));
                dispatch(signuploading(false));
            })

            .catch(error => {

                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                }
                // dispatch(signuploading(false));
                // notify.show(`${error.response.data.message}`, "custom", 5000, { background: '#f8d7da', text: "#721c24" });
                // localStorage.removeItem("token");
            });
    };
}

export function getUpgradablePlans(url, headers) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .get(BASEURL + url, headers)
            .then(res => {
                dispatch(getMembershipDetails(res.data.result));
                dispatch(signuploading(false));
            })
            .catch(error => {
                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                }
                // dispatch(signuploading(false));
                // notify.show(`${error.response.data.message}`, "custom", 5000, { background: '#f8d7da', text: "#721c24" });
                // localStorage.removeItem("token");
            });
    };
}

export function upgragePlan(url, data, headers) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .post(BASEURL + url, data, headers)
            .then(res => {
                dispatch(signuploading(false));
                if (res.status === 200) {
                    notify.show(
                        'Author membership successfully upgraded to Author Premium membership. Thank you.',
                        'custom',
                        2000,
                        {
                            background: '#d4edda',
                            text: '#155724',
                            borderColor: '#c3e6cb'
                        }
                    );
                }
                //localStorage.removeItem("token");
                // notify.show("Please login again", "custom", 5000, {
                //   background: "#f8d7da",
                //   text: "#721c24",
                // });
                if (localStorage.getItem('token')) {
                    globalHistory.push('/dashboard');
                } else {
                    window.location.reload(true);
                }
            })
            .catch(error => {
                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                    // dispatch(signuploading(false));
                } else {
                    notify.show(`${error.response.data.message}`, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                }
                // localStorage.removeItem("token");
            });
    };
}

export function getFollowedUserPrCode(url) {
    return dispatch => {
        dispatch(signuploading(true));
        axios
            .get(BASEURL + url)
            .then(res => {
                dispatch(getFollowPrCode(res.data.data.result));
                dispatch(signuploading(false));
            })
            .catch(error => {
                dispatch(signuploading(false));
                if (error.response && error.response.status === 403) {
                    dispatch(Unauthorized);
                } else {
                    notify.show(`${error.response.data.message}`, 'custom', 5000, {
                        background: '#f8d7da',
                        text: '#721c24'
                    });
                }
                // localStorage.removeItem("token");
            });
    };
}

export function paymentSucess(status) {
    return dispatch => {
        dispatch(success(status));
    };
}

// GetAllDiscounts from braintree

export function GetAllDiscounts(url) {
    axios
        .post(BASEURL + url)
        .then(res => {
            if (res) {
            }
        })
        .catch(error => { });
}
