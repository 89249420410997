import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { addEventFilter, addSortFilter } from '../../Actions/filters';
import { getFilterEventDataList } from '../../Actions/event-setup';
import DatePicker from 'react-datepicker';

import './dashboard.css';
import moment from 'moment';

class FilterPopupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      eventType: null,
      days: null,
      series: null,
      category: null,
      genre: null,
      popularEvents: false,
      populerauthor: false,
      count: 0,
      links: false,
      favourite: false,
      exclusiveToOtr: false,
      isFollow: false,
      isEventFilter: '1',
      isfacebook: '',
      options: [
        {
          name: 'Action-Adventure',
          key: 'Action-Adventure',
          label: 'Action-Adventure',
          isChecked: false
        },
        {
          name: 'Chicklit',
          key: 'ChickLit',
          label: 'ChickLit',
          isChecked: false
        },
        {
          name: 'Dark',
          key: 'Dark',
          label: 'Dark',
          isChecked: false
        },
        {
          name: 'Erotica',
          key: 'Erotica',
          label: 'Erotica',
          isChecked: false
        },
        {
          name: 'Fairytale/Fanatsy',
          key: 'Fairytale/Fanatsy',
          label: 'Fairytale/Fanatsy',
          isChecked: false
        },
        {
          name: 'Inspirational',
          key: 'Inspirational',
          label: 'Inspirational',
          isChecked: false
        },
        {
          name: 'LGBTQIA+',
          key: 'LGBTQIA+',
          label: 'LGBTQIA+',
          isChecked: false
        },
        {
          name: 'Paranormal',
          key: 'Paranormal',
          label: 'Paranormal',
          isChecked: false
        },
        {
          name: 'SciFi/Dystopian',
          key: 'SciFi/Dystopian',
          label: 'SciFi/Dystopian',
          isChecked: false
        },
        {
          name: 'RomCom',
          key: 'RomCom',
          label: 'RomCom',
          isChecked: false
        },
        {
          name: 'Sport',
          key: 'Sport',
          label: 'Sport',
          isChecked: false
        },
        {
          name: 'Thriller/Mystery/Suspense',
          key: 'Thriller/Mystery/SuspenseRomCom',
          label: 'Thriller/Mystery/Suspense',
          isChecked: false
        },
        {
          name: 'Western',
          key: 'Western',
          label: 'Western',
          isChecked: false
        }
      ],
      keyword: [
        {
          name: 'Angsty',
          key: 'Angsty',
          label: 'Angsty',
          isChecked: false
        },
        {
          name: 'BDSM',
          key: 'BDSM',
          label: 'BDSM',
          isChecked: false
        },
        {
          name: 'Celebrity',
          key: 'Celebrity',
          label: 'Celebrity',
          isChecked: false
        },
        {
          name: 'College',
          key: 'College',
          label: 'College',
          isChecked: false
        },
        {
          name: 'Coming of Age',
          key: 'Coming of Age',
          label: 'Coming of Age',
          isChecked: false
        },
        {
          name: 'Criminal',
          key: 'Criminal',
          label: 'Criminal',
          isChecked: false
        },
        {
          name: 'Forbidden',
          key: 'Forbidden',
          label: 'Forbidden',
          isChecked: false
        },
        {
          name: 'Gritty/Edgy',
          key: 'Gritty/Edgy',
          label: 'Gritty/Edgy',
          isChecked: false
        },
        {
          name: ' Kinky',
          key: ' Kinky',
          label: ' Kinky',
          isChecked: false
        },
        {
          name: 'MC/Biker',
          key: ' MC/Biker',
          label: ' MC/Biker',
          isChecked: false
        },
        {
          name: ' Military',
          key: ' Military',
          label: 'Military',
          isChecked: false
        },
        {
          name: 'Light/Sweet',
          key: 'Light/Sweet',
          label: 'Light/Sweet',
          isChecked: false
        },
        {
          name: 'Real life issues',
          key: 'Real life issues',
          label: 'Real life issues',
          isChecked: false
        },
        {
          name: 'Royalty',
          key: 'Royalty',
          label: 'Royalty',
          isChecked: false
        },
        {
          name: 'Slow burn',
          key: 'Slow burn',
          label: 'Slow burn',
          isChecked: false
        },
        {
          name: 'Taboo',
          key: 'Taboo',
          label: 'Taboo',
          isChecked: false
        },
        {
          name: 'Tear-jerker',
          key: 'Tear-jerker',
          label: 'Tear-jerker',
          isChecked: false
        },
        {
          name: 'Triangle',
          key: 'Triangle',
          label: 'Triangle',
          isChecked: false
        }
      ],
      stand: [
        {
          name: 'Stand-alone',
          key: 'Stand-alone',
          label: 'Stand-alone',
          isChecked: false
        },
        {
          name: 'Stand-alone in a series',
          key: 'Stand-alone in a series',
          label: 'Stand-alone in a series',
          isChecked: false
        },
        {
          name: 'Book in a series',
          key: 'Book in a series',
          label: 'Book in a series',
          isChecked: false
        }
      ],
      genre1: [
        {
          name: 'Contemporary',
          key: 'Contemporary',
          label: 'Contemporary',
          isChecked: false
        },
        {
          name: 'Historical',
          key: 'Historical',
          label: 'Historical',
          isChecked: false
        }
      ],
      category1: [
        {
          name: 'Adult',
          key: 'Adult',
          label: 'Adult',
          isChecked: false
        },
        {
          name: 'New adult',
          key: 'New adult',
          label: 'New adult',
          isChecked: false
        },
        {
          name: 'Yound adult',
          key: 'Yound adult',
          label: 'Yound adult',
          isChecked: false
        }
      ]
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlesubgenreChange = this.handlesubgenreChange.bind(this);
    this.handlekeywordsChange = this.handlekeywordsChange.bind(this);
    this.handlestandChange = this.handlestandChange.bind(this);
    this.handlegenreChange = this.handlegenreChange.bind(this);
    this.handlecategory1Change = this.handlecategory1Change.bind(this);

    // this.htmlParser = new HTMLParser();
  }

  handlesubgenreChange(event) {
    let subGenre = this.state.options;
    subGenre.forEach(sub => {
      if (sub.name == event.target.value) sub.isChecked = event.target.checked;
    });
    this.setState({ options: subGenre });
  }

  handlekeywordsChange(event) {
    const keywords = this.state.keyword;
    keywords.forEach(key => {
      if (key.name == event.target.value) key.isChecked = event.target.checked;
    });
    this.setState({ keyword: keywords });
  }
  handlestandChange(event) {
    const stands = this.state.stand;

    stands.forEach(key => {
      if (key.name == event.target.value) key.isChecked = event.target.checked;
    });
    this.setState({ stand: stands });
  }
  handlegenreChange(event) {
    const genre1s = this.state.genre1;
    genre1s.forEach(key => {
      if (key.name == event.target.value) key.isChecked = event.target.checked;
    });
    this.setState({ genre1: genre1s });
  }
  handlecategory1Change(event) {
    const category1s = this.state.category1;

    category1s.forEach(key => {
      if (key.name == event.target.value) key.isChecked = event.target.checked;
    });
    this.setState({ category1: category1s });
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;

    let checked =
      name == 'popularEvents' ||
      name == 'populerauthor' ||
      name == 'favourite' ||
      name == 'exclusiveToOtr' ||
      name == 'links' ||
      name === 'isFollow'
        ? event.target.checked
        : target.value;
    // if (checked == "null" || checked == '') {
    //   checked = null;
    // }
    if (checked == '') {
      checked = null;
    }
    this.setState({
      [name]: checked
    });
  }
  handleCalendarDate(date) {
    this.setState({
      startDate: date
    });
  }
  handleCalendarEnd(date) {
    this.setState({
      endDate: date
    });
  }

  handleSubmit(event) {
    let filterDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let filterEndDate = moment(this.state.endDate).format('YYYY-MM-DD');

    if (this.props.sortFilter.length) {
      this.state.sort = this.props.sortFilter;
    }
    if (filterDate) {
      filterDate = moment.utc(filterDate).format('YYYY-MM-DD');
      // moment(this.state.startDate).toISOString();

      // moment.utc(this.state.startDate).format('YYYY-MM-DD')
    }

    if (filterEndDate) {
      filterEndDate = moment.utc(filterEndDate).format('YYYY-MM-DD');
      // moment(this.state.endDate).toISOString();
      // moment.utc(this.state.endDate).format('YYYY-MM-DD')
    }

    if (this.props.filterData.start_date) {
      this.state.startDate = moment
        .utc(this.props.filterData.start_date)
        .format('YYYY-MM-DD');
      // moment.utc(this.props.filterData.start_date).format('YYYY-MM-DD')
    }

    let filterData = { ...this.state };

    if (filterData.startDate) {
      filterData.startDate = moment(filterDate).toISOString();
    }

    if (filterData.endDate) {
      filterData.endDate = moment(filterEndDate).toISOString();
    }

    // if(filterData.startDate==null)
    // {
    //   let date= new Date(new Date().setDate(new Date().getday() -7 ))
    //   filterData.startDate= moment(date).toISOString();
    // }
    // if(filterData.endDate==null)
    // {
    //   let date = new Date(new Date().setMonth(new Date().getMonth() + 18))
    //   filterData.endDate= moment(date).toISOString();
    // }
    this.props.addFilterData(this.state);

    this.props.getFilterEvent(
      `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
      filterData,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
    this.setState({ count: 0 });
    event.preventDefault();
  }

  resetForm() {
    // e.preventDefault();
    // this.props.addFilterData({});
    this.setState({
      startDate: null,
      endDate: null,
      eventType: '',
      days: '',
      series: null,
      category: null,
      genre: null,
      popularEvents: false,
      populerauthor: false,
      links: false,
      count: 0,
      favourite: false,
      options: [
        {
          name: 'Action-Adventure',
          key: 'Action-Adventure',
          label: 'Action-Adventure',
          isChecked: false
        },
        {
          name: 'Chicklit',
          key: 'ChickLit',
          label: 'ChickLit',
          isChecked: false
        },
        {
          name: 'Dark',
          key: 'Dark',
          label: 'Dark',
          isChecked: false
        },
        {
          name: 'Erotica',
          key: 'Erotica',
          label: 'Erotica',
          isChecked: false
        },
        {
          name: 'Fairytale/Fanatsy',
          key: 'Fairytale/Fanatsy',
          label: 'Fairytale/Fanatsy',
          isChecked: false
        },
        {
          name: 'Inspirational',
          key: 'Inspirational',
          label: 'Inspirational',
          isChecked: false
        },
        {
          name: 'LGBT',
          key: 'LGBT',
          label: 'LGBT',
          isChecked: false
        },
        {
          name: 'Paranormal',
          key: 'Paranormal',
          label: 'Paranormal',
          isChecked: false
        },
        {
          name: 'SciFi/Dystopian',
          key: 'SciFi/Dystopian',
          label: 'SciFi/Dystopian',
          isChecked: false
        },
        {
          name: 'RomCom',
          key: 'RomCom',
          label: 'RomCom',
          isChecked: false
        },
        {
          name: 'Sport',
          key: 'Sport',
          label: 'Sport',
          isChecked: false
        },
        {
          name: 'Thriller/Mystery/Suspense',
          key: 'Thriller/Mystery/SuspenseRomCom',
          label: 'Thriller/Mystery/Suspense',
          isChecked: false
        },
        {
          name: 'Western',
          key: 'Western',
          label: 'Western',
          isChecked: false
        }
      ],
      keyword: [
        {
          name: 'Angsty',
          key: 'Angsty',
          label: 'Angsty',
          isChecked: false
        },
        {
          name: 'BDSM',
          key: 'BDSM',
          label: 'BDSM',
          isChecked: false
        },
        {
          name: 'Celebrity',
          key: 'Celebrity',
          label: 'Celebrity',
          isChecked: false
        },
        {
          name: 'College',
          key: 'College',
          label: 'College',
          isChecked: false
        },
        {
          name: 'Coming of Age',
          key: 'Coming of Age',
          label: 'Coming of Age',
          isChecked: false
        },
        {
          name: 'Criminal',
          key: 'Criminal',
          label: 'Criminal',
          isChecked: false
        },
        {
          name: 'Forbidden',
          key: 'Forbidden',
          label: 'Forbidden',
          isChecked: false
        },
        {
          name: 'Gritty/Edgy',
          key: 'Gritty/Edgy',
          label: 'Gritty/Edgy',
          isChecked: false
        },
        {
          name: ' Kinky',
          key: ' Kinky',
          label: ' Kinky',
          isChecked: false
        },
        {
          name: ' MC/Biker',
          key: ' MC/Biker',
          label: ' MC/Biker',
          isChecked: false
        },
        {
          name: ' Military',
          key: ' Military',
          label: 'Military',
          isChecked: false
        },
        {
          name: 'Light/Sweet',
          key: 'Light/Sweet',
          label: 'Light/Sweet',
          isChecked: false
        },
        {
          name: 'Real life issues',
          key: 'Real life issues',
          label: 'Real life issues',
          isChecked: false
        },
        {
          name: 'Royalty',
          key: 'Royalty',
          label: 'Royalty',
          isChecked: false
        },
        {
          name: 'Slow burn',
          key: 'Slow burn',
          label: 'Slow burn',
          isChecked: false
        },
        {
          name: 'Taboo',
          key: 'Taboo',
          label: 'Taboo',
          isChecked: false
        },
        {
          name: 'Tear-jerker',
          key: 'Tear-jerker',
          label: 'Tear-jerker',
          isChecked: false
        },
        {
          name: 'Triangle',
          key: 'Triangle',
          label: 'Triangle',
          isChecked: false
        }
      ],
      stand: [
        {
          name: 'Stand-alone',
          key: 'Stand-alone',
          label: 'Stand-alone',
          isChecked: false
        },
        {
          name: 'Stand-alone in a series',
          key: 'Stand-alone in a series',
          label: 'Stand-alone in a series',
          isChecked: false
        },
        {
          name: 'Book in a series',
          key: 'Book in a series',
          label: 'Book in a series',
          isChecked: false
        }
      ],
      genre1: [
        {
          name: 'Contemporary',
          key: 'Contemporary',
          label: 'Contemporary',
          isChecked: false
        },
        {
          name: 'Historical',
          key: 'Historical',
          label: 'Historical',
          isChecked: false
        }
      ],
      category1: [
        {
          name: 'Adult',
          key: 'Adult',
          label: 'Adult',
          isChecked: false
        },
        {
          name: 'New adult',
          key: 'New adult',
          label: 'New adult',
          isChecked: false
        },
        {
          name: 'Yound adult',
          key: 'Yound adult',
          label: 'Yound adult',
          isChecked: false
        }
      ],
      isCalendar: false
    });
  }

  clear() {
    this.setState({ count: 0 });
    this.props.addFilterData({});
    this.props.addSortFilter([]);
    this.resetForm();
    this.props.getFilterEvent(
      `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
      {},
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
  }
  //= ==================== RENDER ==========================

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          size="lg"
          className="event-filter-popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h5 className="text-center p-0">Filter Events</h5>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6 filter-date-section">
                  <label htmlFor="startDate">From Date</label>
                  {/* <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    onChange={this.handleChange}
                    value={this.state.startDate}
                  /> */}
                  <DatePicker
                    format="MM/DD/YYYY"
                    placeholderText="MM/DD/YYYY"
                    highlightDates={new Date()}
                    //locale="en-US"
                    selected={this.state.startDate}
                    id="startDate"
                    name="startDate"
                    locale="en"
                    onChange={date => this.handleCalendarDate(date)}
                    value={this.state.startDate}
                  />
                </div>
                <div className="form-group col-md-6 filter-date-section">
                  <label htmlFor="endDate">To Date</label>
                  {/* <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    onChange={this.handleChange}
                    // id="endDate"
                    value={this.state.endDate}
                  /> */}

                  <DatePicker
                    format="MM/DD/YYYY"
                    placeholderText="MM/DD/YYYY"
                    highlightDates={new Date()}
                    //locale="en-US"
                    selected={this.state.endDate}
                    id="endDate"
                    name="endDate"
                    locale="en"
                    onChange={date => this.handleCalendarEnd(date)}
                    value={this.state.endDate}
                  />
                </div>
                <div className="form-group col-md-6 mb-0">
                  <label htmlFor="eventType">Event Type</label>
                  <select
                    className="custom-select"
                    id="eventtype"
                    value={this.state.eventType}
                    name="eventType"
                    onChange={this.handleChange}
                  >
                    <option value="" selected>
                      Select One
                    </option>
                    <option value="-1">All</option>
                    <option
                      value="event_type_1" /* style={{"background":"linear-gradient(rgba(77, 191, 240,100),transparent)",
  "backgroundColor":"#4dbff0"}} */
                    >
                      Book releases
                    </option>
                    <option value="event_type_7">Book promo</option>
                    <option value="event_type_5"> Cover Reveal</option>
                    <option value="event_type_3"> ARC Sign-up</option>
                    <option value="event_type_2">Giveaway</option>
                    <option value="event_type_6">Book Signing</option>
                    <option value="event_type_4">Release Party</option>
                    <option value="ticket sales">Ticket Sales</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="eventtype">Added in last XX days</label>
                  <select
                    className="custom-select"
                    id="days"
                    value={this.state.days}
                    name="days"
                    onChange={this.handleChange}
                  >
                    <option value="" selected>
                      Select Days
                    </option>
                    <option value="7">7 days</option>
                    <option value="15">15 days</option>
                    <option value="30">30 days</option>
                  </select>
                </div>

                <div className="form-group col-md-6 ">
                  <fieldset className="border filters">
                    <legend className="w-auto">Category</legend>
                    {/* <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="cat1"
                        name="category"
                        value="Adult"
                        onChange={this.handleChange}
                        checked={this.state.category === 'Adult'}
                      />
                      <label className="custom-control-label" htmlFor="cat1">
                        Adult
                      </label>
                    </div>

                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="cat2"
                        name="category"
                        value="New adult"
                        onChange={this.handleChange}
                        checked={this.state.category === 'New adult'}
                      />
                      <label className="custom-control-label" htmlFor="cat2">
                        New adult
                      </label>
                    </div>

                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="cat3"
                        name="category"
                        value="Young adult"
                        onChange={this.handleChange}
                        checked={this.state.category === 'Young adult'}
                      />
                      <label className="custom-control-label" htmlFor="cat3">
                        Young adult
                      </label>
                    </div> */}

                    {this.state.category1.map((category, index) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={category.key}
                          name={category.name}
                          value={category.name}
                          onChange={this.handlecategory1Change}
                          checked={category.isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={category.key}
                        >
                          {' '}
                          {category.name}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                </div>

                {/* <div className="form-group col-md-6 ">
                  <fieldset className="border">
                    <legend className="w-auto">Stand-alone/series</legend>

                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="ser1"
                        name="series"
                        value="Stand-alone"
                        onChange={this.handleChange}
                        checked={this.state.series === 'Stand-alone'}
                      />
                      <label className="custom-control-label" htmlFor="ser1">
                        Stand-alone
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="ser2"
                        name="series"
                        value="Stand-alone in a series"
                        onChange={this.handleChange}
                        checked={
                          this.state.series === 'Stand-alone in a series'
                        }
                      />
                      <label className="custom-control-label" htmlFor="ser2">
                        Stand-alone in a series
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="ser3"
                        name="series"
                        value="Book in a series"
                        onChange={this.handleChange}
                        checked={this.state.series === 'Book in a series'}
                      />
                      <label className="custom-control-label" htmlFor="ser3">
                        Book in a series
                      </label>
                    </div>
                  </fieldset>
                </div> */}
                <div className="form-group col-md-6 ">
                  <fieldset className="border">
                    <legend className="w-auto">Stand-alone/series</legend>

                    {this.state.stand.map((stand, index) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={stand.key}
                          name={stand.name}
                          value={stand.name}
                          onChange={this.handlestandChange}
                          checked={stand.isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={stand.key}
                        >
                          {stand.name}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                </div>

                <div className="form-group col-md-6">
                  <fieldset className="border gener">
                    <legend className="w-auto">Genre</legend>

                    {/* <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="gen1"
                        name="genre"
                        value="Contemporary"
                        onChange={this.handleChange}
                        checked={this.state.genre === 'Contemporary'}
                      />
                      <label className="custom-control-label" htmlFor="gen1">
                        Contemporary
                      </label>
                    </div>

                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        className="custom-control-input"
                        id="gen2"
                        name="genre"
                        value="Historical"
                        onChange={this.handleChange}
                        checked={this.state.genre === 'Historical'}
                      />
                      <label className="custom-control-label" htmlFor="gen2">
                        Historical
                      </label>
                    </div> */}

                    {this.state.genre1.map((genre1, index) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={genre1.key}
                          name={genre1.name}
                          value={genre1.name}
                          onChange={this.handlegenreChange}
                          checked={genre1.isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={genre1.key}
                        >
                          {' '}
                          {genre1.name}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                  <fieldset className="border ">
                    <legend className="w-auto">Sub-genre(s):</legend>

                    {this.state.options.map((checkbox, index) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={checkbox.key}
                          name={checkbox.name}
                          value={checkbox.name}
                          onChange={this.handlesubgenreChange}
                          checked={checkbox.isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={checkbox.key}
                        >
                          {' '}
                          {checkbox.name}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                </div>

                <div className="form-group col-md-6 ">
                  <fieldset className="border keyword-box">
                    <legend>Keyword(s)/Trope(s):</legend>
                    {this.state.keyword.map((keyword, index) => (
                      <div
                        className="custom-control custom-checkbox"
                        key={index}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={keyword.key}
                          name={keyword.name}
                          value={keyword.name}
                          onChange={this.handlekeywordsChange}
                          checked={keyword.isChecked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={keyword.key}
                        >
                          {' '}
                          {keyword.name}
                        </label>
                      </div>
                    ))}
                  </fieldset>
                </div>

                <div className="form-group col-md-12">
                  <fieldset className="border">
                    <div className="row">
                      {/* <div className="col-sm-3">
                        <label for="">Most popular events</label>
                        <label class="switch">
                          <input type="checkbox"
                            name="popularEvents"
                            onChange={this.handleChange}
                            checked={this.state.popularEvents}
                          ></input>
                          <span class="slider round"></span>
                        </label>
                      </div> */}
                      {/* <div className="col-sm-3">
                        <label for="">  Most followed authors’ events  </label>
                        <label class="switch">
                          <input type="checkbox"
                            name="populerauthor"
                            onChange={this.handleChange}
                            checked={this.state.populerauthor}></input>
                          <span class="slider round"></span>
                        </label>
                      </div> */}
                      <div className="col-sm-4 event-toggle-col">
                        <label htmlFor=""> Preorder-links </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="links"
                            onChange={this.handleChange}
                            checked={this.state.links}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="col-sm-4 event-toggle-col">
                        <label htmlFor=""> Exclusive-to-OTR </label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="exclusiveToOtr"
                            onChange={this.handleChange}
                            checked={this.state.exclusiveToOtr}
                          />
                          <span className="slider round" />
                        </label>
                      </div>

                      <div className="col-sm-4 event-toggle-col">
                        <label htmlFor="">Events by members I follow</label>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="isFollow"
                            onChange={this.handleChange}
                            checked={this.state.isFollow}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-dark pull-right"
                onClick={this.props.toggle}
                value="Submit"
              >
                Submit
              </button>
            </form>

            <button
              type="submit"
              className="btn btn-light btn_reset"
              onClick={this.resetForm.bind(this)}
              value="Submit"
            >
              RESET
            </button>
            <button
              type="submit"
              className="btn btn-dark pull-right mr-2"
              onClick={e => {
                this.clear(e);
                this.props.toggle();
              }}
              value="Clear"
            >
              Close
            </button>
          </ModalBody>
        </Modal>
        {/* <EventFilter
         sort={ this.state.isEventFilter}
        /> */}
      </>
    );
  }

  componentDidMount() {
    if (this.props.filterData) {
      this.setState(this.props.filterData);
    }
  }

  componentWillReceiveProps() {
    if (
      Object.keys(this.props.filterData).length !== 0 &&
      this.props.filterData.count == 0
    ) {
      this.props.filterData.count = this.props.filterData.count + 1;
      if (this.props.filterData.start_date) {
        this.props.filterData.startDate = moment(
          this.props.filterData.start_date
        ).format('YYYY-MM-DD');
      }
      this.props.addFilterData(this.props.filterData);
      this.setState(this.props.filterData);
    }
  }
}

const mapStateToProps = state => ({
  filterData: state.eventFilter,
  sortFilter: state.sort,
  eventMode: state.getEventMode
});

const mapDispatchToProps = dispatch => ({
  addFilterData: data => dispatch(addEventFilter(data, true)),
  getFilterEvent: (url, data, header) =>
    dispatch(getFilterEventDataList(url, data, header)),
  addSortFilter: data => dispatch(addSortFilter(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPopupModal);
