import React from 'react';
// import { APIURL } from '../constant';
import { BASEURL } from '../constant';
const apiUrl = 'https://api.ontargetromance.com';

// const apiUrl = 'http://localhost:3001';
const FileBox = props => (
  // if(props.attributes.value!==""){
  //     let path = props.attributes.value;
  //     return(
  //       <img src= {path} height="60"  alt="profile"/>
  //     );
  //   }

  <>
    {props.attributes.value !== '' &&
    (props.type === 'book-cover' || props.type === 'alternative-book-cover') ? (
      <>
        {/* <img
          src={`${apiUrl}${props.attributes.value}`}
          height="60"
          alt="profile"
        /> */}
      </>
    ) : (
      ''
    )}
    <input
      className="fileInput1212"
      type={props.attributes.type}
      style={{ overflow: 'hidden' }}
      onChange={e => {
        return props.onFileHandler(
          e.target.files[0],
          props.attributes.size,
          props.type,
          props.index
        );
      }}
      data-max-size={props.attributes.size}
      required={
        localStorage.getItem('mode') && localStorage.getItem('mode') == 'edit'
          ? false
          : props.attributes.validation
      }
      accept={props.attributes.accept}
    />
    <div className="upload_text" style={{ margin: 10 }}>
      {/* <span style={{ color: "#1f1f1f", fontWeight: "600" }}>Upload Image </span> */}
      <p style={{ color: '#8a8a8a', fontWeight: '600' }}>(max file size 3MB)</p>
    </div>
  </>
);
export default FileBox;
