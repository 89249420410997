import React from 'react';
import './ToolbarButton.css';

export default function ToolbarButton(props) {
  const { icon } = props;
  return (
    <button
      className="btn"
      onClick={e => {
        props.onChangeClickHandler(e);
      }}
    >
      <i className={`toolbar-button ${icon}`} />
    </button>
  );
}
