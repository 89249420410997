import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { getstatic } from '../../Actions/index';
import { BASEURL } from '../../constant';
import { isAndroid, isIOS } from 'react-device-detect';
import PaymentModal from '../SignUp/paymentModal';
import {
  getBlasts,
  deleteBlast,
  buyUserBlast
} from '../../Actions/profile-setup';
import logo from '../../Utils/Assets/logo_2.png';
import add from '../../Utils/Assets/add.png';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import close from '../../Utils/Assets/close_p.png';

class BlastPurchaseModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      consent_type: 'Terms & Conditions',
      content: '',
      version: '',
      id: '',
      source: 'Website',
      ipAddress: '',
      reason: 'Blast',
      userId: localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : '',
      showPaymentModal: false,
      showPaymentButton: true,
      amount: 200,
      showAdditionalPaymentButton: true,
      ispcheck: false,
      pversion: '',
      pconsent_type: 'Privacy Policy',
      pid: '',
      modal: false,
      discountAmount: 0,
      allDiscountData: {}
    };
    this.getTermsConditions = this.getTermsConditions.bind(this);
    this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
    this.getIpAddress = this.getIpAddress.bind(this);
    this.getUserConsents = this.getUserConsents.bind(this);
    this.promoToggle = this.promoToggle.bind(this);
  }

  promoToggle = () => {
    if (!this.state.modal) {
      this.setState({ amount: 200, discountAmount: 0, promoCode: '' });
    }
    this.setState({ modal: !this.state.modal });
  };

  handlePromoCode = event => {
    this.setState({ promoCode: event.target.value });
  };

  handlePromoApply = async () => {
    const { promoCode } = this.state;
    const payload = {
      promoCode
    };
    try {
      const Promodata = await axios.post(
        BASEURL + '/api/v1/PromoCodeValidate',
        payload
      );
      // console.log('Promodata', Promodata);
      const { code, message, checkValidPromo } = Promodata.data;
      if (code === 200) {
        this.setState({ discountAmount: checkValidPromo[0].amount });
        this.setState({ allDiscountData: checkValidPromo[0] });
        this.promoToggle();
        notify.show(message, 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
      } else {
        this.setState({ discountAmount: '' });
        this.setState({ allDiscountData: '' });
        notify.show(message, 'custom', 2000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  onPrivacyCheck = a => {
    if (a) {
      this.setState({ ispcheck: true });
      this.setState({ showPaymentButton: false });
      this.setState({ showAdditionalPaymentButton: false });
    } else {
      this.setState({ ispcheck: false });
      this.setState({ showPaymentButton: true });
      this.setState({ showAdditionalPaymentButton: true });
    }
  };

  togglePaymentModal = () => {
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  };

  async getTermsConditions() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getTermsConditions'
      );
      if (response.data.length)
        this.setState({
          content: response.data[0].consent_body,
          version: response.data[0].version,
          id: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getPrivacyPolicy() {
    // console.log('hiiiiiiii', localStorage.getItem('userId'));
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getPrivacyPolicy'
      );
      if (response.data.length)
        this.setState({
          // content: response.data[0].consent_body,
          pversion: response.data[0].version,
          pid: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getUserConsents() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getUserConsents',
        {
          params: {
            userId: localStorage.getItem('userId'),
            version: this.state.version,
            pversion: this.state.pversion,
            reason: this.state.reason
          }
        }
      );
      // console.log('response', response);

      if (response.data.length > 0) {
        this.setState({
          ispcheck: true,
          showPaymentButton: false
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getIpAddress() {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json');
      this.setState({ ipAddress: response.data.ip });
    } catch (e) {
      console.log(e);
    }
  }

  addClientToken = async token => {
    //  console.log('Clinet token will be ', token);

    this.togglePaymentModal();
    this.props.toggle();

    this.props.buyBlast(
      '/api/v1/users/buyblast',
      {
        amount: this.state.amount - this.state.discountAmount,
        clientToken: token
      },
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );

    const response = await axios.get(
      BASEURL + '/api/v1/users/getUserConsents',
      {
        params: {
          userId: localStorage.getItem('userId'),
          version: this.state.version,
          pversion: this.state.pversion,
          reason: this.state.reason
        }
      }
    );
    // console.log('response', response);

    if (response.data.length > 0) {
      // this.setState({
      //   ispcheck: true,
      //   showPaymentButton: false,
      // });
    } else {
      await axios.post(BASEURL + '/api/v1/users/postUserConsent', this.state, {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      });
    }
  };

  //===================== RENDER ==========================

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.disabled ? false : this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          size="md"
          className="blast-purchase popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            {/* <h3 className="text-center p-0"> Terms And Conditions </h3> */}
          </ModalHeader>
          <ModalBody>
            {/* <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span> */}
            <div className="cross-icon">
              <img src={close} alt="close" onClick={this.props.toggle} />
            </div>
            <div className="logo-blast">
              <img src={logo} alt="logo" />
            </div>
            <div class="form-check mb-2 mr-sm-2">
              <div class="mb-2">
                <h6>
                  Purchase additional OTR Blast that will be sent to your OTR
                  followers.
                </h6>
              </div>
            </div>
            <div className="mt-0 input-text" style={{ fontSize: '0.7rem' }}>
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="terms"
                checked={this.state.ispcheck}
                onClick={e => {
                  this.onPrivacyCheck(e.target.checked);
                }}
                required
              />
              <h6>
                By purchasing additional OTR Blasts, you are agreeing and
                abiding to the OTR
                <span
                  onClick={this.openConditionsModal}
                  className="mousechange"
                  style={{ color: 'blue' }}
                >
                  {' '}
                  <Link to="/dashboard/condition" target="_blank">
                    {' '}
                    Terms & Conditions
                  </Link>
                </span>{' '}
                and{'  '}
                <span
                  onClick={this.openPrivacyModal}
                  className="mousechange"
                  style={{ color: 'blue' }}
                >
                  {/* {'Privacy Policy'} */}
                  <Link to="/dashboard/privacy" target="_blank">
                    {' '}
                    Privacy Policy
                  </Link>
                  {'  '}
                </span>
                and understand that OTR does not offer refunds on any purchases.
                {'  '}
              </h6>
            </div>
            <h6 onClick={this.promoToggle} className="promo-code-content">
              {/* <i
                class="fa fa-plus"
                aria-hidden="true"
                onClick={this.promoToggle}
              ></i>{' '} */}
              <img src={add} alt="" onClick={this.promoToggle} />
              <span>Add a Promo Code</span>
            </h6>
            <div className="blast-purchase-btn add-on-btn d-flex flex-column justify-content-center">
              <button
                className="btn btn-filled "
                id="Next"
                type="button"
                // onClick={(e) => {
                //   this.props.toggle();
                //   this.handleSubmit(e);
                // }}
                // disabled={this.state.showAdditionalPaymentButton}
              >
                Additional OTR Blast: $200 USD each
              </button>
              <button
                className="btn btn-filled "
                id="Next"
                type="button"
                // onClick={(e) => {
                // //   this.props.toggle();
                // //     this.handleSubmit(e);

                // }}
                onClick={this.togglePaymentModal}
                disabled={this.state.showPaymentButton}
              >
                PURCHASE ADDITIONAL BLAST
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal}
          toggle={this.promoToggle}
          id="myModal"
          size="md"
          className="blast-purchase popup "
        >
          <ModalHeader toggle={this.promoToggle} className="eventModal">
            {/* <h3 className="text-center p-0"> Terms And Conditions </h3> */}
          </ModalHeader>
          <ModalBody>
            {/* <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span> */}
            <div className="cross-icon">
              <img src={close} alt="close" onClick={this.promoToggle} />
            </div>
            <div className="logo-blast">
              <img src={logo} alt="logo" />
            </div>
            <div class="form-check mb-2 mr-sm-2">
              <div class="mb-2">
                <h6>PROMO CODE</h6>
              </div>
            </div>

            <div className="blast-purchase-btn upgrade-btn">
              <div class="form-check pl-0">
                <input
                  type="text"
                  value={this.state.promoCode}
                  name="promoCode"
                  onChange={this.handlePromoCode.bind(this)}
                />

                <button
                  className="btn btn-filled align-self-end"
                  id="Next"
                  type="button"
                  onClick={this.handlePromoApply.bind(this)}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="text-blast">
              Promo Codes are valid for one-time use only. Future renewal will
              be at the regular membership rate at time of renewal. Offer may
              not be combined with any other promo codes, coupons, discounts,
              offers, or promotions.
            </div>
          </ModalBody>
        </Modal>
        <PaymentModal
          isOpen={this.state.showPaymentModal}
          addClientToken={this.addClientToken}
          planCost={this.state.amount - this.state.discountAmount}
          toggle={this.togglePaymentModal.bind(this)}
          blast="blast"
          allDiscountData={this.state.allDiscountData}
        />
      </>
    );
  }
  componentDidMount() {
    // console.log('Hello boyyyy');
    this.getTermsConditions();
    this.getPrivacyPolicy();
    this.getIpAddress();
    this.getUserConsents();
    this.props.getBlasts(`/api/v1/users/blastDetails`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    if (isIOS) return this.setState({ source: 'IOS' });
    if (isAndroid) return this.setState({ source: 'Android' });
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData,
  blastDetails: state.getBlastDetails,
  displayMode: state.changeMode,
  buyStatus: state.buyBlastStatus
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url)),
  getBlasts: (url, headers) => dispatch(getBlasts(url, headers)),
  deleteBlast: (url, headers) => dispatch(deleteBlast(url, headers)),
  buyBlast: (url, data, header) => dispatch(buyUserBlast(url, data, header))
});

export default connect(mapStateToProps, mapDispatchToProps)(BlastPurchaseModel);
