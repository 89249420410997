import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import {
  getProfileDetails,
  isUpgradable,
  cancelMembership,
  disableAutoRenew
} from '../../Actions/profile-setup';
import { getClientToken } from '../../Actions/payment';
import { Modal, ModalBody } from 'reactstrap';

import moment from 'moment';
import Userprofile from './userProfile';
import axios from 'axios';
import { notify } from 'react-notify-toast';
import OtpModal from './otpImport';
import PaymentModal from '../SignUp/paymentModal';
import AddOnModel from '../Static/addOnModel';
import RenewMembershipModel from '../Static/renewMembershipModel';
import UpdatePaymentModel from '../Static/updatePaymentModel';
import { profileLoading } from '../../Actions/profile-setup';
import { BASEURL } from '../../constant';
import DropIn from 'braintree-web-drop-in-react';

class ManageAccount extends Component {
  instance;
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: [],
      importEmail: null,
      importPassword: null,
      showPaymentModal: false,
      showAddOnModal: false,
      showRenewMembershipModal: false,
      showUpdatePaymentModal: false,
      autoRenew: true,
      payAmount: 160,
      isOpenPrivacy: false,
      isOpenPrivacy1: false,
      profileAutoRenew: this.props.profileData.profile.auto_renew
    };
    this.cancelMembership = this.cancelMembership.bind(this);
  }

  deleteRequest = async () => {
    try {
      const response = await axios.post(
        BASEURL + '/api/v1/users/deleteRequest',
        {},
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );
      this.togglePrivacyModal();

      if (response.status === 200) {
        notify.show('Account Deleted successfully!', 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
        localStorage.removeItem('token');
      }
    } catch (e) {
      console.log(e);
    }
  };

  cancelMembership() {
    // this.deleteRequest();
    this.props.cancelMembership();
    this.togglePrivacyModal1();
  }
  togglePrivacyModal() {
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  }

  togglePrivacyModal1() {
    this.setState({ isOpenPrivacy1: !this.state.isOpenPrivacy1 });
  }

  importSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.importEmail,
      password: this.state.importPassword
    };
    this.submitImportData(data);
  };

  toggleOtpModal = () => {
    this.setState({ showOtpModal: !this.state.showOtpModal });
  };

  addClientToken = async token => {
    try {
      this.togglePaymentModal();
      this.toggleAddOnModal();
      this.toggleRenewMembershipModal();
      this.toggleUpdatePaymentModal();
      this.props.showLoader(true);

      const headers = {
        'x-authorization': localStorage.getItem('token')
      };

      const buyBookReleaseData = {
        clientToken: token
      };

      const buyBookRelease = await axios.post(
        BASEURL + '/api/v1/users/buyBookRelease',
        buyBookReleaseData,
        {
          headers: headers
        }
      );

      if (buyBookRelease.status === 200) {
        this.props.showLoader(false);
        notify.show('Book Release purchased sucessfully. ', 'custom', 5000, {
          background: '#c3e6cb',
          text: '#721c24'
        });
      }
    } catch (error) {
      this.props.showLoader(false);
      if (error.response && error.response.status === 400) {
        notify.show(`${error.response.data.message}`, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      } else {
        notify.show(
          'Something went wrong Please try again after some time.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
      }
    }
  };

  togglePaymentModal = () => {
    this.setState({ showPaymentModal: !this.state.showPaymentModal });
  };
  toggleAddOnModal = () => {
    this.setState({ showAddOnModal: !this.state.showAddOnModal });
  };
  toggleRenewMembershipModal = () => {
    this.setState({
      showRenewMembershipModal: !this.state.showRenewMembershipModal
    });
  };
  toggleUpdatePaymentModal = () => {
    this.setState({
      showUpdatePaymentModal: !this.state.showUpdatePaymentModal
    });
  };

  // disableCheck = async event => {
  //   if (!event.target.checked) {
  //     console.log('callingfff', event.target.checked);
  //     this.setState({ autoRenew: false });
  //     this.props.disableMembership();
  //   } else {
  //     // const { nonce } = await this.instance.requestPaymentMethod();
  //     // console.log('nonce', nonce);
  //     //this.setState({ showPaymentModal: !this.state.showPaymentModal });
  //     let id = localStorage.getItem('membershipId');
  //     console.log('id', id);
  //     this.props.disableMembership();
  //     this.setState({ autoRenew: true });
  //   }

  // const { nonce } = await this.instance.requestPaymentMethod();
  // console.log('nonce', nonce);
  // const newD = {
  //   nonce: nonce
  // };
  //   this.props.disableMembership(newD.nonce);
  //   //this.props.addClientToken('');
  // };

  disableCheck = async event => {
    if (!event.target.checked) {
      this.props.disableMembership();
      this.props.getUserProfile();
      this.setState({autoRenew: false })
      setTimeout(()=>
      {
        console.log("this is the message -- > ", this.props.autoRenewMsg)
        this.setState({ profileAutoRenew: false});
      }, 1000)
    } else {
      const { nonce } = await this.instance.requestPaymentMethod();

      console.log(nonce, 'nonce')

      const newD = {
        nonce: nonce
      };
      console.log(newD.nonce, 'newD.nonce')
      /* this.props.disableMembership(newD.nonce);
      this.props.getUserProfile();
      this.setState({ autoRenew: true})
      setTimeout(()=>
      {
        console.log("this is the message -- > ", this.props.autoRenewMsg)
        this.setState({ profileAutoRenew: true});
      }, 1000) */
    }
  };
  submitImportData = async data => {
    try {
      const url = '/api/v1/users/importAccount';
      const headers = {
        headers: { 'x-authorization': localStorage.getItem('token') }
      };

      const getData = await axios.post(BASEURL + url, data, headers);

      if (getData.status === 200) {
        this.setState({ showOtpModal: true });
        // notify.show(`${getData.data.message}`, 'custom', 5000, {
        //   background: '#d4edda',
        //   text: '#155724'
        // });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        notify.show(`${error.response.data.message}`, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      } else {
        notify.show(
          'Something went wrong Please try again after some time.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
      }
    }
  };

  //===================== RENDER ==========================
  render() {
    // console.log("---------------------------------1",this.props.autoRenewMsg);
    // console.log("---------------------------------2",this.props.profileData.profile.auto_renew);
    const isPRFirm = +localStorage.getItem('membershipId') == 5 ? true : false;
    // console.log("here is the profile data--- , ", this.props.profileData.profile.auto_renew);
    if (this.props.profileData) {
      const membershipInfo = JSON.parse(
        this.props.profileData.profile.description
      );
      // this.setState({autoRenew:this.props.profileData.profile.auto_renew})
      const profileData = this.props.profileData.profile;
      const member_id = profileData.membership_id;
      let cost;
      let type;

      if (member_id === '1') {
        cost = 540;
        type = 'Author Premium';
      }
      if (member_id === '2') {
        cost = 29;
        type = 'Reader';
      }
      if (member_id === '3') {
        cost = 39;
        type = 'Blogger';
      }
      if (member_id === '4') {
        cost = 120;
        type = 'Vendor';
      }
      if (member_id === '5') {
        cost = 720;
        type = 'PR Firm';
      }
      if (member_id === '6') {
        cost = 59;
        type = 'Blogger Premium';
      }
      if (member_id === '8') {
        cost = 360;
        type = 'Author';
      }

      const { payAmount } = this.state;

      // console.log("---=================------>",type);

      return (
        <>
          <Modal
            isOpen={this.state.isOpenPrivacy}
            toggle={this.togglePrivacyModal.bind(this)}
            id="myModal"
            className="advertise-privacy-popup delete-confirmation-popup del-popup-new"
          >
            {/* <ModalHeader toggle={this.state.toggle} className="eventModal">
          <h3 className="text-center p-0">Are you sure to  delete your from OTR membership ? </h3>
        </ModalHeader> */}
            <ModalBody>
              <p className="text-center p-0">
                {' '}
                This will permanently delete your OTR membership in accordance
                with the OTR Privacy Policy. Once deleted it's irreversible.
                Email and username will be permanently barred from site.
              </p>
              <p className="text-center p-0">
                To confirm delete request, click Delete. To cancel request,
                click Cancel.{' '}
              </p>
              <div className="delete-btn">
                <button
                  className="btn btn-filled "
                  id="Next"
                  type="button"
                  disabled={this.state.disabled}
                  onClick={() => this.togglePrivacyModal()}
                >
                  CANCEL
                </button>
                <button
                  className="btn btn-filled"
                  id="Next"
                  type="button"
                  onClick={() => {
                    this.deleteRequest();
                  }}
                  disabled={this.state.disabled}
                >
                  DELETE{' '}
                </button>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            isOpen={this.state.isOpenPrivacy1}
            toggle={this.togglePrivacyModal1.bind(this)}
            id="myModal"
            className="advertise-privacy-popup delete-confirmation-popup delete-popup-section"
          >
            {/* <ModalHeader toggle={this.state.toggle} className="eventModal">
          
        </ModalHeader> */}
            <ModalBody>
              <h6>
                Would you like to cancel your OTR membership, effective day of
                membership expiration?{' '}
              </h6>
              <h6>Click Yes to confirm or click No to cancel.</h6>
              {/* <div className="delete-btn">       */}

              <div className="del-popup-btns">
                <button
                  className="btn btn-filled"
                  id="Next"
                  type="button"
                  onClick={() => {
                    this.cancelMembership();
                  }}
                  disabled={this.state.disabled}
                >
                  Yes{' '}
                </button>
                <button
                  className="btn btn-filled btn-no"
                  id="Next"
                  type="button"
                  disabled={this.state.disabled}
                  onClick={() => this.togglePrivacyModal1()}
                >
                  No
                </button>
              </div>
            </ModalBody>
          </Modal>
          {profileData.login_type && (
            <Userprofile logintype={profileData.login_type}></Userprofile>
          )}

          {/* {profileData.login_type === 'social' && (
            <h4>
              If You joined via Google or Facebook,please manage login settings
              using that platform.
            </h4>
          )} */}

          <div className=" mt-2">
            <fieldset
              class={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend class="scheduler-border">
                <b>AUTO RENEWAL</b>
              </legend>
              <div className="auto-renew">
                <span>
                  Auto Renewal:
                  <div className="toggle_switch">
                    {this.props.token && (
                      <div style={{ display: 'none' }}>
                        <DropIn
                          options={{
                            authorization: this.props.token,
                            paypal: {
                              flow: 'vault',
                              amount: 89,
                              currency: 'USD'
                            },
                            paypalCredit: {
                              flow: 'vault',
                              amount: 89,
                              currency: 'USD'
                            },
                            venmo: {
                              allowNewBrowserTab: false
                            },
                            vaultManager: true
                          }}
                          onInstance={instance => (
                            (this.instance = instance),
                            instance.isPaymentMethodRequestable()
                          )}
                        />
                      </div>
                    )}
                    <span className="check-state" style={{ marginRight: 5 }}>
                      OFF
                    </span>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="myevents"
                        onChange={this.disableCheck.bind(this)}
                        checked={
                          !this.state.profileAutoRenew
                            ? this.state.profileAutoRenew
                            : this.state.autoRenew
                        }
                      />
                      <span className="slider round"></span>
                    </label>
                    {this.state.profileAutoRenew ? (
                      <span className="check-state">ON</span>
                    ) : (
                      ''
                      // <span className="check-state">OFF</span>
                    )}

                    <span
                      style={{
                        color: this.props.displayMode ? '#000' : '#fff'
                      }}
                    >
                      {' '}
                      <i>
                        Your membership will auto-renew at the regular
                        membership rate at time of renewal.
                      </i>
                    </span>
                  </div>
                </span>
              </div>

              <span>
                Member ID: <b>OTR{profileData.id}</b>{' '}
              </span>
              <br />
              <span>
                Renewal Date:{' '}
                <b>
                  {moment(profileData.member_expiry_date).format('MMM-DD-YYYY')}
                </b>{' '}
              </span>
              <br />

              <button
                className={
                  this.props.displayMode
                    ? 'btn new_btn_save_bt float-md-right update-payment-btn'
                    : 'btn new_btn_save_bt float-md-right white-button update-payment-btn'
                }
                onClick={() => {
                  this.toggleUpdatePaymentModal();
                }}
              >
                UPDATE PAYMENT
              </button>

              {/* <span>
                Membership Type: <b>{profileData.type_name}</b>
              </span> */}
              <span>
                Membership Type: <b>{type}</b>
              </span>
              <br />
              <b> To update or change your payment method</b>
            </fieldset>
          </div>
          <div className=" mt-2">
            <fieldset
              class={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend class="scheduler-border">
                <b>MANUAL RENEWAL</b>
              </legend>
              <div className="row" style={{ alignItems: 'flex-end' }}>
                <div className="col-md-10">
                  <span>
                    Renew your membership for one year, effective on day of
                    expiration of current membership or immediately for an
                    expired membership.
                  </span>
                  <br />
                  <i>*Only possible when Auto-renewal is turned off.</i>
                </div>
                <div className="col-md-2">
                  {!this.state.profileAutoRenew ? (
                    <button
                      className={
                        this.props.displayMode
                          ? 'btn new_btn_save_bt float-md-right renew-btn'
                          : 'btn new_btn_save_bt float-md-right white-button renew-btn'
                      }
                      onClick={() => {
                        this.toggleRenewMembershipModal();
                      }}
                    >
                      RENEW
                    </button>
                  ) : (
                    <button
                      className={`disable-renew-btn ${
                        this.props.displayMode
                          ? 'btn new_btn_save_bt float-md-right'
                          : 'btn new_btn_save_bt float-md-right white-button'
                      }`}
                    >
                      RENEW
                    </button>
                  )}
                </div>
              </div>
            </fieldset>
          </div>

          {profileData.membership_id === '8' ? (
            <div className=" mt-2">
              <fieldset
                class={
                  this.props.displayMode
                    ? 'scheduler-border border-light'
                    : 'scheduler-border border-dark'
                }
              >
                <legend class="scheduler-border">
                  <b>UPGRADE</b>
                </legend>
                {profileData.membership_id === '8' ? (
                  <Link
                    to={{
                      pathname: `/dashboard/upgradePlan`,
                      state: { user_type: profileData.type_id }
                    }}
                    className={
                      this.props.displayMode
                        ? 'btn new_btn_save_bt float-md-right upgrade-btn'
                        : 'btn new_btn_save_bt float-md-right white-button upgrade-btn'
                    }
                  >
                    UPGRADE
                  </Link>
                ) : (
                  <Link
                    to={{
                      //pathname: `/dashboard/upgradePlan`,
                      state: { user_type: profileData.type_id }
                    }}
                    className={`disable-renew-btn ${
                      this.props.displayMode
                        ? 'btn new_btn_save_bt float-md-right'
                        : 'btn new_btn_save_bt float-md-right white-button'
                    }`}
                  >
                    UPGRADE
                  </Link>
                )}

                <b>Upgrade to a Premium membership.</b>
                <br />
                <i style={{ display: 'block', maxWidth: 1000 }}>
                  *Only available for Author to Author Premium. Pay the current
                  Author Premium membership fee & receive prorated credit for
                  the unused portion of what you paid for the Author membership.
                </i>
              </fieldset>
            </div>
          ) : (
            ''
          )}
          {profileData.membership_id === '8' && (
            <>
              <div className="mt-2">
                <fieldset
                  class={
                    this.props.displayMode
                      ? 'scheduler-border border-light'
                      : 'scheduler-border border-dark'
                  }
                >
                  <legend class="scheduler-border">
                    <b>BOOK RELEASE ADD-ON</b>
                  </legend>
                  <div className="row">
                    <div className="col-10 ">
                      <p className="mr-2 mb-0 text-justify">
                        An Author membership includes up to 3 book releases per
                        year. For additional book releases, select the purchase
                        option.
                      </p>

                      <p className="mb-0">
                        Cost:<b> ${payAmount} per OTR Book Release</b>
                      </p>
                    </div>

                    <div className="col-2">
                      <button
                        className={
                          this.props.displayMode
                            ? 'btn new_btn_save_bt float-md-right purchase-btn'
                            : 'btn new_btn_save_bt float-md-right white-button purchase-btn'
                        }
                        onClick={() => {
                          this.toggleAddOnModal();
                        }}
                      >
                        PURCHASE
                      </button>
                    </div>
                  </div>
                </fieldset>
              </div>
            </>
          )}

          <div className="mt-2">
            <fieldset
              class={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend class="scheduler-border">
                <b>IMPORT PRIVATE CALENDAR</b>
              </legend>
              <div className="row">
                <div className="col-12 mb-2">
                  <p className="mr-2 text-justify" style={{ margin: 0 }}>
                    To import a private calendar from another OTR membership,{' '}
                    enter the requested details for the exporting membership. A
                    verification email will be sent to complete the process.
                  </p>

                  <b>
                    <i>
                      *Do not cancel the exporting membership before the
                      transfer is complete.
                    </i>
                  </b>
                </div>

                <div className="col-12">
                  <form onSubmit={this.importSubmit} autoComplete="off">
                    <div className="row">
                      <div className="form-group col-6 mb-0 desktop-view">
                        <div class="form-border mb-0">
                          <div class="row mb-3">
                            <label for="inputEmail" class="col-md-5">
                              Exporting email:
                            </label>
                            <div class="col-md-7">
                              <input
                                type="email"
                                class="form-control"
                                // id="inputEmail"
                                placeholder="Email"
                                autocomplete="off"
                                onChange={e => {
                                  this.setState({
                                    importEmail: e.target.value
                                  });
                                }}
                                value={this.state.importEmail}
                                required
                              />
                            </div>
                          </div>
                          <div class="row">
                            <label for="inputPassword" class="col-md-5">
                              Exporting password:
                            </label>
                            <div class="col-md-7">
                              <input
                                type="password"
                                autocomplete="off"
                                class="form-control"
                                onChange={e => {
                                  this.setState({
                                    importPassword: e.target.value
                                  });
                                }}
                                value={this.state.importPassword}
                                id="inputPassword"
                                placeholder="Password"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mb-0 col-6 d-flex align-items-end justify-content-end">
                        <button
                          className={
                            this.props.displayMode
                              ? 'btn new_btn_save_bt import-btn'
                              : 'btn new_btn_save_bt white-button import-btn'
                          }
                          type="submit"
                        >
                          IMPORT
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </fieldset>
          </div>

          <div className="mt-2">
            <fieldset
              class={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend class="scheduler-border">
                <b>CANCEL MEMBERSHIP</b>
              </legend>
              <div className="row">
                <div className="col-10 ">
                  <p className="mr-2 mb-0">
                    A membership must be cancelled before renewal to avoid
                    auto-renewal payment of the membership fee for the next
                    billing period. Upon cancellation, members will continue to
                    have access to services through the end of the member's
                    billing period. Refunds will not be issued. If auto-renewal
                    is turned back on, cancel request will automatically be
                    voided.
                  </p>
                  <p className="mb-0">
                    <b>
                      <i>
                        *If you are cancelling due to new membership type and
                        would like to export your private reading calendar, you
                        must import before cancelling.
                      </i>
                    </b>
                  </p>
                  <p className="mb-0">
                    <a
                      style={{ color: '#007bff' }}
                      onClick={() => this.togglePrivacyModal()}
                    >
                      {' '}
                      <u> PERMANENTLY DELETE YOUR OTR MEMBERSHIP</u>
                    </a>
                  </p>
                </div>

                <div className="form-group col-2 d-flex align-items-end justify-content-end mb-1">
                  <button
                    className={
                      this.props.displayMode
                        ? 'btn new_btn_save_bt float-md-right'
                        : 'btn new_btn_save_bt float-md-right white-button'
                    }
                    onClick={() => {
                      this.togglePrivacyModal1();
                    }}
                  >
                    Cancel
                  </button>
                </div>
                {/* <div className="form-group col-2 d-flex align-items-end justify-content-end mb-1">
                  <button
                    className={
                      this.props.displayMode
                        ? 'btn new_btn_save_bt float-md-right'
                        : 'btn new_btn_save_bt float-md-right white-button'
                    }
                    // onClick={() => {
                    //   if (
                    //     window.confirm(
                    //       'Are you sure you want to cancel your membership ?'
                    //     )
                    //   ) {
                    //     this.cancelMembership();
                    //   }
                    // }}
                    onClick={() =>
                      this.togglePrivacyModal(
                      
                      )
                    }
                  >
                   DELETE
                  </button>
                </div> */}
                {/* <ul className="remove-icon">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="/dashboard/membership-types"
                  >
                    {' '}
                    <u> VIEW ALL PLANS & PRICING</u>
                  </a>
                </li>
              </ul> */}
                {/* <div className="col-10 ">
                </div> */}
              </div>
            </fieldset>
            <dic className="form-group col-2 d-flex align-items-end justify-content-end">
              {/* <button
                className={
                  this.props.displayMode
                    ? "btn new_btn_save_bt float-md-right"
                    : "btn new_btn_save_bt float-md-right white-button"
                }
                onClick={() => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete your Account ?"
                    )
                  ) {
                    this.deleteRequest();
                  }
                }}
              >
                Delete My Account
              </button> */}
            </dic>
          </div>

          <OtpModal
            isOpen={this.state.showOtpModal}
            toggle={this.toggleOtpModal}
          />

          {/* // payment modal  */}

          <PaymentModal
            isOpen={this.state.showPaymentModal}
            addClientToken={this.addClientToken}
            planCost={160}
            toggle={this.togglePaymentModal.bind(this)}
          />
          <AddOnModel
            isOpen={this.state.showAddOnModal}
            addClientToken={this.addClientToken}
            planCost={payAmount}
            toggle={this.toggleAddOnModal.bind(this)}
            blast="blast"
          />

          {}
          <RenewMembershipModel
            isOpen={this.state.showRenewMembershipModal}
            addClientToken={this.addClientToken}
            planCost={cost}
            membershipType={type}
            membershipId={profileData.membership_id}
            toggle={this.toggleRenewMembershipModal.bind(this)}
            blast="blast"
          />
          <UpdatePaymentModel
            isOpen={this.state.showUpdatePaymentModal}
            addClientToken={this.addClientToken}
            planCost={cost}
            membershipType={type}
            membershipId={profileData.membership_id}
            toggle={this.toggleUpdatePaymentModal.bind(this)}
            blast="blast"
          />
        </>
      );
    } else {
      return <h1>No Membership found</h1>;
    }
  }
  componentDidUpdate(prevProps) {
    // if (this.props.profileData !== prevProps.profileData) {
    //   //this.props.getUserProfile();
    // }

    if (prevProps.autoRenewMsg !== this.props.autoRenewMsg) {
      if (
        this.props.autoRenewMsg ==
        'Your membership will auto-renew at the regular membership rate at time of renewal.'
      ) {
        this.setState({ profileAutoRenew: true });
        this.setState({ autoRenew: true });
      } else if (
        this.props.autoRenewMsg == 'Auto-renewal disabled sucessfully.'
      ) {
        this.setState({ autoRenew: false });
        this.setState({ profileAutoRenew: false });
      }
    }
  }

  async componentDidMount() {
    // this.props.checkIsUpgrable();
    this.props.getUserProfile();

    const Plandata = await axios.get(BASEURL + '/api/v1/getAllPlans');

    const { plans } = Plandata.data;
    const findAdvertiseData = plans.find(
      el => el.name === 'OTR Book Release Add-on Purchase (Author)'
    );
    if (findAdvertiseData) {
      this.setState({ payAmount: findAdvertiseData.price });
    }
    if (this.props.token === '') {
      let userId = localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : localStorage.getItem('i_user');
      this.props.getClientToken(`/api/v1/payment/generateToken/${userId}`);
    }
  }
}

const mapStateToProps = state => {
  return {
    isUpgradable: state.isMemberhsipUpgradable,
    loading: state.profileLoading,
    profileData: state.myProfileData,
    displayMode: state.changeMode,
    token: state.genrateClientToken,
    autoRenewMsg: state.autoRenewal
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getClientToken: url => dispatch(getClientToken(url)),
    getUserProfile: () =>
      dispatch(
        getProfileDetails('/api/v1/user/profile', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    checkIsUpgrable: () =>
      dispatch(
        isUpgradable('/api/v1/users/checkUpgradable', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    cancelMembership: () =>
      dispatch(
        cancelMembership('/api/v1/users/cancelSubscription', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),

    disableMembership: newD =>
      dispatch(
        disableAutoRenew('/api/v1/users/disableAutoRenew', {
          params: { newD },
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),

    showLoader: status => dispatch(profileLoading(status))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAccount);
