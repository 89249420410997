import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notify } from 'react-notify-toast';

class AddToBetaReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      publicName: null,
      email: null,
      goodreads: null,
      amazon: null,
      blogLink: null,
      title: []
      // this.htmlParser = new HTMLParser();
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //   this.handleDynamicInput=this.handleDynamicInput.bind(this);
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    this.setState({
      [name]: target.value
    });
  }

  handleDynamicInput(i, event) {
    // console.log("event is", event);
    this.setState({
      title: { ...this.state.title, [i]: event.target.value }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.betaData(this.state);
    this.props.toggle();
    if (Object.keys(this.props.betaUserData).length > 0) {
      notify.show('Successfully updated.', 'custom', 2000, {
        background: '#d4edda',
        text: '#155724',
        borderColor: '#c3e6cb'
    })
  }
    else {
      notify.show('Successfully added to Beta reader list.', 'custom', 2000, {
        background: '#d4edda',
        text: '#155724',
        borderColor: '#c3e6cb'
    }
      )
  }
    // console.log("hey hi      ",this.props.betaUserData);
  }

  //= ==================== RENDER ==========================

  render() {
    var fieldsArray = [];

    for (var i = 0; i < 3; i++) {
      fieldsArray.push(
        <div>
          <input
            type="text"
            className="form-control mt-2"
            value={this.state.title[i]}
            name="title"
            // placeholder="List up to 3 book titles that you have beta read (3 boxes)"
            onChange={this.handleDynamicInput.bind(this, i)}
          />
        </div>
      );
    }

    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="betaModal"
        >
          <ModalHeader
            toggle={this.props.toggle}
            className="eventModal"
            closeButton
          >
            <h5 className="text-center p-0"> ADD TO BETA READER LIST</h5>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                {/* <div class="form-group col-md-6">
                  <label for="endDate" id="text">
                    Name
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name"
                    name="name"
                    onChange={this.handleChange}
                    id="name"
                    // placeholder="Name"
                    value={this.state.name}
                  />
                </div> */}
                <div className="form-group col-md-12">
                  <label htmlFor="publicName" id="text">
                    Public Name*:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="publicName"
                    name="publicName"
                    onChange={this.handleChange}
                    // placeholder="Public Name "
                    value={this.state.publicName}
                    required
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="email" id="text">
                    Email address*:
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={this.handleChange}
                    // placeholder="Email address"
                    value={this.state.email}
                    required
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="goodreads" id="text">
                    Goodreads profile link*:
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id="goodreads"
                    name="goodreads"
                    onChange={this.handleChange}
                    // placeholder="Goodreads profile link"
                    value={this.state.goodreads}
                    required
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="amazon" id="text">
                    Amazon reviewer link*:
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id="amazon"
                    name="amazon"
                    onChange={this.handleChange}
                    // placeholder="Amazon reviewer link"
                    value={this.state.amazon}
                    required
                  />
                </div>

                <div className="form-group col-md-6">
                  <label htmlFor="blog" id="text">
                    Blogger link (optional):
                  </label>
                  <input
                    type="url"
                    className="form-control"
                    id="blogLink"
                    name="blogLink"
                    onChange={this.handleChange}
                    // placeholder="Blogger link (optional)"
                    value={this.state.blogLink}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label htmlFor="title" id="text">
                    {' '}
                    List up to 3 book titles that you have beta read :
                  </label>
                  {fieldsArray}
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-dark pull-right"
                value="Submit"
              >
                Submit
              </button>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    if (Object.keys(this.props.betaUserData).length) {
      const userData = this.props.betaUserData;
      this.setState({
        name: userData.name,
        publicName: userData.publicName,
        email: userData.email,
        goodreads: userData.goodreads,
        amazon: userData.amazon,
        blogLink: userData.blogLink,
        title: JSON.parse(userData.title)
      });
      this.state = userData;
    }
  }

  componentWillReceiveProps() {}
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AddToBetaReader);
