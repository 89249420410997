import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import { addEventFilter, addSortFilter } from '../../Actions/filters';
// import { getFilterEventDataList } from '../../Actions/event-setup';
import { addNewProblem } from '../../Actions/problem';
import { getProfileDetails } from '../../Actions/profile-setup';

// import '../Profile/dashboard.css'
import axios from 'axios';

// constants
const ISSUES = [
  { key: 'INAPPROPIATE', value: 'Inappropriate' },
  { key: 'COPYRIGHT', value: 'Copyright issue ' },
  { key: 'OTHERS', value: 'Other' }
];

class ProblemModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      issue: '',
      description: '',
      email: this.props.profileData ? this.props.profileData.email : '',
      eventDetails: {}
    };
  }

  componentWillReceiveProps = props => {
    if (props.eventDetails) {
      this.setState({ eventDetails: this.props.eventDetails });
    }
  };

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    let checked = target.value;

    this.setState({
      [name]: checked
    });
  };

  handleSubmit = async event => {
    try {
      event.preventDefault();

      const res = await axios.post(
        'https://otrllc.zendesk.com//api/v2/requests.json',

        {
          request: {
            requester: {
              name: this.props.profileData.profile.display_name,
              email: this.props.profileData.profile.email
            },
            subject: this.state.issue,
            comment: { body: this.state.description }
          }
        }
      );
      if (res) {
        const { id } = res.data.request;
        console.log('res', res);
        let eventData = {
          event_id: this.state.eventDetails.id,
          issue: this.state.issue,
          description: this.state.description,
          email: this.props.profileData.profile.email,
          requestId: id
        };

        console.log('eventData', eventData, this.props.profileData);
        this.props.addProblem('/api/v1/problem/addProblem', eventData, {
          headers: {
            'x-authorization': localStorage.getItem('token')
          }
        });
      }
    } catch (error) {
      console.log('Error', error);
      console.log('Error response', error.response);
    }
  };

  //===================== RENDER ==========================

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          className="report-popup"
        >
          <ModalHeader toggle={this.props.toggle}>
            <h5> Report an issue: </h5>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div class="row report-problem">
                <div class="form-group col-md-12">
                  <label for="issue">Select issue:</label>
                  <select
                    class="form-control"
                    id="issue"
                    value={this.state.value}
                    onChange={event => {
                      this.setState({ issue: event.target.value });
                    }}
                    required
                  >
                    <option value="" selected disabled>
                      Select Issue
                    </option>
                    {ISSUES.map(issues => (
                      <option value={issues.key}>{issues.value}</option>
                    ))}
                  </select>
                </div>

                <div class="form-group col-md-6">
                  <label for="eventType">Describe the issue:</label>
                </div>
                <div class="form-group col-md-12">
                  <textarea
                    style={{ width: '100%' }}
                    rows="4"
                    cols="100"
                    maxlength="1000"
                    id="description"
                    name="description"
                    onChange={this.handleChange}
                    required
                  ></textarea>
                </div>
              </div>
              <button
                type="submit"
                onClick={this.props.toggle}
                class="btn btn-dark pull-right"
                value="Send"
              >
                Send
              </button>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    // console.log("message modal called");
  }
}

const mapStateToProps = state => {
  return {
    problemStatus: state.AddProblem,
    profileData: state.myProfileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addProblem: (url, header, data) =>
      dispatch(addNewProblem(url, header, data)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProblemModal);
