import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notify } from 'react-notify-toast';
import axios from 'axios';
import { BASEURL } from '../../constant';

class ImportOtp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
    };
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
    });
  }

  verifyOtp(e) {
    e.preventDefault();
    let data = {};

    if (this.state.otp) {
      data.otp = this.state.otp;
    }

    this.checkOtp(data);
  }

  checkOtp = async (data) => {
    try {
      const url = '/api/v1/users/checkImport';
      const headers = {
        headers: { 'x-authorization': localStorage.getItem('token') },
      };

      const getData = await axios.post(BASEURL + url, data, headers);
      console.log("getData",getData.data.message)

      if (getData.status === 200) {
        this.props.toggle();
        this.setState({otp:''})
        notify.show(`${getData.data.message}`, 'custom', 9000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb',
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        notify.show(`${error.response.data.message}`, 'custom', 9000, {
          background: '#f8d7da',
          text: '#721c24',
        });
      } else {
        notify.show(
          'Something went wrong Please try again after some time.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
      }
    }
  };
  //===================== RENDER ==========================

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id='betaModal'
          className='import-otp'
        >
          <ModalHeader toggle={this.props.toggle} className='eventModal'>
            <h3 className='text-center p-0'>OTP</h3>
          </ModalHeader>
          <ModalBody>
            <div className='signup_text mt-2'>
              {/* <p>
                Please check your email to Import your events.<br></br>
                Enter 6 digit code here:
                
              </p> */}
               <p className='mb-2'>
               Check your membership email for link and instructions on how to complete 
               import process.
              </p>
              <p className='mb-2'>Enter 6 digit code here:</p>
            </div>
            <form onSubmit={this.verifyOtp.bind(this)}>
              <div className='code_main input-code'>
                <div className='form-group form-group_code'>
                  <input
                    type='text'
                    className='form-control code_form'
                    id='code'
                    placeholder='Enter 6 digit code'
                    name='otp'
                    onChange={this.handleChange.bind(this)}
                    value={this.state.otp}
                    required
                  />
                </div>
              </div>
              <div className='btn_group_submit mt-0'>
                <button className='btn btn-lg  btn-block  btn_submit' id='Next'>
                  Submit
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {};

export default connect(mapStateToProps, mapDispatchToProps)(ImportOtp);
