import React, { Component } from 'react';
import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import '../dailyTask/dailyTask.css';
import { defaultSort } from '../../Utils/useFunction';

class blastNotificationsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewModal: false,
      modal: false,
      blastContent: '',
      blastSubject: '',
      blastDisplayName: '',
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'DATE RECEIVED',
          field: `completed_date`,
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.sentdateRender.bind(this)
        },
        {
          headerName: 'SEND TIME',
          field: 'blast_time',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.timeRender.bind(this)
        },
        {
          headerName: 'SENDER',
          field: `author_first_name`,
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'BLAST SUBJECT',
          field: 'email_details',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.emailSubject.bind(this)
        },
        {
          headerName: 'TITLE',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true
          // cellRenderer: this.typeOfBlast.bind(this)
        },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
        // {
        //   headerName: 'Start Date',
        //   field: `start_date`,
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   cellRenderer: function(startDate) {
        //     if (startDate.data.start_date) {
        //       return moment(startDate.data.start_date).format('MM/DD/YYYY');
        //     } else {
        //       return '-';
        //     }
        //   }
        // }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10
    };

    this.hideModal = this.hideModal.bind(this);
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout('autoHeight');
  };

  hideModal() {
    this.setState({ show: false });
  }

  showBlast(bufferBlast) {
    if (bufferBlast.column.colId === 'edit') {
      const blastContent = JSON.parse(bufferBlast.data.email_details)[0]
        .email_body;
      const blastSubject = JSON.parse(bufferBlast.data.email_details)[0]
        .email_subject;

      const first = bufferBlast.data.fname;
      const last = bufferBlast.data.lname;
      const displayName = first + ' ' + last;
      const blastType = JSON.parse(
        bufferBlast.data.email_details
      )[0].blast_mode.toLowerCase();
      const blastTitle = bufferBlast.data.title;

      this.setState({ show: true, blastContent });
      this.setState({ show: true, blastDisplayName: displayName });
      this.setState({ show: true, blastSubject: blastSubject });
      this.setState({ show: true, blastType });
      this.setState({ show: true, blastTitle });
    }
  }

  nameCellRenderer(params) {
    return `${params.data.fname ? params.data.fname : ''} ${
      params.data.lname ? params.data.lname : ''
    }`;
  }

  emailSubject(params) {
    let data = JSON.parse(params.data.email_details);

    return data[0].email_subject;
  }
  timeRender(params) {
    const dateTime = new Date(
      `${params.data.blast_date} ${params.data.blast_time}`
    );
    return moment(dateTime).format('hh:mm A');
  }
  typeOfBlast(params) {
    if (params.data.blast_type === 'release') {
      return (
        params.data.blast_type.charAt(0).toUpperCase() +
        params.data.blast_type.slice(1)
      );
    } else if (params.data.blast_type === 'purchased') {
      return '-';
    } else {
      return '-';
    }
  }
  editEvents(params) {
    let details = params.data;
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }

  sentdateRender(params) {
    if (params.value !== null) {
      return moment(params.data.completed_date).format('MM/DD/YYYY');
    } else {
      return '-';
    }
  }
  render() {
    let filterData = '';
    let blastFilter;
    //this.props.data
    let blassst = this.props.data;

    if (blassst) {
      let dd = this.props.data
        .filter(el => !el.is_completed)
        .sort(function(a, b) {
          let aDate = new Date(`${a.completed_date} ${a.blast_time}`);

          let bDate = new Date(`${b.completed_date} ${b.blast_time}`);

          return aDate - bDate;
        });

      let dd1 =
        this.props.data &&
        this.props.data
          .filter(el => el.is_completed)
          .sort(function(a, b) {
            let aDate = new Date(`${a.completed_date} ${a.blast_time}`);

            let bDate = new Date(`${b.completed_date} ${b.blast_time}`);

            return bDate - aDate;
          });

      // let newD = this.props.data.filter(el => el.is_completed);
      // let comDate = this.props.data.filter(ele => ele['is_completed'] == 1);

      filterData = [...dd, ...dd1];
      blastFilter = filterData.filter(
        (el, index, self) => index === self.findIndex(t => t.id === el.id)
      );
      filterData = blastFilter.sort((a, b) => {
        let first_val = [a.blast_date, a.blast_time];
        let secon_val = [b.blast_date, b.blast_time];
        if (
          (new Date(first_val[0]) >= new Date(secon_val[0]) &&
            first_val[1] > secon_val[1]) ||
          (new Date(first_val[0]) >= new Date(secon_val[0]) &&
            first_val[1] < secon_val[1])
        ) {
          return 1;
        } else if (
          (new Date(first_val[0]) <= new Date(secon_val[0]) &&
            first_val[1] > secon_val[1]) ||
          (new Date(first_val[0]) <= new Date(secon_val[0]) &&
            first_val[1] < secon_val[1])
        ) {
          return -1;
        } else {
          return 0;
        }
      });
    }
    return (
      <>
        <div className="container blast-noti-page">
          <div className={'ag-theme-balham'}>
            {' '}
            <h2 className="text-center heading"> RECEIVED BLASTS</h2>
            <AgGridReact
              onGridReady={this.onGridReady}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={blastFilter}
              onCellClicked={this.showBlast.bind(this)}
              //   pagination={true}
              reactNext={true}
              animateRows
            />
          </div>
        </div>

        {/* <div className="test-header" style={{ float: "right" }}>
          Page Size:
          <select
            //   onChange={this.onPageSizeChanged.bind(this)}
            id="page-size"
            defaultValue="10"
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div> */}

        <Modal
          isOpen={this.state.show}
          toggle={this.hideModal}
          className={`blastdetails-modal received-blast-modal ${this.state.blastType}`}
        >
          <ModalHeader toggle={this.hideModal} className="eventModal">
            <h5 className="text-center p-0">Blast Details</h5>
          </ModalHeader>

          <ModalBody style={{ paddingBottom: 0 }}>
            <b>From:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastDisplayName }}
            ></div>
            {this.state.blastType === 'release' && (
              <>
                <b>Title:</b>
                <div>{this.state.blastTitle}</div>
              </>
            )}
            <b>Subject:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastSubject }}
            ></div>
            <b>Message:</b>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.blastContent }}
            ></div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default blastNotificationsList;
