import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';
import { eventLoading } from './event-setup';

const globalHistory = new createHistory();

export function Search(search) {
  return {
    type: 'SEARCH_FILTER',
    search
  };
}

export function eventFilter(data) {
  return {
    type: 'EVENT_FILTER',
    data
  };
}

export function sort(data) {
  return {
    type: 'SORT_FILTER',
    data
  };
}

export function addEventFilter(filter, status) {
  // console.log("addEventFilter called-------------------->> ", status);
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(eventFilter({}));
    dispatch(eventFilter(filter));
    if (status) {
      globalHistory.push('/dashboard/event-filter');
    }
    dispatch(eventLoading(false));
  };
}

export function addSortFilter(filter) {
  return dispatch => {
    dispatch(sort(filter));
    globalHistory.push('/dashboard/event-filter');
  };
}
