import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import {
  deleteTask,
  getAllTasks,
  editTaskDetails
} from '../../Actions/dailytask';
// import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';

import 'react-quill/dist/quill.snow.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Axios from 'axios';
import { BASEURL } from '../../constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import { notify } from 'react-notify-toast';
// import { Tooltip as ReactTooltip } from 'react-tooltip'

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import { format, formatDistance, formatRelative, subDays, addDays, compareAsc, intervalToDuration, differenceInHours } from 'date-fns';
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);

// import { en } from 'date-fns/locale/en';
// registerLocale('en', en)



class ViewTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editModal: false,
      viewMode: true,
      message: '',
      added_on: null,
      title: '',
      date: null,
      taskMessage: null,
      calender_date: null
    };
    this.quill = React.createRef();
    this.apiPostNewsImage = this.apiPostNewsImage.bind(this);
  }

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };

  handleCalendarDate(dt) {
  
    this.setState({ calender_date: dt });
  }


  handleEditorChange = (event, editor) => {
  
    const data = editor && editor.getData();

    this.setState({ taskMessage: data });
  };
  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  handleClick() {
    this.props.delete('/api/v1/daily_task/' + this.props.data.id, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    notify.show('Daily Task deleted.', 'custom', 2000, {
      background: '#d4edda',
      text: '#155724',
      borderColor: '#c3e6cb'
    });
    // this.setState()
  }
 
  apiPostNewsImage = async data => {
    const response = await Axios.post(
      `${BASEURL}/api/v1/users/uploadBlastImage`,
      data,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );
    return response;
  };
  uploadAdapter = loader => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);
          

            Axios.post(`${BASEURL}/api/v1/users/uploadBlastImage`, body, {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            })
              .then(res => res.data)
              .then(res => {
               
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };
  uploadPlugin = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return this.uploadAdapter(loader);
    };
  };
  onEdit(e) {
    e.preventDefault();
    // this.props.viewMode=false;
    this.setState({ viewMode: false });


    this.setState({
      calender_date: moment(this.props.data.added_on).format('YYYY-MM-DD'),
      //calender_date: this.props.data.added_on,
      taskMessage: this.props.data.task_message,
      title: this.props.data.title
    });

  }

  submitData(id) {
  
  
    let data = {
      task_message: this.state.taskMessage,
      added_on: moment(this.state.calender_date).format('YYYY-MM-DD'),
      title: this.state.title
    };
   

    if(!this.state.taskMessage)
    {
      notify.show('Please enter Daily Task details.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }
    else if(!this.state.title)
    {
      notify.show('Please enter Daily Task title.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }
    else if(!this.state.calender_date)
    {
      notify.show('Please enter Daily Task date.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }else{
    this.props.editTask('/api/v1/daily_task/' + id, data, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    this.props.toggle();
  }
  }

  //===================== RENDER ==========================

  render() {
  

 
 
    const endDate = new Date(moment().format('MM/DD/YYYY'));

    const endDate1 = new Date();
    // const createDate1 = endDate1.setDate(endDate1.getDate() - 7);
    // const createdDate3 = moment(createDate1).format('YYYY-MM-DD');

    // const TaskEnd = endDate.setMonth(endDate.getMonth() + 18);
    const exampledate = moment()
    .add(18, 'M')
    .calendar();
    const editButton = new Date();
    const dailyTaskDate = moment(editButton).format('YYYY-MM-DD');
    return this.props.data ? (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          className="daily-task-popup"
          size="lg"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            {this.state.viewMode ? (
              <h3 className="text-center p-0"> Task Details: </h3>
            ) : (
              <h3 className="text-center p-0"> Update Task: </h3>
            )}
          </ModalHeader>
          <ModalBody>
            {this.state.viewMode ? (
              <div class="row">
                <div class="form-group col-md-6 ">
                  {this.props.data.start && (
                    <label for="startDate" className="mr-2">
                      Date:
                    </label>
                  )}

                  {moment(this.props.data.added_on).format('MM/DD/YYYY')}
                </div>

                <div class="form-group col-md-6 ">
                  {this.props.data.title && (
                    <label for="title" className="mr-2">
                      Task Title:
                    </label>
                  )}

                  {this.props.data.title}
                </div>
                <div class="form-group col-md-6 ">
                  {this.props.data.task_message && <label>Task Details:</label>}
                </div>
               
                <div class="form-group col-md-6 "></div>
                <div class="form-group col-md-12 ">
                  {ReactHtmlParser(this.props.data.task_message)}
                </div>
             

                <div class="form-group col-md-12 float-right">
                  { new Date(dailyTaskDate) <= new Date(this.props.data.added_on) ? (
                    <>
                     <button
                    className="btn btn_save_bt btn-danger"
                    onClick={() => {
                      // if (
                      //   window.confirm(
                      //     'Are you sure you want to delete this task?'
                      //   )
                      {
                        this.handleClick(this);
                        this.props.toggle();
                      }
                    }}
                  >
                    Delete
                  </button>
                     
                  <button
                    className="btn btn_save_bt"
                    style={{ width: '10%' }}
                    onClick={this.onEdit.bind(this)}
                  >
                    Edit
                  </button>
                    
                    </>
                  ):(
                    ''
                  )}
                 
                
                </div>
              </div>
            ) : (
              <div>
                {/* <form onSubmit={this.handleSubmit}> */}
                <div class="row">
                  <div class="form-group col-md-6 ">
                    <label for="startDate">Date*:</label>
                    <div className="custom-datepicker">
                     
                      <DatePicker
                        format='MM/DD/YYYY'
                        placeholderText="mm/dd/yyyy"
                        highlightDates={new Date()} 
                        minDate={moment().toDate()}
                        value={moment(this.state.calender_date).format(
                          'MM/DD/YYYY'
                        )}
                        selected={moment(this.state.calender_date).toDate()}
                        maxDate={new Date(exampledate)}
                        id="date"
                        name="date"
                        onChange={dt => this.handleCalendarDate(dt)}
                        locale="en"
                      />   
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div class="form-group col-md-6 ">
                    <label for="title">Task Title*:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      onChange={this.handleChange.bind(this)}
                      value={this.state.title}
                    />
                  </div>
                  <div class="form-group col-md-12 ">
                    <label for="startDate">Task Detail*:</label>
                    <span style={{ display: 'inline-block' }}>
                      <i
                        className="fa fa-question-circle"
                        data-tip
                        data-for={'test'}
                        style={{ fontSize: '1.2em' }}
                      />
                      {/* <ReactTooltip
                        className="toolTip"
                        id={'test'}
                        aria-haspopup="true"
                        effect="solid"
                        place="right"
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {`Daily Tasks can be used for personal notes, To-do, posting text, campaign notes, writing goals etc.
Compose using Text Formatter bar below. Max image size is 3MB. Images can be resized by dragging & a caption added. Use 2 arrow symbols around image to add text fields above or below.
Daily Tasks can be edited & deleted. Click on LIST view to see all your Daily Tasks from last 7 days and 18 months into the future. To view older Daily Tasks; Scroll in DT calendar view and click on individual days or Daily Tasks. To adjust Daily Task Reminders; Go to Preference page under Settings, turn Reminders on or off and/or set to Daily or Weekly Digest.`}
                         <br/>

{`Default font: Raleway 12 pts.`}
                        
                        
                        </p>
                      </ReactTooltip> */}
                    </span>
              
             
                    <CKEditor
                      editor={FullEditor}
                      data={this.state.taskMessage}
                      onChange={(event, editor) => {
                        const data = editor && editor.getData();

                        this.setState({ taskMessage: data });
                      }}
                      // onReady={event => {
                      //   console.log(
                      //     Array.from(event.ui.componentFactory.names())
                      //   );
                      // }}
                      config={{
                        extraPlugins: [this.uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            resizeOptions: [
                              {
                                name: 'resizeImage:original',
                                value: null,
                                label: 'Original'
                              },
                              {
                                name: 'resizeImage:40',
                                value: '40',
                                label: '40%'
                              },
                              {
                                name: 'resizeImage:60',
                                value: '60',
                                label: '60%'
                              }
                            ],
                            toolbar: [
                              'resizeImage'
                            ]
                          },
                        },
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',                                         
                          ],
                          supportAllValues: true
                        },
                          fontSize: {
                          options: [12,14, 16, 18, 20, 22, 24],
                      }
                      }}
                    />
                    <button
                      type="submit"
                      className="btn btn_save_bt"
                      onClick={() => {
                        this.submitData(this.props.data.id);
                      }}
                    >
                      Update
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            )}
          </ModalBody>
        </Modal>
      </>
    ) : (
      <> </>
    );
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        date: this.props.data.added_on,
        taskMessage: this.props.data.task_message,
        title: this.props.data.title,
        viewMode: this.props.viewMode
      });
    }
  }

  componentWillReceiveProps() {
    if (this.props.viewMode) {
      this.setState({ viewMode: true });
    }
  }
}

const mapStateToProps = state => {
  return {
    // searchData: state.searchByUsername,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    delete: (url, headers) => dispatch(deleteTask(url, headers)),
    getAllTasks: () =>
      dispatch(
        getAllTasks('/api/v1/daily_task/getTask', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    editTask: (url, data, headers) =>
      dispatch(editTaskDetails(url, data, headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTask);
