import React, { useState, useEffect, Component } from 'react';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import AddMessage from '../messageModal';
//import moment from 'moment-timezone';
import {
  getConversationList,
  getUserMessageList
} from '../../../Actions/messages';
import { getProfileDetails } from '../../../Actions/profile-setup';
import axios from 'axios';
import './MessageList.css';
import user from '../../../Utils/Assets/avatar1.jpg';
import defaultUserImg from '../../../Utils/Assets/OTR_Default-avatar_v3.png';

const MY_USER_ID = localStorage.getItem('userId');
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//const localizer = momentLocalizer(moment);
moment.tz.setDefault(timezone);

class MessageList extends Component {
  chatContainer = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      showMessageModal: false,
      profileData: [],
      image: ''
    };
  }

  scrollToBottom = () => {
    //  this.messagesEnd.scrollIntoView({ behavior: 'auto' });
    const scroll =
      this.chatContainer.current.scrollHeight -
      this.chatContainer.current.clientHeight;
    this.chatContainer.current.scrollTo(0, scroll);
  };

  refreshMessageList() {
    let messageId = localStorage.getItem('msgId');
    this.props.getMessages(`/api/v1/message_list/${messageId}`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  renderMessages() {
    let i = 0;
    let messageCount = this.props.messages.length;
    let tempMessages = [];

    // console.log("this.props.messages in message box",this.props.messages);

    while (i < messageCount) {
      let previous = this.props.messages[i - 1];
      let current = this.props.messages[i];
      let next = this.props.messages[i + 1];
      let isMine = current.sender_user_id == localStorage.getItem('userId');
      let currentMoment = moment(current.created_at);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.created_at);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        prevBySameAuthor = previous.sender_user_id === current.sender_user_id;

        if (prevBySameAuthor && previousDuration.as('hours') < 1) {
          startsSequence = false;
        }

        if (previousDuration.as('day') < 1) {
          showTimestamp = false;
        }
      }

      if (next) {
        let nextMoment = moment(next.created_at);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        nextBySameAuthor = next.sender_user_id === current.sender_user_id;

        if (nextBySameAuthor && nextDuration.as('day') < 1) {
          endsSequence = false;
        }
      }

      tempMessages.push(
        <Message
          key={i}
          isMine={isMine}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          data={current}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return tempMessages;
  }

  render() {
    let profile = '';
    if (localStorage.getItem('activeMsg')) {
      let newImage = this.props.myProfile;
      if (newImage) {
        profile = newImage.profile.profile_details_with_values[2].profile;
      }
      var { image, name, msg_id, unseen } = JSON.parse(
        localStorage.getItem('activeMsg')
      );
    }

    console.log('name----130', name);
    // console.log("now the data is about to channge--- >", { image, name, msg_id, unseen });
    let pathImg = '';
    if (this.props.profileData) {
      const profileData = this.props.profileData.profile
        .profile_details_with_values;

      if (profileData) {
        const objImg = profileData.find(_d => _d.profile);
        console.log('profile----------> ', profile);
        if (objImg) {
          pathImg = `${localStorage.getItem('baseUrl')}${
            profile ? profile.split('\\').join('/') : ''
          }`;
        } else {
          pathImg = '';
        }
      } else {
        pathImg = '';
      }
    }
    let userName = '';

    console.log('this.props.messages', this.props.messages);
    if (this.props.messages.length > 0) {
      console.log('unannana', name);
      // const firstName = this.props.messages[0].fname
      //   ? this.props.messages[0].fname
      //   : '';
      // const lastName = this.props.messages[0].lname
      //   ? this.props.messages[0].lname
      //   : '';
      if (name) {
        userName = name;
      }
      //  console.log('this.props.messages', firstName, lastName);
    }

    console.log('yjsindjsa fcs ', name);

    return (
      <>
        <div className="message-list">
          <Toolbar
            // title="Conversation Title"
            // rightItems={[
            //   <ToolbarButton
            //     key="info"
            //     icon="fa fa-refresh"
            //     onChangeClickHandler={e => this.refreshMessageList()}
            //   />
            //   // <ToolbarButton key="video" icon="fa fa-plus-circle" />,
            //   // <ToolbarButton key="phone" icon="fa fa-plus-circle" />
            // ]}
            receiverName={userName ? userName : ''}
            user={pathImg ? pathImg : defaultUserImg}
          />

          <div className="message-list-container" ref={this.chatContainer}>
            {this.renderMessages()}
          </div>

          <Compose
            rightItems={
              [
                // <ToolbarButton key="photo" icon="ion-ios-camera" />,
                // <ToolbarButton key="image" icon="ion-ios-image" />,
                // <ToolbarButton key="audio" icon="ion-ios-mic" />,
                // <ToolbarButton key="money" icon="ion-ios-card" />,
                // <ToolbarButton key="games" icon="ion-logo-game-controller-b" />,
                // <ToolbarButton key="emoji" icon="ion-ios-happy" />
              ]
            }
          />
          <div
            ref={el => {
              this.messagesEnd = el;
            }}
          ></div>
        </div>
      </>
    );
  }

  componentWillReceiveProps() {}

  componentDidMount() {
    this.scrollToBottom();
    this.props.getUserProfile('/api/v1/user/profile', {
      headers: {
        'x-authorization': localStorage.getItem('token')
      }
    });

    //this.renderMessages();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }
}

const mapStateToProps = state => {
  return {
    messages: state.getMessageList,
    profileData: state.myProfileData,
    conversationList: state.conversationList

    //image: state.pathImg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConversationList: (url, header) =>
      dispatch(getConversationList(url, header)),
    getMessages: (url, header) => dispatch(getUserMessageList(url, header)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageList);
