import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { authenticate, signuppage } from '../../Actions/index';
import { Route, Link, Navigate } from 'react-router-dom';
import SocialButton from '../SignUp/SocialButton';
import { notify } from 'react-notify-toast';
import './login.css';
const cookies = new Cookies();
const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isSocial: false,
      showpwd: false,
      key: 0,
      rememberMe: false,
      cookiePolicy: false,
      closeCookiePolicy: false,
      ipAddress: ''
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.rememberMe = this.rememberMe.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }
  async handleLoginSubmit(e, history) {
    e.preventDefault();
    this.props.fetchData(
      '/api/v1/login',
      this.state.email,
      this.state.password,
      this.state.isSocial,
      this.state.rememberMe,
      history
    );
  }

  rememberMe(status) {
    this.setState({ rememberMe: status.target.checked });

    if (!status.target.checked) {
      cookies.remove('email');
      cookies.remove('password');
    }
  }

  handleSocialLogin = user => {
    if (!user._profile.email) {
      return notify.show(
        'Email id is not available with Facebook. Please sign-up with Google or another email id.',
        'custom',
        3000,
        { background: '#f8d7da', text: '#721c24' }
      );
    } else {
      this.props.fetchData('/api/v1/login', user._profile.email, 'null', true);
    }
  };

  handleSocialLoginFailure = err => {
    this.setState({ key: 1 });
  };

  componentWillReceiveProps() {
    // let myColor = { background: '#f8d7da', text: "#721c24"};
    // notify.show("Username or password is incorrect.", "custom", 5000, myColor);
  }
  // handleSaveCookies =async () => {
  //   localStorage.setItem("cookiepolicy", true);
  //   this.setState({ cookiePolicy: true });
  //   const payload={
  //     consent_type: "cookies policy",
  //     content:"",
  //     version:14, //dummy
  //     id: 14,    //dummy
  //     source:"website",
  //     ipAddress: this.state.ipAddress,
  //     reason: "Cookie-pop-up 1/yea",
  //     ip:this.state.ipAddress,
  //     userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : "",
  //   }
  //   await axios.post(BASEURL + "/api/v1/users/postUserConsent", payload, {
  //     headers: {
  //       "x-authorization": localStorage.getItem("token"),
  //     },
  //   });
  // }
  // async getIpAddress() {
  //   try {
  //     // const response = await axios.get("https://geolocation-db.com/json/");
  //     const response = await axios.get("https://api.ipify.org/?format=json");
  //     console.log("responsedddddddddddddddddddddddddddddd", response.data.ip);
  //     this.setState({ ipAddress: response.data.ip });
  //     //this.setState({ ipAddress: response.data.IPv4 });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  render() {
    return (
      <div className="login-screen-page">
        <div className="loginScreen" style={{ backgroundColor: '#1f1f1f' }}>
          <div className="container">
            <Logo />
            <div className="row">
              <div className="col-sm-12 col-md-10 col-lg-9 mx-auto">
                <div className="my-3">
                  <Route
                    render={({ history }) => (
                      <>
                        <form
                          className="form-signin"
                          onSubmit={e => {
                            this.handleLoginSubmit(e, history);
                          }}
                        >
                          <div className="row">
                            <div className="col-12 col-sm-6">
                              <div className="form-label-group form_gp_lft">
                                <input
                                  type="text"
                                  id="inputEmail"
                                  value={this.state.email}
                                  className="form-control email"
                                  placeholder="Email or Username"
                                  onChange={this.handleChangeEmail}
                                  required
                                  autoFocus
                                />
                              </div>
                            </div>
                            <div className="col-12 col-sm-6">
                              <div className="form-label-group form_gp_rgyt">
                                <input
                                  type={
                                    this.state.showpwd ? 'text' : 'password'
                                  }
                                  id="inputPassword"
                                  value={this.state.password}
                                  className="form-control password"
                                  placeholder="Password"
                                  onChange={this.handleChangePassword}
                                  required
                                ></input>
                                <i
                                  className={
                                    'fa fa-eye' +
                                    (this.state.showpwd ? '-slash' : '') +
                                    ' pull-right show-pwd'
                                  }
                                  onClick={() => {
                                    this.setState({
                                      showpwd: !this.state.showpwd
                                    });
                                  }}
                                ></i>
                              </div>
                            </div>
                          </div>
                          <div className="custom-control custom-checkbox mb-4 mt-4">
                            <input
                              type="checkbox"
                              id="check"
                              onChange={e => this.rememberMe(e)}
                              style={{ display: 'none' }}
                              checked={this.state.rememberMe}
                            />
                            <label htmlFor="check" className="checkbox_label">
                              <div className="mark"></div>
                            </label>
                            <p style={{ color: '#fff' }}>Remember me</p>
                          </div>
                          <div className="custom-control custom-checkbox mb-4 mt-4">
                            <Link to="/forgot" style={{ color: '#fff' }}>
                              Forgot password?
                            </Link>
                          </div>
                          <div className="col-12 col-sm-3 mx-auto">
                            <button
                              className="btn btn-lg  btn-block  btn_login"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                          
                        </form>

                        <div
                          className="col-12 col-sm-10 mx-auto mb-4 mt-4 text-center"
                          key={this.state.key}
                        >
                          <p className="text-white mb-0">
                            {' '}
                            Log in with a social media account
                          </p>
                          <ul className="social">
                            <li>
                              <SocialButton
                                provider="facebook"
                                appId="3522912994486567"
                                onLoginSuccess={this.handleSocialLogin}
                                onLoginFailure={this.handleSocialLoginFailure}
                                scope="user_birthday,user_hometown"
                              >
                                <img
                                  src={require('../../Utils/Assets/social_media/White/facebook.png')}
                                  className="facebookLogo"
                                  alt="facebook-logo"
                                />
                              </SocialButton>
                            </li>
                            <li>
                              <SocialButton
                                provider="google"
                                appId="448689382304-i4f7nvtt2hrnbbbhv0oopj32vqjdvefo.apps.googleusercontent.com"
                                onLoginSuccess={this.handleSocialLogin}
                                onLoginFailure={this.handleSocialLoginFailure}
                              >
                                <img
                                  src={require('../../Utils/Assets/social_media/White/google.png')}
                                  alt="google-logo"
                                  className="facebookLogo "
                                />
                              </SocialButton>
                            </li>
                          </ul>
                        </div>
                        
                        <div
                          className="col-12 col-sm-10 mx-auto mb-4 mt-4 text-center"
                          // key={this.state.key}
                        >
                                <Link to="/convertsocialaccount" style={{ color: '#fff', textDecoration: 'none' }}>
                                Convert from Facebook/Google credentials login to OTR login.
                            </Link>
                          {/* <a href='http://localhost:3000/#/conversocialaccount' style={{ color: '#fff', textDecoration: 'none' }}>
                          <p className="text-white mb-0" >
                            {' '}
                            Convert from Facebook/Google credentials login to OTR login.
                          </p>
                          </a> */}
                         
                        </div>
                      </>
                    )}
                  ></Route>
                </div>
              </div>
            </div>
            <div className="row" style={{ backgroundColor: '#1f1f1f' }}>
              <div className="col-12 mx-auto">
                <div className="footer_contant">
                  {' '}
                  <span style={{ color: '#fff' }}>Not yet a member?</span>
                  <Route
                    render={({ history }) => (
                      <button
                        className="btn btn_signup"
                        type="submit"
                        style={{ color: '#fff' }}
                        onClick={() => {
                          history.push('/join');
                        }}
                      >
                        Sign up
                      </button>
                    )}
                  ></Route>
                </div>
              </div>
            </div>
          </div>

          {/* social media footer */}

          {/* {!this.state.cookiePolicy ? (
          <div class="cookip-policy">
            <div class="cookip-inner">
              <div class="container">
                <div class="row">
                  <div class="col-md-9">
                    <b>
                      {" "}
                      We use cookies to ensure the functionality Of our
                      platform, to personalize content, to provide media
                      features and ads, and to analyze our traffic. For more
                      information, please see our{" "}
                      <Link to={"/privacy"} className="red">
                        <u>privacy policy.</u>
                      </Link>
                    </b>
                  </div>
                  <div class="col-md-3 d-flex align-items-center mt-3 mt-mb-0">
                    <button
                      class="btn btn-lg cookip-btn"
                      onClick={(e) => this.handleSaveCookies()}
                    >
                      Accept
                    </button>
                    <button
                      class="close ml-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ cookiePolicy: true });
                      }}
                    >
                      <i class="fa fa-times-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.props.resetSignupPage();
    if (cookies.get('email')) {
      this.setState({ email: cookies.get('email') });
      this.setState({ rememberMe: true });
    }

    if (localStorage.getItem('cookiepolicy') === 'true') {
      this.setState({ cookiePolicy: true });
    }

    if (cookies.get('password')) {
      // let password=cryptr.decrypt(cookies.get('password'));
      // if(password)
      // {
      this.setState({ password: cookies.get('password') });
      // }
      // else{
      //     this.setState({password:''});
      // }
    }
  }
}

//{======= Logo Component ========}
const Logo = () => (
  <div className="row">
    <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
      <div className="logo mt-4">
        {' '}
        <a href="/">
          <img src={require('../../Utils/Assets/logo.png')} alt="logo" />
        </a>{' '}
      </div>
    </div>
  </div>
);

//{======= Footter Component ========}
const Footer = () => (
  <footer style={{ padding: '0px' }}>
    <div className="container">
      <div className="row">
        <div className="col-12 mx-auto">
          <div className="footer_contant">
            {' '}
            <span style={{ color: '#fff' }}>Not yet a member?</span>
            <Route
              render={({ history }) => (
                <button
                  className="btn btn_signup"
                  type="submit"
                  style={{ color: '#fff' }}
                  onClick={() => {
                    history.push('/join');
                  }}
                >
                  Sign up
                </button>
              )}
            ></Route>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

const mapStateToProps = state => {
  return {
    users: state.userlists,
    status: state.userloading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: (url, login, password, social, rememberMe, history) =>
      dispatch(authenticate(url, login, password, social, rememberMe, history)),
    resetSignupPage: () => dispatch(signuppage(0))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
