import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASEURL } from '../../constant';
// import { setupEvent, getFilterEventDataList ,addEventToFavourite} from '../../../Actions/event-setup';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
// import './eventFilter.css';
import '../dashboard/event-filter/eventFilter.css';

const overlayNoRowsTemplate = '<span>No record found</span>';

class ListAdvertisement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      data: [],
      showAdvertisedata: '',
      isOpenAdvertise: false,
      imgurl: '',
      appImage: '',
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'Title',
          field: 'title',
          sortable: true,
          filter: true,
          width: 130,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'AUTHOR NAME',
          field: 'author_name',
          sortable: true,
          filter: true,
          width: 130,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'BOOK LINK',
          field: 'book_link',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 150,
          cellRenderer: this.booklink.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 150,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 140,
          cellRenderer: function(endDate) {
            return moment(endDate.data.end_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'Email',
          field: 'email',
          sortable: true,
          filter: true,
          width: 160,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'CONTACT NAME',
          field: 'contact_name',
          sortable: true,
          filter: true,
          width: 160,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${params.data.author_last_name ? params.data.author_last_name : ''}`;
  }
  showTime(params) {
    return params.data.time
      ? params.data.time + ' ' + this.amPm(params.data.time)
      : '-';
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  getFormattedValue(params) {}

  titleCellRenderer(params) {
    console.log('eee', params.data.title);
    return `${
      params.data.title
        ? params.data.title.length > 20
          ? params.data.title.substr(0, 20) + '...'
          : params.data.title
        : ''
    } `;
  }
  editEvents(params) {
    // let details = params.data;
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }
  booklink(params) {
    return params.data.book_link ? params.data.book_link : '-';
  }

  showEvent(e) {
    if (e.column.colId === 'edit') {
      let data = e.data;
      // let actionType = e.event.target.getAttribute('data-action-type');

      this.togglePrivacyModal(data);
    }
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }
  togglePrivacyModal = data => {
    this.setState({
      isOpenAdvertise: !this.state.isOpenAdvertise,
      imgurl: data.web_image,
      appImage: data.app_image
    });
  };

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  //===================== RENDER ==========================
  render() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    return (
      <>
        <Modal
          isOpen={this.state.isOpenAdvertise}
          toggle={this.togglePrivacyModal.bind()}
          id="myModal"
          className="advertise-privacy-popup delete-confirmation-popup my-event-setup-popup"
        >
          <ModalHeader
            toggle={this.togglePrivacyModal.bind()}
            className=""
          ></ModalHeader>
          <ModalBody>
            <div className="show-advertise-popup">
              <img
                src={`${BASEURL}/${this.state.imgurl}`}
                className="mob-view"
                alt="mob-view"
              />
              {/* <img
                src={`${BASEURL}/${this.state.appImage}`}
                className="mob-view"
              /> */}
            </div>
            <div className="show-app-popup">
              <img
                src={`${BASEURL}/${this.state.appImage}`}
                className="mob-view"
                alt="mob-view"
              />
            </div>
            {/* <img src={`${userServer}/${image}`} className="web-view" /> */}
          </ModalBody>
        </Modal>
        <div className="container event-filter-page">
          <div className="mb-4">
            <h2 className="text-center heading">MY ADVERTISEMENTS</h2>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            <AgGridReact
              onGridReady={this.onGridReady}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={this.state.showAdvertisedata}
              pagination={true}
              reactNext={true}
              animateRows
              onCellClicked={this.showEvent.bind(this)}
              // onRowClicked={this.addToFavourite.bind(this)}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
              overlayNoRowsTemplate={overlayNoRowsTemplate}
            />
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </>
    );
  }
  async componentDidMount() {
    const exist = await axios.get(BASEURL + `/api/v1/users/getadvertise`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    this.setState({ showAdvertisedata: exist.data.result });
  }
}

const mapStateToProps = state => {
  // console.log({ });
  return {
    // data: state.getNotification.currentUserData
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ListAdvertisement);
