import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { editDetailsOtpVerify } from '../../Actions/profile-setup';

class Otpverify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: null,
      password: null,
      oldPassword: null,
      email: null,
    };
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    this.setState({
      [name]: target.value,
    });
  }

  verifyOtp(e) {
    let data = {};

    if (this.state.otp) {
      data.otp = this.state.otp;
    }
    if (this.state.email) {
      data.email = this.state.email;
    }
    if (this.state.password) {
      data.password = this.state.password;
    }
    if (this.state.oldPassword) {
      data.oldPassword = this.state.oldPassword;
    }

    if (Object.keys(data).length > 0) {
      this.props.editDetailsOtpVerify('/api/v1/verify_otp', data, {
        headers: { 'x-authorization': localStorage.getItem('token') },
      });
    }
    /* else
     {
         notify.show("Please Enter Details", "custom", 5000, { background: '#f8d7da', text: "#721c24" });

     } */

    e.preventDefault();
  }
  //= ==================== RENDER ==========================

  render() {
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id='betaModal'
        >
          <ModalHeader toggle={this.props.toggle} className='eventModal'>
            <h5 className='text-center p-0'>OTP</h5>
          </ModalHeader>
          <ModalBody>
            <div className='signup_text mt-2'>
              <p>
                Please check your email to activate your account.
                <br />
                Enter 6 digit code here:
              </p>
            </div>
            <form onSubmit={this.verifyOtp.bind(this)}>
              <div className='code_main'>
                <div className='form-group form-group_code'>
                  <input
                    type='text'
                    className='form-control code_form'
                    id='code'
                    placeholder='Enter 6 digit code'
                    name='otp'
                    onChange={this.handleChange.bind(this)}
                    valur={this.state.otp}
                    required
                  />
                </div>
              </div>
              <div className='btn_group_submit mt-0'>
                <button
                  className='btn btn-lg  btn-block  btn_submit'
                  id='Next'
                  onClick={this.onSubmit}
                >
                  Submit
                </button>
              </div>
            </form>
            {/* <div className="bottom_btn mt-0">
              <div className="footer_contant"> <span style={{ color: "#000", fontWeight: "bold" }}>Did not get email?</span>
                <button className="btn btn_resend" type="button" style={{ color: "#000" }} >Re-send</button>
              </div>

            </div> */}
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {}

  componentWillReceiveProps() {
    // console.log("this.props.data",this.props.data);

    if (this.props.data) {
      if (this.props.data.email) {
        if (this.props.profileData.profile.email !== this.props.data.email) {
          this.setState({
            email: this.props.data.email,
          });
        }
      }
      if (this.props.data.password) {
        this.setState({
          password: this.props.data.password,
        });
      }
      if (this.props.data.oldPassword) {
        this.setState({
          oldPassword: this.props.data.oldPassword,
        });
      }
    }
  }
}

const mapStateToProps = (state) => ({
  profileData: state.myProfileData,
});

const mapDispatchToProps = (dispatch) => ({
  editDetailsOtpVerify: (url, data, header) =>
    dispatch(editDetailsOtpVerify(url, data, header)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Otpverify);
