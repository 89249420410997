import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getClientToken } from '../../Actions/payment';
import DropIn from 'braintree-web-drop-in-react';
// import '../Profile/dashboard.css'
import { BASEURL } from '../../constant';

import axios from 'axios';

class PaymentModal extends Component {
  instance;
  constructor(props) {
    super(props);
    this.state = {
      //console.log
      clientToken: this.props.token
    };

    // this.htmlParser = new HTMLParser();
  }

  savePromoCode = () => {
    const { allDiscountData } = this.props;
    console.log(' this.props', this.props);
    const payload = {
      promo_code: allDiscountData.id,
      billing_cycle: allDiscountData.numberOfBillingCycles,
      uses: 1
    };
    axios
      .post(BASEURL + '/api/v1/savePromoCode', payload)
      .then(response => {
        console.log('response', response);
        //this.setState({ brainTreeDiscounts: response.data.discounts });
      })
      .catch(error => {
        console.log(error);
      });
  };
  async buy() {
    console.log('this.props.planCost---> ', this.props.planCost);
    if (
      this.instance &&
      +this.props.planCost >= 0 &&
      this.props.planCost !== 'TBD'
    ) {
      // Send the nonce to your server
      console.log('this.instance', this.instance);
      const { nonce } = await this.instance.requestPaymentMethod();
      this.props.addClientToken(nonce);
      this.savePromoCode();

      console.log('nonce is :', nonce);
      // await fetch(`server.test/purchase/${nonce}`);
    } else {
      this.props.addClientToken('');
    }
  }

  //===================== RENDER ==========================

  render() {
    let plan_cost = 0;
    plan_cost = this.props.planCost;
    // const isUser =
    //   +localStorage.getItem("membershipId") == 6 ||
    //   +localStorage.getItem("membershipId") == 5
    //     ? true
    //     : false;
    // if (this.props.blast) {
    //   plan_cost = this.props.planCost;
    // }
    // if (localStorage.getItem("prCode") !== null) {
    //   let discount = this.props.planCost * 0.1;
    //   plan_cost = this.props.planCost - discount;
    // } else {
    //   plan_cost = this.props.planCost;
    // }

    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h3 className="text-center p-0">PAYMENT: </h3>
          </ModalHeader>
          <ModalBody>
            {this.props.token && (
              <div>
                <DropIn
                  options={{
                    authorization: this.props.token,
                    paypal: {
                      flow: 'vault',
                      amount: this.props.planCost ? plan_cost : 0,
                      currency: 'USD'
                    },
                    paypalCredit: {
                      flow: 'vault',
                      amount: this.props.planCost ? plan_cost : 0,
                      currency: 'USD'
                    },
                    venmo: {
                      allowNewBrowserTab: false
                    },
                    vaultManager: true
                  }}
                  onInstance={instance => (
                    (this.instance = instance),
                    instance.isPaymentMethodRequestable()
                  )}
                />
                {/* <br />
                <div>
                  <input
                    type="text"
                    className="form-control code_form"
                    id="PromoCode"
                    placeholder="Enter promo code"
                  />
                </div>
                <br />
                <br /> <br /> */}
                {/* <button > PAY $ <span class="badge">{this.props.planCost}</span> </button> */}
                <button
                  onClick={this.buy.bind(this)}
                  type="button"
                  class="btn btn-dark"
                >
                  Pay ${' '}
                  <span class="badge" style={{ fontSize: '1.0em' }}>
                    {plan_cost}
                  </span>
                </button>
                {!this.props.blast && (
                  <div className="mt-5" style={{ fontSize: '0.7rem' }}>
                    {/* <span>
                      <b>
                        <i
                          class="fa fa-exclamation-triangle"
                          aria-hidden="true"
                        ></i>{" "}
                        Disclaimer:{" "}
                      </b>
                      On the day of one-year membership expiration you will
                      auto-renew at the regular membership rate at time of
                      renewal.
                    </span> */}
                  </div>
                )}
              </div>
            )}
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    if (this.props.token === '') {
      let userId = localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : localStorage.getItem('i_user');
      this.props.getClientToken(`/api/v1/payment/generateToken/${userId}`);
    }
  }

  componentWillReceiveProps() {}
}

const mapStateToProps = state => {
  return {
    token: state.genrateClientToken
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getClientToken: url => dispatch(getClientToken(url))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentModal);
