import axios from 'axios';
import { notify } from 'react-notify-toast';
import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';
import { BASEURL } from '../constant';

export default createHistory();

export function AddProblem(status) {
  return {
    type: 'ADD_PROBLEM',
    status
  };
}

export function eventLoading(bool) {
  return {
    type: 'EVENT_LOADING',
    status: bool
  };
}

export function addNewProblem(url, data, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .post(BASEURL + url, data, headers)
      .then(response => {
        dispatch(AddProblem(true));
        notify.show('Issue reported successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          dispatch(eventLoading(false));
          notify.show('Failed to create problem.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }

        // history.push("/setup-event");
      });
  };
}
