import React, { Component } from 'react';
import { connect } from 'react-redux';
import createHistory from 'history/createHashHistory';

import { Link, Navigate } from 'react-router-dom';
import defaultUserImg from '../../Utils/Assets/OTR_Default-avatar_v3.png';
import moment from 'moment';
import { getProfileDetails } from '../../Actions/profile-setup';
import Loader from '../../Components/Loader/loader';
import { newLoader } from '../../Reducers/message';

const globalHistory = new createHistory();
class MyProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileKeyData: [],
            displayEmail: false,
            prfirm: [],
            userId: null,
            code: null
        };
    }
    isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    //= ==================== RENDER ==========================
    render() {
        let fullName = null;
        if (this.props.profileData.profile && this.props.profileData.profile) {
            fullName = `${this.props.profileData.profile.fname} ${this.props.profileData.profile.user_details ? this.props.profileData.profile.user_details[2].value
                    : '' } ${this.props.profileData.profile.lname}`;
        }

        if (this.props.profileData) {
            const profileData = this.props.profileData.profile.profile_details_with_values;

            let pathImg = '';
            if (profileData) {
                const objImg = profileData.find(_d => _d.profile);
                if (objImg) {
                    pathImg = `${localStorage.getItem('baseUrl')}${objImg ? objImg.profile.split('\\').join('/') : '' }`;
                } else if (!(localStorage.getItem('userType') === '3')) {
                    pathImg = defaultUserImg;
                }
            } else {
                pathImg = defaultUserImg;
            }

            // let ful =
            //   this.props.profileData.profile.fname +
            //   '' +
            //   this.props.profileData.profile.lname;
            // console.log(profileData, 'profileData')
            let mm = profileData.filter(el => {
                return el;
            });
            // profileData.findIndex( (element) => element.name);

            mm.splice(9, 0, { Other: 'jjjjjjj' });
            // mm.splice(mm.length - 4, 0, { Other: 'jjjjjjj' });
            // let dd = mm.indexOf(Other)

            let obj = {};
            let type = {};

            const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

            profileData.forEach(el => {
                const key = Object.keys(el)[0];

                if (obj[key]) {
                    if (Array.isArray(obj[key])) {
                        const prevValue = [...obj[key]];
                        prevValue.push(el[key]);
                        obj[key] = [...prevValue];
                    } else {
                        obj[key] = [obj[key], el[key]];
                    }
                } else {
                    obj[key] = el[key];
                }

                if (urlRegex.test(el[key])) {
                    type[key] = 'link';
                } else {
                    type[key] = 'string';
                }
            });

            // const VCOLength = obj[
            //   'Currently featured on Vendor Cancellation Openings (VCO) list'
            // ]
            //   ? obj[
            //       'Currently featured on Vendor Cancellation Openings (VCO) list'
            //     ][0].filter(
            //       el =>
            //         new Date().getTime() >= new Date(Object.values(el)[0]).getTime()
            //     )
            //   : '';

            const VCOLength = obj['Currently featured on Vendor Cancellation Openings (VCO) list'] ? obj['Currently featured on Vendor Cancellation Openings (VCO) list'][0].filter(
                el => {
                    const dateObject = new Date(Object.values(el)[0]);
                    dateObject.setHours(23, 59, 59, 999);
                    const fullDateFormat = dateObject.toISOString();

                    return new Date().getTime() >= new Date(fullDateFormat).getTime();
                }
            ) : '';

            return (
                <>
                    {!this.props.loading && this.props.newLoader ? <Loader /> : null}
                    <div className="container my-profile-page">
                        <div className="mb-4">
                            <h2 className="text-center heading"> My profile</h2>
                        </div>
                        <div className="model_cover profile_detail my-profile">
                            <div className="eventModal">
                                {pathImg !== '' ? (
                                    <div
                                        className={`profile_image gg ${pathImg
                                                ? localStorage.getItem('membershipId') === '1' ||
                                                    localStorage.getItem('membershipId') === '8'
                                                    ? 'authorImg'
                                                    : localStorage.getItem('membershipId') === '4'
                                                        ? 'vendorImg'
                                                        : localStorage.getItem('membershipId') === '5'
                                                            ? 'prFirmImg'
                                                            : localStorage.getItem('membershipId') === '6'
                                                                ? 'bloggerImg'
                                                                : ''
                                                : ''
                                            }`}
                                        style={{
                                            // backgroundImage: pathImg !== '' ? `url(${pathImg})` : '',
                                            backgroundSize: pathImg !== '' ? 'contain' : '',
                                            backgroundRepeat: 'no-repeat',
                                            height: pathImg !== '' ? '250px' : '100px',
                                            width: '100%',
                                            backgroundPositionX: 'center'
                                        }}
                                    >
                                        <img src={pathImg !== '' ? pathImg : ''} alt="img" />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <div className="event_body">
                                {this.props.profileData.profile.membership_id === '8' ||
                                    this.props.profileData.profile.membership_id === '1' ? (
                                    <>
                                        {' '}
                                        <ul>
                                            <li class="bullet-remove">
                                                Author:{' '}
                                                <b>
                                                    {this.props.profileData.profile.fname}{' '}
                                                    {this.props.profileData.profile.user_details[2].value
                                                        ? this.props.profileData.profile.user_details[2]
                                                            .value
                                                        : ''}{' '}
                                                    {this.props.profileData.profile.lname}
                                                </b>
                                                {/* <b>{obj.Author}</b> */}
                                            </li>
                                            {/* <li class="bullet-remove">
                        Contact: <b>{obj.Contact}</b>
                      </li> */}
                                            {obj['Publicly display email on profile page?'] &&
                                                obj['Publicly display email on profile page?'] ===
                                                'Yes' ? (
                                                <li class="bullet-remove">
                                                    Contact:{''} <b> {obj.Contact}</b>
                                                </li>
                                            ) : (
                                                ''
                                            )}
                                            {obj.Goodreads && (
                                                <li class="bullet-remove">
                                                    Goodreads:{' '}
                                                    <a
                                                        href={obj.Goodreads}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj.Goodreads.length > 10
                                                            ? obj.Goodreads
                                                            : obj.Goodreads.slice(0, 20)}
                                                        {'...'}
                                                    </a>
                                                </li>
                                            )}
                                            {obj.Website && (
                                                <li class="bullet-remove">
                                                    Website:{' '}
                                                    <a
                                                        href={obj.Website}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj.Website}
                                                    </a>
                                                </li>
                                            )}
                                            {obj['Social Media'] && !!obj['Social Media'][0].length && (
                                                <li class="bullet-remove">
                                                    {' '}
                                                    <li>
                                                        <b>Social media:</b>
                                                    </li>
                                                    {obj['Social Media'] &&
                                                        obj['Social Media'][0].map(subObj => {
                                                            return (
                                                                <li className="more-profile">
                                                                    <a
                                                                        href={Object.values(subObj)[0].toString()}
                                                                        target="_blank"
                                                                        //className="ellipsis"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {/* {Object.keys(subObj)[0].length >15 ? Object.keys(subObj)[0] : Object.keys(subObj)[0].slice(0,30)}{'...'} */}
                                                                        {Object.keys(subObj)[0]}
                                                                    </a>
                                                                    &nbsp;|&nbsp;
                                                                </li>
                                                            );
                                                        })}
                                                </li>
                                            )}
                                            {obj['Reader Groups'] &&
                                                !!obj['Reader Groups'][0].length && (
                                                    <li class="bullet-remove">
                                                        {' '}
                                                        <li>
                                                            <b>Reader groups:</b>
                                                        </li>
                                                        {obj['Reader Groups'] &&
                                                            obj['Reader Groups'][0].map(subObj => {
                                                                return (
                                                                    <ul>
                                                                        <li>
                                                                            {Object.keys(subObj)[0]}:{' '}
                                                                            <a
                                                                                href={Object.values(subObj)}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.values(subObj).slice(0, 25)}
                                                                                {'...'}
                                                                            </a>{' '}
                                                                        </li>
                                                                    </ul>
                                                                );
                                                            })}
                                                    </li>
                                                )}
                                            {obj['Purchase sites'] &&
                                                !!obj['Purchase sites'][0].length && (
                                                    <li class="bullet-remove">
                                                        {' '}
                                                        <li>
                                                            <b>Purchase sites:</b>
                                                        </li>
                                                        {obj['Purchase sites'] &&
                                                            obj['Purchase sites'][0].map(subObj => {
                                                                return (
                                                                    <ul>
                                                                        <li>
                                                                            {Object.keys(subObj)[0]}:{' '}
                                                                            <a
                                                                                href={Object.values(
                                                                                    subObj
                                                                                )[0].toString()}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.values(subObj)[0].slice(0, 30)}
                                                                                {'...'}
                                                                            </a>{' '}
                                                                        </li>
                                                                    </ul>
                                                                );
                                                            })}
                                                    </li>
                                                )}{' '}
                                            <li class="bullet-remove">
                                                <b>Other:</b>
                                            </li>
                                            {obj.Alias && (
                                                <li class="bullet-remove">
                                                    Alias: <b>{obj.Alias}</b>
                                                </li>
                                            )}
                                            {obj['Newsletter sign up'] && (
                                                <li class="bullet-remove">
                                                    Newsletter sign up:{' '}
                                                    <a
                                                        href={obj['Newsletter sign up']}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj['Newsletter sign up']}
                                                    </a>
                                                </li>
                                            )}
                                            {obj['Master ARC list sign up'] && (
                                                <li class="bullet-remove">
                                                    Master ARC list sign up:{' '}
                                                    <a
                                                        href={obj['Master ARC list sign up']}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj['Master ARC list sign up']}
                                                    </a>
                                                </li>
                                            )}
                                            {obj['Best site to reach me'] && (
                                                <li class="bullet-remove">
                                                    {obj['Best site to reach me'].split(':')[0] ===
                                                        'http' ||
                                                        obj['Best site to reach me'].split(':')[0] ===
                                                        'https' ? (
                                                        <>
                                                            {' '}
                                                            Best site to reach me:{' '}
                                                            <a
                                                                href={obj['Best site to reach me']}
                                                                //className="ellipsis"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {obj['Best site to reach me'].slice(0, 20)}
                                                                {'...'}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <>
                                                            Best site to reach me:{' '}
                                                            <b>{obj['Best site to reach me']}</b>
                                                        </>
                                                    )}
                                                </li>
                                            )}
                                            {obj.Bio && (
                                                <>
                                                    <li class="bullet-remove">Bio:</li>{' '}
                                                    <li class="bullet-remove">
                                                        <i>{obj.Bio}</i>
                                                    </li>
                                                </>
                                            )}
                                            {this.props.profileData.profile.follow_code && (
                                                <>
                                                    <li className="bullet-remove">
                                                        INVITE code:{' '}
                                                        <b>{this.props.profileData.profile.follow_code}</b>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        Link to profile:{' '}
                                                        <Link
                                                            to={`/users/${this.props.profileData.profile.follow_code}`}
                                                            target="_blank"
                                                        >
                                                            {window.location.host}
                                                            /#/users/
                                                            {this.props.profileData.profile.follow_code}
                                                        </Link>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile: </b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id
                                                                            }&name=${fullName ? fullName : obj.Author}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    /#/ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>{' '}
                                    </>
                                ) : this.props.profileData.profile.membership_id == 4 ? (
                                    <>
                                        {' '}
                                        <ul>
                                            <>
                                                <li class="bullet-remove">
                                                    Vendor: <b>{obj.Vendor}</b>
                                                </li>
                                                {obj['Services offered'] &&
                                                    !!obj['Services offered'].length && (
                                                        <li class="bullet-remove">
                                                            Services offered: <b>{obj['Services offered']}</b>
                                                        </li>
                                                    )}
                                                {obj.emailDisplay && obj.emailDisplay === 'Yes' ? (
                                                    <li class="bullet-remove">
                                                        Contact:{'  '}
                                                        <b>{obj.Contact}</b>
                                                    </li>
                                                ) : (
                                                    ''
                                                )}

                                                <li class="bullet-remove">
                                                    Website:{' '}
                                                    <a
                                                        href={obj['Website']}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj.Website && obj.Website.slice(0, 30)}
                                                        {'...'}
                                                    </a>
                                                </li>
                                                {obj['Social media'] &&
                                                    !!obj['Social media'][0].length && (
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <li>
                                                                <b>Social media:</b>
                                                            </li>
                                                            {obj['Social media'] &&
                                                                obj['Social media'][0].map(subObj => {
                                                                    return (
                                                                        <li className="more-profile">
                                                                            <a
                                                                                href={Object.values(
                                                                                    subObj
                                                                                )[0].toString()}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.keys(subObj)[0]}
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                        </li>
                                                                    );
                                                                })}
                                                        </li>
                                                    )}
                                                {obj['Accepting new clients?'] && (
                                                    <li class="bullet-remove">
                                                        Are you accepting new clients:{' '}
                                                        <b>{obj['Accepting new clients?']}</b>
                                                    </li>
                                                )}
                                                {obj[
                                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                ] &&
                                                    obj[
                                                        'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                    ].length == '1' ? (
                                                    <>
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <b>Vendor Cancellation Openings (VCO):</b>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <>
                                                        <li class="bullet-remove">
                                                            <b>Vendor Cancellation Openings (VCO):</b>
                                                        </li>
                                                        <ul>
                                                            <li>No</li>
                                                        </ul>
                                                    </>
                                                )}
                                                {obj[
                                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                ] ? (
                                                    <div className="mb-1">
                                                        {obj[
                                                            'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                        ][0].map(subObj => {

                                                            const dateObj = new Date(Object.values(subObj)[0]);
                                                            dateObj.setHours(23, 59, 59, 999);
                                                            const fullFormat = dateObj.toISOString();
                                                            return (
                                                                <>
                                                                    {new Date().getTime() >= new Date(fullFormat).getTime() ? (
                                                                        <>
                                                                            {/* <ul>
                                                                                <li>No</li>
                                                                            </ul> */}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ul>
                                                                                {' '}
                                                                                <li>
                                                                                    <b>{Object.keys(subObj)[0]}</b>:{' '}
                                                                                    {moment(
                                                                                        Object.values(subObj)[0]
                                                                                    ).format('MMMM DD, YYYY')}
                                                                                </li>
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </>
                                                            );
                                                        })}
                                                        {VCOLength.length == obj['Currently featured on Vendor Cancellation Openings (VCO) list'][0].length && (
                                                            <ul>
                                                                <li>No</li>
                                                            </ul>
                                                        )}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                <li class="bullet-remove">
                                                    Best site to interact with us:{' '}
                                                    {/* <a
                            href={obj['Best site to interact with us']}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {obj['Best site to interact with us'] &&
                              obj['Best site to interact with us'].slice(0, 25)}
                            {'...'}
                          </a> */}
                                                    {type['Best site to interact with us'] == 'link' ? (
                                                        <>
                                                            <a
                                                                href={obj['Best site to interact with us']}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {obj['Best site to interact with us'] &&
                                                                    obj['Best site to interact with us'].slice(0, 25)}
                                                                {'...'}
                                                            </a>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <b>{obj['Best site to interact with us']}</b>
                                                        </>
                                                    )}
                                                </li>
                                                {/* {obj['Recent clients/works'] && (
                          <li class="bullet-remove">
                            Recent clients/works:{' '}
                            <b>{obj['Recent clients/works'].toUpperCase()}</b>
                          </li>
                        )} */}

                                                {obj['Recent clients/works'] && (
                                                    <li class="bullet-remove">
                                                        Recent clients/works:{' '}
                                                        {obj['Recent clients/works'].map(el => (
                                                            <>
                                                                {el.split(':')[0] === 'http' ||
                                                                    el.split(':')[0] === 'https' ? (
                                                                    <ul>
                                                                        <li>
                                                                            <a
                                                                                href={el}
                                                                                //className="ellipsis"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {el.length > 50
                                                                                    ? el.slice(0, 50) + '...'
                                                                                    : el}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                ) : (
                                                                    <ul>
                                                                        <li>{el}</li>
                                                                    </ul>
                                                                )}
                                                            </>
                                                        ))}
                                                    </li>
                                                )}
                                                <li class="bullet-remove">
                                                    Brief introduction: <b>{obj['Brief introduction']}</b>
                                                </li>
                                                {obj.Bio && (
                                                    <>
                                                        <li class="bullet-remove">Bio:</li>{' '}
                                                        <li class="bullet-remove">
                                                            <i>{obj.Bio}</i>
                                                        </li>
                                                    </>
                                                )}
                                            </>

                                            {this.props.profileData.profile.membership_id == 5 ||
                                                this.props.profileData.profile.membership_id == 4 ? (
                                                <>
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile:</b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id}&name=${obj.Vendor}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </ul>
                                    </>
                                ) : this.props.profileData.profile.membership_id == 5 ? (
                                    <>
                                        {' '}
                                        <ul>
                                            <>
                                                <li class="bullet-remove">
                                                    {/* PR Firm:{''} */}
                                                    Company name: {''}
                                                    <b>{obj.Vendor}</b>
                                                </li>
                                                {obj['Services offered'] &&
                                                    !!obj['Services offered'].length && (
                                                        <li class="bullet-remove">
                                                            Services offered: <b>{obj['Services offered']}</b>
                                                        </li>
                                                    )}
                                                {obj.emailDisplay && obj.emailDisplay === 'Yes' ? (
                                                    <li class="bullet-remove">
                                                        Contact:{''} <b>{obj.Contact}</b>
                                                    </li>
                                                ) : (
                                                    ''
                                                )}
                                                <li class="bullet-remove">
                                                    Website:{' '}
                                                    <a
                                                        href={obj['Website']}
                                                        target="_blank"
                                                        //className="ellipsis"
                                                        rel="noreferrer"
                                                    >
                                                        {obj.Website}
                                                    </a>
                                                </li>
                                                {obj['Social media'] &&
                                                    !!obj['Social media'][0].length && (
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <li>
                                                                <b>Social media:</b>
                                                            </li>
                                                            {obj['Social media'] &&
                                                                obj['Social media'][0].map(subObj => {
                                                                    return (
                                                                        <li className="more-profile">
                                                                            <a
                                                                                href={Object.values(
                                                                                    subObj
                                                                                )[0].toString()}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.keys(subObj)[0]}
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                        </li>
                                                                    );
                                                                })}
                                                        </li>
                                                    )}
                                                {obj['PR Fan groups'] &&
                                                    !!obj['PR Fan groups'][0].length && (
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <li>
                                                                {/* <b>PR Fan groups: </b> */}
                                                                <b>Fan groups: </b>
                                                            </li>
                                                            {obj['PR Fan groups'] &&
                                                                obj['PR Fan groups'][0].map(subObj => {
                                                                    return (
                                                                        <>
                                                                            {' '}
                                                                            <ul>
                                                                                <li>
                                                                                    {Object.keys(subObj)[0]}:{' '}
                                                                                    <a
                                                                                        href={Object.values(
                                                                                            subObj
                                                                                        )[0].toString()}
                                                                                        target="_blank"
                                                                                        //className="ellipsis"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        {Object.values(subObj)[0]}
                                                                                    </a>{' '}
                                                                                </li>
                                                                            </ul>
                                                                            {/* <li className="more-profile">
                                                                                <a
                                                                                    href={Object.values(
                                                                                    subObj
                                                                                    )[0].toString()}
                                                                                    target="_blank"
                                                                                    //className="ellipsis"
                                                                                    rel="noreferrer"
                                                                                >
                                                                                    {Object.keys(subObj)[0]}
                                                                                </a>
                                                                                &nbsp; | &nbsp;
                                                                                </li> */}
                                                                        </>
                                                                    );
                                                                })}
                                                        </li>
                                                    )}{' '}
                                                {obj['Best site for AUTHORS to interact with us'] && (
                                                    <li class="bullet-remove">
                                                        {obj[
                                                            'Best site for AUTHORS to interact with us'
                                                        ].split(':')[0] === 'http' ||
                                                            obj[
                                                                'Best site for AUTHORS to interact with us'
                                                            ].split(':')[0] === 'https' ? (
                                                            <>
                                                                {' '}
                                                                Best site for AUTHORS to interact with us:{' '}
                                                                <a
                                                                    href={
                                                                        obj[
                                                                        'Best site for AUTHORS to interact with us'
                                                                        ]
                                                                    }
                                                                    //className="ellipsis"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {obj[
                                                                        'Best site for AUTHORS to interact with us'
                                                                    ].slice(0, 15)}
                                                                    {'...'}
                                                                </a>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {' '}
                                                                Best site for AUTHORS to interact with us:{' '}
                                                                <b>
                                                                    {' '}
                                                                    {
                                                                        obj[
                                                                        'Best site for AUTHORS to interact with us'
                                                                        ]
                                                                    }
                                                                </b>
                                                            </>
                                                        )}
                                                    </li>
                                                )}
                                                {obj[
                                                    'Best site for bloggers/readers to interact with us'
                                                ] && (
                                                        <li class="bullet-remove">
                                                            {obj[
                                                                'Best site for bloggers/readers to interact with us'
                                                            ].split(':')[0] === 'http' ||
                                                                obj[
                                                                    'Best site for bloggers/readers to interact with us'
                                                                ].split(':')[0] === 'https' ? (
                                                                <>
                                                                    {' '}
                                                                    Best site for bloggers/readers to interact:{' '}
                                                                    <a
                                                                        href={
                                                                            obj[
                                                                            'Best site for bloggers/readers to interact with us'
                                                                            ]
                                                                        }
                                                                        //className="ellipsis"
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        {obj[
                                                                            'Best site for bloggers/readers to interact with us'
                                                                        ].slice(0, 15)}
                                                                        {'...'}
                                                                    </a>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    Best site for bloggers/readers to interact:{' '}
                                                                    <b>
                                                                        {
                                                                            obj[
                                                                            'Best site for bloggers/readers to interact with us'
                                                                            ]
                                                                        }
                                                                    </b>
                                                                </>
                                                            )}
                                                        </li>
                                                    )}
                                                {obj['Are you accepting new clients'] && (
                                                    <li class="bullet-remove">
                                                        Are you accepting new clients:{' '}
                                                        <b>{obj['Are you accepting new clients']}</b>
                                                    </li>
                                                )}
                                                {obj[
                                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                ] &&
                                                    obj[
                                                        'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                    ].length == '1' ? (
                                                    <>
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <b>Vendor Cancellation Openings (VCO):</b>
                                                        </li>
                                                    </>
                                                ) : (
                                                    <>
                                                        <li class="bullet-remove">
                                                            <b>Vendor Cancellation Openings (VCO):</b>
                                                        </li>
                                                        <ul>
                                                            <li>No</li>
                                                        </ul>
                                                    </>
                                                )}
                                                {obj[
                                                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                ] ? (
                                                    <div className="mb-1">
                                                        {obj[
                                                            'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                        ][0].map(subObj => {
                                                            const dateObj2 = new Date(Object.values(subObj)[0]);
                                                            dateObj2.setHours(23, 59, 59, 999);
                                                            const fullFormat2 = dateObj2.toISOString();
                                                            return (
                                                                <>
                                                                    {new Date().getTime() >= new Date(fullFormat2).getTime() ? (
                                                                        <>
                                                                            {/* <ul>
                                                                                <li>No</li>
                                                                            </ul> */}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <ul>
                                                                                {' '}
                                                                                <li>
                                                                                    <b>{Object.keys(subObj)[0]}</b>:{' '}
                                                                                    {moment(
                                                                                        Object.values(subObj)[0]
                                                                                    ).format('MMMM DD, YYYY')}
                                                                                </li>
                                                                            </ul>
                                                                        </>
                                                                    )}
                                                                </>
                                                            );
                                                        })}

                                                        {VCOLength.length ==
                                                            obj[
                                                                'Currently featured on Vendor Cancellation Openings (VCO) list'
                                                            ][0].length && (
                                                                <ul>
                                                                    <li>No</li>
                                                                </ul>
                                                            )}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                                {obj['Recent clients/works'] && (
                                                    <li class="bullet-remove">
                                                        Recent clients/works:{' '}
                                                        {obj['Recent clients/works'].map(el => (
                                                            <>
                                                                {el.split(':')[0] === 'http' ||
                                                                    el.split(':')[0] === 'https' ? (
                                                                    <ul>
                                                                        <li>
                                                                            <a
                                                                                href={el}
                                                                                //className="ellipsis"
                                                                                target="_blank"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {el.length > 50
                                                                                    ? el.slice(0, 50) + '...'
                                                                                    : el}
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                ) : (
                                                                    <ul>
                                                                        <li>{el}</li>
                                                                    </ul>
                                                                )}
                                                            </>
                                                        ))}
                                                    </li>
                                                )}
                                                {obj['Brief introduction'] && (
                                                    <li class="bullet-remove">
                                                        Brief introduction:{' '}
                                                        <b>{obj['Brief introduction']}</b>
                                                    </li>
                                                )}
                                                {obj.Bio && (
                                                    <>
                                                        <li class="bullet-remove">Bio:</li>{' '}
                                                        <li class="bullet-remove">
                                                            <i>{obj.Bio}</i>
                                                        </li>
                                                    </>
                                                )}
                                            </>
                                            {this.props.profileData.profile.follow_code && (
                                                <>
                                                    <li className="bullet-remove">
                                                        INVITE code:{' '}
                                                        <b>{this.props.profileData.profile.follow_code}</b>
                                                    </li>
                                                    {/* <li className="bullet-remove">
                                                        Link to profile:{' '}
                                                        <Link
                                                        to={`/users/${this.props.profileData.profile.follow_code}`}
                                                        target="_blank"
                                                        >
                                                        {window.location.host}
                                                        /#/users/
                                                        {this.props.profileData.profile.follow_code}
                                                        </Link>
                                                    </li> */}
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile: </b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id}&name=${obj.Vendor}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </>
                                ) : this.props.profileData.profile.membership_id == 6 ? (
                                    <>
                                        {' '}
                                        <ul>
                                            <>
                                                {/* {console.log("========== ",obj)} */}
                                                <li class="bullet-remove">
                                                    Blog:{''}{' '}
                                                    <b>
                                                        {obj.Blog
                                                            ? obj.Blog
                                                            : this.props.profileData.profile.user_details[0]
                                                                .value}
                                                    </b>
                                                </li>
                                                {/* {obj['Publicly display email on profile page?'] &&
                        obj['Publicly display email on profile page?'] === 'Yes'
                          ? obj.Contact && (
                              <li class="bullet-remove">
                                Contact:{''} <b>{obj.Contact}</b>
                              </li>
                            )
                          : ''} */}

                                                {obj['Blog email'] && (
                                                    <li class="bullet-remove">
                                                        Blog email: <b>{obj['Blog email']}</b>
                                                    </li>
                                                )}
                                                {obj['Blog link'] && (
                                                    <li class="bullet-remove">
                                                        Blog link:{' '}
                                                        <a
                                                            href={obj['Blog link']}
                                                            target="_blank"
                                                            //className="ellipsis"
                                                            rel="noreferrer"
                                                        >
                                                            {obj['Blog link']}
                                                        </a>
                                                    </li>
                                                )}
                                                {obj['Goodreads link'] && (
                                                    <li class="bullet-remove">
                                                        Goodreads link:{' '}
                                                        <a
                                                            href={obj['Goodreads link']}
                                                            target="_blank"
                                                            //className="ellipsis"
                                                            rel="noreferrer"
                                                        >
                                                            {obj['Goodreads link']}
                                                        </a>
                                                    </li>
                                                )}

                                                {obj['Social media'] &&
                                                    !!obj['Social media'][0].length && (
                                                        <li class="bullet-remove">
                                                            {' '}
                                                            <li>
                                                                <b>Social Media:</b>
                                                            </li>
                                                            {obj['Social media'] &&
                                                                obj['Social media'][0].map(subObj => {
                                                                    return (
                                                                        <li className="more-profile">
                                                                            <a
                                                                                href={Object.values(
                                                                                    subObj
                                                                                )[0].toString()}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.keys(subObj)[0]}
                                                                            </a>
                                                                            &nbsp;|&nbsp;
                                                                        </li>
                                                                    );
                                                                })}
                                                        </li>
                                                    )}

                                                {obj['Fan groups'] && !!obj['Fan groups'][0].length && (
                                                    <li class="bullet-remove">
                                                        {' '}
                                                        <li>
                                                            <b>Fan groups:</b>
                                                        </li>
                                                        {obj['Fan groups'] &&
                                                            obj['Fan groups'][0].map(subObj => {
                                                                return (
                                                                    <ul>
                                                                        <li>
                                                                            {Object.keys(subObj)[0]}:{' '}
                                                                            <a
                                                                                href={Object.values(
                                                                                    subObj
                                                                                )[0].toString()}
                                                                                target="_blank"
                                                                                //className="ellipsis"
                                                                                rel="noreferrer"
                                                                            >
                                                                                {Object.values(subObj)[0]}
                                                                            </a>{' '}
                                                                        </li>
                                                                    </ul>
                                                                );
                                                            })}
                                                    </li>
                                                )}
                                                {obj['Best site to interact with us'] && (
                                                    <li class="bullet-remove">
                                                        {/* Best site to interact:{' '}
                            <a
                              href={obj['Best site to interact with us']}
                              target="_blank"
                              //className="ellipsis"
                              rel="noreferrer"
                            >
                              {obj['Best site to interact with us']}
                            </a> */}
                                                        {obj['Best site to interact with us'].split(
                                                            ':'
                                                        )[0] === 'http' ||
                                                            obj['Best site to interact with us'].split(
                                                                ':'
                                                            )[0] === 'https' ? (
                                                            <>
                                                                {' '}
                                                                Best site to interact:{' '}
                                                                <a
                                                                    href={obj['Best site to interact with us']}
                                                                    //className="ellipsis"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    {obj['Best site to interact with us']}
                                                                </a>
                                                            </>
                                                        ) : (
                                                            <>
                                                                Best site to interact:{' '}
                                                                <b>{obj['Best site to interact with us']}</b>
                                                            </>
                                                        )}
                                                    </li>
                                                )}
                                            </>
                                            {this.props.profileData.profile.follow_code && (
                                                <>
                                                    <li className="bullet-remove">
                                                        INVITE code:{' '}
                                                        <b>{this.props.profileData.profile.follow_code}</b>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        Link to profile:{' '}
                                                        <Link
                                                            to={`/users/${this.props.profileData.profile.follow_code}`}
                                                            target="_blank"
                                                        >
                                                            {window.location.host}
                                                            /#/users/
                                                            {this.props.profileData.profile.follow_code}
                                                        </Link>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile: </b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id}&name=${obj.Blog}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </>
                                ) : this.props.profileData.profile.membership_id == 3 ? (
                                    <>
                                        {' '}
                                        <ul>
                                            <>
                                                <li class="bullet-remove">
                                                    Blog:{''} <b>{obj.Blog}</b>
                                                </li>
                                                {obj['Blog email'] && (
                                                    <li class="bullet-remove">
                                                        Blog email: <b>{obj['Blog email']}</b>
                                                    </li>
                                                )}
                                                {obj['Blog link'] && (
                                                    <li class="bullet-remove">
                                                        Blog link:{' '}
                                                        <a
                                                            style={{ fontSize: 16 }}
                                                            href={obj['Blog link']}
                                                            target="_blank"
                                                            //className="ellipsis"
                                                            rel="noreferrer"
                                                        >
                                                            {obj['Blog link']}
                                                        </a>
                                                    </li>
                                                )}
                                            </>
                                            {this.props.profileData.profile.follow_code && (
                                                <>
                                                    <li className="bullet-remove">
                                                        INVITE code:{' '}
                                                        <b>{this.props.profileData.profile.follow_code}</b>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        Link to profile:{' '}
                                                        <Link
                                                            to={`/users/${this.props.profileData.profile.follow_code}`}
                                                            target="_blank"
                                                        >
                                                            {window.location.host}
                                                            /#/users/
                                                            {this.props.profileData.profile.follow_code}
                                                        </Link>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile: </b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id}&name=${obj.Blog}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        <ul>
                                            {profileData &&
                                                profileData.map((data, i) => {
                                                    let key = Object.keys(data)[0];
                                                    let value = Object.values(data);

                                                    if (
                                                        key &&
                                                        (key.toLowerCase() === 'undefined' ||
                                                            key.toLowerCase() === 'end' ||
                                                            key.toLowerCase() === 'event_title' ||
                                                            key.toLowerCase() === 'image' ||
                                                            key.toLowerCase() === 'book-cover' ||
                                                            key.toLocaleLowerCase() == 'profile' ||
                                                            key ==
                                                            'Publicly display email on profile page?' ||
                                                            key == 'emailDisplay')
                                                    ) {
                                                        return;
                                                    }
                                                    if (key && value == 'undefined') {
                                                        return;
                                                    }
                                                    if (Object.keys(data).length !== 0) {
                                                        if (
                                                            value[0] &&
                                                            Array.isArray(value[0]) &&
                                                            value[0].length
                                                        ) {
                                                            return (
                                                                <span>
                                                                    <li className="bullet-remove">
                                                                        <span>
                                                                            <b>{key}:</b>
                                                                        </span>
                                                                    </li>

                                                                    <ul>
                                                                        {value[0].length &&
                                                                            value[0].map(sub => {
                                                                                if (sub) {
                                                                                    return (
                                                                                        sub.length > 0 &&
                                                                                        sub.map(subObj => {
                                                                                            if (
                                                                                                key == 'More profiles' ||
                                                                                                key == 'Social media' ||
                                                                                                key == 'Social Media' ||
                                                                                                key == 'Other social media'
                                                                                            ) {
                                                                                                return (
                                                                                                    <li className="more-profile">
                                                                                                        <a
                                                                                                            href={Object.values(
                                                                                                                subObj
                                                                                                            )[0].toString()}
                                                                                                            //className="ellipsis"
                                                                                                            target="_blank"
                                                                                                            rel="noreferrer"
                                                                                                        >
                                                                                                            {Object.keys(
                                                                                                                subObj
                                                                                                            )[0].toUpperCase()}
                                                                                                        </a>
                                                                                                        &nbsp;|&nbsp;
                                                                                                    </li>
                                                                                                );
                                                                                            }
                                                                                            if (
                                                                                                key ==
                                                                                                'Currently featured on "Last minute vendor cancellation list"' ||
                                                                                                key ==
                                                                                                'Currently featured on Last minute vendor cancellation list' ||
                                                                                                key ==
                                                                                                'Currently featured on last minite Vendor cancellations list' ||
                                                                                                key ==
                                                                                                'Currently featured on Last minute vendor cancellation list' ||
                                                                                                key ==
                                                                                                'Currently featured on last minute vendor cancellations list' ||
                                                                                                key ==
                                                                                                'Currently featured on Last minute vendor cancellations list' ||
                                                                                                key ==
                                                                                                'Currently featured on Vendor Cancellation Openings (VCO) list' ||
                                                                                                key ==
                                                                                                'Currently featured on "Vendor Cancellation Openings (VCO) list"'
                                                                                            ) {
                                                                                                return (
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            {' '}
                                                                                                            <b>
                                                                                                                {Object.keys(subObj)[0]}
                                                                                                            </b>
                                                                                                        </span>
                                                                                                        :{' '}
                                                                                                        <span>
                                                                                                            {/* {moment(
                                                      Object.keys(subObj)[0]
                                                    ).format('YYYY-MM-DD')} */}
                                                                                                            {moment(
                                                                                                                Object.values(subObj)[0]
                                                                                                            ).format('MMMM DD, YYYY')}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                );
                                                                                            }
                                                                                            if (
                                                                                                key == 'Reader groups' ||
                                                                                                key == 'Fan groups' ||
                                                                                                key == 'Purchase sites' ||
                                                                                                key == 'Reader Groups' ||
                                                                                                key == 'PR fan groups: ' ||
                                                                                                key == 'Where to purchase'
                                                                                            ) {
                                                                                                return (
                                                                                                    <li>
                                                                                                        <span>
                                                                                                            {Object.keys(subObj)[0]}
                                                                                                        </span>
                                                                                                        :
                                                                                                        <span>
                                                                                                            {' '}
                                                                                                            <a
                                                                                                                href={Object.values(
                                                                                                                    subObj
                                                                                                                )[0].toString()}
                                                                                                                className="ellipsis"
                                                                                                                target="_blank"
                                                                                                                rel="noreferrer"
                                                                                                            >
                                                                                                                {
                                                                                                                    Object.values(
                                                                                                                        subObj
                                                                                                                    )[0]
                                                                                                                }
                                                                                                            </a>
                                                                                                        </span>
                                                                                                    </li>
                                                                                                );
                                                                                            }
                                                                                            return (
                                                                                                <li>
                                                                                                    <span>
                                                                                                        {Object.keys(subObj)[0]}
                                                                                                    </span>
                                                                                                    :
                                                                                                    <span>
                                                                                                        {' '}
                                                                                                        <b>
                                                                                                            {Object.values(subObj)[0]}
                                                                                                        </b>
                                                                                                    </span>
                                                                                                </li>
                                                                                            );
                                                                                        })
                                                                                    );
                                                                                }
                                                                            })}
                                                                    </ul>
                                                                </span>
                                                            );
                                                        }

                                                        if (!Array.isArray(value[0]) && value[0] !== '') {
                                                            if (
                                                                key == 'Website' ||
                                                                key == 'Goodreads' ||
                                                                key == 'Goodreads link' ||
                                                                key == 'PR Fan groups' ||
                                                                key == 'Best site to rech me' ||
                                                                key == 'Blog link' ||
                                                                key == 'Best site to interact with us' ||
                                                                key == 'Website link' ||
                                                                // key == 'Alias' ||
                                                                key ==
                                                                'Best site for AUTHORS to interact with us' ||
                                                                key == 'Master ARC list sign up' ||
                                                                key == 'Best site to reach me' ||
                                                                key == 'Newsletter sign up' ||
                                                                key ==
                                                                'Best site for bloggers/readers to interact with us'
                                                            ) {
                                                                return (
                                                                    <li className="bullet-remove">
                                                                        {' '}
                                                                        {key == 'Website' || key == 'Goodreads' ? (
                                                                            <li className="bullet-remove">
                                                                                <span>{key}</span>:{' '}
                                                                                <span>
                                                                                    {' '}
                                                                                    <a
                                                                                        href={value[0].toString()}
                                                                                        className="ellipsis spc"
                                                                                        target="_blank"
                                                                                        rel="noreferrer"
                                                                                    >
                                                                                        {' '}
                                                                                        {value[0].toString()}
                                                                                    </a>
                                                                                </span>
                                                                            </li>
                                                                        ) : (
                                                                            <li className="bullet-remove">
                                                                                {/* <b>
                                                                                    {key == 'Newsletter sign up' && (
                                                                                        <>
                                                                                        Other:
                                                                                        <br />
                                                                                        </>
                                                                                    )}
                                                                                </b> */}
                                                                                <span>{key}</span>:{' '}
                                                                                {(key == 'Best site to reach me' ||
                                                                                    key ==
                                                                                    'Best site for AUTHORS to interact with us' ||
                                                                                    key ==
                                                                                    'Best site for bloggers/readers to interact with us') &&
                                                                                    value[0] === value[0].split(':')[0] ? (
                                                                                    <span
                                                                                        style={{ textDecoration: 'none' }}
                                                                                    >
                                                                                        {' '}
                                                                                        {value[0].toString()}
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>
                                                                                        <a
                                                                                            href={value[0].toString()}
                                                                                            className="ellipsis spc"
                                                                                            target="_blank"
                                                                                            rel="noreferrer"
                                                                                        >
                                                                                            {' '}
                                                                                            {value[0].toString()}
                                                                                        </a>
                                                                                    </span>
                                                                                )}
                                                                            </li>
                                                                        )}
                                                                    </li>
                                                                );
                                                            }

                                                            if (key == 'Bio') {
                                                                return (
                                                                    <li className="bullet-remove">
                                                                        <span>{key}</span>:
                                                                        <p
                                                                            className="line-space"
                                                                            style={{
                                                                                'word-break': 'break-all',
                                                                                whiteSpace: 'pre-line',
                                                                                display: 'block',
                                                                                marginBottom: '0'
                                                                            }}
                                                                        >
                                                                            {' '}
                                                                            <i style={{ fontSize: '16px' }}>
                                                                                {' '}
                                                                                {value[0]}
                                                                            </i>
                                                                        </p>
                                                                    </li>
                                                                );
                                                            }

                                                            if (key == 'Author') {
                                                                return (
                                                                    <li className="bullet-remove">
                                                                        <span>{key}:</span>

                                                                        <span>
                                                                            {' '}
                                                                            <b
                                                                                style={{ textTransform: 'capitalize' }}
                                                                            >
                                                                                {value[0]}
                                                                            </b>
                                                                        </span>
                                                                    </li>
                                                                );
                                                            }
                                                            return (
                                                                <>
                                                                    {this.props.profileData.profile
                                                                        .is_email_display === 1 &&
                                                                        key === 'Contact' ? (
                                                                        <li className="bullet-remove">
                                                                            <span>{key}:</span>

                                                                            <span>
                                                                                {' '}
                                                                                <b>{value[0]}</b>
                                                                            </span>
                                                                        </li>
                                                                    ) : key !== 'Contact' ? (
                                                                        <li className="bullet-remove">
                                                                            <span>
                                                                                {' '}
                                                                                {this.props.profileData.profile
                                                                                    .membership_id == 5 &&
                                                                                    key === 'Vendor'
                                                                                    ? 'PR Firm'
                                                                                    : key}
                                                                                {/* {key === 'Vendor'
                                          ? 'PR Firmm'
                                          : key}{' '} */}
                                                                                :
                                                                            </span>

                                                                            <span>
                                                                                {' '}
                                                                                <b>{value[0]}</b>
                                                                            </span>
                                                                        </li>
                                                                    ) : (
                                                                        ''
                                                                    )}
                                                                </>
                                                            );
                                                        }
                                                    }
                                                })}

                                            {this.props.profileData.profile.follow_code && (
                                                <>
                                                    <li className="bullet-remove">
                                                        INVITE code:{' '}
                                                        <b>{this.props.profileData.profile.follow_code}</b>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        Link to profile:{' '}
                                                        <Link
                                                            to={`/users/${this.props.profileData.profile.follow_code}`}
                                                            target="_blank"
                                                        >
                                                            {window.location.host}
                                                            /#/users/
                                                            {this.props.profileData.profile.follow_code}
                                                        </Link>
                                                    </li>
                                                    <li className="bullet-remove">
                                                        <b> All OTR events added by this profile: </b>
                                                        <ul>
                                                            <li>
                                                                Linked Events page:{' '}
                                                                <Link
                                                                    to={{
                                                                        pathname: `/dashboard/linked?userId=${this.props.profileData.profile.id}&name=${obj.Blog}`
                                                                    }}
                                                                    target="_blank"
                                                                >
                                                                    {/* {window.location.href} */}
                                                                    {window.location.host}
                                                                    ort/linkedeventpage/
                                                                    {this.props.profileData.profile.fname}
                                                                    {'....'}
                                                                    {/* {this.props.profileData.profile.fname} */}
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="edit-profile-btn mt-2">
                            <button
                                className={this.props.displayMode ? 'btn bg-btn' : 'btn bg-btn'}
                                onClick={() => {
                                    globalHistory.push('/dashboard/my-profile');
                                    localStorage.setItem(
                                        'userId',
                                        this.props.profileData.profile.id
                                    );
                                    localStorage.setItem('mode', 'edit');
                                    this.props.getUserProfile(
                                        '/api/v1/user/profile',
                                        {
                                            headers: {
                                                'x-authorization': localStorage.getItem('token')
                                            }
                                        },
                                        true
                                    );
                                }}
                            >
                                Edit Profile
                            </button>
                        </div>
                    </div>
                </>
            );
        }
        return <></>;
    }

    componentDidMount() {
        const query = window.location.hash;
        const params = new URLSearchParams(query.split('?')[1]);
        const code = params.get('code');
        const userId = params.get('userId');
        globalHistory.push(`/dashboard/my-profile`);
        localStorage.removeItem('mode');
        // this.props.getUserProfile('/api/v1/user/profile',
        //     { headers: { 'x-authorization': localStorage.getItem('token') } },
        //     false,
        //     'dashboard'
        // );
        this.setState({
            userId: userId,
            code: code
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let code = this.state.code == 'null' ? null : this.state.code;

        if (localStorage.getItem('mode') !== 'edit') {
            if (this.props.profileData) {
                if (code && this.props.profileData.profile.id != this.state.userId) {
                    globalHistory.push(`/users/${code}`);
                    return;
                } else if (
                    this.state.userId &&
                    this.props.profileData.profile.id != this.state.userId
                ) {
                    globalHistory.push(`/users/fcfc?userId=${this.state.userId}`);
                    return;
                }

                if (!code) {
                    globalHistory.push(
                        `/dashboard/my-profile?code=${this.props.profileData.profile.follow_code}&userId=${this.props.profileData.profile.id}`
                    );
                    return;
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    loading: state.profileLoading,
    profileData: state.myProfileData,
    displayMode: state.changeMode,
    newLoader: state.newLoader
});

const mapDispatchToProps = dispatch => ({
    getUserProfile: (url, header, edit, page) =>
        dispatch(getProfileDetails(url, header, 'myProfile')),
    falseLoader: () => {
        dispatch(newLoader(false));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
