import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

const ResetCoversPopup = ({ isOpen, toggle, handleReset }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="md"
      className="reset-cover-popup advertise-privacy-popup delete-confirmation-popup del-popup-new"
    >
      <ModalBody>
        <p class="text-center p-0">
          Are you certain you want to permanently delete all uploaded book
          covers for this & synced events? You can replace a cover by simply
          choosing a different file to upload.
        </p>
        <p class="text-center p-0">
          Once reset, you will be able to upload a new Temporary, Official
          and/or Alternate book cover. If no cover is uploaded, the OTR default
          image will display.
        </p>
        <div class="delete-btn">
          <button
            className="btn btn-filled"
            onClick={toggle} 
          >
            Cancel
          </button>{' '}
          <button className="btn btn-filled" onClick={() => {
              handleReset();
              toggle();
            }}>
            Reset
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ResetCoversPopup;
