import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  setupEvent,
  getFilterEvents,
  getEventDetails,
  previewData
} from '../../../Actions/event-setup';
import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';

class MyCurrentEvents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 130,
          filter: true,
          cellRenderer: function(event_type) {
            var backgroundColor;
            let event_list_value = event_type.value;
            if (event_list_value.length > 13) {
              event_list_value = event_type.value.slice(0, 13) + '...';
            }
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF79BC, #FF65FF';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#F3FfB5, #fFDB69';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#B469FF, #8E5BfF';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Signup'
            ) {
              backgroundColor = '#FF5B5B, #FF5B9D';
            }
            if (event_type.value === 'Ticket Sales') {
              backgroundColor = '#DCB9FF, #B9B9FF';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              event_list_value
            );
          }
        },
        {
          headerName: 'TITLE/NAME',
          field: 'commanTitle',
          sortable: true,
          filter: true,
          unSortIcon: true,
          width: 80,
          cellRenderer: this.titleCellRenderer.bind(this),
          className: 'titlevmmmmvvvvvvvvvvvvv'
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,

          unSortIcon: true,
          width: 110,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 100,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 95,
          cellRenderer: function(endDate) {
            if (endDate.data.event_type === 'Ticket Sales') {
              return '-';
            } else if (endDate.data.end_date) {
              return moment(endDate.data.end_date).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TIME (US EASTERN)',
          field: 'time',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 120,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 115,
          cellRenderer: this.hotsted.bind(this)
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 120
        },
        {
          headerName: 'ACTION',
          field: 'edit',
          width: 100,
          cellRenderer: this.editEvents.bind(this)
        }
        // {
        //     headerName: "FAVORITE", field: "", sortable: true, filter: true, resizable: true,
        //     cellRenderer: function () {
        //         return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
        //     }
        // },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    let midName = params.data.author_mid_name;

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }
  titleCellRenderer(params) {
    if (params.data.event_type === 'Giveaway') {
      return `${params.data.giveaway ? params.data.giveaway : ''} `;
    } else if (params.data.event_type === 'Release Party') {
      return `${params.data.releaseparty ? params.data.releaseparty : ''} `;
    } else {
      return `${params.data.title ? params.data.title : ''} `;
    }
  }

  editEvents(params) {
    // let details = params.data.event_details_with_values;
    return `<button type="button" data-action-type="edit" class="btn btn-sm  new_btn_save_bt_users_light  py-0">EDIT</button>`;
  }
  showTime(params) {
    // console.log("data--::::::::======> ", params.data);
    return params.data.time
      ? this.getEstTimeNow(new Date(), params.data.time)
      : '-';
  }

  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }

  getEstTimeNow(date, time) {
    const dates = date || new Date();
    const convertedTime = moment(time, 'hh:mm A').format('HH:mm');

    let start2 = moment(dates).toDate();
    const time2 = convertedTime.split(':');

    start2.setHours(time2[0]);
    start2.setMinutes(time2[1]);
    start2.setSeconds(0);
    const dt2 = new Date(start2);

    const timeZone = 'America/New_York';
    let myTime = dt2.toLocaleString('en-US', {
      timeZone
    });
    let timeTaken = moment(myTime).format('hh:mm A');

    return timeTaken;
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  showEvent(e) {
    if (e.column.colId === 'edit') {
      let data = e.data;
      if (data.event_type === 'Ticket Sales') {
        let newData = this.props.eventsData.find(el => el.id === data.id + 1);
        return this.onActionEditClick(newData);
      }
      // let actionType = e.event.target.getAttribute('data-action-type');
      return this.onActionEditClick(data);
    } else {
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }
  hotsted(params) {
    const changeString = params.data.hosted_by
      ? params.data.hosted_by.length > 13
        ? params.data.hosted_by.slice(0, 13) + '..'
        : params.data.hosted_by
      : '';

    return changeString;
  }

  onActionEditClick(data) {
    localStorage.setItem('eventId', data.id);
    localStorage.setItem('event_type', data.event_id.split('_').pop());

    this.props.editEvent(
      '/api/v1/event/' + data.id,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      },
      this.props.newTab
    );
  }
  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  handleRefresh = () => {
    window.location.reload();
  };

  //===================== RENDER ==========================
  render() {
    let data = [];
    let filterData = '';
    if (this.props.eventsData.length) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      data = this.props.eventsData;
      data.forEach(element => {
        let e = {};
        e = element;
        e.start = moment(element['start']).format('MM/DD/YYYY');
        e.end = moment(element['end']).format('MM/DD/YYYY');
        e.created_date = moment(element['created_date']).format('MM/DD/YYYY');
        e.start_date = moment(element['start_date']).format('MM/DD/YYYY');
      });
      const endDate = new Date();
      const createDate = endDate.setDate(endDate.getDate() - 7);

      const createdDate = moment(createDate).format('MM/DD/YYYY');
      filterData = data.filter(
        el => new Date(el.start_date) >= new Date(createdDate)
      );

      filterData = filterData.sort((a, b) => {
        return new Date(a.start_date) > new Date(b.start_date)
          ? 1
          : new Date(a.start_date) < new Date(b.start_date)
          ? -1
          : 0;
      });

      let newData = [];
      filterData.forEach(el => {
        let obj = { ...el };
        // console.log("el.event_type--> ", el.event_type);
        if (el.event_type === 'Giveaway') {
          obj.commanTitle = el.giveaway ? el.giveaway : '';
        } else if (el.event_type === 'Release Party') {
          obj.commanTitle = el.releaseparty ? el.releaseparty : '';
        } else {
          obj.commanTitle = el.title ? el.title : '';
        }
        obj.commanTitle = obj.commanTitle.toLowerCase();
        newData.push(obj);
      });
      filterData = [...newData];
    }

    return (
      <div className="container my-events-page">
        <div className="mb-3">
          <div className="color_guide mb-3">
            <div className="row">
              <div className="legend">
                <span className="color_box">RELEASE PARTY</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">ARC SIGNUP</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">BOOK PROMO</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">COVER REVEAL</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">GIVEAWAY</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box">BOOK RELEASE</span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>

              <div className="legend">
                <span className="color_box text-uppercase">
                  Boxes & Signings
                </span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
              <div className="legend">
                <span className="color_box text-uppercase">
                  Ticket/box sales
                </span>
                {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
              </div>
            </div>
          </div>
          {!this.props.isReader && !this.props.isBlogger ? (
            <>
              <div className="col-md-12 mb-0 setup-btn">
                <button
                  onClick={e => this.props.openEventModal()}
                  className={
                    this.props.isTheameLight
                      ? 'btn btn_save_bt setup_event_bt daily'
                      : 'btn btn_save_bt white-button setup_event_bt daily'
                  }
                >
                  <span>Set up Event</span>
                  <img
                    src={require('../../../Utils/Assets/plus.png')}
                    alt="cup-icon"
                  />
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
          <h2 className="text-center heading">My Events</h2>
          <h4 className="text-center heading">Events added to OTR by me</h4>

          {/* <hr /> */}
        </div>
        <div
          className={
            this.props.myevents ? 'ag-theme-balham' : 'ag-theme-dark home-ag'
          }
          style={{}}
        >
          <AgGridReact
            className="ag-cell-value"
            onGridReady={this.onGridReady}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            rowData={filterData}
            pagination={true}
            reactNext={true}
            animateRows
            onCellClicked={this.showEvent.bind(this)}
            paginationPageSize={this.state.paginationPageSize}
            paginationNumberFormatter={this.state.paginationNumberFormatter}
          />
          <div className="search-features" style={{ marginTop: '10px' }}>
            <p>
              <i>
                Each column has search feature. Column width can be changed by
                dragging. Hit refresh to reset.
              </i>
              <i
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer'
                }}
                className="fa fa-refresh"
                onClick={this.handleRefresh}
              />
            </p>
          </div>
          <div
            className="test-header"
            style={{ float: 'right', marginTop: -11 }}
          >
            Page Size:
            <select
              onChange={this.onPageSizeChanged.bind(this)}
              id="page-size"
              defaultValue="10"
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        {this.state.modal ? (
          <EventModal
            isOpen={this.state.modal}
            eventData={this.state.eventData}
            toggle={this.hideEvent}
          />
        ) : null}
      </div>
    );
  }

  componentWillReceiveProps() {
    if (this.props.eventsData == '') {
      // this.props.getAllEvents(this.props.getAllEvents(`/api/v1/events`, { headers: { 'x-authorization': localStorage.getItem("token") } }));
    } else {
      if (this.gridApi) this.gridApi.setQuickFilter(this.props.search);
    }
  }

  componentDidMount() {
    this.props.getAllEvents(
      this.props.getAllEvents(`/api/v1/events?event_type=my`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    );
  }
}

const mapStateToProps = state => {
  return {
    eventsData: state.filterevent,
    newTab: state.addTab
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setupEvent: () => dispatch(setupEvent()),
    getAllEvents: (url, header) => dispatch(getFilterEvents(url, header)),
    editEvent: (url, headers, newTab) =>
      dispatch(getEventDetails(url, headers, newTab)),
    clearPreviewData: () => dispatch(previewData({}))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCurrentEvents);
