import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import ViewTask from './viewTaskModal';
import DatePicker, { registerLocale } from 'react-datepicker';
// import { Tooltip as ReactTooltip } from 'react-tooltip'

import {
  getAllTasks,
  editTaskDetails,
  saveDetail
} from '../../Actions/dailytask';

import moment from 'moment-timezone';
import Axios from 'axios';
import { BASEURL } from '../../constant';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import FullEditor from 'ckeditor5-build-full';
import { notify } from 'react-notify-toast';

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

moment.tz.setDefault(timezone);

class DailyListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      viewModal: false,
      eventData: {},
      show: false,
      calender_date: '',
      startDate: null,
      task_details: '',
      title: '',
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'DATE',
          field: 'added_on',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: function(startDate) {
            return moment(startDate.data.added_on).format('MM/DD/YYYY');
          }
  
        },

        {
          headerName: 'TITLE',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true
        },
        // {
        //   headerName: 'DESCRIPTION',
        //   field: 'task_message',
        //   sortable: true,
        //   filter: true,
        //   resizable: true,
        //   suppressMovable: true,
        //   unSortIcon: true,
        //   cellRenderer: this.nameCellRenderer.bind(this)
        // },
        {
          headerName: 'ACTION',
          field: 'edit',
          cellRenderer: this.editEvents.bind(this)
        }
      ],
      rowData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.quill = React.createRef();
    this.apiPostNewsImage = this.apiPostNewsImage.bind(this);
    this.hideEvent = this.hideEvent.bind(this);
  }
  editEvents(params) {
    
    // let details = params.data;
    return `<button type="button" data-action-type="edit"  class="btn btn-sm  new_btn_save_bt_users_light  py-0">View</button>`;
  }


  nameCellRenderer(params) {
    return `  <div
    
    className="msg-text"
    dangerouslySetInnerHTML={{
      __html: ${params.data.task_message},
    }}
  ></div>`;
  }


  showEvent = task => {
 
    if (task.column.colId === 'edit') {
      // let data = task.data;
      // let actionType = task.event.target.getAttribute('data-action-type');
      this.setState({
        viewData: task.data,
        viewModal: true
      });
      // return this.onActionEditClick(data);
    }
  
  };

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }
  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
  };
  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  
  };
  refreshGrid() {
    this.props.getCases();
  }

  
  apiPostNewsImage = async data => {
    const response = await Axios.post(
      `${BASEURL}/api/v1/users/uploadBlastImage`,
      data,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );
    return response;
  };

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
    this.setState({ paginationPageSize: Number(value) });
  }

  handleCalendarDate(date) {
    this.setState({
      calender_date: date
    });
  }
  onFilterChanged(e) {
    var value = document.getElementById('search').value;
    this.gridApi.setQuickFilter(value);
    this.setState({ displayedRows: this.gridApi.getDisplayedRowCount() });
  }
  // handleEditorChange = e => {
  //   this.setState({ task_details: e });
  // };
  handleEditorChange = (event, editor) => {
    // this.setState({ task_details: e });
    const data = editor && editor.getData();

    this.setState({ task_details: data });
  };

  handleChange = event => {
  
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({ [name]: value });
  };
  uploadAdapter = loader => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(file => {
            body.append('file', file);
           

            Axios.post(`${BASEURL}/api/v1/users/uploadBlastImage`, body, {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            })
              .then(res => res.data)
              .then(res => {
             
                resolve({
                  default: res
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  };
  uploadPlugin = editor => {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return this.uploadAdapter(loader);
    };
  };
  handleSubmit = event => {
    event.preventDefault();
    let date = this.state.calender_date;
    let task_data = this.state.task_details;
    

    if (date) {
      date = moment(date).format('YYYY-MM-DD');
    }

    if (task_data) {
      task_data = task_data;
    }
    if(!this.state.task_details )
    {
      notify.show('Please enter Daily Task details.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }
    else if(!this.state.title)
    {
      notify.show('Please enter Daily Task title.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }
    else if(!date)
    {
      notify.show('Please enter Daily Task date.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });

    }
    else{
    const data = {
      task_message: task_data,
      added_on: moment(date).format('YYYY-MM-DD'),
      title: this.state.title
    };

    this.props.saveDailyTask(`/api/v1/daily_task/addTask`, data, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    this.setState({
      calender_date: ''
    });
    this.hideModal();
    this.resetForm();
  }
  };
  resetForm() {
    this.setState({
      task_details: '',
      title: '',
      startDate: null
    });
  }
  saveTask = () => {
    const details = this.state.task_details;
    const date = this.state.startDate;
  };

  //===================== RENDER ==========================
  render() {
    let data = [];
    let filterData = '';
    if (this.props.eventsData !== '' && this.props.eventsData.length > 0) {
      this.props.eventsData.forEach(element => {
        let e = {};
        e = element;
        e.start = moment(element['start']).format('MM/DD/YYYY');
        e.end = moment(element['end']).format('MM/DD/YYYY');
        e.created_date = moment(element['created_date']).format('MM/DD/YYYY');
        data.push(e);
      });
    }
 
    const endDate = new Date(moment().format('MM/DD/YYYY'));

    const TaskEnd = endDate.setMonth(endDate.getMonth() + 18);
    // console.log("task end--> ", TaskEnd);
    const EndDate = moment(TaskEnd).format('YYYY-MM-DD');
    const endDate1 = new Date();
    const createDate1 = endDate1.setDate(endDate1.getDate());
    const createdDate3 = moment(createDate1).format('YYYY-MM-DD');
    filterData = data.filter(
      el => el.added_on >= createdDate3 && el.added_on <= EndDate
    );
    filterData.sort(function(a, b) {
      return new Date(a.added_on) - new Date(b.added_on);
    });
  

    const exampledate = moment()
    .add(18, 'M')
    .calendar()
   
    return (
      <>
     
        <div className="container">
          <h2 className="text-center heading">Daily Task List</h2>
          <div className="daily-task-list-view">
            <div className="add-btn">
              <button
                onClick={this.showModal}
                className={
                  this.props.displayMode
                    ? 'btn btn_save_bt  daily'
                    : 'btn btn_save_bt white-button daily'
                }
              >
                <span>Add Task</span>
                <img
                  src={require('../../Utils/Assets/plus.png')}
                  alt="cup-icon"
                />
              </button>
            </div>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            <AgGridReact
              onGridReady={this.onGridReady}
              columnDefs={this.state.columnDefs}
              rowData={filterData}
              pagination={true}
              reactNext={true}
              animateRows
              onCellClicked={task => this.showEvent(task)}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
            />
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
                value={this.state.paginationPageSize}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <ViewTask
            isOpen={this.state.viewModal}
            toggle={this.hideViewModal.bind(this)}
            data={this.state.viewData}
            viewMode={true}
          />
      
          <Modal
            isOpen={this.state.show}
            toggle={this.hideModal}
            className="daily-task-popup"
            size="lg"
          >
            <ModalHeader toggle={this.hideModal} className="eventModal">
              <h5 className="text-center p-0">Daily Tasks</h5>
            </ModalHeader>
            <ModalBody>
              <form onSubmit={event => this.handleSubmit(event)}>
                <div class="row">
                  <div class="form-group col-md-6 ">
                    <label for="startDate">Date*:</label>
                    <div className="custom-datepicker">
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholderText="mm-dd-yyyy"
                        highlightDates={new Date()}
                        locale="en"
                        minDate={moment().toDate()}
                        selected={this.state.calender_date}
                        maxDate={new Date(exampledate)}
                        id="startDate"
                        name="startDate"
                        onChange={date => this.handleCalendarDate(date)}
                      />
                      <i class="fa fa-calendar-o" aria-hidden="true"></i>
                    </div>
                    {/* <input
                      type="date"
                      class="form-control"
                      id="startDate"
                      name="startDate"
                      onChange={this.handleChange}
                      value={this.state.startDate}
                      min={new Date().toISOString().split('T')[0]}
                      required
                    /> */}
                  </div>
                  <div class="form-group col-md-6 ">
                    <label for="title">Task Title*:</label>
                    <input
                      type="text"
                      class="form-control"
                      id="title"
                      name="title"
                      onChange={this.handleChange}
                      value={this.state.title}    
                    />
                  </div>
                  <div class="form-group col-md-12 ">
                    <label for="startDate">Task Detail*:</label>
                    <span style={{ display: 'inline-block' }}>
                      <i
                        className="fa fa-question-circle"
                        data-tip
                        data-for={'test'}
                        style={{ fontSize: '1.2em' }}
                      />
                      {/* <ReactTooltip
                        className="toolTip"
                        id={'test'}
                        aria-haspopup="true"
                        effect="solid"
                        place="right"
                      >
                        <p style={{ margin: 0, padding: 0 }}>
                          {`Daily Tasks can be used for personal notes, To-do, posting text, campaign notes, writing goals etc.
          Compose using Text Formatter bar below. Max image size is 3MB. Images can be resized by dragging & a caption added. Use 2 arrow symbols around image to add text fields above or below.
          Daily Tasks can be edited & deleted. Click on LIST view to see all your Daily Tasks from last 7 days and 18 months into the future. To view older Daily Tasks; Scroll in DT calendar view and click on individual days or Daily Tasks. To adjust Daily Task Reminders; Go to Preference page under Settings, turn Reminders on or off and/or set to Daily or Weekly Digest.`}
      <br/>

        {`Default font: Raleway 12 pts.`}
                        </p>
                      </ReactTooltip> */}
                    </span>
                 
                
                    <CKEditor
                      editor={FullEditor}
                      onChange={this.handleEditorChange}
                      config={{
                        extraPlugins: [this.uploadPlugin],
                        toolbar: {
                          items: [
                            'fontSize',
                            'fontFamily',
                            '|',
                            'bold',
                            'italic',
                            '|',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            'imageStyle:full',
                            'imageStyle:side',
                            'imageStyle:alignLeft',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'link',
                            'blockQuote',
                            'imageUpload',
                            // 'insertTable',
                            'fontColor'
                          ],
                          image: {
                            // Configure the available styles.
                            styles: ['alignLeft', 'alignCenter', 'alignRight'],
                            sizes: ['50%', '75%', '100%'],

                            // Configure the available image resize options.
                            // resizeOptions: [
                            //   {
                            //     name: 'imageResize:original',
                            //     label: 'Original',
                            //     value: null
                            //   },
                            //   {
                            //     name: 'imageResize:50',
                            //     label: '50%',
                            //     value: '50'
                            //   },
                            //   {
                            //     name: 'imageResize:75',
                            //     label: '75%',
                            //     value: '75'
                            //   }
                            // ],

                            // You need to configure the image toolbar, too, so it shows the new style
                            // buttons as well as the resize buttons.
                            toolbar: [
                              'imageStyle:alignLeft',
                              'imageStyle:alignCenter',
                              'imageStyle:alignRight',
                              '|',
                              'imageResize',
                              '|',
                              'imageTextAlternative'
                            ]
                          }
                        },  
                        fontFamily: {
                          options: [
                            'Arial, Helvetica, sans-serif',
                            'Calibri',
                            'Courier New, Courier, monospace',
                            'Georgia, serif',
                            'Lucida Sans Unicode, Lucida Grande, sans-serif',
                            'Raleway, Geneva, sans-serif',
                            'Tahoma, Geneva, sans-serif',
                            'Times New Roman, Times, serif',
                            'Trebuchet MS, Helvetica, sans-serif',
                            'Verdana, Geneva, sans-serif',                                         
                          ],
                          supportAllValues: true
                        },
                          fontSize: {
                          options: [12,14, 16, 18, 20, 22, 24],
                      }
                      }}
                    />
                    <button
                      type="submit"
                      onClick={this.saveTask}
                      className={
                        'btn btn_save_bt' || 'btn btn_save_bt dark-button'
                      }
                    >
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }

  onDeleteEvent() {
    this.props.delete('/api/v1/event/' + localStorage.getItem('eventId'), {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    this.setState({ modal: false });
  }
  componentDidMount() {
    this.props.getAllTask(`/api/v1/daily_task/getTask`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }
}

const mapStateToProps = state => {
  return {
    displayEventMode: state.getEventMode,
    displayMode: state.changeMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    //getAllEvents: () => dispatch(getAllEvents()),
    //  getAllDailyTasks: () => dispatch(getAllDailyTasks()),
    // setupEvent: () => dispatch(setupEvent()),
    // delete: (url, headers) => dispatch(deleteEvent(url, headers)),
    // addToFavourite: (url, header, data, pageName) =>
    //  dispatch(addEventToFavourite(url, header, data, pageName)),
    saveDailyTask: (url, data, headers) =>
      dispatch(saveDetail(url, data, headers)),
    getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
    editTask: (url, data, headers) =>
      dispatch(editTaskDetails(url, data, headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyListView);
