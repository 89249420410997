import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { getstatic } from '../../Actions/index';
import { BASEURL } from '../../constant';
import { isAndroid, isIOS } from 'react-device-detect';
import PaymentModal from '../SignUp/paymentModal';
import { notify } from 'react-notify-toast';
import { profileLoading } from '../../Actions/profile-setup';
import {
  getBlasts,
  deleteBlast,
  buyUserBlast
} from '../../Actions/profile-setup';
import logo from '../../Utils/Assets/logo_2.png';
import { Link } from 'react-router-dom';
import add from '../../Utils/Assets/add.png';
import close from '../../Utils/Assets/close_p.png';

class AddOnModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      consent_type: 'Terms & Conditions',
      content: '',
      version: '',
      id: '',
      source: 'Website',
      ipAddress: '',
      reason: 'Add-on',
      userId: localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : '',
      showPaymentModal: false,
      showPaymentButton: true,
      showAdditionalPaymentButton: true,
      ispcheck: false,
      pconsent_type: 'Privacy Policy',
      pversion: '',
      pid: '',
      modal: false,
      promoCode: '',
      discountAmount: 0,
      allDiscountData: {},
      payAmount: 0
    };
    this.getTermsConditions = this.getTermsConditions.bind(this);
    this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
    this.getIpAddress = this.getIpAddress.bind(this);
    this.getUserConsents = this.getUserConsents.bind(this);
  }

  promoToggle = () => this.setState({ modal: !this.state.modal });
  onPrivacyCheck = a => {
    if (a) {
      this.setState({ ispcheck: true });
      this.setState({ showPaymentButton: false });
      this.setState({ showAdditionalPaymentButton: false });
    } else {
      this.setState({ ispcheck: false });
      this.setState({ showPaymentButton: true });
      this.setState({ showAdditionalPaymentButton: true });
    }
  };
  togglePaymentModal = () => {
    this.setState({
      showPaymentModal: !this.state.showPaymentModal
      // profileData: {}
    });
  };

  addClientToken = async token => {
    const { discountAmount } = this.state;
    const { planCost } = this.props;
    try {
      this.togglePaymentModal();
      //  this.toggleAddOnModal();

      this.props.showLoader(true);

      const headers = {
        'x-authorization': localStorage.getItem('token')
      };

      const buyBookReleaseData = {
        clientToken: token,
        amount: planCost - discountAmount
        // promoCode: this.state.promoCode
      };

      const buyBookRelease = await axios.post(
        BASEURL + '/api/v1/users/buyBookRelease',
        buyBookReleaseData,
        {
          headers: headers
        }
      );

      if (buyBookRelease.status === 200) {
        this.props.showLoader(false);
        notify.show('Book Release purchased successfully. ', 'custom', 5000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        this.props.toggle();
        const response = await axios.get(
          BASEURL + '/api/v1/users/getUserConsents',
          {
            params: {
              userId: localStorage.getItem('userId'),
              version: this.state.version,
              pversion: this.state.pversion,
              reason: this.state.reason
            }
          }
        );
        if (response.data.length > 0) {
          // this.setState({
          //   ispcheck: true,
          //   showPaymentButton: false,
          // });
        } else {
          await axios.post(
            BASEURL + '/api/v1/users/postUserConsent',
            this.state,
            {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            }
          );
        }
      }
    } catch (error) {
      console.log(error);
      this.props.showLoader(false);
      if (error.response && error.response.status === 400) {
        notify.show(`${error.response.data.message}`, 'custom', 5000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      } else {
        notify.show(
          'Something went wrong Please try again after some time.',
          'custom',
          5000,
          { background: '#f8d7da', text: '#721c24' }
        );
      }
    }
  };
  async getTermsConditions() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getTermsConditions'
      );

      if (response.data.length)
        this.setState({
          content: response.data[0].consent_body,
          version: response.data[0].version,
          id: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }
  async getPrivacyPolicy() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getPrivacyPolicy'
      );
      if (response.data.length)
        this.setState({
          pversion: response.data[0].version,
          pid: response.data[0].id
        });
    } catch (e) {
      console.log(e);
    }
  }

  async getUserConsents() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getUserConsents',
        {
          params: {
            userId: localStorage.getItem('userId'),
            version: this.state.version,
            pversion: this.state.pversion,
            reason: this.state.reason
          }
        }
      );
      if (response.data.length > 0) {
        this.setState({
          ispcheck: true,
          showPaymentButton: false
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getIpAddress() {
    try {
      const response = await axios.get('https://api.ipify.org/?format=json');
      this.setState({ ipAddress: response.data.ip });
    } catch (e) {
      console.log(e);
    }
  }

  handlePromoCode = event => {
    this.setState({ promoCode: event.target.value });
  };

  handlePromoApply = async () => {
    const { promoCode } = this.state;
    const payload = {
      promoCode
    };
    try {
      const Promodata = await axios.post(
        BASEURL + '/api/v1/PromoCodeValidate',
        payload
      );
      console.log('Promodata', Promodata);
      const { code, message, checkValidPromo } = Promodata.data;
      if (code === 200) {
        this.setState({ discountAmount: checkValidPromo[0].amount });
        this.setState({ allDiscountData: checkValidPromo[0] });
        this.promoToggle();
        notify.show(message, 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
      } else {
        this.setState({ discountAmount: '' });
        this.setState({ allDiscountData: '' });
        notify.show(message, 'custom', 2000, {
          background: '#f8d7da',
          text: '#721c24'
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  //===================== RENDER ==========================

  render() {
    // console.log(this.state);
    const { planCost } = this.props;
    const { discountAmount } = this.state;
    console.log(
      'this is planCost ',
      planCost,
      ' This is discount ',
      discountAmount
    );
    return (
      <>
        <Modal
          isOpen={this.state.disabled ? false : this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          size="md"
          className="blast-purchase popup add-on-purchase membership-page-popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            {/* <h3 className="text-center p-0"> Terms And Conditions </h3> */}
          </ModalHeader>
          <ModalBody>
            {/* <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span> */}
            <div className="cross-icon">
              <img src={close} alt="close" onClick={this.props.toggle} />
            </div>
            <div className="logo-blast">
              <img src={logo} alt="logo" />
            </div>
            <div class="form-check mb-2 mr-sm-2">
              <div class="mb-2">
                <h6
                  style={{
                    maxWidth: '88%',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                  }}
                >
                  Purchase of one add-on (additional) Book Release to be added
                  to the OTR Romance Event calendar.
                </h6>
              </div>
            </div>
            <div className="mt-0 input-text" style={{ fontSize: '0.7rem' }}>
              <input
                type="checkbox"
                name="acknowledge"
                className="agreeTerms"
                id="terms"
                checked={this.state.ispcheck}
                onClick={e => {
                  this.onPrivacyCheck(e.target.checked);
                }}
                required
              />
              <h6>
                By purchasing add-on, you are agreeing to the OTR
                <span
                  onClick={this.openConditionsModal}
                  className="mousechange"
                  style={{ color: 'blue' }}
                >
                  {' '}
                  <Link to={`/dashboard/condition`} target="_blank">
                    {'Terms & Conditions'}
                  </Link>
                </span>{' '}
                and{'  '}
                <span
                  onClick={this.openPrivacyModal}
                  className="mousechange"
                  style={{ color: 'blue' }}
                >
                  <Link to={`/dashboard/privacy`} target="_blank">
                    {'Privacy Policy'}
                  </Link>
                  {'  '}
                </span>
                understand that we do not offer refunds on any purchasesdd.
                {'  '}
              </h6>
            </div>
            <h6 onClick={this.promoToggle} className="promo-code-content">
              {/* <i
                class="fa fa-plus"
                aria-hidden="true"
                onClick={this.promoToggle}
              ></i>{' '} */}
              <img src={add} alt="" onClick={this.promoToggle} />
              <span>Add a Promo Code</span>
            </h6>
            <div className="blast-purchase-btn purchase-popup">
              <button
                className="btn btn-filled align-self-end"
                id="Next"
                type="button"
                // onClick={(e) => {
                //   this.props.toggle();
                //   this.handleSubmit(e);
                // }}

                // onClick={this.togglePaymentModal}
                // disabled={this.state.showAdditionalPaymentButton}
                // disabled={this.state.showPaymentButton}
              >
                OTR Book Release: ${planCost - discountAmount} USD each
              </button>
              <button
                className="btn btn-filled align-self-end"
                id="Next"
                type="button"
                // onClick={(e) => {
                // //   this.props.toggle();
                // //     this.handleSubmit(e);

                // }}
                onClick={this.togglePaymentModal}
                disabled={this.state.showPaymentButton}
              >
                PURCHASE ADD-ON
              </button>
            </div>
          </ModalBody>
        </Modal>
        {/* <PaymentModal
          isOpen={this.state.showPaymentModal}
          addClientToken={this.addClientToken}
          planCost={200}
          toggle={this.togglePaymentModal.bind(this)}
          blast="blast"
        /> */}

        <Modal
          isOpen={this.state.modal}
          toggle={this.promoToggle}
          id="myModal"
          size="md"
          className="blast-purchase popup"
        >
          <ModalHeader toggle={this.promoToggle} className="eventModal">
            {/* <h3 className="text-center p-0"> Terms And Conditions </h3> */}
          </ModalHeader>
          <ModalBody className="promo-popup">
            {/* <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span> */}
            <div className="cross-icon">
              <img src={close} alt="close" onClick={this.promoToggle} />
            </div>
            <div className="logo-blast">
              <img src={logo} alt="logo" />
            </div>
            <div class="form-check mb-2 mr-sm-2">
              <div class="mb-2">
                <h6>PROMO CODE</h6>
              </div>
            </div>

            <div className="blast-purchase-btn upgrade-btn">
              <div class="form-check pl-0">
                <input
                  type="text"
                  value={this.state.promoCode}
                  name="promoCode"
                  onChange={this.handlePromoCode.bind(this)}
                />

                <button
                  className="btn btn-filled align-self-end"
                  id="Next"
                  type="button"
                  onClick={this.handlePromoApply.bind(this)}
                >
                  Apply
                </button>
              </div>
              <div className="text-blast">
                Promo Codes are valid for one-time use only. Future renewal will
                be at the regular membership rate at time of renewal. Offer may
                not be combined with any other promo codes, coupons, discounts,
                offers, or promotions.
              </div>
            </div>
          </ModalBody>
        </Modal>
        <PaymentModal
          isOpen={this.state.showPaymentModal}
          addClientToken={this.addClientToken}
          planCost={planCost - discountAmount}
          toggle={this.togglePaymentModal.bind(this)}
          allDiscountData={this.state.allDiscountData}
        />
      </>
    );
  }
  async componentDidMount() {
    this.getTermsConditions();
    this.getPrivacyPolicy();
    this.getIpAddress();
    this.getUserConsents();
    this.props.getBlasts(`/api/v1/users/blastDetails`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    if (isIOS) return this.setState({ source: 'IOS' });
    if (isAndroid) return this.setState({ source: 'Android' });

    const Plandata = await axios.get(BASEURL + '/api/v1/getAllPlans');

    const { plans } = Plandata.data;
    const findAdvertiseData = plans.find(
      el => el.name === 'OTR Book Release Add-on Purchase (Author)'
    );
    if (findAdvertiseData) {
      this.setState({ payAmount: findAdvertiseData.price });
    }
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData,
  blastDetails: state.getBlastDetails,
  displayMode: state.changeMode,
  buyStatus: state.buyBlastStatus
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url)),
  getBlasts: (url, headers) => dispatch(getBlasts(url, headers)),
  deleteBlast: (url, headers) => dispatch(deleteBlast(url, headers)),
  buyBlast: (url, data, header) => dispatch(buyUserBlast(url, data, header)),
  showLoader: status => dispatch(profileLoading(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOnModel);
