import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { HashLoader } from 'react-spinners';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Route, Link, Redirect, withRouter, Navigate } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import PropTypes, { object } from 'prop-types';
import {
  changeDisplayMode,
  getProfileDetails,
  Tab,
  filterevent,
  editPrefrence
} from '../../Actions/profile-setup';

class EditPrefrences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notification: true,
      newsletters: true,
      trendinglists: true,
      reminders: true,
      reminderType: 'daily',
      emails_frequency: 'daily'
    };
  }

  //= ==================== RENDER ==========================
  render() {
    // console.log("this.state.newsletters--> ", this.state.newsletters);
    const { value, prefrence } = this.state;
    const isReader = +localStorage.getItem('membershipId') === 2;
    const isBlogger = +localStorage.getItem('membershipId') === 3;
    const isVendor = +localStorage.getItem('membershipId') === 4;
    const isBloggerPremium = +localStorage.getItem('membershipId') === 6;

    return (
      <div className="container edit-preferences-page">
        <div className="mb-4">
          <h2 className="text-center heading"> Edit Prefrences </h2>
        </div>

        <div className="row">
          <div className="col-md-12 mb-2">
            <fieldset
              className={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend className="scheduler-border">
                <b>DISPLAY</b>
              </legend>
              <div className="control-group blast-list">
                <div className="toggle_switch">
                  <span
                    className="yes_no"
                    style={{
                      color: this.props.displayMode ? '#000' : '#fff'
                    }}
                  >
                    Dark
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="myevents"
                      onChange={this.handleMyEvents.bind(this)}
                      checked={this.props.displayMode}
                    />
                    <span className="slider round" />
                  </label>
                  <span
                    className="yes_no"
                    style={{
                      color: this.props.displayMode ? '#000' : '#fff'
                    }}
                  >
                    Light
                  </span>
                </div>
              </div>
            </fieldset>
          </div>

          {/*  REMINDER */}
          <div className="col-md-12  mb-2">
            <fieldset
              className={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend className="scheduler-border">
                <b>REMINDERS</b>
              </legend>
              <div className="control-group blast-list ">
                <div className="reminderContainer">
                  <div className="">
                    <p className="reminderText">
                      Would you like to receive email notifications?
                    </p>
                    <p className="reminderText1">
                      <b>
                        {' '}
                        {`EVENT REMINDERS${!isReader && !isBlogger ? ',' : ''}
                     ${!isReader && !isBlogger ? 'DAILY TASKS,' : ''} ${
                          !isReader && !isBlogger && !isVendor
                            ? 'FOLLOWER UPDATES'
                            : ''
                        }  via email`}
                        :
                      </b>
                    </p>
                  </div>
                  <div className="">
                    <div className="toggle_switch  ml-2">
                      <span
                        className="yes_no"
                        style={{
                          color: this.props.displayMode ? '#000' : '#fff'
                        }}
                      >
                        No
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="reminders"
                          onChange={this.handleChangeEvent.bind(this)}
                          checked={this.state.reminders}
                        />
                        <span className="slider round" />
                      </label>
                      <span
                        className="yes_no"
                        style={{
                          color: this.props.displayMode ? '#000' : '#fff'
                        }}
                      >
                        Yes
                      </span>
                    </div>
                  </div>
                </div>

                <div className="toggle_switch">
                  <span
                    className="yes_no"
                    style={{
                      color: this.props.displayMode ? '#000' : '#fff'
                    }}
                  >
                    Daily
                  </span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="reminderType"
                      onChange={e => {
                        if (e.target.checked) {
                          this.setState({ reminderType: 'weekly' }, () =>
                            this.editPrefrance()
                          );
                        } else {
                          this.setState({ reminderType: 'daily' }, () =>
                            this.editPrefrance()
                          );
                        }
                      }}
                      checked={this.state.reminderType === 'weekly'}
                    />
                    <span className="slider round" />
                  </label>
                  <span
                    className="yes_no"
                    style={{
                      color: this.props.displayMode ? '#000' : '#fff'
                    }}
                  >
                    Weekly Digest
                  </span>
                </div>
              </div>
            </fieldset>
          </div>

          {/* OTR Notification */}

          <div className="col-md-12  mb-2">
            <fieldset
              className={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend className="scheduler-border">
                <b>OTR NOTIFICATIONS</b>
              </legend>
              <div className="control-group blast-list ">
                <div className="reminderContainer">
                  <div className="toggle-section">
                    <p className="reminderText">
                      Would you like to receive email notifications?
                    </p>
                    <p className="reminderText1 mb-2">
                      <b>
                        {' '}
                        {`NEWSLETTERS:
                       `}
                      </b>
                      <div className="toggle_switch">
                        <span
                          className="yes_no"
                          style={{
                            color: this.props.displayMode ? '#000' : '#fff'
                          }}
                        >
                          No
                        </span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="newsletters"
                            onChange={this.handleChangeEvent.bind(this)}
                            checked={this.state.newsletters}
                          />
                          <span className="slider round" />
                        </label>
                        <span
                          className="yes_no"
                          style={{
                            color: this.props.displayMode ? '#000' : '#fff'
                          }}
                        >
                          Yes
                        </span>
                      </div>
                    </p>
                    <p className="reminderText1 mb-2">
                      <b>
                        {' '}
                        {`TRENDING LISTS:
                       `}
                      </b>
                      <div className="toggle_switch">
                        <span
                          className="yes_no"
                          style={{
                            color: this.props.displayMode ? '#000' : '#fff'
                          }}
                        >
                          No
                        </span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="trendinglists"
                            onChange={this.handleChangeEvent.bind(this)}
                            checked={this.state.trendinglists}
                          />
                          <span className="slider round" />
                        </label>
                        <span
                          className="yes_no"
                          style={{
                            color: this.props.displayMode ? '#000' : '#fff'
                          }}
                        >
                          Yes
                        </span>
                      </div>
                    </p>

                    {!isReader && !isBlogger && !isBloggerPremium ? (
                      <p className="reminderText1">
                        <b>
                          {' '}
                          {`PRIVATE MESSAGE NOTIFICATIONS:
                      `}
                        </b>
                        <div className="toggle_switch">
                          <span
                            className="yes_no"
                            style={{
                              color: this.props.displayMode ? '#000' : '#fff'
                            }}
                          >
                            No
                          </span>
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="notification"
                              onChange={this.handleChangeEvent.bind(this)}
                              checked={this.state.notification}
                            />
                            <span className="slider round" />
                          </label>
                          <span
                            className="yes_no"
                            style={{
                              color: this.props.displayMode ? '#000' : '#fff'
                            }}
                          >
                            Yes
                          </span>
                        </div>
                      </p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          {/* new events & blasts */}
          <div className="col-md-12  mb-2">
            <fieldset
              className={
                this.props.displayMode
                  ? 'scheduler-border border-light'
                  : 'scheduler-border border-dark'
              }
            >
              <legend className="scheduler-border">
                <b>NEW EVENTS & BLASTS</b>
              </legend>
              <div className="control-group blast-list ">
                <p>
                  New Events & Blasts from Authors, PR Firms and Blogger
                  Premiums you follow. To update:{' '}
                  <Link to="/dashboard/users/following">Follow page</Link>{' '}
                </p>

                <div className="row">
                  <div className="col-6">
                    <p>New Event notifications via email:</p>
                  </div>

                  <div className="col-12">
                    <div className="toggle_switch">
                      <span
                        className="yes_no"
                        style={{
                          color: this.props.displayMode ? '#000' : '#fff'
                        }}
                      >
                        DAILY
                      </span>
                      <label className="switch">
                        <input
                          type="checkbox"
                          name="newEvents"
                          onChange={e => {
                            if (e.target.checked) {
                              this.setState(
                                { emails_frequency: 'weekly' },
                                () => this.editPrefrance()
                              );
                            } else {
                              this.setState({ emails_frequency: 'daily' }, () =>
                                this.editPrefrance()
                              );
                            }
                          }}
                          checked={this.state.emails_frequency === 'weekly'}
                        />
                        <span className="slider round" />
                      </label>
                      <span
                        className="yes_no"
                        style={{
                          color: this.props.displayMode ? '#000' : '#fff'
                        }}
                      >
                        WEEKLY DIGEST
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <div className="mt-2 pl-2 bottom-preferences">
              <span className="">
                <i>*Weekly Digest is emailed on Sundays.</i>
              </span>
              <br />
              <span>
                <i>*When turned OFF you will get onsite notifications only.</i>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(newProps) {
    let newData = newProps.profileData.profile;
    if (newData) {
      this.setState({
        notification: newData.email_notification,
        trendinglists: newData.trendinglists,
        newsletters: newData.newsletters,
        reminders: newData.send_reminder,
        reminderType: newData.reminder_frequency,
        emails_frequency: newData.emails_frequency
      });
    }
  }

  componentDidMount() {
    this.props.getUserProfile(
      '/api/v1/user/profile',
      { headers: { 'x-authorization': localStorage.getItem('token') } },
      false
    );
    if (this.props.myProfileData == '') {
      this.props.getUserProfile(
        '/api/v1/user/profile',
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        false
      );
    }
    if (this.props.profileData) {
      // this.setState({
      //     value: this.props.profileData.profile.email_notification,
      //     prefrence: this.props.profileData.profile.emails_frequency
      // })
      let newData = this.props.profileData.profile;
      this.setState({
        notification: newData.email_notification,
        trendinglists: newData.trendinglists,
        newsletters: newData.newsletters,
        reminders: newData.send_reminder,
        reminderType: newData.reminder_frequency,
        emails_frequency: newData.emails_frequency
      });
    }
  }

  handleMyEvents(event) {
    this.props.setDisplayMode(event.target.checked);
  }
  handleChangeEvent(event) {
    this.setState({ [event.target.name]: event.target.checked }, () =>
      this.editPrefrance()
    );
  }

  editPrefrance() {
    let data = {
      email_notification: this.state.notification,
      trendinglists: this.state.trendinglists,
      newsletters: this.state.newsletters,
      send_reminder: this.state.reminders,
      reminder_frequency: this.state.reminderType,
      emails_frequency: this.state.emails_frequency
    };

    this.props.editPrefrence('/api/v1/profile/editPrefrence', data, {
      headers: {
        'x-authorization': localStorage.getItem('token')
      }
    });
  }

  changePrefrence(event) {
    let data = {
      email_notification: +this.state.value,
      trendinglists: +this.state.value,
      newsletters: +this.state.value,
      emails_frequency: this.state.prefrence
    };
    this.props.editPrefrence('/api/v1/profile/editPrefrence', data, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    // event.preventDefault();
  }

  setSelectedOption(event) {
    this.setState({ value: event.target.value });
  }

  setPrefrence(event) {
    this.setState({ prefrence: event.target.value });
  }
}
const mapStateToProps = state => ({
  loading: state.profileLoading,
  displayMode: state.changeMode,
  profileData: state.myProfileData
});

const mapDispatchToProps = dispatch => ({
  setDisplayMode: mode => dispatch(changeDisplayMode(mode)),
  getUserProfile: (url, header, edit) =>
    dispatch(getProfileDetails(url, header, edit)),
  editPrefrence: (url, data, headers) =>
    dispatch(editPrefrence(url, data, headers))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPrefrences);
