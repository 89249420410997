import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import CONSTANT from '../../constant';
import '../Landing/landing.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div style={{ backgroundColor: '#1f1f1f' }}>
        <footer>
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-8 order-lg-2">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <ul className="p-0 footer-links">
                      <li
                        className={
                          window.location.hash === '#/privacy'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/privacy" className="links">
                          PRIVACY
                        </Link>
                      </li>
                      <li>|</li>
                      <li
                        className={
                          window.location.hash === '#/condition'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/condition" className="links">
                          TERMS &amp; CONDITIONS
                        </Link>
                      </li>
                      <li>|</li>
                      <li
                        className={
                          window.location.hash === '#/plans'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/plans" className="links">
                          MEMBERSHIP PLANS
                        </Link>
                      </li>
                      <li>|</li>
                      <li
                        className={
                          window.location.hash === '#/about'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/about" className="links">
                          ABOUT US
                        </Link>
                      </li>
                      <li>|</li>
                      <li
                        className={
                          window.location.hash === '#/help'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/help" className="links">
                          HELP
                        </Link>
                      </li>
                      <li>|</li>
                      <li
                        className={
                          window.location.hash === '#/advertise'
                            ? 'nav-item active'
                            : 'nav-item'
                        }
                      >
                        <Link to="/advertise" className="links">
                          ADVERTISE
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 order-lg-3">
                <ul className="list-unstyled footer-links text-lg-right text-center">
                  <li>
                    <a
                      href={CONSTANT.FACEBOOK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require('../../Utils/Assets/social_media/White/facebook.png')}
                        alt="logo"
                        width="40px"
                        height="40px"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={CONSTANT.INSTAGRAM}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require('../../Utils/Assets/social_media/White/instagram.png')}
                        alt="logo"
                        width="40px"
                        height="40px"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={CONSTANT.TWITTER_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require('../../Utils/Assets/social_media/White/twitter.png')}
                        alt="logo"
                        width="40px"
                        height="40px"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href={CONSTANT.YOUTUBE_URL}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={require('../../Utils/Assets/social_media/White/youtube.png')}
                        alt="logo"
                        width="40px"
                        height="40px"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 text-lg-left text-center">
                <p className="copyRight text-white">
                  &copy; Copyright 2023 OTR LLC
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }

  componentDidMount() {
    // this.props.resetSignupPage();
    // console.log('componentDidMount');
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
