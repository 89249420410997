import React from 'react';

import './Toolbar.css';

export default function Toolbar(props) {
  const { title, leftItems, user, receiverName } = props;
  console.log('receiverName', receiverName);
  return (
    <div className="toolbar">
      <div className="left-items">
        {leftItems}
        <b>{receiverName}</b>
      </div>
      <h1 className="toolbar-title">{title}</h1>

      <div className="right-items">
        <div
          className={`user-avatar-image ${
            localStorage.getItem('membershipId') === '1' ||
            localStorage.getItem('membershipId') === '8'
              ? 'authorImg'
              : localStorage.getItem('membershipId') === '4'
              ? 'vendorImg'
              : localStorage.getItem('membershipId') === '5'
              ? 'prFirmImg'
              : ''
          }`}
        >
          <img src={user} alt="" />
        </div>
      </div>
    </div>
  );
}
