import axios from 'axios';
import { notify } from 'react-notify-toast';
import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';
import { BASEURL } from '../constant';

export default createHistory();
const globalHistory = new createHistory();

export function genrateClientToken(token) {
  return {
    type: 'CLIENT_TOKEN',
    token
  };
}

export function signuploading(bool) {
  return {
    type: 'SIGNUP_LOADING',
    signup_status: bool
  };
}

export function getClientToken(url) {
  return dispatch => {
    // dispatch(signuploading(true));
    axios
      .get(BASEURL + url)
      .then(res => {
        dispatch(genrateClientToken(res.data.token.clientToken));
        dispatch(signuploading(false));
        // console.log("uuu", res.data.token.clientToken);
      })
      .catch(error => {
        dispatch(signuploading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // notify.show(`${error.response.data.message}`, "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
      });
  };
}
