import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  addEventToMyCalendar,
  getLinkedEvents,
  associatedEvents,
  addEventToFavourite
} from '../../../Actions/event-setup';
import EventModal from '../eventModal';
import axios from 'axios';
import { BASEURL } from '../../../constant';
import { notify } from 'react-notify-toast';

class LinkedBookrelease extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      fullName: '',
      eventData: {},
      rowData: [],
      profileKeyData: [],
      show: false,
      showGrid: false,
      event: '',
      patent: '',
      keyCheck: 0,
      columnDefs1: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_list',
          sortable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 295,
          filter: true,

          cellRenderer: function(event_type) {
            var backgroundColor;
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF79BC, #FF65FF';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#F3FfB5, #fFDB69';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#B469FF, #8E5BfF';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Sign-up' ||
              event_type.value === 'ARC Signup'
            ) {
              backgroundColor = '#ff5b5b,#ff5b9d';
            }
            if (event_type.value === 'Ticket Sales') {
              backgroundColor = '#DCB9FF, #B9B9FF';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              event_type.value
            );
          }
        },
        {
          headerName: 'TITLE',
          field: 'title',
          sortable: true,
          suppressMovable: true,
          unSortIcon: true,
          filter: true,
          width: 240,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 240,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 220,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 190,
          cellRenderer: function(endDate) {
            if (endDate.data.end_date) {
              return moment(endDate.data.end_date).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TIME (US EASTERN)',
          field: 'time',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'FAVORITE',
          field: 'favourite',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(favourite) {
            if (favourite.data.favourite) {
              return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
            } else {
              return '<i class="fa fa-star-o" style="font-size:20px;"></i>';
            }
          }
        },
        {
          headerName: 'ACTION',
          field: 'addcalender',
          cellRenderer: this.showAddCalender.bind(this)
        }
      ],
      columnDefs2: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(event_type) {
            var backgroundColor;
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF79BC, #FF65FF';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#F3FfB5, #fFDB69';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#B469FF, #8E5BfF';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Sign-up' ||
              event_type.value === 'ARC Signup'
            ) {
              backgroundColor = '#FF5B5B, #FF5B9D';
            }
            if (event_type.value === 'Ticket Sales') {
              backgroundColor = '#DCB9FF, #B9B9FF';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              event_type.value
            );
          }
        },
        {
          headerName: 'TITLE',
          field: 'title',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(endDate) {
            if (endDate.data.end_date) {
              return moment(endDate.data.end_date).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TIME',
          field: 'time',
          sortable: true,
          filter: true,
          resizable: true,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 180
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'FAVORITE',
          field: 'favourite',
          sortable: true,
          filter: true,
          resizable: true,
          unSortIcon: true,
          cellRenderer: function(favourite) {
            if (favourite.data.favourite) {
              return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
            } else {
              return '<i class="fa fa-star-o" style="font-size:20px;"></i>';
            }
          }
        },
        {
          headerName: 'ACTION',
          field: 'addcalender',
          cellRenderer: this.showAddCalender.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0
    };
    this.hideEvent = this.hideEvent.bind(this);
  }
  // showAddCalender(params) {
  //   let details = params.data.event_details_with_values;
  //   return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
  // }
  showAddCalender(params) {
    // console.log("------------------------>hhiii");
    let details = params.data.event_details_with_values;
    let is = params.data.isIncluded;
    let ownedByMe = params.data.ownedByMe;
    const createdDate = moment().format('YYYY-MM-DD');
    let checkPreviouDate =
      new Date(createdDate).getTime() <=
      new Date(params.data.start_date).getTime();
    // console.log('ownedByMe', ownedByMe, is, this.props.eventMode, params.data);
    // console.log('isIncludedisIncludedisIncluded', is);
    // console.log("this.props.eventMode, is, ownedByMe, checkPreviouDate", this.props.eventMode, is, ownedByMe, checkPreviouDate)
    if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === false &&
      checkPreviouDate === false
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADD</button>`;
    } else if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === false
    ) {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    } else {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    }
  }
  nameCellRenderer(params) {
    let midName = JSON.parse(params.data.user_details).filter(
      el => el.name == 'middlename'
    )[0];
    // console.log('midNameeee', midNameeee);
    // let midName = params.data.author_mid_name;

    // if (midName === 'null' || midName === null) {
    //   midName = '';
    // }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName.value : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }

  showEvent(e) {
    console.log('e is:', e);
    if (e.column.colId == 'favourite') {
      const data = this.props.filterData ? this.props.filterData : {};
      console.log('r=this is the data----->>> ', data);
      data.isGeneral = this.props.eventMode ? this.props.eventMode : '';
      if (this.props.sort) {
        data.sort = this.props.sort;
      }
      this.props.addToFavourite(
        `/api/v1/favourite/${e.data.id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        data,
        'eventFilter'
      );
    } else if (e.column.colId === 'addcalender') {
      let data = e.data;
      let actionType = e.event.target.getAttribute('data-action-type');
      //  window.location.reload();
      return this.onActionViewClick(data);

      // return this.onActionAddCalenderClick(data);
    } else {
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }
  showTime(params) {
    return params.data.time
      ? this.getEstTimeNow(new Date(), params.data.time) + ' '
      : '-';
  }
  getEstTimeNow(date, time) {
    const dates = date || new Date();
    const convertedTime = moment(time, 'hh:mm A').format('HH:mm');

    let start2 = moment(dates).toDate();
    const time2 = convertedTime.split(':');

    start2.setHours(time2[0]);
    start2.setMinutes(time2[1]);
    start2.setSeconds(0);
    const dt2 = new Date(start2);

    const timeZone = 'America/New_York';
    let myTime = dt2.toLocaleString('en-US', {
      timeZone
    });
    let timeTaken = moment(myTime).format('hh:mm A');

    return timeTaken;
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  titleCellRenderer(params) {
    if (params.data) {
      const eventDetails = params.data.event_details_with_values
        ? JSON.parse(params.data.event_details_with_values)
        : {};

      if (
        params.data.event_type === 'Release Party' ||
        params.data.event_type === 'Giveaway'
      ) {
        // console.log("]]]]]]]]]]]]]]]]]]]]]", eventDetails)
        let newTitle = '';
        if (params.data.event_type === 'Release Party') {
          newTitle = eventDetails[6]['release-party-event-name']
            ? eventDetails[6]['release-party-event-name'].length > 20
              ? eventDetails[6]['release-party-event-name'].substr(0, 20) +
                '...'
              : eventDetails[6]['release-party-event-name']
            : '';
        } else if (params.data.event_type === 'Giveaway') {
          newTitle = eventDetails[6]['Name of Giveaway']
            ? eventDetails[6]['Name of Giveaway'].length > 20
              ? eventDetails[6]['Name of Giveaway'].substr(0, 20) + '...'
              : eventDetails[6]['Name of Giveaway']
            : '';
        }
        return newTitle;
      }
      return `${
        params.data.title
          ? params.data.title.length > 20
            ? params.data.title.substr(0, 20) + '...'
            : params.data.title
          : ''
      } `;
    }
  }
  onActionViewClick(data) {
    // this.props.addToMyCalendar(data.id);
    // const data1 = this.props.filterData;

    data.isGeneral = this.props.eventMode;
    let url = window.location.href;
    let split = url.split('/');
    let tab = split[5];
    let tab1 = tab.split('?');
    let tab2 = tab1[0];

    // let pageName = '';
    // if (tab === 'linkedbookrelease') {
    //   pageName = 'linkedbookrelease';
    // }
    // console.log('pageName11', pageName);
    // notify.show(
    //   'Event successfully added to your Private calendar.',
    //   'custom',
    //   5000,
    //   {
    //     background: '#d4edda',
    //     text: '#155724'
    //   }
    // );
    // this.props.addToMyCalendar(
    //   `/api/v1/add_mycalendar/${data.id}`,
    //   {
    //     headers: { 'x-authorization': localStorage.getItem('token') }
    //   },
    //   this.props.associated,
    //   'linkedbookrelease',
    //   data.isGeneral
    // );
    this.props.addToMyCalendar(
      `/api/v1/add_mycalendar/${data.id}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      },
      data,
      'linkedbookrelease',
      this.props.associated,
      this.state.event
    );

    this.setState({ keyCheck: Math.random() });
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
    // this.handleRefresh(params);
  };

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  handleEditorChange = e => {
    this.setState({ task_details: e.target.getContent() });
  };

  hideViewModal = e => {
    this.setState({ viewModal: false });
  };

  handleRefresh = () => {
    window.location.reload();
  };

  //===================== RENDER ==========================
  render() {
    // console.log("hii associated------------->> ",this.props.associated)
    // console.log("thhis is rwndering check====>>>")
    // console.log("here is the this.props.associated---------- >>>>", this.props.associated);
    let data1 = this.props.linkedEvents.filter(
      el => el.parent_id === this.state.event || el.id === this.state.event
    );

    let my =
      this.props.associated && this.props.associated.find(el => el['title']);
    let data2;
    if (my && my.title) {
      data2 = my.title;
    }

    const yy = [];
    Array.isArray(this.props.associated) &&
      this.props.associated.filter(el => {
        if (el['event_details_with_values']) {
          yy.push({
            event_details_with_values: el['event_details_with_values']
          });
        }
      });

    let mee;

    if (localStorage.getItem('membershipId') === '8') {
      mee =
        this.props.associated &&
        this.props.associated.filter(e => e.is_cancel == 0);
    } else {
      mee =
        this.props.associated &&
        this.props.associated.filter(e => e.is_cancel == 0);
    }
    let collectId = [];
    let collectId1 = [];
    let collectEvent = [];
    let dataCheck =
      this.props.associated &&
      this.props.associated.map(el => {
        let checkMembership = moment().format('YYYY-MM-DD');
        if (el.update_cancel_cron === 0) {
          collectEvent.push(el);
        } else {
          collectId1.push(el);
        }
      });
    let checkEventFilter;
    // const createdDate = moment().format('YYYY-MM-DD');
    // if (collectId && collectId.length > 0) {
    //   checkEventFilter = collectId.filter(el => el.start_date < createdDate);
    // } else {
    //   checkEventFilter = collectEvent.filter(el => el.start_date < createdDate);
    // }
    const createdDate = moment().format('YYYY-MM-DD');
    if (collectId && collectId.length > 0) {
      checkEventFilter = [...collectId];
    } else {
      checkEventFilter = [...collectEvent];
    }
    let togetherEvent = [...checkEventFilter, ...collectId1];

    // console.log("hello title--------->>> ",  (new URLSearchParams((window.location.hash).split('?')[1])).get('title'), data2);

    if (!data2) {
      data2 = new URLSearchParams(window.location.hash.split('?')[1]).get(
        'title'
      );
    }

    const byData = this.props.associated[0]
      ? `${this.props.associated[0].author_first_name} ${
          JSON.parse(this.props.associated[0].user_details)[2].value
        } ${this.props.associated[0].author_last_name}`
      : new URLSearchParams(window.location.hash.split('?')[1]).get('author');

    return (
      <div className="linked-events-page">
        <div className="container">
          <div
            className={
              this.props.displayMode
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            <div className="mb-4">
              <h2 className="text-center heading">Linked Events</h2>
              {/* {this.props.linkedEvents[0] && (
                <h4 className="text-center">
                  Profile: &nbsp;
                  {this.props.linkedEvents[0] &&
                    this.props.linkedEvents[0].author_first_name +
                      ' ' +
                      this.props.linkedEvents[0].author_last_name}
                </h4>
              )} */}

              {this.state.event ? (
                <>
                  {' '}
                  <h4 className="text-center">
                    Book Release: {'  '}
                    {data2} (by {byData})
                  </h4>
                </>
              ) : (
                <>
                  {' '}
                  <h4 className="text-center">
                    {/* Book Release: &nbsp;
                    {this.props.linkedEvents[0] &&
                      this.props.linkedEvents[0].author_first_name +
                        ' ' +
                        this.props.linkedEvents[0].author_last_name} */}
                    Book Release: {'  '}
                    {data2} (by {byData})
                  </h4>
                </>
              )}
            </div>
            {this.state.patent !== 'null' ? (
              <div key={this.state.keyCheck}>
                {' '}
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs1}
                  defaultColDef={this.state.defaultColDef}
                  rowData={togetherEvent}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                />
              </div>
            ) : (
              <>
                <AgGridReact
                  onGridReady={this.onGridReady}
                  columnDefs={this.state.columnDefs2}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.props.linkedEvents}
                  pagination={true}
                  reactNext={true}
                  animateRows
                  onCellClicked={this.showEvent.bind(this)}
                  paginationPageSize={this.state.paginationPageSize}
                  paginationNumberFormatter={
                    this.state.paginationNumberFormatter
                  }
                />
              </>
            )}

            <div className="search-features" style={{ marginTop: '10px' }}>
              <p>
                <i>
                  Each column has search feature. Column width can be changed by
                  dragging. Hit refresh to reset.
                </i>
                {/* <button onClick={() => this.handleRefresh()}>
                  <i style={{ marginLeft: '5px' }} class="fa fa-refresh"></i>
                </button> */}
                <i
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer'
                  }}
                  className="fa fa-refresh"
                  onClick={this.handleRefresh}
                />
              </p>
            </div>
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          {Object.keys(this.state.eventData).length ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
              isInclude={this.state.eventData.isIncluded}
              eventTpy={this.state.event}
            />
          ) : null}
        </div>
      </div>
    );
  }

  componentWillReceiveProps() {
    if (this.gridApi) this.gridApi.setQuickFilter(this.props.search);
  }

  async componentDidMount() {
    // const query = new URLSearchParams(this.props.props.location.search);
    const query = window.location.hash;
    const params = new URLSearchParams(query.split('?')[1]);

    const userId = parseInt(params.get('userId'));

    const params1 = new URLSearchParams(query.split('&')[1]);
    const eventye = parseInt(params.get('parentId'));

    const params2 = new URLSearchParams(query.split('&')[1]);
    const eventye2 = params2.get('parentId');

    // console.log("eveny type:: ", { event: eventye, patent: eventye2 })
    this.setState({ event: eventye, patent: eventye2 });

    let url = window.location.href;
    let split = url.split('/');
    let tab = split[5];
    let tab1 = tab.split('?');
    let tab2 = tab1[0];

    // let tab = split[5] ? split[5] : split[4];
    // let pageName = '';

    if (query) {
      const { useName } = query;
      const { parentId } = query;
      // console.log('parentId', parentId);
      // /api/1v / events / getParentid;
      // console.log('parentId', parentId);
      // const dd = parentId === 'undefined' ? 'null' : parentId;
      // this.setState({ patent: dd });

      // this.props.getLinkedEvents(
      //   `/api/v1/events/getLinkedEvents?${
      //     query.eventId ? `event_id=${query.eventId}` : `userId=${userId}`
      //   }`,
      //   {
      //     headers: { 'x-authorization': localStorage.getItem('token') }
      //   }
      // );
      // console.log('query.eventId', eventye);
      this.props.getAssociatedEvents(
        `/api/v1/events/associatedEvents/${eventye}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } }
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    linkedEvents: state.getLinkedEventList,
    // getDateWiseTasks: state.getDateWiseTasks,
    displayMode: state.changeMode,
    associated: state.getAssociatedEvents,
    eventMode: state.getEventMode,
    favouriteData: state.addToFavourite,
    filterData: state.eventFilter
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLinkedEvents: (url, headers) => dispatch(getLinkedEvents(url, headers)),

    // addToMyCalendar: eventId =>
    //   dispatch(
    //     addEventToMyCalendar(`/api/v1/add_mycalendar/${eventId}`, {
    //       headers: { 'x-authorization': localStorage.getItem('token') }
    //     })
    //   ),
    addToMyCalendar: (url, header, data, pageName, eventsData, eventType) =>
      dispatch(
        addEventToMyCalendar(url, header, data, pageName, eventsData, eventType)
      ),
    getAssociatedEvents: (url, headers) =>
      dispatch(associatedEvents(url, headers)),
    addToFavourite: (url, header, data, pageName) =>
      dispatch(addEventToFavourite(url, header, data, pageName))

    // saveDailyTask: (url, data, headers) =>
    //   dispatch(saveDetail(url, data, headers)),
    //   getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedBookrelease);
