import React, { Component } from 'react';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import {
  getProfileDetails,
  editUserProfile,
  closeOtpMoal
} from '../../Actions/profile-setup';
import Otpverify from './otp-verifyModal';

class Userprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      oldPassword: null,
      showpwd: false,
      showpwds: false,
      openOtpModal: false,
      cNew: null
    };
  }

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    this.setState({
      [name]: target.value
    });
  }

  toggleOtpModal() {
    this.setState({
      email: this.props.profileData.profile.email,
      oldPassword: '',
      password: '',
      cNew: ''
    });
    this.props.closeOtpModal(false);
    this.setState({ otpModal: !this.state.otpModal });
  }

  updateUserDetails(e) {
    console.log('cNew', this.state.cNew, this.state.password, this.state.email);
    let data = {};
    if (this.props.profileData.profile.email !== this.state.email) {
      data.email = this.state.email;
    }

    if (this.state.password) {
      data.password = this.state.password;
    }
    if (this.state.oldPassword && this.state.password) {
      data.oldPassword = this.state.oldPassword;
    }
    if (this.state.cNew) {
      data.cNew = this.state.cNew;
    }
    if (this.state.email) {
      data.email = this.state.email;
    }
    if (this.state.oldPassword) {
      data.oldPassword = this.state.oldPassword;
    }

    if (this.state.password && !this.state.oldPassword) {
      e.preventDefault();
      return notify.show(
        'Please enter your current password.',
        'custom',
        5000,
        {
          background: '#f8d7da',
          text: '#721c24'
        }
      );
    }
    if (Object.keys(data).length > 0) {
      this.props.editUserprofile('/api/v1/user/change_info', data, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
      this.setState({
        email: '',
        oldPassword: '',
        password: '',
        cNew: ''
      });
    }

    e.preventDefault();
  }

  //= ==================== RENDER ==========================
  render() {
    return (
      <>
        <Otpverify
          isOpen={this.state.openOtpModal}
          toggle={this.toggleOtpModal.bind(this)}
          data={this.state}
        />
        <div className="mb-2" />
        <div className="">
          <fieldset
            className={
              this.props.displayMode
                ? 'scheduler-border border-light'
                : 'scheduler-border border-dark desktop'
            }
          >
            <legend className="scheduler-border">
              <b>UPDATE LOG-IN</b>
            </legend>
            <form onSubmit={this.updateUserDetails.bind(this)}>
              <div className="row">
                <div className="form-group col-6 mb-0">
                  <div className="form-border">
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label htmlFor="email">Current Email:</label>
                      </div>
                      <div className="col-md-8 desktop">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          onChange={this.handleChange.bind(this)}
                          // placeholder="Name"
                          value={this.state.email}
                          disabled={this.props.logintype ==='social' ? 'disabled':''}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="Newemail">New Email:</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="email"
                          className="form-control"
                          value={this.state.cNew}
                          id="cNew"
                          name="cNew"
                          onChange={this.handleChange.bind(this)}
                          placeholder="New Email"
                          disabled={this.props.logintype ==='social' ? 'disabled':''}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-6 mb-0">
                  <div className="form-border">
                    <div className="row mb-3">
                      <div className="col-md-4">
                        <label htmlFor="recieveemail">Current Password:</label>
                      </div>

                      <div className="col-md-8">
                        <input
                          type={this.state.showpwd ? 'text' : 'password'}
                          value={this.state.oldPassword}
                          className="form-control password_s "
                          placeholder="Password*"
                          name="oldPassword"
                          onChange={this.handleChange.bind(this)}
                          pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$"
                          disabled={this.props.logintype ==='social' ? 'disabled':''}
                        />
                        <i
                          className={`fa fa-eye${
                            this.state.showpwd ? '-slash' : ''
                          } pull-right show-pwd-dark-change`}
                          onClick={() => {
                            this.setState({ showpwd: !this.state.showpwd });
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="recieveemail">Change Password:</label>
                      </div>

                      <div className="col-md-8">
                        <input
                          type={this.state.showpwds ? 'text' : 'password'}
                          value={this.state.password}
                          className="form-control password_s"
                          placeholder="Password*"
                          name="password"
                          onChange={this.handleChange.bind(this)}
                          pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,20}$"
                          disabled={this.props.logintype ==='social' ? 'disabled':''}
                        />
                        <i
                          className={`fa fa-eye${
                            this.state.showpwds ? '-slash' : ''
                          } pull-right show-pwd-dark-change`}
                          onClick={() => {
                            this.setState({ showpwds: !this.state.showpwds });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
          
               {this.props.logintype ==='social' ?(<>
               
                <div className="form-group col-12 d-flex align-items-end justify-content-end mb-1 mr-1 ">
                    <p className='login-socialInfo'>
                      <b>
                        *If you joined via Google or Facebook, please manage login settings using that platform.
                        <br />
                        To create an OTR login; Log out, go to login page and click on the Convert login link.
                      </b>
                    </p>
                  <button
                   className={`disable-renew-btn ${
                    this.props.logintype
                      ? 'btn new_btn_save_bt float-md-right'
                      : 'btn new_btn_save_bt float-md-right white-button'
                  }`}
                  disabled={this.props.logintype ==='social' && 'disabled'}
                  >
                    UPDATE
                  </button>
                </div>
               
               </>):(<>
                <div className="form-group col-12 d-flex align-items-end justify-content-end mb-1 mr-1">
                <p className='login-socialInfo'>
                      <b>
                        *If you joined via Google or Facebook, please manage login settings using that platform.
                        <br />
                        To create an OTR login; Log out, go to login page and click on the Convert login link.
                      </b>
                    </p>
                  <button
                    className={
                      this.props.displayMode
                        ? 'btn btn_save_bt'
                        : 'btn btn_save_bt white-button'
                    }
                    onClick={this.onSubmit}
                  >
                   UPDATE
                  </button>
                </div>
               </>)}
              
             
              </div>
            </form>
          </fieldset>
        </div>
      </>
    );
  }

  componentWillReceiveProps(props) {
    // console.log("Props is: ", props.otpModal);
    if (this.props.profileData && !this.state.email) {
      this.setState({ email: this.props.profileData.profile.email });
    }
    if (props.otpModal) {
      this.setState({ openOtpModal: true });
    } else {
      this.setState({ openOtpModal: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('prevProps', this.props.profileData, prevProps.profileData);

    if (this.props.profileData !== prevProps.profileData) {
      this.setState({ email: this.props.profileData.profile.email });
    }
  }
  componentDidMount() {
    if (!this.props.profileData) {
      this.props.getUserProfile(
        '/api/v1/user/profile',
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        false
      );
    } else {
      this.setState({
        email: this.props.profileData.profile.email
      });
    }
  }
}
const mapStateToProps = state => ({
  loading: state.profileLoading,
  profileData: state.myProfileData,
  displayMode: state.changeMode,
  otpModal: state.openOtpModal
});

const mapDispatchToProps = dispatch => ({
  getUserProfile: (url, header, edit) =>
    dispatch(getProfileDetails(url, header, edit)),
  editUserprofile: (url, data, header) =>
    dispatch(editUserProfile(url, data, header)),
  closeOtpModal: status => dispatch(closeOtpMoal(status))
});

export default connect(mapStateToProps, mapDispatchToProps)(Userprofile);
