import React, { Component, useState } from 'react';
import { Redirect, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import TextBox from '../../Components/text-box';
import SelectBox from '../../Components/select-box';
import FileBoxVendor from '../../Components/file-box-vendor';
import DateBox from '../../Components/date-box';
import createHistory from 'history/createHashHistory';
import moment from 'moment';
import { css } from '@emotion/react';
import {
    saveProfile,
    getProfile,
    previewUserProfile
} from '../../Actions/profile-setup';
import UserProfileModal from '../usersList/userProfileModal';
import { notify } from 'react-notify-toast';
import './home.css';
import { stat } from 'fs';
import { Route } from 'react-router-dom';
import Footer from '../Footer/footer';
import LeftLogo from '../../Components/left-logo';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import Select from 'react-select';

const globalHistory = new createHistory();

const override = css`
  position: fixed;
  margin-top: 15%;
  left: 45%;
`;

var position = 0;
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formJSON: [],
            profile: null,
            preview: false,
            imgSrc: [],
            imgName: 'No file chosen',
            isimageError: false,
            displayEmail: '',
            startDate: new Date(),
            chosenEmoji: false,
            vco: false
        };
        this.arrprefillComponentValues = [];
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.onCheckboxChangeHandler = this.onCheckboxChangeHandler.bind(this);
        this.onCustomChangeHandler = this.onCustomChangeHandler.bind(this);
        this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
        this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
        this.getCustomElementObject = this.getCustomElementObject.bind(this);
        this.addMoreLinkHandler = this.addMoreLinkHandler.bind(this);
        this.addMoreServiceHandler = this.addMoreServiceHandler.bind(this);
        this.prefillComponentValueSubmitHandler = this.prefillComponentValueSubmitHandler.bind(
            this
        );
        this.saveProfile = this.saveProfile.bind(this);
    }

    //======================= On Change Handlers ================
    onChangeHandler(value, index, selectedValue) {
        if (index === 19 || index === 22) {
            if (value.length > 30) {
                return notify.show(
                    'Please enter no more than 30 characters.',
                    'custom',
                    3000,
                    {
                        background: '#f8d7d9',
                        text: '#721c24'
                    }
                );
            }
            let data = this.state.formJSON;
            data[index].value = value;
            this.setState({
                formJSON: data,
                displayEmail: value
            });
        } else {
            let data = this.state.formJSON;
            data[index].value = value;
            this.setState({
                formJSON: data,
                displayEmail: value
            });
        }
    }

    removeServiceHandler(parentIndex, index) {
        let data = JSON.parse(JSON.stringify(this.state.formJSON));

        data[parentIndex * 1].children.splice(index * 1, 1);
        data[parentIndex * 1].children.splice(index * 1 - 1, 1);
        data[parentIndex * 1].children.splice(index * 1 - 2, 1);

        if(localStorage.getItem('userType') === '5') {
            data[parentIndex * 1].children.splice(index * 1 - 3, 1);
        }

        if(data[parentIndex * 1].children.length < 4 && parentIndex == 11 && localStorage.getItem('userType') === '4') {

            let addMoreBtn = data[parentIndex].children[data[parentIndex].children.length - 1];

            data[parentIndex * 1].children = [];
            data[parentIndex].children.push(addMoreBtn);
        }

        this.setState({ formJSON: data });
    }

    onCheckboxChangeHandler(value, parentIndex, buttonIndex) {
        let data = this.state.formJSON;
        if (data[parentIndex].value == undefined) {
            data[parentIndex].value = '';
        }
        if (data[parentIndex].value.includes(value)) {
            data[parentIndex].value = data[parentIndex].value.replace(value, ' | ');
            data[parentIndex].buttons[buttonIndex].value = false;
        } else {
            data[parentIndex].value = data[parentIndex].value.concat(value, ' | ');
            data[parentIndex].buttons[buttonIndex].value = true;
        }
        let array = [];
        data[parentIndex].buttons.map(data => {
            if (data.value === true) {
                array += data.name + ' | ';
            }
        });

        if (data[parentIndex].value !== undefined) {
            data[parentIndex].value = array;
        }
        this.setState({ formJSON: data });
    }

    onCustomChangeHandler(
        value,
        index,
        parentIndex,
        date,
        display_vco_date_name
    ) {
        let data = this.state.formJSON;

        if (
            value &&
            display_vco_date_name == 'vcodate' &&
            index == 1 &&
            parentIndex == 14
        ) {
            data[14].children[0].value = 'PR Services';
            data[parentIndex].children[index].value = value;
        } else if (
            value == null &&
            display_vco_date_name == 'vcodate' &&
            index == 1 &&
            parentIndex == 14
        ) {
            data[14].children[0].value = '';
            data[parentIndex].children[index].value = value;
        } else {
            data[parentIndex].children[index].value = value;
        }

        this.setState({ formJSON: data });
    }

    onChangeFileHandler(file, acceptedSize) {
        if (!file) {
            // this.setState({ profile: null });
            // this.setState({ imgSrc: [] });
        } else {
            this.setState({ imgName: file.name });
            if (+acceptedSize * 1000 <= parseInt(file.size)) {
                this.setState({ isimageError: true });
                notify.show('Image File Too Large.', 'custom', 5000, {
                    background: '#f8d7da',
                    text: '#721c24'
                });
                return;
            } else {
                this.setState({ isimageError: false });
                this.setState({ profile: file });
                this.setState({ imgSrc: [] });
                const reader = new FileReader();
                const url = reader.readAsDataURL(file);
                reader.onloadend = function (e) {
                    this.setState({
                        imgSrc: [reader.result]
                    });
                }.bind(this);
            }
        }
    }

    onSubmitFormHandler(e, history) {
        e.preventDefault();
        let vcoData = [];
        let serviceData = [];

        if (this.state.vco && localStorage.getItem('userType') === '4') {
            vcoData = this.state.formJSON[11].children.filter(el => {
                if (el.tag === 'date') {

                    const dateObject = new Date(el.value);
                    dateObject.setHours(23, 59, 59, 999);
                    const fullDateFormat = dateObject.toISOString();

                    // return ( el.value === '' || new Date().getTime() >= new Date(el.value).getTime());
                    return ( el.value === '' || new Date().getTime() >= new Date(fullDateFormat).getTime());
                } else {
                    return false;
                }
            });

            serviceData = this.state.formJSON[11].children.filter(el => {
                if (el.tag === 'select') {
                    return el.value === '';
                } else {
                    return false;
                }
            });
        } else if (this.state.vco && localStorage.getItem('userType') === '5') {
            vcoData = this.state.formJSON[16].children.filter(el => {
                if (el.tag === 'date') {
                    // return (el.value === '' || new Date().getTime() >= new Date(el.value).getTime());

                    const dateObject = new Date(el.value);
                    dateObject.setHours(23, 59, 59, 999);
                    const fullDateFormat = dateObject.toISOString();

                    return ( el.value === '' || new Date().getTime() >= new Date(fullDateFormat).getTime());
                } else {
                    return false;
                }
            });
            serviceData = this.state.formJSON[16].children.filter(el => {
                if (el.tag === 'select') {
                    return el.value === '';
                } else {
                    return false;
                }
            });
        }

        if (this.state.vco && vcoData.length > 0) {
            return notify.show(
                'You cannot save an expired VCO. Remove field(s) or update to a current date.',
                'custom',
                5000,
                {
                    background: '#f8d7da',
                    text: '#721c24'
                }
            );
        } else if (this.state.vco && serviceData.length > 0) {
            return notify.show(
                'You cannot save an expired VCO. Remove field(s) or update to a current date.',
                'custom',
                5000,
                {
                    background: '#f8d7da',
                    text: '#721c24'
                }
            );
        } else if((this.state.formJSON[3].value == " " || this.state.formJSON[3].value == "") && localStorage.getItem('userType') === '4') {
            return notify.show(
                'Please select at least one service.',
                'custom',
                5000,
                {
                    background: '#f8d7da',
                    text: '#721c24'
                }
            );
        }

        this.prefillComponentValueSubmitHandler();
        if (this.state.isimageError) {
            notify.show('Please upload your profile image.', 'custom', 5000, {
                background: '#f8d7da',
                text: '#721c24'
            });
            return;
        }
        let formData = new FormData();

        if(localStorage.getItem('userType') === '4') {
            if(this.state.formJSON[11].children.length < 4) {
                this.state.formJSON[11].children = this.state.formJSON[11].childrenobj;
            }
        }
        formData.append('details', JSON.stringify(this.state.formJSON));
        formData.append('profile', this.state.profile);

        const headers = {
            'content-type': 'multipart/form-data',
            'x-authorization': localStorage.getItem('token')
        };

        this.props.previewProfile(
            '/api/v1/preview/user/profile',
            headers,
            formData
        );
    }

    saveProfile(e) {
        // e.preventDefault();
        this.prefillComponentValueSubmitHandler();
        const isEdit = this.props.profileData ? true : false;

        if (this.state.isimageError) {
            notify.show('Please upload your profile image.', 'custom', 5000, {
                background: '#f8d7da',
                text: '#721c24'
            });
            return;
        }
        let formData = new FormData();
        formData.append('details', JSON.stringify(this.state.formJSON));
        formData.append('profile', this.state.profile);

        //this.getJSONFormData(); ytrmz
        const headers = {
            'content-type': 'multipart/form-data',
            'x-authorization': localStorage.getItem('token')
        };
        this.props.saveUserProfile(
            '/api/v1/profile/setup',
            headers,
            formData,
            this.history,
            isEdit
        );
    }

    prefillComponentValueSubmitHandler() {
        this.arrprefillComponentValues.forEach(element => {
            let data = this.state.formJSON;

            if (
                data[element.split(',')[2]].children[element.split(',')[1]] &&
                data[element.split(',')[2]].children[element.split(',')[1]].value === ''
            ) {
                data[element.split(',')[2]].children[
                    element.split(',')[1]
                ].value = element.split(',')[0];
                this.setState({ formJSON: data });
            }
        });
    }
    addEmoji = e => {
        let data;
        if (localStorage.getItem('membershipId') === '5') {
            data = this.state.formJSON;
            data[0].value = data[0].value + e.native;
        } else if (localStorage.getItem('membershipId') === '6') {
            data = this.state.formJSON;
            data[0].value = data[0].value + e.native;
        } else {
            data = this.state.formJSON;
            data[1].value = data[1].value + e.native;
        }

        this.setState({
            chosenEmoji: false
            //formJSON: data
        });
    };
    addMoreLinkHandler(parentIndex, index) {
        let data = this.state.formJSON;
        data[parentIndex].children.push(data[parentIndex].children[index]);
        let el = {
            tag: 'input',
            type: 'text',
            className: 'form-control in_form_control',
            placeholder: 'link',
            value: '',
            validation: false,
            name: ''
        };
        data[parentIndex].children[index] = el;
        data[parentIndex].children[index].name = data[parentIndex].linkname + (parseInt(data[parentIndex].links) + 1);
        data[parentIndex].links = parseInt(data[parentIndex].links) + 1;

        this.setState({ formJSON: data });
    }

    addMoreServiceHandler(parentIndex, index) {
        let data = this.state.formJSON;
        let addMoreBtn = data[parentIndex].children[data[parentIndex].children.length - 1];
        let minusBtn = { tag: 'minus', type: 'service', placeholder: 'Remove' };
        let arrChildData = data[parentIndex].children;
        console.log(data[parentIndex], 'data[parentIndex]')
        arrChildData.splice(index, 1);
        if (
            data[parentIndex].childrenobj == undefined ||
            data[parentIndex].childrenobj.length === 0
        ) {
            data[parentIndex].childrenobj = [];
            data[parentIndex].childrenobj = data[parentIndex].childrenobj.concat(
                arrChildData
            );
        }

        let arrChildData_deepcopy = JSON.parse(
            JSON.stringify(data[parentIndex].childrenobj)
        );
        arrChildData_deepcopy.forEach(function (el, i) {
            if (
                data[parentIndex].children.count != undefined &&
                i > data[parentIndex].children.count
            ) {
                return;
            }
            el.name += '_' + data[parentIndex].service;
            el.value = '';
            if (!(el.tag === 'minus' && arrChildData_deepcopy[i - 1].tag === 'minus'))
                data[parentIndex].children = data[parentIndex].children.concat([el]);
        });
        // Increase service count
        data[parentIndex].service = parseInt(data[parentIndex].service) + 1;

        // data[parentIndex].children = data[parentIndex].children.concat(data[parentIndex].childrenobj);
        minusBtn.index = data[parentIndex].children.length;
        if (data[parentIndex].children.length > 0 && 
            !(
                data[parentIndex].children[data[parentIndex].children.length - 1].tag === 'minus'
            )
        )
            if (parentIndex !== 11) {
                // data[parentIndex].children.push(minusBtn);
            }

        if (parentIndex !== 11) {
            data[parentIndex].children.push(addMoreBtn);
        }
        this.setState({ formJSON: data });
    }

    handleEmojiPicker = e => {
        this.setState({ chosenEmoji: !this.state.chosenEmoji });
    };

    //======================= On Change Handlers ENDS ================

    //======================= Common functions ====================

    getJSONFormData() {
        const formData = new FormData();
        this.state.formJSON.map(el => {
            if (el.name) {
                if (el.tag === 'file') formData.append(el.name, this.state.profile);
                else if (el.tag === 'custom') {
                    let data = this.getCustomElementObject(el.children);

                    formData.append(el.name, data);
                } else formData.append(el.name, el.value);
            }
        });
        return formData;
    }

    getCustomElementObject(children) {
        let data = {};
        children.map(el => {
            if (el.name) {
                data[el.name] = el.value;
            }
        });
        return JSON.stringify(data);
    }

    //======================= Common functions ENDS ====================

    //======================= Custom DOM Providers ================

    getCheckBoxes(attributes, parentIndex, IsUnderCustom) {
        return attributes.buttons.map((el, i) => {
            var checkboxid = 'exampleCheck' + i;
            return (
                <div
                    className="custom-control custom-checkbox white col-md-4 "
                    style={{ float: 'left' }}
                >
                    <input
                        type="checkbox"
                        checked={
                            attributes.value !== undefined &&
                            attributes.value.includes(el.name)
                        }
                        onChange={() =>
                            this.onCheckboxChangeHandler(el.name, parentIndex, i)
                        }
                        id={checkboxid}
                        style={{ display: 'none' }}
                    />
                    <label htmlFor={checkboxid} className="checkbox_label_black">
                        <div className="mark"></div>
                    </label>
                    <p>
                        {el.name === 'Book signing organizers'
                            ? 'Book signing organizer'
                            : el.name}
                    </p>
                </div>

                // <div className="lft" style={{ marginTop: "10px" }}>
                //  <input type="checkbox" checked={this.state.formJSON[parentIndex].value !== undefined && this.state.formJSON[parentIndex].value.includes(el.name)}
                //     onChange={() => this.onCheckboxChangeHandler(el.name, parentIndex)} id={checkboxid} />

                //         <span>{el.name}</span>
                // </div>
            );
        });
    }

    getRadioButtons(attributes, index, parentIndex, IsUnderCustom) {
        return attributes.buttons.map((el, i) => {
            return (
                <label key={el.name} className="raemail">
                    <input
                        type="radio"
                        name={attributes.name}
                        checked={this.state.formJSON[index].value === el.name}
                        onChange={() => this.onChangeHandler(el.name, index)}
                        required
                    />{' '}
                    {el.name}
                </label>
            );
        });
    }

    getTextAreaElement(attributes, index, parentIndex, IsUnderCustom) {
        return (
            // <input
            <textarea
                type={attributes.type}
                value={attributes.value ? attributes.value : ''}
                className={attributes.className}
                placeholder={
                    attributes.placeholder === 'Add your bio here'
                        ? 'Add your bio here.'
                        : attributes.placeholder === 'Add your author bio here'
                            ? 'Add your author bio here.'
                            : attributes.placeholder
                }
                onChange={e =>
                    IsUnderCustom
                        ? this.onCustomChangeHandler(e.target.value, index, parentIndex)
                        : this.onChangeHandler(e.target.value, index)
                }
                required={attributes.validation}
                maxLength={attributes.maxLength ? attributes.maxLength : 10000}
            ></textarea>
            // />
        );
    }

    getTitleElement(attributes) {
        return (
            <label>
                {attributes.title === 'Profile summary'
                    ? 'Profile summary(Brief description of services)*'
                    : attributes.title}
            </label>
        );
    }

    //======================= Custom DOM Providers ENDS ================

    //======================= Custom Component Providers ================

    getCustomComponent(attributes, parentIndex, tit) {
        let pos = 0;

        let getValue;

        return attributes.children.map((el, index) => {
            if (
                (el.tag === 'select' &&
                    el.placeholder === 'Select Profile' &&
                    el.value !== 'undefined') ||
                (el.tag === 'select' &&
                    el.placeholder === 'Type' &&
                    el.value !== 'undefined')
            ) {
                getValue = el.value;
            }
            switch (el.tag) {
                case 'input':
                    return (
                        <div
                            className={pos % 2 === 0 ? 'rght' : 'lft'}
                            key={index + '_' + parentIndex}
                            style={
                                tit === 'PR Fan groups' && el.placeholder === 'Name'
                                    ? index === 0
                                        ? { float: 'revert' }
                                        : { float: 'revert', marginBottom: 12 }
                                    : {}
                            }
                        >
                            {/* <label>{el.placeholder}</label> */}
                            <TextBox
                                attributes={el}
                                IsUnderCustom={true}
                                onCustomHandler={this.onCustomChangeHandler}
                                onChangeTextHandler={this.onChangeHandler}
                                index={index}
                                parentIndex={parentIndex}
                                workingIndex={getValue}
                            />
                        </div>
                    );
                case 'select':
                    return (
                        <div className={`${index} ${pos % 2 === 0 ? 'rght' : 'lft'}`} key={index + '_' + parentIndex}>
                            <SelectBox
                                attributes={el}
                                IsUnderCustom={true}
                                onCustomHandler={this.onCustomChangeHandler}
                                onChangeTextHandler={this.onChangeHandler}
                                index={index}
                                parentIndex={parentIndex}
                            />
                        </div>
                    );
                case 'title':
                    return (
                        <div className="full_title" key={index}>
                            {this.getTitleElement(el)}
                        </div>
                    );

                case 'date':
                    return (
                        <div className="lft" key={index}>
                            <div className="form-group">
                                <DateBox
                                    attributes={el}
                                    index={index}
                                    IsUnderCustom={true}
                                    parentIndex={parentIndex}
                                    onCustomHandler={this.onCustomChangeHandler}
                                    onChangeDateHandler={this.onChangeHandler}
                                    minDate={
                                        localStorage.getItem('userType') === '4' ||
                                            localStorage.getItem('userType') === '5'
                                            ? moment()
                                                .clone()
                                                .add(1, 'days')
                                                .toDate()
                                            : false
                                    }
                                    maxDate={
                                        localStorage.getItem('userType') === '4' ||
                                            localStorage.getItem('userType') === '5'
                                            ? moment()
                                                .clone()
                                                .add(18, 'M')
                                                .toDate()
                                            : false
                                    }
                                // startDate={this.startDate}
                                />
                            </div>
                            <div style={{ display: 'none' }}>
                                {/* {
                                    (this.arrprefillComponentValues = this.arrprefillComponentValues.concat(
                                        [
                                        (el.value === '' ? new Date().toDateString() : el.value) +
                                            ',' +
                                            index +
                                            ',' +
                                            parentIndex
                                        ]
                                    ))
                                } */}
                            </div>
                        </div>
                    );

                case 'service':
                    return (
                        <div className="rght" key={index}>
                            <div className="form-group">
                                <TextBox
                                    attributes={el}
                                    IsUnderCustom={true}
                                    onCustomHandler={this.onCustomChangeHandler}
                                    onChangeTextHandler={this.onChangeHandler}
                                    index={index}
                                    parentIndex={parentIndex}
                                />
                            </div>
                        </div>
                    );

                case 'add':
                    return (
                        <div className="ft_text add-more-btn" key={'plus' + index}>
                            <span
                                onClick={() => {
                                    el.type == 'service'
                                        ? this.addMoreServiceHandler(parentIndex, index, el.type)
                                        : this.addMoreLinkHandler(parentIndex, index);
                                }}
                            >
                                {el.placeholder}
                                <img
                                    src={require('../../Utils/Assets/plus.png')}
                                    className="addMore"
                                />{' '}
                            </span>
                        </div>
                    );

                case 'minus':
                    return (
                        <>
                            <div
                                className={`ft_text vco-remove-btn ${index}`}
                                key={'plus' + index}
                            >
                                <span
                                    onClick={() => {
                                        this.removeServiceHandler(parentIndex, index);
                                    }}
                                >
                                    {el.placeholder} <i className="fa fa-minus-circle" />
                                </span>
                            </div>
                        </>
                    );

                case 'blank':
                    return (
                        <div
                            class=" col-md-0 d-none d-md-block"
                            style={{ minHeight: '100px' }}
                            key={index}
                        >
                            <div className="form-group"></div>
                        </div>
                    );
            }
        });
    }

    handleReset = (e, el, index, title) => {
        e.preventDefault();
        el.value = '';
        this.setState({ imgSrc: [] });
        this.setState({ imgName: 'No file chosen' });
        this.setState({ profile: null });
    };
    //======================= Custom Component Providers ENDS ================

    getFormElements() {
        let hostUrl = window.location.href;
        let breakHostUrl = hostUrl.split('/');
        let breakFromForm = breakHostUrl[4];
        return this.state.formJSON.map((el, index) => {
            (el && el.tag === 'title') || (el && el.tag === 'custom') ? (position = 0) : position++;
            switch (el && el.tag) {
                case 'blank':
                    return <div class="col-md-6" key={index}></div>;

                case 'input':
                    return (
                        <div
                            class={`col-md-6 nh ${el.placeholder === 'Website link' ||
                                    el.placeholder === 'Website'
                                    ? 'mb-3'
                                    : ''
                                }`}
                            key={index}
                        >
                            <div className="form-group">
                                <b>
                                    <label>
                                        {index == 80
                                            ? ''
                                            : el.placeholder === 'Email address'
                                                ? 'Email address*:'
                                                : el.placeholder === 'Goodreads profile link'
                                                    ? 'Goodreads*:'
                                                    : el.placeholder === 'Author middle name'
                                                        ? 'Author middle name:'
                                                        : el.placeholder === 'Website' &&
                                                            localStorage.getItem('userType') === '4'
                                                            ? 'Website*:'
                                                            : el.placeholder === 'Alias'
                                                                ? 'Alias:'
                                                                : el.placeholder === 'Newsletter sign up'
                                                                    ? 'Newsletter sign up:'
                                                                    : el.placeholder === 'Master ARC list sign up'
                                                                        ? 'Master ARC list sign up:'
                                                                        : el.placeholder ===
                                                                            'Best site for readers to connect with you?'
                                                                            ? 'Best site for readers to connect with you?*'
                                                                            : el.placeholder === 'Company name'
                                                                                ? 'Company name*:'
                                                                                : el.placeholder === 'Website'
                                                                                    ? 'Website*:'
                                                                                    : el.placeholder === 'Blog'
                                                                                        ? 'Blog*:'
                                                                                        : el.placeholder === 'Public blog email'
                                                                                            ? 'Public blog email (displayed on OTR for all members)*:'
                                                                                            : el.placeholder === 'Goodreads profile link'
                                                                                                ? 'Goodreads:'
                                                                                                : el.placeholder === 'Blog name'
                                                                                                    ? 'Blog name*:'
                                                                                                    : el.placeholder === 'Blog email'
                                                                                                        ? 'Blog email (displayed on Bloggers list viewable by all authors)*:'
                                                                                                        : el.placeholder === 'Blog link'
                                                                                                            ? 'Blog link*:'
                                                                                                            : el.placeholder === 'PR firm name'
                                                                                                                ? // ? 'PR Firm name*:'
                                                                                                                'Company name*:'
                                                                                                                : el.placeholder === 'Are you accepting new clients?'
                                                                                                                    ? 'Are you accepting new clients?*'
                                                                                                                    : el.placeholder ===
                                                                                                                        'Publicly display email on profile page?'
                                                                                                                        ? 'Publicly display email on profile page?*'
                                                                                                                        : el.placeholder === 'Website link'
                                                                                                                            ? 'Website link*:'
                                                                                                                            : el.placeholder ===
                                                                                                                                'Describe your skills in max 30 characters to be displayed in vendor list.'
                                                                                                                                ? ''
                                                                                                                                : el.placeholder === 'Author first name'
                                                                                                                                    ? 'Author first name*:'
                                                                                                                                    : el.placeholder === 'Author last name'
                                                                                                                                        ? 'Author last name*:'
                                                                                                                                        : ''}
                                    </label>
                                </b>

                                <TextBox
                                    attributes={el}
                                    IsUnderCustom={false}
                                    onCustomHandler={this.onCustomChangeHandler}
                                    onChangeTextHandler={this.onChangeHandler}
                                    onChangeEmoji={this.handleEmojiPicker}
                                    onSelectEmoji={this.addEmoji}
                                    chosenEmoji={this.state.chosenEmoji}
                                    index={index}
                                    parentIndex={0}
                                    urlLink={breakFromForm}
                                />
                            </div>
                        </div>
                    );
                case 'select':
                    return (
                        <div class="col-md-6" key={index}>
                            <div className="form-group">
                                <SelectBox
                                    attributes={el}
                                    IsUnderCustom={true}
                                    onCustomHandler={this.onCustomChangeHandler}
                                    onChangeTextHandler={this.onChangeHandler}
                                    index={index}
                                    parentIndex={0}
                                />
                            </div>
                        </div>
                    );
                case 'title':
                    return (
                        <div
                            className={`full_title ${el.title ===
                                'Please add your blog info to be added to Bloggers list viewable by all author members' &&
                                'mb-2'}`}
                            key={index}
                        >
                            {el.title ===
                                'To edit, go to your profile page, and click on edit box in the upper right corner.' ? (
                                <i>
                                    {el.title ===
                                        'To edit, go to your profile page, and click on edit box in the upper right corner.'
                                        ? 'To edit, go to your profile page, and click on edit box in the lower right corner.'
                                        : el.title}
                                </i>
                            ) : (
                                <b>
                                    <label>
                                        {el.title === 'Profile summary'
                                            ? 'Profile summary (Brief description of services)*'
                                            : el.title == 'Services offered (select all that apply)'
                                                ? 'Services offered (select all that apply)*'
                                                : el.title === 'Recent clients/ works'
                                                    ? 'Recent clients/works'
                                                    : el.title ===
                                                        'Best site for readers to interact with you'
                                                        ? 'Best site for readers to interact with you*'
                                                        : el.title === 'Contact '
                                                            ? 'Contact'
                                                            : el.title}
                                    </label>
                                    :
                                </b>
                            )}
                        </div>
                    );
                case 'textarea':
                    return (
                        <div className="col-md-12" key={index}>
                            <div className="form-group">
                                {this.getTextAreaElement(el, index, 0, false)}
                            </div>
                        </div>
                    );
                case 'file':
                    if (el.value && this.state.imgSrc.length == 0) {
                        this.state.imgSrc = `${localStorage.getItem('baseUrl')}${el.value}`;
                    }
                    return (
                        <div class="col-md-6" key={index}>
                            <span style={{ color: '#1f1f1f', fontWeight: '600' }}>
                                Upload Avatar:{' '}
                            </span>
                            <div className="form-group" style={{ display: 'flex', height: '40px' }}>
                                <FileBoxVendor
                                    attributes={el}
                                    index={index}
                                    parentIndex={0}
                                    fileName={this.state.imgName}
                                    onFileHandler={this.onChangeFileHandler}
                                />

                                {this.state.imgSrc ? (
                                    <div
                                        className={`editProfile-img ${localStorage.getItem('membershipId') === '1' ||
                                                localStorage.getItem('membershipId') === '8'
                                                ? 'authorImg'
                                                : localStorage.getItem('membershipId') === '4'
                                                    ? 'vendorImg'
                                                    : localStorage.getItem('membershipId') === '5'
                                                        ? 'prFirmImg'
                                                        : localStorage.getItem('membershipId') === '6'
                                                            ? 'bloggerImg'
                                                            : ''
                                            }`}
                                    >
                                        {this.state.imgSrc.length > 0 && (
                                            <img
                                                className="edit-profile-img"
                                                src={this.state.imgSrc}
                                            ></img>
                                        )}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="d-flex resetAuth-section">
                                <button
                                    type="submit"
                                    className="btn reset-cover-btn"
                                    onClick={e => this.handleReset(e, el, index, el.title)}
                                >
                                    RESET UPLOAD
                                </button>
                            </div>
                        </div>
                    );
                case 'radio':
                    return (
                        <>
                            {index == 13 ? (
                                <div class="col-md-12" key={index}>
                                    <div className="form_group form-group radio-label">
                                        <span style={{ color: '#1f1f1f', fontWeight: '600' }}>
                                            {el.placeholder === 'Are you accepting new clients?*'
                                                ? 'Are you accepting new clients?*'
                                                : el.placeholder}
                                            {/* {el.placeholder} */}
                                        </span>
                                        {this.getRadioButtons(el, index, 0, false)}
                                    </div>
                                </div>
                            ) : (
                                <div
                                    class={`${el.placeholder === 'Are you accepting new clients?*'
                                            ? 'col-md-12'
                                            : 'col-md-6 d-flex justify-content-center flex-column'
                                        }`}
                                    key={index}
                                >
                                    <div
                                        className={`form-group ${el.placeholder === 'Are you accepting new clients?*'
                                                ? ''
                                                : 'radio-label'
                                            } ${el.placeholder ===
                                                'Publicly display email on profile page?:'
                                                ? 'mt-0 m-0'
                                                : ''
                                            }`}
                                    >
                                        <span style={{ color: '#1f1f1f', fontWeight: '600' }}>
                                            {el.placeholder ==
                                                'Publicly display email on profile page?'
                                                ? 'Publicly display email on profile page?*'
                                                : el.placeholder === 'Are you accepting new clients? '
                                                    ? 'Are you accepting new clients?*'
                                                    : el.placeholder ==
                                                        'Publicly display email on profile page?:'
                                                        ? 'Publicly display email on profile page?*:'
                                                        : el.placeholder === 'Are you accepting new clients?*'
                                                            ? 'Are you accepting new clients?*'
                                                            : el.placeholder}
                                        </span>
                                        {this.getRadioButtons(el, index, 0, false)}
                                    </div>
                                </div>
                            )}
                        </>
                    );

                case 'checkbox':
                    return (
                        <div
                            key={index}
                            className={`${(localStorage.getItem('membershipId') === '4' ||
                                    localStorage.getItem('membershipId') === '5') &&
                                    index === 3
                                    ? 'mb-3'
                                    : '-'
                                }`}
                        >
                            {this.getCheckBoxes(el, index, 0, false)}
                        </div>
                    );
                case 'custom':
                    return (
                        <div className="col-md-12" key={index}>
                            <div className="form-group fam_gp mmmm">
                                <div className="in_form">
                                    <div className="in_title 342234">
                                        {' '}
                                        <label>
                                            {el.title === 'Where to purchase'
                                                ? 'Purchase sites'
                                                : el.title === 'More profiles'
                                                    ? 'More Social media profiles'
                                                    : el.title === 'PR Fan groups'
                                                        ? 'Fan groups'
                                                        : el.title}
                                            :
                                        </label>{' '}
                                    </div>
                                    {this.getCustomComponent(el, index, el.title)}
                                </div>
                            </div>
                        </div>
                    );
                case 'customdate':
                    return (
                        <div className="col-md-12" key={index}>
                            <div className="form-group fam_gp hhhhh">
                                <div
                                    className={`in_form ${el.title ===
                                            'Currently featured on "Vendor Cancellation Openings (VCO) list"'
                                            ? 'vco-section'
                                            : ''
                                        }`}
                                >
                                    <div className="in_title">
                                        <span>
                                            {el.placeholder ===
                                                'Do you have any work openings, Vendor Cancellation Openings (VCO) to be indicated on Vendor list?'
                                                ? 'Vendor Cancellation Openings (VCO)'
                                                : el.placeholder}
                                        </span>
                                    </div>
                                    {el.title ===
                                        'Currently featured on "Vendor Cancellation Openings (VCO) list"' ? (
                                        <div className="vendor-openings">
                                            <div class={`form-group`}>
                                                <span>
                                                    Do you have any VCO work openings to be indicated on
                                                    the OTR Vendor list?*
                                                </span>
                                                <label class="vco-openings">
                                                    <input
                                                        type="radio"
                                                        name="vco-opening"
                                                        checked={this.state.vco === true}
                                                        required=""
                                                        onChange={event => this.hadleVcoChange(true)}
                                                    />{' '}
                                                    Yes
                                                </label>
                                                <label class="vco-openings">
                                                    <input
                                                        type="radio"
                                                        name="vco-opening"
                                                        checked={this.state.vco === false}
                                                        required=""
                                                        onChange={() => this.hadleVcoChange(false)}
                                                    />{' '}
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    ) : null}
                                    {this.state.vco ? this.getCustomComponent(el, index) : null}
                                </div>
                            </div>
                        </div>
                    );
            }
        });
    }

    hadleVcoChange = bool => {
        let data = this.state.formJSON;

        console.log(bool, 'bool')

        if (localStorage.getItem('membershipId') === '4') {
            if(data[11].children.length > 0) {
                data[11].children = [
                    ...data[11].children.slice(0, 3),
                    data[11].children[data[11].children.length - 1]
                ];
                if (!bool && data[11].children.length) {
                    // data[11].children.forEach(el => {
                    //     el.value = '';
                    // });
                    data[11].children = data[11].childrenobj;
                }
            } else {
                data[11].children = data[11].childrenobj;
            }
            data[11].children.forEach(el => {
                el.value = '';
            });
        } else {
            data[16].children = [
                ...data[16].children.slice(0, 3),
                data[16].children[data[16].children.length - 1]
            ];

            if (!bool && data[16].children.length) {
                data[16].children.forEach(el => {
                    el.value = '';
                });
            }
            data[16].children.forEach(el => {
                el.value = '';
            });
        }
        //   el.value===""
        // }));
        this.setState({ vco: bool });
    };

    //===================== RENDER ==========================
    render() {
        let type;

        if (localStorage.getItem('membershipId') === '1') {
            type = 'Author Premium';
        }
        if (localStorage.getItem('membershipId') === '2') {
            type = 'Reader';
        }
        if (localStorage.getItem('membershipId') === '3') {
            type = 'Blogger';
        }
        if (localStorage.getItem('membershipId') === '4') {
            type = 'Vendor';
        }
        if (localStorage.getItem('membershipId') === '5') {
            type = 'PR Firm';
        }
        if (localStorage.getItem('membershipId') === '6') {
            type = 'Blogger Premium';
        }
        if (localStorage.getItem('membershipId') === '8') {
            type = 'Author';
        }

        return (
            <>
                {this.state.formJSON.length > 0 ? (
                    <div className="main-wrapper">
                        <div className="sidebar">
                            <LeftLogo history={this.props.history} />
                        </div>
                        <div className={`main-content profile-setup`}>
                            <div className="header-secion">
                                <div className="container">
                                    <div className="setup_p_hed">
                                        <h4>
                                            {' '}
                                            Set-up Profile:{' '}
                                            {this.props.userType === 'Blogger' && localStorage.getItem('membershipId') === '6' ? 'Blogger Premium' : type === 'PR Firm' || this.props.userType === 'PR Firm' || localStorage.getItem('membershipId') === '5' ? 'PR Firm/Bookstore'  : this.props.userType ? this.props.userType : type}
                                        </h4>
                                        <a href="/">
                                            <button
                                                type="button"
                                                className="btn"
                                                onClick={() => {
                                                    localStorage.clear();
                                                    return <Redirect to="/" push />;
                                                }}
                                            >
                                                <span>LogOut</span>
                                                <img
                                                    className="logout_img"
                                                    src={require('../../Utils/Assets/logout.png')}
                                                    alt="join"
                                                />
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="middle-content ">
                                <div
                                // className={
                                //   props.displayMode
                                //     ? "main_right author_profile"
                                //     : "main_right author_profile calendar-dark"
                                // }
                                // style={{ flex: "1" }}
                                >
                                    <div className="container">
                                        <div className="set-up-profile mt-4">
                                            <Route
                                                render={({ history }) => (
                                                    <form className="" onSubmit={e => this.onSubmitFormHandler(e, history)}
                                                    >
                                                        {' '}
                                                        <div class="row">
                                                            {this.state.formJSON ? this.getFormElements() : ''}
                                                        </div>
                                                        <div class="row">
                                                            <div className="full_title">
                                                                <div className="form-group text-right">
                                                                    <button
                                                                        type="submit"
                                                                        className="btn float-md-right new_btn_save_bt m-0"
                                                                        style={{
                                                                            marginRight: '0% !important'
                                                                        }}
                                                                    >
                                                                        Save
                                                                    </button>
                                                                    <button type="submit" onClick={() => this.props.history.push('/dashboard/my-profile')} className="btn float-md-right new_btn_save_bt m-0" style={{ marginRight: '0% !important' }}>
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                )}
                                            ></Route>
                                        </div>
                                    </div>

                                    {this.props.preview.length > 0 ? (
                                        <UserProfileModal
                                            isOpen={this.props.preview.length > 0}
                                            userKeyData={this.props.preview}
                                            data={'jivabn'}
                                            preview={true}
                                            history={this.props.history}
                                            confirmSave={e => this.saveProfile(e)}
                                            displayEmail={this.state.displayEmail}
                                        />
                                    ) : null}
                                </div>
                            </div>
                            <div className="footer">
                                <Footer />
                            </div>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </>
        );
    }

    componentDidMount = () => {
        localStorage.setItem('mode', 'edit');
        if (localStorage.getItem('token') && this.props.profileData == '') {
            this.props.getUserProfile('/api/v1/user/profile', {
                headers: { 'x-authorization': localStorage.getItem('token') }
            });
        } else if (
            this.props.profileData &&
            this.props.profileData.profile['profile_details_with_values'] &&
            localStorage.getItem('mode') !== 'edit'
        ) {
            globalHistory.push('/dashboard');
        } else {
            //  this.props.history.push("/log-in")
            if (localStorage.getItem('mode') == 'edit') {
                let minusBtn = { tag: 'minus', type: 'service', placeholder: 'Remove' };
                // let addMoreBtn = this.props.profileData.profile.user_details[11]
                //   .children[
                //   this.props.profileData.profile.user_details[11].children.length - 1
                // ];
                // this.props.profileData.profile.user_details[11].children.push(
                //   addMoreBtn
                // );

                //   this.setState({
                //     formJSON: this.props.profileData.profile.user_details
                //   });
                // }

                this.setState({
                    formJSON: this.props.profileData.profile.user_details
                });
                if (localStorage.getItem('membershipId') === '4') {
                    let VCODATA = this.props.profileData.profile.user_details[11]
                        .children;
                    if (VCODATA && VCODATA[0].value !== '') {
                        this.setState({ vco: true });
                    }
                } else {
                    let VCODATA = this.props.profileData.profile.user_details[16] ? this.props.profileData.profile.user_details[16].children : null;
                    if (VCODATA && VCODATA[0].value !== '') {
                        this.setState({ vco: true });
                    }
                }
            } else {
                localStorage.clear();
            }
        }
    };

    componentWillReceiveProps() {
        if (localStorage.getItem('token') && !this.props.profileData) {
            this.setState({ formJSON: this.props.formJSON });
        } else {
            // this.setState({ formJSON: this.props.profileData.profile.user_details })
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.profileLoading,
        formJSON: state.profileData,
        userType: state.userType,
        preview: state.previewProfile,
        profileData: state.myProfileData
    };
};

const mapDispatchToProps = dispatch => {
    return {
        saveUserProfile: (url, headers, data, history, edit) =>
            dispatch(saveProfile(url, headers, data, history, edit)),
        getUserProfile: (url, headers) => dispatch(getProfile(url, headers)),
        previewProfile: (url, headers, data) =>
            dispatch(previewUserProfile(url, headers, data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
