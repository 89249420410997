export function dailyTask(state = {}, action) {
  switch (action.type) {
    case 'DAILY_TASK':
      return action.data;
    default:
      return state;
  }
}

export function filterTask(state = [], action) {
  switch (action.type) {
    case 'TASK_FILTER':
      return action.data;
    default:
      return state;
  }
}

export function addTab(state = null, action) {
  switch (action.type) {
    case 'ADD_TAB':
      return action.data;
    default:
      return state;
  }
}
