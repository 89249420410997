import React from 'react';
// import LeftLogo from './left-logo';
// import Header from '../Pages/Profile/header';
import Layout from '../Pages/layout/layout';

const BlankPage = () => {
  return (
    // <div className="main-wrapper">
    //   <div className="sidebar">
    //     <LeftLogo />
    //   </div>
    //   <div

    //     className={`main-content ${
    //       localStorage.getItem('token') ? 'add-header' : 'remove-header'
    //     }`}
    //   >
    //     <div className="header-secion">
    //       {localStorage.getItem('token') ? <Header /> : ''}
    //     </div>
    <Layout>
      <div className={`profile-detail-page no-profile-page py-4`}>
        <div className="container">
          <p className="text-center">
            <strong>No profile created.</strong>
          </p>
        </div>
      </div>
    </Layout>
    //   </div>
    // </div>
  );
};

export default BlankPage;
