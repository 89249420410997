import React, { useEffect } from 'react';
import shave from 'shave';
// import user from '../../../Utils/Assets/user_s.png';
import user from '../../../Utils/Assets/OTR_Default-avatar_v3.png';

import './ConversationListItem.css';

export default function ConversationListItem(props) {
  /* useEffect(() => {
    shave('.conversation-snippet', 20);
  }) */

  const { image, name, msg_id, unseen, user_details } = props.data;
  console.log('user_details', user_details);

  console.log(
    'iiiiiiiiiiii',
    props.data.membership_id,
    typeof props.data.membership_id
  );

  let midName = JSON.parse(user_details).filter(
    el => el.name == 'middlename'
  )[0];

  console.log('midName', midName, name);
  var nameParts = name.split(' ');

  // Insert the middle name in the middle of the array
  if (midName) {
    nameParts.splice(1, 0, midName.value);
  }
  // Join the array back into a single string
  var newName = nameParts.join(' ');

  console.log('new name', newName);

  //set the first message selected initially
  if (props.index === 0 && !localStorage.getItem('activeMsg')) {
    localStorage.setItem(
      'activeMsg',
      JSON.stringify({ image, newName, msg_id, unseen })
    );
  }
  // console.log("{ image, name, msg_id, unseen }-----> ", { image, name, msg_id, unseen });
  const activeMember = props.activeMember;
  const messageCount = props.messageCount;

  const pathImg = `${localStorage.getItem('baseUrl')}${
    image ? image.split('\\').join('/') : ''
  }`;

  let active =
    localStorage.getItem('msgId') == msg_id ? !activeMember : activeMember;

  setTimeout(() => {
    var elmnt = document.getElementsByClassName('active-list');
    if (elmnt.length === 1) {
      elmnt[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, 200);

  const activeMsgId = JSON.parse(localStorage.getItem('activeMsg')).msg_id;
  active = false;
  if (msg_id === activeMsgId) {
    active = true;
  }

  return (
    <>
      <div
        className={`conversation-list-item ${
          active === true ? 'active-list' : ''
        }`}
        onClick={e => {
          // console.log("called  the data----> ", )
          localStorage.setItem(
            'activeMsg',
            JSON.stringify({ image, name: newName, msg_id, unseen })
          );
          props.onChangeClickHandler(msg_id);
        }}
      >
        {pathImg ? (
          <div
            className={`conversation-photo ${
              props.data.membership_id === '1' ||
              props.data.membership_id === '8'
                ? 'authorImg'
                : props.data.membership_id === '4'
                ? 'vendorImg'
                : props.data.membership_id === '5'
                ? 'prFirmImg'
                : ''
            }`}
          >
            <img
              src={pathImg}
              alt=""
              onError={e => {
                e.target.onerror = null;
                e.target.src = user;
              }}
            />
          </div>
        ) : (
          <div
            className={`conversation-photo ${
              props.data.membership_id === '1' ||
              props.data.membership_id === '8'
                ? 'authorImg'
                : props.data.membership_id === '4'
                ? 'vendorImg'
                : props.data.membership_id === '5'
                ? 'prFirmImg'
                : ''
            }`}
          >
            <img
              src={user}
              alt=""
              onError={e => {
                e.target.onerror = null;
                e.target.src = user;
              }}
            />
          </div>
        )}
        <div className="conversation-info">
          <h6 className="conversation-title">
            {newName}{' '}
            {+unseen > 0 && messageCount > 0 && (
              <span className="badge badge-pill badge-danger convCount">
                <b>{unseen}</b>
              </span>
            )}
          </h6>
          {/* <p className="conversation-snippet">{name}</p> */}
        </div>
      </div>
    </>
  );
}
