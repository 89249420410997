import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  getUserList,
  setupEvent,
  AddBeta,
  RemoveBeta,
  getBeta,
  followUser,
  unFollowUser
} from '../../Actions/profile-setup';
import UserProfileModal from './userProfileModal';
import AddToBetaReader from './addBetaForm';
import AddMessage from '../messages/messageModal';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './usersList.css';
import { object } from 'prop-types';
import CONSTANT, { BASEURL } from '../../constant';
import axios from 'axios';
import { defaultSort } from '../../Utils/useFunction';
import Axios from 'axios';

class UsersListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usersList: [],
      profileData: {},
      showBetaModal: false,
      modal: false,
      show: false,
      userData: {},
      callListApi: false,
      isOpenPrivacy: false,
      betaReadersPopupOpen: false,
      userId: '',
      betaareader: '',
      columnDefs: [
        {
          headerName: 'Id',
          field: 'id',
          hide: true
        },
        /* {
           headerName: "USERNAME", field: "display_name"
         },*/
        {
          headerName: 'Name',
          field: 'userName',
          suppressMovable: true,
          unSortIcon: true,
          resizeable: true,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        /* {
           headerName: "COUNTRY", field: "country_name"
         },*/
        {
          // headerName: "EMAIL",
          // field: "email",
          // cellRenderer: this.checkEmailView.bind(this),
          headerName: 'OTR profile',
          field: 'follow_code',
          suppressMovable: true,
          cellRenderer: this.showPRProfile.bind(this)
        },
        {
          headerName: 'Member type',
          field: 'name',
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? true
              : false,
          cellRenderer: this.showAuthorProfile.bind(this)
        },
        // {
        //   headerName: "PR CODE",
        //   field: "follow_code",
        //   hide:
        //     this.props.history.location.pathname.split("/").pop() == "vco_list"
        //       ? true
        //       : false,
        // },
        {
          headerName: 'Follow',
          field: 'follow',
          suppressMovable: true,
          //unSortIcon: true,
          cellRenderer: this.showFollowButton.bind(this)
        },
        {
          headerName: 'Message',
          hide:
            this.props.history.location.pathname.split('/').pop() ===
            'most_followed'
              ? true
              : false,
          field: 'message',
          suppressMovable: true,
          //unSortIcon: true,
          cellRenderer: this.showMessageButton.bind(this)
        },
        {
          headerName: 'Services',
          field: 'Services',
          suppressMovable: true,
          unSortIcon: true,
          width: 100,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? false
              : true,
          suppressToolPanel: true,
          cellRenderer: this.showServices.bind(this)
        }
      ],
      columnDefMostFollowed: [
        {
          headerName: 'Id',
          field: 'id',
          hide: true
        },
        /* {
           headerName: "USERNAME", field: "display_name"
         },*/
        {
          headerName: 'Name',
          field: 'userName',
          suppressMovable: true,
          unSortIcon: true,
          resizeable: true,
          cellRenderer: this.nameCellRendererForBlogger.bind(this)
        },
        /* {
           headerName: "COUNTRY", field: "country_name"
         },*/
        {
          // headerName: "EMAIL",
          // field: "email",
          // cellRenderer: this.checkEmailView.bind(this),
          headerName: 'OTR profile',
          field: 'follow_code',
          suppressMovable: true,
          cellRenderer: this.showPRProfile.bind(this)
        },
        {
          headerName: 'Member type',
          field: 'member_type',
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? true
              : false,
          cellRenderer: this.showAuthorProfilemost.bind(this)
        },
        // {
        //   headerName: "PR CODE",
        //   field: "follow_code",
        //   hide:
        //     this.props.history.location.pathname.split("/").pop() == "vco_list"
        //       ? true
        //       : false,
        // },
        {
          headerName: 'Follow',
          field: 'follow',
          suppressMovable: true,
          //unSortIcon: true,
          cellRenderer: this.showFollowButton.bind(this)
        },
        {
          headerName: 'Message',
          hide:
            this.props.history.location.pathname.split('/').pop() ===
            'most_followed'
              ? true
              : false,
          field: 'message',
          suppressMovable: true,
          //unSortIcon: true,
          cellRenderer: this.showMessageButton.bind(this)
        },
        {
          headerName: 'Services',
          field: 'Services',
          suppressMovable: true,
          unSortIcon: true,
          width: 100,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? false
              : true,
          suppressToolPanel: true,
          cellRenderer: this.showServices.bind(this)
        }
      ],
      bloggerRedearcolumnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        /* {
           headerName: "USERNAME", field: "display_name"
         },*/
        {
          headerName: 'Name',
          // field: 'display_name',
          field: 'userName',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.nameCellRenderer.bind(this)
          // cellRenderer: this.nameCellRenderer.bind(this),
        },
        /* {
           headerName: "COUNTRY", field: "country_name"
         },*/
        {
          headerName: 'Email',
          field: 'email1',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.checkEmailView.bind(this)
        },
        {
          headerName: 'Member type',
          field: 'name',
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? true
              : false,
          cellRenderer: this.showAuthorProfile.bind(this)
        },
        {
          headerName: 'Follow',
          field: 'follow',
          suppressMovable: true,
          //unSortIcon: true,
          cellRenderer: this.showFollowButton.bind(this)
        },
        {
          headerName: 'Services',
          field: 'Services',
          width: 100,
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? false
              : true,
          suppressToolPanel: true,
          cellRenderer: this.showServices.bind(this)
        }
      ],
      allProfilescolumnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'Name',
          field: 'userName',
          suppressMovable: true,
          unSortIcon: true,
          resizeable: true,
          cellRenderer: this.nameCellRenderer.bind(this)
          // cellRenderer: this.nameCellRenderer.bind(this),
        },
        {
          // headerName: "EMAIL",
          // field: "email",
          // cellRenderer: this.checkEmailView.bind(this),
          headerName: 'OTR profile link',
          field: 'follow_code',
          suppressMovable: true,
          //unSortIcon: true,
          resizeable: true,
          cellRenderer: this.showPRProfile.bind(this)
        },
        {
          headerName: 'Member type',
          field: 'name',
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? true
              : false,
          cellRenderer: this.showAuthorProfile.bind(this)
        },
        {
          headerName: 'Follow',
          field: 'follow',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showFollowButton.bind(this)
        },
        {
          headerName: 'Message',
          field: 'message',
          suppressMovable: true,
          hide:
            +localStorage.getItem('membershipId') == 3 ||
            +localStorage.getItem('membershipId') == 6 ||
            +localStorage.getItem('membershipId') == 2
              ? true
              : false,
          //unSortIcon: true,
          cellRenderer: this.showMessageButton.bind(this)
        },
        {
          headerName: 'Services',
          field: 'Services',
          width: 100,
          suppressMovable: true,
          unSortIcon: true,
          hide:
            this.props.history.location.pathname.split('/').pop() == 'vco_list'
              ? false
              : true,
          suppressToolPanel: true,
          cellRenderer: this.showServices.bind(this)
        }
      ],
      VCOcolumnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        {
          headerName: 'Company name',
          field: 'userName',
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'OTR profile link',
          field: 'follow_code',
          cellRenderer: this.showPRProfile.bind(this),
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'Type of service',
          field: 'profile_details_with_values',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showServices.bind(this)
        },
        {
          headerName: 'Date',
          field: 'profile_details_with_values',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showDate.bind(this)
        }
      ],
      bloggercolumnDefs: [
        {
          headerName: 'Blog name',
          field: 'userName',
          unSortIcon: true,
          cellRenderer: this.showBlogName.bind(this)
        },
        {
          headerName: 'Blog link',
          field: 'profile_details_with_values',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showBlogLink.bind(this)
        },
        {
          headerName: 'Blog contact',
          field: 'contact',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showBlogContact.bind(this)
        },
        {
          headerName: 'OTR profile link',
          field: 'follow_code',
          suppressMovable: true,

          cellRenderer: this.showPRProfile.bind(this)
        },
        {
          headerName: 'Follow',
          field: 'follow',
          suppressMovable: true,
          cellRenderer: this.showFollowButtonBlogger.bind(this)
        }
        // {
        //   headerName: 'Message',
        //   field: 'message',
        //   suppressMovable: true,
        //   //unSortIcon: true,
        //   cellRenderer: this.showMessageButtonBlogger.bind(this)
        // },
      ],

      betacolumnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true
        },
        // {
        //   headerName: "Name",
        //   field: "name",
        // },
        {
          headerName: 'Public name',
          field: 'publicName',
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'Email',
          field: 'email',
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: ' Goodreads',
          field: 'goodreads',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showAsLink.bind(this)
        },
        {
          headerName: 'Amazon',
          field: 'amazon',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showAsLink.bind(this)
          // hide: this.props.history.location.pathname.split("/").pop() == 'last_minute_cancellations' ? true : false,
        },
        {
          headerName: 'Blog',
          field: 'blogLink',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showAsLink.bind(this)
          // hide: this.props.history.location.pathname.split("/").pop() == 'last_minute_cancellations' ? true : false,
        },
        {
          headerName: 'Title(s) read',
          field: 'title1',
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showTitle.bind(this)
          // hide: this.props.history.location.pathname.split("/").pop() == 'last_minute_cancellations' ? true : false,
        }
        // {
        //   headerName: "Services",
        //   field: "Services",
        //   width: 100,
        //   hide: this.props.history.location.pathname.split("/").pop() == 'last_minute_cancellations' ? false : true,
        //   suppressToolPanel: true,
        //   cellRenderer: this.showServices.bind(this)
        // }

        // {
        //   headerName: "MEMBER EXPIRY DATE", field: "member_expiry_date"
        // },
      ],

      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      showMenu: false
    };
    this.props.setupEvent();
    this.hideEvent = this.hideEvent.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  showDate(params) {
    let details = JSON.parse(params.data.profile_details_with_values);
    if (details) {
      const values = details.find(
        _d =>
          _d['Currently featured on Vendor Cancellation Openings (VCO) list']
      );
      let finalValues = [];
      if (
        values &&
        values['Currently featured on Vendor Cancellation Openings (VCO) list']
      ) {
        let data =
          values[
            'Currently featured on Vendor Cancellation Openings (VCO) list'
          ];
        data.map(val => {
          if (val) {
            val.map(key => {
              return finalValues.push(Object.values(key));
            });
          }
        });
        let user = {};
        user = finalValues.map(d => moment(d.toString()).format('DD/MM/YYYY'));
        return user.toString();
        // values.map(val=>{
        //   object.keys(val);
        // })
      }
    }
  }

  showPRProfile(params) {
    let details = params.data.profile_details_with_values;
    if (details) {
      if (this.props.displayMode) {
        return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Profile</button>`;
      } else {
        return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Profile</button>`;
      }
    } else {
      return '-';
    }
  }

  openModal() {
    this.setState({ showMessageModal: true });
  }

  hideMessageModal() {
    this.setState({ showMessageModal: false });
  }
  showBlogName(params) {
    let details = JSON.parse(params.data.profile_details_with_values);
    let BloggName = JSON.parse(params.data.user_details)[0].value;
    // console.log(
    //   'this is blogggg details==',
    //   JSON.parse(params.data.user_details)[0].value
    // );
    if (details) {
      if (details.find(_d => _d['Blog'])) {
        const values = details.find(_d => _d['Blog']);
        // console.log('thi is bloggg value', values);
        return values.Blog ? values.Blog : BloggName;
      } else {
        return BloggName ? BloggName : '-';
      }
    }
  }
  showBlogLink(params) {
    let details = JSON.parse(params.data.profile_details_with_values);
    if (details) {
      if (details.find(_d => _d['Blog link'])) {
        const values = details.find(_d => _d['Blog link']);
        return values['Blog link'];
      } else {
        return '-';
      }
    }
  }
  showAuthorProfile(params) {
    return params.data.name === 'Author Premium'
      ? 'Author'
      : params.data.member_type === 'PR Firm'
      ? 'PR Firm/Bookstore'
      : params.data.name;
  }

  showAuthorProfilemost(params) {
    if (params.data.member_type === 'Blogger') {
      return params.data.member_type === 'Blogger' ? 'Blogger Premium' : '';
    } else {
      if (params.data.member_type === 'PR Firm') {
        return params.data.member_type === 'PR Firm' ? 'PR Firm/Bookstore' : '';
      } else return params.data.member_type ? params.data.member_type : '';
    }
  }
  showBlogOTRLink(params) {
    let details = JSON.parse(params.data.profile_details_with_values);
    if (details) {
      if (details.find(_d => _d['Best site to interact with us'])) {
        const values = details.find(_d => _d['Best site to interact with us']);
        return values['Best site to interact with us'];
      } else {
        return '-';
      }
    }
  }
  showBlogContact(params) {
    let details = JSON.parse(params.data.profile_details_with_values);

    if (details) {
      if (details.find(_d => _d['Contact'])) {
        const values = details.find(_d => _d['Contact']);
        return values['Contact'];
      } else if (details.find(_d => _d['Blog email'])) {
        const values = details.find(_d => _d['Blog email']);
        return values['Blog email'];
      } else {
        return '-';
      }
    }
  }

  showServices(params) {
    let details = JSON.parse(params.data.profile_details_with_values);
    if (details) {
      const values = details.find(
        _d =>
          _d['Currently featured on Vendor Cancellation Openings (VCO) list']
      );
      let finalValues = [];
      if (
        values &&
        values['Currently featured on Vendor Cancellation Openings (VCO) list']
      ) {
        let data =
          values[
            'Currently featured on Vendor Cancellation Openings (VCO) list'
          ];
        data.map(val => {
          if (val) {
            val.map(key => {
              return finalValues.push(Object.keys(key));
            });
          }
        });
        return finalValues.toString();
        // values.map(val=>{
        //   object.keys(val);
        // })
      }
    }
  }

  showEvent(e) {
    // alert(e.column.colId);
    if (e.column.colId == 'message') {
      if (
        !(
          e.data.profile_details_with_values &&
          e.data.membership_id !== '6' &&
          e.data.profile_details_with_values &&
          e.data.membership_id !== '3'
        ) ||
        (this.props.profileData.profile &&
          this.props.profileData.profile.id == e.data.id)
      ) {
        return;
      }
      let data = e.data;
      let actionType = e.event.target.getAttribute('data-action-type');
      return this.onActionMessageClick(data);
    } else if (e.column.colId == 'follow') {
      let data = e.data;
      let actionType = e.event.target.getAttribute('data-action-type');

      switch (actionType) {
        case 'follow':
          return this.onActionViewClick(data);
        case 'unFollow':
          return this.onActionRemoveClick(data);
      }
    } else if (e.column.colId === 'follow_code') {
      // alert('i');

      this.setState({
        modal: true,
        profileData: e.data
      });
    }

    // else {
    //   this.setState({
    //     modal: true,
    //     profileData: e.data,
    //   });
    // }
  }

  onActionMessageClick(data) {
    this.setState({ userData: data });
    this.openModal();
  }

  onActionViewClick(data) {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();

    // if (
    //   window.confirm(
    //     `You will be notified about this member's updates and their events.`
    //   )
    // ) {
    let userData = {
      following_user_id: +data.id
    };
    this.props.follow(userData, route);
  }

  onActionRemoveClick(data) {
    this.setState({ userId: data.id });
    // let route = this.props.history.location.pathname;
    // route = route.split('/').pop();
    // this.props.unFollow(+data.id, route);
    this.togglePrivacyModal();
  }

  nameCellRenderer(params) {
    console.log('nameCellRenderer', params);
    let midName = {
      value:'',
    };
    if (params.data.type_id === 1) {
     try {
       midName = JSON.parse(params.data.user_details).filter((el) => el.name === 'middlename')[0];
     } catch (error) {
      // console.log(error,'mmmmmmmmmmmmmmmmmmmmm')
     }
    //  console.log(midName,'gkkkkkkkkkkkkk')
      if (params.data.fname || params.data.lname) {
        return `${params.data.fname} ${midName.value} ${params.data.lname}`;
      } else {
        return '-';
      }
    } else if (params.data.membership_id === '6') {
      if (params.data.display_name) {
        return params.data.display_name;
      } else {
        return '-';
      }
    } else if (params.data.type_id >= 3) {
      if (params.data.fname) {
        return params.data.fname;
      } else {
        return '-';
      }
    }
  }

  nameCellRendererForBlogger(params) {
    console.log(
      'params.dataparams.dataparams.data749',
      JSON.parse(params.data.user_details)
    );
    let midName = JSON.parse(params.data.user_details).filter(
      el => el.name == 'middlename'
    )[0];
    console.log('midName', midName);

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    //   return `${
    //     params.data.author_first_name ? params.data.author_first_name : ''
    //   } ${midName ? midName : ''} ${
    //     params.data.author_last_name ? params.data.author_last_name : ''
    //   }`;
    // }
    if (params.data.type_id === 1) {
      if (params.data.fname || params.data.lname) {
        return `${params.data.fname} ${midName.value} ${params.data.lname}`;
      } else {
        return '-';
      }
    } else if (params.data.membership_id === '6') {
      if (params.data.display_name) {
        return params.data.display_name;
      } else {
        return '-';
      }
    } else if (params.data.membership_id >= 3) {
      if (params.data.fname) {
        return params.data.fname;
      } else {
        return '-';
      }
    }
  }

  showMessageButton(params) {
    // console.log("here is params.data---> ", params.data);
    let chekMessageUrl = window.location.href;
    let messageUrl = chekMessageUrl.split('/')[6];
    if (
      this.props.profileData.profile &&
      this.props.profileData.profile.id == params.data.id
    ) {
      return '';
    }
    if (
      params.data.profile_details_with_values &&
      params.data.membership_id !== '6' &&
      params.data.profile_details_with_values &&
      params.data.membership_id !== '3'
    ) {
      if (this.props.displayMode) {
        return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Message</button>`;
      } else {
        return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Message</button>`;
      }
    } else {
      return '-';
    }
  }

  showMessageButtonBlogger(params) {
    if (
      params.data.profile_details_with_values &&
      params.data.membership_id === '6'
    ) {
      if (this.props.displayMode) {
        return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Message</button>`;
      } else {
        return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Message</button>`;
      }
    } else {
      return '';
    }
  }
  //   showMessageButtonBlogger(params) {
  //     console.log("params.data.profile_details_with_values",params.data.membership_id)
  //   if (params.data.profile_details_with_values && params.data.membership_id ==='6') {
  //     if (this.props.displayMode) {
  //       return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Message</button>`;
  //     } else {
  //       return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Message</button>`;
  //     }
  //   } else {
  //     return '<p class="text-center empty-cell">-</p>';
  //   }
  // }

  showFollowButton(params) {
    if (params.data.membership_id !== '4') {
      if (!params.data.follow) {
        if (this.props.displayMode) {
          return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Follow</button>`;
        } else {
          return `<button type="button" data-action-type="follow" class="btn btn-sm new_btn_save_bt_users_dark py-0">Follow</button>`;
        }
      } else {
        if (this.props.displayMode) {
          // console.log(
          //   'ajjaha',
          //   params.data.id.toString() === localStorage.getItem('userId')
          // );
          if (params.data.id.toString() === localStorage.getItem('userId')) {
          } else {
            return `<button type="button" ${
              this.props.profileData.profile &&
              this.props.profileData.profile.id == params.data.id
                ? true
                : false
            } 
           data-action-type="unFollow" class="btn btn-sm new_btn_save_bt_users_light btn-hover btn-danger py-0">UnFollow</button>`;
          }
        } else {
          if (params.data.id.toString() === localStorage.getItem('userId')) {
          } else {
            return `<button type="button" ${
              this.props.profileData.profile &&
              this.props.profileData.profile.id == params.data.id
                ? true
                : false
            } 
           data-action-type="unFollow" class="btn btn-sm  new_btn_save_bt_users_dark  btn-hover btn-danger py-0">UnFollow</button>`;
          }
        }
      }
    } else {
      return '-';
    }
  }

  showFollowButtonBlogger(params) {
    if (params.data.membership_id !== '3') {
      if (!params.data.follow) {
        if (this.props.displayMode) {
          return `<button type="button" data-action-type="follow" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Follow</button>`;
        } else {
          return `<button type="button" data-action-type="follow" class="btn btn-sm new_btn_save_bt_users_dark py-0">Follow</button>`;
        }
      } else {
        if (this.props.displayMode) {
          return `<button type="button" data-action-type="unFollow" class="btn btn-sm new_btn_save_bt_users_light btn-hover btn-danger py-0">UnFollow</button>`;
        } else {
          return `<button type="button" data-action-type="unFollow" class="btn btn-sm  new_btn_save_bt_users_dark  btn-hover btn-danger py-0">UnFollow</button>`;
        }
      }
    } else {
      return '-';
    }
  }

  showAsLink(params) {
    if (params.value) {
      return `<a href=${params.value.toString()} target="_blank" rel="noopener">${
        params.column.userProvidedColDef.headerName
      }</a>`;
    } else {
      return '-';
    }
  }
  showTitle(params) {
    if (params.value)
      return params.value.length === 0 ? '-' : params.value.toString();
    // if (params.value) {
    //   let value = JSON.parse(params.value);
    //   let titles = [];
    //   if (value) {
    //     for (const title in value) {
    //       titles.push(value[title]);
    //     }
    //     if (titles.length) {
    //       // console.log("this is title in show title--> ", titles);
    //       return titles.toString();
    //     } else {
    //       return '-';
    //     }
    //   }
    // } else {
    //   return '-';
    // }
  }
  checkisVendorTab(params) {
    let route = this.props.history.location.pathname;

    route = route.split('/').pop();
    if (route == 'vco_list') {
      return true;
    } else {
      return false;
    }
  }

  checkEmailView(params) {
    // console.log("params.data--> ", params.data);
    if (params.data.email1) {
      return params.data.email1;
    }
    {
      return '-';
    }

    // let data = JSON.parse(params.data['profile_details_with_values']);

    // if (params.data.is_email_display && data && data.length) {
    //   const email = data.find(_d => _d['Contact']);
    //   console.log("this is email in email---> ", email);
    //   return email && email['Contact'];
    // } else {
    //   return '-';
    // }
    // if(params.data.is_email_display && params.data.email)
    // {
    //   return params.data.email;
    // }else
    // {
    //   return '-';
    // }
  }

  hideEvent() {
    this.setState({
      modal: false,
      profileData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
  };

  refreshGrid() {
    this.props.getCases();
  }
  getUserData() {
    // this.setState({callListApi:true})
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();

    this.props.getUserList(`/api/v1/users?list=${route}`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  getBetaList = () => {
    this.props.getUserList(`/api/v1/users?list=beta_readers`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  };

  getMostFollowed = () => {
    this.props.getUserList(`/api/v1/users/mostpopular`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  };

  // get data from beta form
  addBetadata(data) {
    let betaData = data;
    if (betaData.title) {
      betaData.title = JSON.stringify(betaData.title);
    }

    this.props.AddBetaReader('/api/v1/add_beta_readers', data, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    // this.hideModal();
    if (
      (localStorage.getItem('membershipId') === '1' ||
        localStorage.getItem('membershipId') === '8') &&
      this.props.callListApi
    ) {
      this.getUserData();
    }
    // this.props.getBeta();
  }

  handleAddReader(e) {
    e.preventDefault();
    this.hideModal();
    this.setState({ showBetaModal: true });
  }
  handleRemoveReader(e) {
    e.preventDefault();
    this.props.getBeta();
    this.betaRederaOpen();
    this.props.RemoveBetaReader('/api/v1/beta_readers_delete', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    if (
      (localStorage.getItem('membershipId') === '1' ||
        localStorage.getItem('membershipId') === '8') &&
      this.props.callListApi
    ) {
      this.getUserData();
    }
  }

  toggleBetaModal() {
    this.setState({ showBetaModal: !this.state.showBetaModal });
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
    this.getUserData();
  };
  togglePrivacyModal = () => {
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };
  agreePolicy = () => {
    this.togglePrivacyModal();
  };
  betaRederaOpen = () => {
    this.setState({ betaReadersPopupOpen: !this.state.betaReadersPopupOpen });
  };

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }
  handleRefresh = () => {
    // console.log('called');
    window.location.reload();
  };
  handleDelete = async () => {
    try {
      let route = this.props.history.location.pathname;
      route = route.split('/').pop();
      this.props.unFollow(+this.state.userId, route);
      this.togglePrivacyModal();
    } catch (err) {
      console.log('err', err);
    }
  };

  //===================== RENDER ==========================
  render() {
    const isBlogger =
      +localStorage.getItem('membershipId') == 3 ||
      +localStorage.getItem('membershipId') == 6 ||
      +localStorage.getItem('membershipId') == 2
        ? true
        : false;
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    let data = [];
    let dataReders = [];
    let check;

    if (this.props.userList.length > 0) {
      // data = this.props.userList.filter(
      //   el => el.update_cancel_cron === 1 && el.delete_member_request === '0'
      // );

      // console.log('this.props.userList', this.props.userList);
      data = this.props.userList && this.props.userList;
      if (route === 'following') {
        if (Array.isArray(data) && data.length > 0) {
          data = defaultSort(data, 'follow_time');
        }
      } else if (route === 'blogger') {
        if (Array.isArray(data) && data.length > 0) {
          data = data.sort((a, b) => {
            if (a.created_date < b.created_date) return -1;
          });
        }
        // console.log("here is the blogger data--------------- >>>>>>>>", data);
      } else if (route === 'all') {
        let newPopularArr = [];
        data = data.filter(el => {
          let index = this.props.mostPopular.findIndex(
            mostPop => mostPop.id === el.id
          );
          if (index !== -1) {
            newPopularArr.push(el);
          }
          return index === -1 ? true : false;
        });
        data = [...newPopularArr, ...data];
      }

      data = data.map(element => {
        let e = {};
        e = { ...element };
        e.userName = element['fname'] + ' ' + element['lname'];
        if (route === 'all' || route === 'following') {
          if (!element['fname']) {
            e.userName = e.display_name;
          }
          let data = e['profile_details_with_values']
            ? JSON.parse(e['profile_details_with_values'])
            : null;
          const email = data ? data.find(_d => _d['Contact']) : null;
          e.email1 = email ? email['Contact'].toLowerCase() : '';
        } else if (route === 'blogger' && e.profile_details_with_values) {
          console.log('thisssssssssssssss', typeof e.user_details);
          let details = e ? JSON.parse(e.profile_details_with_values) : null;
          let BloggName = '';
          BloggName = e ? e.user_details[0].value : null;
          // if (typeof e.user_details === 'string') {
          //   BloggName = e ? JSON.parse(e.user_details)[0].value : null;
          // } else {
          //   BloggName = e ? e.user_details[0].value : null;
          // }
          if (details) {
            if (details.find(_d => _d['Blog'])) {
              const values = details.find(_d => _d['Blog']);
              const values2 = details.find(_d => _d['Blog email']);
              // console.log("values['Blog email'] ", values2['Blog email'])
              console.log('************', values.Blog);
              e.userName = values.Blog ? values.Blog : BloggName;
              e.contact = values2['Blog email'];
            }
            if (details) {
              if (details.find(_d => _d['Contact'])) {
                const values = details.find(_d => _d['Contact']);
                e.contact = values['Contact'].toLowerCase();
              } else if (details.find(_d => _d['Blog email'])) {
                const values = details.find(_d => _d['Blog email']);
                e.contact = values['Blog email'].toLowerCase();
              } else {
                return '-';
              }
            }
          }
        }
        if (e.userName) {
          e.userName = e.userName.toUpperCase();
        }
        return e;
      });
    }
    let filterWithFname = data.filter(
      el =>
        el.fname !== null ||
        (el.membership_id === '6' && el.profile_details_with_values !== null)
    );

    if (this.props.userList.length > 0) {
      // data = this.props.userList.filter(
      //   el => el.update_cancel_cron === 1 || el.delete_member_request == '0'
      // );
      dataReders = this.props.userList;
      dataReders.forEach(element => {
        let e = {};
        e = element;
        e.userName = element['fname'] + ' ' + element['lname'];
      });
    }

    let mydeleteRequest = [];

    const filter =
      this.state.betaareader &&
      this.state.betaareader.map(el => {
        let deleteRequest =
          this.props.userList &&
          this.props.userList.filter(ele => {
            if (el.id === ele.user_id) {
              mydeleteRequest.push(ele.user_id);
            }
          });
      });
    let filterdeleteRequest;
    if (mydeleteRequest.length > 0) {
      filterdeleteRequest =
        this.props.userList &&
        this.props.userList.filter(el => !mydeleteRequest.includes(el.user_id));
    } else {
      filterdeleteRequest = this.props.userList;
    }
    if (filterdeleteRequest.length > 0) {
      let userobj = {};
      let newArr = [];
      filterdeleteRequest = filterdeleteRequest.map(el => {
        let nobj = { ...el };

        nobj.userName =
          nobj.userName.charAt(0).toUpperCase() +
          nobj.userName.slice(1).toLowerCase();
        // nobj.display_name = nobj.display_name ? nobj.display_name.toUpperCase() : '';
        // console.log("this is the nobj.lname---> ", nobj.lname);
        if (route === 'beta_readers' && nobj.publicName) {
          nobj.publicName =
            nobj.publicName.charAt(0).toUpperCase() +
            nobj.publicName.slice(1).toLowerCase();
          nobj.email = nobj.email;
          let value = JSON.parse(el.title);
          console.log('jivan', value);
          let arrObj = Object.keys(value);
          console.log('arrObj', arrObj);
          let narr = arrObj.map(el => {
            let val = value[el] ? value[el] : '-';
            console.log('val', val);
            return val;
          });
          nobj.title1 = [...narr].join(' | ');
          console.log('narrnarr', narr);
          if (
            this.props.profileData &&
            nobj.email === this.props.profileData.profile.email.toUpperCase()
          ) {
            userobj = { ...nobj };
            return;
          }
        }
        if (!nobj.fname || !nobj.lname) {
          nobj.userName = nobj.display_name
            ? nobj.display_name.toUpperCase()
            : '-';
        }
        if (route === 'following') {
          if (!el['fname']) {
            nobj.userName = el.display_name;
          } else {
            nobj.userName = el['fname'].toUpperCase();
          }
        }
        newArr.push(nobj);
        return nobj;
      });

      // console.log("hera is data------> ",filterdeleteRequest);
      if (Object.keys(userobj).length > 0) {
        newArr.unshift(userobj);
      }
      if (newArr.length > 0 && route === 'beta_readers') {
        filterdeleteRequest = [...newArr];
      }

      // console.log("here is filtered ")
    }
    // console.log("--------------------------------", {route, isBlogger})

    if (route === 'all') {
      if (
        this.props.profileData &&
        this.props.profileData.profile.membership_id === '8'
      ) {
        filterWithFname = filterWithFname.filter(el => {
          return el.name !== 'Blogger';
        });
      }
      // if (this.state.mostPopular.length > 0) {
      //   if (
      //     !isBlogger &&
      //     route === 'all' &&
      //     localStorage.getItem('membershipId') === '8'
      //   ) {
      //     filterWithFname = filterWithFname.filter(el => {
      //       let index = this.state.mostPopular.findIndex(
      //         mostPop => mostPop.id === el.id
      //       );
      //       return index === -1 ? true : false;
      //     });
      //     filterWithFname = [...this.state.mostPopular, ...filterWithFname];
      //   } else {
      //     data = data.filter(el => {
      //       let index = this.state.mostPopular.findIndex(
      //         mostPop => mostPop.id === el.id
      //       );
      //       return index === -1 ? true : false;
      //     });
      //     data = [...this.state.mostPopular, ...data];
      //   }
      // }
    }

    // console.log("isBlogger---> ", isBlogger);
    // console.log(
    //   '------------------------------->',
    //   this.props.profileData.profile
    // );

    console.log('-----------------------------------> ', filterdeleteRequest);

    return (
      <>
        <Modal
          isOpen={this.state.betaReadersPopupOpen}
          toggle={this.betaRederaOpen.bind(this)}
          id="betareader"
          className="advertise-privacy-popup delete-confirmation-popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <ModalBody>
            <p className="text-center p-0">
              {' '}
              Are you sure you want to unsubscribe from the beta reader list?{' '}
            </p>
            <div className="delete-btn">
              <button
                className="btn btn-filled"
                id="Next"
                type="button"
                disabled={this.state.disabled}
                onClick={this.betaRederaOpen}
              >
                Cancel
              </button>
              <button
                className="btn btn-filled "
                id="Next"
                type="button"
                onClick={e => {
                  this.handleRemoveReader(e);
                }}
                disabled={this.state.disabled}
              >
                Ok
              </button>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.isOpenPrivacy}
          toggle={this.togglePrivacyModal.bind(this)}
          id="myModal"
          className="advertise-privacy-popup delete-confirmation-popup"
        >
          {/* <ModalHeader toggle={this.state.toggle} className="eventModal">
           
          </ModalHeader> */}
          <ModalBody>
            <p className="text-center p-0">
              {' '}
              Are you sure you want to unfollow?{' '}
            </p>
            <div className="delete-btn">
              <button
                className="btn btn-filled"
                id="Next"
                type="button"
                disabled={this.state.disabled}
                onClick={this.togglePrivacyModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-filled "
                id="Next"
                type="button"
                onClick={() => {
                  this.handleDelete(data);
                  this.togglePrivacyModal();
                }}
                disabled={this.state.disabled}
              >
                Remove
              </button>
            </div>
          </ModalBody>
        </Modal>
        {this.state.showBetaModal && (
          <AddToBetaReader
            isOpen={this.state.showBetaModal}
            betaData={this.addBetadata.bind(this)}
            toggle={this.toggleBetaModal.bind(this)}
            betaUserData={
              Object.keys(this.props.betaUserData).length
                ? this.props.betaUserData
                : {}
            }
          />
        )}
        <div
          className={`container user-listing-page ${
            route === 'beta_readers' || route === 'beta_reader_list'
              ? 'beta-reader-page'
              : ''
          }`}
          style={{ paddingBottom: 0 }}
        >
          {route ? (
            <>
              <div className="row">
                <div className="col-md-12">
                  {route === 'beta_readers' ? (
                    <div className="mb-0">
                      <h2 className="text-center heading">BETA READER LIST</h2>
                    </div>
                  ) : route === 'beta_reader_list' ? (
                    <div className="mb-0">
                      <h2 className="text-center heading">BETA READER LIST</h2>
                    </div>
                  ) : (
                    ''
                  )}
                  {route === 'following' && (
                    <div className="mb-4">
                      <h2 className="text-center heading">Following</h2>
                    </div>
                  )}
                  {route === 'vco_list' && (
                    <div className="mb-4">
                      <h2 className="text-center heading">
                        Vendor Cancellation Openings (VCO)
                      </h2>
                    </div>
                  )}

                  {route === 'all' && (
                    <div className="mb-4">
                      <h2 className="text-center heading">All profiles</h2>
                    </div>
                  )}
                  {route === 'blogger' && (
                    <div className="mb-4">
                      <h2 className="text-center heading">Bloggers</h2>
                    </div>
                  )}
                  {route === 'most_followed' && (
                    <div className="mb-4">
                      <h2 className="text-center heading">Most Followed</h2>
                    </div>
                  )}
                  {route === 'followers' && (
                    <>
                      <div>
                        <h2 className="text-center heading">Followers</h2>
                      </div>
                      <div className="text-center mt-2">
                        <h4>
                          You have {data.length} followers.
                          {/* You have {this.props.userList.length} followers. */}
                        </h4>
                      </div>
                    </>
                  )}
                  {(route === 'beta_readers' ||
                    route === 'beta_reader_list') && (
                    <>
                      {/* <div className="alignment add_to_beta"> */}
                      <button
                        // onClick={(e)=>{ if (window.confirm('Disclaimer for all users:Thank you for your interest in beta reading for authors. The contact information you provided can be accessed by all author members, as the list is not specific to certain authors. Please keep in mind that being added to the list does not guarantee beta reading offers will be extended by authors, but it increases the chance to be chosen.Once your name is added, OTR is not responsible to facilitate communication between you as a beta reader and author. OTR is the platform in which all members can connect and does not receive any financial incentive nor does OTR exert any influence in pairing authors with beta readers. We hope you enjoy the beta experience!')) this.handleAddReader(e)  } }
                        onClick={this.showModal}
                        disabled={this.props.betaStatus}
                        className={
                          this.props.displayMode
                            ? 'btn btn_save_bt add_to_beta_reder'
                            : 'btn btn_save_bt add_to_beta_reder white-button'
                        }
                      >
                        {/* Add to beta reader list */}
                        Join List
                      </button>
                      {/* </div> */}

                      <button
                        disabled={!this.props.betaStatus}
                        onClick={
                          // if (
                          //   window.confirm(
                          //     'Are you sure you want to unsubscribe from the beta reader list?'
                          //   )
                          // )
                          // this.handleRemoveReader(e);
                          this.betaRederaOpen.bind(this)
                        }
                        className={
                          this.props.displayMode
                            ? 'btn btn_save_bt add_to_beta_reder'
                            : 'btn btn_save_bt add_to_beta_reder white-button'
                        }
                      >
                        Leave List
                      </button>
                      {/* </div> */}

                      <button
                        disabled={!this.props.betaStatus}
                        onClick={() => {
                          this.toggleBetaModal();
                        }}
                        className={
                          this.props.displayMode
                            ? 'btn btn_save_bt add_to_beta_reder'
                            : 'btn btn_save_bt add_to_beta_reder white-button'
                        }
                      >
                        Edit Beta Form
                      </button>
                    </>
                  )}
                </div>

                {(localStorage.getItem('membershipId') === '1' ||
                  localStorage.getItem('membershipId') === '8') &&
                  route === 'beta_readers' && (
                    <div className="col-md-12">
                      <div className="add_to_beta_text mt-2">
                        <p style={{ marginBottom: 8 }}>
                          As a benefit to being an author member, you have
                          access to an exclusive OTR beta reader list. This beta
                          list is strictly a volunteer list whereby interested
                          readers can sign up, knowing the list is only viewable
                          by authors. It is the responsibility of the author,
                          not OTR, to vet participants in order to establish
                          whether the reader will be a good fit. Authors should
                          exercise caution when distributing their copyrighted
                          material. Similarly, the names and contact details of
                          beta list members are to be used for the beta process
                          and not to be shared for any other purpose without the
                          explicit written consent of an OTR member. OTR wishes
                          you success, as we strive to enrich the reading and
                          writing experience!
                        </p>
                      </div>
                    </div>
                  )}
                {/* {route === 'beta_reader_list' &&
                  localStorage.getItem('membershipId') !== '2' && (
                    <div className="col-md-12">
                      <div className="add_to_beta_text mt-4">
                        <p>
                          As a benefit to being an author member, you have
                          access to an exclusive OTR beta reader list. This beta
                          list is strictly a volunteer list whereby interested
                          readers can sign up, knowing the list is only viewable
                          by authors. It is the responsibility of the author,
                          not OTR, to vet participants in order to establish
                          whether the reader will be a good fit. Authors should
                          exercise caution when distributing their copyrighted
                          material. Similarly, the names and contact details of
                          beta list members are to be used for the beta process
                          and not to be shared for any other purpose without the
                          explicit written consent of an OTR member. OTR wishes
                          you success, as we strive to enrich the reading and
                          writing experience!
                        </p>
                      </div>
                    </div>
                  )} */}
                {/* {route === 'beta_reader_list' &&
                  localStorage.getItem('membershipId') === '2' && (
                    <div className="col-md-12">
                      <div className="add_to_beta_text mt-4">
                        <p>
                          Thank you for your interest in beta reading for
                          authors. The contact information you provided can be
                          accessed by all author members, as the list is not
                          specific to certain authors. Please keep in mind that
                          being added to the list does not guarantee beta
                          reading offers will be extended by authors, however it
                          increases the chance to be chosen. Once your name is
                          added, OTR is not responsible to facilitate
                          communication between you as a beta reader and the
                          author. OTR is the platform in which members can
                          connect. OTR does not receive any financial incentive,
                          nor does OTR exert any influence in pairing authors
                          with beta readers. We hope you enjoy the beta
                          experience!
                        </p>
                      </div>
                    </div>
                  )} */}
                <br></br>
                <div className="col-md-12">
                  {isBlogger && route === 'all' && (
                    <div
                      className={
                        this.props.displayMode
                          ? 'ag-theme-balham'
                          : 'ag-theme-dark home-ag'
                      }
                    >
                      <AgGridReact
                        onGridReady={this.onGridReady}
                        columnDefs={this.state.allProfilescolumnDefs}
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.props.userList}
                        rowData={data}
                        pagination={true}
                        reactNext={true}
                        animateRows
                        onCellClicked={
                          route !== 'beta_readers'
                            ? this.showEvent.bind(this)
                            : null
                        }
                        paginationPageSize={this.state.paginationPageSize}
                        paginationNumberFormatter={
                          this.state.paginationNumberFormatter
                        }
                      />
                      <div className="test-header">
                        <div className="search-features">
                          <p>
                            <i>
                              Each column has search feature. Column width can
                              be changed by dragging. Hit refresh to reset.
                            </i>
                            <i
                              style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                              }}
                              className="fa fa-refresh"
                              onClick={this.handleRefresh}
                            />
                          </p>
                        </div>
                        <div className="pageNo">
                          Page Size:
                          <select
                            onChange={this.onPageSizeChanged.bind(this)}
                            id="page-size"
                            defaultValue="1000"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {isBlogger && route === 'following' && (
                    <div
                      className={
                        this.props.displayMode
                          ? 'ag-theme-balham following-list'
                          : 'ag-theme-dark home-ag'
                      }
                    >
                      <AgGridReact
                        onGridReady={this.onGridReady}
                        columnDefs={this.state.bloggerRedearcolumnDefs}
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.props.userList}
                        rowData={data}
                        pagination={true}
                        reactNext={true}
                        animateRows
                        onCellClicked={
                          route !== 'beta_readers'
                            ? this.showEvent.bind(this)
                            : null
                        }
                        paginationPageSize={this.state.paginationPageSize}
                        paginationNumberFormatter={
                          this.state.paginationNumberFormatter
                        }
                      />
                      <div className="test-header">
                        <div className="search-features">
                          <p>
                            <i>
                              Each column has search feature. Column width can
                              be changed by dragging. Hit refresh to reset.
                            </i>
                            <i
                              style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                              }}
                              className="fa fa-refresh"
                              onClick={this.handleRefresh}
                            />
                          </p>
                        </div>
                        <div className="pageNo">
                          Page Size:
                          <select
                            onChange={this.onPageSizeChanged.bind(this)}
                            id="page-size"
                            defaultValue="1000"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isBlogger &&
                    route === 'all' &&
                    localStorage.getItem('membershipId') === '8' && (
                      <div
                        className={
                          this.props.displayMode
                            ? 'ag-theme-balham'
                            : 'ag-theme-dark home-ag'
                        }
                      >
                        <AgGridReact
                          onGridReady={this.onGridReady}
                          columnDefs={this.state.allProfilescolumnDefs}
                          defaultColDef={this.state.defaultColDef}
                          // rowData={this.props.userList}
                          rowData={filterWithFname}
                          pagination={true}
                          reactNext={true}
                          animateRows
                          onCellClicked={
                            route !== 'beta_readers'
                              ? this.showEvent.bind(this)
                              : null
                          }
                          paginationPageSize={this.state.paginationPageSize}
                          paginationNumberFormatter={
                            this.state.paginationNumberFormatter
                          }
                        />
                        <div className="test-header">
                          <div className="search-features">
                            <p>
                              <i>
                                Each column has search feature. Column width can
                                be changed by dragging. Hit refresh to reset.
                              </i>
                              <i
                                style={{
                                  marginLeft: '10px',
                                  cursor: 'pointer'
                                }}
                                className="fa fa-refresh"
                                onClick={this.handleRefresh}
                              />
                            </p>
                          </div>
                          <div className="pageNo">
                            Page Size:
                            <select
                              onChange={this.onPageSizeChanged.bind(this)}
                              id="page-size"
                              defaultValue="1000"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {!isBlogger &&
                    route === 'all' &&
                    localStorage.getItem('membershipId') !== '8' && (
                      <div
                        className={
                          this.props.displayMode
                            ? 'ag-theme-balham'
                            : 'ag-theme-dark home-ag'
                        }
                      >
                        <AgGridReact
                          onGridReady={this.onGridReady}
                          columnDefs={this.state.allProfilescolumnDefs}
                          defaultColDef={this.state.defaultColDef}
                          // rowData={this.props.userList}
                          rowData={data}
                          pagination={true}
                          reactNext={true}
                          animateRows
                          onCellClicked={
                            route !== 'beta_readers'
                              ? this.showEvent.bind(this)
                              : null
                          }
                          paginationPageSize={this.state.paginationPageSize}
                          paginationNumberFormatter={
                            this.state.paginationNumberFormatter
                          }
                        />
                        <div className="test-header">
                          <div className="search-features">
                            <p>
                              <i>
                                Each column has search feature. Column width can
                                be changed by dragging. Hit refresh to reset.
                              </i>
                              <i
                                style={{
                                  marginLeft: '10px',
                                  cursor: 'pointer'
                                }}
                                className="fa fa-refresh"
                                onClick={this.handleRefresh}
                              />
                            </p>
                          </div>
                          <div className="pageNo">
                            Page Size:
                            <select
                              onChange={this.onPageSizeChanged.bind(this)}
                              id="page-size"
                              defaultValue="1000"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                  {route === 'blogger' && (
                    <div
                      className={
                        this.props.displayMode
                          ? 'ag-theme-balham blogger-list'
                          : 'ag-theme-dark home-ag'
                      }
                    >
                      <AgGridReact
                        onGridReady={this.onGridReady}
                        columnDefs={this.state.bloggercolumnDefs}
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.props.userList}
                        rowData={data}
                        pagination={true}
                        reactNext={true}
                        animateRows
                        //onCellClicked={null}
                        paginationPageSize={this.state.paginationPageSize}
                        onCellClicked={
                          route !== 'beta_readers'
                            ? this.showEvent.bind(this)
                            : null
                        }
                        paginationNumberFormatter={
                          this.state.paginationNumberFormatter
                        }
                      />
                      <div className="test-header">
                        <div className="search-features">
                          <p>
                            <i>
                              Each column has search feature. Column width can
                              be changed by dragging. Hit refresh to reset.
                            </i>
                            <i
                              style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                              }}
                              className="fa fa-refresh"
                              onClick={this.handleRefresh}
                            />
                          </p>
                        </div>
                        <div className="pageNo">
                          Page Size:
                          <select
                            onChange={this.onPageSizeChanged.bind(this)}
                            id="page-size"
                            defaultValue="1000"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {route === 'vco_list' && (
                    <div
                      className={
                        this.props.displayMode
                          ? 'ag-theme-balham'
                          : 'ag-theme-dark home-ag'
                      }
                    >
                      <AgGridReact
                        onGridReady={this.onGridReady}
                        columnDefs={this.state.VCOcolumnDefs}
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.props.userList}
                        rowData={data}
                        pagination={true}
                        reactNext={true}
                        animateRows
                        onCellClicked={
                          route !== 'beta_readers'
                            ? this.showEvent.bind(this)
                            : null
                        }
                        paginationPageSize={this.state.paginationPageSize}
                        paginationNumberFormatter={
                          this.state.paginationNumberFormatter
                        }
                      />
                      <div className="test-header">
                        <div className="search-features">
                          <p>
                            <i>
                              Each column has search feature. Column width can
                              be changed by dragging. Hit refresh to reset.
                            </i>
                            <i
                              style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                              }}
                              className="fa fa-refresh"
                              onClick={this.handleRefresh}
                            />
                          </p>
                        </div>
                        <div className="pageNo">
                          Page Size:
                          <select
                            onChange={this.onPageSizeChanged.bind(this)}
                            id="page-size"
                            defaultValue="1000"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* {console.log(
                    'filterdeleteRequestfilterdeleteRequest',
                    filterdeleteRequest
                  )} */}
                  {!(route === 'beta_reader_list') &&
                    !(route === 'most_followed') &&
                    !(route === 'all') &&
                    !isBlogger &&
                    !(route === 'followers') &&
                    !(route === 'blogger') &&
                    !(route === 'vco_list') && (
                      <div
                        className={
                          this.props.displayMode
                            ? 'ag-theme-balham shivvv'
                            : 'ag-theme-dark home-ag jvbjhbkughjuujbjb'
                        }
                      >
                        <AgGridReact
                          onGridReady={this.onGridReady}
                          columnDefs={
                            route !== 'beta_readers'
                              ? this.state.columnDefs
                              : this.state.betacolumnDefs
                          }
                          defaultColDef={this.state.defaultColDef}
                          // rowData={this.props.userList}
                          rowData={filterdeleteRequest}
                          pagination={true}
                          reactNext={true}
                          animateRows
                          onCellClicked={
                            route !== 'beta_readers'
                              ? this.showEvent.bind(this)
                              : null
                          }
                          paginationPageSize={this.state.paginationPageSize}
                          paginationNumberFormatter={
                            this.state.paginationNumberFormatter
                          }
                        />
                        <div className="test-header">
                          <div className="search-features">
                            <p>
                              <i>
                                {' '}
                                Each column has search feature. Column width can
                                be changed by dragging. Hit refresh to reset.
                              </i>
                              <i
                                style={{
                                  marginLeft: '10px',
                                  cursor: 'pointer'
                                }}
                                className="fa fa-refresh"
                                onClick={this.handleRefresh}
                              />
                            </p>
                            <div></div>
                          </div>

                          <div className="pageNo">
                            Page Size:
                            <select
                              onChange={this.onPageSizeChanged.bind(this)}
                              id="page-size"
                              defaultValue="1000"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                  {route === 'most_followed' && (
                    <div
                      className={
                        this.props.displayMode
                          ? 'ag-theme-balham'
                          : 'ag-theme-dark home-ag'
                      }
                    >
                      <AgGridReact
                        onGridReady={this.onGridReady}
                        columnDefs={
                          route !== 'beta_readers'
                            ? this.state.columnDefMostFollowed
                            : this.state.betacolumnDefs
                        }
                        defaultColDef={this.state.defaultColDef}
                        // rowData={this.props.userList}
                        rowData={filterdeleteRequest}
                        pagination={true}
                        reactNext={true}
                        animateRows
                        onCellClicked={
                          route !== 'beta_readers'
                            ? this.showEvent.bind(this)
                            : null
                        }
                        paginationPageSize={this.state.paginationPageSize}
                        paginationNumberFormatter={
                          this.state.paginationNumberFormatter
                        }
                      />
                      <div className="test-header">
                        <div className="search-features">
                          <p>
                            <i>
                              {' '}
                              Each column has search feature. Column width can
                              be changed by dragging. Hit refresh to reset.
                            </i>
                            <i
                              style={{
                                marginLeft: '10px',
                                cursor: 'pointer'
                              }}
                              className="fa fa-refresh"
                              onClick={this.handleRefresh}
                            />
                          </p>
                          <div></div>
                        </div>

                        <div className="pageNo">
                          Page Size:
                          <select
                            onChange={this.onPageSizeChanged.bind(this)}
                            id="page-size"
                            defaultValue="1000"
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}

                  {isBlogger &&
                    route === 'most_followed' &&
                    localStorage.getItem('membershipId') !== '3' &&
                    localStorage.getItem('membershipId') !== '6' &&
                    localStorage.getItem('membershipId') !== '2' && (
                      <div
                        className={
                          this.props.displayMode
                            ? 'ag-theme-balham'
                            : 'ag-theme-dark home-ag'
                        }
                      >
                        <AgGridReact
                          onGridReady={this.onGridReady}
                          columnDefs={
                            route !== 'beta_readers'
                              ? this.state.columnDefs
                              : this.state.betacolumnDefs
                          }
                          defaultColDef={this.state.defaultColDef}
                          // rowData={this.props.userList}
                          //rowData={data}
                          pagination={true}
                          reactNext={true}
                          animateRows
                          onCellClicked={
                            route !== 'beta_readers'
                              ? this.showEvent.bind(this)
                              : null
                          }
                          paginationPageSize={this.state.paginationPageSize}
                          paginationNumberFormatter={
                            this.state.paginationNumberFormatter
                          }
                        />
                        <div className="test-header">
                          <div className="search-features">
                            <p>
                              <i>
                                Each column has search feature. Column width can
                                be changed by dragging. Hit refresh to reset.
                              </i>
                              <i
                                style={{
                                  marginLeft: '10px',
                                  cursor: 'pointer'
                                }}
                                className="fa fa-refresh"
                                onClick={this.handleRefresh}
                              />
                            </p>
                          </div>
                          <div className="pageNo">
                            Page Size:
                            <select
                              onChange={this.onPageSizeChanged.bind(this)}
                              id="page-size"
                              defaultValue="1000"
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>

              {route === 'vco_list' && (
                <div
                  className="mt-3 disclaimer-text"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )}
              {/* {route === 'followers' && (
                <div
                  className="mt-3 disclaimer-text"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )} */}
              {route === 'beta_readers' && (
                <div
                  className="mt-0 hsj disclaimer-text"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p style={{ marginBottom: 0 }}>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )}
              {/* {route === 'all' && (
                <div
                  className="mt-3 disclaimer-text"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )} */}
              {route === 'beta_reader_list' && (
                <div
                  className="mt-3 disclaimer-text beta-reader-disclaimer"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )}

              {route === 'blogger' && (
                <div
                  className="mt-3 disclaimer-text"
                  style={{ fontSize: '0.7rem' }}
                >
                  <span>
                    <b>
                      <i
                        class="fa fa-exclamation-triangle"
                        aria-hidden="true"
                      ></i>{' '}
                      Disclaimer:{' '}
                    </b>
                    <p>
                      OTR is not responsible for screening or verifying any
                      members featured on lists. Being featured on a list does
                      not constitute an endorsement or verification by OTR of
                      any of the services, abilities or credentials. It is the
                      sole responsibility of the member to check references and
                      credentials.
                    </p>
                  </span>
                </div>
              )}

              {this.state.modal ? (
                <UserProfileModal
                  isOpen={this.state.modal}
                  newdata={'jivabn'}
                  profileData={this.state.profileData}
                  toggle={this.hideEvent}
                />
              ) : null}
            </>
          ) : null}
        </div>

        <Modal isOpen={this.state.show} toggle={this.hideModal} id="modal">
          <ModalHeader
            toggle={this.hideModal}
            className="eventModal disclaimer-popup"
          >
            <h5 className="text-center p-0"> Disclaimer</h5>
          </ModalHeader>

          <ModalBody>
            {!isBlogger ? (
              <>
                <p style={{ fontSize: '14px!important' }}>
                  {/* As a benefit to being an author member, you have access to an
                  exclusive OTR beta reader list. This beta list is strictly a
                  volunteer list whereby interested readers can sign up, knowing
                  the list is only viewable by authors. It is the responsibility
                  of the author, not OTR, to vet participants in order to
                  establish whether the reader will be a good fit. Authors
                  should exercise caution when distributing their copyrighted
                  material. Similarly, the names and contact details of beta
                  list members are to be used for the beta process and not to be
                  shared for any other purpose without the explicit written
                  consent of an OTR member. OTR wishes you success, as we strive
                  to enrich the reading and writing experience! */}
                  Thank you for your interest in beta reading for authors. The
                  contact information you provided can be accessed by all author
                  members, as the list is not specific to certain authors.
                  Please keep in mind that being added to the list does not
                  guarantee beta reading offers will be extended by authors,
                  however it increases the chance to be chosen.
                </p>
                <p>
                  Once your name is added, OTR is not responsible to facilitate
                  communication between you as a beta reader and the author. OTR
                  is the platform in which members can connect. OTR does not
                  receive any financial incentive, nor does OTR exert any
                  influence in pairing authors with beta readers. We hope you
                  enjoy the beta experience!
                </p>
              </>
            ) : (
              <>
                <p style={{ fontSize: '14px !important' }}>
                  Thank you for your interest in beta reading for authors. The
                  contact information you provided can be accessed by all author
                  members, as the list is not specific to certain authors.
                  Please keep in mind that being added to the list does not
                  guarantee beta reading offers will be extended by authors, but
                  it increases the chance to be chosen.
                </p>
                <p style={{ fontSize: '14px' }}>
                  Once your name is added, OTR is not responsible to facilitate
                  communication between you as a beta reader and author. OTR is
                  the platform in which all members can connect and does not
                  receive any financial incentive nor does OTR exert any
                  influence in pairing authors with beta readers. We hope you
                  enjoy the beta experience!
                </p>
              </>
            )}

            <div className="disclaimer-popup-btn">
              <button
                type="submit"
                onClick={e => this.handleAddReader(e)}
                className={'btn btn_save_bt' || 'btn btn_save_bt dark-button'}
              >
                CONFIRM
              </button>

              <button
                type="submit"
                onClick={this.hideModal}
                className={'btn btn_save_bt' || 'btn btn_save_bt dark-button'}
              >
                CANCEL
              </button>
            </div>
          </ModalBody>
        </Modal>

        <AddMessage
          isOpen={this.state.showMessageModal}
          data={this.state.userData}
          toggle={this.hideMessageModal.bind(this)}
        />
      </>
    );
  }

  // componentWillReceiveProps(props){

  //   if(props.callListApi){
  //     this.getUserData();
  //   }
  // }

  async componentDidMount() {
    let route = this.props.history.location.pathname;
    route = route.split('/').pop();
    // console.log('userlist', route);
    if (route === 'most_followed') {
      this.getMostFollowed();
    } else if (route === 'beta_readers' || route === 'beta_reader_list') {
      this.props.getBeta();
      this.getBetaList();
    } else if (route === 'most_followed') {
      this.getMostFollowed();
    } else {
      this.getUserData();
    }
    const getListOfUser = await axios.get(
      BASEURL + `/api/v1/getdeleteuser/${localStorage.getItem('userId')}`,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );
    // console.log('getListOfUser', getListOfUser.data.result);
    this.setState({ betaareader: getListOfUser.data.result });
  }
}

const mapStateToProps = state => {
  return {
    loading: state.profileLoading,
    userList: state.userListData,
    betaStatus: state.isBetaReaderExist,
    displayMode: state.changeMode,
    callListApi: state.callListApi,
    betaUserData: state.betaUserData,
    status: state.checkIsFollowed,
    profileData: state.myProfileData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setupEvent: () => dispatch(setupEvent()),
    getUserList: (url, headers) => dispatch(getUserList(url, headers)),
    AddBetaReader: (url, body, headers) =>
      dispatch(AddBeta(url, body, headers)),
    RemoveBetaReader: (url, headers) => dispatch(RemoveBeta(url, headers)),
    getBeta: () =>
      dispatch(
        getBeta(`/api/v1/isBetaReader`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    follow: (data, route) =>
      dispatch(
        followUser(
          `/api/v1/follow`,
          data,
          { headers: { 'x-authorization': localStorage.getItem('token') } },
          true,
          route
        )
      ),
    unFollow: (id, route) =>
      dispatch(
        unFollowUser(
          `/api/v1/follow/${id}`,
          { headers: { 'x-authorization': localStorage.getItem('token') } },
          true,
          route
        )
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersListView);
