import axios from 'axios';
import createHistory from 'history/createHashHistory';
import { notify } from 'react-notify-toast';
import {
  events, eventsummary, filterevent, getFilterEventData,
} from './event-setup';

export default createHistory();
const globalHistory = new createHistory();

export function Unauthorized() {
  notify.show('You are not Authorized.', 'custom', 5000, { background: '#f8d7da', text: '#721c24' });
  localStorage.clear();
  globalHistory.push('/log-in');
}
