import axios from 'axios';
import { notify } from 'react-notify-toast';
import createHistory from 'history/createHashHistory';
import { Unauthorized } from './errors';
import { BASEURL } from '../constant';
import { defaultSortByDate } from '../Utils/useFunction';

export default createHistory();
const globalHistory = new createHistory();

export function Tab(activeTab) {
  return {
    type: 'ACTIVE_TAB',
    activeTab
  };
}
export function filterTask(data) {
  return {
    type: 'TASK_FILTER',
    data
  };
}

export function dailyTask(data) {
  return {
    type: 'DAILY_TASK',
    data
  };
}
export function eventLoading(bool) {
  return {
    type: 'EVENT_LOADING',
    status: bool
  };
}

export function previewData(data) {
  return {
    type: 'PREVIEW_DATA',
    data
  };
}

export function editEventType(data) {
  return {
    type: 'EDIT_EVENT_TYPE',
    data
  };
}

export function events(data) {
  return {
    type: 'EVENTS',
    data
  };
}
export function tasks(data) {
  return {
    type: 'EVENTS',
    data
  };
}

export function eventsummary(data) {
  return {
    type: 'EVENTS_SUMMARY',
    data
  };
}
export function eventFilter(data) {
  return {
    type: 'EVENT_FILTER',
    data
  };
}

export function getDateWiseTasks(data) {
  return {
    type: 'DAILY_TASK_DATE_WISE',
    data
  };
}

export function getAllTasks(url, headers, history) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(filterTask([]));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        let data = defaultSortByDate(res.data.result, 'added_on');
        // dispatch(filterTask(res.data.result));
        dispatch(filterTask(data));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // notify.show("failed to fetch Events", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
      });
  };
}

export function getTaskDateWise(url, headers, history) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(getDateWiseTasks([]));
    axios
      .get(BASEURL + url, headers)
      .then(res => {
        dispatch(getDateWiseTasks(res.data.result));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        // console.log(error);
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
        // notify.show("failed to fetch Events", "custom", 5000, { background: '#f8d7da', text: "#721c24" });
        // localStorage.removeItem("token");
      });
  };
}

export function saveDetail(url, data, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .post(BASEURL + url, data, headers)
      .then(response => {
        dispatch(getAllTasks('/api/v1/daily_task/getTask', headers));
        notify.show('Daily Task added successfully.', 'custom', 2000, {
          background: '#d4edda',
          text: '#155724',
          borderColor: '#c3e6cb'
        });
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        } else {
          notify.show('Failed to create Daily Task.', 'custom', 5000, {
            background: '#f8d7da',
            text: '#721c24'
          });
        }
        // history.push("/setup-event");
      });
  };
}

export function deleteTask(url, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    axios
      .delete(BASEURL + url, headers)
      .then(res => {
        dispatch(tasks([]));
        globalHistory.push('/dashboard/daily-task');
        dispatch(getAllTasks('/api/v1/daily_task/getTask', headers));
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}

export function editTaskDetails(url, data, headers) {
  return dispatch => {
    dispatch(eventLoading(true));
    dispatch(filterTask([]));
    axios
      .put(BASEURL + url, data, headers)
      .then(res => {
        dispatch(getAllTasks('/api/v1/daily_task/getTask', headers));
        globalHistory.push('/dashboard/daily-task');
        dispatch(eventLoading(false));
      })
      .catch(error => {
        dispatch(eventLoading(false));
        if (error.response && error.response.status === 403) {
          dispatch(Unauthorized);
        }
      });
  };
}
