export function Search(state = '', action) {
  switch (action.type) {
    case 'SEARCH_FILTER':
      return action.search;
    default:
      return state;
  }
}

export function sort(state = [], action) {
  switch (action.type) {
    case 'SORT_FILTER':
      return action.data;
    default:
      return state;
  }
}

export function eventFilter(state = {}, action) {
  switch (action.type) {
    case 'EVENT_FILTER':
      return action.data;
    default:
      return state;
  }
}
