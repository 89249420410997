import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUserList, setupEvent } from '../../Actions/profile-setup';
import UserProfileModal from './userProfileModal';
import AddMessage from '../messages/messageModal';
import moment from 'moment';
import './usersList.css';
import Select from 'react-select';
import { param } from 'jquery';

class VendorListView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            issues: [
                {
                    label: 'Select a service',
                    value: 'Service'
                },
                {
                    label: 'Agent',
                    value: 'Agent'
                },
                {
                    label: 'Blurb writer',
                    value: 'Blurb writer'
                },
                {
                    label: 'Book boxes',
                    value: 'Book boxes'
                },
                {
                    label: 'Book cover designer',
                    value: 'Book cover designer'
                },
                {
                    label: 'Book formatter',
                    value: 'Book formatter'
                },
                {
                    label: 'Book signing organizer',
                    value: 'Book signing organizer'
                },
                {
                    label: 'Bookstore',
                    value: 'Bookstore'
                },
                {
                    label: 'Cover model',
                    value: 'Cover model'
                },
                {
                    label: 'Editor',
                    value: 'Editor'
                },
                {
                    label: 'Graphic designer',
                    value: 'Graphic designer'
                },
                {
                    label: 'Merchandising',
                    value: 'Merchandising'
                },
                {
                    label: 'Personal assistant (PA)',
                    value: 'Personal Assistant (PA)'
                },
                {
                    label: 'Photographer',
                    value: 'Photographer'
                },
                {
                    label: 'PR services',
                    value: 'PR services'
                },
                {
                    label: 'Proofreader',
                    value: 'Proofreader'
                },
                {
                    label: 'Publisher',
                    value: 'Publisher'
                },
                {
                    label: 'Social media consultant',
                    value: 'Social media consultant'
                },
                {
                    label: 'Social media manager',
                    value: 'Social media manager'
                },
                {
                    label: 'Swags',
                    value: 'Swags'
                },
                {
                    label: 'Web design',
                    value: 'Web design'
                },
                {
                    label: 'Workshops',
                    value: 'Workshops'
                }
            ],
            service: '',
            usersList: [],
            profileData: {},
            showMessageModal: false,
            modal: false,
            vcoValue: '',
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    hide: true
                },
                {
                    headerName: 'Company Name',
                    field: 'fname',
                    cellRenderer: this.nameCellRenderer.bind(this),
                    suppressMovable: true,
                    unSortIcon: true
                },
                {
                    headerName: 'OTR profile',
                    field: 'follow_code',
                    suppressMovable: true,
                    cellRenderer: this.showPRProfile.bind(this)
                },
                {
                    headerName: 'Brief introduction',
                    field: 'Brief introduction',
                    suppressMovable: true,
                    unSortIcon: true,
                    cellRenderer: this.getSummary.bind(this)
                },
                {
                    headerName: 'Membership Type',
                    field: 'member_type',
                    suppressMovable: true,
                    unSortIcon: true,
                    cellRenderer: this.setMemberType.bind(this)
                },
                {
                    headerName: 'Type of service(s)',
                    field: 'services',
                    suppressMovable: true,
                    unSortIcon: true,
                    cellRenderer: this.checkServiceView.bind(this)
                },
                {
                    headerName: 'Accepting new clients',
                    field: 'Are you accepting new clients',
                    suppressMovable: true,
                    unSortIcon: true,
                    cellRenderer: this.checkAcceptView.bind(this)
                },
                {
                    headerName: 'VCO (featured)',
                    field: 'Currently featured on last minute vendor cancellations list',
                    suppressMovable: true,
                    unSortIcon: true,
                    cellRenderer: this.checkVCOView.bind(this)
                },
                {
                    headerName: 'Message',
                    field: 'message',
                    suppressMovable: true,
                    cellRenderer: this.showMessageButton.bind(this)
                }
            ],
            defaultColDef: {
                sortable: true,
                resizable: true,
                filter: true
            },
            paginationPageSize: 10,
            paginationNumberFormatter: function (params) {
                return '' + params.value.toLocaleString() + '';
            },
            displayedRows: 0,
            showMenu: false,
            selectedOption: null
        };
        this.props.setupEvent();
        this.hideEvent = this.hideEvent.bind(this);
    }
    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.setState({ service: selectedOption.value });
    };

    showPRProfile(params) {
        let details = params.data.profile_details_with_values;

        if (details) {
            if (this.props.displayMode) {
                return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Profile</button>`;
            } else {
                return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Profile</button>`;
            }
        } else {
            return '<p class="blankCell">-</p>';
        }
    }

    openModal() {
        this.setState({ showMessageModal: true });
    }

    hideMessageModal() {
        this.setState({ showMessageModal: false });
    }

    showMessageButton(params) {
        if (params.data.profile_details_with_values) {
            if (params.data.id.toString() !== localStorage.getItem('userId')) {
                if (this.props.displayMode) {
                    return `<button type="button" data-action-type="message" class="btn btn-sm  new_btn_save_bt_users_light  py-0">Message</button>`;
                } else {
                    return `<button type="button" data-action-type="message" class="btn btn-sm new_btn_save_bt_users_dark py-0">Message</button>`;
                }
            }
        } else {
            return '<p class="blankCell">-</p>';
        }
    }

    onActionMessageClick(data) {
        this.setState({ userData: data });
        this.openModal();
    }

    showEvent(e) {
        if (e.column.colId == 'message') {
            if (!e.data.profile_details_with_values) {
                return;
            }
            if (!(e.data.id.toString() !== localStorage.getItem('userId'))) {
                return;
            }
            let data = e.data;

            return this.onActionMessageClick(data);
        } else if (e.column.colId === 'follow_code') {
            this.setState({
                modal: true,
                profileData: e.data
            });
        }
    }

    nameCellRenderer(params) {
        if (params.data.type_id === 1) {
            return `${params.data.fname} ${params.data.lname}`;
        } else if (params.data.type_id >= 3) {
            return params.data.fname;
        }
    }

    checkEmailView(params) {
        let data = JSON.parse(params.data['profile_details_with_values']);

        if (params.data.is_email_display && data && data.length) {
            const email = data.find(_d => _d['Contact']);
            return email.Contact;
        } else {
            return '<p class="blankCell">-</p>';
        }
    }
    checkAcceptView(params) {
        const JSONValues = JSON.parse(params.data.profile_details_with_values);
        const data = JSONValues.find(_d => _d['Accepting new clients?']);
        const data1 = JSONValues.find(_d => _d['Are you accepting new clients']);

        if (data && data['Accepting new clients?'] == 'Yes') {
            return 'Yes';
        } else if (data1 && data1['Are you accepting new clients'] == 'Yes') {
            return 'Yes';
        } else {
            return 'No';
        }
    }
    checkServiceView(params) {
        const JSONValues = JSON.parse(params.data.profile_details_with_values);
        const data = JSONValues.find(_d => _d['Services offered']);
        return data
            ? data['Services offered']
            : params.data.member_type === 'Vendor'
                ? '-'
                : 'PR services';
    }
    checkVCOView(params) {
        let details = JSON.parse(params.data.profile_details_with_values);
        console.log("this is detauls--='> ", details);
        if (details) {
            const values = details.find(
                _d =>
                    _d['Currently featured on Vendor Cancellation Openings (VCO) list']
            );
            if (
                values &&
                values['Currently featured on Vendor Cancellation Openings (VCO) list']
            ) {
                console.log('valuesvalues', values);
                const boolVal = values[
                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                ][0].filter(el => {
                    return Object.keys(el)[0] === 'Service'
                        ? false
                        : new Date().getTime() <= new Date(Object.values(el)[0]).getTime();
                });
                console.log('boolVal', boolVal);
                return boolVal.length === 0 ? 'No' : 'Yes';
            } else {
                return 'No';
            }
        }
    }

    getSummary(params) {
        const JSONValues = JSON.parse(params.data.profile_details_with_values);

        const summary = JSONValues.find(_d => _d['Brief introduction']);
        return summary
            ? summary['Brief introduction']
            : '<p class="blankCell">-</p>';
    }

    setMemberType(params) {
        return params.data.member_type === 'PR Firm'
            ? 'PR Firm/Bookstore'
            : params.data.member_type;
    }

    hideEvent() {
        this.setState({
            modal: false,
            profileData: {}
        });
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.paginationGoToPage(this.props.current);
        this.gridApi.setDomLayout('autoHeight');
    };

    refreshGrid() {
        this.props.getCases();
    }
    handleRefresh = () => {
        window.location.reload();
    };
    onPageSizeChanged(newPageSize) {
        var value = document.getElementById('page-size').value;
        this.gridApi.paginationSetPageSize(Number(value));
    }

    //===================== RENDER ==========================
    render() {
        let data = [];
        if (this.props.userList.length > 0) {
            data = this.props.userList;
            data.forEach(element => {
                let e = {};
                e = element;
                e.profileSummary =
                    element['profile_details_with_values'] &&
                    JSON.parse(element['profile_details_with_values']).find(
                        _d => _d['Profile summary']
                    ) &&
                    JSON.parse(element['profile_details_with_values']).find(
                        _d => _d['Profile summary']
                    )['Profile summary'];

                e.services =
                    element['profile_details_with_values'] &&
                    JSON.parse(element['profile_details_with_values']).find(
                        _a => _a['Services offered']
                    ) &&
                    JSON.parse(element['profile_details_with_values']).find(
                        _a => _a['Services offered']
                    )['Services offered'];
            });
        }
        let data1;
        data1 =
            this.props.userList &&
            this.props.userList.filter(el => el.update_cancel_cron === 1);
        if (Array.isArray(data1) && data1.length > 0) {
            data1 = data1.sort((a, b) => {
                if (!a['fname'] || !b['fname']) return 0;
                let na = a['fname'].toUpperCase();
                let nb = b['fname'].toUpperCase();
                if (na < nb) return -1;
                if (na > nb) return 1;
                return 0; // Add this line to handle equal values
            });
        }
        if (data1 && data1.length > 0) {
            data1 = data1.map(el => {
                let nobj = { ...el };
                if (nobj.fname) {
                    nobj.fname = nobj.fname.toUpperCase();
                }
                if (nobj.lname) {
                    nobj.lname = nobj.lname.toUpperCase();
                }

                return nobj;
            });

            if (this.state.service !== 'Service' && this.state.service !== '') {
                data1 = data1.filter(el => {
                    const JSONValues = JSON.parse(el.profile_details_with_values);

                    const fobj = JSONValues.find(_d => _d['Services offered']);
                    let flag = false;
                    let value = !!fobj ? fobj['Services offered'] : 'pr services';
                    if (!fobj && el.member_type === 'Vendor') {
                        value = '-';
                    }
                    flag = value.toLowerCase().includes(this.state.service.toLowerCase());
                    return flag;
                });
            }
        }
        const filterStyles = {
            control: styles => ({
                ...styles,
                backgroundColor: `#fff !important`,
                border: `0.5px solid #000 !important`,
                color: `#000 !important`,
                cursor: 'pointer',
                minHeight: '26px',
                boxShadow: `none !important`,
                '&:hover': {
                    backgroundColor: `#fff !important`
                },
                '&:focus': {
                    backgroundColor: `#fff !important`
                },
                '&:focusWithin': {
                    backgroundColor: `#fff !important`
                }
            }),
            indicatorsContainer: base => ({
                ...base,
                position: 'relative',
                cursor: 'pointer'
            }),
            indicatorSeparator: base => ({
                ...base,
                display: 'none !important'
            }),
            dropdownIndicator: base => ({
                ...base
            })
        };
        data = data.map(el => {
            const JSONValues = JSON.parse(el.profile_details_with_values);
            const summary = JSONValues.find(_d => _d['Brief introduction']);
            // if(JSONValues[1]['Vendor'] === 'mypr firm profile')
            // {
            //   console.log("this ois json value---->>>1 ", JSONValues)
            // }else{
            //   console.log("this ois json value---->>>2 ", JSONValues)
            // }
            // console.log('JSON value sis:', summary);
            el['Brief introduction'] = summary
                ? summary['Brief introduction'].toLowerCase()
                : '';
            // console.log("this is json value----->> ", JSONValues)
            //----------------------------------------------------------
            if (JSONValues) {
                const values = JSONValues.find(
                    _d =>
                        _d['Currently featured on Vendor Cancellation Openings (VCO) list']
                );
                let finalValues = [];
                if (
                    values &&
                    values[
                    'Currently featured on Vendor Cancellation Openings (VCO) list'
                    ]
                ) {
                    el['Currently featured on last minute vendor cancellations list'] =
                        values[
                            'Currently featured on Vendor Cancellation Openings (VCO) list'
                        ][0].filter(el => {
                            // console.log("this is el in  vendor list-->> ", Object.keys(el)[0] === 'Service')
                            return Object.keys(el)[0] === 'Service'
                                ? false
                                : new Date().getTime() >=
                                new Date(Object.values(el)[0]).getTime();
                        }).length === 0
                            ? 'No'
                            : 'Yes';

                    // console.log("this is contact--> ", JSONValues.find((el)=> el['Contact'])['Contact'])
                    // console.log("this is my data---->> ", el['Currently featured on last minute vendor cancellations list'])

                    // if(el['Currently featured on last minute vendor cancellations list'] === 'Yes')
                    // {
                    //   console.log("this is el-->> ", values[
                    //     'Currently featured on Vendor Cancellation Openings (VCO) list'
                    //   ][0])
                    // }

                    // values.map(val=>{
                    //   object.keys(val);
                    // })
                } else {
                    // console.log("hii th9isis ekl ==> elll", el['Currently featured on last minute vendor cancellations list'])
                    el['Currently featured on last minute vendor cancellations list'] =
                        'No';
                }
            }

            const data = JSONValues.find(_d => _d['Accepting new clients?']);
            const data1 = JSONValues.find(_d => _d['Are you accepting new clients']);

            if (data && data['Accepting new clients?'] == 'Yes') {
                el['Are you accepting new clients'] = 'Yes';
            } else if (data1 && data1['Are you accepting new clients'] == 'Yes') {
                el['Are you accepting new clients'] = 'Yes';
            } else {
                el['Are you accepting new clients'] = 'No';
            }
            //
            return el;
        });

        return (
            <div className="container">
                <div className="vendor-listing-page">
                    <div className="mb-4">
                        <h2 className="text-center heading">Vendors</h2>
                    </div>
                    <div>
                        <label className="vendor-services">
                            <span>FILTER BY SERVICE: {''}</span>
                            <Select
                                value={this.state.selectedOption}
                                onChange={this.handleChange}
                                options={this.state.issues}
                                placeholder="Select a service"
                                isSearchable={false}
                                className="filter-select"
                                classNamePrefix="filter-select"
                                styles={filterStyles}
                            />
                            {/* <select onChange={this.handleChange}>
                {   
                  Array.isArray(this.state.issues) && this.state.issues.length > 0 && this.state.issues.map((el, i) => 
                  {
                          return <option value={el ? el.value : ''} key={i}>{el ? el.label : ''}</option>    
                  })
                }
                
              </select> */}
                        </label>
                    </div>
                    <div className={`vendor-list-table ${this.props.myevents ? 'ag-theme-balham' : 'ag-theme-dark home-ag' }`} >
                        <AgGridReact
                            onGridReady={this.onGridReady}
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={data1}
                            pagination={true}
                            reactNext={true}
                            animateRows
                            onCellClicked={this.showEvent.bind(this)}
                            paginationPageSize={this.state.paginationPageSize}
                            paginationNumberFormatter={this.state.paginationNumberFormatter}
                        />
                        <div className="search-features" style={{ marginTop: '10px' }}>
                            <p>
                                <i>
                                    Each column has search feature. Column width can be changed by
                                    dragging. Hit refresh to reset.
                                </i>
                                <i style={{
                                        marginLeft: '10px',
                                        cursor: 'pointer'
                                    }}
                                    className="fa fa-refresh"
                                    onClick={this.handleRefresh}
                                />
                            </p>
                        </div>
                        <div className="test-header" style={{ float: 'right' }}>
                            Page Size:
                            <select
                                onChange={this.onPageSizeChanged.bind(this)}
                                id="page-size"
                                defaultValue="1000"
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                    <div className="mt-5" style={{ fontSize: '0.7rem' }}>
                        <span>
                            <b>
                                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{' '}
                                Disclaimer:{' '}
                            </b>
                            OTR is not responsible for screening or verifying any members
                            featured on lists. Being featured on a list does not constitute an
                            endorsement or verification by OTR of any of the services,
                            abilities or credentials. It is the sole responsibility of the
                            member to check references and credentials.
                        </span>
                    </div>
                    {this.state.modal ? (
                        <UserProfileModal
                            isOpen={this.state.modal}
                            profileData={this.state.profileData}
                            toggle={this.hideEvent}
                        />
                    ) : null}

                    <AddMessage
                        isOpen={this.state.showMessageModal}
                        data={this.state.userData}
                        toggle={this.hideMessageModal.bind(this)}
                    />
                </div>
            </div>
        );
    }

    componentDidMount() {
        let route = this.props.history.location.pathname;
        route = route.split('/').pop();
        if (route) {
            this.props.getUserList(`/api/v1/users?list=${route}`, {
                headers: { 'x-authorization': localStorage.getItem('token') }
            });
        }
    }
}

const mapStateToProps = state => {
    return {
        loading: state.profileLoading,
        userList: state.userListData,
        displayMode: state.changeMode
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setupEvent: () => dispatch(setupEvent()),
        getUserList: (url, headers) => dispatch(getUserList(url, headers))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorListView);
