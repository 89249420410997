const CONSTANT = () => {};
CONSTANT.FACEBOOK = 'https://www.facebook.com/OnTargetRomance/';
CONSTANT.INSTAGRAM = 'https://www.instagram.com/ontargetromance/';
CONSTANT.TWITTER_URL = 'https://twitter.com/OnTargetRomance';
CONSTANT.YOUTUBE_URL =
  'https://www.youtube.com/channel/UCs8Dxoq6qFP-qsl7bfZIpKw';
CONSTANT.LINKTREE_URL = 'https://linktr.ee/OnTargetRomance ';
// facebook login
CONSTANT.FACEBOOKAPPID = '1467340310106904';
CONSTANT.GOOGLEAPPID =
  '272295103198-tgmuidjg59979o9pn3pi1stcmuhkcnal.apps.googleusercontent.com';
// tiny mce
CONSTANT.TINY_API_KEY = '02bmdr65eds9vmo76ewfyfqeibdtgp3ysm9e4s8z4btzizv4';
CONSTANT.WEBSITEMODE = 'BETA';
CONSTANT.GTM = 'GTM-PC89VTN';
CONSTANT.BASEURL = 'https://apivirtual.ontargetromance.com';
//CONSTANT.BASEURL = 'http://localhost:3001';
//CONSTANT.BASEURL = 'http://localhost:3000';
module.exports = CONSTANT;
