import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { notify } from 'react-notify-toast';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import Select from 'react-select';
import avatarImg from '../../Utils/Assets/OTR_Default-avatar_v3.png';

// import { addEventFilter, addSortFilter } from '../../Actions/filters';
// import { getFilterEventDataList } from '../../Actions/event-setup';
import { searchUserByUsername } from '../../Actions/profile-setup';
import {
  addMessage,
  getConversationList,
  getUserMessageList
} from '../../Actions/messages';
// import '../Profile/dashboard.css'

class AddMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      title: '',
      selectedOption: null,
      data: [],
      showMessageModal: false,
      chosenEmoji: false,
      newD: '',
      currentUser: ''
    };

    // this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.isJsonString = this.isJsonString.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    let checked = target.value;

    this.setState({
      [name]: checked
    });
  }

  handleEmojiPicker = e => {
    this.setState({ chosenEmoji: !this.state.chosenEmoji });
  };

  addEmoji = e => {
    let oldmesg = this.state.message;

    this.setState({
      chosenEmoji: false,
      message: oldmesg + e.native
    });
  };

  handleSubmit(event) {
    if (
      this.state.selectedOption === null ||
      this.state.selectedOption === 'undefined' ||
      this.state.selectedOption === ''
    ) {
      return notify.show('Please select username.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }

    if (this.state.message === '' || this.state.message === null) {
      return notify.show('Please type a message.', 'custom', 5000, {
        background: '#f8d7da',
        text: '#721c24'
      });
    }
    let data = {
      receiver_user_id: this.state.selectedOption.value,
      message: this.state.message,
      title: this.state.title,
      created_at: moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
    };
    this.props.addMessage(
      `/api/v1/message`,
      { headers: { 'x-authorization': localStorage.getItem('token') } },
      data,
      localStorage.getItem('msgId')
    );

    this.props.getMessages(
      `/api/v1/message_list/${localStorage.getItem('msgId')}`,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );

    event.preventDefault();
    this.setState({ message: '' });
    this.setState({ selectedOption: '' });
    this.get();

    if (this.props.conversationList.length > 0) {
      localStorage.setItem(
        'msgId',
        this.props.conversationList[0] && this.props.conversationList[0].msg_id
      );
    }
  }

  get = () => {
    this.props.toggle();
  };

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      let mdata = this.state.data;
      mdata = [];
      this.props.searchUsername(
        `/api/v1/user_search_username?searchString=${inputValue}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } }
      );
      callback(mdata);
    }, 1000);
  };

  onChange = selectedOption => {
    if (selectedOption) {
      this.setState({ selectedOption });
      if (selectedOption && this.props.conversationList.length > 0) {
        let a = this.props.conversationList.filter(
          el => el.receiver_user_id === selectedOption.value
        );
        if (a.length > 0) {
          localStorage.setItem('msgId', a[0] && a[0].msg_id);
          this.props.toggle();
          this.setState({ selectedOption: '' });
          this.props.getMessages(
            `/api/v1/message_list/${localStorage.getItem('msgId')}`,
            {
              headers: {
                'x-authorization': localStorage.getItem('token')
              }
            }
          );
        }
      }
    } else {
      this.setState({ selectedOption: '' });
    }
  };

  valueChanges = value => {
    this.props.searchUsername(
      `/api/v1/user_search_username?searchString=${value}`,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
  };

  isJsonString(str) {
    console.log('I am in json string fucntion');
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  //===================== RENDER ==========================

  render() {
    const { selectedOption } = this.state;
    // console.log("this is the----------> ", this.props.searchData);
    let updatedSearchData = [];
    if (this.props.searchData.length) {
      updatedSearchData = this.props.searchData.filter(
        el => !(el.membership_id === '6' || el.membership_id === '3')
      );
    }
    // console.log('profileImg.value', profileImg.value);

    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.toggle}
          id="myModal"
          className="message-popup"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h5 className="text-center p-0">Compose new message: </h5>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit.bind(this)}>
              <div class="row">
                {/* <div class="form-group col-md-6 " > 
                  <label for="startDate">Title</label>
                  <input type="text"
                    class="form-control"
                    id="title"
                    name="title"
                    onChange={this.handleChange}
                    value={this.state.title}
                    placeholder="Title"
                     />
                </div> */}
                <div class="form-group col-md-12">
                  <label for="endDate">Recipient:</label>
                  <Select
                    isClearable
                    cacheOptions
                    isSearchable={true}
                    // isLoading={true}
                    value={selectedOption}
                    className="message-select"
                    classNamePrefix="user-message"
                    onInputChange={e => this.valueChanges(e)}
                    onChange={e => {
                      this.onChange(e);
                    }}
                    options={updatedSearchData}
                    placeholder="Search by name"
                    required
                    // menuIsOpen
                    getOptionLabel={updatedSearchData => {
                      let profileImg = this.isJsonString(
                        updatedSearchData.user_details
                      )
                        ? JSON.parse(updatedSearchData.user_details).find(
                            el => el.name === 'profile'
                          )
                        : { value: '' };
                      console.log(
                        'this is profile img from message box',
                        profileImg
                      );
                      return (
                        <div className="message-list-option">
                          <div
                            className={`msg-avatarImg ${
                              updatedSearchData.membership_id === '1' ||
                              updatedSearchData.membership_id === '8'
                                ? 'authorImg'
                                : updatedSearchData.membership_id === '4'
                                ? 'vendorImg'
                                : updatedSearchData.membership_id === '5'
                                ? 'prFirmImg'
                                : ''
                            }`}
                          >
                            <img
                              src={
                                profileImg.value
                                  ? localStorage.getItem('baseUrl') +
                                    profileImg.value
                                  : avatarImg
                              }
                              alt="avatar-img"
                              onError={e => {
                                e.target.src = avatarImg; // Set the default image if there's an error
                              }}
                            />
                          </div>
                          {updatedSearchData.label}
                        </div>
                      );
                    }}
                  />

                  {/* <AsyncSelect
                    isClearable
                    // cacheOptions
                    // className={this.props.attributes.className}
                    isSearchable={true}
                    isLoading={true}
                    value={selectedOption}
                    placeholder="Search by username"
                    loadOptions={this.loadOptions}
                    onChange={(e) => {
                       this.onChange(e);
                    }}
                  /> */}
                </div>
                <div class="form-group col-md-6 mb-0">
                  <label for="eventType">Message:</label>
                </div>
                <div class="form-group col-md-12 choose-emoji">
                  <textarea
                    style={{ width: '100%' }}
                    rows="4"
                    cols="100"
                    maxlength="1000"
                    id="message"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange.bind(this)}
                  />
                  <div
                    className="emojipicker"
                    onBlur={this.handleEmojiPicker}
                    onClick={this.handleEmojiPicker}
                  >
                    😀
                  </div>
                  {this.state.chosenEmoji && (
                    <Picker onSelect={this.addEmoji} />
                  )}
                </div>
              </div>
              <button
                type="submit"
                //onClick={this.props.toggle}
                class="btn btn-dark pull-right"
                value="Send"
              >
                Send
              </button>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }

  componentDidMount() {
    this.props.searchUsername(
      `/api/v1/user_search_username?searchString=${''}`,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );

    this.props.getConversationList('/api/v1/message_list', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    // this.props.getMessages(
    //   `/api/v1/message_list/${localStorage.getItem('msgId')}`,
    //   {
    //     headers: {
    //       'x-authorization': localStorage.getItem('token')
    //     }
    //   }
    // );
  }

  componentWillReceiveProps() {
    if (this.props.isOpen == false) {
      this.setState({ selectedOption: '' });
    }
    if (this.props.searchData.length > 0) {
      this.setState({ data: this.props.searchData });
      this.setState({ newD: this.props.conversationList[0] });
    }

    if (this.props.data) {
      let key = ['author', 'blog', 'vendor'];
      if (this.props.data.profile_details_with_values) {
        let profile = JSON.parse(this.props.data.profile_details_with_values);

        let displayNameIndex = null;

        for (let i = 0; i < profile.length; i++) {
          let keys = Object.keys(profile[i])[0].toLocaleLowerCase();
          for (let j = 0; j < key.length; j++) {
            if (key[j].trim() === keys.trim()) {
              displayNameIndex = i;
            }
          }
        }

        console.log(
          'this.props.data.id',
          this.props.data.id.toString() === localStorage.getItem('id')
        );
        if (this.props.data.id.toString() === localStorage.getItem('userId')) {
          this.setState({ selectedOption: '' });
        } else {
          this.setState({
            selectedOption: {
              label: displayNameIndex
                ? Object.values(profile[displayNameIndex])
                : this.props.data.display_name,
              value: this.props.data.id
            }
          });
        }
      } else {
        this.setState({
          selectedOption: {
            label: this.props.data.display_name,
            value: this.props.data.id
          }
        });
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    searchData: state.searchByUsername,
    conversationList: state.conversationList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConversationList: (url, header) =>
      dispatch(getConversationList(url, header)),
    searchUsername: (url, header) =>
      dispatch(searchUserByUsername(url, header)),
    addMessage: (url, header, data, msgId) =>
      dispatch(addMessage(url, header, data, msgId)),
    getMessages: (url, header) => dispatch(getUserMessageList(url, header))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMessage);
