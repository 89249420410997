import React, { useState, useEffect } from 'react';
import './offerpromo.css';
import axios from 'axios';
import { BASEURL } from '../../constant';
import moment from 'moment';
import createHistory from 'history/createHashHistory';
import Footer from '../Footer/footer';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Loader/loader';
// import copy from 'copy-to-clipboard';

const globalHistory = new createHistory();

const PromoPage = () => {
  const [data, setData] = useState('dataa');
  const [page, setPage] = useState(false);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    async function getPromotions() {
      let response = await axios.get(BASEURL + `/api/v1/users/promooffer`);
      let respo = JSON.parse(JSON.stringify(response.data.result));
      const createdDate = moment().format('YYYY-MM-DD');
      let respo1 = respo.filter(el => el.date >= createdDate);
      let updatedData = respo1.sort((a, b) => a.date.localeCompare(b.date));
      setData(updatedData);
      setLoader(false);
    }
    getPromotions();
  }, []);

  const handleChange = () => {
    globalHistory.push('/join');
  };
  // console.log('this is token', localStorage.getItem('token'));

  useEffect(() => {
    setPage(true);
  }, []);

  return (
    <>
      {loader ? <Loader /> : null}
      <div className="offer-page">
        <div className="logo-section">
          <Link
            to={localStorage.getItem('token') === null ? '/dashboard' : '/user'}
          >
            <img src={require('../../Utils/Assets/logo_3.png')} alt="logo" />
          </Link>
          {/* showAd && props.profileData && props.profileData !== "" && */}
        </div>
        <div className="container">
          <div
            className="offer-section promo-offer-section row"
            style={data.length ? { paddingBottom: 100 } : {}}
          >
            {data && Array.isArray(data) && data.length
              ? data.map(el => {
                  const {
                    title,
                    subtitle,
                    description,
                    disclaimer,
                    discount,
                    ctatitle,
                    promocode,
                    date
                  } = el;
                  return (
                    <div className="offer-section-card">
                      <div className="offer-section-inner">
                        <div class="main_left">
                          <div className="logo_3 pt-2 text-center">
                            <img
                              src={require('../../Utils/Assets/logo_3.png')}
                              alt="logo"
                            />
                          </div>
                        </div>

                        <div className="offer-heading">
                          <h2 className="text-center heading">{title}</h2>
                          <span className="text-center heading">
                            {subtitle}
                          </span>
                        </div>

                        {/* <button className="offer-btn" onClick={handleChange}>join</button> */}
                        <div className="discount-number">
                          {discount}%<p>*</p>
                        </div>
                        {description && (
                          <div className="discount-text">{description}</div>
                        )}

                        <div className="clipboard-copy-section">
                          <input type="text" value={promocode} />{' '}
                          <ClipboardCopy copyText={promocode} />
                        </div>

                        <div className="offer-card-btns">
                          <a
                            href="https://coredev.ontargetromance.com/#/plans"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                            className="offer-btn"
                          >
                            See Plans
                          </a>

                          <a
                            href="https://coredev.ontargetromance.com/#/join"
                            target="_blank"
                            rel="noreferrer"
                            style={{ textDecoration: 'none' }}
                            className="offer-btn"
                          >
                            {/* {ctatitle} */}
                            JOIN / LOG IN
                          </a>
                        </div>

                        {/* 
                        <a
                          href="https://coredev.ontargetromance.com/#/sign-up"
                          target="_blank"
                          style={{ textDecoration: 'none' }}
                        >
                          <button className="offer-btn">{ctatitle}</button>
                        </a>
                         */}

                        <p className="offer-info-text">
                          {disclaimer
                            ? disclaimer
                            : `Promo Offers/Discount Codes are valid for one-time use only. One Promo Offer per membership. Promo Offers may not be combined with any other promo codes, coupons, discounts, offers, or promotions. Promotions have no cash value. Offers cannot be sold or otherwise bartered. Void where prohibited, taxed or otherwise restricted. Join & renewal Discount Offers are applied towards that year's membership. Renewal fees (auto- or manual) at the regular membership rate at time of renewal. OTR offers no refunds.`}
                        </p>

                        <p className="note">
                          *Offer ends on {moment(date).format('MMM-DD-YYYY')}
                        </p>
                      </div>
                    </div>
                  );
                })
              : ''}
          </div>

          {/* <div className="offer-section-footer">
          <div className="container">
            <p>&copy; Copyright 2023 OTR LLC</p>
          </div>
        </div> */}
        </div>
        <div className="footer">
          <Footer page={page} />
        </div>
      </div>
    </>
  );
};
const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="input_button">
      {/* <input type="text" value={copyText} readOnly/> */}
      <button
        type="submit"
        onClick={handleCopyClick}
        className="clipboard-copy"
      >
        {isCopied ? (
          <>
            <i className="fa fa-clipboard"></i>
            <span>Copied</span>
          </>
        ) : (
          <>
            <i className="fa fa-copy"></i>
            <span>Copy Code</span>
          </>
        )}
      </button>
    </div>
  );
};

export default PromoPage;
