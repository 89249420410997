import React, { Component } from 'react';
import TextBox from '../text-box';
import SelectBox from '../select-box';
import FileBox from '../file-box';
import Searchbox from '../searchbox';
import DateBox from '../date-box';
import moment from 'moment';
// import { deviceDetect } from 'react-device-detect';
import TimePicker from 'rc-time-picker';
// import ReactDOM from 'react-dom';
import 'rc-time-picker/assets/index.css';
// import { Button } from 'reactstrap';
let position = 0;
class HTMLParser extends Component {
  getFormElements = function(
    el,
    index,
    onChangeHandler,
    onTimeHandler,
    onSearchHandler,
    onCustomChangeHandler,
    onChangeFileHandler,
    onCheckboxChangeHandler,
    addMoreServiceHandler,
    removeServiceHandler,
    addMoreLinkHandler,
    onButtonClickHandler,
    childIndex,
    isSubmitted,
    imgSrc,
    eventImageError,
    startDate,
    radiotext,
    radioValue,
    typeo,
    eventType,
    disableProperty,
    standaloneValue,
    hideGiveaway,
    indexRemove,
    givevalu,
    data,
    disapear,
    handleForm,
    reset,
    openResetPopup,
    readOnly,
    handleReset,
    checkRef,
    giveawayTz,
    userSuggestion,
    openUserSuggestion,
    textBoxRef,
    userClickHandler
  ) {
    // console.log("data-------->> ", data)
    //  if(el.name === 'coauthor')
    //  {
    //     el.value = 'shivam'
    //  }
    const bookCover = data ? data.find(el => el.name === 'book-cover') : {};
    const altrnBookCover = data
      ? data.find(el => el.name === 'alternative-book-cover')
      : {};
    // console.log("this is book cover in html-parser---->> ",{bookCover, altrnBookCover, imgSrc})
    const fileData = {};
    const getEventData = (ind, type, eventType) => {
      if (eventType === 'Book Release') {
        return (
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 5) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 4) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 6)
        );
      }
      if (eventType === 'Book Promo') {
        return (
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 7) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 5) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 6) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 8)
        );
      }
      if (eventType === 'Giveaway') {
        return (
          // title
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 8) ||
          // image
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 9) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 10)
        );
      }
      if (eventType === 'Release Party') {
        return (
          // title
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 8) ||
          // image
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 9) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 10)
        );
      }
      if (eventType === 'ARC Sign-up') {
        return (
          // title
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 6) ||
          // image
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 7) ||
          ((type === 'book-cover' || type === 'alternative-book-cover') &&
            ind === 8)
        );
      }
    };
    // const disabledSeconds = (h, m) => {
    //   return [h + (m % 60)];
    // };

    const SearchSync = data ? data.find(el => el.text === 'Search/Sync') : null;
    const coAuthor = data ? data.find(el => el.name === 'coauthor') : null;
    const connectToEx = data
      ? data.find(el => el.title === 'Connect to existing events')
      : null;

    el.tag === 'title' || el.tag === 'custom' ? (position = 0) : position++;

    switch (el.tag) {
      case 'blank':
        return (
          <>
            {radiotext === '' && index === 22 ? (
              ''
            ) : eventType === 'Release Party' || eventType === 'Giveaway' ? (
              <div
                class=" col-md-6 d-none d-md-block"
                style={{ minHeight: '0px' }}
                key={index}
              >
                <div className="form-group"></div>
              </div>
            ) : null}
          </>
        );

      case 'input':

        return (
          <>
            {
              /* {el.text === 'Search/Sync' ? <button>hello</button> : null} */
              //  el.text === 'Search/Sync' || el.name === 'coauthor' ? console.log("el in condition ", el) : console.log("kuch n ")
            }

            {(standaloneValue === 'Stand-alone' &&
              el.type !== 'button' &&
              eventType === 'Book Release') ||
            (radiotext === '' && index === 21) ? (
              ''
            ) : (
              <>
                {el.text === 'Search/Sync' &&
                !(
                  eventType === 'Book Release' || eventType === 'Book Signing'
                ) ? (
                  <div class="col-md-6 release-party-group" key={index}>
                    <div className="form-group">
                      <TextBox
                        attributes={coAuthor}
                        IsUnderCustom={false}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={0}
                        user={coAuthor.value}
                        valueDisable={disableProperty}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readOnly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </div>
                  </div>
                ) : el.name === 'coauthor' &&
                  !(
                    eventType === 'Book Release' || eventType === 'Book Signing'
                  ) ? (
                  <>
                    <div class="col-md-6 release-party-group" key={index}>
                      <div className="form-group">
                        <TextBox
                          attributes={SearchSync}
                          IsUnderCustom={false}
                          onCustomHandler={onCustomChangeHandler}
                          onChangeTextHandler={onChangeHandler}
                          index={index}
                          parentIndex={0}
                          user={SearchSync.value}
                          valueDisable={disableProperty}
                          readOnly={
                            eventType !== 'BOOK SIGNING' ? readOnly : false
                          }
                          userSuggestion={userSuggestion}
                          openUserSuggestion={openUserSuggestion}
                          textBoxRef={textBoxRef}
                          userClickHandler={userClickHandler}
                        />
                      </div>
                    </div>
                    <div
                      class={`col-md-6 release-party-group d-flex ${
                        eventType === 'Cover Reveal' || eventType === 'Giveaway'
                          ? 'align-items-start'
                          : 'align-items-center'
                      }`}
                      key={index}
                      style={
                        eventType === 'Cover Reveal'
                          ? { paddingTop: 20 }
                          : eventType === 'Giveaway'
                          ? { paddingTop: 24 }
                          : {}
                      }
                    >
                      {/* <label>{el.title}</label> */}
                      <button
                        className="btn reset-cover-btn"
                        onClick={handleReset}
                      >
                        RESET
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`col-md-6 release-party-group d-flex justify-content-start  ${
                        eventType === 'Book Release'
                          ? 'align-items-end pb-3'
                          : 'align-items-center'
                      }
                       ${
                         (eventType === 'Book Signing' &&
                           el.name == 'link-to-event') ||
                         el.name == 'link-to-ticket-sales'
                           ? 'col-md-6 book-signing-me'
                           : 'col-md-6'
                       }
                      
                      `}
                      key={index}
                      // style={{ backgroundColor: 'pink' }}
                    >
                      {/* {(eventType === 'Book Release' && el.text === 'Search/Sync')? <button className="btn reset-cover-btn">RESET</button> :null }
                       */}
                      {eventType === 'Book Release' &&
                      el.text === 'Search/Sync' ? (
                        <div className="d-flex resetAuth-section">
                          <button
                            className="btn reset-cover-btn"
                            onClick={handleReset}
                          >
                            RESET
                          </button>
                        </div>
                      ) : (
                        <div className="form-group authorName">
                          <TextBox
                            attributes={el}
                            IsUnderCustom={false}
                            onCustomHandler={onCustomChangeHandler}
                            onChangeTextHandler={onChangeHandler}
                            index={index}
                            parentIndex={0}
                            user={el.value}
                            valueDisable={disableProperty}
                            readOnly={
                              eventType !== 'BOOK SIGNING' ? readOnly : false
                            }
                            userSuggestion={userSuggestion}
                            openUserSuggestion={openUserSuggestion}
                            textBoxRef={textBoxRef}
                            userClickHandler={userClickHandler}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            )}

            {el.name === 'Link to teasers' &&
              eventType === 'Book Release' &&
              standaloneValue !== 'Stand-alone in a series' &&
              standaloneValue !== 'Book in a series' && (
                <>
                  <div class="col-md-6 release-party-group" key={index}>
                    <div className="form-group">
                      <TextBox
                        attributes={el}
                        IsUnderCustom={false}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={0}
                        user={el.value}
                        valueDisable={disableProperty}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readOnly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </div>
                  </div>
                </>
              )}
            {el.name === 'Goodreads Link' &&
              eventType === 'Book Release' &&
              standaloneValue !== 'Stand-alone in a series' &&
              standaloneValue !== 'Book in a series' && (
                <>
                  <div class="col-md-6 release-party-group" key={index}>
                    <div className="form-group">
                      <TextBox
                        attributes={el}
                        IsUnderCustom={false}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={0}
                        user={el.value}
                        valueDisable={disableProperty}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readOnly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </div>
                  </div>
                </>
              )}
            {el.name === 'Book trailer' &&
              eventType === 'Book Release' &&
              standaloneValue !== 'Stand-alone in a series' &&
              standaloneValue !== 'Book in a series' && (
                <>
                  <div class="col-md-6 release-party-group" key={index}>
                    <div className="form-group">
                      <TextBox
                        attributes={el}
                        IsUnderCustom={false}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={0}
                        user={el.value}
                        valueDisable={disableProperty}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readOnly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </div>
                  </div>
                </>
              )}
            {el.name === 'Book playlist' &&
              eventType === 'Book Release' &&
              standaloneValue !== 'Stand-alone in a series' &&
              standaloneValue !== 'Book in a series' && (
                <>
                  <div class="col-md-6 release-party-group" key={index}>
                    <div className="form-group">
                      <TextBox
                        attributes={el}
                        IsUnderCustom={false}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={0}
                        user={el.value}
                        valueDisable={disableProperty}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readOnly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </div>
                  </div>
                </>
              )}

            {/* {radiotext === false ?  ''} */}
          </>
        );

      case 'searchbox':
        return (
          <div class="col-md-6" key={index}>
            <div className="form-group">
              <Searchbox
                attributes={el}
                onCustomHandler={onSearchHandler}
                onChangeTextHandler={onSearchHandler}
                index={index}
                parentIndex={0}
                isSuggestionOpen={false}
                readOnly={
                  !(eventType === 'BOOK SIGNING') &&
                  (el.name === 'title' || el.name === 'coauthor')
                    ? readOnly
                    : false
                }
              />
            </div>
          </div>
        );

      case 'select':
        return (
          <>
            {standaloneValue === 'Stand-alone' ? (
              ''
            ) : (
              <>
                <div class="col-md-6" key={index}>
                  <div className="form-group">
                    <SelectBox
                      attributes={el}
                      IsUnderCustom={true}
                      onCustomHandler={onCustomChangeHandler}
                      onChangeTextHandler={onChangeHandler}
                      index={0}
                      parentIndex={index}
                    />
                  </div>
                </div>
              </>
            )}
            {el.display_name === 'Country' && (
              <>
                <div class="col-md-6" key={index}>
                  <div className="form-group">
                    <SelectBox
                      attributes={el}
                      IsUnderCustom={true}
                      onCustomHandler={onCustomChangeHandler}
                      onChangeTextHandler={onChangeHandler}
                      index={0}
                      parentIndex={index}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        );

      case 'title':
        return (
          <div
            class={`${
              eventType === 'Book Release' &&
              !(
                el.title === 'Upload Temporary book cover' ||
                el.title === 'Upload Alternate book cover' ||
                el.title === 'Upload Official book cover'
              )
                ? 'col-md-6'
                : 'col-md-12'
            }`}
            key={index}
          >
            {/* {console.log("here is the tite----------- >>> ", el)} */}
            {this.getTitleElement(
              el,
              index,
              typeo,
              getEventData,
              eventType,
              fileData,
              data,
              reset,
              handleReset,
              onCustomChangeHandler,
              onChangeHandler,
              disableProperty,
              readOnly,
              SearchSync,
              connectToEx,
              bookCover
            )}
          </div>
        );

      case 'textarea':
        return (
          <div class="col-md-12" key={index}>
            <div className="form-group">
              {this.getTextAreaElement(
                el,
                index,
                0,
                false,
                onChangeHandler,
                onCustomChangeHandler
              )}
            </div>
          </div>
        );

      case 'file':
        //recent cahge ------------------------------------------>>
        // if (
        //   !reset &&
        //   el.name === 'temporary-book-cover' &&
        //   localStorage.getItem('mode') === 'edit'
        // ) {
        //   let newObj = data.find(el => el.name === 'temporary-book-cover');
        //   if (!newObj.value && bookCover.value) return null;
        // }
        //recent cahge ------------------------------------------>>

        // console.log("udasss",{value:el.value,imgSrc})
        if (el.value && !imgSrc) {
          imgSrc = `${localStorage.getItem('baseUrl')}${el.value}`;
        } else if (imgSrc) {
          // imgSrc = imgSrc.find(ele => ele.type === el.name).result;
        }
        // console.log("imgSrc.result:::: :: ----> ", imgSrc);

        // if(el.name === 'temporary-book-cover' && imgSrc && (imgSrc.type === 'book-cover' || imgSrc.type === 'alternative-book-cover' && imgSrc.result))
        // {
        //   return null
        // }

        return (
          <>
            <div class="col-md-12 " key={index}>
              <div className="form-group ddd">
                {getEventData(index, typeo, eventType) ? (
                  el.name === 'temporary-book-cover' ? (
                    <FileBox
                      attributes={el}
                      index={index}
                      parentIndex={0}
                      onFileHandler={onChangeFileHandler}
                      type={el.name}
                    />
                  ) : (
                    ''
                  )
                ) : (
                  <FileBox
                    attributes={el}
                    index={index}
                    parentIndex={0}
                    onFileHandler={onChangeFileHandler}
                    type={el.name}
                  />
                )}

                <span>
                  {' '}
                  {eventImageError && el.name ? (
                    <small id="mobile image" class="text-danger">
                      {eventImageError[el.name] && eventImageError[el.name]}
                    </small>
                  ) : null}
                </span>
                {imgSrc && imgSrc.type === el.name ? (
                  getEventData(index, typeo, eventType) ? (
                    ''
                  ) : (
                    <>
                      {/* {console.log('==>>>coming')} */}
                      {/* {(el.name === 'temporary-book-cover' &&  (altrnBookCover.value || bookCover.value)) ? '': */}
                      <img
                        className="edit-profile-img"
                        src={imgSrc.result}
                        height="60"
                        alt="profile-img"
                      />
                    </>
                  )
                ) : el.value &&
                  (!disapear ||
                    el.display_name === 'alternative-book-cover' ||
                    el.display_name === 'book-cover') ? (
                  <>
                    {el.name === 'temporary-book-cover' &&
                    (altrnBookCover.value || bookCover.value) ? (
                      ''
                    ) : (
                      <img
                        className="edit-profile-img"
                        src={`${localStorage.getItem('baseUrl')}${el.value}`}
                        height="60"
                        alt="profile-img"
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
            {/* {localStorage.getItem('mode') === 'edit' && */}
            {/* {console.log("----------------->>>> ", el.name)} */}
            {el.name === 'alternative-book-cover' ? (
              <div class="col-md-12 mt-2 mb-4">
                <button
                  onClick={openResetPopup}
                  type="button"
                  className="btn reset-cover-btn"
                >
                  RESET UPLOAD&#40;S&#41;
                </button>
              </div>
            ) : null}
          </>
        );

      case 'radio':
        return (
          <div class="col-md-12" key={index}>
            <div style={{ marginTop: '0px' }}>
              <div
                className="in_form"
                style={{ border: 'none', marginBottom: 0 }}
              >
                {/* <div className="in_title_two">
                  
                </div> */}
                <div
                  className="radiobuttons"
                  style={{ marginBottom: 0, display: 'inline-flex' }}
                >
                  <span style={{ marginRight: '10px' }}>{el.placeholder}:</span>
                  {this.getRadioButtons(
                    el,
                    index,
                    childIndex,
                    false,
                    onChangeHandler
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case 'checkbox':
        return (
          <div class="col-md-12 checkbox_list" key={index}>
            <div className="form-group">
              <span style={{ color: '#1f1f1f', fontWeight: '600' }}>
                {el.placeholder}
              </span>
              {this.getCheckBoxes(
                el,
                index,
                0,
                false,
                onCheckboxChangeHandler,
                eventType,
                checkRef,
                data,
                giveawayTz
              )}
            </div>
          </div>
        );

      case 'time':
        return (
          <div class="col-md-6" key={index}>
            <div className="form-group">
              <span style={{ color: '#1f1f1f', fontWeight: '600' }}>
                {el.placeholder ===
                'Start time (list time in your time zone, OTR will feature in Universal time zone)'
                  ? 'Start time (list time in your time zone, OTR will feature in Universal time zone)*:'
                  : ''}
              </span>
              {this.getTime(
                el,
                index,
                0,
                false,
                onTimeHandler,
                onCustomChangeHandler,
                el.type
              )}
            </div>
          </div>
        );

      case 'custom':
        return (
          <div class="col-md-12" key={index}>
            <div className="form-group fam_gp form_gp">
              <div className="in_form row" style={{ marginLeft: 0 }}>
                <div className="in_title">
                  {' '}
                  <label>{el.title}:</label>
                </div>
                {this.getCustomComponent(
                  el,
                  index,
                  onChangeHandler,
                  onCustomChangeHandler,
                  onChangeFileHandler,
                  onCheckboxChangeHandler,
                  addMoreServiceHandler,
                  removeServiceHandler,
                  addMoreLinkHandler,
                  onButtonClickHandler,
                  getEventData,
                  eventType,
                  '',
                  '',
                  '',
                  readOnly,
                  handleReset,
                  SearchSync,
                  connectToEx,
                  bookCover,
                  userSuggestion,
                  openUserSuggestion,
                  textBoxRef,
                  userClickHandler
                )}
              </div>
            </div>
          </div>
        );
      case 'date':
        // const tempDate = moment().format('DD-MM-YYYY');
        // alert(tempDate);
        const maxDate = moment()
          .add(18, 'M')
          .calendar();
        // let id;

        const minDate =
          localStorage.getItem('event_type') == 3 &&
          el.placeholder === 'Estimated Cover reveal date'
            ? false
            : moment().toDate();
        return (
          <>
            <div class="col-md-6" key={index}>
              <DateBox
                attributes={el}
                index={index}
                IsUnderCustom={false}
                parentIndex={index}
                onCustomHandler={onCustomChangeHandler}
                onChangeDateHandler={onChangeHandler}
                submitted={isSubmitted}
                maxDate={maxDate}
                minDate={minDate}
                id={index}
              />
            </div>
          </>
        );
      case 'switch':
        return (
          <div className="left" key={index}>
            <div className="form-group fam_gp">
              <div className="in_form" style={{ border: 'none' }}>
                <div className="toggle_switch">
                  <span className="yes_no">{el.valueOn}</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={el.value === el.valueOff}
                      name="myevents"
                      onChange={() =>
                        onChangeHandler(el.name, index, undefined)
                      }
                    />
                    <span className="slider round"></span>
                  </label>
                  <span className="yes_no">{el.valueOff}</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 'customdate':
        return (
          <div class="col-md-12" key={index}>
            <div className="form-group fam_gp">
              <div className="in_form">
                <div className="in_title">
                  {' '}
                  <span>{el.placeholder}:</span>{' '}
                </div>
                {this.getCustomComponent(
                  el,
                  index,
                  onChangeHandler,
                  onCustomChangeHandler,
                  onChangeFileHandler,
                  onCheckboxChangeHandler,
                  addMoreServiceHandler,
                  removeServiceHandler,
                  addMoreLinkHandler,
                  onButtonClickHandler,
                  getEventData,
                  eventType,
                  '',
                  '',
                  '',
                  readOnly,
                  handleReset,
                  SearchSync,
                  connectToEx,
                  bookCover,
                  userSuggestion,
                  openUserSuggestion,
                  textBoxRef,
                  userClickHandler
                )}
              </div>
            </div>
          </div>
        );

      default:
        return <div></div>;

      case 'button':
        return (
          <div class="col-md-6" key={index}>
            <div className="form-group">
              <input
                type="button"
                className={el.className}
                value={el.value}
                onClick={() => {
                  onButtonClickHandler(el);
                }}
              />
            </div>
          </div>
        );
    }
  };

  getCustomComponent = function(
    attributes,
    parentIndex,
    onChangeHandler,
    onCustomChangeHandler,
    onChangeFileHandler,
    onCheckboxChangeHandler,
    addMoreServiceHandler,
    removeServiceHandler,
    addMoreLinkHandler,
    onButtonClickHandler,
    isSubmitted,
    typeo,
    getEventData,
    eventType,
    readonly,
    handleReset,
    disableProperty,
    SearchSync,
    connectToEx,
    bookCover,
    userSuggestion,
    openUserSuggestion,
    textBoxRef,
    userClickHandler
  ) {
    position++;
    let getValue;
    return attributes.children.map((el, index) => {
      if (
        (el.tag === 'select' &&
          el.placeholder === 'Select Amazon site' &&
          el.value !== 'undefined') ||
        (el.tag === 'select' &&
          el.placeholder === 'Other retail sites' &&
          el.value !== 'undefined')
      ) {
        getValue = el.value;
      }

      el.className = 'form-control';
      switch (el.tag) {
        // case "blank":
        // return(<div className={position % 2 === 0 ? "rght" : "lft"} key={index}></div>)

        case 'input':
          return (
            <>
              {/* {console.log("this iinns the inputtttttttt   ", el)} */}
              {index === 2 ? (
                <div className="hosted-by-section">
                  <div class="col-md-6" key={index}>
                    <React.Fragment key={index + '_' + parentIndex}>
                      <TextBox
                        attributes={el}
                        IsUnderCustom={true}
                        onCustomHandler={onCustomChangeHandler}
                        onChangeTextHandler={onChangeHandler}
                        index={index}
                        parentIndex={parentIndex}
                        readOnly={
                          eventType !== 'BOOK SIGNING' ? readonly : false
                        }
                        userSuggestion={userSuggestion}
                        openUserSuggestion={openUserSuggestion}
                        textBoxRef={textBoxRef}
                        userClickHandler={userClickHandler}
                      />
                    </React.Fragment>
                  </div>
                </div>
              ) : (
                <div class="col-md-6" key={index}>
                  <React.Fragment key={index + '_' + parentIndex}>
                    <TextBox
                      attributes={el}
                      IsUnderCustom={true}
                      onCustomHandler={onCustomChangeHandler}
                      onChangeTextHandler={onChangeHandler}
                      index={index}
                      parentIndex={parentIndex}
                      workingIndex={getValue}
                      readOnly={eventType !== 'BOOK SIGNING' ? readonly : false}
                      userSuggestion={userSuggestion}
                      openUserSuggestion={openUserSuggestion}
                      textBoxRef={textBoxRef}
                      userClickHandler={userClickHandler}
                    />
                  </React.Fragment>
                </div>
              )}
            </>
          );

        case 'select':
          return (
            <div class="col-md-6">
              <React.Fragment key={index + '_' + parentIndex}>
                <div key={index + '_' + parentIndex}>
                  <SelectBox
                    attributes={el}
                    IsUnderCustom={true}
                    onCustomHandler={onCustomChangeHandler}
                    onChangeTextHandler={onChangeHandler}
                    index={index}
                    parentIndex={parentIndex}
                  />
                </div>
              </React.Fragment>
            </div>
          );
        case 'title':
          return (
            <div class="col-md-12" key={index}>
              {this.getTitleElement(
                el,
                index,
                typeo,
                getEventData,
                eventType,
                handleReset,
                '',
                '',
                '',
                '',
                onCustomChangeHandler,
                onChangeHandler,
                disableProperty,
                SearchSync,
                connectToEx,
                bookCover
              )}
            </div>
          );

        case 'date':
          return (
            <div class="col-md-6" key={index}>
              <div className="form-group">
                <DateBox
                  attributes={el}
                  index={index}
                  IsUnderCustom={true}
                  parentIndex={parentIndex}
                  onCustomHandler={onCustomChangeHandler}
                  onChangeDateHandler={onChangeHandler}
                  submitted={isSubmitted}
                />
              </div>
            </div>
          );
        case 'service':
          return (
            <div class="col-md-6" key={index}>
              <div className="form-group">
                <TextBox
                  attributes={el}
                  IsUnderCustom={true}
                  onCustomHandler={onCustomChangeHandler}
                  onChangeTextHandler={onChangeHandler}
                  index={index}
                  parentIndex={parentIndex}
                  bookrelease={getValue}
                  readOnly={eventType !== 'BOOK SIGNING' ? readonly : false}
                  userSuggestion={userSuggestion}
                  openUserSuggestion={openUserSuggestion}
                  textBoxRef={textBoxRef}
                  userClickHandler={userClickHandler}
                />
              </div>
            </div>
          );
        case 'switch':
          return (
            <div className="toggle_switch">
              <span className="yes_no">{el.valueOn}</span>
              <label className="switch">
                <input
                  type="checkbox"
                  name="myevents"
                  onChange={() =>
                    onCustomChangeHandler(el.name, index, parentIndex)
                  }
                />
                <span class="slider round"></span>
              </label>
              <span className="yes_no">{el.valueOff}</span>
            </div>
          );
        case 'add':
          return (
            <div className="ft_text" key={'plus' + index}>
              <span>
                {el.placeholder}
                <img
                  src={require('../../Utils/Assets/plus.png')}
                  alt="add"
                  onClick={() => {
                    el.type === 'service'
                      ? addMoreServiceHandler(parentIndex, index)
                      : addMoreLinkHandler(parentIndex, index);
                  }}
                  className="addMore"
                />{' '}
              </span>
            </div>
          );
        case 'minus':
          return (
            <div className="ft_text" key={'plus' + index}>
              <span>
                {el.placeholder}{' '}
                <img
                  src={require('../../Utils/Assets/minus.png')}
                  alt="add"
                  onClick={() => {
                    el.type === 'service'
                      ? removeServiceHandler(parentIndex, index)
                      : removeServiceHandler(parentIndex, index);
                  }}
                  className="addMore"
                />{' '}
              </span>
            </div>
          );
        default:
          return this.getFormElements(
            el,
            parentIndex,
            onChangeHandler,
            onCustomChangeHandler,
            onChangeFileHandler,
            onCheckboxChangeHandler,
            addMoreServiceHandler,
            removeServiceHandler,
            addMoreLinkHandler,
            onButtonClickHandler,
            index
          );
      }
    });
  };

  getCheckBoxes(
    attributes,
    parentIndex,
    index,
    IsUnderCustom,
    onCheckboxChangeHandler,
    eventType,
    checkRef,
    data,
    giveawayTz
  ) {
    return attributes.buttons.map((el, i) => {
      return (
        <>
          <div
            className="custom-control custom-checkbox white col-md-4 timeZoneControl"
            style={{ float: 'left' }}
          >
            <input
              ref={checkRef}
              type="checkbox"
              checked={
                attributes.value !== undefined &&
                attributes.value.includes(el.name)
              }
              onChange={e =>
                onCheckboxChangeHandler(
                  e.target.checked,
                  el.name,
                  parentIndex,
                  i
                )
              }
              id={'check_' + parentIndex + '_' + i}
              // style={{ display: 'none' }}
              name="test"
              required={eventType === 'Giveaway' ? attributes.required : ''}
              onInvalid={e => {
                e.target.setCustomValidity(
                  eventType === 'Giveaway' && !giveawayTz
                    ? 'Please check a box to proceed.'
                    : ''
                );
              }}
            />
            {/* <label
              htmlFor={'check_' + parentIndex + '_' + i}
              className="checkbox_label_black"
            >
              <div className="mark"></div>
            </label> */}
            <p>{el.name}</p>
          </div>
        </>
      );
    });
  }

  getTime(
    attributes,
    parentIndex,
    index,
    IsUnderCustom,
    onTimeHandler,

    onCustomChangeHandler,
    type
  ) {
    const time = attributes.value
      ? (() => {
          const arr = attributes.value.split(':');
          const hour = arr[0];
          // const minuteAndTime = arr[1].split(' ');
          const minute = arr[1].split(' ')[0];
          return { hour, minute };
        })()
      : { hour: 0, minute: 0 };

    return (
      <div
        className="custom-control custom-checkbox white time-picker "
        style={{ float: 'left' }}
      >
        <TimePicker
          // defaultValue={moment()}
          // value={attributes.value ? attributes.value : ''}
          value={moment()
            .hour(time.hour)
            .minute(time.minute)}
          onChange={value => onTimeHandler(value, index, parentIndex)}
          showSecond={false}
          type={type}
          placeholder="HH:MM"
          hideDisabledOptions
          minuteStep={5}
          use12Hours
        />
      </div>
    );
  }

  getRadioButtons(
    attributes,
    parentIndex,
    index,
    IsUnderCustom,
    onChangeHandler
  ) {
    return attributes.buttons.map((el, i) => {
      return (
        <div className="rdio rdio-primary radio-inline">
          <input
            name={attributes.name}
            id={'radio_' + parentIndex + '_' + i}
            type="radio"
            className="author-radio"
            value="1"
            checked={attributes.value === el.name}
            onChange={() => onChangeHandler(el.name, parentIndex, index)}
          />
          <label key={el.name} htmlFor={'radio_' + parentIndex + '_' + i}>
            {el.name}
          </label>
        </div>
      );
    });
  }

  getTextAreaElement(
    attributes,
    index,
    parentIndex,
    IsUnderCustom,
    onChangeHandler,
    onCustomChangeHandler
  ) {
    return (
      <textarea
        type={attributes.type}
        value={attributes.value ? attributes.value : ''}
        className={attributes.className}
        placeholder={attributes.placeholder}
        onChange={e =>
          IsUnderCustom
            ? onCustomChangeHandler(e.target.value, index, parentIndex)
            : onChangeHandler(e.target.value, index)
        }
        required={attributes.validation}
        maxLength={attributes.maxLength ? attributes.maxLength : 10000}
      ></textarea>
    );
  }

  getTitleElement(
    attributes,
    index,
    typeo,
    getEventData,
    eventType,
    fileData,
    data,
    reset,
    handleReset,
    onCustomChangeHandler,
    onChangeHandler,
    disableProperty,
    readOnly,
    SearchSync,
    connectToEx,
    bookCover
  ) {
    // console.log("i am here attributes--> ", attributes)
    if (
      attributes.title === 'Connect to existing events' &&
      eventType === 'Book Release'
    ) {
      return (
        <>
          <label>{connectToEx.title}</label>
          <div className="form-group">
            <TextBox
              attributes={SearchSync}
              IsUnderCustom={false}
              onCustomHandler={onCustomChangeHandler}
              onChangeTextHandler={onChangeHandler}
              index={index}
              parentIndex={0}
              user={SearchSync.value}
              valueDisable={disableProperty}
              readOnly={eventType !== 'BOOK SIGNING' ? readOnly : false}
            />
          </div>
        </>
      );
    }

    //recent cahge ------------------------------------------>>
    // if (
    //   !reset &&
    //   (attributes.title === 'Upload Temporary book cover' ||
    //     attributes.title ===
    //       '(will automatically be replaced if Official book cover is uploaded)') &&
    //   localStorage.getItem('mode') === 'edit'
    // ) {
    //   let newObj = data.find(el => el.name === 'temporary-book-cover');
    //   if (!(newObj && newObj.value) && (bookCover.value)) return null;
    // }
    //recent cahge ------------------------------------------>>

    // console.log("here is the example of index------> ", index, 'and type=', typeo);
    return (
      <>
        {attributes.title === 'Sync to Book Release' ? (
          <>
            <i>{attributes.title}</i>
          </>
        ) : // (typeo === 'book-cover' || typeo === 'alternative-book-cover') &&
        // index ===
        getEventData(index, typeo, eventType) ? (
          attributes.title === 'Upload Temporary book cover' ? (
            <label>{attributes.title}</label>
          ) : (
            <p>{attributes.title}</p>
          )
        ) : (
          <>
            {attributes.title ===
            '(will automatically be replaced if Official book cover is uploaded)' ? (
              <>
                <p>{attributes.title}</p>
              </>
            ) : attributes.title ===
              '(Discreet, Alternative or Limited edition cover)' ? (
              <>
                <p>{attributes.title}78</p>
              </>
            ) : attributes.title === 'Link to event*' ? (
              <>
                <label className="label_top"></label>
              </>
            ) : attributes.title === 'Link to ticket sales' ? (
              <>
                <label className="label_top"></label>
              </>
            ) : attributes.title === 'List of attending authors' ? (
              <>
                <label className="label_top">
                  List of attending/participating authors{' '}
                </label>
              </>
            ) : (
              <>
                <label className="label_top">{attributes.title}</label>
              </>
            )}
            {attributes.title ===
            '(will automatically be replaced if Official book cover is uploaded)' ? (
              <></>
            ) : attributes.title ===
              '(Discreet, Alternative or Limited edition cover)' ? (
              <></>
            ) : attributes.title === 'Link to event*' ? (
              <></>
            ) : attributes.title === 'Link to ticket sales' ? (
              <></>
            ) : (
              <>:</>
            )}
          </>
        )}
      </>
    );
  }

  componentDidMount() {
    position = 0;
  }
}
export default HTMLParser;
