import { combineReducers } from 'redux';
import {
  userlists,
  userloading,
  usererror,
  signuppage,
  signuploading,
  checkIsFollowed,
  searchByUsername,
  isBetaReaderExist,
  betaUserData,
  openOtpModal,
  editUserData,
  getMembershipDetails,
  success,
  getFollowPrCode,
  isMemberhsipUpgradable,
  buyBlastStatus,
  betaUserAccess,
  autoRenewal,
  userSearchresult,
  initialSearchresult
} from './users';
import {
  profileError,
  profileLoading,
  profileData,
  callListApi,
  userType,
  userListData,
  myProfileData,
  userProfileStatic,
  previewProfile,
  changeMode,
  userProfile,
  getBlastDetails,
  changePopularEvents,
  sevenDaysLoading,
  newLoader
} from './profile';
import {
  eventError,
  eventLoading,
  eventData,
  events,
  eventsummary,
  filterevent,
  Tab,
  previewData,
  getEventButtons,
  dashboardTabs,
  getEventsGroup,
  editEventType,
  getEventMode,
  getFilterEventData,
  addToFavourite,
  searchTitle,
  addToMyCalendar,
  getAssociatedEvents,
  getSingleEventDetails,
  sendMailModel,
  disableButtonRelease,
  getDateWiseTasks,
  getBookRelease,
  getLinkedEventList,
  getBookReleaseForUsers,
  getBookReleaseEventBlast,
  duplicateEventExist,
  checkForSync,
  eventMost,
} from './event';
import { Search, eventFilter, sort } from './filter';
import { dailyTask, filterTask, addTab } from './dailytask';
import { genrateClientToken } from './payment';
import {
  conversationList,
  getMessageList,
  getNotification,
  countUnreadedMessages,
} from './message';


import { AddProblem } from './problem';

export default combineReducers({
  userlists,
  userloading,
  usererror,
  signuppage,
  signuploading,
  profileError,
  profileLoading,
  profileData,
  callListApi,
  userType,
  sevenDaysLoading,
  userListData,
  myProfileData,
  userProfileStatic,
  previewProfile,
  changeMode,
  checkIsFollowed,
  searchByUsername,
  isBetaReaderExist,
  openOtpModal,
  betaUserData,
  editUserData,
  userProfile,
  getMembershipDetails,
  success,
  getFollowPrCode,
  isMemberhsipUpgradable,
  getBlastDetails,
  buyBlastStatus,
  betaUserAccess,
  changePopularEvents,
  eventError,
  eventLoading,
  eventData,
  events,
  eventsummary,
  filterevent,
  Tab,
  previewData,
  getEventButtons,
  dashboardTabs,
  getEventsGroup,
  editEventType,
  getEventMode,
  getFilterEventData,
  addToFavourite,
  searchTitle,
  addToMyCalendar,
  getAssociatedEvents,
  getSingleEventDetails,
  sendMailModel,
  disableButtonRelease,
  getDateWiseTasks,
  getBookRelease,
  getLinkedEventList,
  getBookReleaseForUsers,
  getBookReleaseEventBlast,
  Search,
  eventFilter,
  sort,
  dailyTask,
  filterTask,
  conversationList,
  getMessageList,
  getNotification,
  countUnreadedMessages,
  genrateClientToken,
  AddProblem,
  duplicateEventExist,
  checkForSync,
  eventMost,
  autoRenewal,
  addTab,
  newLoader,
  userSearchresult,
  initialSearchresult
});
