import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { setupEvent, getFilterEventDataList ,addEventToFavourite} from '../../../Actions/event-setup';
import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';

const overlayNoRowsTemplate = '<span>No record found</span>';

class Newsletter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      eventData: {},
      rowData: [],
      data: [],
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          resizable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 150,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'SUBJECT',
          field: 'time',
          sortable: true,
          filter: true,
          width: 140,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'VIEW BUTTON',
          field: 'addcalender'

          //cellRenderer: this.showAddCalender.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${params.data.author_last_name ? params.data.author_last_name : ''}`;
  }

  showTime(params) {
    return params.data.time ? this.convertTo12Hour(params.data.time) : '-';
  }
  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    // var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }
  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }
  getFormattedValue(params) {}

  titleCellRenderer(params) {
    if (params.data.event_type === 'Giveaway') {
      return `${
        params.data.giveaway
          ? params.data.giveaway.length > 20
            ? params.data.giveaway.substr(0, 20) + '...'
            : params.data.giveaway
          : ''
      } `;
    } else if (params.data.event_type === 'Release Party') {
      return `${
        params.data.releaseparty
          ? params.data.releaseparty.length > 20
            ? params.data.releaseparty.substr(0, 20) + '...'
            : params.data.releaseparty
          : ''
      } `;
    } else {
      return `${
        params.data.title
          ? params.data.title.length > 20
            ? params.data.title.substr(0, 20) + '...'
            : params.data.title
          : ''
      } `;
    }
  }

  showEvent(e) {
    if (e.column.colId == 'favourite') {
      const data = this.props.filterData;
      data.isGeneral = this.props.eventMode;
      if (this.props.sort) {
        data.sort = this.props.sort;
      }
      this.props.addToFavourite(
        `/api/v1/favourite/${e.data.id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        data,
        'eventFilter'
      );
    } else {
      const event_name = {
        event_type_1: 'Book Release',
        event_type_2: 'Giveaway',
        event_type_3: 'Book Promo',
        event_type_4: 'Release Party',
        event_type_5: 'Cover Reveal',
        event_type_6: 'Book Signing',
        event_type_7: 'ARC Sign-up',
        'ticket sales': 'Ticket Sales'
      };
      e.data.event_type = event_name[e.data.event_type];
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  //===================== RENDER ==========================
  render() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    return (
      <>
        <div className="container event-filter-page">
          <div className="mb-4">
            <div className="color_guide mb-3">
              <div className="row">
                <div className="legend">
                  <span className="color_box">RELEASE PARTY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">ARC SIGNUP</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK PROMO</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">COVER REVEAL</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">GIVEAWAY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box text-uppercase">BOOK RELEASE</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>

                <div className="legend">
                  <span className="color_box text-uppercase">
                    Boxes & Signings
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box text-uppercase">
                    Ticket/box sales
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
              </div>
            </div>

            <h2 className="text-center heading">OTR NEWSLETTER</h2>
          </div>
          <div
            className={
              this.props.isTheameLight
                ? 'ag-theme-balham'
                : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            <AgGridReact
              onGridReady={this.onGridReady}
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={this.state.data}
              pagination={true}
              reactNext={true}
              animateRows
              onCellClicked={this.showEvent.bind(this)}
              // onRowClicked={this.addToFavourite.bind(this)}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
              overlayNoRowsTemplate={overlayNoRowsTemplate}
            />
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          {this.state.modal ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
            />
          ) : null}
        </div>
      </>
    );
  }

  // componentWillReceiveProps(props) {
  //   console.log('helo', props);
  //   let eventData = [];
  //   if (props.data.length) {
  //     props.data.map(data => {
  //       return eventData.push(data.event);
  //     });
  //   }

  //   this.setState({ data: eventData });
  // }

  componentDidMount() {
    let eventData = [];
    if (this.props.data)
      if (this.props.data.length) {
        this.props.data.map(data => {
          return eventData.push(data.event);
        });
      }
    this.setState({ data: eventData });
  }
}

const mapStateToProps = state => {
  // console.log({ });
  return {
    // data: state.getNotification.currentUserData
  };
};

const mapDispatchToProps = dispatch => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
