import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmail } from '../../Actions/index';
import Layout from '../layout/layout';
import '../Landing/landing.css';
import { Helmet } from 'react-helmet';

class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isSubmitted: false
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  onSubmitFormHandler(e) {
    this.setState({ isSubmitted: true });
    e.preventDefault();

    let data = {
      email: this.state.email
    };
    this.props.sendEmail('/api/v1/forgot/password', data);
  }

  render() {
    return (
      <>
        <Helmet>
          <html className="remove-header" lang="en" />
        </Helmet>
        <Helmet>
          <html className="remove-footer" lang="en" />
        </Helmet>
        <Layout>
          <div className="forgot-password">
            <div style={{ backgroundColor: '#1f1f1f' }}>
              <div className="container-fluid">
                <div className="row mainleft">
                  <div className="main_right author_profile">
                    <div className="setup_p_hed">
                      <h4>Forgot Password</h4>
                    </div>

                    <span>
                      <form
                        className="form_custom"
                        onSubmit={e => this.onSubmitFormHandler(e)}
                      >
                        <div className="code_main">
                          <div className="signup_text">
                            <p>
                              Please check your email to reset your password.
                              <br />
                            </p>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="email"
                                name="email"
                                className="form-control email_s"
                                placeholder="Email address*"
                                onChange={this.handleChangeEmail}
                                required
                                autoFocus
                              />
                            </div>

                            <div className="form-group">
                              <button className="btn btn-filled" type="submit">
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </>
    );
  }

  componentDidMount() {}
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  sendEmail: (url, data) => dispatch(getEmail(url, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
