import Axios from 'axios';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BASEURL } from '../../../constant';
class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ''
    };

    this.getTermsConditions = this.getTermsConditions.bind(this);
  }

  async getTermsConditions() {
    try {
      const response = await Axios.get(
        BASEURL + '/api/v1/users/getTermsConditions'
      );
      if (response.data.length)
        this.setState({ content: response.data[0].consent_body });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className="terms-condition">
        <div className="container">
          <div className="mb-4">
            <h2 className="text-center heading">TERMS &amp; CONDITIONS</h2>
          </div>
          {this.state.content && (
            <div
              contentEditable="false"
              className="msg-text"
              dangerouslySetInnerHTML={{ __html: this.state.content }}
            ></div>
          )}

          <div className="edit-profile-btn">
            <a href="/" class="">
              <button
                type="submit"
                className="btn new_btn_save_bt m-0"
                style={{
                  marginRight: '0% !important'
                }}
              >
                Back to Dashboard
              </button>
            </a>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.getTermsConditions();
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => {};

const mapDispatchToProps = dispatch => {};

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
