import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { connect } from 'react-redux';
import {
  setupEvent,
  getFilterEventDataList,
  addEventToFavourite,
  getPopularEvents,
  addEventToMyCalendar
} from '../../../Actions/event-setup';
import { addEventFilter } from '../../../Actions/filters';

import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';
import { PureComponent } from 'react';

const overlayNoRowsTemplate = '<span>No record found</span>';

class TrandingEvents extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      eventData: {},
      popularEvents: false,
      rowData: [],
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'isIncluded ',
          field: 'isIncluded',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          filter: true,
          //suppressMovable: true,
          unSortIcon: true,
          width: 295,
          cellRenderer: function(event_type) {
            var backgroundColor;
            let event_list_value = event_type.value;
            if (event_list_value.length > 13) {
              event_list_value = event_type.value.slice(0, 13) + '...';
            }
            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF65FF,#FF79BC';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#fFDB69, #F3FF85';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#8E5BFF,#B469FF';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Signup'
            ) {
              backgroundColor = '#FF5E8E, #FF5858';
            }
            if (event_type.value === 'Ticket Sales') {
              backgroundColor = '#B9B9FF,#DCB9FF';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              event_list_value
            );
          }
        },
        {
          headerName: 'TITLE/NAME',
          field: 'commanTitle',
          sortable: true,
          filter: true,
          //suppressMovable: true,
          unSortIcon: true,
          width: 200,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'author_first_name',
          sortable: true,
          filter: true,
          //suppressMovable: true,
          unSortIcon: true,
          width: 235,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 210,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 175,
          cellRenderer: function(endDate) {
            if (endDate.data.end_date) {
              return moment(endDate.data.end_date).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TIME (US EASTERN)',
          field: 'time',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 185,
          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          width: 195,
          suppressMovable: true,
          unSortIcon: true
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          width: 210,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'FAVORITE',
          field: 'favourite',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 250,
          cellRenderer: function(favourite) {
            if (favourite.data.favourite) {
              return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
            } else {
              return '<i class="fa fa-star-o" style="font-size:20px;"></i>';
            }
          }
        },
        {
          headerName: 'ACTION',
          field: 'addcalender',
          cellRenderer: this.showAddCalender.bind(this)
        }
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }

  nameCellRenderer(params) {
    let midName = params.data.author_mid_name;

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }

  titleCellRenderer(params) {
    if (params.data.event_id === 'event_type_2') {
      let eventWithValues = JSON.parse(params.data.event_details_with_values);
      let getData = eventWithValues.find(el => el['Name of Giveaway']);
      let finalGiveaway = Object.values(getData)[0];
      return `${finalGiveaway ? finalGiveaway : ''}`;
    } else if (params.data.event_id === 'event_type_4') {
      let eventWithValues = JSON.parse(params.data.event_details_with_values);
      let getData = eventWithValues.find(el => el['release-party-event-name']);
      let finalReleaseParty = Object.values(getData)[0];

      return `${finalReleaseParty ? finalReleaseParty : ''}`;
    } else {
      return `${params.data.title ? params.data.title : ''} `;
    }
  }

  showAddCalender(params) {
    // let details = params.data.event_details_with_values;
    let is = params.data.isIncluded;
    let ownedByMe = params.data.ownedByMe;

    // console.log("---------> ", this.props.eventMode, is, ownedByMe);

    if (this.props.eventMode === true && is === false && ownedByMe === false) {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    } else if (
      this.props.eventMode === true &&
      is === true &&
      ownedByMe === false
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === true
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else if (
      this.props.eventMode === false &&
      is === true &&
      ownedByMe === true
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else if (
      this.props.eventMode === false &&
      is === true &&
      ownedByMe === false
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else if (
      this.props.eventMode === true &&
      is === true &&
      ownedByMe === true
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    }
  }

  getFormattedValue(params) {}
  showTime(params) {
    return params.data.time
      ? this.getEstTimeNow(new Date(), params.data.time)
      : '-';
  }
  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    // var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }
  getEstTimeNow(date, time) {
    const dates = date || new Date();
    const convertedTime = moment(time, 'hh:mm A').format('HH:mm');

    let start2 = moment(dates).toDate();
    const time2 = convertedTime.split(':');

    start2.setHours(time2[0]);
    start2.setMinutes(time2[1]);
    start2.setSeconds(0);
    const dt2 = new Date(start2);

    const timeZone = 'America/New_York';
    let myTime = dt2.toLocaleString('en-US', {
      timeZone
    });
    let timeTaken = moment(myTime).format('hh:mm A');

    return timeTaken;
  }

  amPm(time) {
    const hour = time.split(':')[0] * 1;
    return hour > 12 ? 'PM' : 'AM';
  }

  showEvent(e) {
    if (e.column.colId === 'favourite') {
      const data = this.props.filterData;
      data.isGeneral = this.props.eventMode;
      if (this.props.sort) {
        data.sort = this.props.sort;
      }
      this.props.addToFavourite(
        `/api/v1/favourite/${e.data.id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        data,
        'trendingList'
      );
    } else if (e.column.colId === 'addcalender') {
      let data = e.data;
      let actionType = e.event.target.getAttribute('data-action-type');

      this.props.addToMyCalendar(
        `/api/v1/add_mycalendar/${e.data.id}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        data,
        'trendingList',
        this.props.filterData
      );
    } else {
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
  };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  handleMyEvents = status => {
    if (status) {
      this.props.getPopularEvents(`api/v1/events/getPopularEvents`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });
    }
  };
  handleRefresh = () => {
    window.location.reload();
  };

  //===================== RENDER ==========================
  render() {
    let membershipId = localStorage.getItem('membershipId');
    // if (this.props.eventsData) {
    //   this.props.eventsData.forEach(el => {
    //     let d = el.event_details_with_values.find(fin => fin['ARC-Sign-up']);
    //     console.log('nnnnnnnnnnnnnnnnnnn', d);
    //   });
    // }

    let data = [];
    let filterData = [];
    if (this.props.eventsData.length) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      data = this.props.eventsData;

      console.log('here is the event data---> ', data);
      // data.forEach(element => {
      //     let e = {}
      //     e = element
      //     e.start = moment(element["start_date"]).format("MM/DD/YYYY")
      //     e.end = moment(element["end_date"]).format("MM/DD/YYYY")
      //     e.created_date = moment(element["created_date"]).format("MM/DD/YYYY")
      // });
    }

    const endDate = new Date();
    const createDate = endDate.setDate(endDate.getMonth() + 18);
    const createdDate = moment(createDate).format('MM/DD/YYYY');
    if (membershipId == 2) {
      filterData = data.filter(
        el => el.event_type_id !== 3 && el.event_type_id !== null
        // && new Date(el.start_date) >= new Date(createdDate)
      );

      let arcValue = '';
      let fi = [];
      let filterArcSign = filterData.filter(el => el.event_type_id === 7);
      if (filterArcSign.length > 0) {
        let a = filterArcSign[0].event_details_with_values;
        if (a.length) {
          let b = JSON.parse(a).find(fi => fi['ARC-Sign-up']);
          arcValue = b['ARC-Sign-up'];
          if (arcValue === 'Open to all OTR members') {
            fi = filterData;
          } else {
            fi = filterData.find(e => e.event_type_id === 7);
          }
        }
      }
      filterData = fi;
    } else {
      filterData = data.filter(
        el =>
          // new Date(el.start_date) >= new Date(createdDate) &&
          el.event_type_id !== null
      );
    }
    //console.log('filterData', filterData);
    let collectId = [];
    let collectId1 = [];
    let collectEvent = [];
    let newData = [];
    data.forEach(el => {
      let obj = { ...el };
      // console.log("el.event_type--> ", el.event_type);
      if (el.event_id === 'event_type_2') {
        let eventWithValues = JSON.parse(el.event_details_with_values);
        let getData = eventWithValues.find(el => el['Name of Giveaway']);
        let finalGiveaway = Object.values(getData)[0];
        obj.commanTitle =
          finalGiveaway.length > 15
            ? finalGiveaway.substr(0, 20) + '...'
            : finalGiveaway;
      } else if (el.event_id === 'event_type_4') {
        let eventWithValues = JSON.parse(el.event_details_with_values);
        let getData = eventWithValues.find(
          el => el['release-party-event-name']
        );
        let finalReleaseParty = Object.values(getData)[0];

        obj.commanTitle =
          finalReleaseParty.length > 15
            ? finalReleaseParty.substr(0, 20) + '...'
            : finalReleaseParty;
      } else {
        obj.commanTitle = el.title
          ? el.title.length > 20
            ? el.title.substr(0, 20) + '...'
            : el.title
          : '';
      }

      obj.commanTitle = obj.commanTitle.toLowerCase();
      newData.push(obj);
    });
    filterData = [...newData];

    let dataCheck = filterData.map(el => {
      if (el.update_cancel_cron === 0) {
        collectEvent.push(el);
      } else {
        collectId1.push(el);
      }
    });
    let checkEventFilter;
    const createdFilterDate = moment().format('YYYY-MM-DD');
    if (collectId && collectId.length > 0) {
      checkEventFilter = collectId.filter(
        el => el.start_date < createdFilterDate
      );
    } else {
      checkEventFilter = collectEvent.filter(
        el => el.start_date < createdFilterDate
      );
    }
    let togetherEvent = [...checkEventFilter, ...collectId1];

    // console.log("this is the filter data-- > " ,filterData);
    // console.log('now this is the most followed data- - - - - - - - - ->> ', filterData);
    filterData = filterData.sort((a, b) => {
      // console.log({a: a.start_date , b: b.start_date})
      if (new Date(a.start_date) > new Date(b.start_date)) {
        return -1;
      }
    });

    // console.log("filterData after sorting- > ", filterData);
    return (
      <>
        <div className="event-filter-trending">
          <div className="container event-filter-page">
            <div className="mb-4">
              <div className="color_guide mb-3">
                <div className="row">
                  <div className="legend">
                    <span className="color_box">RELEASE PARTY</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box">ARC SIGNUP</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box">BOOK PROMO</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box">COVER REVEAL</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box">GIVEAWAY</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box">BOOK RELEASE</span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>

                  <div className="legend">
                    <span className="color_box text-uppercase">
                      Boxes & Signings
                    </span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                  <div className="legend">
                    <span className="color_box text-uppercase">
                      Ticket/box sales
                    </span>
                    {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                  </div>
                </div>
              </div>
              {/* {!this.props.isReader && !this.props.isBlogger ? (
              <>
                <div className="col-md-12 mb-2 setup-btn">
                  <button
                    onClick={e => this.props.openEventModal()}
                    className={
                      this.props.isTheameLight
                        ? 'btn btn_save_bt setup_event_bt daily'
                        : 'btn btn_save_bt white-button setup_event_bt daily'
                    }
                  >
                    <span>Setup Event</span>
                    <img
                      src={require('../../../Utils/Assets/plus.png')}
                      alt="cup-icon"
                    />
                  </button>
                </div>
              </>
            ) : (
              <></>
            )} */}

              <h2 className="text-center heading">Trending Events</h2>
            </div>

            <div
              className={
                !this.props.myevents
                  ? 'ag-theme-balham'
                  : 'ag-theme-dark home-ag'
              }
              style={{}}
            >
              <AgGridReact
                onGridReady={this.onGridReady}
                columnDefs={this.state.columnDefs}
                defaultColDef={this.state.defaultColDef}
                rowData={filterData}
                pagination={true}
                reactNext={true}
                animateRows
                onCellClicked={this.showEvent.bind(this)}
                // onRowClicked={this.addToFavourite.bind(this)}
                paginationPageSize={this.state.paginationPageSize}
                paginationNumberFormatter={this.state.paginationNumberFormatter}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              />
              <div
                className="search-features"
                style={{
                  marginTop: '10px',
                  position: 'relative',
                  top: 5,
                  left: 5
                }}
              >
                <p>
                  <i>
                    Each column has search feature. Column width can be changed
                    by dragging. Hit refresh to reset.
                  </i>
                  <i
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer'
                    }}
                    className="fa fa-refresh"
                    onClick={this.handleRefresh}
                  />
                </p>
              </div>
              <div className="test-header" style={{ float: 'right' }}>
                Page Size:
                <select
                  onChange={this.onPageSizeChanged.bind(this)}
                  id="page-size"
                  defaultValue="10"
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            {this.state.modal ? (
              <EventModal
                isOpen={this.state.modal}
                eventData={this.state.eventData}
                toggle={this.hideEvent}
                isInclude={this.state.eventData.isIncluded}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }

  componentWillReceiveProps(prevProps) {
    if (this.props.favouriteData) {
      //   this.props.getFilterEvent(`/api/v1/event_filter`,this.props.filterData, { headers: { 'x-authorization': localStorage.getItem("token") } });
    } else {
      if (this.gridApi) this.gridApi.setQuickFilter(this.props.search);
    }

    if (prevProps.checkPopularEvents !== this.state.popularEvents) {
      this.setState({ popularEvents: prevProps.checkPopularEvents }, () => {
        this.handleMyEvents(prevProps.checkPopularEvents);
        // this.props.getPopularEvents(`api/v1/events/getPopularEvents`, {
        //   headers: { 'x-authorization': localStorage.getItem('token') }
        // });
        let data = this.props.eventsData;

        // this.props.getFilterEvent(
        //   `/api/v1/event_filter?isGeneral=${false}`,
        //   data,
        //   { headers: { 'x-authorization': localStorage.getItem('token') } }
        // );
      });
    }
  }

  componentDidMount() {
    this.props.getPopularEvents(`api/v1/events/getPopularEvents`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  componentWillUnmount() {
    this.props.addFilterData({});
  }
}

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    filterData: state.eventFilter,
    sort: state.sort,
    eventsData: state.getFilterEventData,
    favouriteData: state.addToFavourite,
    eventMode: state.getEventMode,
    checkPopularEvents: state.changePopularEvents,
    events: state.events
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setupEvent: () => dispatch(setupEvent()),
    addFilterData: data => dispatch(addEventFilter(data)),
    getFilterEvent: (url, data, header) =>
      dispatch(getFilterEventDataList(url, data, header)),
    addToFavourite: (url, header, data, pageName) =>
      dispatch(addEventToFavourite(url, header, data, pageName)),
    getPopularEvents: (url, header) => dispatch(getPopularEvents(url, header)),
    addToMyCalendar: (url, header, data, pageName, eventsData) =>
      dispatch(addEventToMyCalendar(url, header, data, pageName, eventsData))
    // addToMyCalendar: eventId =>
    //   dispatch(
    //     addEventToMyCalendar(`/api/v1/add_mycalendar/${eventId}`, {
    //       headers: { 'x-authorization': localStorage.getItem('token') }
    //     })
    //   )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TrandingEvents);
