import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLoader } from 'react-spinners';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Route, Link, Redirect, withRouter, Navigate } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  getProfileDetails,
  Tab,
  filterevent
} from '../../Actions/profile-setup';
import Messenger from './Messenger';

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileKeyData: []
    };
  }

  render() {
    return (
      <div className="App">
        <Messenger 
         myProfile = {this.props.profileData}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      loading: state.profileLoading,
      profileData: state.myProfileData,
  }
};

const mapDispatchToProps = dispatch => {
  // return {
  //     getUserProfile: (url,header,edit) => dispatch(getProfileDetails(url,header,edit))
  // };
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
