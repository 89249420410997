export function userlists(state = '', action) {
  switch (action.type) {
    case 'USERS_LOADED':
      return action.token;

    default:
      return state;
  }
}

export function userloading(state = false, action) {
  switch (action.type) {
    case 'USERS_LOADING':
      return action.status;

    default:
      return state;
  }
}

export function signuploading(state = false, action) {
  // console.log("state in signuploading ", state);
  switch (action.type) {
    case 'SIGNUP_LOADING':
      return action.signup_status;

    default:
      return state;
  }
}

export function usererror(state = true, action) { 
  switch (action.type) {
    case 'USERS_ERROR':
      return action.error;
    default:
      return state;
  }
}

export function signuppage(state = 0, action) {
  switch (action.type) {
    case 'SIGNUP_PAGE':
      return action.page;

    default:
      return state;
  }
}

export function checkIsFollowed(state = false, action) {
  switch (action.type) {
    case 'IS_FOLLOWED':
      return action.data;

    default:
      return state;
  }
}

export function isBetaReaderExist(state = true, action) {
  switch (action.type) {
    case 'IS_BETA_READER':
      return action.data;

    default:
      return state;
  }
}

export function betaUserData(state = {}, action) {
  switch (action.type) {
    case 'BETA_DATA':
      return action.data;

    default:
      return state;
  }
}

export function editUserData(state = {}, action) {
  switch (action.type) {
    case 'EDIT_USER':
      return action.data;

    default:
      return state;
  }
}

export function openOtpModal(state = false, action) {
  switch (action.type) {
    case 'OPEN_OTP_MODAL':
      return action.data;

    default:
      return state;
  }
}

export function searchByUsername(state = [], action) {
  switch (action.type) {
    case 'USER_NAME':
      return action.data;
    default:
      return state;
  }
}

export function getMembershipDetails(state = [], action) {
  switch (action.type) {
    case 'MEMBERSHIP_DETAILS':
      return action.data;
    default:
      return state;
  }
}

export function success(state = false, action) {
  switch (action.type) {
    case 'SHOW_THANKYOU':
      return action.data;
    default:
      return state;
  }
}

export function getFollowPrCode(state = [], action) {
  switch (action.type) {
    case 'GET_FOLLOW_CODE':
      return action.data;
    default:
      return state;
  }
}

export function isMemberhsipUpgradable(state = false, action) {
  switch (action.type) {
    case 'IS_MEMEBERSHIP_UPGRADABLE':
      return action.bool;
    default:
      return state;
  }
}

export function buyBlastStatus(state = false, action) {
  switch (action.type) {
    case 'BUY_BLAST':
      return action.bool;
    default:
      return state;
  }
}

export function betaUserAccess(state = false, action) {
  switch (action.type) {
    case 'BETA_ACCESS':
      return action.bool;
    default:
      return state;
  }
}

export function autoRenewal(state = '', action) {
  switch (action.type) {
    case 'disabled_msg':
      return action.data;
    default:
      return state;
  }
}

export function userSearchresult(state = [], action) {
  switch (action.type) {
    case 'user_search':
      return action.data;
    default:
      return state;
  }
}

export function initialSearchresult(state = [], action) {
  switch (action.type) {
    case 'initial_author':
      return action.data;
    default:
      return state;
  }
}