import React, { Component } from 'react';
import { Link, Route, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { connect } from 'react-redux';
import { authenticate, signuppage } from '../../Actions/index';
import './landing.css';
import '../Footer/footer.css';
import CONSTANT from '../../constant';
// import Footer from '../Footer/footer';
import axios from 'axios';
import { BASEURL } from '../../constant';

const cookies = new Cookies();

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      isSocial: false,
      selectedLoader: 0,
      email: '',
      password: '',
      isSocial: false,
      showpwd: false,
      key: 0,
      rememberMe: false,
      ipAddress: ''
    };
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
  }

  handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleLoginSubmit(e, history) {
    e.preventDefault();
    this.props.fetchData(
      '/api/v1/login',
      this.state.email,
      this.state.password,
      this.state.isSocial,
      history
    );
  }

  // handleSaveCookies = async () => {
  //   localStorage.setItem("cookiepolicy", true);
  //   const payload = {
  //     consent_type: "cookies policy",
  //     content: "",
  //     version: 14, //dummy
  //     id: 14,    //dummy
  //     source: "website",
  //     ipAddress: this.state.ipAddress,
  //     reason: "Cookie-pop-up 1/yea",
  //     ip: this.state.ipAddress,
  //     userId: localStorage.getItem('userId') ? localStorage.getItem('userId') : "",
  //   }
  //   await axios.post(BASEURL + "/api/v1/users/postUserConsent", payload, {
  //     headers: {
  //       "x-authorization": localStorage.getItem("token"),
  //     },
  //   });
  // }
  // async getIpAddress() {
  //   try {
  //     // const response = await axios.get("https://geolocation-db.com/json/");
  //     const response = await axios.get("https://api.ipify.org/?format=json");
  //     this.setState({ ipAddress: response.data.ip });
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  render() {
    return (
      <>
        {/* <Logo /> */}
        <div className="btn-login-1">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="navbar-collapse">
              <ul className="navbar-nav mr-auto" />
              <Route
                render={({ history }) => (
                  <form
                    className="form-inline my-2 my-lg-0"
                    onSubmit={e => {
                      this.handleLoginSubmit(e, history);
                    }}
                    autoComplete="off"
                  >
                    <input
                      className="form-control form_cust_hed mr-sm-2"
                      type="text"
                      placeholder="Email or Username"
                      aria-label=""
                      value={this.state.email}
                      onChange={this.handleChangeEmail}
                      required
                      autoComplete="off"
                    />
                    <input
                      className="form-control form_cust_hed mr-sm-2"
                      type="password"
                      placeholder="Password"
                      aria-label=""
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                      required
                      autoComplete="off"
                    />
                    <button
                      className="btn btn_h_login my-2 my-sm-0"
                      type="submit"
                    >
                      Quick LogIn
                    </button>
                  </form>
                )}
              />
            </div>
          </nav>

          <div className="mid-section landing-page">
            <div className="main-wrapper">
              <div className="container">
                <section className="optimizeBox">
                  <div className="row">
                    <div className="col-12 text-center main-title">
                      <h1>Your Romance book life, optimized</h1>
                    </div>
                    <div className="col-12 col-md-3 text-md-left text-center">
                      <img
                        className="brand-logo black-logo"
                        src={require('../../Utils/Assets/Black_Background.png')}
                        alt="Logo"
                      />
                      <img
                        className="brand-logo white-logo"
                        src={require('../../Utils/Assets/logo_3.png')}
                        alt="Logo"
                      />
                    </div>
                    <div className="col-12 col-md-9 text-md-left text-center pl-5">
                      <p className="welcome-text">
                        Welcome to <b>On Target Romance</b>, a cutting-edge
                        organizational calendar platform for romance readers,
                        authors and industry professionals to optimize their
                        reading and stay up-to-date with book related events and
                        exclusives.
                      </p>
                      <div className="row align-items-end">
                        <div className="col-12 col-md-4">
                          <div className="signup-btn">
                            <Link
                              to="/join"
                              class="btn btn_save_bt radius8 text-white bg-dark mt-0"
                            >
                              Sign Up
                            </Link>
                          </div>
                        </div>
                        <div className="col-12 col-md-8">
                          <ul className="download-links list-unstyled d-flex align-items-center justify-content-center justify-content-md-end">
                            <li>
                              <a href="/#">
                                {' '}
                                <img
                                  src={require('../../Utils/Assets/gp.png')}
                                  alt="Google Play"
                                />
                              </a>
                            </li>
                            <li>
                              <a href="/#">
                                {' '}
                                <img
                                  src={require('../../Utils/Assets/apple-appstore-icon.png')}
                                  alt="App Store"
                                />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="writing-promoting-section">
                  <div className="row">
                    <div className="col-12 text-center main-title">
                      <h1>
                        Stay on Target in your reading, writing &amp; promoting
                      </h1>
                    </div>
                    <div className="col-12">
                      <ul className="ul_plans list-unstyled">
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon01.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>OTR Hub</h5>
                          <p>
                            Stay on target in your Romance reading &
                            book-related events with the OTR app & website.
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon02.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Features</h5>
                          <p>
                            Calendar consists of:
                            <br />
                            - Book Release events
                            <br />
                            - Promo Tours, Cover Reveals & ARC Sign-ups
                            <br />
                            - Release Parties & Giveaways
                            <br />- Book Signing events
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon03.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Choose</h5>
                          <p>
                            Target the right plan for your professional or
                            reading needs as an Author, Reader, Blogger, PR Firm
                            or Vendor (editor, agent, designer, PA, consultant
                            etc.). <br />
                            <i> *Premium plans available.</i>
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon04.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Stay Current</h5>
                          <p>
                            Keep apprised of Romance book events by Authors, PR
                            Firms, Vendors & Premium Bloggers.
                            {/* Member generated
                          content includes both public & exclusive to OTR
                          events. */}
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon05.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Get Organized</h5>
                          <p>
                            Quickly browse & sort OTR calendar, save events &
                            set reminders. Plan your writing & reading.
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon06.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Easy Search</h5>
                          <p>
                            - Customize searches by genres, tropes, authors &
                            more.
                            <br />
                            - Book release page links to all release events.
                            <br />- All events listed by an author are linked in
                            their profile.
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon07.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Daily Tasks</h5>
                          <p>
                            Use the private notepad* with a reminder function to
                            help you stay organized. <br />
                            <i>*Applicable to certain plans.</i>
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon08.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Network</h5>
                          <p>
                            Authors have access to exclusive resources to
                            connect with vendors, search for bloggers & find
                            beta readers.
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon09.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>One-stop Marketing</h5>
                          <p>
                            - Quickly organize PR launch of book releases.
                            <br />- All book release events are linked via
                            author's profile & book release page.
                          </p>
                        </li>
                        <li>
                          <div className="divImg">
                            <img
                              src={require('../../Utils/Assets/plans-icon10.png')}
                              alt="Plans Icon"
                            />
                          </div>
                          <h5>Stay on Target</h5>
                          <p>
                            - Never miss another cover reveal, book release,
                            release party or giveaway.
                            <br />- Free more time to read, write or simply
                            relax!
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-12 text-right">
                      <div className="see-plans-btn">
                        <Link
                          to="/plans"
                          class="btn btn_save_bt radius8 text-white bg-dark"
                        >
                          See Plans
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="features-plans-section">
                  <div className="row">
                    <div className="col-12 main-title text-center">
                      <h1>
                        Learn more about our membership plans &amp; features
                      </h1>
                    </div>
                    <div className="col-md-12 m-auto">
                      <div className="row" />
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <VideoFrames />
                        </div>
                        <div className="col-12 col-sm-6">
                          <VideoFrames />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <VideoFrames />
                        </div>
                        <div className="col-12 col-sm-6">
                          <VideoFrames />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="our-members-section">
                  <div className="row">
                    <div className="col-12 main-title text-center">
                      <h1>What our members say</h1>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="members-list">
                        <em>
                          "Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Vero quo earum vitae dolorem perspiciatis amet
                          dolores quod quos dignissimos totam! Quidem incidunt
                          adipisci porro rerum. Eveniet nesciunt debitis magni
                          illum."
                        </em>
                        <h5>Lorem ipsum dolor</h5>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="members-list">
                        <em>
                          "Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Vero quo earum vitae dolorem perspiciatis amet
                          dolores quod quos dignissimos totam! Quidem incidunt
                          adipisci porro rerum. Eveniet nesciunt debitis magni
                          illum."
                        </em>
                        <h5>Lorem ipsum dolor</h5>
                      </div>
                    </div>
                    <div className="col-md-4 mb-3">
                      <div className="members-list">
                        <em>
                          "Lorem ipsum dolor sit amet consectetur adipisicing
                          elit. Vero quo earum vitae dolorem perspiciatis amet
                          dolores quod quos dignissimos totam! Quidem incidunt
                          adipisci porro rerum. Eveniet nesciunt debitis magni
                          illum."
                        </em>
                        <h5>Lorem ipsum dolor</h5>
                      </div>
                    </div>
                    {/* {( !localStorage.getItem('cookiepolicy') && localStorage.getItem('userId')) ? (
                    <div class="cookip-policy">
                      <div class="cookip-inner">
                        <div class="container">
                          <div class="row">
                            <div class="col-md-9">
                              <b>
                                {" "}
                                We use cookies to ensure the functionality Of our
                                platform, to personalize content, to provide media
                                features and ads, and to analyze our traffic. For more
                                information, please see our{" "}
                                <Link to={"/privacy"} className="red">
                                  <u>privacy policy.</u>
                                </Link>
                              </b>
                            </div>
                            <div class="col-md-3 d-flex align-items-center mt-3 mt-mb-0">
                              <button
                                class="btn btn-lg cookip-btn"
                                onClick={(e) => this.handleSaveCookies()}
                              >
                                Accept
                              </button>
                              <button
                                class="close ml-auto"
                                onClick={(e) => this.handleSaveCookies}
                              >
                                <i class="fa fa-times-circle"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null} */}
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }

  componentDidMount() {
    if (localStorage.getItem('token')) {
      window.location = '/';
    }

    if (cookies.get('email')) {
      this.setState({ email: cookies.get('email') });
      this.setState({ rememberMe: true });
    }

    if (cookies.get('password')) {
      //   let password=cryptr.decrypt(cookies.get('password'));
      //   if(password)
      //   {
      this.setState({ password: cookies.get('password') });
    }
    this.props.resetSignupPage();
  }
}

// {======= Logo Component ========}
// {======= VideoFrames Component ========}
const VideoFrames = () => (
  <div className="video">
    <iframe
      width="100%"
      src="https://www.youtube.com/embed/yodw5jIOBt4"
      frameBorder="0"
      title="video 1"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

// {======= Footter Component ========}

const Footer = () => {
  return (
    <footer id="footer">
      {localStorage.getItem('token') ? (
        <>
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12 float-md-left text-center">
                <div className="footer_text-mb-4">
                  <ul className="p-0">
                    <li>
                      <Link to="/about" className="links">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/condition" className="links">
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" className="links">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="links">
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link to="/contactus" className="links">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <table align="center" cellPadding="10%" >
              <td>
                <Link to="/about" className="links"  >About Us</Link>
              </td>
              <td>
                <Link to="/condition" className="links" >Terms of Service</Link>
              </td>
              <td>
                <Link to="/privacy" className="links" >Privacy Policy</Link>
              </td>
              <td>
                <Link to="/faq" className="links" >FAQ</Link>
              </td>
              <td>
                <Link to="/contact" className="links" >Contact Us</Link>
              </td>
            </table> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row footer-margin">
              <div className="col-md-12">
                <div className="col-md-10 col-xl-8 text-center m-auto">
                  <div className="footer_text text-white">
                    <h3>
                      Target your Romance book needs.
                      <span className="slogan-text">℠</span>
                      <br /> Start organizing your favorite Romance book events
                      today!
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="row footer-badges-link">
              <div className="col-lg-8 col-sm-12  order-lg-2 d-flex justify-content-center align-items-center">
                <div className="footer-btn">
                  <div className="divbutton float-md-right">
                    <Route
                      render={({ history }) => (
                        <button
                          className="btn btn-lg btn-block btn_login pull-right mb-3"
                          type="button"
                          onClick={() => {
                            history.push('/join');
                          }}
                        >
                          Join
                        </button>
                      )}
                    />
                  </div>

                  <div className="divbutton ml-md-0">
                    <Route
                      render={({ history }) => (
                        <button
                          className="btn btn-lg btn-block btn_login pull-right"
                          type="button"
                          onClick={() => {
                            history.push('/log-in');
                          }}
                        >
                          Log In
                        </button>
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-sm-12 d-flex flex-column justify-content-between order-lg-3 pt-3 pt-lg-0">
                <ul className="download-links list-unstyled text-lg-right text-center">
                  <li>
                    <a href="/#">
                      {' '}
                      <img
                        src={require('../../Utils/Assets/1.png')}
                        alt="Google Play"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/#">
                      {' '}
                      <img
                        src={require('../../Utils/Assets/2.png')}
                        alt="App Store"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-sm-12 d-flex flex-column justify-content-between pt-3 pt-lg-0 text-lg-left text-center">
                <ul className="payments-links list-unstyled text-lg-left text-center">
                  <li>
                    <a
                      href="https://www.braintreegateway.com/merchants/YOUR_MERCHANT_ID/verified"
                      target="_blank"
                    >
                      <img
                        src="https://s3.amazonaws.com/braintree-badges/braintree-badge-light.png"
                        width="164px"
                        height="44px"
                        border="0"
                      />
                    </a>{' '}
                  </li>
                  <li>
                    <a href="/#">
                      {' '}
                      <img
                        src={require('../../Utils/Assets/payment-option.png')}
                        alt="Payment"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="footer-bottom">
                <div className="col-md-2 col-12">
                  <p className="copyRight text-white">
                    &copy; Copyright 2023 OTR LLC
                  </p>
                </div>
                <div className="col-md-8 col-12">
                  <ul className="p-0 footer-links">
                    <li
                      className={
                        window.location.hash === '#/privacy'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/privacy" className="links">
                        PRIVACY
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/condition'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/condition" className="links">
                        TERMS &amp; CONDITIONS
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/plans'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/plans" className="links">
                        MEMBERSHIP PLANS
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/about'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/about" className="links">
                        ABOUT US
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/help'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/help" className="links">
                        HELP
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/advertise'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/advertise" className="links">
                        ADVERTISE
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      className={
                        window.location.hash === '#/offers'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/offers" className="links">
                        OFFERS
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-2 col-12">
                  <ul className="list-unstyled footer-links text-lg-right text-center">
                    <li>
                      <a
                        href={CONSTANT.FACEBOOK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../Utils/Assets/social_media/White/facebook.png')}
                          alt="logo"
                          width="40px"
                          height="40px"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={CONSTANT.INSTAGRAM}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../Utils/Assets/social_media/White/instagram.png')}
                          alt="logo"
                          width="40px"
                          height="40px"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={CONSTANT.TWITTER_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../Utils/Assets/social_media/White/twitter.png')}
                          alt="logo"
                          width="40px"
                          height="40px"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href={CONSTANT.YOUTUBE_URL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={require('../../Utils/Assets/social_media/White/youtube.png')}
                          alt="logo"
                          width="40px"
                          height="40px"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </footer>
  );
};

const mapStateToProps = state => ({
  users: state.userlists,
  status: state.userloading
});

const mapDispatchToProps = dispatch => ({
  fetchData: (url, login, password, social, history) =>
    dispatch(authenticate(url, login, password, social, history)),
  resetSignupPage: () => dispatch(signuppage(0))
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
