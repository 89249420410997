import 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { connect } from 'react-redux';
import {
  setupEvent,
  getFilterEventDataList,
  addEventToFavourite,
  getPopularEvents,
  addEventToMyCalendar
} from '../../../Actions/event-setup';
import { getNotificationlist } from '../../../Actions/messages';
import { addEventFilter } from '../../../Actions/filters';
import moment from 'moment';
import EventModal from '../eventModal';
import './eventFilter.css';
import { PureComponent } from 'react';
import createHistory from 'history/createHashHistory';

const momentCurrent = require('moment-timezone');
const currentTime = Intl.DateTimeFormat().resolvedOptions().currentTime;

momentCurrent.tz.setDefault(currentTime);

const overlayNoRowsTemplate = '<span>No record found</span>';
const globalHistory = new createHistory();

class TodayEvent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      eventData: {},
      popularEvents: false,
      checkEvents: '',
      rowData: [],
      dateStore: '',
      columnDefs: [
        {
          headerName: 'ID',
          field: 'id',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'isIncluded ',
          field: 'isIncluded',
          hide: true,
          suppressMovable: true,
          unSortIcon: true
        },
        {
          headerName: 'EVENT TYPE',
          field: 'event_type',
          sortable: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 275,
          filter: true,

          cellRenderer: function(event_type) {
            var backgroundColor;
            let event_list_value = event_type.value;
            if (event_type.value.length > 13) {
              event_list_value = event_type.value.slice(0, 13) + '...';
            }

            if (
              event_type.value === 'event_type_1' ||
              event_type.value === 'Book Release'
            ) {
              backgroundColor = '#65C1FF, #7193FF';
            }
            if (
              event_type.value === 'event_type_2' ||
              event_type.value === 'Giveaway'
            ) {
              backgroundColor = '#9FFF81, #75ffc9';
            }
            if (
              event_type.value === 'event_type_3' ||
              event_type.value === 'Book Promo'
            ) {
              backgroundColor = '#FF964F, #ff8181';
            }
            if (
              event_type.value === 'event_type_4' ||
              event_type.value === 'Release Party'
            ) {
              backgroundColor = '#FF65FF,#FF79BC';
            }
            if (
              event_type.value === 'event_type_5' ||
              event_type.value === 'Cover Reveal'
            ) {
              backgroundColor = '#fFDB69, #F3FF85';
            }
            if (
              event_type.value === 'event_type_6' ||
              event_type.value === 'Book Signing'
            ) {
              backgroundColor = '#8E5BFF,#B469FF';
            }
            if (
              event_type.value === 'event_type_7' ||
              event_type.value === 'ARC Signup'
            ) {
              backgroundColor = '#FF5E8E, #FF5858';
            }
            if (event_type.value === 'Ticket Sales') {
              backgroundColor = '#B9B9FF,#DCB9FF';
            }
            return (
              '<span style="height: 13px;width: 13px; background:linear-gradient(to right,' +
              backgroundColor +
              '); border-radius: 50%; display: inline-block"></span> ' +
              event_list_value
            );
          }
        },
        {
          headerName: 'TITLE/NAME',
          field: 'commanTitle',
          sortable: true,
          suppressMovable: true,
          unSortIcon: true,
          filter: true,
          width: 250,
          // suppressSizeToFit: true,
          cellRenderer: this.titleCellRenderer.bind(this)
        },
        {
          headerName: 'AUTHOR NAME',
          field: 'userName',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 210,
          cellRenderer: this.nameCellRenderer.bind(this)
        },
        {
          headerName: 'START DATE',
          field: 'start_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 190,
          cellRenderer: function(startDate) {
            return moment(startDate.data.start_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'END DATE',
          field: 'end_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          width: 160,
          cellRenderer: function(endDate) {
            if (endDate.data.end_date) {
              return moment(endDate.data.end_date).format('MM/DD/YYYY');
            } else {
              return '-';
            }
          }
        },
        {
          headerName: 'TIME (US EASTERN)',
          field: 'time',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,

          cellRenderer: this.showTime.bind(this)
        },
        {
          headerName: 'HOSTED BY',
          field: 'hosted_by',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,
          cellRenderer: this.hotsted.bind(this)
        },

        {
          headerName: 'ADDED ON',
          field: 'created_date',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,

          cellRenderer: function(created_date) {
            return moment(created_date.data.created_date).format('MM/DD/YYYY');
          }
        },
        {
          headerName: 'FAVORITE',
          field: 'favourite',
          sortable: true,
          filter: true,
          suppressMovable: true,
          unSortIcon: true,

          cellRenderer: function(favourite) {
            if (favourite.data.favourite) {
              return '<i class="fa fa-star" style="font-size:20px;color:#ffff4d"></i>';
            } else {
              return '<i class="fa fa-star-o" style="font-size:20px;"></i>';
            }
          }
        },

        {
          headerName: 'ACTION',
          field: 'addcalender',

          cellRenderer: this.showAddCalender.bind(this)
        }
      ],

      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true
      },
      paginationPageSize: 10,
      paginationNumberFormatter: function(params) {
        return '' + params.value.toLocaleString() + '';
      },
      displayedRows: 0,
      localeText: { noRowsToShow: 'No Data found' }
    };
    this.hideEvent = this.hideEvent.bind(this);
  }
  // showAddCalender(params) {
  //   let details = params.data.event_details_with_values;
  //   let is = params.data.isIncluded;
  //   let ownedByMe = params.data.ownedByMe;

  //   // console.log('isIncludedisIncludedisIncluded', is);
  //   if (this.props.eventMode === true && is === false && ownedByMe === false) {
  //     return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
  //   } else {
  //     return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
  //   }
  // }
  showAddCalender(params) {
    let details = params.data.event_details_with_values;
    let is = params.data.isIncluded;
    let ownedByMe = params.data.ownedByMe;
    // console.log('params', params.data.end_date);
    const createdDate = moment().format('YYYY-MM-DD');
    let checkPreviouDate =
      new Date(createdDate).getTime() <=
      new Date(params.data.start_date).getTime();

    let checkPreviouDate1 =
      new Date(createdDate).getTime() <=
      new Date(params.data.end_date).getTime();

    if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === false &&
      checkPreviouDate === true &&
      checkPreviouDate1 === true
    ) {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    } else if (
      checkPreviouDate === false &&
      checkPreviouDate1 === false &&
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === true
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    } else if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === false &&
      checkPreviouDate === false &&
      checkPreviouDate1 === true
    ) {
      return `<button type="button" data-action-type="addcalender" class="btn btn-sm  new_btn_save_bt_users_light  py-0">ADD</button>`;
    } else if (
      this.props.eventMode === true &&
      is === false &&
      ownedByMe === false &&
      checkPreviouDate === false &&
      checkPreviouDate1 === false
    ) {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADD</button>`;
    } else {
      return `<button type="button" disabled class="btn btn-sm  new_btn_save_bt_users_light btn-gray  py-0">ADDED</button>`;
    }
  }
  hotsted(params) {
    const changeString = params.data.hosted_by
      ? params.data.hosted_by.length > 13
        ? params.data.hosted_by.slice(0, 13) + '..'
        : params.data.hosted_by
      : '';

    return changeString;
  }
  showTime(params) {
    return params.data.time
      ? this.getEstTimeNow(new Date(), params.data.time)
      : '-';
  }

  convertTo12Hour(oldFormatTime) {
    var oldFormatTimeArray = oldFormatTime.split(':');

    var HH = parseInt(oldFormatTimeArray[0]);
    var min = oldFormatTimeArray[1];

    // var AMPM = HH >= 12 ? 'PM' : 'AM';
    var hours;
    if (HH == 0) {
      hours = HH + 12;
    } else if (HH > 12) {
      hours = HH - 12;
    } else {
      hours = HH;
    }
    var newFormatTime = hours + ':' + min + ' ';

    return newFormatTime;
  }
  getEstTimeNow(date, time) {
    const dates = date || new Date();
    const convertedTime = moment(time, 'hh:mm A').format('HH:mm');

    let start2 = moment(dates).toDate();
    const time2 = convertedTime.split(':');

    start2.setHours(time2[0]);
    start2.setMinutes(time2[1]);
    start2.setSeconds(0);
    const dt2 = new Date(start2);

    const timeZone = 'America/New_York';
    let myTime = dt2.toLocaleString('en-US', {
      timeZone
    });
    let timeTaken = moment(myTime).format('hh:mm A');

    return timeTaken;
  }

  amPm(time) {
    const hour = time.split(':')[0] * 1;

    return hour > 12 ? 'PM' : 'AM';
  }
  nameCellRenderer(params) {
    let midName = params.data.author_mid_name;

    if (midName === 'null' || midName === null) {
      midName = '';
    }
    return `${
      params.data.author_first_name ? params.data.author_first_name : ''
    } ${midName ? midName : ''} ${
      params.data.author_last_name ? params.data.author_last_name : ''
    }`;
  }

  titleCellRenderer(params) {
    if (params.data.event_type === 'Giveaway') {
      return `${params.data.giveaway ? params.data.giveaway : ''} `;
    } else if (params.data.event_type === 'Release Party') {
      return `${params.data.releaseparty ? params.data.releaseparty : ''} `;
    } else {
      return `${params.data.title ? params.data.title : ''} `;
    }
  }

  getFormattedValue(params) {}

  showEvent(e) {
    if (e.column.colId == 'favourite') {
      const data = this.props.filterData;
      data.isGeneral = this.props.eventMode;
      // if (this.props.sort) {
      //   data.sort = this.props.sort;
      // }
      this.props.addToFavourite(
        `/api/v1/favourite/${e.data.id}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        data,
        'eventFilter'
      );
    } else if (e.column.colId === 'addcalender') {
      const data = this.props.filterData;
      data.isGeneral = this.props.eventMode;
      console.log('e.data.id', data);
      this.props.addToMyCalendar(
        `/api/v1/add_mycalendar/${e.data.id}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        data,
        'eventFilter',
        this.props.filterData
      );
      this.props.getNotificationList(`/api/v1/notification`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });

      //return this.onActionViewClick(data);
    } else {
      e.data.event_details_with_values =
        typeof e.data.event_details_with_values == 'string'
          ? JSON.parse(e.data.event_details_with_values)
          : e.data.event_details_with_values;
      this.setState({
        eventData: e.data,
        modal: true
      });
    }
  }
  onActionAddCalenderClick(data) {
    data.event_details_with_values =
      typeof data.event_details_with_values == 'string'
        ? JSON.parse(data.event_details_with_values)
        : data.event_details_with_values;
    this.setState({
      eventData: data,
      modal: true
    });
  }

  // onActionViewClick(data) {
  //   // window.location.reload();
  //   //this.props.addToMyCalendar(data.id);
  //   this.props.addToMyCalendar(`/api/v1/add_mycalendar/${data.id}`, {
  //     headers: { 'x-authorization': localStorage.getItem('token') }
  //   });
  // }

  hideEvent() {
    this.setState({
      modal: false,
      eventData: {}
    });
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.paginationGoToPage(this.props.current);
    this.gridApi.setDomLayout('autoHeight');
    this.gridApi.setQuickFilter(this.props.search);
    //this.autoSizeColumns(params);
  };
  // autoSizeColumns = params => {
  //   const colIds = params.columnApi
  //     .getAllDisplayedColumns()
  //     .map(col => col.getColId());

  //   params.columnApi.autoSizeColumns(colIds);
  // };

  refreshGrid() {
    this.props.getCases();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById('page-size').value;
    this.gridApi.paginationSetPageSize(Number(value));
  }

  handleMyEvents = status => {
    // if (status) {
    //   this.props.getPopularEvents(`api/v1/events/getPopularEvents`, {
    //     headers: { "x-authorization": localStorage.getItem("token") },
    //   });
    // } else {
    const data = this.props.filterData;

    if (this.props.sort && this.props.sort.length) {
      data.sort = this.props.sort;
    }

    this.props.getFilterEvent(
      `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
      data,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
  };

  handleRefresh = () => {
    window.location.reload();
  };

  //===================== RENDER ==========================
  render() {
    // console.log(
    //   '--------------------------------- hii ',
    //   this.props.eventArray[0]
    // );

    let data = [];
    let membershipId = localStorage.getItem('membershipId');
    if (this.props.eventsData.length) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      data = this.props.eventsData;

      data.forEach(element => {
        let e = {};
        e = element;
        e.userName =
          element['author_first_name'] + ' ' + element['author_last_name'];
        //data.push(e);
      });
    }
    // console.log('hellomydear', this.props.filterData, this.props.eventsData);
    let arcSignup;
    if (membershipId === '2' || membershipId === '4') {
      arcSignup = data.filter(el => {
        if (el.arc_sign_up !== 'Open to Bloggers/PR Firms') {
          return el;
        }
      });
    } else {
      arcSignup = data;
    }

    const todayEventFilter = new Date();
    var pstDateWithCurrentTime = todayEventFilter.toLocaleString('en-US', {
      timeZone: currentTime
    });

    const convertTime = new Date(pstDateWithCurrentTime);
    let currentPstDate = moment(convertTime).format('YYYY-MM-DD');
    let filterTodayEvent = arcSignup.filter(
      el => el.start_date == currentPstDate || el.end_date == currentPstDate
    );

    let newData = [];
    filterTodayEvent.forEach(el => {
      let obj = { ...el };
      // console.log("el.event_type--> ", el.event_type);
      if (el.event_type === 'Giveaway') {
        obj.commanTitle = el.giveaway
          ? el.giveaway.length > 20
            ? el.giveaway.substr(0, 20) + '...'
            : el.giveaway
          : '';
      } else if (el.event_type === 'Release Party') {
        obj.commanTitle = el.releaseparty
          ? el.releaseparty.length > 20
            ? el.releaseparty.substr(0, 20) + '...'
            : el.releaseparty
          : '';
      } else {
        obj.commanTitle = el.title
          ? el.title.length > 20
            ? el.title.substr(0, 20) + '...'
            : el.title
          : '';
      }
      obj.commanTitle = obj.commanTitle.toLowerCase();
      newData.push(obj);
    });

    filterTodayEvent = [...newData];
    let MostSavedEvent = [];
    if (this.props.eventArray && this.props.eventArray.length > 0) {
      // this.props.eventArray.forEach((el)=>
      // {
      //    let newObj = {...el};
      //   //  let secObj = filterTodayEvent.find((ell)=> ell.id === el.event_id);
      //   console.log("ell.id ", filterTodayEvent[0].id === el.event_id ? filterTodayEvent[0] : '')
      //   //  console.log("secObj ", secObj);
      //   //  if(secObj)
      //   //  {
      //   //   newObj.saveCount = secObj.eventcount;
      //   //   MostSavedEvent.push(newObj);
      //   //  }
      // })

      filterTodayEvent.forEach(el => {
        // console.log("elllllllllllllllllll ", el)
        let userId = localStorage.getItem('userId');
        let element = { ...el };
        let obj = this.props.eventArray.find(ne => el.id == ne.event_id);
        if (obj) {
          element.eventCount = obj.eventcount;
        } else if (userId == el.user_id) {
          element.eventCount = 0;
        } else {
          element.eventCount = -1;
        }

        MostSavedEvent.push(element);
      });
    }

    filterTodayEvent = MostSavedEvent.sort((a, b) => {
      if (a.eventCount > b.eventCount) return -1;
      if (a.eventCount === b.eventCount && a.created_date > b.eventCount)
        return -1;
    });

    console.log('filterTodayEvent - > ', MostSavedEvent);

    return (
      <div className="event_filter_page">
        <div className="container event-filter-page">
          <div className="mb-3">
            <div className="color_guide mb-3">
              <div className="row">
                <div className="legend">
                  <span className="color_box">RELEASE PARTY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">ARC SIGNUP</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK PROMO</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">COVER REVEAL</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">GIVEAWAY</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box">BOOK RELEASE</span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>

                <div className="legend">
                  <span className="color_box text-uppercase">
                    Boxes & Signings
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
                <div className="legend">
                  <span className="color_box text-uppercase">
                    Ticket/box sales
                  </span>
                  {/* <p className="indicator_name" style={{ color: this.props.isTheameLight ? "#202020" : "#fff" }}></p> */}
                </div>
              </div>
            </div>
            {!this.props.isReader && !this.props.isBlogger ? (
              <>
                <div className="col-md-12 mb-2 setup-btn">
                  <button
                    onClick={e => this.props.openEventModal()}
                    className={
                      this.props.isTheameLight
                        ? 'btn btn_save_bt setup_event_bt daily'
                        : 'btn btn_save_bt white-button setup_event_bt daily'
                    }
                  >
                    <span>Set up Event</span>
                    <img
                      src={require('../../../Utils/Assets/plus.png')}
                      alt="cup-icon"
                    />
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}

            <h2 className="text-center heading"> Today's Events</h2>
          </div>

          <div
            className={
              this.props.myevents ? 'ag-theme-balham' : 'ag-theme-dark home-ag'
            }
            style={{}}
          >
            {' '}
            <AgGridReact
              onGridReady={this.onGridReady}
              // columnDefs={
              //   this.props.eventMode === true
              //     ? this.state.columnDefs
              //     : this.state.columnDefss
              // }
              columnDefs={this.state.columnDefs}
              defaultColDef={this.state.defaultColDef}
              rowData={filterTodayEvent}
              pagination={true}
              reactNext={true}
              animateRows
              onCellClicked={this.showEvent.bind(this)}
              // onRowClicked={this.addToFavourite.bind(this)}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
              //overlayNoRowsTemplate={overlayNoRowsTemplate}
              //onFirstDataRendered={this.autoSizeColumns}
            />
            <div className="search-features" style={{ marginTop: '15px' }}>
              <p>
                <i>
                  Each column has search feature. Column width can be changed by
                  dragging. Hit refresh to reset.
                </i>
                <i
                  style={{
                    marginLeft: '10px',
                    cursor: 'pointer'
                  }}
                  className="fa fa-refresh"
                  onClick={this.handleRefresh}
                />
              </p>
            </div>
            <div className="test-header" style={{ float: 'right' }}>
              Page Size:
              <select
                onChange={this.onPageSizeChanged.bind(this)}
                id="page-size"
                defaultValue="10"
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>

          {this.state.modal ? (
            <EventModal
              isOpen={this.state.modal}
              eventData={this.state.eventData}
              toggle={this.hideEvent}
              isInclude={this.state.eventData.isIncluded}
            />
          ) : null}
        </div>
      </div>
    );
  }

  componentWillReceiveProps(prevProps) {
    if (this.props.favouriteData) {
      //   this.props.getFilterEvent(`/api/v1/event_filter`,this.props.filterData, { headers: { 'x-authorization': localStorage.getItem("token") } });
    } else {
      if (this.gridApi) this.gridApi.setQuickFilter(this.props.search);
    }

    if (this.props.eventMode !== this.state.eventMode) {
      this.setState({ eventMode: this.props.eventMode });
      const data = this.props.filterData;

      if (this.props.sort && this.props.sort.length) {
        data.sort = this.props.sort;
      }
      if (data.start_date) {
        data.startDate = moment.utc(data.start_date).format('YYYY-MM-DD');
      }

      this.props.getFilterEvent(
        `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
        data,
        { headers: { 'x-authorization': localStorage.getItem('token') } }
      );
    }

    if (prevProps.checkPopularEvents !== this.state.popularEvents) {
      this.setState({ popularEvents: prevProps.checkPopularEvents }, () => {
        this.handleMyEvents(prevProps.checkPopularEvents);
      });
    }
  }

  componentDidMount() {
    // if (this.props.eventMode === true) {
    //   this.props.getFilterEvent(`/api/v1/event_filter?isGeneral=false`, data, {
    //     headers: { 'x-authorization': localStorage.getItem('token') }
    //   });

    //   console.log('jjjjjjjjjjjj', this.props.eventsData);
    // }
    this.props.getNotificationList(`/api/v1/notification`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    this.setState({ eventMode: this.props.eventMode });
    const data = this.props.filterData;

    if (this.props.sort && this.props.sort.length) {
      data.sort = this.props.sort;
    }

    this.props.getFilterEvent(
      `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
      data,
      { headers: { 'x-authorization': localStorage.getItem('token') } }
    );
    const query = window.location.hash;

    let data2 = query.split('?')[1];

    // let data3 = data2 && data2.split('=');

    let data4 = data2 && data2.split('=')[0];

    this.setState({ dateStore: data4 });
  }

  componentWillUnmount() {
    this.props.addFilterData({});
  }
}

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    filterData: state.eventFilter,
    sort: state.sort,
    eventsData: state.getFilterEventData,
    favouriteData: state.addToFavourite,
    eventMode: state.getEventMode,
    checkPopularEvents: state.changePopularEvents,
    notificationList: state.getNotification,
    eventArray: state.eventMost
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setupEvent: () => dispatch(setupEvent()),
    addFilterData: data => dispatch(addEventFilter(data)),
    getFilterEvent: (url, data, header) =>
      dispatch(getFilterEventDataList(url, data, header)),
    addToFavourite: (url, header, data, pageName) =>
      dispatch(addEventToFavourite(url, header, data, pageName)),
    getPopularEvents: (url, header) => dispatch(getPopularEvents(url, header)),
    getNotificationList: (url, header) =>
      dispatch(getNotificationlist(url, header)),
    // addToMyCalendar: (eventId) =>
    //   dispatch(
    //     addEventToMyCalendar(`/api/v1/add_mycalendar/${eventId}`, {
    //       headers: { 'x-authorization': localStorage.getItem('token') }
    //     })
    //   )
    addToMyCalendar: (url, header, data, pageName, eventsData) =>
      dispatch(addEventToMyCalendar(url, header, data, pageName, eventsData))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TodayEvent);
