import React, { Component } from 'react';
import LeftLogo from '../../Components/left-logo';
import { connect } from 'react-redux';
import Footer from '../Footer/footer';
import Header from '../Profile/header';
import './layout.css';

// const Layout = ({ children }) => {
class Layout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        // window.scrollTo(0, 0);
        return (
            <div className="main-wrapper">
                <div className="sidebar">
                    <LeftLogo history={this.props.history} />
                </div>
                <div
                    className={`main-content ${localStorage.getItem('token') ? 'add-header' : 'remove-header'
                        }`}
                >
                    <div className="header-secion">
                        {localStorage.getItem('token') ? (
                            <Header navData={this.props} />
                        ) : (
                            ''
                        )}
                    </div>
                    <div
                        className={`middle-content ${this.props.displayMode ? '' : 'calendar-dark'
                            }`}
                    >
                        <div
                        // className={
                        //   props.displayMode
                        //     ? "main_right author_profile"
                        //     : "main_right author_profile calendar-dark"
                        // }
                        // style={{ flex: "1" }}
                        >
                            {this.props.children}
                        </div>
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        displayMode: state.changeMode
    };
};

const mapDispatchToProps = dispatch => {
    // return {
    //   changeTab: (tab) => dispatch(Tab(tab))
    // }
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
