import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import AddMessage from '../messageModal';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import {
  getConversationList,
  getUserMessageList,
  markAsRead,
  countUnreadMessages
  // getLastUserMessages
} from '../../../Actions/messages';
import CONSTANT, { BASEURL } from '../../../constant';

import './ConversationList.css';

class ConversationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conversations: [],
      showMessageModal: false,
      activeMember: false,
      deleteRequest: ''
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ showMessageModal: true });
  }

  refreshConversationList() {
    console.log(
      '--------------------------------------------------------------> '
    );
    this.props.getConversationList('/api/v1/message_list', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  hideMessageModal() {
    this.setState({ showMessageModal: false });
  }

  render() {
    // if (this.props.messageCount )

    // localStorage.setItem(
    //   'msgId',
    //   this.props.conversationList[0] && this.props.conversationList[0].msg_id
    // );

    // const filter =
    //   this.props.conversationList &&
    //   this.props.conversationList.map(el => {
    //     let deleteRequest =
    //       this.state.deleteRequest &&
    //       this.state.deleteRequest.filter(ele => ele.id !== el.sender_user_id);
    //     console.log('11213', deleteRequest);
    //     // let data = deleteRequest.splice(el.id, deleteRequest.sender_user_id);
    //     // console.log('data000', data);
    //     // if (deleteRequest) {
    //     //   console.log('dele', deleteRequest);
    //     //   return el;
    //     // }
    //   });
    let mydeleteRequest = [];

    const filter =
      this.state.deleteRequest &&
      this.state.deleteRequest.map(el => {
        let deleteRequest =
          this.props.conversationList &&
          this.props.conversationList.filter(ele => {
            if (el.id === ele.sender_user_id) {
              mydeleteRequest.push(ele.sender_user_id);
              return ele;
            }
          });
      });
    let data;
    if (mydeleteRequest.length > 0) {
      data =
        this.props.conversationList &&
        this.props.conversationList.filter(
          el => !mydeleteRequest.includes(el.sender_user_id)
        );
    } else {
      data = this.props.conversationList;
    }

    data = data.filter(
      el => !(el.membership_id === '6' || el.membership_id === '3')
    );

    data = data.filter(el => {
      return el.name.toLowerCase().includes(this.props.search.toLowerCase());
    });
    console.log(
      '-------------------------------------------------------------------------------------------',
      data
    );

    return (
      <>
        <Toolbar
          title="Messenger"
          leftItems={[
            <ToolbarButton
              key="cog"
              icon="fa fa-plus-circle"
              onChangeClickHandler={() => {
                this.openModal();
              }}
            />
          ]}
          // rightItems={[
          //   <ToolbarButton
          //     key="add"
          //     icon="fa fa-refresh"
          //     onChangeClickHandler={e => this.refreshConversationList()}
          //   />
          // ]}
        />
        <AddMessage
          isOpen={this.state.showMessageModal}
          toggle={this.hideMessageModal.bind(this)}
        />
        {/* <ConversationSearch /> */}

        {/* {console.log('this is message list ', data)} */}
        <div className="messages-listing">
          {data && data.length > 0 ? (
            data
              .sort((a, b) => b.id - a.id)

              .map((conversation, i) => (
                <>
                  <ConversationListItem
                    index={i}
                    key={conversation.title}
                    data={conversation}
                    activeMember={this.state.activeMember}
                    messageCount={this.props.messageCount}
                    // currentUser={
                    //   this.props.conversationList[0] &&
                    //   this.props.conversationList[0].id
                    // }
                    onChangeClickHandler={e => {
                      this.props.getMessages(`/api/v1/message_list/${e}`, {
                        headers: {
                          'x-authorization': localStorage.getItem('token')
                        }
                      });
                      this.props.markMessagesAsRead(
                        `/api/v1/message_isRead/${e}`,
                        {
                          headers: {
                            'x-authorization': localStorage.getItem('token')
                          }
                        }
                      );
                      localStorage.setItem('msgId', e);
                    }}
                  />
                </>
              ))
          ) : (
            <p className="mt-6">
              No conversation found. Start a new message by clicking on the plus
              button.
            </p>
          )}
        </div>
      </>
    );
  }

  componentWillReceiveProps() {
    //   if (this.props.conversationList.length==0) {
    //  this.props.getConversationList(`/api/v1/message_list`,{ headers: { 'x-authorization': localStorage.getItem("token") } });
    //  }
  }

  async componentDidMount() {
    // this.props.getConversationList('/api/v1/message_list', {
    //   headers: { 'x-authorization': localStorage.getItem('token') }
    // });
    // this.props.getMessages(
    //   `/api/v1/message_list/${localStorage.getItem('msgId')}`,
    //   {
    //     headers: {
    //       'x-authorization': localStorage.getItem('token')
    //     }
    //   }
    // );
    this.props.markMessagesAsRead(
      `/api/v1/message_isRead/${localStorage.getItem('msgId')}`,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );
    const getListOfUser = await axios.get(
      BASEURL + `/api/v1/getdeleteuser/${localStorage.getItem('msgId')}`,
      {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      }
    );

    this.setState({ deleteRequest: getListOfUser.data.result });

    // this.props.getLastUserMessages(`/api/v1/get_last_user_message`, {
    //   headers: {
    //     'x-authorization': localStorage.getItem('token')
    //   }
    // });
  }
}

const mapStateToProps = state => ({
  conversationList: state.conversationList,
  messages: state.getMessageList,
  messageCount: state.countUnreadedMessages,
  myProfile: state.myProfileData
});

const mapDispatchToProps = dispatch => ({
  getConversationList: (url, header) =>
    dispatch(getConversationList(url, header)),
  getMessages: (url, header) => dispatch(getUserMessageList(url, header)),
  markMessagesAsRead: (url, header) => dispatch(markAsRead(url, header)),
  getMessageCount: (url, header) => dispatch(countUnreadMessages(url, header))
  // getLastUserMessages: (url, header) =>
  //   dispatch(getLastUserMessages(url, header))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConversationList);
