import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Notifications from 'react-notify-toast';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './Store/configureStore';

const store = configureStore();
ReactDOM.render(<Provider store={store}>
  <div className="main"><Notifications options={{ zIndex: 2000, top: '50px', left: '500px' }} /></div>
  <App />
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
