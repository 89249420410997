import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './dashboard.css';
import moment from 'moment';
import EventHTMLViewParser from './eventHtmlViewParser';
import { Link, Navigate } from 'react-router-dom';
import {
  deleteEvent,
  getEventDetails,
  previewData,
  getAllEvents,
  getAllEventsSummary,
  associatedEvents,
  getFilterEventDataList,
  getFilterEvents,
  getPopularEvents,
  // addEventToMyCalendarByModal,
  addEventToMyCalendar,
  removeCalendar
} from '../../Actions/event-setup';

import ProblemModal from './problemModal';
import pathimg from '../../Utils/Assets/OTR_default_book_cover_v2.jpg';
import axios from 'axios';
import UserProfileModal from '../usersList/userProfileModal';
import { BASEURL } from '../../constant';
import { getNotificationlist } from '../../Actions/messages';
import Loader from '../../Components/Loader/loader';
class EventModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKeyData: [],
      title: '',
      showProblemModal: false,
      isOpenPrivacy: false,
      show: false,
      deleteEvent: false,
      isReadMore: true,
      myid: '',
      releasePartyPopupOpen: '',
      timee: '',
      previousDateStore: '',
      previousDatEnd: '',
      authorEvent: '',
      openAuthorEventName: false,
      storeAuthorData: '',
      openHostInformation: '',
      openPopUp: false,
      hostNameAdd: '',
      addCalendar: '',
      followCode: '',
      authorLink: '9v1GiX',
      AuthorFullName: '',
      nameLoader: false
    };
    this.htmlParser = new EventHTMLViewParser();
  }

  toggleProblemModal = () => {
    this.setState({ showProblemModal: !this.state.showProblemModal });
  };
  togglePrivacyModal = () => {
    this.setState({ isOpenPrivacy: !this.state.isOpenPrivacy });
  };
  agreePolicy = () => {
    this.togglePrivacyModal();
  };
  delete = () => {
    this.setState({ deleteEvent: !this.state.deleteEvent });
  };

  // toggleOpenOnAuthor = () => {
  //   this.setState({ openAuthorEventName: !this.state.openAuthorEventName });
  // };

  // agreeDelete = () => {
  //   this.togglePrivacyModal();
  // };
  handleAddCalendar = async eventData => {
    let url = window.location.href;
    let split = url.split('/');
    let tab = split[5] ? split[5] : split[4];
    let pageName = '';
    if (tab === 'event-filter') {
      pageName = 'eventFilter';
    }
    if (tab === 'dashboard') {
      pageName = 'listView';
    }
    if (tab === 'tranding-events') {
      pageName = 'trendingList';
    }
    const data = eventData;
    data.isGeneral = this.props.eventMode;

    //  let filterTicketSale = eventData.map((el) => el.tick)

    let filterTicketSale = eventData.ticket_sales_id
      ? eventData.ticket_sales_id
      : '';

    const response = await axios.get(BASEURL + '/api/v1/getbook', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    let checkBookSinging = response.data.result.filter(
      el => el.ticket_sales_id === eventData.id
    );

    let filterBookSiging;
    if (checkBookSinging.length !== 0) {
      filterBookSiging = checkBookSinging && checkBookSinging[0].id;
    }

    if (filterBookSiging || (filterBookSiging && tab === 'event-filter')) {
      this.props.addToMyCalendar(
        `/api/v1/add_mycalendar/${filterBookSiging}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        data,
        pageName,
        this.props.filterData
      );
    }
    if (filterTicketSale && (filterTicketSale || tab === 'event-filter')) {
      this.props.addToMyCalendar(
        `/api/v1/add_mycalendar/${filterTicketSale}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        data,
        pageName,
        this.props.filterData
      );
    }

    this.props.addToMyCalendar(
      `/api/v1/add_mycalendar/${data.id}`,
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      },
      data,
      pageName,
      this.props.filterData
    );

    if (this.props.eventTpy)
      this.props.getAssociatedEvents(
        `/api/v1/events/associatedEvents/${this.props.eventTpy}`,
        { headers: { 'x-authorization': localStorage.getItem('token') } }
      );

    this.props.toggle();
  };
  toggleReadMore = () => {
    this.setState({ isReadMore: !this.state.isReadMore });
  };

  handleDelete = async eventData => {
    try {
      let url = window.location.href;
      let split = url.split('/');
      let tab = split[5] ? split[5] : split[4];

      let pageName = '';
      if (tab === 'event-filter') {
        pageName = 'eventFilter';
      }
      if (tab === 'dashboard') {
        pageName = 'listView';
      }
      if (tab === 'tranding-events') {
        pageName = 'trendingList';
      }
      if (tab === 'followingevent') {
        pageName = 'followingevent';
      }
      let data = eventData;
      data.isGeneral = this.props.eventMode;
      let filterTicketSale = eventData.ticket_sales_id
        ? eventData.ticket_sales_id
        : '';

      let response;
      let checkBookSinging;
      if (this.props.eventData.event_type === 'Ticket Sales') {
        response = await axios.get(BASEURL + '/api/v1/getbook', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        });
        checkBookSinging = response.data.result.filter(
          el => el.ticket_sales_id === eventData.id
        );
      }

      let filterBookSiging;
      if (checkBookSinging && checkBookSinging) {
        filterBookSiging = checkBookSinging[0].id;
      }
      if (
        filterTicketSale ||
        (filterTicketSale && tab === 'event-filter') ||
        (filterTicketSale && tab === 'tranding-events')
      ) {
        // console.log("this is second log---------> ")
        this.props.removeToMyCalendar(
          `/api/v1/remove_mycalendar/${filterTicketSale}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          },
          data,
          pageName,
          this.props.filterData
        );
      }
      if (
        filterBookSiging ||
        (filterBookSiging && tab === 'event-filter') ||
        (filterTicketSale && tab === 'tranding-events')
      ) {
        // console.log("this is first log---------> ");
        // console.log("this is the data----------------->>>> ", data)
        this.props.removeToMyCalendar(
          `/api/v1/remove_mycalendar/${filterBookSiging}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          },
          data,
          pageName,
          this.props.filterData
        );
      }
      this.props.removeToMyCalendar(
        `/api/v1/remove_mycalendar/${eventData.id}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        },
        data,
        pageName,
        this.props.filterData
      );

      this.props.getNotificationList(`/api/v1/notification`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      });

      if (this.props.eventTpy)
        this.props.getAssociatedEvents(
          `/api/v1/events/associatedEvents/${this.props.eventTpy}`,
          { headers: { 'x-authorization': localStorage.getItem('token') } }
        );
      this.togglePrivacyModal();
      this.props.toggle();
    } catch (err) {
      console.log('err', err);
    }
  };
  hideEvent(openAuthorEventName) {
    this.setState({
      openAuthorEventName: false
    });
  }
  hideEventHost() {
    this.setState({ openPopUp: false });
  }
  getEstTimeNow = (date, time) => {
    const dates = date || new Date();
    const convertedTime = moment(time, 'hh:mm A').format('HH:mm');

    let start2 = moment(dates).toDate();
    const time2 = convertedTime.split(':');

    start2.setHours(time2[0]);
    start2.setMinutes(time2[1]);
    start2.setSeconds(0);
    const dt2 = new Date(start2);

    const timeZone = 'America/New_York';
    let myTime = dt2.toLocaleString('en-US', {
      timeZone
    });
    let timeTaken = moment(myTime).format('hh:mm A');

    return timeTaken;
  };
  render() {
    // console.log('this is this.props.loading---> ', this.props.loading);
    // console.log(
    //   'here is the props of this.props.eventData --------------------->>',
    //   this.state.AuthorFullName
    // );
    let isTicketSalesPreview = false;
    if (!this.props.preview && this.state.title === 'Ticket Sales') {
      isTicketSalesPreview = true;
    }

    let objImg = [];
    let ddd = [];
    if (this.props.preview) {
      if (
        this.props.eventData.find(
          el => el['book-cover'] || el['alternative-book-cover']
        )
      ) {
        if (this.props.eventData.find(el => el['book-cover'])) {
          objImg.push(
            this.props.eventData.find(el => el['book-cover'])['book-cover']
          );
        }
        if (this.props.eventData.find(el => el['alternative-book-cover'])) {
          objImg.push(
            this.props.eventData.find(el => el['alternative-book-cover'])[
              'alternative-book-cover'
            ]
          );
        }
      } else if (this.props.eventData.find(el => el['temporary-book-cover']))
        objImg.push(
          this.props.eventData.find(el => el['temporary-book-cover'])[
            'temporary-book-cover'
          ]
        );
    } else {
      if (
        this.props.eventData.event_details_with_values.find(
          el => el['book-cover'] || el['alternative-book-cover']
        )
      ) {
        if (
          this.props.eventData.event_details_with_values.find(
            el => el['book-cover']
          )
        ) {
          objImg.push(
            this.props.eventData.event_details_with_values.find(
              el => el['book-cover']
            )['book-cover']
          );
        }

        if (
          this.props.eventData.event_details_with_values.find(
            el => el['alternative-book-cover']
          )
        ) {
          objImg.push(
            this.props.eventData.event_details_with_values.find(
              el => el['alternative-book-cover']
            )['alternative-book-cover']
          );
        }
      } else if (
        this.props.eventData.event_details_with_values.find(
          el => el['temporary-book-cover']
        )
      )
        objImg.push(
          this.props.eventData.event_details_with_values.find(
            el => el['temporary-book-cover']
          )['temporary-book-cover']
        );
    }

    objImg.forEach(el => {
      if (el) {
        ddd.push(localStorage.getItem('baseUrl') + el.split('\\').join('/'));
      }
    });

    // const fill =
    //   this.state.eventKeyData &&
    //   this.state.eventKeyData.filter(el => {
    //     if (!el['temporary-book-cover'] && !el['book-cover']) {
    //       return el;
    //     }
    //   });

    // let fill1 = this.state.eventKeyData.filter(el => {
    //   if (el['temporary-book-cover'] || el['book-cover']) {
    //     return el;
    //   }
    // });
    //printArray(arr, 7);
    //leftRotate(arr, 2, 7);
    // let arr8 = [...fill1, ...fill];

    // console.log("event key data------> ", this.state.eventKeyData
    // )
    let obj = {};
    // console.log("here---------->> ", this.state.eventKeyData)
    this.state.eventKeyData.forEach(el => {
      obj[Object.keys(el)] = el[Object.keys(el)];
      //
    });

    const endDate = new Date(moment().format('MM/DD/YYYY'));
    const createdDate = moment().format('YYYY-MM-DD');
    // let checkPreviouDate =
    //   new Date(createdDate).getTime() <
    //   new Date(obj['Giveaway start date,format']).getTime();

    const TaskEnd = endDate.setMonth(endDate.getMonth() + 18);
    // const EndDate = moment(TaskEnd).format('YYYY-MM-DD');

    let hostUrl = window.location.href;
    let breakHostUrl = hostUrl.split('/');
    let breakFromForm = breakHostUrl[4];
    let hideName = breakHostUrl[5];

    // console.log('hostNameAdd------------------------------------------------------> ', this.state.hostNameAdd);

    return (
      <>
        <Modal
          isOpen={this.state.isOpenPrivacy}
          toggle={this.togglePrivacyModal.bind(this.props.eventData.id)}
          id="myModal"
          className="advertise-privacy-popup delete-confirmation-popup"
        >
          <ModalBody>
            <p className="text-center p-0">
              Are you sure you want to remove this event from your private OTR
              calendar?
            </p>
            <div className="delete-btn">
              <button
                className="btn btn-filled align-self-end ml-auto"
                id="Next"
                type="button"
                disabled={this.state.disabled}
                onClick={() => this.props.toggle()}
              >
                Cancel
              </button>
              <button
                className="btn btn-filled align-self-end ml-auto"
                id="Next"
                type="button"
                onClick={() => {
                  this.handleDelete(this.props.eventData);
                }}
                disabled={this.state.disabled}
              >
                Remove
              </button>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.deleteEvent}
          toggle={this.delete.bind(this)}
          id="myModal"
          className="advertise-privacy-popup delete-confirmation-popup"
        >
          <ModalBody>
            <p className="text-center p-0" style={{ fontSize: '8px' }}>
              Are you certain you want to permanently delete this event from the
              OTR calendar? Doing so will permanently delete the event for any
              OTR members who have saved the event to their private reading
              calendars.
            </p>
            <div className="delete-btn">
              <button
                className="btn btn-filled align-self-start ml-auto"
                id="Next"
                type="button"
                disabled={this.state.disabled}
                onClick={this.delete}
              >
                CANCEL
              </button>
              <button
                className="btn btn-filled align-self-end ml-auto"
                id="Next"
                type="button"
                onClick={() => {
                  {
                    this.handleClick(this.props.eventData);
                  }
                }}
                disabled={this.state.disabled}
              >
                DELETE
              </button>
            </div>
          </ModalBody>
        </Modal>
        {!this.state.nameLoader ? (
          <Modal
            isOpen={this.props.isOpen}
            toggle={this.props.toggle}
            id="myModal"
            size="md"
            className="event-cover-popup event-filter-page-popup"
          >
            <ProblemModal
              isOpen={this.state.showProblemModal}
              toggle={this.toggleProblemModal}
              eventDetails={this.props.eventData}
            />

            <div className="model_cover">
              {pathimg ? (
                <ModalHeader
                  className="eventModal h5_tag"
                  toggle={this.props.toggle}
                >
                  {this.props.eventData.event_type === 'event_type_6' ||
                  this.props.eventData.event_type === 'Book Signing' ||
                  this.props.eventData.event_type === 'Ticket Sales' ||
                  this.props.title === 'Book Signing' ? (
                    <>
                      {' '}
                      <div className="book-release-cover1">
                        {this.props.eventData.event_type === 'event_type_5' ||
                        this.props.eventData.event_type === 'Cover Reveal' ||
                        this.props.title === 'Cover Reveal' ? (
                          ''
                        ) : (
                          <>
                            {' '}
                            {ddd.length ? (
                              ddd.map(el => <img src={el} alt="pathimg" />)
                            ) : (
                              <img src={pathimg} alt="pathimg" />
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="book-release-cover">
                        {this.props.eventData.event_type === 'event_type_5' ||
                        this.props.eventData.event_type === 'Cover Reveal' ||
                        this.props.title === 'Cover Reveal' ? (
                          ''
                        ) : (
                          <>
                            {' '}
                            {ddd.length ? (
                              ddd.map((el, index) => (
                                <img src={el} alt="pathimg" key={index} />
                              ))
                            ) : (
                              <img src={pathimg} alt="pathimg" />
                            )}
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {this.props.eventData ? (
                    <center>
                      {' '}
                      <h3
                        // style={{
                        //   paddingTop: pathImg !== "" ? "250px" : "0px",
                        //   textAlign: "center",
                        // }}
                        className="text-center"
                      >
                        {this.state.title === 'event_type_1'
                          ? 'Book Release'
                          : this.state.title === 'event_type_2'
                          ? 'Giveaway'
                          : this.state.title === 'event_type_5'
                          ? 'Cover Reveal'
                          : this.state.title === 'event_type_3'
                          ? 'Book Promo'
                          : this.state.title === 'event_type_4'
                          ? 'Release Party'
                          : this.state.title === 'event_type_7'
                          ? 'ARC Sign-up'
                          : this.state.title === 'Ticket Sales'
                          ? 'BOXES & SIGNINGS'
                          : this.state.title === 'Book Signing'
                          ? 'BOXES & SIGNINGS'
                          : this.state.title}
                      </h3>
                    </center>
                  ) : null}
                </ModalHeader>
              ) : (
                <ModalHeader className="eventModal" toggle={this.props.toggle}>
                  {this.props.eventData ? (
                    <center>
                      <h3 style={{ paddingTop: '0', textAlign: 'center' }}>
                        {this.state.title}
                      </h3>
                    </center>
                  ) : null}
                </ModalHeader>
              )}

              <ModalBody className="event_body event__body">
                <div className="event-popup-body">
                  {this.props.eventData.event_type === 'Book Release' ||
                  this.props.eventData.event_type === 'event_type_1' ||
                  this.props.eventWithLocalStorage === '1' ? (
                    <>
                      {' '}
                      <ul className="remove-icon">
                        {obj.Title && (
                          <li>
                            Title: <b>{obj.Title}</b>
                          </li>
                        )}

                        {obj.Author && (
                          <li>
                            {/* Author: <b>{obj.Author}</b> */}
                            Author:{' '}
                            {/* <a
                            href="javascript:;"
                            onClick={() =>
                              this.bookRelase(this.props.eventData.user_id)
                            }

                            //className="ellipsis"
                          >
                            {obj.Author}
                          </a> */}
                            <Link
                              to={`/users/${this.state.followCode}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {obj.Author}
                            </Link>
                          </li>
                        )}
                        {obj['Co-author(s)'] && (
                          <li>
                            Co-author(s): <b>{obj['Co-author(s)']}</b>
                          </li>
                        )}
                        {obj['Release date'] && (
                          <li>
                            Release date:{' '}
                            <b>
                              {moment(obj['Release date']).format(
                                'MMMM DD, YYYY'
                              )}
                            </b>
                          </li>
                        )}
                        {obj.Type && (
                          <li>
                            Type: <b>{obj.Type}</b>
                          </li>
                        )}

                        {obj['Series name'] && (
                          <li>
                            Series name: <b>{obj['Series name']}</b>
                          </li>
                        )}

                        {obj['Book #*:'] && (
                          <li>
                            {' '}
                            Book #: <b>{obj['Book #*:']}</b>
                          </li>
                        )}
                        {obj['Total number of books in the series*'] && (
                          <li>
                            Number of books:{' '}
                            <b>{obj['Total number of books in the series*']}</b>
                          </li>
                        )}

                        {obj.Genre && (
                          <li>
                            Genre:{''} <b>{obj.Genre}</b>
                          </li>
                        )}
                        {obj.Category && (
                          <li>
                            Category:{''} <b>{obj.Category}</b>
                          </li>
                        )}
                        {obj['Sub-genre(s)'] && (
                          <li>
                            Sub-genre(s):{''}{' '}
                            <b>{obj['Sub-genre(s)'].split(',').join(' | ')}</b>
                          </li>
                        )}
                        {obj['Keyword(s)/Trope(s)'] && (
                          <li>
                            {/* {console.log('hiiii', obj['Keyword(s)/Trope(s)'])} */}
                            Keyword(s)/Trope(s):{' '}
                            <b>
                              {obj['Keyword(s)/Trope(s)']
                                .split(',')
                                .join(' | ')}
                            </b>
                          </li>
                        )}
                        {obj['Goodreads link'] && (
                          <li>
                            Goodreads link:{' '}
                            <a
                              href={obj['Goodreads link'].toString()}
                              target="_blank"
                              //className="ellipsis"
                              rel="noreferrer"
                              style={{
                                maxWidth: '100%'
                              }}
                            >
                              {obj['Goodreads link'].slice(0, 30) + '...'}
                            </a>
                          </li>
                        )}
                        {obj['Pre-order links'] &&
                          obj['Pre-order links'].length > 0 && (
                            <>
                              <li>
                                <b>Pre-order links:</b>
                              </li>
                              <ul>
                                {obj['Pre-order links'].length &&
                                  obj['Pre-order links'].map(sub => {
                                    return (
                                      sub.length > 0 &&
                                      sub.map(subObj => {
                                        return (
                                          <li>
                                            <span>
                                              {Object.keys(subObj)[0]}:
                                            </span>

                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                //className="ellipsis"
                                                rel="noreferrer"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  30
                                                ) + '...'}
                                              </a>
                                            </span>
                                          </li>
                                        );
                                      })
                                    );
                                  })}
                              </ul>

                              {'  '}
                            </>
                          )}
                        {obj['Other retail sites'] &&
                          obj['Other retail sites'].length > 0 && (
                            <>
                              <li>
                                <b>Other retail sites:</b>
                              </li>
                              <ul>
                                {obj['Other retail sites'].length &&
                                  obj['Other retail sites'].map(sub => {
                                    return (
                                      sub.length > 0 &&
                                      sub.map(subObj => {
                                        return (
                                          <li>
                                            <span>
                                              {Object.keys(subObj)[0]}:
                                            </span>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                //className="ellipsis"
                                                rel="noreferrer"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  30
                                                ) + '...'}
                                              </a>
                                            </span>
                                          </li>
                                        );
                                      })
                                    );
                                  })}
                              </ul>

                              {'  '}
                            </>
                          )}

                        {obj['Book trailer'] && (
                          <li>
                            Book trailer:{' '}
                            <a
                              href={obj['Book trailer'].toString()}
                              target="_blank"
                              //className="ellipsis"
                              rel="noreferrer"
                              style={{
                                maxWidth: '100%'
                              }}
                            >
                              {obj['Book trailer'].slice(0, 30) + '...'}
                            </a>
                          </li>
                        )}
                        {obj['Book playlist'] && (
                          <li>
                            Book playlist:{' '}
                            <a
                              href={obj['Book playlist'].toString()}
                              target="_blank"
                              rel="noreferrer"
                              //className="ellipsis"
                              style={{
                                maxWidth: '100%'
                              }}
                            >
                              {obj['Book playlist'].slice(0, 30) + '...'}
                            </a>
                          </li>
                        )}
                        {obj['Link to teasers'] && (
                          <li>
                            Link to teasers:{' '}
                            <a
                              href={obj['Link to teasers']}
                              target="_blank"
                              //className="ellipsis"
                              rel="noreferrer"
                              style={{
                                maxWidth: '100%'
                              }}
                            >
                              {obj['Link to teasers'].slice(0, 30) + '...'}
                            </a>
                          </li>
                        )}

                        {obj.Blurb && (
                          <>
                            <li>Blurb:</li>
                            {'  '}
                            <li>
                              <i>
                                {/* {obj.Blurb.slice(0, 70) + '...'}

                              <button onClick={obj.Blurb}>Read More</button> */}
                                {this.state.isReadMore
                                  ? obj.Blurb.slice(0, 70)
                                  : obj.Blurb}

                                {obj.Blurb.length > 500 && (
                                  <span onClick={this.toggleReadMore}>
                                    {this.state.isReadMore ? (
                                      <p style={{ color: '#007bff' }}>
                                        ...read more
                                      </p>
                                    ) : (
                                      <p style={{ color: '#007bff' }}>
                                        ...show less
                                      </p>
                                    )}
                                  </span>
                                )}
                              </i>
                            </li>
                          </>
                        )}

                        {this.props.title === 'Book Release' ? (
                          <>
                            {' '}
                            <div>
                              <b>All OTR events linked to Book Release:</b>
                              <ul>
                                <li>
                                  Linked Events page:{' '}
                                  <Link
                                    to={{
                                      pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                        'userId'
                                      )}&parentId=${
                                        this.props.eventData.parent_id
                                          ? this.props.eventData.parent_id
                                            ? this.props.eventData.parent_id
                                            : 'null'
                                          : this.props.parenId
                                          ? this.props.parenId
                                          : 'null'
                                      }${
                                        !(
                                          this.props.eventData.parent_id ||
                                          this.props.parenId
                                        )
                                          ? '&title=' +
                                            this.props.eventData.title +
                                            '&author=' +
                                            this.props.eventData
                                              .author_first_name
                                          : ''
                                      }`
                                    }}
                                    target="_blank"
                                  >
                                    {/* {window.location.href} */}
                                    {/* {window.location.host} */}
                                    {/* /#/dashboard/my-events */}
                                    otr/linkedeventpage/{'...'}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        {breakFromForm === 'setup-event' && (
                          <>
                            {this.state.hostNameAdd && (
                              <li>
                                Added by:{' '}
                                <Link
                                  to={`/users/${this.state.followCode}`}
                                  target="_blank"
                                >
                                  {this.state.hostNameAdd}
                                </Link>
                              </li>
                            )}
                          </>
                        )}
                      </ul>
                    </>
                  ) : this.props.eventData.event_type === 'event_type_2' ||
                    this.props.eventData.event_type === 'Giveaway' ||
                    this.props.eventWithLocalStorage === '2' ? (
                    <>
                      {' '}
                      {/* {console.log("onkkkkkkkkjjjjjjjjjj==> ", obj)} */}
                      <ul className="remove-icon">
                        {obj['Name of Giveaway'] && (
                          <li>
                            Name of Giveaway: <b>{obj['Name of Giveaway']}</b>
                          </li>
                        )}
                        <li>
                          Book Title: <b>{obj.Title}</b>
                        </li>
                        {obj.Author && (
                          <li>
                            {/* Author: <b>{obj.Author}</b> */}
                            Author:{' '}
                            <Link
                              to={`/users/${this.state.authorLink}`}
                              target="_blank"
                            >
                              {this.state.AuthorFullName
                                ? this.state.AuthorFullName
                                : obj.Author}
                            </Link>
                          </li>
                        )}
                        {obj['Co-author(s)'] && (
                          <li>
                            Co-author(s): <b>{obj['Co-author(s)']}</b>
                          </li>
                        )}
                        {obj['Giveaway start date'] && (
                          <li>
                            Giveaway start date:{' '}
                            <b>
                              {moment(obj['Giveaway start date']).format(
                                'MMMM DD, YYYY'
                              )}
                            </b>
                          </li>
                        )}
                        {obj['Giveaway end date'] && (
                          <li>
                            Giveaway end date:{' '}
                            <b>
                              {moment(obj['Giveaway end date']).format(
                                'MMMM DD, YYYY'
                              )}
                            </b>
                          </li>
                        )}
                        {obj['giveawayopen'] === 'US Eastern time zone' ? (
                          <li>
                            Giveaway time zone:{' '}
                            <b>
                              {obj['giveawayopen'] === 'US Eastern time zone'
                                ? obj['giveawayopen']
                                : ''}
                            </b>
                          </li>
                        ) : (
                          ''
                        )}
                        {obj['giveaway timezone'] &&
                        obj['giveawayopen'] === 'Other time zone' ? (
                          <li>
                            Giveaway time zone:{' '}
                            <b>{obj['giveaway timezone']}</b>
                          </li>
                        ) : (
                          ''
                        )}
                        {obj['Time zone'] && (
                          <li>
                            Time zone: <b>{obj['Time zone']}</b>
                          </li>
                        )}
                        {obj['Link to Giveaway'] && (
                          <li>
                            Link to Giveaway:{' '}
                            <a
                              href={obj['Link to Giveaway']}
                              target="_blank"
                              //className="ellipsis"
                              rel="noreferrer"
                            >
                              {obj['Link to Giveaway'].slice(0, 35)}
                              {'...'}
                            </a>
                          </li>
                        )}
                        {obj['Must join group/page to participate'] ? (
                          <li>
                            Must join group/page to participate:{' '}
                            <b>
                              {obj['Must join group/page to participate']
                                ? obj['Must join group/page to participate']
                                : 'No'}
                            </b>
                          </li>
                        ) : (
                          <li>
                            Must join group/page to participate: <b>{'No'}</b>
                          </li>
                        )}{' '}
                        {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                          <>
                            <li>
                              <b>Host(s):</b>
                            </li>
                            <ul>
                              {obj['Host(s)'].length &&
                                obj['Host(s)'].map(sub => {
                                  return (
                                    sub.length > 0 &&
                                    sub.map(subObj => {
                                      return (
                                        <li>
                                          <span>{Object.keys(subObj)[0]}:</span>

                                          {Object.values(subObj)[0].split(
                                            ':'
                                          )[0] === 'https' ||
                                          Object.values(subObj)[0].split(
                                            ':'
                                          )[0] === 'http' ? (
                                            <>
                                              <span>
                                                {' '}
                                                <a
                                                  href={Object.values(
                                                    subObj
                                                  )[0].toString()}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  //className="ellipsis"
                                                  style={{
                                                    maxWidth: '100%'
                                                  }}
                                                >
                                                  {Object.values(subObj)[0]
                                                    .toString()
                                                    .slice(0, 35)}

                                                  {'...'}
                                                </a>
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <b>
                                                {' '}
                                                {Object.values(
                                                  subObj
                                                )[0].toString()}
                                              </b>
                                            </>
                                          )}
                                        </li>
                                      );
                                    })
                                  );
                                })}
                            </ul>

                            {'  '}
                          </>
                        )}
                        {obj['Exclusive to OTR'] ? (
                          <li>
                            Exclusive to OTR:{' '}
                            <b>
                              {obj['Exclusive to OTR']
                                ? obj['Exclusive to OTR']
                                : 'No'}
                            </b>
                          </li>
                        ) : (
                          <>
                            {' '}
                            <li>
                              Exclusive to OTR: <b>{'No'}</b>
                            </li>
                          </>
                        )}
                      </ul>
                      <div>
                        <b>All OTR events linked to Book Release:</b>
                        <ul className="remove-icon">
                          <li>
                            Linked Events page:{' '}
                            <Link
                              // to={{
                              //   pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                              //     'userId'
                              //   )}
                              //   &parentId=${
                              //     this.props.eventData.parent_id
                              //       ? this.props.eventData.parent_id
                              //         ? this.props.eventData.parent_id
                              //         : 'null'
                              //       : this.props.parenId
                              //       ? this.props.parenId
                              //       : 'null'
                              //   }
                              //   `
                              to={{
                                pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                  'userId'
                                )}&parentId=${
                                  this.props.eventData.parent_id
                                    ? this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                      : this.props.eventData.id
                                    : this.props.parenId
                                    ? this.props.parenId
                                    : this.props.eventData.id
                                }
                              `
                              }}
                              target="_blank"
                            >
                              otr/linkedeventpage/{'...'}
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <ul class="remove-icon">
                        <li>
                          Added by:{' '}
                          <Link
                            to={`/users/${this.state.followCode}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.state.hostNameAdd}
                          </Link>
                        </li>
                      </ul>
                    </>
                  ) : this.props.eventData.event_type === 'event_type_3' ||
                    this.props.eventData.event_type === 'Book Promo' ||
                    this.props.eventWithLocalStorage === '3' ? (
                    <ul className="remove-icon">
                      <li>
                        Book Title: <b>{obj.Title}</b>
                      </li>
                      {obj.Author && (
                        <li>
                          {/* Author: <b>{obj.Author}</b> */}
                          Author:{' '}
                          <Link
                            to={`/users/${this.state.authorLink}`}
                            target="_blank"
                          >
                            {this.state.AuthorFullName
                              ? this.state.AuthorFullName
                              : obj.Author}
                          </Link>
                        </li>
                      )}
                      {obj['Co-author(s)'] && (
                        <li>
                          Co-author(s): <b>{obj['Co-author(s)']}</b>
                        </li>
                      )}
                      {obj['Sign-up start date'] && (
                        <li>
                          Sign-up start Date:{' '}
                          <b>
                            {moment(obj['Sign-up start date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['Sign-up link'] && (
                        <li>
                          Sign-up link:{' '}
                          <a
                            href={obj['Sign-up link']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Sign-up link'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}
                      {obj['Cover Reveal Date'] && (
                        <li>
                          Cover Reveal Date:{' '}
                          <b>
                            {moment(obj['Cover Reveal Date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['Release date'] && (
                        <li>
                          Estimated release date:{' '}
                          <b>
                            {moment(obj['Release date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}

                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>
                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map((subObj, index) => {
                                    return (
                                      <li key={index}>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0]
                                                  .toString()
                                                  .slice(0, 35)}

                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>

                          {'  '}
                        </>
                      )}
                      <div>
                        <b>All OTR events linked to Book Release:</b>
                        <ul>
                          <li>
                            Linked Events page:{' '}
                            <Link
                              // to={{
                              //   pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                              //     'userId'
                              //   )} &parentId=${
                              //     this.props.eventData.parent_id
                              //       ? this.props.eventData.parent_id
                              //         ? this.props.eventData.parent_id
                              //         : 'null'
                              //       : this.props.parenId
                              //       ? this.props.parenId
                              //       : 'null'
                              //   }`
                              to={{
                                pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                  'userId'
                                )}&parentId=${
                                  this.props.eventData.parent_id
                                    ? this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                      : this.props.eventData.id
                                    : this.props.parenId
                                    ? this.props.parenId
                                    : this.props.eventData.id
                                }
                              `
                              }}
                              target="_blank"
                            >
                              otr/linkedeventpage/{'...'}
                            </Link>
                          </li>
                        </ul>
                        <ul class="remove-icon">
                          <li>
                            Added by:{' '}
                            <Link
                              to={`/users/${this.state.followCode}`}
                              target="_blank"
                            >
                              {this.state.hostNameAdd}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  ) : this.props.eventData.event_type === 'event_type_7' ||
                    this.props.eventData.event_type === 'ARC Signup' ||
                    this.props.eventWithLocalStorage === '7' ? (
                    <ul className="remove-icon">
                      {obj.Title && (
                        <li>
                          Book Title: <b>{obj.Title}</b>
                        </li>
                      )}
                      {obj.Author && (
                        <li>
                          Author:{' '}
                          <Link
                            to={`/users/${this.state.authorLink}`}
                            target="_blank"
                          >
                            {this.state.AuthorFullName
                              ? this.state.AuthorFullName
                              : obj.Author}
                          </Link>
                        </li>
                      )}
                      {obj['Co-author(s)'] && (
                        <li>
                          Co-author(s): <b>{obj['Co-author(s)']}</b>
                        </li>
                      )}

                      {obj['ARC sign-up date'] && (
                        <li>
                          ARC sign-up date:{' '}
                          <b>
                            {moment(obj['ARC sign-up date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['ARC Sign-up link'] && (
                        <li>
                          ARC Sign-up link:{' '}
                          <a
                            href={obj['ARC Sign-up link']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['ARC Sign-up link'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}

                      {obj['Release date'] && (
                        <li>
                          Release date:{' '}
                          <b>
                            {moment(obj['Release date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>

                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map(subObj => {
                                    return (
                                      <li>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  35
                                                )}
                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>

                          {'  '}
                        </>
                      )}
                      <div>
                        <b>All OTR events linked to Book Release:</b>
                        <ul className="remove-icon">
                          <li>
                            Linked Events page:{' '}
                            <Link
                              // to={{
                              //   pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                              //     'userId'
                              //   )}&parentId=${
                              //     this.props.eventData.parent_id
                              //       ? this.props.eventData.parent_id
                              //         ? this.props.eventData.parent_id
                              //         : 'null'
                              //       : this.props.parenId
                              //       ? this.props.parenId
                              //       : 'null'
                              //   }`
                              to={{
                                pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                  'userId'
                                )}&parentId=${
                                  this.props.eventData.parent_id
                                    ? this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                      : this.props.eventData.id
                                    : this.props.parenId
                                    ? this.props.parenId
                                    : this.props.eventData.id
                                }
                              `
                              }}
                              target="_blank"
                            >
                              otr/linkedeventpage/{'...'}
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <li>
                        Added by:{' '}
                        <Link
                          to={`/users/${this.state.followCode}`}
                          target="_blank"
                        >
                          {this.state.hostNameAdd}
                        </Link>
                      </li>
                    </ul>
                  ) : this.props.eventData.event_type === 'event_type_4' ||
                    this.props.eventData.event_type === 'Release Party' ||
                    this.props.eventWithLocalStorage === '4' ? (
                    <ul className="remove-icon">
                      {obj['release-party-event-name'] && (
                        <li>
                          Name of Release Party:{' '}
                          <b>{obj['release-party-event-name']}</b>
                        </li>
                      )}
                      {obj.Title && (
                        <li>
                          Book Title: <b>{obj.Title}</b>
                        </li>
                      )}
                      {obj.Author && (
                        <li>
                          Author:{' '}
                          <Link
                            to={`/users/${this.state.authorLink}`}
                            target="_blank"
                          >
                            {this.state.AuthorFullName
                              ? this.state.AuthorFullName
                              : obj.Author}
                          </Link>
                        </li>
                      )}
                      {obj['Co-author(s)'] && (
                        <li>
                          Co-author(s): <b>{obj['Co-author(s)']}</b>
                        </li>
                      )}

                      {obj['Date of release party'] && (
                        <li>
                          Date of Release Party:{' '}
                          <b>
                            {moment(obj['Date of release party']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}

                      {this.state.releasePartyPopupOpen &&
                      this.state.releasePartyPopupOpen ? (
                        <>
                          <li>
                            Start time in US Eastern time zone:{'  '}{' '}
                            <b>
                              {' '}
                              {this.getEstTimeNow(
                                new Date(),
                                this.state.releasePartyPopupOpen
                              )}
                            </b>
                          </li>
                        </>
                      ) : (
                        <>
                          <li>
                            Start time in US Eastern time zone:{'  '}
                            <b>
                              {this.getEstTimeNow(
                                new Date(),
                                this.props.selectTime
                              )}
                            </b>
                          </li>
                        </>
                      )}

                      {obj['Time'] && (
                        <li>
                          <i>Start time in local time zone</i>:{''}{' '}
                          <i>
                            {moment(obj['Time'], ['HH.mm']).format('hh:mm A')} (
                            {this.props.istutc
                              ? this.props.istutc
                              : this.state.timee}
                            )
                          </i>
                        </li>
                      )}
                      {obj['Link to party'] && (
                        <li>
                          Link to party:{' '}
                          <a
                            href={obj['Link to party']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Link to party'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}

                      {obj['Guest authors'] && (
                        <li>
                          Guest authors: <i>{obj['Guest authors']}</i>
                        </li>
                      )}

                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>
                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map(subObj => {
                                    return (
                                      <li>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0]
                                                  .toString()
                                                  .slice(0, 35)}

                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>

                          {'  '}
                        </>
                      )}

                      {obj['*Must join group/page to participate'] ? (
                        <li>
                          Must join group/page to participate:{' '}
                          <b>{obj['*Must join group/page to participate']}</b>
                        </li>
                      ) : (
                        <li>
                          Must join group/page to participate: <b>{'No'}</b>
                        </li>
                      )}
                      {obj['*Exclusive to OTR'] ? (
                        <li>
                          Exclusive to OTR: <b>{obj['*Exclusive to OTR']}</b>
                        </li>
                      ) : (
                        <li>
                          Exclusive to OTR: <b>{'No'}</b>
                        </li>
                      )}
                      <div>
                        <b>All OTR events linked to Book Release:</b>
                        <ul className="remove-icon">
                          <li>
                            Linked Events page:{' '}
                            <Link
                              // to={{
                              //   pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                              //     'userId'
                              //   )}&parentId=${
                              //     this.props.eventData.parent_id
                              //       ? this.props.eventData.parent_id
                              //         ? this.props.eventData.parent_id
                              //         : 'null'
                              //       : this.props.parenId
                              //       ? this.props.parenId
                              //       : 'null'
                              //   }`
                              to={{
                                pathname: `/dashboard/linkedbookrelease?userId=${Number(
                                  localStorage.getItem('userId')
                                )}&parentId=${
                                  this.props.eventData.parent_id
                                    ? this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                      : this.props.eventData.id
                                    : this.props.parenId
                                    ? this.props.parenId
                                    : this.props.eventData.id
                                }
                              `
                              }}
                              target="_blank"
                            >
                              otr/linkedeventpage/{'...'}
                            </Link>
                          </li>
                        </ul>
                        <li>
                          Added by:{' '}
                          <Link
                            to={`/users/${this.state.followCode}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {this.state.hostNameAdd}
                          </Link>
                        </li>
                      </div>
                    </ul>
                  ) : this.props.eventData.event_type === 'event_type_5' ||
                    this.props.eventData.event_type === 'Cover Reveal' ||
                    this.props.eventWithLocalStorage === '5' ? (
                    <ul className="remove-icon">
                      {obj.Title && (
                        <li>
                          Book Title: <b>{obj.Title}</b>
                        </li>
                      )}
                      {obj.Author && (
                        <li>
                          {/* Author: <b>{obj.Author}</b> */}
                          Author:{' '}
                          <Link
                            to={`/users/${this.state.authorLink}`}
                            target="_blank"
                          >
                            {this.state.AuthorFullName
                              ? this.state.AuthorFullName
                              : obj.Author}
                          </Link>
                        </li>
                      )}
                      {obj['Co-author(s)'] && (
                        <li>
                          Co-author(s): <b>{obj['Co-author(s)']}</b>
                        </li>
                      )}
                      {obj['Cover reveal date'] && (
                        <li>
                          Cover Reveal Date:{' '}
                          <b>
                            {moment(obj['Cover reveal date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['book-cover-link'] && (
                        <li>
                          Link where book cover can be viewed on day of reveal:{' '}
                          <a
                            href={obj['book-cover-link']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['book-cover-link'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}
                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>
                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map(subObj => {
                                    return (
                                      <li>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  35
                                                )}
                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>

                          {'  '}
                        </>
                      )}
                      <div>
                        <b>All OTR events linked to Book Release:</b>
                        <ul className="remove-icon">
                          <li>
                            Linked Events page:{' '}
                            <Link
                              // to={{
                              //   pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                              //     'userId'
                              //   )}&parentId=${
                              //     this.props.eventData.parent_id
                              //       ? this.props.eventData.parent_id
                              //         ? this.props.eventData.parent_id
                              //         : 'null'
                              //       : this.props.parenId
                              //       ? this.props.parenId
                              //       : 'null'
                              //   }`
                              to={{
                                pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                  'userId'
                                )}&parentId=${
                                  this.props.eventData.parent_id
                                    ? this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                      : this.props.eventData.id
                                    : this.props.parenId
                                    ? this.props.parenId
                                    : this.props.eventData.id
                                }
                              `
                              }}
                              target="_blank"
                            >
                              otr/linkedeventpage/{'...'}
                            </Link>
                          </li>
                          <li>
                            Added by:{' '}
                            <Link
                              to={`/users/${this.state.followCode}`}
                              target="_blank"
                            >
                              {this.state.hostNameAdd}
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </ul>
                  ) : this.props.eventData.event_type === 'Book Signing' ||
                    this.props.eventData.event_type === 'event_type_6' ||
                    this.props.eventWithLocalStorage === '6' ? (
                    <ul className="remove-icon">
                      {obj['Name of book signing event'] && (
                        <li>
                          Name of event:{' '}
                          <b>{obj['Name of book signing event']}</b>
                        </li>
                      )}

                      {obj['City'] && (
                        <li>
                          City: {''}
                          {'  '} <b>{obj['City']}</b>
                        </li>
                      )}
                      {obj['Country'] && (
                        <li>
                          Country:{''}
                          {'  '}
                          <b>{obj['Country']}</b>
                        </li>
                      )}
                      {obj['Link to book signing event'] && (
                        <li>
                          Link to event:{' '}
                          <a
                            href={obj['Link to book signing event']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Link to book signing event'].slice(0, 28)}
                            {'...'}
                          </a>
                        </li>
                      )}
                      {obj['Event start date'] && (
                        <li>
                          Event start date:{' '}
                          <b>
                            {moment(obj['Event start date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['Event end date'] && (
                        <li>
                          Event end date:{' '}
                          <b>
                            {moment(obj['Event end date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}

                      {obj['Link to ticket sales'] && (
                        <li>
                          Link to Ticket/box sales:{''}
                          {'  '}
                          <a
                            href={obj['Link to ticket sales']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Link to ticket sales'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}

                      {obj['Ticket sales start date'] && (
                        <li>
                          Ticket/box sales start date:{' '}
                          <b>
                            {moment(obj['Ticket sales start date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['List of attending authors'] && (
                        <li>
                          List of attending/participating authors:{''}
                          {'  '}
                          <b>{obj['List of attending authors']}</b>
                        </li>
                      )}
                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>
                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map(subObj => {
                                    return (
                                      <li>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  35
                                                )}
                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>
                          <li className="remove-icon">
                            Added by:{' '}
                            {this.state.followCode ? (
                              <>
                                <Link
                                  to={`/users/${this.state.followCode}`}
                                  target="_blank"
                                >
                                  {this.state.hostNameAdd}
                                </Link>
                              </>
                            ) : (
                              <>
                                <b> {this.state.hostNameAdd}</b>
                              </>
                            )}
                          </li>

                          {'  '}
                        </>
                      )}
                    </ul>
                  ) : (
                    <ul className="remove-icon">
                      {obj['Name of book signing event'] && (
                        <li>
                          Name of event*:{' '}
                          <b>{obj['Name of book signing event']}</b>
                        </li>
                      )}

                      {obj['City'] && (
                        <li>
                          City: {''}
                          {'  '} <b>{obj['City']}</b>
                        </li>
                      )}
                      {obj['Country'] && (
                        <li>
                          Country:{''}
                          {'  '}
                          <b>{obj['Country']}</b>
                        </li>
                      )}
                      {obj['Link to book signing event'] && (
                        <li>
                          Link to event:{' '}
                          <a
                            href={obj['Link to book signing event']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Link to book signing event'].slice(0, 28)}
                            {'...'}
                          </a>
                        </li>
                      )}
                      {obj['Event start date'] && (
                        <li>
                          Event start date:{' '}
                          <b>
                            {moment(obj['Event start date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['Event end date'] && (
                        <li>
                          Event end date:{' '}
                          <b>
                            {moment(obj['Event end date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}

                      {obj['Link to ticket sales'] && (
                        <li>
                          Link to Ticket/box sales:{''}
                          {'  '}
                          <a
                            href={obj['Link to ticket sales']}
                            target="_blank"
                            //className="ellipsis"
                            rel="noreferrer"
                          >
                            {obj['Link to ticket sales'].slice(0, 35)}
                            {'...'}
                          </a>
                        </li>
                      )}

                      {obj['Ticket sales start date'] && (
                        <li>
                          Ticket/box sales start date:{' '}
                          <b>
                            {moment(obj['Ticket sales start date']).format(
                              'MMMM DD, YYYY'
                            )}
                          </b>
                        </li>
                      )}
                      {obj['List of attending authors'] && (
                        <li>
                          List of attending/participating authors:{''}
                          {'  '}
                          <b>{obj['List of attending authors']}</b>
                        </li>
                      )}
                      {obj['Host(s)'] && obj['Host(s)'].length > 0 && (
                        <>
                          <li>
                            <b>Host(s):</b>
                          </li>
                          <ul>
                            {obj['Host(s)'].length &&
                              obj['Host(s)'].map(sub => {
                                return (
                                  sub.length > 0 &&
                                  sub.map(subObj => {
                                    return (
                                      <li>
                                        <span>{Object.keys(subObj)[0]}:</span>

                                        {Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'https' ||
                                        Object.values(subObj)[0].split(
                                          ':'
                                        )[0] === 'http' ? (
                                          <>
                                            <span>
                                              {' '}
                                              <a
                                                href={Object.values(
                                                  subObj
                                                )[0].toString()}
                                                target="_blank"
                                                rel="noreferrer"
                                                //className="ellipsis"
                                                style={{
                                                  maxWidth: '100%'
                                                }}
                                              >
                                                {Object.values(subObj)[0].slice(
                                                  0,
                                                  35
                                                )}
                                                {'...'}
                                              </a>
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <b>
                                              {' '}
                                              {Object.values(
                                                subObj
                                              )[0].toString()}
                                            </b>
                                          </>
                                        )}
                                      </li>
                                    );
                                  })
                                );
                              })}
                          </ul>

                          {'  '}
                        </>
                      )}
                      {this.state.hostNameAdd && (
                        <li className="remove-icon">
                          Added by:{' '}
                          {this.state.followCode ? (
                            <>
                              <Link
                                to={`/users/${this.state.followCode}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {this.state.hostNameAdd}
                              </Link>
                            </>
                          ) : (
                            <>
                              <b> {this.state.hostNameAdd}</b>
                            </>
                          )}
                        </li>
                      )}
                    </ul>
                  )}

                  {!this.props.preview &&
                    (this.props.eventData.event_type === 'event_type_1' ||
                      this.props.eventData.event_type.toLowerCase() ===
                        'book release') && (
                      <>
                        <div>
                          <b>All OTR events linked to Book Release:</b>
                          <ul className="remove-icon">
                            <li>
                              {/* {console.log("---------------->> ", )} */}
                              Linked Events page:{' '}
                              <Link
                                to={{
                                  pathname: `/dashboard/linkedbookrelease?userId=${localStorage.getItem(
                                    'userId'
                                  )}&parentId=${
                                    this.props.eventData.parent_id
                                      ? this.props.eventData.parent_id
                                        ? this.props.eventData.parent_id
                                        : this.props.eventData.id
                                      : this.props.parenId
                                      ? this.props.parenId
                                      : this.props.eventData.id
                                  }
                              `
                                }}
                                target="_blank"
                              >
                                otr/linkedeventpage/{'...'}
                              </Link>
                            </li>
                          </ul>
                          <p>
                            Added by:{' '}
                            <Link
                              to={`/users/${this.state.followCode}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {this.state.hostNameAdd}
                            </Link>
                          </p>
                        </div>
                      </>
                    )}
                </div>

                <div className="event-poup-btn">
                  {!this.props.preview ? (
                    <>
                      <div className="report-delete">
                        <button
                          className="btn btn-link btn-custom"
                          onClick={this.toggleProblemModal}
                        >
                          <span style={{ fontSize: '13px' }}>
                            REPORT AN ISSUE
                          </span>
                        </button>
                      </div>
                    </>
                  ) : (
                    ''
                  )}

                  {this.props.preview ? (
                    <>
                      <button
                        className="btn new_btn_save_b"
                        onClick={() => {
                          this.onSave(this);
                          this.setState({ nameLoader: false });
                        }}
                        ref="btn"
                      >
                        Save
                      </button>

                      <button
                        className="btn new_btn_save_bt"
                        onClick={() => {
                          this.props.clearPreviewData();
                        }}
                      >
                        Edit
                      </button>
                    </>
                  ) : (
                    <>
                      {!isTicketSalesPreview &&
                      this.props.eventData.ownedByMe &&
                      this.state.previousDateStore === true &&
                      this.state.previousDatEnd === true ? (
                        <>
                          <button
                            className="btn btn-link btn-custom"
                            onClick={this.delete}
                          >
                            <span style={{ fontSize: '13px', color: 'red' }}>
                              DELETE FROM OTR
                            </span>
                          </button>
                          <button
                            className="btn new_btn_save_bt"
                            onClick={() => {
                              localStorage.setItem(
                                'eventId',
                                this.props.eventData.id
                              );
                              localStorage.setItem(
                                'event_type',
                                this.props.eventData['event_id']
                                  .split('_')
                                  .pop()
                              );

                              this.props.editEvent(
                                '/api/v1/event/' + this.props.eventData.id,
                                {
                                  headers: {
                                    'x-authorization': localStorage.getItem(
                                      'token'
                                    )
                                  }
                                },
                                this.props.newTab
                              );
                            }}
                          >
                            EDIT
                          </button>
                        </>
                      ) : !isTicketSalesPreview &&
                        this.props.eventData.ownedByMe &&
                        this.state.previousDateStore === false &&
                        this.state.previousDatEnd === true ? (
                        <>
                          {/* <button
                          className="btn new_btn_save_bt"
                          style={{
                            backgroundColor: '#a2a2a2'
                          }}
                          onClick={() => {
                            this.togglePrivacyModal(this.props.eventData.id);
                          }}
                        >
                          REMOVE
                        </button> */}

                          <button
                            className="btn btn-link btn-custom"
                            onClick={this.delete}
                          >
                            <span style={{ fontSize: '13px', color: 'red' }}>
                              DELETE FROM OTR
                            </span>
                          </button>
                          <button
                            className="btn new_btn_save_bt"
                            onClick={() => {
                              localStorage.setItem(
                                'eventId',
                                this.props.eventData.id
                              );
                              localStorage.setItem(
                                'event_type',
                                this.props.eventData['event_id']
                                  .split('_')
                                  .pop()
                              );

                              this.props.editEvent(
                                '/api/v1/event/' + this.props.eventData.id,
                                {
                                  headers: {
                                    'x-authorization': localStorage.getItem(
                                      'token'
                                    )
                                  }
                                },
                                this.props.newTab
                              );
                            }}
                          >
                            EDIT
                          </button>
                        </>
                      ) : (
                        <>
                          {/* {isTicketSalesPreview && (
                          <p class="ml-3">
                            To edit this event, click on the associated book
                            signing event and edit the same.
                          </p>
                        ) } */}
                        </>
                      )}

                      {!this.props.eventData.ownedByMe ? (
                        <>
                          {this.props.eventMode === true &&
                          this.props.isInclude === false &&
                          this.state.previousDateStore === true &&
                          this.state.previousDatEnd === true ? (
                            <>
                              <button
                                className="btn new_btn_save_bt"
                                onClick={() =>
                                  this.handleAddCalendar(this.props.eventData)
                                }
                              >
                                Add to my calendar
                              </button>
                            </>
                          ) : this.props.eventMode === true &&
                            this.props.isInclude === false &&
                            this.state.previousDateStore === false &&
                            this.state.previousDatEnd === true ? (
                            <>
                              <button
                                className="btn new_btn_save_bt"
                                onClick={() =>
                                  this.handleAddCalendar(this.props.eventData)
                                }
                              >
                                Add to my calendar
                              </button>
                            </>
                          ) : this.state.previousDateStore === false &&
                            this.state.previousDatEnd === false ? (
                            ''
                          ) : (
                            <>
                              {hideName === 'notification' ? (
                                ''
                              ) : (
                                <div className="remove-btn">
                                  <button
                                    className="btn new_btn_save_bt btn-danger"
                                    onClick={() =>
                                      this.togglePrivacyModal(
                                        this.props.eventData.id
                                      )
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                </div>
              </ModalBody>
            </div>
          </Modal>
        ) : (
          <Loader />
        )}
        {this.state.openAuthorEventName ? (
          <UserProfileModal
            isOpen={this.state.openAuthorEventName}
            profileData={this.state.storeAuthorData}
            toggle={() => this.hideEvent(this.state.storeAuthorData)}
          />
        ) : null}

        {this.state.openPopUp ? (
          <UserProfileModal
            isOpen={this.state.openPopUp}
            profileData={this.state.openHostInformation}
            toggle={() => this.hideEventHost(this.state.openPopUp)}
          />
        ) : null}
      </>
    );
  }

  // onDelete() {
  //   this.props.delete('/api/v1/event/' + this.props.eventData.id, {
  //     headers: { 'x-authorization': localStorage.getItem('token') }
  //   });
  //   this.setState({ modal: false });
  //   this.props.getSummary();
  //   this.props.getAllEvents();
  // }

  getFormattedValue(obj, key) {
    if (obj) {
      if (obj.format && obj.format === 'date' && key !== 'Time') {
        return moment(obj[key]).format('MMMM DD, YYYY');
      } else if (key === 'Time') {
        // const hour = obj[key].split(':')[0] * 1;
        // const myho = hour > 12 ? 'PM' : 'AM';
        // return obj[key] + ' ' + myho;
        var ts = obj[key];
        var H = +ts.substr(0, 2);
        var h = H % 12 || 12;
        h = h < 10 ? '0' + h : h; // leading 0 at the left for 1 digit hours
        var ampm = H < 12 ? ' AM' : ' PM';
        ts = h + ts.substr(2, 3) + ampm;
        return ts;
      } else if (key === 'Additional roles') {
        return obj['Name'];
      } else if (key === 'alternative-book-cover') {
        delete obj[key];
      } else if (key === 'temporary-book-cover') {
        delete obj[key];
      } else if (obj[key]) {
        return obj[key];
      } else {
        return obj;
      }
    }
  }

  handleClick = async filterDelete => {
    const data = filterDelete;
    // const mydata = this.props.fil;
    let url = window.location.href;
    let split = url.split('/');
    let tab = split[5] ? split[5] : split[4];
    const mydata = this.props.filterData;
    let pageName = '';
    if (tab === 'event-filter') {
      pageName = 'eventFilter';
    }
    if (tab === 'dashboard') {
      pageName = 'listView';
    }
    if (tab === 'tranding-events') {
      pageName = 'trendingList';
    }
    if (tab === 'my-events') {
      pageName = 'my-events';
    }

    this.props.delete(`/api/v1/event/${data.id}`, data, pageName, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    // this.props.getAllEvents(`/api/v1/events?event_type=my`, {
    //   headers: { 'x-authorization': localStorage.getItem('token') }
    // });
    this.setState({ modal: false });
    this.props.getSummary();
    this.props.toggle();
    this.props.getAllEvents(
      this.props.getAllEvents(`/api/v1/events?event_type=my`, {
        headers: { 'x-authorization': localStorage.getItem('token') }
      })
    );
  };

  onSave() {
    this.refs.btn.setAttribute('disabled', 'disabled');
    this.props.confirmSave();
    this.props.getAllEvents();
  }

  async componentDidMount() {
    if (this.props.preview && this.props.eventData) {
      this.setState({
        eventKeyData: this.props.eventData,
        title: this.props.title,
        myid: this.props.eventData.parent_id,
        authorEvent: this.props.eventData.id
      });
      //when user save event and display name on popup different popups
      const getDispalyNameFromSaveEvent = await axios.get(
        BASEURL +
          `/api/v1/users/addfromSaveEvent/${localStorage.getItem('userId')}`,
        {
          headers: { 'x-authorization': localStorage.getItem('token') }
        }
      );

      let authorUserName;
      //when user save event and display author profile
      if (this.props.title !== 'Book Signing') {
        if (
          localStorage.getItem('membershipId') !== '4' &&
          this.props.title !== 'Book Release'
        ) {
          // console.log("this.props.eventPopupid--> ", this.props.eventPopupid)
          if (this.props.eventPopupid)
            authorUserName = await axios.get(
              BASEURL +
                `/api/v1/users/getSavedEvent/${this.props.eventPopupid}`,
              {
                headers: { 'x-authorization': localStorage.getItem('token') }
              }
            );
          // console.log("here is the data in event modal33------------->> ", authorUserName.data.result)
          if (
            authorUserName &&
            authorUserName.data &&
            Object.keys(authorUserName.data.result).length > 0
          ) {
            if (this.props.changeLinkedAuthorId) {
              this.props.changeLinkedAuthorId(authorUserName.data.result[0].id);
            }
            const { data } = authorUserName;

            this.setState({
              authorLink: authorUserName.data.result[0].follow_code,
              nameLoader: false
            });
          }
        }
      }
      let findVendor;
      let findAuthor;
      let fullHostName;
      let findBlogPremium;

      if (
        localStorage.getItem('membershipId') === '4' &&
        getDispalyNameFromSaveEvent &&
        getDispalyNameFromSaveEvent.data.result.length > 0
      ) {
        const extractname = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].profile_details_with_values
        );
        findVendor = extractname.find(el => el.Vendor);
        fullHostName = Object.values(findVendor)[0];
        console.log(getDispalyNameFromSaveEvent.data.result[0].user_details, 'user_details')
        const allNames = JSON.parse(getDispalyNameFromSaveEvent.data.result[0].user_details).filter(
          el =>
            el.name === 'firstname' ||
            el.name === 'lastname' ||
            el.name === 'middlename' || 
            el.name === 'comapany_name'
        );
        const fullName = allNames.length > 0 ? (`${allNames[0].value} ${allNames[2] ? allNames[2].value : ''} ${allNames[1] ? allNames[1].value : ''}`) : '';
        // console.log("1>------------------- ", findVendor)
        this.setState({
          hostNameAdd: fullName
        });
      } else if (
        localStorage.getItem('membershipId') === '5' &&
        getDispalyNameFromSaveEvent &&
        getDispalyNameFromSaveEvent.data.result.length > 0
      ) {
        const extractname = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].profile_details_with_values
        );
        findVendor = extractname.find(el => el.Vendor);
        fullHostName = Object.values(findVendor)[0];
        const allNames = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].user_details
        ).filter(
          el =>
            el.name === 'firstname' ||
            el.name === 'lastname' ||
            el.name === 'middlename'
        );
        const fullName = `${(allNames[0] ? allNames[0].value : '')} ${(allNames[2] ? allNames[2].value : '')} ${(allNames[1] ? allNames[1].value : '')}`;
        // console.log("2>------------------- ", findVendor)
        this.setState({
          hostNameAdd: fullName,
          followCode: getDispalyNameFromSaveEvent.data.result[0].follow_code
        });
      } else if (
        localStorage.getItem('membershipId') === '8' &&
        getDispalyNameFromSaveEvent &&
        getDispalyNameFromSaveEvent.data.result.length > 0
      ) {
        const extractname = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].profile_details_with_values
        );

        findAuthor = extractname.find(el => el.Author);
        fullHostName = Object.values(findAuthor)[0];
        const allNames = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].user_details
        ).filter(
          el =>
            el.name === 'firstname' ||
            el.name === 'lastname' ||
            el.name === 'middlename'
        );
        const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
        this.setState({
          hostNameAdd: fullName,
          followCode: getDispalyNameFromSaveEvent.data.result[0].follow_code
        });
      } else if (
        localStorage.getItem('membershipId') === '1' &&
        getDispalyNameFromSaveEvent &&
        getDispalyNameFromSaveEvent.data.result.length > 0
      ) {
        const extractname = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].profile_details_with_values
        );
        findAuthor = extractname.find(el => el.Author);
        fullHostName = Object.values(findAuthor)[0];
        // console.log("3>------------------- ", findAuthor)
        const allNames = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].user_details
        ).filter(
          el =>
            el.name === 'firstname' ||
            el.name === 'lastname' ||
            el.name === 'middlename'
        );
        const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
        this.setState({
          hostNameAdd: fullName,
          followCode: getDispalyNameFromSaveEvent.data.result[0].follow_code
        });
      } else if (
        localStorage.getItem('membershipId') === '6' &&
        getDispalyNameFromSaveEvent &&
        getDispalyNameFromSaveEvent.data.result.length > 0
      ) {
        const extractname = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].profile_details_with_values
        );
        findBlogPremium = extractname.find(el => el.Blog);
        fullHostName = Object.values(findBlogPremium)[0];
        const allNames = JSON.parse(
          getDispalyNameFromSaveEvent.data.result[0].user_details
        ).filter(
          el =>
            el.name === 'firstname' ||
            el.name === 'lastname' ||
            el.name === 'middlename'
        );
        const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
        // console.log("4>------------------- ", findBlogPremium)
        this.setState({
          hostNameAdd: fullName,
          followCode: getDispalyNameFromSaveEvent.data.result[0].follow_code
        });
      }
    } else {
      const createdDate = moment().format('YYYY-MM-DD');
      let checkPreviouDate =
        new Date(createdDate).getTime() <=
        new Date(this.props.eventData.start_date).getTime();
      let checkPreviouDate1 =
        new Date(createdDate).getTime() <=
        new Date(this.props.eventData.end_date).getTime();

      this.setState({
        previousDateStore: checkPreviouDate,
        previousDatEnd: checkPreviouDate1
      });

      if (
        this.props.eventData.event_type === 'event_type_1' ||
        this.props.eventData.event_type.toLowerCase() === 'book release'
      ) {
        // this.props.getAssociatedEvents(
        //   `/api/v1/events/associatedEvents/${this.props.eventData.id}`,
        //   { headers: { 'x-authorization': localStorage.getItem('token') } }
        // );
      }

      localStorage.setItem('eventId', this.props.eventData);
      // localStorage.setItem('parenId');
      if (this.props.eventData.event_type === 'Book Release') {
        let author = this.props.eventData.author_first_name
          ? this.props.eventData.author_first_name
          : '';
        let mid = this.props.eventData.author_mid_name
          ? this.props.eventData.author_mid_name
          : '';
        let last = this.props.eventData.author_last_name
          ? this.props.eventData.author_last_name
          : '';
        let fullname = author + ' ' + mid + ' ' + last;

        let authora = this.props.eventData.event_details_with_values.find(
          el => el['Author']
        );
        authora['Author'] = fullname;
        // console.log('authora', this.props.eventData);
        this.setState({
          eventKeyData: this.props.eventData.event_details_with_values,
          title: this.props.eventData.event_type
            ? this.props.eventData.event_type
            : this.props.title,
          authorEvent: this.props.eventData.id
        });
        //this api call when pop open from event filter,event today and other grid only for book release
        // console.log("this is this.props.eventData.id--->> ", this.props.eventData.id)
        const canConCat = await axios.get(
          BASEURL +
            `/api/v1/users/getAddedbyInformationAuthor/${this.props.eventData.id}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );


        let fullHostName;
        // this.setState({ nameLoader: true });
        // const authorUserName = await axios.get(
        //   BASEURL +
        //     `/api/v1/users/getInformationFromEventPopupBookRelase/${this.props.eventData.user_id}`,
        //   {
        //     headers: { 'x-authorization': localStorage.getItem('token') }
        //   }
        // );
        // // console.log("here is the data in event modal-22------------>> ", authorUserName.data.result[0])
        // if (authorUserName.data) {
        //   this.setState({ nameLoader: false });
        // }
        // if (
        //   authorUserName.data.result &&
        //   Object.keys(authorUserName.data.result).length > 0
        // ) {
        //   if (this.props.changeLinkedAuthorId) {
        //     this.props.changeLinkedAuthorId(authorUserName.data.result.id);
        //   }
        //   const { data } = authorUserName;
        //   const middleName = JSON.parse(data.result.user_details).find(
        //     el => el.name === 'middlename'
        //   );
        //   const FullName = `${data.result.fname} ${middleName.value} ${data.result.lname}`;
        //   this.setState({
        //     authorLink: data.result.follow_code,
        //     AuthorFullName: FullName
        //   });
        // }

        // if (canConCat.data && canConCat.data.result.length > 0) {
        //   const fetchFullName = canConCat.data.result[0];
        //   fullHostName = fetchFullName.display_name;
        // }
        // let work = JSON.parse(canConCat.data.result[0].profile_details_with_values);

        let findAuthor;
        if (
          this.props.eventData.membership_id === '8' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const auhtorPremiumName = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findAuthor = auhtorPremiumName.find(el => el.Author);
          fullHostName = Object.values(findAuthor)[0];
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          console.log('fullHostName1 ', fullHostName);
          // console.log("5>------------------- ", findAuthor)
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code,
            AuthorFullName: fullName
          });
        }
        if (
          this.props.eventData.membership_id === '1' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const authorNameFind = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findAuthor = authorNameFind.find(el => el.Author);
          fullHostName = Object.values(findAuthor)[0];
          // console.log('fullHostName2 ', fullHostName);
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          console.log('6>------------------- ', fullName);
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code,
            AuthorFullName: fullName
          });
        }
      } else {
        this.setState({
          releasePartyPopupOpen: this.props.eventData.time,
          timee: this.props.eventData.isttime
        });

        console.log(
          'this.props.eventData.event_details_with_values',
          this.props.eventData.event_details_with_values
        );

        this.setState({
          eventKeyData: this.props.eventData.event_details_with_values,
          title: this.props.eventData.event_type
            ? this.props.eventData.event_type
            : this.props.title
        });
        // this api call for event filter and other grid
        //extract value for added by
        // console.log("here is the event id--> ", this.props.eventData.id)
        this.setState({ nameLoader: true });
        const canConCat = await axios.get(
          BASEURL +
            `/api/v1/users/getAddedbyInformationAuthor/${this.props.eventData.id}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );

        console.log('canConCat3043', canConCat);

        const authorUserName = await axios.get(
          BASEURL +
            `/api/v1/users/getAddedByForOtherEvent/${this.props.eventData.id}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        );
        // console.log("here is the data in event modal33------------->> ", authorUserName.data.result)
        if (authorUserName.data) {
          // this.setState({ nameLoader: false });
        }
        if (
          authorUserName.data.result &&
          Object.keys(authorUserName.data.result).length > 0
        ) {
          // console.log(
          //   'heeeeeeeeeeeeeeeeeeeeeeeeefwsddddddddddddddddd====> ',
          //   this.props.changeLinkedAuthorId
          // );
          if (this.props.changeLinkedAuthorId) {
            this.props.changeLinkedAuthorId(authorUserName.data.result.id);
          }
          this.setState({ nameLoader: true });
          const { data } = authorUserName;
          // console.log(
          //   '========================1111',
          //   JSON.parse(data.result.user_details)
          // );
          // if (!data.result.user_details) {
          //   return ;
          // }

          const middleName =
            data.result.user_details &&
            JSON.parse(data.result.user_details).find(
              el => el.name === 'middlename'
            );
          console.log('middleName', middleName);
          const FullName = `${data.result.fname} ${middleName &&
            middleName.value} ${data.result.lname}`;
          console.log('========================222 ', FullName);
          this.setState({
            authorLink: data.result.follow_code,
            AuthorFullName: FullName,
            nameLoader: false
          });
        }
        // this.setState({ nameLoader: false });

        // console.log("this.props.eventData------------------> ", this.props.eventData)

        // let work = JSON.parse(canConCat.data.result[0].profile_details_with_values);
        let findVendor;
        let findAuthor;
        let findBlogPremium;
        let findAddedByName;
        // console.log("this . props . eventData--> ", this.props.eventData)-----------------------------------------
        // console.log("upadted data================== > ",{membership_id: this.props.eventData.membership_id, concatData: canConCat.data } )--------------------------------------------------------------
        //  console.log("canConCat",this.props.eventData,work,localStorage.getItem('membershipId') )
        if (
          this.props.eventData.membership_id === '5' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const work = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findVendor = work.find(el => el.Vendor);
          findAddedByName = Object.values(findVendor)[0];
          // console.log('fullHostName3 ', findAddedByName);
          // console.log("7>------------------- ", findVendor)
          console.log(JSON.parse(canConCat.data.result[0].user_details), 'hhhhhhhhhhhhh')
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename' ||
              el.name === 'comapany_name'
          );
          const fullName = `${canConCat.data.result && (allNames[0] ? allNames[0].value : '')} ${allNames[2] ? allNames[2].value : '' } ${allNames[1] ? allNames[1].value : ''}`;
          console.log(allNames, 'allNames')
          console.log(fullName, 'fullName')
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code
          });
        } else if (
          this.props.eventData.membership_id === '8' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const work = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findAuthor = work.find(el => el.Author);
          findAddedByName = Object.values(findAuthor)[0];
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          // console.log('fullHostName4 ', findAddedByName);
          // console.log("8>------------------- ", findAuthor)
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code
          });
        } else if (
          this.props.eventData.membership_id === '6' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const work = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findBlogPremium = work.find(el => el.Blog);
          findAddedByName = Object.values(findBlogPremium)[0];
          // console.log('fullHostName12 ', findAddedByName);
          // console.log("9>------------------- ", findBlogPremium)
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code
          });
        } else if (
          this.props.eventData.membership_id === '4' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          const work = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findVendor = work.find(el => el.Vendor);
          findAddedByName = Object.values(findVendor)[0];
          // console.log("10>------------------- ", findVendor)
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName =
            allNames.length > 0 &&
            `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          this.setState({ hostNameAdd: fullName });
        } else if (
          this.props.eventData.membership_id === '1' &&
          canConCat.data &&
          canConCat.data.result.length > 0
        ) {
          // console.log(" canConCat.data.result--> ",  canConCat.data.result)
          const work = JSON.parse(
            canConCat.data.result[0].profile_details_with_values
          );
          findAuthor = work.find(el => el.Author);
          findAddedByName = Object.values(findAuthor)[0];
          // console.log('fullHostName13 ', findAddedByName);
          const allNames = JSON.parse(
            canConCat.data.result[0].user_details
          ).filter(
            el =>
              el.name === 'firstname' ||
              el.name === 'lastname' ||
              el.name === 'middlename'
          );
          const fullName = `${allNames[0].value} ${allNames[2].value} ${allNames[1].value}`;
          this.setState({
            hostNameAdd: fullName,
            followCode: canConCat.data.result[0].follow_code
          });
          // console.log("11>------------------- ", fullName)
        }
      }
      this.props.getAllEvents(
        this.props.getAllEvents(`/api/v1/events?event_type=my`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    ownedByMe: state.getEventMode,
    AssociatedEvents: state.getAssociatedEvents,
    filterDaode: state.getEventMode,
    getAllta: state.eventFilter,
    eventMEvents: state.getAllEvents,
    userList: state.userListData,
    newtab: state.addTab,
    eventMode: state.getEventMode
  };
};

const mapDispatchToProps = dispatch => {
  return {
    delete: (url, data, pageName, headers) =>
      dispatch(deleteEvent(url, data, pageName, headers)),
    editEvent: (url, headers, newTab) =>
      dispatch(getEventDetails(url, headers, newTab)),
    clearPreviewData: () => dispatch(previewData({})),
    getSummary: () =>
      dispatch(
        getAllEventsSummary(`/api/v1/events/day/count?isGeneral=${true}`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    getAllEvents: () =>
      dispatch(
        getAllEvents('/api/v1/events', {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    addToMyCalendar: (url, header, data, pageName, filterData) =>
      dispatch(addEventToMyCalendar(url, header, data, pageName, filterData)),
    removeToMyCalendar: (url, header, data, pageName, filterData) =>
      dispatch(removeCalendar(url, header, data, pageName, filterData)),
    getFilterEvent: (url, data, header) =>
      dispatch(getFilterEventDataList(url, data, header)),
    getEvents: eventMode =>
      dispatch(
        getAllEvents(`/api/v1/events?isGeneral=${eventMode}`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    getAllEvents: (url, header) => dispatch(getFilterEvents(url, header)),
    getAssociatedEvents: (url, headers) =>
      dispatch(associatedEvents(url, headers)),

    getPopularEvents: (url, header) => dispatch(getPopularEvents(url, header)),
    getNotificationList: (url, header) =>
      dispatch(getNotificationlist(url, header))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventModal);
