import React, { Component } from "react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
//import "react-big-calendar/lib/css/react-big-calendar.css";
import { HashLoader } from "react-spinners";
import { saveSendEmailDetail } from "../../Actions/profile-setup";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { notify } from "react-notify-toast";
import moment from "moment";
import createHistory from "history/createHashHistory";

const globalHistory = new createHistory();

class sendEmail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email_subject: null,
			email_body: null,
			modalOpen: false,
			time: "",
			date: null,
			timeList: [],
		};

		// this.showTask = this.showTask.bind(this);
	}

	handleEditorChange = (e) => {
		this.setState({ task_details: e.target.getContent() });
	};

	hideViewModal = (e) => {
		this.setState({ viewModal: false });
	};

	handleChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;
		this.setState({ [name]: value });
	};

	handleSubmit = (event) => {
		event.preventDefault();

		let subject = this.state.email_subject;
		let body = this.state.email_body;
		const dateTime = moment(
			`${this.state.date} ${this.state.time}`,
			"YYYY-MM-DD HH:mm"
		).format();
		const calculateisBefore = moment().isAfter(dateTime, "time");

		if (!subject) {
			return notify.show("Please Enter Email Subject.", "custom", 2000, {
				background: "#d4edda",
				text: "#155724",
				borderColor: "#c3e6cb",
			});
		}

		if (!body) {
			return notify.show("Please Enter Email Body.", "custom", 2000, {
				background: "#d4edda",
				text: "#155724",
				borderColor: "#c3e6cb",
			});
		}

		if (!this.state.date) {
			return notify.show("Please Enter Date.", "custom", 2000, {
				background: "#d4edda",
				text: "#155724",
				borderColor: "#c3e6cb",
			});
		}

		if (!this.state.time) {
			return notify.show("Please Enter Time.", "custom", 2000, {
				background: "#d4edda",
				text: "#155724",
				borderColor: "#c3e6cb",
			});
		}


		if (calculateisBefore) {
			return notify.show(
				"Please select correct date and time.",
				"custom",
				2000,
				{
					background: "#d4edda",
					text: "#155724",
					borderColor: "#c3e6cb",
				}
			);
		}


		const data = {
			email_subject: subject,
			email_body: body,
			blast_date: moment.utc(dateTime).format('YYYY-MM-DD'),
			blast_time: moment.utc(dateTime).format('HH:mm'),
		};
		this.props.saveEmailDetails(`/api/v1/send_Email/Details`, data, {
			headers: { "x-authorization": localStorage.getItem("token") },
		});
		this.hideModal();
		this.resetForm();
		event.preventDefault();
	};

	resetForm() {
		this.setState({
			email_subject: null,
			email_body: null,
		});
	}

	showModal = () => {
		this.setState({ modalOpen: true });
	};

	hideModal = () => {
		this.setState({ modalOpen: false });
		globalHistory.push("/dashboard");
	};

	render() {
		return (
			<>
				<Modal isOpen={this.state.modalOpen} toggle={this.hideModal}>
					<ModalHeader toggle={this.hideModal}>
						<h5>Send Email: </h5>
					</ModalHeader>
					<ModalBody>
						<form onSubmit={this.handleSubmit}>
							<div class="row">
								<div class="form-group col-md-12 ">
									<label for="startDate">Email Subject</label>
									<input
										type="text"
										class="form-control"
										id="email_subject"
										name="email_subject"
										maxlength="300"
										onChange={this.handleChange}
										value={this.state.email_subject}
										required
									/>
								</div>

								<div class="form-group col-md-12 ">
									<label for="title">Email Body</label>
									<textarea
										type="text"
										class="form-control"
										id="email_body"
										name="email_body"
										onChange={this.handleChange}
										value={this.state.email_body}
										rows="5"
										required
									/>
								</div>
								<div className="form-group col-md-6">
									<label for="title">Date</label>
									<input
										type="date"
										id="blastData"
										class="form-control"
										name="blastdata"
										value={this.state.date}
										min={new Date()
											.toISOString()
											.slice(0, 10)}
										required
										onChange={(event) =>
											this.setState({
												date: event.target.value,
											})
										}
									/>
								</div>

								<div class="form-group col-md-6">
									<label for="sel1">Select Time:</label>
									<select
										class="form-control"
										value={this.state.time}
										style={{height:'39px'}}
										onChange={(event) => {
											this.setState({
												time: event.target.value,
											});
										}}
										required
									>
										<option value="" selected disabled>
											Select Time
										</option>
										{this.state.timeList.map((timeList) => (
											<option value={timeList}>
												{timeList}
											</option>
										))}
									</select>
								</div>

								<div class="form-group col-md-12">
									<button
										type="submit"
										className={
											"btn btn_save_bt" ||
											"btn btn_save_bt dark-button"
										}
									>
										{"Send"}
									</button>
								</div>
							</div>
						</form>
					</ModalBody>
				</Modal>
			</>
		);
	}

	componentDidMount() {
		if (this.props.modelOpen) {
			this.setTimeInterval();
			this.setState({ modalOpen: true });
		} else {
			globalHistory.push("/dashboard");
		}
	}

	componentWillReceiveProps(newProps) {}

	setTimeInterval = () => {
		let timeList = [];
		let currentTime = "00:60",
			time;
		let hour = 0,
			min = 60;
		timeList.push("00:00");

		while (currentTime !== "00:00") {
			min = 60;
			if (min >= 60) {
				hour += 1;
				min = min - 60;
			}
			if (hour >= 24) {
				time = "23:59";
				currentTime = "00:00";
			} else {
				currentTime = hour + ":" + `${min}0`;
				time = currentTime;
			}
			timeList.push(moment(time, "HH:mm").format("HH:mm"));
		}
		this.setState({ timeList: timeList });
	};
}

const mapStateToProps = (state) => {
	return {
		modelOpen: state.sendMailModel,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		saveEmailDetails: (url, data, headers) =>
			dispatch(saveSendEmailDetail(url, data, headers)),
		// getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
		//addFilterData: (data) => dispatch(addEventFilter(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(sendEmail);
