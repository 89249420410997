import React, { Component } from "react";
import $ from "jquery";

export default class OTP extends Component {
  constructor(props) {
    super(props);
    this.handleOTP = this.handleOTP.bind(this);
    this.handleResendOTP = this.handleResendOTP.bind(this);
  }

  handleOTP() {
    document.getElementById("code").value !== ""
      ? this.props.otp(document.getElementById("code").value)
      : alert("please add valid OTP");
  }

  handleResendOTP() {
    console.log("hi i am calling man");
    this.props.resend();
  }

  render() {
    return (
      <>
        <div>
          <div className="signup_text">
            <span>
              Please check your email to activate your account.
              <br />
              Enter 6 digit Verification code here:
            </span>
          </div>
          <div className="code_main">
            <div className="form-group form-group_code">
              <input
                type="text"
                className="form-control code_form"
                id="code"
                placeholder="Enter 6 digit code"
              />
            </div>
          </div>
          <div className="btn_group_submit mt-0">
            <button
              className="btn btn-lg  btn-block  btn_submit"
              id="Next"
              onClick={this.handleOTP}
              type="button"
            >
              Submit
            </button>
          </div>
          <div className="bottom_btn mt-0">
            <div className="footer_contant">
              {" "}
              <span style={{ color: "#000", fontWeight: "bold" }}>
                Did not get email?
              </span>
              <button
                className="btn btn_resend"
                type="button"
                style={{ color: "#000" }}
                onClick={this.handleResendOTP}
              >
                Re-send
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  componentDidMount() {
    $("#page1").addClass("active");
    $("#page2").addClass("active");
  }
}
