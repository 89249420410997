import React, { useState, useEffect, Component } from 'react';
import { connect } from 'react-redux';
import { addNewMessage, getConversationList } from '../../../Actions/messages';
import './Compose.css';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import moment from 'moment';

class Compose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      showMessageModal: false,
      chosenEmoji: false,
      showMessage: ''
    };
  }

  handleEmojiPicker = e => {
    this.setState({ chosenEmoji: !this.state.chosenEmoji });
    console.log('state', this.state.chosenEmoji);
  };

  addEmoji = e => {
    let oldmesg = this.state.message;

    this.setState({
      chosenEmoji: false,
      message: oldmesg + e.native
    });
  };

  handleChange(event) {
    const { target } = event;
    const { name } = target;
    let checked = target.value;

    console.log('name', name);

    this.setState({
      [name]: checked
    });
  }

  enterPressed(event) {
    console.log('event.which', event.target.value.length);
    var code = event.keyCode || event.which;
    if (code === 13) {
      // 13 is the enter keycode
      console.log('this.state.message', this.state.message);
      if (this.state.message.trim().length > 0) {
        //  console.log('ggggggggggggggggggggggggg');
        const data = {
          message: this.state.message,
          created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        };
        this.props.addNewMessage(
          `/api/v1/addConversation/${localStorage.getItem('msgId')}`,
          data,
          { headers: { 'x-authorization': localStorage.getItem('token') } },
          localStorage.getItem('msgId')
        );
        this.setState({ message: '' });
      }

      // this.props.getConversationList('/api/v1/message_list', {
      //   headers: { 'x-authorization': localStorage.getItem('token') }
      // });
    }
    // this.props.getConversationList('/api/v1/message_list', {
    //   headers: { 'x-authorization': localStorage.getItem('token') }
    // });
  }

  refreshConversationList() {
    this.props.getConversationList('/api/v1/message_list', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
  }

  addMessage = event => {
    console.log('this.state.message', this.state.message);
    if (this.state.message != '') {
      const data = {
        message: this.state.message,
        created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      };

      console.log('datadatadatadatadatadatadatadatadatadata', data);
      this.props.addNewMessage(
        `/api/v1/addConversation/${localStorage.getItem('msgId')}`,
        data,
        { headers: { 'x-authorization': localStorage.getItem('token') } },
        localStorage.getItem('msgId')
      );
      this.setState({ message: '' });

      this.refreshConversationList();
    }
  };

  render() {
    return (
      // <div className="compose">
      <div
        className={
          this.props.displayMode
            ? 'compose compose-light'
            : 'compose compose-dark'
        }
      >
                   <input
                    type='text'
                     id="message"
                     name="message"
                     className="compose-input"
                      onChange={this.handleChange.bind(this)}
                      value={this.state.message}
                      onKeyPress={this.enterPressed.bind(this)}
                      placeholder="Type a message here"
                    />        
          {/* <textarea
          style={{ width: '100%',overflowY: 'scroll'}}
          rows="4"
          cols="100"
          maxLength="1000"
          id="message"
          name="message"
          className="compose-input"
          onChange={this.handleChange.bind(this)}
          value={this.state.message}
          onKeyPress={this.enterPressed.bind(this)}
          placeholder="Type a message here"
        /> */}
        <div
          className="emojipicker"
          onBlur={this.handleEmojiPicker}
          onClick={this.handleEmojiPicker}
        >
          😀
        </div>
        {this.state.chosenEmoji && <Picker onSelect={this.addEmoji} />}
        <ToolbarButton
          key="photo"
          icon="fa fa-arrow-right"
          onChangeClickHandler={e => this.addMessage(e)}
        />

        {this.props.rightItems}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  conversationList: state.conversationList
  // messages:state.getMessageList
});

const mapDispatchToProps = dispatch => ({
  addNewMessage: (url, data, header, msgId) =>
    dispatch(addNewMessage(url, data, header, msgId)),
  getConversationList: (url, header) =>
    dispatch(getConversationList(url, header))
});

export default connect(mapStateToProps, mapDispatchToProps)(Compose);
