import React, { Component } from 'react';
// import { Link } from "react-router-dom";
import { connect } from 'react-redux';


// import "../Landing/landing.css";
import { notify } from 'react-notify-toast';

import 'react-accessible-accordion/dist/fancy-example.css';


class MyVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  notifyHandle = () => {
    notify.show('Email sent successfully.', 'custom', 3000, {
      background: '#d4edda',
      text: '#155724',
      borderColor: '#c3e6cb'
    });
  };

  render() {
    // const profileData = this.props.profileData.profile;

    return (
      <div className="help-section ">
        <div className="container">
          <div className="mb-4">
            <h2 className="text-center heading">How to Videos</h2>
            <p className="text-center mb-0"></p>
          </div>
          <div className="col-md-12 mb-5">
            <div id="video-section" className="video-section mb-5">
              <fieldset class="scheduler-border border-light">
                <legend class="scheduler-border">
                  <b>HOW-TO-VIDEOS</b>
                </legend>
                <div className="row mt-3">
                  <div className="col-md-4">
                    <p>Using OTR calendar</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>What are benefits to following</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <p>How do a change my reminders</p>
                    <div class="video">
                      <iframe
                        width="100%"
                        src="https://www.youtube.com/embed/yodw5jIOBt4"
                        frameborder="0"
                        title="video 1"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                      ></iframe>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() { }
}

//{======= VideoFrames Component ========}

const mapStateToProps = state => { };

const mapDispatchToProps = dispatch => { };

export default connect(mapStateToProps, mapDispatchToProps)(MyVideos);
