import React, { useState, useEffect, Component } from 'react';
import moment from 'moment';
import './Message.css';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // messages: [],
      // showMessageModal:false
    };
  }

  render() {
    const {
      data,
      isMine,
      startsSequence,
      endsSequence,
      showTimestamp
    } = this.props;
    //console.log('jjjjjjjjjjjjjjjjj', data);
    const componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
    let da = data.created_at.toLocaleString();

    const friendlyTimestamp = moment(da).format('LLLL');

    return localStorage.getItem('msgId') ? (
      <Linkify componentDecorator={componentDecorator}>
        <div
          className={[
            'message',
            `${isMine ? 'mine' : ''}`,
            `${startsSequence ? 'start' : ''}`,
            `${endsSequence ? 'end' : ''}`
          ].join(' ')}
        >
          {/* {showTimestamp && (
            <div className="timestamp">{friendlyTimestamp}</div>
          )} */}

          <div className="bubble-container">
            <div className="bubble">
              {data.message}
             
              {/* {msg} */}

            </div>
            

          </div>
          <span className="message-time">
          {/* {moment(data.created_at).format('YYYY-MM-DD hh:mm:ss A')} */}
          {moment(data.created_at).format('llll')}
          </span>
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={el => {
              this.messagesEnd = el;
            }}
          />
        </div>
      </Linkify>
    ) : (
      ''
    );
  }
}

const mapStateToProps = state => ({
  // messages:state.getMessageList
});

const mapDispatchToProps = dispatch => ({
  // getConversationList: (url,header) => dispatch(getConversationList(url,header)),
  // getMessages:(url,header) => dispatch(getUserMessageList(url,header))
});

export default connect(mapStateToProps, mapDispatchToProps)(Message);
// export default function Message(props) {
//     const {
//       data,
//       isMine,
//       startsSequence,
//       endsSequence,
//       showTimestamp
//     } = props;

//     const friendlyTimestamp = moment(data.timestamp).format('LLLL');
//     return (
//       <div className={[
//         'message',
//         `${isMine ? 'mine' : ''}`,
//         `${startsSequence ? 'start' : ''}`,
//         `${endsSequence ? 'end' : ''}`
//       ].join(' ')}>
//         {
//           showTimestamp &&
//             <div className="timestamp">
//               { friendlyTimestamp }
//             </div>
//         }

//         <div className="bubble-container">
//           <div className="bubble" title={friendlyTimestamp}>
//             { data.message }
//           </div>
//         </div>
//         <div style={{ float:"left", clear: "both" }}
//              ref={(el) => { this.messagesEnd = el; }}>
//         </div>

//       </div>
//     );
// }
