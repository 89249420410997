import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import axios from 'axios';
import { getstatic } from '../../Actions/index';
import { BASEURL } from '../../constant';
import { isAndroid, isIOS } from 'react-device-detect';

class ConditionsModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      consent_type: 'Terms & Conditions',
      content: '',
      version: '',
      id: '',
      source: 'Website',
      ipAddress: '',
      reason: this.props.reason ? this.props.reason : 'Join',
      userId: localStorage.id ? localStorage.id : ''
    };
    this.getTermsConditions = this.getTermsConditions.bind(this);
    this.getIpAddress = this.getIpAddress.bind(this);
  }

  handleSubmit = async e => {
    e.preventDefault();

    console.log({ token: localStorage.token });
    // this.setState({ ischeck: !this.state.ischeck });
    try {
      await axios.post(BASEURL + '/api/v1/users/postUserConsent', this.state, {
        headers: {
          'x-authorization': localStorage.getItem('token')
        }
      });
    } catch (e) {
      console.log('PPP', e.response);
    }
  };

  async getTermsConditions() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getTermsConditions'
      );

      if (response.data.length)
        this.setState({
          content: response.data[0].consent_body,
          version: response.data[0].version,
          id: response.data[0].id
        });
    } catch (e) {
      console.log('error', e);
    }
  }

  async getIpAddress() {
    try {
      // const response = await axios.get("https://geolocation-db.com/json/");
      const response = await axios.get('https://api.ipify.org/?format=json');
      // console.log("responsedddddddddddddddddddddddddddddd", response);
      this.setState({ ipAddress: response.data.ip });
      //this.setState({ ipAddress: response.data.IPv4 });
    } catch (e) {
      console.log(e);
    }
  }

  //===================== RENDER ==========================

  render() {
    console.log('lol', this.state);
    console.log('hu', this.props.check);

    // console.log('kjkjkj', this.props.cross);
    return (
      <>
        <Modal
          isOpen={this.state.disabled ? false : this.props.isOpen}
          // toggle={this.props.toggle}
          id="myModal"
          contentClassName="conditions-modal"
        >
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h3 className="text-center p-0"> Terms & Conditions </h3>
          </ModalHeader>
          <ModalBody>
            <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span>
            <button
              className="btn btn-filled align-self-end ml-auto"
              id="Next"
              type="button"
              onClick={e => {
                this.props.toggle();
                this.props.check();
                this.handleSubmit(e);
              }}
              disabled={this.state.disabled}
            >
              I agree
            </button>
          </ModalBody>
        </Modal>
      </>
    );
  }
  componentDidMount() {
    this.getTermsConditions();
    this.getIpAddress();

    if (isIOS) return this.setState({ source: 'IOS' });
    if (isAndroid) return this.setState({ source: 'Android' });
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConditionsModel);
