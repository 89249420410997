import React, { Component } from 'react';
import { notify } from 'react-notify-toast';
import { connect } from 'react-redux';
import CONSTANT, { BASEURL } from '../../constant';
import { Route, Link, Redirect } from 'react-router-dom';
import {
  setupEvent,
  getAllEventsSummary,
  Tab,
  filterevent,
  getAllEvents,
  changeEventMode,
  getFilterEvents,
  getPopularEvents
} from '../../Actions/event-setup';
import {
  saveDetail,
  getAllTasks,
  addEventFilter
} from '../../Actions/dailytask';
import { getProfileDetails } from '../../Actions/profile-setup';
import { changeDisplayMode, popularEvents } from '../../Actions/profile-setup';
import { Search } from '../../Actions/filters';
import Logo from '../../Components/left-logo';
import EventsCalendarView from '../dashboard/eventsCalendarView';
//import EventsListView from '../dashboard/eventsListView';
import DailyListView from '../dailyTask/dailyListView';
import EventFilter from '../dashboard/event-filter/eventFilter';
import EventsListView from '../dashboard/event-filter/eventsListView';
import NotificaitonList from '../dashboard/event-filter/notificationList';
import NotificaitonListFollowing from '../dashboard/event-filter/followingEventList';
import Newsletter from '../dashboard/event-filter/newsletter';
import MyEvents from '../dashboard/event-filter/myevents';
import MyCurrentEvents from '../dashboard/event-filter/mycurrentevents';
import EventStart from '../dashboard/event-filter/eventStart';
import UsersListView from '../usersList/usersList';
import Userprofile from '../dashboard/userProfile';
import HomePage from '../dashboard/homePage';
import MyProfile from '../dashboard/myProfile';
import MembershipInfo from '../dashboard/membershipInfo';
import EditPrefrences from '../dashboard/editprefrence';
import CreateEventModal from '../dashboard/createEventModal';
import FilterPopupModal from '../dashboard/filterpopup';
import SortPopupModal from '../dashboard/sortpopup';
import Messages from '../messages/messages';
import DailyTask from '../dailyTask/dailyTask';
import Terms from '../Footer/list/terms';
import Membership from '../Footer/list/memebershipPlan';
import Advertise from '../Footer/list/advertise';
import About from '../Footer/list/aboutUs';
import Help from '../Footer/list/help';
import Faq from '../Footer/list/faq';
import MyVideos from '../Footer/list/my-videos';
import ShowAdvertiseList from '../dashboard/showAdvertise';
import ShowBlastList from '../dashboard/showBlast';

import DailTaskDetails from '../dailyTask/dailyTaskDetails';
import PrivacyPolicy from '../Footer/list/privacy';
import { withRouter } from 'react-router-dom';
import Popover, { ArrowContainer } from 'react-tiny-popover';
import ManageAccount from '../dashboard/manageAccount';
import LinkedEvents from '../dashboard/linkedEvents/linkedEvents';
import LinkedBookrelease from '../dashboard/linkedEvents/linkedBookrelease';
import './../usersList/usersList.css';
import BlastNotificationsList from '../dashboard/blastNotification';
import TrandingEvents from '../dashboard/event-filter/trandingevents';
import TodayEvent from '../dashboard/event-filter/todayEvent';

import {
  getNotificationlist,
  countUnreadMessages
} from '../../Actions/messages';
import VendorListView from '../usersList/vendorList';
import Blast from '../dashboard/blast';
import createHistory from 'history/createHashHistory';
import UpgradePlan from '../../Pages/SignUp/upgradePlan';
import $ from 'jquery';
import moment from 'moment';
import AboutUs from '../../Pages/Static/aboutUs';
import axios from 'axios';
import Layout from '../layout/layout';
import Cookies from 'universal-cookie';
import Loader from '../../Components/Loader/loader';
import Axios from 'axios';
import MembershipReaderdfree from '../Footer/list/membershipReaderdfree';
import MembershipRegularPrice from '../Footer/list/membershipRegular';
const globalHistory = new createHistory();

const data = JSON.parse(localStorage.getItem('menuList'));
const myItem = localStorage.getItem('cookiepolicy');
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formJSON: [],
      mostPopular: [],
      event: null,
      showModal: false,
      showFilterModal: false,
      showSortModal: false,
      myevents: true,
      isCalenderView: true,
      showMenu: false,
      showNotification: false,
      activeTab: '',
      search: '',
      showNotificaiton: false,
      notificationData: {},
      popularEvents: false,
      blastNotifications: [],
      cookiesPolicy: false,
      cookiesContent: '',
      cookiesId: null,
      cookiesVersion: null,
      isDailyTaskView: true,
      isMyAllEventsList: true,
      CookieStoredDate: '',
      cookieDateDiff: '',
      allevents: true,
      allcheck: false,
      checkWithPreviousDate: '',
      checkWithPreviousDateDailyTask: '',
      isFollowingEventList: ''
      // cookieData: '',
    };

    this.props.setupEvent();
    this.onChangeHandler = this.onChangeHandler.bind(this);
    this.onCheckboxChangeHandler = this.onCheckboxChangeHandler.bind(this);
    this.onCustomChangeHandler = this.onCustomChangeHandler.bind(this);
    this.onChangeFileHandler = this.onChangeFileHandler.bind(this);
    this.onSubmitFormHandler = this.onSubmitFormHandler.bind(this);
    this.getCustomElementObject = this.getCustomElementObject.bind(this);
    this.addMoreLinkHandler = this.addMoreLinkHandler.bind(this);
    this.addMoreServiceHandler = this.addMoreServiceHandler.bind(this);
    this.changeEventsView = this.changeEventsView.bind(this);
    this.hideDropDown = this.hideDropDown.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
    this.getBlastNotifications = this.getBlastNotifications.bind(this);
    this.getDifferenceInDays = this.getDifferenceInDays.bind(this);
  }

  //======================= On Change Handlers ================

  getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  }

  showDropDown() {
    this.setState({
      showMenu: !this.state.showMenu
    });
    this.props.changeTab('');
  }

  showNotification() {
    this.setState({
      showNotification: !this.state.showNotification
    });
  }

  hideCreateModal = () => {
    this.setState({
      showModal: false
      // profileData: {}
    });
  };

  hideFilterModal() {
    this.setState({
      showFilterModal: false
      // profileData: {}
    });
  }

  hideSortModal() {
    this.setState({
      showSortModal: false
      // profileData: {}
    });
  }

  hideDropDown() {
    this.setState({
      showMenu: false
    });
  }

  async getBlastNotifications() {
    const response = await axios.get(
      BASEURL + '/api/v1/getBlastNotifications',
      {
        headers: { 'x-authorization': localStorage.getItem('token') }
      }
    );

    this.setState({ blastNotifications: response.data });
  }

  // async getCookiePlolicy() {
  //   const response = await axios.get(
  //     BASEURL + '/api/v1/users/getCookiePolicy',
  //     {
  //       headers: { 'x-authorization': localStorage.getItem('token') }
  //     }
  //   );
  //   if (response.data) {
  //     let content = response.data[0].consent_body;
  //     content = content.replace('<p>', '');
  //     content = content.replace('</p>', '');
  //     this.setState({
  //       cookiesId: response.data[0].id,
  //       cookiesVersion: response.data[0].version
  //     });
  //     this.setState({ cookiesContent: content });
  //   }
  // }

  async getCookiePolicyConsent() {
    const response = await axios.get(
      BASEURL + '/api/v1/users/getCookiePolicyConsent',
      {
        params: {
          userId: localStorage.getItem('userId')
        }
      }
    );

    if (response.data.length > 0) {
      // console.log(response.data.length);
      this.setState({
        CookieStoredDate:
          response.data[response.data.length - 1].cookie_save_date
      });

      const date2 = new Date();
      const date1 = new Date(this.state.CookieStoredDate);

      const cookies = new Cookies();

      if (this.getDifferenceInDays(date1, date2) < 365)
        this.setState({ cookiesPolicy: false });
      else this.setState({ cookiesPolicy: true });
    } else this.setState({ cookiesPolicy: true });
    // else {
    //   this.setState({ CookieStoredDate: '' });
    //   this.setState({ cookieDateDiff: '' });
    // }

    // console.log('fddgdfgf', this.getDifferenceInDays(date1, date2));
  }

  handleMyEvents(event) {
    let startDate = moment()
      .startOf('month')
      .format('YYYY-MM-DD');
    let endDate = this.getNextSunday(moment().endOf('month'));
    this.setState({
      myevents: event.target.checked
    });
    this.props.eventDisplayMode(event.target.checked);
    this.props.getAllEvents(event.target.checked, startDate, endDate);
    this.props.getEvents(event.target.checked);
    // this.props.getPopularEvents(`api/v1/events/getPopularEvents`, {
    //   headers: { 'x-authorization': localStorage.getItem('token') }
    // });
    // this.props.getFilterEvent(
    //   `/api/v1/event_filter?isGeneral=${this.props.eventMode}`,
    //   data,
    //   { headers: { 'x-authorization': localStorage.getItem('token') } }
    // );
  }

  handleAllEvents(event) {
    this.setState({
      allevents: event.target.checked
    });

    this.props.eventDisplayMode(event.target.checked);
    this.props.getMyEvents(event.target.checked);
    this.props.getMyEvents(event.target.checked);
  }
  onChangeHandler(value, index) {
    let data = this.state.formJSON;
    data[index].value = value;
    this.setState({ formJSON: data });
  }
  onCheckboxChangeHandler(value, parentIndex) {
    let data = this.state.formJSON;
    if (data[parentIndex].value.includes(value)) {
      data[parentIndex].value = data[parentIndex].value.replace(value, '');
    } else {
      data[parentIndex].value += data[parentIndex].value.concat(value);
    }

    // if(data[parentIndex].value.contains(value))
    // {
    //   data[parentIndex].value = data[parentIndex].value.replace(data[parentIndex].value).trim(",");
    // }
    // else{
    //   data[parentIndex].value += "," + value;
    // }
    this.setState({ formJSON: data });
  }

  handlePopularEvents = event => {
    // console.log('eventevent', event.target.checked);
    this.props.changePopularEvents(event.target.checked);
    this.setState({
      popularEvents: event.target.checked
    });
    if (event.target.checked) {
      this.props.history.push(`/dashboard/tranding-events`);
    } else {
      this.props.history.push(`/dashboard/event-filter`);
    }
  };

  onCustomChangeHandler(value, index, parentIndex) {
    let data = this.state.formJSON;
    data[parentIndex].children[index].value = value;
    this.setState({ formJSON: data });
  }

  onChangeFileHandler(file, acceptedSize) {
    if (parseInt(acceptedSize) * 1000 <= parseInt(file.size)) {
      notify.show(
        'File size is bigger than max size. Profile will be saved without Image.',
        'custom',
        5000,
        { background: '#f8d7da', text: '#721c24' }
      );
      return;
    }
    this.setState({ profile: file });
  }

  onSubmitFormHandler(e, history) {
    e.preventDefault();
    this.setState({
      showModal: true
    });
    // history.push("/setup-event");
  }

  addMoreLinkHandler(parentIndex, index) {
    let data = this.state.formJSON;
    data[parentIndex].children.push(data[parentIndex].children[index]);
    let el = {
      tag: 'input',
      type: 'text',
      className: 'form-control in_form_control',
      placeholder: 'link',
      value: '',
      validation: false,
      name: ''
    };
    data[parentIndex].children[index] = el;
    data[parentIndex].children[index].name =
      data[parentIndex].linkname + (parseInt(data[parentIndex].links) + 1);
    data[parentIndex].links = parseInt(data[parentIndex].links) + 1;

    this.setState({ formJSON: data });
  }

  addMoreServiceHandler(parentIndex, index) {
    let data = this.state.formJSON;
    //data[parentIndex].children.push(data[parentIndex].children[index]);
    let el = data[parentIndex].children[index];
    let el1 = { tag: 'date', value: '', name: '' };
    let el2 = {
      tag: 'service',
      name: '',
      type: 'text',
      value: '',
      className: 'form-control',
      validation: 'false',
      placeholder: 'Service'
    };

    data[parentIndex].children[index] = el1;
    data[parentIndex].children[index].name =
      'date_' + (parseInt(data[parentIndex].service) + 1);
    data[parentIndex].children.push(el2);
    data[parentIndex].children[index + 1].name =
      'service_' + (parseInt(data[parentIndex].service) + 1);
    data[parentIndex].service = parseInt(data[parentIndex].service) + 1;
    data[parentIndex].children.push(el);
    this.setState({ formJSON: data });
  }

  changeEventsView(value) {
    // console.log('ggggggggggggggg', value);
    this.setState({
      isCalenderView: value
    });
  }
  changeMyEventsView(value) {
    this.setState({
      isMyAllEventsList: value.target.checked
    });
  }
  changeFollowingEvent(value) {
    console.log('value===>>>', value.target.checked);
    this.setState({
      isFollowingEventList: value.target.checked
    });
  }

  // changeDailyTaskView(value) {
  //   this.setState({
  //     isDailyTaskView: value
  //   });
  // }

  handleSaveCookies = async () => {
    // this.setState({ cookiesPolicy: true });

    const cookies = new Cookies();
    cookies.set('cookiesPolicy', true, { path: '/' });

    const payload = {
      consent_type: 'Cookie Policy',
      version: this.state.cookiesVersion,
      id: this.state.cookiesId,
      source: 'website',
      ipAddress: this.state.ipAddress,
      reason: 'Cookie 1-year',
      cookie_save_date: moment(new Date()).format('YYYY-MM-DD'),
      ip: this.state.ipAddress,
      userId: localStorage.getItem('userId')
        ? localStorage.getItem('userId')
        : ''
    };

    try {
      const response = await axios.post(
        BASEURL + '/api/v1/users/postUserConsent',
        payload,
        {
          headers: {
            'x-authorization': localStorage.getItem('token')
          }
        }
      );

      if (response.status === 200) {
        this.setState({ cookiesPolicy: false });
      }
    } catch (e) {
      console.log(e);
    }
  };
  async getIpAddress() {
    const cookies = localStorage.getItem('cookiepolicy');
    if (cookies) {
      this.setState({ cookiesPolicy: true });
    }
    try {
      // const response = await axios.get("https://geolocation-db.com/json/");
      const response = await axios.get('https://api.ipify.org/?format=json');
      this.setState({ ipAddress: response.data.ip });
    } catch (e) {
      console.log(e);
    }
  }

  //======================= On Change Handlers ENDS ================

  //======================= Common functions ====================

  getJSONFormData() {
    const formData = new FormData();
    this.state.formJSON.map(el => {
      if (el.name) {
        if (el.tag === 'file') formData.append(el.name, this.state.profile);
        else if (el.tag === 'custom') {
          let data = this.getCustomElementObject(el.children);

          formData.append(el.name, data);
        } else formData.append(el.name, el.value);
      }
    });
    return formData;
  }

  getCustomElementObject(children) {
    let data = {};
    return JSON.stringify(data);
  }

  onChangeTab(tab) {
    this.props.changeTab(tab);
  }
  setSearchFilter(e) {
    if (this.props.search.trim() == '') {
      this.props.changeTab('Event filter');
      this.props.history.push(this.props.match.url + '/event-filter');
    }
    this.setState({ search: e.target.value });

    this.setState({
      popularEvents: false
    });

    //this.props.setSearchFilter(e.target.value)
  }

  setMyEventSearchFilter(e) {
    if (this.props.search.trim() == '') {
      this.props.changeTab('My Events');
      this.props.history.push(this.props.match.url + '/my-events');
    }
    this.setState({ search: e.target.value });
    //this.props.setSearchFilter(e.target.value)
  }
  setTrendingEventSearchFilter(e) {
    // if (this.props.search.trim() == '') {
    //   this.props.changeTab('Event filter');
    //   this.props.history.push(this.props.match.url + '/tranding-events');
    // }
    this.setState({ search: e.target.value });
    //this.props.setSearchFilter(e.target.value)
  }

  isAuthorized(route) {
    let isAuthorized = false;
    JSON.parse(localStorage.getItem('menuList')).forEach(element => {
      if (route === '/users/blogger') {
        isAuthorized = true;
      }
      if (+localStorage.getItem('membershipId') == 6) {
        isAuthorized = true;
      }
      if (element.weblink === route) {
        isAuthorized = true;
      } else if (element.subMenu) {
        element.subMenu.map(subMenu => {
          if (subMenu.weblink == route) {
            return (isAuthorized = true);
          }
        });
      }
    });
    return isAuthorized;
  }

  // getNoficationList(data) {
  //   console.log({ data });
  //   this.setState(
  //     {
  //       notificationData: data,
  //       showNotification: false
  //     }
  //     // this.props.history.push('/dashboard/notification')
  //   );
  // }

  getDailyTask() {
    this.props.history.push(
      `/dashboard/dailyTaskDetails?startDate=${moment().format('YYYY-MM-DD')}`
    );
  }

  childToProps = data => {
    this.setState({ checkWithPreviousDate: data });
  };

  childToPropsDailyTaks = data => {
    this.setState({ checkWithPreviousDateDailyTask: data });
  };

  updateProps = data => {
    this.setState({ displayEventMode: data });
  };

  getMostPopularevents = () => {
    this.props.changePopularEvents(true);
    this.setState({
      popularEvents: true
    });
    this.props.history.push(`/dashboard/tranding-events`);
  };

  getBlastNotificationsPage() {
    this.props.history.push('/dashboard/blast-notification');
  }

  getNextSunday = date => {
    // Create a Moment object with the given date
    const inputDate = moment(date);

    // Calculate the number of days to add to reach the next Sunday
    const daysToAdd = (7 - inputDate.day()) % 7;

    // Add the days to get the next Sunday
    const nextSunday = inputDate.add(daysToAdd, 'days');

    return nextSunday.format('YYYY-MM-DD'); // Format the date as desired
  };
  //======================= Common functions ENDS ====================

  //===================== RENDER ==========================
  render() {
    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    // check for todays task and then show daily notification
    let dailyTask = this.props.taskData;
    let dailyDate = dailyTask.map(d => moment(d.added_on).format('DD/MM/YYYY'));

    const today = moment().format('DD/MM/YYYY');

    let today_daily_task = dailyDate
      .filter(data => data.includes(today))
      .map(d => d);

    const data = JSON.parse(localStorage.getItem('menuList'));

    const pathName = this.props.location.pathname;
    const pathIsDashboard = pathName == '/dashboard' ? true : false;
    const isEventList = pathName == '/dashboard/event-list' ? true : false;
    const isTodayEventList =
      pathName == '/dashboard/today-event-list' ? true : false;
    const isEventFilter = pathName == '/dashboard/event-filter' ? true : false;
    const isEventStart = pathName == '/dashboard/event-start' ? true : false;
    const isMyEvents = pathName == '/dashboard/my-events' ? true : false;
    const isFollowingEvent =
      pathName == '/dashboard/followingevent' ? true : false;
    const isDailyTasks = pathName == '/dashboard/daily-task' ? true : false;
    const isDailyTaskList =
      pathName == '/dashboard/daily_task_list' ? true : false;
    const isTrendingEvents =
      pathName == '/dashboard/tranding-events' ? true : false;
    const isReader = +localStorage.getItem('membershipId') == 2 ? true : false;
    // const isDailyTasks = pathName == '/dashboard/daily_task' ? true : false;
    const isBlogger = +localStorage.getItem('membershipId') == 3 ? true : false;
    const isBloggerPrem =
      +localStorage.getItem('membershipId') == 6 ? true : false;
    const isPRFirm =
      +localStorage.getItem('membershipId') == 5 ||
      +localStorage.getItem('membershipId') == 6
        ? true
        : false;
    const isAuthor =
      +localStorage.getItem('membershipId') == 1 ||
      +localStorage.getItem('membershipId') == 8
        ? true
        : false;
    let notificationTotal =
      this.props.notificationList &&
      Object.keys(this.props.notificationList).length !== 0
        ? this.props.notificationList.currentUserData.length +
            this.props.notificationList.followingData &&
          this.props.notificationList.followingData.length +
            this.props.notificationList.suggestedEvents.length +
            this.props.notificationList.favouriteEvents.length +
            this.state.blastNotifications.length
        : 0;
    let totalNotification =
      notificationTotal +
      today_daily_task.length +
      this.state.blastNotifications.length;

    let active = window.location.href;
    const splitData = active.split('/');
    const splt = splitData[splitData.length - 1];

    const breakUrl = splitData[5];
    const breakUrl1 = breakUrl && breakUrl.split('?')[1];
    let eventListBreakUrl = breakUrl1 && breakUrl1.split('=')[0];
    const breakUrl12 = breakUrl && breakUrl.split('?')[0];
    const breakEventfilter = splitData[6];
    const calendarFilter = splitData[5];
    let eventFiler = breakEventfilter && breakEventfilter.split('=')[0];

    // let checkevent = eventFiler === 'startDate' ? breakUrl : calendarFilter;

    let checkevent = splt === 'event-filter' ? splt : breakUrl12;

    const eventFilter = splitData[6];
    const calendar = eventFilter && eventFilter.split('=')[0];
    return (
      <>
        <Layout history={this.props.history}>
          <div className="container-fluid">
            <div className="row">
              {/* <Logo history={this.props.history} /> */}

              <div
                className={
                  this.props.displayMode
                    ? 'main_right author_profile'
                    : 'main_right author_profile calendar-dark'
                }
                // style={{ minHeight: 1000 + "px" }}
                style={{ flex: '1' }}
              >
                {(isEventFilter || isTodayEventList || pathIsDashboard) && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                <div className="d-inline-block">
                                  <div className="toggle_switch">
                                    <span className="yes_no">Private</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handleMyEvents.bind(
                                          this
                                        )}
                                        checked={this.props.displayEventMode}
                                      />

                                      {/* <input type="checkbox" name="myevents" /> */}
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">OTR</span>
                                  </div>
                                </div>
                                <div className="sport">
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      this.setState({ showSortModal: true })
                                    }
                                  >
                                    {/* <span>Sort</span> */}
                                    <img
                                      src={require('../../Utils/Assets/sort_icon.png')}
                                      alt="sort-icon"
                                    />
                                    <h1></h1>
                                  </a>
                                  <SortPopupModal
                                    isOpen={this.state.showSortModal}
                                    history={this.props.history}
                                    toggle={this.hideSortModal.bind(this)}
                                  />
                                </div>
                                <div className="new_filter">
                                  {/* <button onClick={() => this.setState({showFilterModal: true})}> <img src={require("../../Utils/Assets/cup_icon.png")} alt="cup-icon" /></button> */}

                                  <a
                                    onClick={() =>
                                      this.setState({ showFilterModal: true })
                                    }
                                  >
                                    <img
                                      src={require('../../Utils/Assets/cup_icon.png')}
                                      alt="cup-icon"
                                    />
                                  </a>

                                  <FilterPopupModal
                                    isOpen={this.state.showFilterModal}
                                    history={this.props.history}
                                    toggle={this.hideFilterModal.bind(this)}
                                    filerProps={this.updateProps}
                                  />
                                </div>
                                <div className="search_box">
                                  <form>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      name="search"
                                      onChange={e => {
                                        this.setSearchFilter(e);
                                      }}
                                    />
                                    <button type="submit">
                                      <img
                                        src={require('../../Utils/Assets/search_icon.png')}
                                        alt="search-icon"
                                      />
                                    </button>
                                  </form>
                                </div>

                                {/* most popular events */}

                                {pathIsDashboard && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                      // onClick={() =>
                                      //   this.changeEventsView(true)
                                      // }
                                    >
                                      <span
                                        className={`header_text ${splt ===
                                          'dashboard' && 'activeOutline'}`}
                                      >
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/event-list'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className={'header_text'}
                                        style={{ marginRight: '4px' }}
                                      >
                                        LIST VIEW: {''}
                                      </span>
                                      <span
                                        className={`header_text ${this.state
                                          .checkWithPreviousDate === 'PREV' &&
                                          'disabled_list'} `}
                                      >
                                        ALL CURRENT
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={() =>
                                        this.props.history.push(
                                          this.props.match.url +
                                            '/today-event-list'
                                        )
                                      }
                                    >
                                      <span
                                        className="header_text"
                                        style={{ margin: '0 -2px' }}
                                      >
                                        TODAY'S EVENTS
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="icon"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/tranding-events'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span className="header_text">
                                        MOST POPULAR
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}
                                {isTodayEventList && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                      // onClick={() =>
                                      //   this.changeEventsView(true)
                                      // }
                                    >
                                      <span
                                        className={`header_text ${splt ===
                                          'dashboard' && 'activeOutline'}`}
                                      >
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/event-list'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className={'header_text'}
                                        style={{ marginRight: '4px' }}
                                      >
                                        LIST VIEW: {''}
                                      </span>
                                      <span
                                        className={`header_text ${this.state
                                          .checkWithPreviousDate === 'PREV' &&
                                          'disabled_list'} `}
                                      >
                                        ALL CURRENT
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={() =>
                                        this.props.history.push(
                                          this.props.match.url +
                                            '/today-event-list'
                                        )
                                      }
                                    >
                                      <span
                                        style={{ margin: '0 -2px' }}
                                        className={`header_text ${checkevent ===
                                          'today-event-list' &&
                                          'activeOutline'}`}
                                      >
                                        TODAY'S EVENTS
                                      </span>

                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="icon"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/tranding-events'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span className="header_text">
                                        MOST POPULAR
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}

                                {isEventFilter && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span className="header_text">
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/event-list'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className={'header_text'}
                                        style={{ marginRight: '4px' }}
                                      >
                                        LIST VIEW: {''}
                                      </span>
                                      <span
                                        className={`header_text ${checkevent ===
                                          'event-filter' && 'activeOutline'}`}
                                      >
                                        ALL CURRENT
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={() =>
                                        this.props.history.push(
                                          this.props.match.url +
                                            '/today-event-list'
                                        )
                                      }
                                    >
                                      <span
                                        className="header_text"
                                        style={{ margin: '0 -2px' }}
                                      >
                                        TODAY'S EVENTS
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="icon"
                                      />
                                    </a>

                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/tranding-events'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span className="header_text">
                                        MOST POPULAR
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}

                                {/* {isEventFilter && (
                                  <>
                                    <span className="yes_no"> All Events</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handlePopularEvents.bind(
                                          this
                                        )}
                                        checked={false}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">
                                      {' '}
                                      Most Popular Events
                                    </span>
                                  </>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isEventList && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                <div className="d-inline-block">
                                  <div className="toggle_switch">
                                    <span className="yes_no">Private</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handleMyEvents.bind(
                                          this
                                        )}
                                        checked={this.props.displayEventMode}
                                      />
                                      {/* <input type="checkbox" name="myevents" /> */}
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">OTR</span>
                                  </div>
                                </div>
                                <div className="sport">
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      this.setState({ showSortModal: true })
                                    }
                                  >
                                    {/* <span>Sort</span> */}
                                    <img
                                      src={require('../../Utils/Assets/sort_icon.png')}
                                      alt="sort-icon"
                                    />
                                    <h1></h1>
                                  </a>
                                  <SortPopupModal
                                    isOpen={this.state.showSortModal}
                                    history={this.props.history}
                                    toggle={this.hideSortModal.bind(this)}
                                  />
                                </div>
                                <div className="new_filter">
                                  {/* <button onClick={() => this.setState({showFilterModal: true})}> <img src={require("../../Utils/Assets/cup_icon.png")} alt="cup-icon" /></button> */}

                                  <a
                                    onClick={() =>
                                      this.setState({ showFilterModal: true })
                                    }
                                  >
                                    <img
                                      src={require('../../Utils/Assets/cup_icon.png')}
                                      alt="cup-icon"
                                    />
                                  </a>

                                  <FilterPopupModal
                                    isOpen={this.state.showFilterModal}
                                    history={this.props.history}
                                    toggle={this.hideFilterModal.bind(this)}
                                  />
                                </div>
                                <div className="search_box">
                                  <form>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      name="search"
                                      onChange={e => {
                                        this.setSearchFilter(e);
                                      }}
                                    />
                                    <button type="submit">
                                      <img
                                        src={require('../../Utils/Assets/search_icon.png')}
                                        alt="search-icon"
                                      />
                                    </button>
                                  </form>
                                </div>

                                {/* most popular events */}

                                {isEventList && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                      // onClick={() =>
                                      //   this.changeEventsView(true)
                                      // }
                                    >
                                      <span className="header_text">
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/event-list'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className={'header_text'}
                                        style={{ marginRight: '4px' }}
                                      >
                                        LIST VIEW: {''}
                                      </span>

                                      <span
                                        className={`header_text ${splt ===
                                          'event-list' && 'activeOutline'}`}
                                      >
                                        ALL CURRENT
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={() =>
                                        this.props.history.push(
                                          this.props.match.url +
                                            '/today-event-list'
                                        )
                                      }
                                    >
                                      <span
                                        className="header_text"
                                        style={{ margin: '0 -2px' }}
                                      >
                                        TODAY'S EVENTS
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="icon"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/tranding-events'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span className="header_text">
                                        MOST POPULAR
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}

                                {isEventFilter && (
                                  <>
                                    <span className="yes_no"> All Events</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handlePopularEvents.bind(
                                          this
                                        )}
                                        checked={false}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">
                                      {' '}
                                      Most Popular Events
                                    </span>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isTrendingEvents && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                {/*    <div className="d-inline-block">
                                  <div className="toggle_switch">
                                    <span className="yes_no">Private</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handleMyEvents.bind(
                                          this
                                        )}
                                        checked={this.props.displayEventMode}
                                      />
                                      {/* <input type="checkbox" name="myevents" /> */}
                                {/* <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">OTR</span>
                                  </div> */}
                                {/* </div>  */}
                                <div className="sport">
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      this.setState({ showSortModal: true })
                                    }
                                  >
                                    {/* <span>Sort</span> */}
                                    <img
                                      src={require('../../Utils/Assets/sort_icon.png')}
                                      alt="sort-icon"
                                    />
                                    <h1></h1>
                                  </a>
                                  <SortPopupModal
                                    isOpen={this.state.showSortModal}
                                    history={this.props.history}
                                    toggle={this.hideSortModal.bind(this)}
                                  />
                                </div>
                                <div className="new_filter">
                                  {/* <button onClick={() => this.setState({showFilterModal: true})}> <img src={require("../../Utils/Assets/cup_icon.png")} alt="cup-icon" /></button> */}

                                  <a
                                    onClick={() =>
                                      this.setState({ showFilterModal: true })
                                    }
                                  >
                                    <img
                                      src={require('../../Utils/Assets/cup_icon.png')}
                                      alt="cup-icon"
                                    />
                                  </a>

                                  <FilterPopupModal
                                    isOpen={this.state.showFilterModal}
                                    history={this.props.history}
                                    toggle={this.hideFilterModal.bind(this)}
                                  />
                                </div>
                                <div className="search_box">
                                  <form>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      name="search"
                                      onChange={e => {
                                        this.setSearchFilter(e);
                                      }}
                                    />
                                    <button type="submit">
                                      <img
                                        src={require('../../Utils/Assets/search_icon.png')}
                                        alt="search-icon"
                                      />
                                    </button>
                                  </form>
                                </div>

                                {/* most popular events */}

                                {isTrendingEvents && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                      // onClick={() =>
                                      //   this.changeEventsView(true)
                                      // }
                                    >
                                      <span className="header_text">
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/event-list'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className="header_text"
                                        style={{ marginRight: '4px' }}
                                      >
                                        LIST VIEW: {''}
                                      </span>
                                      <span className="header_text">
                                        ALL CURRENT
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={() =>
                                        this.props.history.push(
                                          this.props.match.url +
                                            '/today-event-list'
                                        )
                                      }
                                    >
                                      <span
                                        className="header_text"
                                        style={{ margin: '0 -2px' }}
                                      >
                                        TODAY'S EVENTS
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="icon"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/tranding-events'
                                          )
                                        // this.changeEventsView(
                                        //   !this.state.isCalenderView
                                        // )
                                      }
                                    >
                                      <span
                                        className={`header_text ${splt ===
                                          'tranding-events' &&
                                          'activeOutline'}`}
                                      >
                                        MOST POPULAR
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}

                                {/* {isEventFilter && (
                                  <>
                                    <span className="yes_no"> All Events</span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="myevents"
                                        onChange={this.handlePopularEvents.bind(
                                          this
                                        )}
                                        checked={false}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">
                                      {' '}
                                      Most Popular Events
                                    </span>
                                  </>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {eventFilter !== 'vendors' ||
                  eventFilter !== 'all' ||
                  eventFilter !== 'blogger' ||
                  eventFilter !== 'beta_readers' ||
                  eventFilter !== 'followers' ||
                  eventFilter !== 'following' ||
                  (eventFilter !== 'most_followed' && (
                    <div className="filter_row">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="filter_options">
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="filter-section">
                                  <div className="d-inline-block">
                                    <div className="toggle_switch">
                                      <span className="yes_no">Private</span>
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          name="myevents"
                                          onChange={this.handleMyEvents.bind(
                                            this
                                          )}
                                          checked={this.props.displayEventMode}
                                        />
                                        {/* <input type="checkbox" name="myevents" /> */}
                                        <span className="slider round"></span>
                                      </label>
                                      <span className="yes_no">OTR</span>
                                    </div>
                                  </div>
                                  <div className="sport">
                                    <a
                                      href="javascript:;"
                                      onClick={() =>
                                        this.setState({ showSortModal: true })
                                      }
                                    >
                                      {/* <span>Sort</span> */}
                                      <img
                                        src={require('../../Utils/Assets/sort_icon.png')}
                                        alt="sort-icon"
                                      />
                                      <h1></h1>
                                    </a>
                                    <SortPopupModal
                                      isOpen={this.state.showSortModal}
                                      history={this.props.history}
                                      toggle={this.hideSortModal.bind(this)}
                                    />
                                  </div>
                                  <div className="new_filter">
                                    {/* <button onClick={() => this.setState({showFilterModal: true})}> <img src={require("../../Utils/Assets/cup_icon.png")} alt="cup-icon" /></button> */}

                                    <a
                                      onClick={() =>
                                        this.setState({ showFilterModal: true })
                                      }
                                    >
                                      <img
                                        src={require('../../Utils/Assets/cup_icon.png')}
                                        alt="cup-icon"
                                      />
                                    </a>

                                    <FilterPopupModal
                                      isOpen={this.state.showFilterModal}
                                      history={this.props.history}
                                      toggle={this.hideFilterModal.bind(this)}
                                    />
                                  </div>
                                  <div className="search_box">
                                    <form>
                                      <input
                                        type="text"
                                        placeholder="Search"
                                        name="search"
                                        onChange={e => {
                                          this.setSearchFilter(e);
                                        }}
                                      />
                                      <button type="submit">
                                        <img
                                          src={require('../../Utils/Assets/search_icon.png')}
                                          alt="search-icon"
                                        />
                                      </button>
                                    </form>
                                  </div>

                                  {/* most popular events */}

                                  {eventFiler && (
                                    <div
                                      className="calender_list"
                                      style={{ color: 'black' }}
                                    >
                                      <a
                                        href="javascript:;"
                                        className="calender cursor-pointer"
                                        onClick={
                                          () =>
                                            this.props.history.push(
                                              this.props.match.url
                                            )
                                          // this.changeEventsView(
                                          //   !this.state.isCalenderView
                                          // )
                                        }
                                        // onClick={() =>
                                        //   this.changeEventsView(true)
                                        // }
                                      >
                                        <span
                                          className="header_text"
                                          style={{ marginRight: '4px' }}
                                        >
                                          LIST VIEW: {''}
                                        </span>
                                        <span className="header_text">
                                          ALL CURRENT
                                        </span>
                                        <img
                                          className="header_mobile_img"
                                          src={
                                            this.props.displayMode
                                              ? require('../../Utils/Assets/calender_logo.png')
                                              : require('../../Utils/Assets/calender_logo_white.png')
                                          }
                                          alt="join"
                                        />
                                      </a>
                                      <span className="devider">|</span>
                                      <a
                                        href="javascript:;"
                                        className="list cursor-pointer"
                                        onClick={
                                          () =>
                                            this.props.history.push(
                                              this.props.match.url +
                                                '/event-list'
                                            )
                                          // this.changeEventsView(
                                          //   !this.state.isCalenderView
                                          // )
                                        }
                                      >
                                        <span
                                          className={`header_text ${eventFiler ===
                                            'startDate' && 'activeOutline'}`}
                                        >
                                          List View : All Current
                                        </span>
                                        <img
                                          className="header_mobile_img"
                                          src={
                                            this.props.displayMode
                                              ? require('../../Utils/Assets/listView_logo.png')
                                              : require('../../Utils/Assets/listView_logo_white.png')
                                          }
                                          alt="join"
                                        />
                                      </a>
                                      <span className="devider">|</span>
                                      <a
                                        href="javascript:;"
                                        className="list cursor-pointer"
                                        onClick={() =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/today-event-list'
                                          )
                                        }
                                      >
                                        <span
                                          className="header_text"
                                          style={{ margin: '0 -2px' }}
                                        >
                                          TODAY'S EVENTS
                                        </span>
                                        <img
                                          className="header_mobile_img"
                                          src={
                                            this.props.displayMode
                                              ? require('../../Utils/Assets/listView_logo.png')
                                              : require('../../Utils/Assets/listView_logo_white.png')
                                          }
                                          alt="icon"
                                        />
                                      </a>
                                      <span className="devider">|</span>
                                      <a
                                        href="javascript:;"
                                        className="list cursor-pointer"
                                        onClick={
                                          () =>
                                            this.props.history.push(
                                              this.props.match.url +
                                                '/tranding-events'
                                            )
                                          // this.changeEventsView(
                                          //   !this.state.isCalenderView
                                          // )
                                        }
                                      >
                                        <span className="header_text">
                                          MOST POPULAR
                                        </span>
                                        <img
                                          className="header_mobile_img"
                                          src={
                                            this.props.displayMode
                                              ? require('../../Utils/Assets/listView_logo.png')
                                              : require('../../Utils/Assets/listView_logo_white.png')
                                          }
                                          alt="join"
                                        />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {isMyEvents && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                {/* <div className="d-inline-block">
                                  <div className="toggle_switch">
                                    <span className="yes_no">
                                      Current Events
                                    </span>
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        name="allevents"
                                        onChange={this.handleAllEvents.bind(
                                          this
                                        )}
                                        checked={this.props.displayEventMode}
                                      />
                                      <span className="slider round"></span>
                                    </label>
                                    <span className="yes_no">All Events</span>
                                  </div>
                                </div> */}
                                <div className="sport">
                                  <a
                                    href="javascript:;"
                                    onClick={() =>
                                      this.setState({ showSortModal: true })
                                    }
                                  >
                                    {/* <span>Sort</span> */}
                                    <img
                                      src={require('../../Utils/Assets/sort_icon.png')}
                                      alt="sort-icon"
                                    />
                                    <h1></h1>
                                  </a>
                                  <SortPopupModal
                                    isOpen={this.state.showSortModal}
                                    history={this.props.history}
                                    toggle={this.hideSortModal.bind(this)}
                                  />
                                </div>
                                <div className="new_filter">
                                  {/* <button onClick={() => this.setState({showFilterModal: true})}> <img src={require("../../Utils/Assets/cup_icon.png")} alt="cup-icon" /></button> */}

                                  <a
                                    onClick={() =>
                                      this.setState({ showFilterModal: true })
                                    }
                                  >
                                    <img
                                      src={require('../../Utils/Assets/cup_icon.png')}
                                      alt="cup-icon"
                                    />
                                  </a>

                                  <FilterPopupModal
                                    isOpen={this.state.showFilterModal}
                                    history={this.props.history}
                                    toggle={this.hideFilterModal.bind(this)}
                                  />
                                </div>
                                <div className="search_box">
                                  <form>
                                    <input
                                      type="text"
                                      placeholder="Search"
                                      name="search"
                                      onChange={e => {
                                        this.setMyEventSearchFilter(e);
                                      }}
                                    />
                                    <button type="submit">
                                      <img
                                        src={require('../../Utils/Assets/search_icon.png')}
                                        alt="search-icon"
                                      />
                                    </button>
                                  </form>
                                </div>

                                {/* most popular events */}

                                {isMyEvents && (
                                  <>
                                    <div className="d-inline-block">
                                      <div className="toggle_switch">
                                        <span className="yes_no">
                                          All Events
                                        </span>
                                        <label className="switch">
                                          <input
                                            type="checkbox"
                                            name="allevents"
                                            onChange={this.changeMyEventsView.bind(
                                              this
                                            )}
                                            //checked={this.state.allcheck}
                                            checked={
                                              this.state.isMyAllEventsList
                                            }
                                          />
                                          <span className="slider round"></span>
                                        </label>
                                        <span className="yes_no">
                                          Current Events
                                        </span>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {isDailyTasks && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                {isDailyTasks && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/daily-task'
                                          )
                                        //this.changeDailyTaskView(true)
                                      }
                                    >
                                      <span
                                        className={`header_text ${splt ===
                                          'daily_task' && 'activeOutline'}`}
                                      >
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/daily_task_list'
                                          )
                                        // this.changeDailyTaskView(false)
                                      }
                                    >
                                      <span
                                        className={`header_text ${this.state
                                          .checkWithPreviousDateDailyTask ===
                                          'PREV' && 'disabled_list'} `}
                                      >
                                        List View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isDailyTaskList && (
                  <div className="filter_row">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="filter_options">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="filter-section">
                                {isDailyTaskList && (
                                  <div
                                    className="calender_list"
                                    style={{ color: 'black' }}
                                  >
                                    <a
                                      href="javascript:;"
                                      className="calender cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url + '/daily-task'
                                          )
                                        //this.changeDailyTaskView(true)
                                      }
                                    >
                                      <span className="header_text">
                                        Calendar View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/calender_logo.png')
                                            : require('../../Utils/Assets/calender_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                    <span className="devider">|</span>
                                    <a
                                      href="javascript:;"
                                      className="list cursor-pointer"
                                      onClick={
                                        () =>
                                          this.props.history.push(
                                            this.props.match.url +
                                              '/daily_task_list'
                                          )
                                        // this.changeDailyTaskView(false)
                                      }
                                    >
                                      <span
                                        className={`header_text ${splt ===
                                          'daily_task_list' &&
                                          'activeOutline'}`}
                                      >
                                        List View
                                      </span>
                                      <img
                                        className="header_mobile_img"
                                        src={
                                          this.props.displayMode
                                            ? require('../../Utils/Assets/listView_logo.png')
                                            : require('../../Utils/Assets/listView_logo_white.png')
                                        }
                                        alt="join"
                                      />
                                    </a>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isFollowingEvent && (
                  <div className="navbar navbar-expand-lg navbar-light bg-light following-event-header">
                    <div className="d-inline-block">
                      <div className="toggle_switch">
                        <span className="yes_no">All New</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="allevents"
                            onChange={this.changeFollowingEvent.bind(this)}
                            //checked={this.state.allcheck}
                          />
                          <span className="slider round"></span>
                        </label>
                        <span className="yes_no">Today's Event</span>
                      </div>
                    </div>
                  </div>
                )}

                <div
                  className={
                    this.props.displayMode
                      ? 'calendar-container mb-2'
                      : 'calendar-container-dark mb-2'
                  }
                >
                  <Route
                    exact
                    path="/dashboard"
                    render={() =>
                      this.props.eventsData && this.state.isCalenderView ? (
                        <>
                          <EventsCalendarView
                            openEventModal={() =>
                              this.setState({ showModal: true })
                            }
                            eventsData={this.props.eventsData}
                            isTheameLight={this.props.displayMode}
                            history={this.props.history}
                            eventmode={this.state.myevents}
                            isReader={isReader}
                            isBlogger={isBlogger}
                            pathIsDashboard={pathIsDashboard}
                            childToProps={this.childToProps}
                          />
                        </>
                      ) : (
                        <Loader />
                      )
                    }
                  />

                  <Route
                    path={this.props.match.url + '/event-filter'}
                    render={() =>
                      this.isAuthorized('/event-filter') ? (
                        <EventFilter
                          openEventModal={() =>
                            this.setState({ showModal: true })
                          }
                          myevents={this.props.displayMode}
                          search={this.state.search}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          pathIsDashboard={pathIsDashboard}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/today-event-list'}
                    render={() => (
                      <TodayEvent
                        openEventModal={() =>
                          this.setState({ showModal: true })
                        }
                        myevents={this.props.displayMode}
                        search={this.state.search}
                        isReader={isReader}
                        isBlogger={isBlogger}
                        pathIsDashboard={pathIsDashboard}
                      />
                    )}
                  />
                  {/* {console.log("vinay",this.props.eventsData)} */}
                  <Route
                    path={this.props.match.url + '/event-list'}
                    render={() =>
                      this.props.eventsData ? (
                        <EventsListView
                          openEventModal={() =>
                            this.setState({ showModal: true })
                          }
                          myevents={this.props.displayMode}
                          search={this.state.search}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          pathIsDashboard={pathIsDashboard}
                        />
                      ) : (
                        <Loader />
                      )
                    }
                  />
                  {/* <Route
                    path={this.props.match.url + '/my-events'}
                    render={() =>
                      this.isAuthorized('/my-events') ? (
                        <MyEvents
                          myevents={this.props.displayMode}
                          search={this.state.search}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          pathIsDashboard={pathIsDashboard}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  /> */}

                  <Route
                    exact
                    path="/dashboard/my-events"
                    render={() =>
                      this.props.myEventsData &&
                      this.state.isMyAllEventsList ? (
                        <MyCurrentEvents
                          openEventModal={() =>
                            this.setState({ showModal: true })
                          }
                          myevents={this.props.displayMode}
                          search={this.state.search}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          isMyEvents={isMyEvents}
                        />
                      ) : (
                        <MyEvents
                          openEventModal={() =>
                            this.setState({ showModal: true })
                          }
                          myevents={this.props.displayMode}
                          search={this.state.search}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          isMyEvents={isMyEvents}
                        />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/event-start'}
                    render={() => (
                      <EventStart
                        openEventModal={() =>
                          this.setState({ showModal: true })
                        }
                        myevents={this.props.displayMode}
                        search={this.state.search}
                        isReader={isReader}
                        isBlogger={isBlogger}
                        pathIsDashboard={pathIsDashboard}
                      />
                    )}
                  />

                  {/* <Route
                    path={this.props.match.url + '/event-filter/startDate'}
                    render={() => (
                      <EventFilter
                        openEventModal={() =>
                          this.setState({ showModal: true })
                        }
                        myevents={this.props.displayMode}
                        search={this.state.search}
                        isReader={isReader}
                        isBlogger={isBlogger}
                        pathIsDashboard={pathIsDashboard}
                      />
                    )}
                  /> */}
                  <Route
                    path={this.props.match.url + '/home'}
                    render={() =>
                      this.isAuthorized('/home') ? (
                        <HomePage history={this.props.history} />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  {/* <Route path={this.props.match.url + "/users"} render={() => this.isAuthorized("/users") ? <UsersListView myevents={this.props.displayMode} history={this.props.history} /> : <Redirect to="/dashboard" push />} /> */}
                  <Route
                    path={this.props.match.url + '/users/followers'}
                    render={() =>
                      this.isAuthorized('/users/followers') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/blogger'}
                    render={() =>
                      this.isAuthorized('/users/blogger') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/beta_reader_list'}
                    render={() =>
                      this.isAuthorized('/users/beta_reader_list') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/beta_readers'}
                    render={() =>
                      this.isAuthorized('/users/beta_readers') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                          profileData={this.props.profileData}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/most_followed'}
                    render={() =>
                      this.isAuthorized('/users/most_followed') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/vco_list'}
                    render={() =>
                      this.isAuthorized('/users/vco_list') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/following'}
                    render={() =>
                      this.isAuthorized('/users/following') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/users/all'}
                    render={() =>
                      this.isAuthorized('/users/all') ? (
                        <UsersListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                          mostPopular={this.state.mostPopular}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/messages'}
                    render={() =>
                      this.isAuthorized('/messages') ? (
                        <Messages />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  {/* <Route
                    path={this.props.match.url + '/daily_task'}
                    render={() =>
                      this.isAuthorized('/daily_task') ? (
                        <DailyTask
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  /> */}

                  <Route
                    exact
                    path="/dashboard/daily-task"
                    render={() =>
                      this.props.taskData ? (
                        <DailyTask
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          isDailyTasks={isDailyTasks}
                          childToPropsDaily={this.childToPropsDailyTaks}
                        />
                      ) : (
                        <Loader />
                      )
                    }
                  />

                  <Route
                    exact
                    path="/dashboard/daily_task_list"
                    render={() =>
                      this.props.taskData ? (
                        <DailyListView
                          eventsData={this.props.taskData}
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          // eventmode={this.state.myevents}
                          isReader={isReader}
                          isBlogger={isBlogger}
                          isDailyTasks={isDailyTasks}
                        />
                      ) : (
                        <Loader />
                      )
                    }
                  />

                  <Route
                    exact
                    path={this.props.match.url + '/dailyTaskDetails'}
                    render={() =>
                      this.isAuthorized('/dailyTaskDetails') ? (
                        this.props.taskData ? (
                          <DailTaskDetails
                            isTheameLight={this.props.displayMode}
                            history={this.props.history}
                            props={this.props}
                          />
                        ) : (
                          <Loader />
                        )
                      ) : this.props.taskData ? (
                        <DailTaskDetails
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          props={this.props}
                        />
                      ) : (
                        <Loader />
                      )
                    }
                  />

                  <Route
                    path={this.props.match.url + '/users/vendors'}
                    render={() =>
                      this.isAuthorized('/users/vendors') ? (
                        <VendorListView
                          myevents={this.props.displayMode}
                          history={this.props.history}
                        />
                      ) : (
                        <Redirect to="/dashboard" push />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/my-profile'}
                    render={() => <MyProfile />}
                  />
                  <Route
                    path={this.props.match.url + '/prefrences'}
                    render={() => <EditPrefrences />}
                  />
                  <Route
                    path={this.props.match.url + '/membership'}
                    render={() => <MembershipInfo />}
                  />
                  <Route
                    path={this.props.match.url + '/linked'}
                    render={() => (
                      <LinkedEvents
                        history={this.props.history}
                        props={this.props}
                      />
                    )}
                  />

                  <Route
                    path={this.props.match.url + '/linkedbookrelease'}
                    render={() => (
                      <LinkedBookrelease
                        history={this.props.history}
                        props={this.props}
                      />
                    )}
                  />
                  <Route
                    path={this.props.match.url + '/blasts'}
                    render={() => <Blast />}
                  />
                  <Route
                    path={this.props.match.url + '/user'}
                    render={() => <ManageAccount />}
                  />

                  <Route
                    path={this.props.match.url + '/notification'}
                    render={() => {
                      return (
                        <NotificaitonList
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={this.props.notificationList.currentUserData}
                          followingData={
                            this.props.notificationList.followingData
                          }
                          eventArray={this.props.notificationList.eventarray}
                        />
                      );
                    }}
                  />

                  <Route
                    path={this.props.match.url + '/followingevent'}
                    render={() => {
                      return this.state.isFollowingEventList ? (
                        <NotificaitonListFollowing
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={this.props.notificationList.followingData}
                          eventArray={this.props.notificationList.eventarray}
                        />
                      ) : (
                        <NotificaitonListFollowing
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={this.props.notificationList.sevenDaysEvent}
                        />
                      );
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/showadvertise'}
                    render={() => {
                      return (
                        <ShowAdvertiseList
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={this.props.notificationList.followingData}
                        />
                      );
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/showblastlist'}
                    render={() => {
                      return (
                        <ShowBlastList
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={this.props.notificationList.followingData}
                        />
                      );
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/newsletter'}
                    render={() => {
                      return this.props.notificationList.currentUserData ? (
                        <Newsletter
                          isTheameLight={this.props.displayMode}
                          history={this.props.history}
                          data={
                            this.props.notificationList.currentUserData.event
                          }
                        />
                      ) : (
                        <Loader />
                      );
                    }}
                  />
                  <Route
                    path={`${this.props.match.url}/blast-notification`}
                    render={() => (
                      <BlastNotificationsList
                        data={this.state.blastNotifications}
                      />
                    )}
                  />

                  <Route
                    path={`${this.props.match.url}/tranding-events`}
                    render={() =>
                      this.props.trendingEvent ? (
                        <TrandingEvents data={this.state.blastNotifications} />
                      ) : (
                        <Loader />
                      )
                    }
                  />
                  <Route
                    path={this.props.match.url + '/privacy'}
                    render={() => {
                      return <PrivacyPolicy />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/condition'}
                    render={() => {
                      return <Terms />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/plans'}
                    render={() => {
                      return <Membership />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/about'}
                    render={() => {
                      return <About />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/help'}
                    render={() => {
                      return <Help />;
                    }}
                  />
                    <Route
                    path={this.props.match.url+'/otrplans_readerfree'}
                    render={() => {
                      return <MembershipReaderdfree />;             
                    }}
                  />
                   <Route
                    path={this.props.match.url+'/otrplans_regular'}
                    render={() => {
                      return <MembershipRegularPrice />;      
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/faq'}
                    render={() => {
                      return <Faq />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/howto'}
                    render={() => {
                      return <MyVideos />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/advertise'}
                    render={() => {
                      return <Advertise />;
                    }}
                  />
                  <Route
                    path={this.props.match.url + '/upgradePlan'}
                    render={() => {
                      return <UpgradePlan props={this.props} />;
                    }}
                  />
                </div>

                <div>
                  {this.state.showModal ? (
                    <CreateEventModal
                      isOpen={this.state.showModal}
                      history={this.props.history}
                      toggle={this.hideCreateModal}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {/* <Footer props={this.props} />  */}
          {this.state.cookiesPolicy && (
            <div class="cookip-policy">
              <div class="cookip-inner">
                <div class="privacy-policy">
                  <div className="privacy-content">
                    We use cookies to ensure the functionality of our platform,
                    to personalize content, to provide social media features and
                    ads, and to analyze our traffic. For more information,
                    please see our
                    <Link to={'/privacy'} className="red">
                      <u>Privacy policy.</u>
                    </Link>
                  </div>
                  <div className="privacy-btn">
                    <button
                      class="btn btn-lg cookip-btn"
                      onClick={e => this.handleSaveCookies()}
                    >
                      Accept
                    </button>
                    {/* <button
                      class="close ml-auto"
                      onClick={e => this.setState({ cookiesPolicy: false })}
                    >
                      <i class="fa fa-times-circle"></i>
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      </>
    );
  }

  componentDidMount() {
    this.getBlastNotifications();
    this.getIpAddress();
    // this.getCookiePlolicy();
    this.getCookiePolicyConsent();

    this.props.getMyEvents(`/api/v1/events?event_type=my`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    this.props.getAllTask(`/api/v1/daily_task/getTask`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    localStorage.removeItem('mode');
    this.props.getNotificationList(`/api/v1/notification`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });
    this.props.getMessageCount(`/api/v1/countUnreadedMessages`, {
      headers: { 'x-authorization': localStorage.getItem('token') }
    });

    // this.props.getAllEvents(`/api/v1/events?isGeneral=${eventMode}`, {
    //   headers: { 'x-authorization': localStorage.getItem('token') }
    // })
    // this.props.getMenuList("/api/v1/dashboard/menu", { headers: { 'x-authorization': localStorage.getItem("token") } });
    this.props.getAllEvents(this.props.displayEventMode);
    this.props.getEvents(this.props.displayEventMode);
    this.props.getUserProfile(
      '/api/v1/user/profile',
      { headers: { 'x-authorization': localStorage.getItem('token') } },
      false
    );

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      $(document).ready(function() {
        $('.nav-link, .dropdown-item').click(function() {
          $('#navbarSupportedContent').removeClass('show');
        });
      });
    }
    this.props.eventLoadingTrue();
    Axios.get(BASEURL + '/api/v1/users/mostpopular', {
      headers: { 'x-authorization': localStorage.getItem('token') }
    })
      .then(res => {
        if (res.data.result && res.data.result.userData) {
          this.setState({ mostPopular: [...res.data.result.userData] });
        }
        this.props.eventLoadingFalse();
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
        } else {
          localStorage.removeItem('token');
        }
        this.props.eventLoadingFalse();
        //   dispatch(profileLoading(false));
      });
  }

  componentWillReceiveProps() {
    // if (!this.props.eventsData || this.props.eventsData === '') {
    //   this.props.getAllEvents(this.state.myevents);
    // }
    // if (!this.props.events || this.props.events === '') {
    //   this.props.getEvents(this.state.myevents);
    // }
    //this.props.getAllEvents();
    //this.setState({ formJSON: this.props.formJSON });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.getAllEvents(this.state.myevents);
    }
  }
}

const Footer = ({ props }) => {
  return (
    <>
      <footer style={{ position: 'absolute', bottom: '0', width: '100%' }}>
        {/* <div className="container"> */}
        {/* <div className="row text-center">
        <div className="col-md-12 float-md-left text-center">
          <div className="footer_text-mb-4">
            <ul className="p-0">
              <li className="col-md-2 float-md-left">
                <Link to="/about" className="links" >About Us</Link>
              </li>
              <li className="col-md-2 float-md-left">
                <Link to="/condition" className="links" >Terms of Service</Link>
              </li>
              <li className="col-md-2 float-md-left">
                <Link to="/privacy" className="links" >Privacy Policy</Link>
              </li>
              <li className="col-md-2 float-md-left">
                <Link to="/faq" className="links" >FAQ</Link>
              </li>
              <li className="col-md-2 float-md-left">
                <Link to="/contact" className="links" >Contact Us</Link>
              </li>
            </ul>

             <div >
                        <a href="#" class="socialAcc fa fa-facebook"></a>
                        <a href="#" class="socialAcc fa fa-twitter"></a>
                        <a href="#" class="socialAcc fa fa-instagram"></a>
                        <a href="#" class="socialAcc fa fa-youtube"></a>
                      </div> 
          </div>
        </div>
      </div> */}

        {/* </div> */}

        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-8 order-lg-2">
              <div className="row">
                <div className="col-md-12 text-center">
                  <ul className="p-0 footer-links">
                    <li
                      onClick={() => {
                        props.changeTab('privacy');
                      }}
                      className={
                        props.activeTab === 'privacy'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/dashboard/privacy" className="links">
                        PRIVACY
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      onClick={() => {
                        props.changeTab('condition');
                      }}
                      className={
                        props.activeTab === 'condition'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/dashboard/condition" className="links">
                        TERMS &amp; CONDITIONS
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      onClick={() => {
                        props.changeTab('plans');
                      }}
                      className={
                        props.activeTab === 'plans'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/dashboard/plans" className="links">
                        MEMBERSHIP PLANS
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      onClick={() => {
                        props.changeTab('about');
                      }}
                      className={
                        props.activeTab === 'about'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/dashboard/about" className="links">
                        ABOUT US
                      </Link>
                    </li>
                    <li>|</li>
                    <li
                      onClick={() => {
                        props.changeTab('help');
                      }}
                      className={
                        props.activeTab === 'help'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      <Link to="/dashboard/help" className="links">
                        HELP
                      </Link>
                    </li>

                    <li
                      onClick={() => {
                        props.changeTab('advertise');
                      }}
                      className={
                        props.activeTab === 'advertise'
                          ? 'nav-item active'
                          : 'nav-item'
                      }
                    >
                      {localStorage.getItem('membershipId') == 1 ||
                      localStorage.getItem('membershipId') == 8 ||
                      localStorage.getItem('membershipId') == 5 ? (
                        <li>
                          <li>|</li>
                          <Link to="/dashboard/advertise" className="links">
                            ADVERTISE
                          </Link>
                        </li>
                      ) : (
                        ''
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 order-lg-3">
              <ul className="list-unstyled footer-links text-lg-right text-center">
                <li>
                  <a
                    href={CONSTANT.FACEBOOK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../../Utils/Assets/social_media/White/facebook.png')}
                      alt="logo"
                      width="40px"
                      height="40px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={CONSTANT.INSTAGRAM}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../../Utils/Assets/social_media/White/instagram.png')}
                      alt="logo"
                      width="40px"
                      height="40px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={CONSTANT.TWITTER_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../../Utils/Assets/social_media/White/twitter.png')}
                      alt="logo"
                      width="40px"
                      height="40px"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={CONSTANT.YOUTUBE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={require('../../Utils/Assets/social_media/White/youtube.png')}
                      alt="logo"
                      width="40px"
                      height="40px"
                    />
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 text-lg-left text-center">
              <p class="copyRight text-white">&copy; Copyright 2023 OTR LLC</p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = state => {
  return {
    loading: state.eventLoading,
    eventsData: state.eventsummary,
    events: state.events,
    activeTab: state.Tab,
    search: state.Search,
    menuList: state.dashboardTabs.menuList,
    displayMode: state.changeMode,
    displayEventMode: state.getEventMode,
    notificationList: state.getNotification,
    messageCount: state.countUnreadedMessages,
    profileData: state.myProfileData,
    taskData: state.filterTask,
    myEventsData: state.filterevent,
    trendingEvent: state.getFilterEventData
  };
};

const mapDispatchToProps = dispatch => {
  const getNextSunday = date => {
    // Create a Moment object with the given date
    const inputDate = moment(date);

    // Calculate the number of days to add to reach the next Sunday
    const daysToAdd = (7 - inputDate.day()) % 7;

    // Add the days to get the next Sunday
    const nextSunday = inputDate.add(daysToAdd, 'days');

    return nextSunday.format('YYYY-MM-DD'); // Format the date as desired
  };
  let startDate = moment()
    .startOf('month')
    .format('YYYY-MM-DD');
  let endDate = getNextSunday(moment().endOf('month'));
  return {
    eventLoadingTrue: () =>
      dispatch({
        type: 'EVENT_LOADING',
        status: true
      }),
    eventLoadingFalse: () =>
      dispatch({
        type: 'EVENT_LOADING',
        status: false
      }),
    setupEvent: () => dispatch(setupEvent()),
    getAllEvents: eventMode =>
      dispatch(
        getAllEventsSummary(
          `/api/v1/events/day/count?isGeneral=${eventMode}&startDate=${startDate}&endDate=${endDate}`,
          {
            headers: { 'x-authorization': localStorage.getItem('token') }
          }
        )
      ),
    getEvents: eventMode =>
      dispatch(
        getAllEvents(`/api/v1/events?isGeneral=${eventMode}`, {
          headers: { 'x-authorization': localStorage.getItem('token') }
        })
      ),
    getPopularEvents: (url, header) => dispatch(getPopularEvents(url, header)),
    changeTab: tab => dispatch(Tab(tab)),
    setSearchFilter: search => dispatch(Search(search)),
    clearFilterEvents: () => dispatch(filterevent([])),
    clearSearch: () => dispatch(Search('')),
    getDisplayMode: () => dispatch(changeDisplayMode()),
    eventDisplayMode: mode => dispatch(changeEventMode(mode)),
    getUserProfile: (url, header, edit) =>
      dispatch(getProfileDetails(url, header, edit, 'dashboard')),
    getNotificationList: (url, header) =>
      dispatch(getNotificationlist(url, header)),
    getMessageCount: (url, header) =>
      dispatch(countUnreadMessages(url, header)),
    getAllTask: (url, headers) => dispatch(getAllTasks(url, headers)),
    changePopularEvents: status => dispatch(popularEvents(status)),
    getMyEvents: (url, header) => dispatch(getFilterEvents(url, header))
    // getMenuList:(url,headers)=>dispatch(getDashboardTabs(url,headers))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
