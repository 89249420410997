import React, { Component } from 'react';

class EventHTMLViewParser extends Component {
  getFormElements = function(el, index) {
    switch (el.tag) {
      case 'input':
        return (
          <React.Fragment>
            {el.value !== '' ? (
              el.placeholder === '' ? (
                <span>{el.value}</span>
              ) : (
                <ul>
                  <li>
                    <span>
                      <b>{el.placeholder}</b> : {el.value}
                    </span>
                  </li>
                </ul>
              )
            ) : null}
          </React.Fragment>
        );
      case 'select':
        return (
          <React.Fragment>
            {el.value !== '' ? (
              el.placeholder === '' ? (
                <span>{el.value}</span>
              ) : (
                <ul>
                  <li>
                    <span>
                      <b>{el.placeholder}</b> : {el.value}
                    </span>
                  </li>
                </ul>
              )
            ) : null}
          </React.Fragment>
        );
      case 'title':
        return (
          <React.Fragment>
            <h5>
              <b>
                <u>{el.title}</u>
              </b>
            </h5>
          </React.Fragment>
        );
      case 'textarea':
        return (
          <React.Fragment>
            {el.value !== '' ? (
              el.placeholder === '' ? (
                <span>{el.value}</span>
              ) : (
                <ul>
                  <li>
                    <span>
                      <b>{el.placeholder}</b> : {el.value}
                    </span>
                  </li>
                </ul>
              )
            ) : null}
          </React.Fragment>
        );

      case 'checkbox':
        return (
          <React.Fragment>
            <ul>
              <li>
                <span>{el.value}</span>
              </li>
            </ul>
            <br />
          </React.Fragment>
        );

      case 'date':
        return (
          <React.Fragment>
            {el.value !== '' ? (
              el.placeholder === '' ? (
                <span>{el.value}</span>
              ) : (
                <ul>
                  <li>
                    <span>
                      <b>{el.placeholder}</b> : {el.value}
                    </span>
                  </li>
                </ul>
              )
            ) : null}
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <div></div>
          </React.Fragment>
        );
    }
  };
}
export default EventHTMLViewParser;
