export function eventLoading(state = false, action) {
  switch (action.type) {
    case 'EVENT_LOADING':
      return action.status;

    default:
      return state;
  }
}

export function sendMailModel(state = false, action) {
  switch (action.type) {
    case 'SEND_EMAIL':
      return action.status;
    default:
      return state;
  }
}

export function eventError(state = true, action) {
  switch (action.type) {
    case 'EVENT_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function getDateWiseTasks(state = [], action) {
  switch (action.type) {
    case 'DAILY_TASK_DATE_WISE':
      return action.data;

    default:
      return state;
  }
}

export function eventData(state = '', action) {
  // console.log("action", action.data);
  switch (action.type) {
    case 'EVENT_DATA':
      return action.data;
    default:
      return state;
  }
}

export function events(state = '', action) {
  switch (action.type) {
    case 'EVENTS':
      return action.data;
    default:
      return state;
  }
}

export function eventsummary(state = '', action) {
  switch (action.type) {
    case 'EVENTS_SUMMARY':
      return action.data;
    default:
      return state;
  }
}

export function filterevent(state = '', action) {
  switch (action.type) {
    case 'EVENTS_FILTER':
      return action.data;
    default:
      return state;
  }
}

export function Tab(state = '', action) {
  //console.log('Action =>', action);
  if (action.type !== 'ACTIVE_TAB') {
    let active = window.location.href;
    const splitData = active.split('/');
    const splt = splitData[splitData.length - 1];
    // console.log('splt', splt);

    // let active2 = window.location.href.split('?startDate=')[1];
    // let active1 = window.location.href.split('?')[1].split('=')[0];
    // console.log('active1', active1);
    // console.log(window.location.href.split('?')[1].split('='));
    if (splt === 'dashboard') state = 'Calendar';
    else if (splt === 'event-filter') state = 'event filter';
    else if (splt === 'event-list') state = 'event filter';
    else if (splt === 'vendors') state = 'vendors';
    else if (splt === 'my-events') state = 'MY EVENTS';
    else if (splt === 'daily_task') state = 'DAILY TASKS';
    // else if (splt === 'daily_task' || splt.search('dailyTask') !== -1)
    //   state = 'DAILY TASKS';
    else if (splt === 'messages') state = 'MESSAGES';
    else if (splt === 'help') state = 'help';
    else if (splt === 'about') state = 'about';
    else if (splt === 'plans') state = 'plans';
    else if (splt === 'condition') state = 'condition';
    else if (splt === 'privacy') state = 'privacy';
    else if (splt === 'advertise') state = 'advertise';
    else state = splt;
  }

  //console.log('state', state);
  switch (action.type) {
    case 'ACTIVE_TAB':
      return action.activeTab;
    default:
      return state;
  }
}

export function previewData(state = {}, action) {
  switch (action.type) {
    case 'PREVIEW_DATA':
      return action.data;
    default:
      return state;
  }
}

export function dashboardTabs(state = {}, action) {
  switch (action.type) {
    case 'DASHBOARD_TABS':
      return action.data;
    default:
      return state;
  }
}
export function getEventButtons(state = null, action) {
  switch (action.type) {
    case 'EVENT_BUTTONS':
      return action.data;
    default:
      return state;
  }
}

export function getEventsGroup(state = {}, action) {
  switch (action.type) {
    case 'EVENT_GROUP':
      return action.data;
    default:
      return state;
  }
}

export function editEventType(state = {}, action) {
  switch (action.type) {
    case 'EDIT_EVENT_TYPE':
      return action.data;
    default:
      return state;
  }
}

export function getEventMode(state = true, action) {
  switch (action.type) {
    case 'EVENT_MODE':
      return action.mode;
    default:
      return state;
  }
}

export function getFilterEventData(state = [], action) {
  switch (action.type) {
    case 'GET_FILTER_DATA':
      return action.data;
    default:
      return state;
  }
}

export function addToFavourite(state = false, action) {
  switch (action.type) {
    case 'ADD_FAVOURITE':
      return action.data;
    default:
      return state;
  }
}

export function searchTitle(state = [], action) {
  switch (action.type) {
    case 'EVENT_TITLE':
      return action.data;
    default:
      return state;
  }
}

export function addToMyCalendar(state = {}, action) {
  switch (action.type) {
    case 'ADD_TO_CALENDAR':
      return action.data;
    default:
      return state;
  }
}

export function getAssociatedEvents(state = [], action) {
  switch (action.type) {
    case 'GET_ASSOCIATED_EVENTS':
      return action.data;
    default:
      return state;
  }
}

export function getSingleEventDetails(state = {}, action) {
  // console.log("getSingleEventDetails", action);
  switch (action.type) {
    case 'SINGLE_EVENT':
      return action.data;
    default:
      return state;
  }
}

export function disableButtonRelease(state = false, action) {
  switch (action.type) {
    case 'DISABLE_BOOK_RELEASE':
      return action.boolean;
    default:
      return state;
  }
}

export function getBookRelease(state = [], action) {
  switch (action.type) {
    case 'GET_BOOK_RELEASE_TITLE':
      return action.data;
    default:
      return state;
  }
}

export function getLinkedEventList(state = [], action) {
  switch (action.type) {
    case 'GET_LINKED_EVENTS_LIST':
      return action.data;
    default:
      return state;
  }
}

export function getBookReleaseForUsers(state = [], action) {
  switch (action.type) {
    case 'BOOK_RELEASE_BLAST':
      return action.data;
    default:
      return state;
  }
}

export function getBookReleaseEventBlast(state = [], action) {
  switch (action.type) {
    case 'BOOK_RELEASE_EVENT_BLAST':
      return action.data;
    default:
      return state;
  }
}

export function duplicateEventExist(state = false, action) {
  switch (action.type) {
    case 'DUPLICATE_EVENT_EXIST':
      return action.data;
    default:
      return state;
  }
}

export function checkForSync(state = false, action) {
  switch (action.type) {
    case 'CHECK_SYNC':
      return action.data;
    default:
      return state;
  }
}

export function eventMost(state = [], action) {
  switch (action.type) {
    case 'Event_Most':
      return action.data;
    default:
      return state;
  }
}
