import React, { Component } from "react";
import { PureComponent } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { changeBetaStatus } from "../../Actions/profile-setup";
import { Link, Navigate } from 'react-router-dom';
import { BASEURL } from "../../constant";
import './landing.css'
import { Helmet } from "react-helmet";
class BetaAgreement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      name: null,
      terms: false,
      errMessage: null,
    };
  }

  onCheck = () => {
    this.setState({ terms: !this.state.terms });
  };

  componentDidMount = () => {
    if (localStorage.getItem("betaagreement") === "true") {
      this.props.changeAccess(true);
    }
  };

  handleSubmit = async (e) => {
    try {
      this.setState({ errMessage: null });
      e.preventDefault();
      const data = {
        email: this.state.email,
        name: this.state.name,
      };

      const callApi = await axios.post(
        BASEURL + "/api/v1/users/check_beta",
        data
      );

      if (callApi.status === 200) {
        this.props.changeAccess(true);
        localStorage.setItem("betaagreement", true);
        this.props.history.push("/");
      }
      console.log("callApi", callApi);
    } catch (err) {
      if (err.response.status === 400) {
        this.setState({
          errMessage: "Oops something went wrong. Please verify email address.",
        });
      } else {
        this.setState({
          errMessage: "Something went wrong Please try again",
        });
      }
    }
  };
  render() {
    return (
     
      <>
       {console.log('hdf')}
      <Helmet>
        <html lang="en" className="beta-main-page"/>
      </Helmet>
        <div class="container-fluid beta">
          <div class="row terms-and-conditions-section beta-agreement pt-2">
            <div class="col-12 text-center mb-2 ">
              <h3>Beta Testing Agreement</h3>
            </div>

            <div className="col-12 text-justify text-size ">
              <div class="content">
                <p>
                  This Beta Testing Agreement (“Agreement”) governs the
                  disclosure of information by OTR LLC (“Company”) to the
                  Recipient (“Recipient”), who is the Beta Tester, and the
                  Recipient’s use of Company’s beta service offering.
                </p>

                <ol type="1">
                  <li>
                    <p>
                      {" "}
                      Subject to the terms and conditions of this Agreement,
                      Company grants Recipient a nonexclusive, non-transferable
                      license to use the Company service (“Service”) for a
                      period designated by the Company for the purpose of
                      testing and evaluating the Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Recipient agrees that it will at all times hold in
                      strict confidence and not disclose Confidential
                      Information (as defined below) to any third party except
                      as approved in writing by the Company and will use the
                      Confidential Information for no purpose other than
                      evaluating the Service. The Recipient shall only permit
                      access to Confidential Information to those of its
                      employees having a need to know and who have signed
                      confidentiality agreements or are otherwise bound by
                      confidentiality obligations at least as restrictive as
                      those contained herein. “Confidential Information” means
                      all non-public materials and information provided or made
                      available by Company to Recipient, including products and
                      services, information regarding technology, know-how,
                      processes, software programs, research, development,
                      financial information and information the Company provides
                      regarding third parties.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Recipient’s obligations under this Agreement with
                      respect to any portion of the Confidential Information
                      shall terminate when the Recipient can document that:
                    </p>
                    <ol type="a">
                      <li>
                        <p>
                          it was in the public domain at the time it was
                          communicated to the Recipient;
                        </p>
                      </li>
                      <li>
                        <p>
                          it entered the public domain subsequent to the time it
                          was communicated to the Recipient through no fault of
                          the Recipient;
                        </p>
                      </li>
                      <li>
                        <p>
                           it was in the Recipient’s possession free of any
                          obligation of confidence at the time it was
                          communicated to the Recipient;
                        </p>
                      </li>
                      <li>
                        <p>
                          it was rightfully communicated to the Recipient free
                          of any obligation of confidence subsequent to the time
                          it was communicated to the Recipient; or
                        </p>
                      </li>
                      <li>
                        <p>
                          it was developed by employees or agents of the
                          Recipient who had no access to any information
                          communicated to the Recipient. After Recipient’s
                          evaluation of the Service is complete, or upon request
                          of the Company, the Recipient shall promptly return to
                          the Company all documents, notes and other tangible
                          materials and return or certify the destruction of all
                          electronic documents, notes, software, data, and other
                          materials in electronic form representing the
                          Confidential Information and all copies thereof.
                        </p>
                      </li>
                    </ol>
                  </li>

                  <li>
                    <p>
                      The Recipient agrees that nothing contained in this
                      Agreement shall be construed as granting any ownership
                      rights to any Confidential Information disclosed pursuant
                      to this Agreement, or to any invention or any patent,
                      copyright, trademark, or other intellectual property
                      right. The Recipient shall not make, have made, use or
                      sell for any purpose any product or other item using,
                      incorporating or derived from any Confidential Information
                      or the Service. The Recipient will not modify, reverse
                      engineer, decompile, create other works from, or
                      disassemble any software programs contained in the
                      Confidential Information or the Service.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Recipient agrees to complete membership sign-up, set
                      up a profile, if applicable, generate content and provide
                      feedback about their experience. The Recipient also agrees
                      to help bring awareness about the launch of the Company’s
                      Service with at least one pre-launch and two post-launch
                      social media postings using provided invite code. Content
                      for postings can be generated by the Recipient, tagging
                      company, featuring relevant hashtags and FTC material
                      disclosure and/or Company can provide the Recipient access
                      to pre-made postings and art.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Recipient assigns the Company Intellectual Property
                      rights to all published content created by Recipient about
                      the Company and its Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      This Service is a beta release offering and is not at the
                      level of performance of a commercially available product
                      offering. The Service may not operate correctly and may be
                      substantially modified prior to first commercial release,
                      or at Company’s option may not be released commercially in
                      the future. THE SERVICE AND DOCUMENTATION ARE PROVIDED “AS
                      IS” WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS
                      LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR
                      STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED
                      WARRANTIES OF TITLE, NON- INFRINGEMENT OF THIRD PARTY
                      RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR
                      PURPOSE. NO ORAL OR WRITTEN ADVICE OR CONSULTATION GIVEN
                      BY COMPANY, ITS AGENTS OR EMPLOYEES WILL IN ANY WAY GIVE
                      RISE TO A WARRANTY. THE ENTIRE RISK ARISING OUT OF THE USE
                      OR PERFORMANCE OF THE SERVICE REMAINS WITH RECIPIENT.
                    </p>
                  </li>
                  <li>
                    <p>
                      COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF
                      USE, LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS
                      INTERRUPTION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL,
                      PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR
                      RELATED TO THE SERVICE OR THIS AGREEMENT, HOWEVER CAUSED
                      AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT,
                      TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY, OR
                      OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL COMPANY’S
                      AGGREGATE CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT
                      OF OR RELATED TO THIS AGREEMENT EXCEED $1.00 OR THE AMOUNT
                      RECIPIENT ACTUALLY PAID COMPANY UNDER THIS AGREEMENT (IF
                      ANY).
                    </p>
                  </li>
                  <li>
                    <p>
                      The Recipient’s obligations under this Agreement shall
                      survive any termination of this agreement. This Agreement
                      shall be governed by and construed in accordance with the
                      laws of the State of California. The Recipient hereby
                      agrees that breach of this Agreement will cause Company
                      irreparable damage for which recovery of damages would be
                      inadequate, and that the Company shall therefore be
                      entitled to obtain timely injunctive relief under this
                      Agreement, as well as such further relief as may be
                      granted by a court of competent jurisdiction. The
                      Recipient will not assign or transfer any rights or
                      obligations under this Agreement without the prior written
                      consent of the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                      This Agreement may be executed via electronic signature
                      and in counterparts. Electronic, facsimile or photocopied
                      signatures shall be considered as valid signatures.
                    </p>
                  </li>
                </ol>
                <h6 className="mb-0 mt-5">Contact Information</h6>
                <p className="mb-5">
                  If You have any questions about these Terms, please contact us
                  at otrlegal@ontargetromance.com
                </p>
              </div>
            </div>
          </div>
          <div class="form-check mb-2 mr-sm-2">
            <div className="row">
              <form onSubmit={this.handleSubmit}>
                <div className="col-md-6">
                  <div class="form-group contact-form">
                    <label for="colFormLabelLg" class="text-right">
                      Full Name
                    </label>
                    <input
                      type="text"
                      class="form-control form-control-lg"
                      id="colFormLabelLg"
                      placeholder="Full Name"
                      value={this.state.name}
                      onChange={(e) => {
                        this.setState({ name: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div class="form-group contact-form">
                    <label
                      for="colFormLabelLg"
                      className="beta"
                      class=" text-right"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      class="form-control form-control-lg beta"
                      id="colFormLabelLg"
                      placeholder="Enter your email"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                      required
                    />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <input
                      type="checkbox"
                      name="acknowledge"
                      className="agreeTerms beta"
                      id="terms"
                      onClick={this.onCheck}
                      checked={this.state.terms}
                      required
                    />
                    <label
                      for="terms"
                      style={{ display: "inline" }}
                      class="form-check-label ml-2 beta"
                    >
                      I have read and agree to the OTR Beta Testing Agreement.
                      {/* I have read and agreed to the{" "}
                      <strong>
                        <a href="/#/condition" target="_blank">
                        Terms & Conditions & Privacy Policy
                        </a>
                      </strong> */}
                    </label>
                  </div>
                  {this.state.errMessage ? (
                    <div className="text-danger">
                      <p>{this.state.errMessage}</p>
                    </div>
                  ) : null}
                </div>
                <div class="col-12">
                <div class="justify-content-center beta-btn">
                  <button type="submit" class="btn btn-dark btn-lg">
                    Submit
                  </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.profileLoading,
    userList: state.userListData,
    displayMode: state.changeMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAccess: (status) => dispatch(changeBetaStatus(status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BetaAgreement);
