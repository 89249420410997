import React, { Component } from 'react';
import { Link, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { GridLoader, BounceLoader } from 'react-spinners';
import { authenticate, signuppage } from '../../Actions/index';
import { getstatic } from '../../Actions/index';
import axios from 'axios';
import '../Landing/landing.css';

import Header from '../Profile/header';
import CONSTANT, { BASEURL } from '../../constant';
import Footer from './footer';

import Logo from '../../Components/left-logo';
import Layout from '../layout/layout';

const override = css`
  position: relative;
`;

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consent_body: ''
    };

    this.getPrivacyPolicy = this.getPrivacyPolicy.bind(this);
  }

  async getPrivacyPolicy() {
    try {
      const response = await axios.get(
        BASEURL + '/api/v1/users/getPrivacyPolicy'
      );

      console.log({ response });
      if (response.data.length)
        this.setState({
          consent_body: response.data[0].consent_body
        });
    } catch (e) {
      console.log(e);
    }
  }

  componentDidMount() {
    this.getPrivacyPolicy();
    console.log('hi');
    console.log(this.props, 'props');
  }

  render() {
    window.scrollTo(0, 0);
    return (
      // <div style={{ backgroundColor: '#1f1f1f' }}>
      //   <div className="container-fluid">
      //     <div className="row">
      //       <Logo />

      //     </div>
      //   </div>

      //   <Footer />
      // </div>
      <Layout>
        <div
          className="privacy-page privacy-page-second"
          style={
            localStorage.getItem('betaagreement') ? {} : { paddingBottom: 50 }
          }
        >
          <div className="container">
            <div className="mb-4">
              <h2 className="text-center heading">Privacy Policy</h2>
            </div>
            <div
              contentEditable="false"
              className="msg-text"
              dangerouslySetInnerHTML={{ __html: this.state.consent_body }}
            ></div>
          </div>
          {localStorage.getItem('betaagreement') && (
            <div className="edit-profile-btn">
              <a href="/" class="">
                <button
                  type="submit"
                  className="btn new_btn_save_bt m-0"
                  style={{
                    marginRight: '0% !important'
                  }}
                >
                  Back to Dashboard
                </button>
              </a>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.getPrivacy
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);
