import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import {
  getstatic,
  upgragePlan,
  getUpgradablePlans
} from '../../Actions/index';
import { profileLoading } from '../../Actions/profile-setup';
import {
  getBlasts,
  deleteBlast,
  buyUserBlast
} from '../../Actions/profile-setup';
import './aboutUs.css';

class AgreeModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      open: false,
      content: this.props.content
        ? this.props.connect
        : 'Please be advised that for any uploaded content, you must either be the owner of such content or adhere to United States Fair Use doctrine. You agree that any upload adheres to'
    };
  }
  onPrivacyCheck = () => {
    this.setState({ open: false });
  };

  //===================== RENDER ==========================

  render() {
    return (
      <>
        <Modal isOpen={this.state.open} toggle={this.props.toggle} id="myModal">
          <ModalHeader toggle={this.props.toggle} className="eventModal">
            <h3 className="text-center p-0">CONTENT UPLOAD AGREEMENT</h3>
          </ModalHeader>
          <ModalBody>
            {/* <span>
              {this.state.content && (
                <div
                  dangerouslySetInnerHTML={{ __html: this.state.content }}
                ></div>
              )}
            </span> */}
            <span>
              Please be advised that for any uploaded content, you must either
              be the owner of such content or adhere to United States Fair Use
              doctrine. You agree that any uploaded content complies with
              <span
                onClick={this.openConditionsModal}
                className="mousechange"
                style={{ color: 'blue' }}
              >
                {' '}
                <Link to={`/dashboard/condition`} target="_blank">
                  {' '}
                  <u>{'OTR Terms & Conditions'}</u>
                </Link>
              </span>{' '}
              and
              <span
                onClick={this.openConditionsModal}
                className="mousechange"
                style={{ color: 'blue' }}
              >
                {' '}
                <Link to={`/dashboard/privacy`} target="_blank">
                  <u> {'OTR Privacy Policy.'}</u>{' '}
                </Link>
              </span>
            </span>
            <div className="agree-btn">
              <button
                className="btn btn-filled  btn-align"
                id="Next"
                type="button"
                onClick={this.onPrivacyCheck}
                disabled={this.state.disabled}
              >
                I agree
              </button>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
  componentDidMount() {
    this.setState({ open: this.props.isOpen });
  }
}

// {======= VideoFrames Component ========}

const mapStateToProps = state => ({
  pages: state.userListData,
  blastDetails: state.getBlastDetails,
  displayMode: state.changeMode,
  buyStatus: state.buyBlastStatus,
  loading: state.signuploading,
  membershipDetails: state.getMembershipDetails
});

const mapDispatchToProps = dispatch => ({
  getstatic: url => dispatch(getstatic(url)),
  getBlasts: (url, headers) => dispatch(getBlasts(url, headers)),
  deleteBlast: (url, headers) => dispatch(deleteBlast(url, headers)),
  buyBlast: (url, data, header) => dispatch(buyUserBlast(url, data, header)),
  showLoader: status => dispatch(profileLoading(status)),
  upgradePlan: (url, body, headers) =>
    dispatch(upgragePlan(url, body, headers)),
  getMembershipDetails: (url, header) =>
    dispatch(getUpgradablePlans(url, header))
});

export default connect(mapStateToProps, mapDispatchToProps)(AgreeModel);
