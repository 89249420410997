export function profileLoading(state = false, action) {
  switch (action.type) {
    case 'PROFILE_LOADING':
      return action.status;

    default:
      return state;
  }
}

export function profileError(state = true, action) {
  switch (action.type) {
    case 'PROFILE_ERROR':
      return action.error;

    default:
      return state;
  }
}

export function profileData(state = '', action) {
  switch (action.type) {
    case 'PROFILE_DATA':
      return action.data;
    default:
      return state;
  }
}

export function callListApi(state = false, action) {
  switch (action.type) {
    case 'CALL_API':
      return action.data;
    default:
      return state;
  }
}

export function userType(state = '', action) {
  switch (action.type) {
    case 'USER_TYPE':
      return action.user_type;
    default:
      return state;
  }
}

export function userListData(state = '', action) {
  switch (action.type) {
    case 'USERLIST_DATA':
      return action.data;
    default:
      return state;
  }
}

export function myProfileData(state = '', action) {
  // console.log("reducer result  is:",action.data);
  switch (action.type) {
    case 'MY_PROFILE':
      return action.data;
    default:
      return state;
  }
}

export function userProfileStatic(state = '', action) {
  // console.log("reducer result  is:",action.data);
  switch (action.type) {
    case 'MY_PROFILE_STATIC':
      return action.data;
    default:
      return state;
  }
}

export function previewProfile(state = {}, action) {
  switch (action.type) {
    case 'PREVIEW_PROFILE':
      return action.data;
    default:
      return state;
  }
}
export function changeMode(state = true, action) {
  switch (action.type) {
    case 'MODE_CHANGE':
      localStorage.setItem('light', action.status);
      return action.status;
    default:
      return state;
  }
}

export function userProfile(state = [], action) {
  switch (action.type) {
    case 'USER_PROFILE':
      return action.data;
    default:
      return state;
  }
}

export function getBlastDetails(state = {}, action) {
  switch (action.type) {
    case 'BLAST_DETAILS':
      return action.data;
    default:
      return state;
  }
}

export function changePopularEvents(state = false, action) {
  switch (action.type) {
    case 'CHANGE_POPULAR_EVENTS':
      return action.status;
    default:
      return state;
  }
}

export function sevenDaysLoading(state = false, action) {
  switch (action.type) {
    case 'SEVEN_LOADING':
      return action.status;

    default:
      return state;
  }
}

export function newLoader(state = false, action) {
  switch (action.type) {
    case 'NEW_LOADING':
      return action.status;

    default:
      return state;
  }
}
