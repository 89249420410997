import React, { PureComponent, Suspense } from 'react';
import { css } from '@emotion/react';
import { GridLoader } from 'react-spinners';
import { connect } from 'react-redux';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { authenticate } from './Actions/index';
import { changeDisplayMode, staticGetProfile } from './Actions/profile-setup';
import Landing from './Pages/Landing/landing';
import Login from './Pages/Login/login';
import SignUp from './Pages/SignUp/signup';
import Event from './Pages/Event/event';
import Dashboard from './Pages/Profile/dashboard';
import ForgotPassword from './Pages/Forgot-password/forgot-password';
import ConvertSocialAccount from './Pages/Social-media/conver-socialmedia';
import Profile from './Pages/Profile/profile';
import UsersListView from './Pages/usersList/usersList';
import EventFilter from './Pages/dashboard/event-filter/eventFilter';
import MyEvents from './Pages/dashboard/event-filter/myevents';
import PrivacyPolicy from './Pages/Static/privacyPolicy';
import AboutUs from './Pages/Static/aboutUs';
import Forgot from './Pages/Forgot-password/forgot';
import ContactUs from './Pages/Static/contactUs';
import Faq from './Pages/Static/faq';
import Condition from './Pages/Static/condition';
import PaymentDetails from './Pages/SignUp/paymentDetails';
import sendEmail from './Pages/dashboard/sendEmailModel';
import otpVerifyModal from './Pages/dashboard/otp-verifyModal';
import UpgradePlan from './Pages/SignUp/upgradePlan';
import Home from './Pages/Home/home';
import Membership from './Pages/Static/membershipTypes';
import Help from './Pages/Static/help';
import Advertise from './Pages/Static/advertise';
import BetaAgreement from './Pages/Landing/betaAgreement';
import CONSTANT from './constant';
import TagManager from 'react-gtm-module';
import myVideos from './Pages/Static/my-videos';
import './App.css';
import './responsive.css';
import OfferPromoPage from './Pages/Offerpromo/Promo';
import PromoPage from './Pages/Offerpromo/Promo';
import Loader from './Components/Loader/loader';
import MembershipInfo from './Pages/dashboard/membershipInfo';
import BlankPage from './Components/blankPage';
import MembershipReaderdfree from './Pages/Footer/list/membershipReaderdfree';
import MembershipRegular from './Pages/Footer/list/membershipRegular';
import MembershipRegularLogout from './Pages/Footer/list/membershipRegularLogout';
import membershipReaderfreeLogout from './Pages/Footer/list/membershipReaderfreeLogout';

const override = css`
  position: fixed;
  margin-top: 15%;
  left: 45%;
`;

class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { user: '', profileData: '' };
  }

  componentWillReceiveProps = prevProps => {
    // if (prevProps.getBetaAccessStatus) {
    //   this.props.history.push("/");
    // }
  };

  render() {
    const tagManagerArgs = {
      gtmId: CONSTANT.GTM
    };

    TagManager.initialize(tagManagerArgs);
    if (
      !localStorage.getItem('betaagreement') &&
      !this.props.getBetaAccessStatus &&
      CONSTANT.WEBSITEMODE === 'BETA'
    ) {
      return [
        <HashRouter>
          <Switch>
            <Route exact path="/" component={BetaAgreement} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route exact path="/condition" component={Condition} />
            {/* <Route path="/offers" component={PromoPage} /> */}
            <Route path="*" render={() => <Redirect to="/" push />} />
          </Switch>
        </HashRouter>
      ];
    } else {
      if (!localStorage.getItem('token')) {
        return [
          <HashRouter>
            <Switch>
              <Route exact path="/" component={Landing} />

              <Route exact path="/legal" component={BetaAgreement} />
              <Route exact path="/log-in" component={Login} />
              <Route
                exact
                path="/forgotpassword/:id/:token"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/convertsocialaccount"
                component={ConvertSocialAccount}
              />
              <Route exact path="/join" component={SignUp} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/condition" component={Condition} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/howto" component={myVideos} />
              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/plans" component={Membership} />
              <Route path="/help" component={Help} />
              <Route
                path="/otrplans_readerfree"
                component={membershipReaderfreeLogout}
              />
              <Route
                exact
                path="/otrplans_regular"
                component={MembershipRegularLogout}
              />
              {/* <Route path="/offerpromo" component={PromoPage} /> */}
              <Route exact path="/offers" component={PromoPage} />
              <Route exact path="/advertise" component={Advertise} />
              {/* <Route exact path="/payment" component={PaymentDetails} /> */}
              <Route path="*" render={() => <Redirect to="/" push />} />
            </Switch>
          </HashRouter>,

          this.props.status || this.props.loading ? (
            // <div className="loader-container">
            //   <GridLoader
            //     css={override}
            //     sizeUnit={'px'}
            //     size={25}
            //     color={'#e20f0b'}
            //     loading={true}
            //   />
            // </div>
            <Loader />
          ) : (
            ''
          )
        ];
      } else if (
        localStorage.getItem('token') &&
        localStorage.getItem('membershipId') == 'null'
      ) {
        return [
          this.props.status ||
          this.props.loading ||
          this.props.eventLoad ||
          this.props.profileLoading ? (
            // <div className="loader-container">
            //   <GridLoader
            //     css={override}
            //     sizeUnit={'px'}
            //     size={25}
            //     color={'#e20f0b'}
            //     loading={true}
            //   />
            // </div>
            <Loader />
          ) : (
            ''
          ),
          <HashRouter>
            <div>
              <Route exact path="/" component={PaymentDetails} />
              <Route exact path="/payment" component={PaymentDetails} />
              <Route exact path="/condition" component={Condition} />
              <Route exact path="/log-in" component={Login} />
              <Route
                exact
                path="/forgotpassword/:id/:token"
                component={ForgotPassword}
              />
              <Route exact path="/join" component={SignUp} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/about" component={AboutUs} />
              <Route exact path="/contactus" component={ContactUs} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/forgot" component={Forgot} />
              <Route exact path="/plans" component={Membership} />
              <Route
                path="/otrplans_readerfree"
                component={membershipReaderfreeLogout}
              />
              <Route
                exact
                path="/otrplans_regular"
                component={MembershipRegularLogout}
              />
              <Route path="/help" component={Help} />
              <Route exact path="/offers" component={PromoPage} />
              <Route exact path="/advertise" component={Advertise} />
            </div>
          </HashRouter>
        ];
      } else if (
        localStorage.getItem('token') &&
        localStorage.getItem('expirepop') == '1'
      ) {
        return [
          this.props.status ||
          this.props.loading ||
          this.props.eventLoad ||
          this.props.profileLoading ? (
            // <div className="loader-container">
            //   <GridLoader
            //     css={override}
            //     sizeUnit={'px'}
            //     size={25}
            //     color={'#e20f0b'}
            //     loading={true}
            //   />
            // </div>
            <Loader />
          ) : (
            ''
          ),
          <HashRouter>
            <div>
              {/* <Route path="/dashboard" component={Dashboard} /> */}
              <Route path="/dashboard/membership" component={MembershipInfo} />
              <Route path="/dashboard/my-events" component={MyEvents} />
              <Route
                exact
                path="/"
                render={() => <Redirect to="/dashboard/membership" push />}
              />
              <Route path="/blankpage" component={BlankPage} />
            </div>
          </HashRouter>
        ];
      } else {
        return [
          this.props.status ||
          this.props.loading ||
          this.props.eventLoad ||
          this.props.profileLoading ? (
            // <div className="loader-container">
            //   <GridLoader
            //     css={override}
            //     sizeUnit={'px'}
            //     size={25}
            //     color={'#e20f0b'}
            //     loading={true}
            //   />
            // </div>
            <Loader />
          ) : (
            ''
          ),
          <HashRouter>
            <div>
              {this.state.profileData == '' &&
              localStorage.getItem('profile_setup') !== 'done' ? (
                <>
                  {/* <Route exact path="/" render={() => <Redirect to="/user" push />} /> */}
                  {/* <Route path="/user" component={Home} /> */}
                  <Route exact path="/log-in" component={Login} />
                  <Route path="*" component={Home} />
                </>
              ) : (
                <>
                  <Route
                    exact
                    path="/forgotpassword/:id/:token"
                    component={ForgotPassword}
                  />
                  <Route
                    exact
                    path="/"
                    render={() => <Redirect to="/dashboard" push />}
                  />
                  <Route exact path="/users/:profile" component={Profile} />
                  <Route exact path="/payment" component={PaymentDetails} />
                  <Route exact path="/join" component={SignUp} />
                  <Route exact path="/log-in" component={Login} />
                  <Route exact path="/setup-event" component={Event} />
                  <Route path="/dashboard" component={Dashboard} />
                  <Route path="/user" component={Home} />
                  <Route exact path="/privacy" component={PrivacyPolicy} />
                  <Route exact path="/about" component={AboutUs} />
                  <Route exact path="/contactus" component={ContactUs} />
                  <Route exact path="/faq" component={Faq} />
                  <Route exact path="/howto" component={myVideos} />
                  <Route exact path="/condition" component={Condition} />
                  <Route exact path="/sendEmail" component={sendEmail} />
                  <Route exact path="/upgradePlan" component={UpgradePlan} />
                  <Route exact path="/plans" component={Membership} />
                  <Route
                    path="/otrplans_readerfree"
                    component={membershipReaderfreeLogout}
                  />
                  <Route
                    exact
                    path="/otrplans_regular"
                    component={MembershipRegularLogout}
                  />
                  <Route path="/help" component={Help} />
                  {/* <Route path="/otrplans" component={MembershipReaderdfree} /> */}

                  <Route exact path="/offers" component={PromoPage} />
                  <Route exact path="/advertise" component={Advertise} />
                  {/* <Route exact path="/" render={() => <Redirect to="/user" push />} /> */}
                </>
              )}
            </div>
          </HashRouter>
        ];
      }
    }
  }

  componentDidMount() {
    if (localStorage.getItem('light')) {
      this.props.setDisplayMode(localStorage.getItem('light') === 'true');
    }
  }

  componentWillReceiveProps = props => {
    if (props.profileData) {
      this.setState({ profileData: props.profileData });
    }
    if (localStorage.getItem('light')) {
      this.props.setDisplayMode(localStorage.getItem('light') === 'true');
    }
  };
}

// const LazyHome = React.lazy(() => import('./Pages/Home/home'));
// const Home = () => (
//   <Suspense fallback={<div></div>}>
//     <LazyHome />
//   </Suspense>
// );

const mapStateToProps = state => {
  return {
    status: state.userloading,
    error: state.usererror,
    token: state.userlists,
    loading: state.signuploading,
    eventLoad: state.eventLoading,
    profileLoading: state.profileLoading,
    profileData: state.userProfileStatic,
    openOtp: state.openOtpModal,
    getBetaAccessStatus: state.betaUserAccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchData: url => dispatch(authenticate(url)),
    setDisplayMode: mode => dispatch(changeDisplayMode(mode))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
